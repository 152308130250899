import React from 'react'
import { Form, Checkbox } from "antd";
import { formatMessage } from "../../utils/util";
import { injectIntl } from "react-intl";

class CustomCheckbox extends React.Component {
  render() {
    const {name, disabled, initialValue, labelCol, onChange, label, rules, valuePropName, wrapperCol} = this.props
    return (
      <Form.Item
          name={name}
          label={label ? formatMessage(this,label) : ''}
          initialValue={initialValue}
          rules={rules}
          labelCol={labelCol}
          valuePropName={valuePropName}
          wrapperCol={wrapperCol}
        >
          <Checkbox
            disabled={disabled}
            onChange={onChange}
          />
        </Form.Item>
      )
  }
}

export default injectIntl(CustomCheckbox)
