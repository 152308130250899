/**
 * 包含应用中所有接口请求函数的模块
 * 每个函数值返回的都是promise
 * JSONP 只能处理GET请求跨域
 */
import ajax from "./ajax";
import exportAjax from "./exportAjax";
import {exportAjax1} from "./exportAjax";


const BASE = '/api'; // /api

export const reqLogin = (data) => ajax(BASE + '/auth/password/login', data, 'POST', true);

export const reqLogout = (data) => ajax(BASE + '/auth/logout', data,'POST', true);

export const reqNewLogin = (searchParams) => ajax(BASE + '/auth/newLogin', {}, 'POST', true, '', searchParams)
// 免登录接口
// export const reqPasswordFreeLogin = (searchParams) => ajax(BASE + '/auth/login', {}, 'POST', true, '', searchParams)
export const reqPasswordFreeLogin = (searchParams) => ajax(BASE + '/auth/login', searchParams, 'POST', true)

export const reqNewLogout = (searchParams) => ajax(BASE + '/auth/newLogout', searchParams, 'POST', true, '', searchParams);

export const reqCheckTokenValid = (data) => ajax(BASE + '/user/checkTokenValid', data,'POST', true);

export const getSingleSupplierByUserCode = (data) => ajax(BASE + '/bill/supplier/getSingleSupplierByUserCode',{}, 'POST', true, '',data)

export const reqCommonOrderList = (searchParams) => ajax(BASE + '/order/getOrderInfos', searchParams, 'GET', true);

export const reqCompany = (searchParams) => ajax(BASE + '/supplier/getSupplierNameByCode', searchParams, 'GET', true);

export const reqCustomsOrderList = (searchParams) => ajax(BASE + '/order/getCustomsOrderInfos', searchParams, 'GET', true);

export const reqDeliveryManageList = (searchParams) => ajax(BASE + '/order/getDeliveryManageOrderInfos', searchParams, 'GET', true);

export const reqGpOrderList = (searchParams) => ajax(BASE + '/order/orderapi/getGpOrderInfos', searchParams, 'GET');

export const reqJitOrderList = (searchParams) => ajax(BASE + '/order/jitOrder/getJitOrderInfos', searchParams, 'GET');

export const reqFileList = (searchParams) => ajax(BASE + '/order/fileapi/getFileInfos', searchParams, 'GET');

export const reqUploadFile = (searchParams) => ajax(BASE + '/order/fileapi/uploadFile', searchParams, 'POST');

export const reqOrderReplyList = (searchParams) => ajax(BASE + '/order/getOrderReplyInfos', searchParams, 'GET', true);

export const reqUserList = (searchParams) => ajax(BASE + '/supplier/getUserInfos', searchParams, 'GET');

export const reqBasedataList = (searchParams) => ajax(BASE + '/supplier/getBasedataInfos', searchParams, 'GET');

export const exportOrderReplyList = (searchParams) => ajax(BASE + '/order/exportOrderReplyList', searchParams, 'GET', false, {responseType: 'blob',});

export const importOrderReplyList = (searchParams) => ajax(BASE + '/order/importOrderReplyList', searchParams, 'POST', true, {headers: {post: {'Content-Type': 'multipart/form-data'}}});

export const replyOrderList = (searchParams) => ajax(BASE + '/order/replyOrderList', searchParams, 'POST', true);

export const reqAddUser = (searchParams) => ajax(BASE + '/supplier/add_user', searchParams, 'POST');

export const reqUpdateUser = (searchParams) => ajax(BASE + '/supplier/updateUserInfo', searchParams, 'POST');

/*基础数据-查询所有供应商代码*/
export const reqSupplierList = (searchParams) => ajax(BASE + '/supplier/getSupplierInfos', searchParams, 'GET');

export const reqAddSupplier = (searchParams) => ajax(BASE + '/supplier/addSupplierInfo', searchParams, 'POST');

export const reqUpdateSupplier = (searchParams) => ajax(BASE + '/supplier/updateSupplierInfo', searchParams, 'POST');

export const reqOrderImport = (searchParams) => ajax(BASE + '/order/order_import', searchParams, 'POST', false, {headers: {post: {'Content-Type': 'multipart/form-data'}}});

export const reqSupplierImport = (searchParams) => ajax(BASE + '/supplier/supplier_import', searchParams, 'POST', false, {headers: {post: {'Content-Type': 'multipart/form-data'}}});

export const exportSupplierExcelTemplate = (searchParams) => exportAjax(BASE + '/supplier/exportSupplierExcelTemplate', searchParams, 'GET',true, 'application/json',{responseType: 'blob',});

export const exportOrderExcelTemplate = (searchParams) => exportAjax(BASE + '/order/exportCustomsOrderInfos', searchParams, 'GET', false, 'application/json',{responseType: 'blob',});

export const reqCaptcha = (searchParams) => ajax(BASE + '/auth/checkCode', searchParams, 'GET');
//原srm路由接口
export const getMenuInfos = (searchParams) => ajax(BASE + '/supplier/getMenuInfos', searchParams, 'GET', false);
//新路由接口
export const getNewMenuInfos = (searchParams) => ajax(BASE + '/system/menu/getRouters', searchParams, 'GET', false);

export const reqTruckSeqList = (searchParams) => ajax(BASE + '/car/getTruckSeqInfos', searchParams, 'GET');

export const reqSaveTruck = (searchParams) => ajax(BASE + '/car/saveTruck', searchParams, 'POST');

export const reqOrderDetail = (searchParams)=>ajax(BASE+'/order/orderSplit',searchParams,'POST',true, 'application/json')

export const reqViewOrderDetail = (searchParams) => ajax(BASE + '/order/getDacDeliveryOrderDetail', searchParams, 'GET', true)

export const reqViewOrderDetailSubmit = (searchParams) => ajax(BASE + '/order/updateDacDeliveryOrderDetail', searchParams, 'POST', true, 'application/json')

/*请求装箱列表*/
export const reqBoxList = (searchParams) => ajax(BASE + '/car/getTruckBillDetailPacking', searchParams, 'GET', true)

/*装箱单-删除合同*/
export const reqDelBoxList = (searchParams) => ajax(BASE + '/car/deleteTruckbilldetail', searchParams, 'POST', true, 'application/json')

/*装箱单-明细字段汇总*/
export const reqTruckBillDetailPackingSum = (searchParams) => ajax(BASE + '/car/getTruckBillDetailPackingSum', searchParams, 'GET', false)

/*装箱单-列表修改提交*/
export const reqEditContract = (searchParams) => ajax(BASE + '/car/saveTruckBillDetailForModify', searchParams, 'POST', true, 'application/json')

/*装箱单-添加合同弹框列表*/
export const reqSearchAddContractList = (searchParams) => ajax(BASE + '/car/getContractDetail', searchParams, 'GET', true, 'application/json')

/*装箱单-添加合同提单*/
export const reqAddContract = (searchParams) => ajax(BASE + '/car/addTruckBillDetail', searchParams, 'POST', true, 'application/json')

/*基础数据-添加基础数据*/
export const reqAddBaseData = (searchParams) => ajax(BASE + '/supplier/insertBaseData', searchParams, 'POST', true)

/*基础数据-修改基础数据*/
export const reqEditBaseData = (searchParams) => ajax(BASE + '/supplier/updateBasedataInfos', searchParams, 'POST', true)

/*基础数据-删除基础数据*/
export const reqDelBaseData = (searchParams) => ajax(BASE + '/supplier/deleteBasedataByIds', searchParams, 'POST', true, 'application/json')

/*基础数据-获取报关原产国/启运国*/
export const reqCountryList = (searchParams) => ajax(BASE + '/supplier/selectAllCountryNames', searchParams, 'GET', true)

/*装箱单-转单提交*/
export const reqCarNumber = (searchParams) => ajax(BASE + '/car/transformTruckbilldetail', searchParams, 'POST', true)

/*送货单列表保存数据*/
export const reqUpdateDacDeliveryOrderList =(searchParams)=>ajax(BASE+'/order/updateDacDeliveryOrderList',searchParams,'POST',true,'application/json')

/*装箱单-证书列表*/
export const reqCertificateList = (searchParams)=>ajax(BASE+'/car/getUploadFileForContract',searchParams,'GET',true)

/*车次管理-修改车的状态*/
export const reqUpdateTruckBillStatus= (searchParams)=>ajax(BASE+'/car/updateTruckBillStatus',searchParams,'POST',true,'application/json')

/*删除证书*/
export const reqDelCertificate = (searchParams)=>ajax(BASE+'/car/deleteInvoiceFileAndSignFile',searchParams,'POST',true)

/*上传证书*/
export const reqUploadCertificate = (searchParams)=>ajax(BASE+'/car/uploadTruckBillDetailFile',searchParams,'POST',true)

/*下载证书*/
export const reqDownloadCertificate = (searchParams)=>ajax(BASE+'/car/downloadFile',searchParams,'GET')

/*车次管理-下载文件*/
export const reqDownloadCarFile = (searchParams)=>ajax(BASE+'/car/downloadFile',searchParams,'GET')

/*送货单管理-上传发票*/
export const reqUploadInvoice = (searchParams)=>ajax(BASE+'/order/uploadDeliveryNoteInvoice',searchParams,'POST',true)

/*车辆管理页面-上传文件*/
export const reqUploadCarFile = (searchParams)=>ajax(BASE+'/car/uploadTruckFile',searchParams,'POST',true)
/*打印送货单*/
export const reqPrintDeliveryOrder = (searchParams) => exportAjax(BASE + '/order/printDeliveryNote', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});
/*装箱单明细-导出合同/发票*/
export const reqExportContractAndInvoice = (searchParams)=>exportAjax(BASE+'/car/exportContractInvoiceExcel',searchParams,'POST',true,'application/json',{responseType: 'blob'})

/*装箱单明细-导出合同*/
export const reqExportContract = (searchParams) => exportAjax(BASE+'/car/exportContractExcel',searchParams,'POST',true,'application/json',{responseType: 'blob'})

/*送货单管理页面-查询送货单发票*/
export const reqDeliveryNoteInvoiceList =(searchParams)=>ajax(BASE+'/order/getDeliveryNoteInvoice',searchParams,'GET',true)

/*送货单管理页面-删除发票*/
export const reqDelDeliveryNoteInvoice =(searchParams)=>ajax(BASE+'/order/deleteDeliveryNoteInvoice',searchParams,'POST',true)

/*送货单管理页面-确认*/
export const reqConfirmDacDeliveryOrder = (searchParams)=>ajax(BASE+'/order/confirmDacDeliveryOrder',searchParams,'POST',true,'application/json')

/*送货单管理页面-取消*/
export const reqCancelConfirmDacDeliveryOrder = (searchParams)=>ajax(BASE+'/order/cancelConfirmDacDeliveryOrder',searchParams,'POST',true,'application/json')

/*送货单管理页面-删除*/
export const reqDelDacDeliveryOrder = (searchParams)=>ajax(BASE+'/order/deleteDeliveryOrderList',searchParams,'POST',true)

/*装箱单明细-打印/批量打印合同/发票*/
export const reqPrintContractOrInvoice = (searchParams)=>exportAjax(BASE+'/car/exportContractOrInvoicePdf',searchParams,'POST',true,'application/json',{responseType: 'blob'})

/*装箱单明细-打印/批量打印合同和发票*/
export const reqPrintContractAndInvoice = (searchParams)=>exportAjax(BASE+'/car/exportContractAndInvoicePdf',searchParams,'POST',true,'application/json',{responseType: 'blob'})

/*装箱单明细-导出装箱单*/
export const reqExportTruckBillDetail = (searchParams)=>exportAjax(BASE+'/car/exportTruckBillDetail',searchParams,'POST',true,'application/json',{responseType: 'blob'})

/*查询所有国家*/
export const reqSearchAllCountries = (searchParams)=>ajax(BASE+'/supplier/getCountryInfos',searchParams,'GET',false)

/*导出发票*/
export const reqDownInInvoice = (searchParams)=>exportAjax(BASE+'/order/downloadDeliveryNoteInvoice',searchParams,'GET',true,'application/json',{responseType: 'blob'})

/*导出送货单明细*/
export const reqExportDeliveryOrder = (searchParams)=>exportAjax(BASE+'/order/exportDeliveryDetailnfos',searchParams,'POST',true,'application/json',{responseType: 'blob'})

/*导出送货单*/
export const reqExportDeliveryOrderDetail = (searchParams)=>exportAjax(BASE+'/order/exportDeliverySummaryInfos',searchParams,'POST',true,'application/json',{responseType: 'blob'})

/*导出国家代码标准表*/
export const reqExportCodeStandard = (searchParams)=>exportAjax(BASE+'/order/exportStandardCountryCode',searchParams,'GET',true,'application/json',{responseType: 'blob'})

/*下载送货单*/
export const reqDownloadDelivery = (searchParams) => exportAjax(BASE + '/order/exportDeliveryOrder', searchParams, 'GET', false, 'application/json',{responseType: 'blob',});

/*上传送货单*/
export const reqUploadDelivery =(searchParams)=>ajax(BASE+'/order/importOrderSplit',searchParams,'POST',true)

/*删除选中的送货单*/
export const reqDelSelectedDelivery = (searchParams)=>ajax(BASE+'/order/orderManagement/productionPurchasing/deleteDeliveryNote',searchParams,'POST',true,'application/json',)

/*删除当前行送货单*/
export const reqDelCurrentDelivery = (searchParams)=>ajax(BASE+'',searchParams,'POST',true)

/*打印送货单PDF*/
export const reqPrintDelivery = (searchParams) => exportAjax(BASE + '/order/orderManagement/productionPurchasing/exportDeliveryNotePDF', searchParams, 'GET', false, 'application/json',{responseType: 'blob',});

/*打印外箱标贴*/
export const reqPrintBoxLabel = (searchParams) => exportAjax(BASE + '/order/orderManagement/productionPurchasing/exportDeliveryOrderExtraBoxLabelPDF', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*导出送货单*/
export const reqExportDelivery = (searchParams) => exportAjax(BASE + '/order/orderManagement/productionPurchasing/exportDeliveryOrderList', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*导入送货单*/
export const reqImportDelivery = (searchParams) => exportAjax(BASE + '', searchParams, 'GET', false, 'application/json',{responseType: 'blob',});

/*提交打印送货单行数据*/
export const reqSubmitPrintDeliveryS = (searchParams)=>ajax(BASE+'/order/orderManagement/productionPurchasing/modifyDeliveryNote',searchParams,'POST',true)

/*提交生成送货单-PO*/
export const reqSubmitGenerateDelivery = (searchParams)=>ajax(BASE+'/order/orderManagement/productionPurchasing/createDeliveryNote',searchParams,'POST',true)

/*生成送货单-PO页面 -导出送货单*/
export const reqExportDeliveryExcel = (searchParams) => exportAjax(BASE + '/order/orderManagement/productionPurchasing/exportDeliveryNote', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*生成送货单-PO页面 -导出生成送货单*/
export const reqExportGenerateDeliveryExcel = (searchParams) => exportAjax(BASE + '/order/orderManagement/productionPurchasing/exportCreateDeliveryNote', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*生成送货单-PO页面 -导入生成送货单*/
export const reqImportGenerateDeliveryExcel = (searchParams) => ajax(BASE + '/order/orderManagement/productionPurchasing/importCreateDeliveryNote', searchParams, 'POST', true);

/*回复交期-列表的提交/采购确认*/
export const reqSubmitReplyList= (searchParams)=>ajax(BASE+'/order/replyOrderList',searchParams,'POST',true)

/*回复交期-采购驳回*/
export const reqOrderPurchaseReject= (searchParams)=>ajax(BASE+'/order/purchaseReject',searchParams,'POST',true)

/*回复交期-获取回复交期列表*/
export const reqReplyList= (searchParams)=>ajax(BASE+'/order/getOrderReplyInfos',searchParams,'GET',true)

/*回复交期-导出*/
export const reqExportBatchReply = (searchParams) => exportAjax(BASE + '/order/exportOrderReplyToExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*回复交期-订单确认*/
export const reqOrderConfirm= (searchParams)=>ajax(BASE+'/order/orderConfirmBySupplier',searchParams,'GET',true)

/*回复交期/生成送货单PO-查看操作日志*/
export const reqGetOrderOperateLogs= (searchParams)=>ajax(BASE+'/order/getOrderDetailOperateLogs',searchParams,'GET',false)

/*回复交期-同步sap订单*/
export const reqSyncOrderData= (searchParams)=>ajax(BASE+'/order/syncOrderData',searchParams,'POST',false)

/*获取收货地址*/
export const reqGetDeliveryAddressList= (searchParams)=>ajax(BASE+'/supplier/getDeliveryAddress',searchParams,'GET',true)

/*获取工厂列表*/
export const reqGetFactoryList= (searchParams)=>ajax(BASE+'/user/getUserFactory',searchParams,'GET',true)

/*获取工厂列表*/
export const reqGetComboBoxFactory= (searchParams)=>ajax(BASE+'/user/getComboBoxFactory',searchParams,'POST',true)

/*分批回复交期的提交*/
export const reqBatchReplySubmit= (searchParams)=>ajax(BASE+'/order/savePoSplitBatchList',searchParams,'POST',true)

/*获取收货截止日期*/
export const reqDeliveryDay= (searchParams)=>ajax(BASE+'/supplier/getReceivingDayBySupplierCode',searchParams,'GET',false)

/*生成送货单PO-获取列表*/
export const reqGenerateDeliveryList= (searchParams)=>ajax(BASE+'/order/orderManagement/productionPurchasing/getPurchaseOrderCodeInfos',searchParams,'GET',true)

/*装箱单明细-单证核对*/
export const reqBillCheck= (searchParams)=>ajax(BASE+'/car/saveTruckBillDetailBillCheck',searchParams,'POST',true)

/*付款信息列表(月结)*/
export const reqSearchPaymentInfoMonth = (searchParams)=>ajax(BASE+'',searchParams,'GET',true)

/*付款信息列表(随货)*/
export const reqSearchPaymentInfoAlongWithCargo = (searchParams)=>ajax(BASE+'/reconciliation/selectPaymentWithGoodsInfo',searchParams,'POST',true)

/*付款信息列表(月结)-导出excel*/
export const reqExportPaymentInfoMonthExcel = (searchParams) => exportAjax(BASE + '/reconciliation/exportPaymentList', searchParams, 'POST', false, 'application/json',{responseType: 'blob',});

/*付款信息列表(随货)-导出excel*/
export const reqExportPaymentInfoAlongWithCargoExcel = (searchParams) => exportAjax(BASE + '', searchParams, 'GET', false, 'application/json',{responseType: 'blob',});

/*查询订单列表*/
export const reqOderList = (searchParams)=>ajax(BASE+'/order/getOrderInfos',searchParams,'GET',true)

/*导出批量回复交期模板*/
export const reqExportReplyTemplate = (searchParams) => exportAjax(BASE + '/order/exportOrderReplyList', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*导入回复交期*/
export const reqImportOrderReplyList = (searchParams)=>ajax(BASE+'/order/importOrderReplyList',searchParams,'POST',true)

/*回复交期-打印订单pdf*/
export const reqPrintDeliveryPdf = (searchParams) => exportAjax(BASE + '/order/printOrderPdf', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*付款信息列表(随货)-删除*/
export const reqDeletePaymentWithGoods = (searchParams)=>ajax(BASE+'/reconciliation/deletePaymentWithGoods',searchParams,'POST',true)

/*付款信息列表(随货)-一键开票(400)/一键开票(200)有勾选数据*/
export const reqInvocedByselectPaymentHasSelected = (searchParams)=>ajax(BASE+'/reconciliation/invocedByselectPayment',searchParams,'POST',true)

/*付款信息列表(随货)-一键开票(400)/一键开票(200)没有勾选数据*/
export const reqInvocedByselectPaymentHasNoSelected = (searchParams)=>ajax(BASE+'/reconciliation/batchInvocedByPaymentQO',searchParams,'POST',true)

/*付款信息列表(随货)-修改数量/备注*/
export const reqUpdatePaymentWithGoods = (searchParams)=>ajax(BASE+'/reconciliation/updatePaymentWithGoods',searchParams,'POST',true)

/*付款信息列表(随货)-详情*/
export const reqPaymentWithGoodsDetail = (searchParams)=>ajax(BASE+'/reconciliation/selectPaymentDetail',searchParams,'POST',true)

/*回复交期-申请提交交货*/
export const reqApplyEarlyReceipt = (searchParams)=>ajax(BASE+'/order/applyEarlyReceipt',searchParams,'POST',true)

/*付款信息列表(随货)-更改为随货*/
export const reqUpdateReconciliationType = (searchParams)=>ajax(BASE+'/reconciliation/updateReconciliationType',searchParams,'POST',true)

/*查询订单-导到延迟表*/
export const reqExportDelayDeliveryOrder = (searchParams) => exportAjax(BASE + '/order/exportDelayDeliveryOrder', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

/*回复交期-清除提前收货*/
export const reqClearEarlyReceipt= (searchParams) => ajax(BASE + '/order/clearEarlyReceipt', searchParams, 'POST', true);

/*管理送货单po-获取列表*/
export const reqGetDeliveryOrderList = (searchParams) => ajax(BASE + '/order/orderManagement/productionPurchasing/getDeliveryOrderList', searchParams, 'GET', true);

/*订单查询-导到excel*/
export const reqExportOrder = (searchParams) => exportAjax(BASE + '/order/exportOrder', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

/*国际订单确认列表*/
export const reqIntelOrderConfirmList = (searchParams) => ajax(BASE + '/order/getInternationalOrderConfirmInfos', searchParams, 'GET', true);

/*国际订单确认 - 提交*/
export const reqUpdateInternationalOrder= (searchParams) => ajax(BASE + '/order/updateInternationalOrderInfos', searchParams, 'POST', true);

/*国际订单确认 生成pdf*/
export const reqGeneratePdfOfIntelOrderConfirm = (searchParams) => exportAjax(BASE + '', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

/*导入送货单*/
export const reqImportDeliveryOfManagerDelivery = (searchParams) => ajax(BASE + '/order/orderManagement/productionPurchasing/importModifyDeliveryNote', searchParams, 'POST', true);

/*国际订单确认-导出*/
export const reqExportExcelOfIntelOrderConfirm = (searchParams) => exportAjax(BASE + '/order/exportInternationalOrderConfirm', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

/*重排列表-获取上传重排列表*/
export const reqRearrangementList = (searchParams) => ajax(BASE + '/order/getRearrangementInfos', searchParams, 'GET', true);

/*重排列表-获取上传重排列表*/
export const reqOperateLogsOfRearrangement = (searchParams) => ajax(BASE + '/order/getRearrangementDetail', searchParams, 'GET', true);

/*国际订单审核 - 列表  */
export const reqIntelOrderExamineList = (searchParams) => ajax(BASE + '/order/getInternationalOrderAuditInfos', searchParams, 'GET', true);

/*修改送货单-查询列表*/
export const reqUpdateDeliveryList = (searchParams) => ajax(BASE + '/order/orderManagement/productionPurchasing/getDeliveryOrderListForModify', searchParams, 'GET', true);

/*修改送货单页面-修改送货单*/
export const reqUpdateDeliverySubmit = (searchParams) => ajax(BASE + '/order/orderManagement/productionPurchasing/modifyDeliveryNoteForPO', searchParams, 'POST', true);

/*重排列表-上传重排*/
export const reqUploadRearrangeSheet = (searchParams) => ajax(BASE + '/order/importRearrangementList', searchParams, 'POST', true);

/*仓储管理-收货管理-收货过账管理 - 请求列表*/
export const reqSearchReceiptPostingList = (searchParams) => ajax(BASE + '/order/getConfirmOrderList', searchParams, 'GET', true);

/*重排页面- 获取版本列表*/
export const reqVersionNoList = (searchParams) => ajax(BASE + '/order/getRearrangementVersion', searchParams, 'GET', false);

/*重排页面- 清空重排*/
export const reqClearRearrangement = (searchParams) => ajax(BASE + '/order/clearRearrangement', searchParams, 'GET', true);

/*重排页面- 导出错误信息*/
export const reqExportErrorMessage = (searchParams) => ajax(BASE + '/order/exportRearrangementErrorMessage', searchParams, 'GET', true);

/*重排页面-导出重排数据*/
export const reqExportRearrangementData = (searchParams) => exportAjax(BASE + '/order/exportRearrangementToExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*采购订单查询-打印英文Pdf*/
export const reqPrintEnglishPdf = (searchParams) => exportAjax(BASE + '/order/printOrderPdfs', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*查询提前送货记录-查询列表*/
export const reqAdvanceDeliveryRecordList = (searchParams) => ajax(BASE + '/order/selectEarlyDeliverysByQO', searchParams, 'POST', true);

/*查询提前送货记录-导到excel*/
export const reqExportEarlyDeliveryS = (searchParams) => exportAjax(BASE + '/order/exportEarlyDeliverys', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

/*国际订单确认-导出pdf*/
export const reqPrintIntelOrderPdf = (searchParams) => exportAjax(BASE + '/order/exportInternationalOrderPDF', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*送货单异常确认-查询列表*/
export const reqDeliveryNoteExceptionConfirmList = (searchParams) => ajax(BASE + '/order/deliveryOrderConfirmInfo', searchParams, 'GET', true);

/*送货单异常确认-采购确认*/
export const reqPurchaseConfirmation = (searchParams) => ajax(BASE + '/order/purchaseConfirmation', searchParams, 'POST', true);

/*送货单异常确认-采购驳回*/
export const reqPurchaseDismiss = (searchParams) => ajax(BASE + '/order/purchaseDismiss', searchParams, 'POST', true);

/*查询未按承诺交付-搜索列表*/
export const reqDeliveryPromisedList = (searchParams) => ajax(BASE + '/order/getOrderUnPromiseDeliverList', searchParams, 'GET', true);

/*查询未按承诺交付-导出excel*/
export const reqExportDeliveryUnPromised = (searchParams) => exportAjax(BASE + '/order/exportOrderUnPromiseDeliverList', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*查询未按承诺交付-删除*/
export const reqDeleteReceiptPosting = (searchParams) => ajax(BASE + '/order/deleteOrderConfirmByIds', searchParams, 'POST', true);

/*查询未按承诺交付-收货确认*/
export const reqReceiptConfirm = (searchParams) => ajax(BASE + '/order/orderConfirmByIds', searchParams, 'POST', true);

/*审批提前收货-列表*/
export const reqApproveAdvanceReceiptList = (searchParams) => ajax(BASE + '/order/selectEarlyDeliverysByAuditor', searchParams, 'POST', true);

/*审批提前收货-审批通过*/
export const reqApproved = (searchParams) => ajax(BASE + '/order/approvedEarlyDeliverys', searchParams, 'POST', true);

/*审批提前收货-审核驳回*/
export const reqReviewRejected = (searchParams) => ajax(BASE + '/order/rejectEarlyDeliverys', searchParams, 'POST', true);

/*国际订单-通过*/
export const reqIntelOrderApproved = (searchParams) => ajax(BASE + '/order/approvedInternationalOrder', searchParams, 'POST', true);

/*国际订单-取消*/
export const reqInterOrderCancel = (searchParams) => ajax(BASE + '/order/rejectInternationalOrder', searchParams, 'POST', true);

/*过账管理-勾选的导出*/
export const reqExportReceiptPostingBySelect = (searchParams) => exportAjax(BASE + '/order/exportSerialNumberList', searchParams, 'POST', true, 'application/json', {responseType: 'blob',});

/*过账管理-输入送货单的导出*/
export const reqExportReceiptPostingByInput = (searchParams) => exportAjax(BASE + '/order/exportSerialNumberListBy', searchParams, 'POST', true, 'application/json', {responseType: 'blob',});

/*回复交期-获取分批回复列表*/
export const reqPoSplitBatchList = (searchParams) => ajax(BASE + '/order/getPoSplitBatchList', searchParams, 'GET', true);

/*获取采购员列表*/
export const reqPurchaserCodeList= (searchParams) => ajax(BASE + '/user/getPurchaseByPurchaseNo', searchParams, 'GET', true);

/*获取JIT工单预留列表*/
export const reqReservedOrderList= (searchParams) => ajax(BASE + '/jit/work-order-forecast/list', searchParams, 'GET', true);

/*JIT工单预留页面-导出excel*/
export const reqExportReservedExcel = (searchParams) => exportAjax(BASE + '/jit/work-order-forecast/exportWorkOrderForecastInfos', searchParams, 'GET', true, 'application/json', {responseType: 'blob',});

/*获取JIT查询（SAP)*/
export const reqJitList= (searchParams) => ajax(BASE + '/jit/getJitOrderInfos', searchParams, 'GET', true);

/*JIT查询（SAP)-导出excel*/
export const reqExportJitExcel = (searchParams) => exportAjax(BASE + '/jit/exportJitOrderInfos', searchParams, 'GET', true, 'application/json', {responseType: 'blob',});

/*jit收货确认管理*/
export const reqDeliveryConfirmationJitList= (searchParams) => ajax(BASE + '/jit/receiving/list', searchParams, 'GET', true);

/*jit收货确认提交*/
export const reqDeliveryConfirmationJitSubmit= (searchParams) => ajax(BASE + '/jit/receiving/confirm-receiving', searchParams, 'POST', true);

/*打印发料单*/
export const reqPrintMaterialIssuance = (searchParams) => exportAjax(BASE + '/mr/receiving/print-material-issuance', searchParams, 'GET', true, 'application/json', {responseType: 'blob',});

/*打印收货单*/
export const reqPrintReceiptOder = (searchParams) => exportAjax(BASE + '/mr/receiving/print-receiving-list', searchParams, 'GET', true, 'application/json', {responseType: 'blob',});

/*请求未到货*/
export const reqUndelivered= (searchParams) => ajax(BASE + '', searchParams, 'GET', true);

/*收货过账管理-MilkRun列表*/
export const reqDeliveryConfirmationMilkRunList= (searchParams) => ajax(BASE + '/mr/receiving/list', searchParams, 'GET', true);

/*MilkRun收货确认提交*/
export const reqDeliveryConfirmationMilkRunSubmit= (searchParams) => ajax(BASE + '/mr/receiving/confirm-receiving', searchParams, 'POST', true);

/*获取上传重排信息*/
export const reqUploadRearrangementMessage= (searchParams) => ajax(BASE + '/framework/getUploadRearrangementMessage', searchParams, 'GET', true);

/*需求信息查询列表*/
export const reqDemandInformationList= (searchParams) => ajax(BASE + '/mr/getMrReqInfos', searchParams, 'GET', true);

/*需求汇总列表*/
export const reqDemandSummaryList= (searchParams) => ajax(BASE + '/mr/getMrReqSummaryInfos', searchParams, 'GET', true);

/*需求信息查询导出*/
export const reqExportDemandInformationExcel = (searchParams) => exportAjax(BASE + '/mr/exportMrReqToExcel', searchParams, 'GET', true, 'application/json', {responseType: 'blob',});

/*需求汇总查询导出*/
export const reqExportDemandSummaryExcel = (searchParams) => exportAjax(BASE + '/mr/exportMrReqSummaryToExcel', searchParams, 'GET', true, 'application/json', {responseType: 'blob',});

/*手动匹配需求提交*/
export const reqMrRequirementMatchingSubmit= (searchParams) => ajax(BASE + '/mr/save-allocate-require-select-list', searchParams, 'POST', true);

/*获取MR需求匹配列表*/
export const reqMrRequirementMatchList= (searchParams) => ajax(BASE + '/mr/allocate-require-select-list', searchParams, 'GET', true);

/*jit生成送货单列表*/
export const reqJitOrderNormalList= (searchParams) => ajax(BASE + '/jit/jit-order-create/getJitOrderNormalList', searchParams, 'GET', true);

/*jit提交生成送货单 */
export const reqSubmitPutInDelivery= (searchParams) => ajax(BASE + '/jit/jit-order-create/createJitOrder', searchParams, 'POST', true);

/*导出jit送货单*/
export const reqExportJitDeliveryNotePDF = (searchParams) => exportAjax(BASE + '/jit/jit-order-create/exportJitDeliveryNotePDF', searchParams, 'GET', true, 'application/json', {responseType: 'blob',});

/*jit 生成送货单的导出*/
export const reqJitExportDeliveryExcel = (searchParams) => exportAjax(BASE + '/jit/jit-order-create/exportJitSummaryInfo', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});
/*jit 导入生成送货单*/
export const reqImportJitGenerateDeliveryExcel= (searchParams) => ajax(BASE + '/jit/jit-order-create/importCreateDeliveryNote', searchParams, 'POST', true);
/*milkRun生成送货单*/
export const reqMilkRunGenerateDeliveryList= (searchParams) => ajax(BASE + '/mr/mr-order-create/getMrOrderNormalList', searchParams, 'GET', true);

/*milkRun 生成送货单的导出*/
export const reqMilkRunExportDeliveryExcel = (searchParams) => exportAjax(BASE + '/mr/mr-order-create/exportDeliveryNote', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*手动添加需求*/
export const reqAddRequirementData= (searchParams) => ajax(BASE + '/mr/manual-save-require-summary', searchParams, 'POST', true);

/*继续匹配需求*/
export const reqContinueRequirementData= (searchParams) => ajax(BASE + '/mr/allocate-require-summary', searchParams, 'POST', true);

/*milkRun放入送货单提交*/
export const reqSubmitPutInMilkRunDelivery= (searchParams) => ajax(BASE + '/mr/mr-order-create/createMrOrder', searchParams, 'POST', true);

/*milkRun 导入生成送货单*/
export const reqImportMilkRunGenerateDeliveryExcel= (searchParams) => ajax(BASE + '/mr/mr-order-create/importCreateDeliveryNote', searchParams, 'POST', true);

/*删除milkRun 需求*/
export const reqDelSelectRequirements= (searchParams) => ajax(BASE + '/mr/delete-require-sum-select-list', searchParams, 'POST', true);

/*删除手动需求*/
export const reqDeleteAllocatePo= (searchParams) => ajax(BASE + '/mr/delete-allocate-po', searchParams, 'POST', true);

/*milkRun管理送货单列表*/
export const reqMilkRunManageDeliveryList= (searchParams) => ajax(BASE + '/mr/delivery-manager/queryMrDeliveryList', searchParams, 'GET', true);

/*打印milkRun管理外箱标贴*/
export const reqMilkRunPrintBoxLabel= (searchParams) => exportAjax(BASE + '/mr/delivery-manager/print-box-label', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*保存milkRun管理送货单*/
export const reqSaveMilkRunDelivery= (searchParams) => ajax(BASE + '/mr/delivery-manager/saveMrDeliveryList', searchParams, 'POST', true);

/*打印milkRun送货单pdf*/
export const reqMilkRunPrintDeliveryPdf= (searchParams) => exportAjax(BASE + '/mr/delivery-manager/print-delivery', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*取消milkRun管理送货单*/
export const reqCancelMilkRunDelivery= (searchParams) => ajax(BASE + '/mr/delivery-manager/cancel-delivery-nos', searchParams, 'POST', true);

/*导出milkRun excel*/
export const reqMilkRunExportExcel= (searchParams) => exportAjax(BASE + '/mr/delivery-manager/exportDeliveryExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*判断token是否过期*/
export const reqCheckToken= (searchParams) => ajax(BASE + '/user/checkToken', searchParams, 'GET', false);

/*按需拉料收货确认列表*/
export const reqReceiptPostingPullDemandList= (searchParams) => ajax(BASE + '/sa/receiving/getSaReceivingInfos', searchParams, 'GET', true);

/*按需拉料生成送货单列表*/
export const reqPullMaterialGenerateDeliveryList= (searchParams) => ajax(BASE + '/sa/create-delivery/getPoCodeInfos', searchParams, 'GET', true);

/*导出关务香港送货单*/
export const reqExportHongKongDeliveryOrder= (searchParams) => exportAjax(BASE + '/order/exportDeliveryCustomsInfos', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

/*拉料需求生成送货单导出*/
export const reqPullMaterialGeneratePageExportDeliveryExcel= (searchParams) => exportAjax(BASE + '/sa/create-delivery/exportDeliveryNote', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*提交按需拉料生成送货单*/
export const reqSubmitPutInPullMaterialDelivery= (searchParams) => ajax(BASE + '/sa/create-delivery/createSaOrder', searchParams, 'POST', true);

/*拉需拉料确认收货*/
export const reqDeliveryConfirmationPullMaterialSubmit= (searchParams) => ajax(BASE + '/sa/receiving/confirm-receiving', searchParams, 'POST', true);

/*上传按需拉料排程*/
export const reqImportPullMaterialGenerateDeliveryExcel= (searchParams) => ajax(BASE + '/sa/create-delivery/importCreateDeliveryNote', searchParams, 'POST', true);

/*按需拉料管理送货单列表*/
export const reqPullMaterialManageDeliveryList= (searchParams) => ajax(BASE + '/sa/management-delivery/queryDeliveryList', searchParams, 'GET', true);

/*提交按需拉料管理送货单*/
export const reqPullMaterialManageDelivery= (searchParams) => ajax(BASE + '/sa/management-delivery/modifyDelivery', searchParams, 'POST', true);

/*提交按需拉料管理导入送货单*/
export const reqImportPullMaterialManageDeliveryExcel= (searchParams) => ajax(BASE + '/sa/management-delivery/importModifyDelivery', searchParams, 'POST', true);

/*按需拉料获取时间表头*/
export const reqGetSaTitleTimes= (searchParams) => ajax(BASE + '/sa/get-sa-title-times', searchParams, 'GET', false);

/*按需拉料回复列表*/
export const reqPullMaterialSearchReplyList= (searchParams) => ajax(BASE + '/sa/get-sa-require-reply-list', searchParams, 'POST', true);

/*供应商回复提交*/
export const reqPullMaterialReplySubmit= (searchParams) => ajax(BASE + '/sa/save-sa-require-reply-list', searchParams, 'POST', true);

/*按需拉料取消送货单*/
export const reqPullMaterialCancelDelivery= (searchParams) => ajax(BASE + '/sa/management-delivery/cancelDelivery', searchParams, 'POST', true);

/*获取MC审核列表*/
export const reqGetSaAuditList= (searchParams) => ajax(BASE + '/sa/get-sa-audit-list', searchParams, 'POST', true);
export const reqGetSaMcAuditList= (searchParams) => ajax(BASE + '/sa/get-sa-mc-audit-list', searchParams, 'POST', true);

/*获取采购审核列表*/
export const reqGetPurchaseAuditList= (searchParams) => ajax(BASE + '/sa/get-sa-audit-list', searchParams, 'POST', true);

/*采购审核通过与不通过*/
export const reqPurchaseAuditPassAndUnPassSubmit= (searchParams) => ajax(BASE + '/sa/save-sa-purchase-audit', searchParams, 'POST', true);

/*MC审核通过与不通过*/
export const reqMcAuditPassAndUnPassSubmit= (searchParams) => ajax(BASE + '/sa/save-sa-mc-audit', searchParams, 'POST', true);

/*MC删除*/
export const reqDeleteAudit= (searchParams) => ajax(BASE + '/sa/delete-sa-require-list', searchParams, 'POST', true);

/*打印按需拉料管理送货单PDF*/
export const reqPullMaterialManagePagePrintDeliveryPdf= (searchParams) => exportAjax(BASE + '/sa/management-delivery/exportSaDeliveryNotePDF', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

/*导出按需拉料管理送货单excel*/
export const reqPullMaterialManagePageExportDeliveryExcel= (searchParams) => exportAjax(BASE + '/sa/management-delivery/exportDeliveryList', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*接需排程列表*/
export const reqPullMaterialDemandSchedulingList= (searchParams) => ajax(BASE + '/sa/get-sa-require-list', searchParams, 'GET', true);

/*接需排程导出excel*/
export const reqPullDemandSchedulingExportExcel= (searchParams) => exportAjax(BASE + '/sa/export-sa-require', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*导出po放入送货单的订单*/
export const reqExportGenerateDeliveryPutOrderExcel= (searchParams) => exportAjax(BASE + '/order/orderManagement/productionPurchasing/exportSelectDeliveryNote', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

/*用户解锁*/
export const reqUnLock= (searchParams) => ajax(BASE + '/supplier/unLockUser', searchParams, 'POST', true);

/*上品清单&查询列表*/
export const reqCategoryAndQueryList= (searchParams) => ajax(BASE + '/preparegoods/selectByTCategoryListQO', searchParams, 'POST', true);

/*添加/修改分类*/
export const reqAddOrEditCategory= (searchParams) => ajax(BASE + '/preparegoods/saveCategoryInfo', searchParams, 'POST', true);

/*删除分类*/
export const reqDelCategory= (searchParams) => ajax(BASE + '/preparegoods/deleteCategoryInfo', searchParams, 'POST', true);

/*导出分类*/
export const reqExportCategory= (searchParams) => exportAjax(BASE + '/preparegoods/exportCategoryList', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

/*导入上品类*/
export const reqImportCategory= (searchParams) => ajax(BASE + '/preparegoods/importCategoryList', searchParams, 'POST', true);

/*备货综合报表列表*/
export const reqSearchStockComprehensiveReportList= (searchParams) => ajax(BASE + '/preparegoods/selectPrepareGoodsInfo', searchParams, 'POST', true);

/* 备货综合报表--获取版本年月 */
export const getStockReportYeat= (searchParams) => ajax(BASE + '/forecast/fore/getCompYearMonthVersion', searchParams, 'POST', true);

/*导出备货综合报表*/
export const reqExportReport= (searchParams) => exportAjax(BASE + '/preparegoods/exportPrepareGoodList', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

/*导入按需拉料报表*/
export const reqImportPullDemandSchedulingReport = (searchParams) => ajax(BASE + '/sa/importSaReqReplyList', searchParams, 'POST', true);

/*打印按需拉料外箱标贴*/
export const reqPullMaterialManagePrintOutBoxLabelPdf  = (searchParams) => exportAjax(BASE + '/sa/management-delivery/print-box-label', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

/*edi生成送货单列表*/
export const reqEdiDeliveryOrderList = (searchParams) => ajax(BASE + '/edi/ediDeliveryReport', searchParams, 'GET', true);

/*edi生成快递单列表*/
export const reqEdiDeliveryReportList = (searchParams) => ajax(BASE + '/edi/ediDeliveryShipTrackNumberReport', searchParams, 'GET', true);

/*采购送货单管理列表*/
export const reqPurchaseDeliveryManageList = (searchParams) => ajax(BASE + '/order/getDeliveryPurchaseInfos', searchParams, 'GET', true);

/*采购送货单管理-采购确认*/
export const reqPurchaseDeliveryConfirm = (searchParams) => ajax(BASE + '/order/confirmByPurchase', searchParams, 'POST', true);

/*采购送货单管理-采购取消*/
export const reqPurchaseDeliveryCancelConfirm = (searchParams) => ajax(BASE + '/order/cancelConfirmByPurchase', searchParams, 'POST', true);

/*物料与供应商型号列表*/
export const reqMaterialAndSupplierModelList = (searchParams) => ajax(BASE + '/material/getSupplierMaterialList', searchParams, 'GET', true);

/*物料与供应商型号-导入*/
export const reqImportMaterialAndSupplierModelExcel= (searchParams) => ajax(BASE + '/material/importSupplierMaterialList', searchParams, 'POST', true);

/*物料与供应商型号-导出*/
export const reqExportMaterialAndSupplierModelTemplate = (searchParams) => ajax(BASE + '/material/exportSupplierMaterialTemplate', searchParams, 'GET', true);

/*物料与供应商型号-删除*/
export const reqDelMaterialAndSupplierModel= (searchParams) => ajax(BASE + '/material/deleteSupplierMaterialList', searchParams, 'POST', true);

/*供应商库列表*/
export const reqSupplierInventoryList = (searchParams) => ajax(BASE + '/inventorypricing/getInventoryPricingInfo', searchParams, 'POST', true);

/*供应商库存-导出*/
export const reqExportSupplierInventoryList = (searchParams) => exportAjax(BASE + '/inventorypricing/exportInventoryPricingInfo', searchParams, 'POST', true, 'application/json', {responseType: 'blob',});

/*ti需求匹配报表*/
export const reqTiSupplyDemandMatchReportList = (searchParams) => ajax(BASE + '/inventorypricing/getSupplyDemandList', searchParams, 'GET', true);

/*ti需求匹配报表-手工同步*/
export const reqTiManualSynchronization = (searchParams) => ajax(BASE + '/inventorypricing/refreshSupplyDemandList', searchParams, 'GET', true);

/*ti需求匹配报表-导出*/
export const reqExportMatchingReport = (searchParams) => exportAjax(BASE + '/inventorypricing/exportSupplyDemandList', searchParams, 'POST', true, 'application/json', {responseType: 'blob',});

/*订单管理-导出*/
export const reqExportOrderManagement = (searchParams) => exportAjax(BASE + '/rdp/exportOrderExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/*订单管理-查询订单详情操作日志*/
export const reqGetOrderDetailOperateLogs = (searchParams) => ajax(BASE + '/rdp/getOrderDetailOperateLogs', searchParams, 'GET', false);

/*订单管理-生成送货单列表查询*/
export const reqGetOrderList= (searchParams) => ajax(BASE + '/rdp/getOrderList', searchParams, 'GET', true);

/*订单管理-打印PDF*/
export const reqPrintOrderPdf = (searchParams) => exportAjax(BASE + '/rdp/printOrderPdf', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/*订单管理- 回复交期*/
export const reqReplyOrderList = (searchParams) => ajax(BASE + '/rdp/replyOrderList', searchParams, 'POST', true);

/*订单管理- 采购确认*/
export const confirmOrderList = (searchParams) => ajax(BASE + '/rdp/confirmOrderList', searchParams, 'POST', true);

/*生成送货单- 生成送货单*/
export const reqCreateDeliveryOrder = (searchParams) => ajax(BASE + '/rdp/createDeliveryOrder', searchParams, 'POST', true);

/*生成送货单- 导出*/
export const exportGenerateDeliveryOrderExcel = (searchParams) => exportAjax(BASE + '/rdp/exportGenerateDeliveryOrderExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/*生成送货单- 生成送货单列表查询*/
export const reqGetGenerateDeliveryNote = (searchParams) => ajax(BASE + '/rdp/getDeliveryOrderList', searchParams, 'GET', true);

/*送货单管理- 取消送货单*/
// export const reqCancelDeliveryOrder = (searchParams) => ajax(BASE + '/rdp/cancelDeliveryOrder', searchParams, 'POST', true);

/*送货单管理- 按送货单号取消送货单*/
export const reqCancelDeliveryByNumber= (params) => ajax(BASE + '/rdp/cancelDeliveryOrder', {}, 'POST', true, 'application/json', {params: params});

/*送货单管理- 导出送货单PDF*/
export const reqExportDeliveryNotePDF = (searchParams) => exportAjax(BASE + '/rdp/exportDeliveryNotePDF', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/*送货单管理- 导出*/
export const reqExportDeliveryOrderExcel = (searchParams) => exportAjax(BASE + '/rdp/exportDeliveryOrderExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/*送货单管理- 送货单列表查询*/
export const reqGetDeliveryNoteList = (searchParams) => ajax(BASE + '/rdp/getDeliveryNoteList', searchParams, 'GET', true);

/*送货单管理- 修改送货单信息*/
export const reqModifyDeliveryOrder = (searchParams) => ajax(BASE + '/rdp/modifyDeliveryOrder', searchParams, 'POST', true);

/*退货管理- 导出Excel*/
export const reqExportReceiveOrder = (searchParams) => exportAjax(BASE + '/rdp/exportReceiveOrder', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/*退货管理- 送货单列表查询*/
export const reqGetReceiveOrderList = (searchParams) => ajax(BASE + '/rdp/getReceiveOrderList', searchParams, 'GET', true);

/*退货管理- 打印PDF*/
export const reqPrintReceiveOrderPDF = (searchParams) => exportAjax(BASE + '/rdp/printReceiveOrderPDF', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/*BPO查询-列表分页查询*/
export const reqGetBpoOrderList = (searchParams) => ajax(BASE + '/bpo/getBpoOrderList', searchParams, 'POST', true);

/*BPO查询-导出Excel*/
export const reqExportBpoOrderExcel = (searchParams) => exportAjax(BASE + '/bpo/exportBpoOrderExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

/*BPO查询-详情查询*/
export const reqGetBpoDetails = (searchParams) => ajax(BASE + '/bpo/getBpoDetails', searchParams, 'POST', true);

/*BPO可供回复-发布BPO*/
export const reqPublishBpo = (searchParams) => ajax(BASE + '/bpo/publishBpo', searchParams, 'POST', true);

/*BPO可供回复-列表分页查询*/
export const reqGetReplyOrderList = (searchParams) => ajax(BASE + '/bpo/getReplyOrderList', searchParams, 'POST', true);

/*BPO可供回复-批量上传提交*/
export const reqImportBpoReplyList = (searchParams) => ajax(BASE + '/bpo/importBpoReplyList', searchParams, 'POST', true);

/*BPO可供回复-采购确认*/
export const reqPurchaseConfirm = (searchParams) => ajax(BASE + '/bpo/purchaseConfirm', searchParams, 'POST', true);

/*BPO可供回复-采购驳回*/
export const reqPurchaseReject = (searchParams) => ajax(BASE + '/bpo/purchaseReject', searchParams, 'POST', true);

/*BPO可供回复-引用FCST库存*/
export const reqUpdateForecastQuoteBySupplierCode = (searchParams) => ajax(BASE + '/bpo/updateForecastQuoteBySupplierCode', searchParams, 'POST', true);

/*BPO可供回复-供应商回复*/
export const reqSupplierReply = (searchParams) => ajax(BASE + '/bpo/supplierReply', searchParams, 'POST', true);

/*BPO可供回复-导出Excel*/
export const reqExportReplyOrderExcel = (searchParams) => exportAjax(BASE + '/bpo/exportReplyOrderExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

/*BPO可供综合报表-列表分页查询*/
export const reqGetReplyReportOrderList = (searchParams) => ajax(BASE + '/bpo/getReplyReportOrderList', searchParams, 'POST', true);

/*BPO可供综合报表-导出Excel*/
export const reqExportReplyReportOrderExcel = (searchParams) => exportAjax(BASE + '/bpo/exportReplyReportOrderExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

/*FCST可供回复 - 导出FCST可供回复*/
export const exportFcstAvailableReplyList = (searchParams) => exportAjax(BASE + '/forecast/exportFcstAvailableReplyList', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/*FCST可供回复 - 查询FCST可供*/
export const getFcstAvailableReplyInfos = (searchParams) => ajax(BASE + '/forecast/getFcstAvailableReplyInfos', searchParams, 'GET', true)

/*FCST可供回复 - 保存可供回复数据*/
export const saveFcstAvailableReplyList = (searchParams) => ajax(BASE + '/forecast/saveFcstAvailableReplyList', searchParams, 'POST', true);

/* 国际FCST-信息查询 */
export const InformationQuery = (searchParams) => ajax(BASE + '/forecast/fore/internationFcstPageList', searchParams, 'POST', true);

/* 国际FCST-查看回复 */
export const CheckResponses = (searchParams) => ajax(BASE + '/forecast/fore/pageIntlReplyRecordList', searchParams, 'POST', true);

/* FCST重复接口 */
export const GetYear = (searchParams) => ajax(BASE + '/forecast/fore/getYearMonth', searchParams, 'POST', true);

/* FCST查询导出 */
export const exportFCSTexcel = (searchParams) => exportAjax(BASE + '/forecast/fore/exportInternationExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* FCST回复库存列表 */
export const supplyList = (searchParams) => ajax(BASE + '/forecast/fore/replyInternationPageList', searchParams, 'POST', true);

/* FCST回复库存列表-提交 */
export const supplyListRowSubmit = (searchParams) => ajax(BASE + '/forecast/fore/replyInternationSubmit', searchParams, 'POST', true);

/* FCST回复库存列表-查看回复 */
export const supplyLookReturn = (searchParams) => ajax(BASE + '/forecast/fore/pageIntlReplyRecordList', searchParams, 'POST', true);

/* FCST回复库存-导出 */
export const exportSupply = (searchParams) => exportAjax(BASE + '/forecast/fore/exportReplyInternationExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* FCST回复库存-导出错误信息 */
export const exportErrorSupply = (searchParams) => exportAjax(BASE + '/forecast/fore/exportInternationErrorExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* FCST回复库存-导入送货单 */
export const reqImportSupply = (searchParams) => ajax(BASE + '/forecast/fore/importReplyInternationExcel', searchParams, 'POST', true);

/* FCST回复库存-获取最新年月--当获取的当前数据调用这个接口 */
export const getLastYear = (searchParams) => ajax(BASE + '/forecast/fore/getLastYearMonthVersion', searchParams, 'POST', true);

/* FCST信息查询-获取信息查询列表 */
export const getMessageList = (searchParams) => ajax(BASE + '/forecast/fore/pageList', searchParams, 'POST', true);

/* FCST信息查询-导入试剂FCST */
export const reqImportReagent = (searchParams) => ajax(BASE + '/forecast/fore/importReagent', searchParams, 'POST', true);

/* FCST信息查询-导出模版 */
export const reqExportReagent = (searchParams) => exportAjax(BASE + '/forecast/fore/exportReagentTemplate', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* FCST信息查询-导出 */
export const exportReagent = (searchParams) => exportAjax(BASE + '/forecast/fore/exportExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* FCST回复库存-获取最新年月--当获取的原始数据调用这个接口 */
export const getLastYearForOriginal = (searchParams) => ajax(BASE + '/forecast/fore/getOriginalYearMonthVersion', searchParams, 'POST', true);

/* FCST信息查询-最终确认调用得年月版本接口 */
export const getAgoList = (searchParams) => ajax(BASE + '/forecast/fore/getUnionYearMonthVersion', searchParams, 'POST', true);

/* FCST回复库存-获取回复列表 */
export const getInventoryList = (searchParams) => ajax(BASE + '/forecast/fore/stockPageList', searchParams, 'POST', true);

/* FCST回复库存-获取修改列表 */
export const getInventoryModifyList = (searchParams) => ajax(BASE + '/forecast/fore/stockModifyPageList', searchParams, 'POST', true);

/* FCST回复库存-获取确认列表 */
export const getInventoryConfirmList = (searchParams) => ajax(BASE + '/forecast/fore/stockConfirmPageList', searchParams, 'POST', true);

/* FCST回复库存-提交功能 */
export const getAndreplySubmit = (searchParams) => ajax(BASE + '/forecast/fore/replySubmit', searchParams, 'POST', true);

/* FCST回复库存-备注功能 */
export const remarkUse = (searchParams) => ajax(BASE + '/forecast/fore/remarkSubmit', searchParams, 'POST', true);

/* FCST回复库存-是否是授权商 */
export const isAuthorize = (searchParams) => ajax(BASE + '/forecast/fore/getSupplierAuthorize', searchParams, 'POST', true);

/* FCST回复库存-导出 */
export const exportInStock = (searchParams) => exportAjax(BASE + '/forecast/fore/exportModifyExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* FCST回复库存-导出 */
export const exportInStockFcstOut = (searchParams) => exportAjax(BASE + '/forecast/fore/supplierExportTemplate', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* FCST回复库存-导出错误信息 */
export const exportFCSTError = (searchParams) => exportAjax(BASE + '/forecast/fore/replyExportError', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* FCST回复库存-查询以及可供回复列表-新增FCST外 */
export const addFcstOut = (searchParams) => ajax(BASE + '/forecast/fore/supplierAdd', searchParams, 'POST', true);

/* FCST回复库存-查询以及可供回复列表-导入可供库存 */
export const importantReply = (searchParams) => ajax(BASE + '/forecast/fore/replyImport', searchParams, 'POST', true);

/* FCST回复库存-查询以及可供回复列表-导入FCST外 */
export const importantFCSTOut = (searchParams) => ajax(BASE + '/forecast/fore/supplierImport', searchParams, 'POST', true);

/* FCST回复库存-导出下载模板 */
export const exportModal = (searchParams) => exportAjax(BASE + '/forecast/fore/replyExport', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* FCST修改可供库存-导出 */
export const changeExport = (searchParams) => exportAjax(BASE + '/forecast/fore/exportModifyExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* FCST确认可供库存-确认通过/驳回 */
export const surePass = (searchParams) => ajax(BASE + '/forecast/fore/confirmReply', searchParams, 'POST');

/* 协议信息审核列表 */
export const agreementInformationList = (searchParams) => ajax(BASE + '/forecast/fore/protocolPageList', searchParams, 'POST');

/* 协议信息审核-通过 */
export const agreementInformationPass = (searchParams) => ajax(BASE + '/forecast/fore/protocolCheckPass', searchParams, 'POST');

/* 协议信息审核-驳回 */
export const agreementInformationReject = (searchParams) => ajax(BASE + '/forecast/fore/protocolCheckReject', searchParams, 'POST');

/* 协议信息维护-导出错误信息 */
export const exportAuditError = (searchParams) => exportAjax(BASE + '/forecast/fore/exportErrorProtocol', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* 协议信息维护-导出信息 */
export const exportAudit = (searchParams) => exportAjax(BASE + '/forecast/fore/exportProtocol', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* 协议信息维护-导出模板 */
export const exportAuditModul = (searchParams) => exportAjax(BASE + '/forecast/fore/protocolExportTemplate', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* 协议信息维护-导入 */
export const importantAudit = (searchParams) => ajax(BASE + '/forecast/fore/protocolImport', searchParams, 'POST', true);

/* 协议信息维护-导入 */
export const deleteAudit = (searchParams) => ajax(BASE + '/forecast/fore/protocolDelete', searchParams, 'POST', true);

/* 协议信息维护-新增 */
export const addAudit = (searchParams) => ajax(BASE + '/forecast/fore/protocolAdd', searchParams, 'POST');

/* 协议信息维护-编辑 */
export const editAudit = (searchParams) => ajax(BASE + '/forecast/fore/protocolEdit', searchParams, 'POST');

/* 备货综合报表-备货达成-列表 */
export const stockingList = (searchParams) => ajax(BASE + '/forecast/fore/prepareGoodsPageList', searchParams, 'POST');

/* 备货综合报表-备货达成-导出信息 */
export const stockingExport = (searchParams) => exportAjax(BASE + '/forecast/fore/exportPrepareGoodsReport', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* 备货综合报表-安全库存-列表 */
export const safetlyList = (searchParams) => ajax(BASE + '/forecast/fore/safetyStockAgreementPageList', searchParams, 'POST');

/* 备货综合报表-安全库存-导出信息 */
export const safetlyExport = (searchParams) => exportAjax(BASE + '/forecast/fore/exportSafetyStockAgreementReport', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* 半成品&原材料-回复库存-信息列表 */
export const rawMaterialList = (searchParams) => ajax(BASE + '/forecast/materials/stockReplyPageList', searchParams, 'POST');

/* 半成品&原材料-回复库存-提交 */
export const rawMaterialSubmit = (searchParams) => ajax(BASE + '/forecast/materials/replySubmit', searchParams, 'POST');

/* 备货综合报表-安全库存-导出信息 */
export const rawMaterialImportant = (searchParams) => exportAjax(BASE + '/forecast/materials/exportReplyMaterialsTemplate', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* 半成品&原材料-回复库存-导入 */
export const rawMaterialExport = (searchParams) => ajax(BASE + '/forecast/materials/importReplyMaterials', searchParams, 'POST');

/* 半成品&原材料-信息查询-列表 */
export const rawMaterialInformationList = (searchParams) => ajax(BASE + '/forecast/materials/replyPageList', searchParams, 'POST');

/* 半成品&原材料-信息查询-导出 */
export const rawMaterialInformationExport = (searchParams) => exportAjax(BASE + '/forecast/materials/replyExport', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* 半成品&原材料-信息查询-获取最新年月日 */
export const getRawMaterialInformationData = (searchParams) => ajax(BASE + '/forecast/materials/getLastReplyReleaseDate', searchParams, 'POST');

/* 半成品&原材料-物料协议信息维护-列表 */
export const rawMaterialGoodInformationList = (searchParams) => ajax(BASE + '/forecast/materials/maintainPageList', searchParams, 'POST');

/* 半成品&原材料-物料协议信息维护-导出 */
export const rawMaterialGoodInformationExport = (searchParams) => exportAjax(BASE + '/forecast/materials/exportMaintainMaterials', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* 半成品&原材料-物料协议信息维护-导出模板 */
export const rawMaterialGoodInformationExportModal = (searchParams) => exportAjax(BASE + '/forecast/materials/exportMaintainMaterials', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* 半成品&原材料-物料协议信息维护-导出模板 */
export const rawMaterialGoodInformationExportTemplate = (searchParams) => exportAjax(BASE + '/forecast/materials/exportMaintainMaterialsTemplate', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

/* 半成品&原材料-物料协议信息维护-导入 */
export const rawMaterialGoodInformationImportant = (searchParams) => ajax(BASE + '/forecast/materials/importMaintainMaterials', searchParams, 'POST');

/* 半成品&原材料-物料协议信息维护-删除 */
export const rawMaterialGoodInformationDelete = (searchParams) => ajax(BASE + '/forecast/materials/maintainDelete', searchParams, 'POST');

/* 半成品&原材料-物料协议信息维护-审核通过 */
export const rawMaterialGoodInformationPass = (searchParams) => ajax(BASE + '/forecast/materials/maintainCheck', searchParams, 'POST');

/* 半成品&原材料-通用查看回复接口 */
export const rawMaterialLookReply = (searchParams) => ajax(BASE + '/forecast/materials/queryReplyPageList', searchParams, 'POST');

/* 半成品&原材料-物料协议信息维护-发布 */
export const rawMaterialGoodInformationRelease = (searchParams) => ajax(BASE + '/forecast/materials/maintainRelease', searchParams, 'POST');

/* 毛需求-查询列表 */
export const getGrossList = (searchParams) => ajax(BASE + '/forecast/fore/supplierPageList', searchParams, 'POST', true);

/* 毛需求-删除供应商 */
export const deleteSupplier = (searchParams) => ajax(BASE + '/forecast/fore/deleteSupplier', searchParams, 'POST', true);

/* 毛需求-增加供应商 */
export const addSupplierFn = (searchParams) => ajax(BASE + '/forecast/fore/addSupplier', searchParams, 'POST', true);

/* 毛需求-编辑供应商 */
export const editSupplierFn = (searchParams) => ajax(BASE + '/forecast/fore/editSupplier', searchParams, 'POST', true);

/* 毛需求-编辑供应商 */
export const getSupplierListFn = (searchParams) => exportAjax(BASE + '/supplier/getSupplier', searchParams, 'POST', true);

/* FCST管理-手动添加节假日 */
export const addHoliday = (searchParams) => ajax(BASE + '/forecast/fore/editHoliday', searchParams, 'POST', true);

/* FCST管理-测试要求增加接口1 */
export const addHoliday1 = (searchParams) => ajax(BASE + '/forecast/fore/handleSupplier', searchParams, 'POST', true);

/* FCST管理-测试要求增加接口1 */
export const addHoliday2 = (searchParams) => ajax(BASE + '/forecast/fore/handleStockAgreement', searchParams, 'POST', true);

/* FCST管理-测试要求增加接口1 */
export const addHoliday3 = (searchParams) => ajax(BASE + '/forecast/fore/taskSyncStockA', searchParams, 'POST', true);

/* FCST管理-测试要求增加接口1 */
export const addHoliday4 = (searchParams) => ajax(BASE + '/forecast/materials/calStockUpAchievingRate', searchParams, 'POST', true);

/* /estimation/getPageList 暂估列表-查询暂估分页数据 */
export const getPageList = (searchParams) => ajax(BASE + '/bill/estimation/getPageList', {...searchParams,fromPageTag:'BILL_PAGE'}, 'POST', true);

// /estimation/delMaxReturnCycleBySupllierCode 删除供应商处理退货最大周期
export const delMaxReturnCycleBySupllierCode = (searchParams) => ajax(BASE + '/bill/estimation/delMaxReturnCycleBySupllierCode', searchParams, 'POST', true);

// /estimation/getSupplierMaxReturnCycleList 查询供应商处理退货最大周期列表
export const getSupplierMaxReturnCycleList = (searchParams) => ajax(BASE + '/bill/estimation/getSupplierMaxReturnCycleList', searchParams, 'POST', true);

// /estimation/importSupplierMaxReturnCycleExecl 导入供应商处理退货最大周期
export const importSupplierMaxReturnCycleExecl = (searchParams) => ajax(BASE + '/bill/estimation/importSupplierMaxReturnCycleExecl', searchParams, 'POST', true);

// /estimation/updateMaxReturnCycleBySupllierCode 更新供应商处理退货最大周期
export const updateMaxReturnCycleBySupllierCode = (searchParams) => ajax(BASE + '/bill/estimation/updateMaxReturnCycleBySupllierCode', searchParams, 'POST', true);

// /estimation/delSyncSupplierByCode 删除需要进行同步暂估的供应商
export const delSyncSupplierByCode = (searchParams) => ajax(BASE + '/bill/estimation/delSyncSupplierByCode', searchParams, 'POST', true);

// /estimation/getSyncSupplierPage 查询需要进行同步暂估的供应商分页列表
export const getSyncSupplierPage = (searchParams) => ajax(BASE + '/bill/estimation/getSyncSupplierPage', searchParams, 'POST', true);

// /estimation/importSyncSupplierExecl 导入需要进行同步暂估的供应商
export const importSyncSupplierExecl = (searchParams) => ajax(BASE + '/bill/estimation/importSyncSupplierExecl', searchParams, 'POST', true);

// /estimation/downloadSupplierCycleExeclTemplate 下载供应商处理退货最大周期模板
export const downloadSupplierCycleExeclTemplate = (searchParams) => exportAjax(BASE + '/bill/estimation/downloadSupplierCycleExeclTemplate', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

// /estimation/downloadSyncSupplierExeclTemplate 下载需要进行同步暂估的供应商模板
export const downloadSyncSupplierExeclTemplate = (searchParams) => exportAjax(BASE + '/bill/estimation/downloadSyncSupplierExeclTemplate', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

// // /estimation/importSupplierMaxReturnCycleExecl 导入供应商处理退货最大周期
// export const importSupplierMaxReturnCycleExecl = (searchParams) => ajax(BASE + '/bill/estimation/importSupplierMaxReturnCycleExecl', searchParams, 'POST', true);

// /estimation/exportSupplierMaxReturnCycleList 导出供应商处理退货最大周期列表
export const exportSupplierMaxReturnCycleList = (searchParams) => exportAjax(BASE + '/bill/estimation/exportSupplierMaxReturnCycleList', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /estimation/syncMaterialCategoryHandler 同步物料品类
export const syncMaterialCategoryHandler = (searchParams) => ajax(BASE + '/bill/estimation/syncMaterialCategoryHandler', searchParams, 'GET', true);

// /estimation/getCategoryTree 获取物料品类数据
export const getCategoryTree = (searchParams) => ajax(BASE + '/bill/estimation/getCategoryTree', searchParams, 'POST', true);

// /estimation/getEstInfo 暂估列表-查询暂估弹窗详情
export const getEstInfo = (searchParams) => ajax(BASE + '/bill/estimation/getEstInfo', searchParams, 'POST', true);

// /estimation/exportEstExecl 暂估列表-暂估列表导出
export const reqExportEstExecl= (searchParams) => exportAjax(BASE + '/bill/estimation/exportEstExecl', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /estimation/exportQtEstExecl 导出(质检中)execl
export const exportQtEstExecl= (searchParams) => exportAjax(BASE + '/bill/estimation/exportQtEstExecl', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /estimation/createDiffPriceOrder 暂估列表-生成价差单
export const createDiffPriceOrder = (searchParams) => ajax(BASE + '/bill/estimation/createDiffPriceOrder', searchParams, 'POST', true);

// /estimation/creatOrderNo 创建单号
export const creatOrderNo = (searchParams) => ajax(BASE + '/bill/estimation/creatOrderNo', {}, 'POST', true, 'application/json',{...searchParams});

// /estimation/checkCreateDiffPriceOrder 暂估列表-校验生成价差单
export const checkCreateDiffPriceOrder = (searchParams) => ajax(BASE + '/bill/estimation/checkCreateDiffPriceOrder', searchParams, 'POST', true);

// /billOrder/createBillOrder 生成对账单
export const createBillOrder = (searchParams) => ajax(BASE + '/bill/billOrder/createBillOrder', searchParams, 'POST', true);

/* /estimation/getEstClearPageList 暂估清理-分页列表 */
export const getEstClearPageList = (searchParams) => ajax(BASE + '/bill/estimation/getEstClearPageList', searchParams, 'POST', true);

// /estimation/clearEstExport 暂估清理-导出
export const clearEstExport= (searchParams) => exportAjax(BASE + '/bill/estimation/clearEstExport', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

// /estimation/clearEstUploadFile 暂估清理-上传附件
export const clearEstUploadFile= (searchParams) => exportAjax(BASE + '/bill/estimation/clearEstUploadFile', searchParams, 'POST', true);

// /estimation/estClearAffirmOrReject 暂估清理确认或驳回
export const estClearAffirmOrReject = (searchParams) => ajax(BASE + '/bill/estimation/estClearAffirmOrReject', searchParams, 'POST', true);

// /estimation/estClearAffirmOrReject 暂估清理 - 供应商 - 审批
export const estSupplierApproval = (params) => ajax(BASE + '/bill/estimation/supplierApproval', {}, 'POST', true, '', {params: params});

// /estimation/estClearAffirmOrReject 暂估清理 - 经理 - 审批
export const estManagerApproval = (params) => ajax(BASE + '/bill/estimation/managerApproval', {}, 'POST', true, '', {params: params});

// /estimation/estClearAffirmOrReject 暂估清理 - 财务 - 审批
export const estFinanceApproval= (params) => ajax(BASE + '/bill/estimation/financeApproval', {}, 'POST', true, '', {params: params});

// /estimation/estClearAffirmOrReject 暂估清理 - 会计 - 审批
export const estAccountantApproval = (params) => ajax(BASE + '/bill/estimation/accountantApproval', {}, 'POST', true, '', {params: params});

// /estimation/estClearAffirmOrReject 暂估清理 - 驳回
export const estClearReject = (params) => ajax(BASE + '/bill/estimation/clearReject', {}, 'POST', true, '', {params: params});

// /estimation/estClearAffirmOrReject 暂估清理 - 废弃
export const estClearAbandon = (params) => ajax(BASE + '/bill/estimation/clearAbandon', {}, 'POST', true, '', {params: params});

// /estimation/clearEstSave 发起暂估清理-暂存
export const clearEstSave = (searchParams) => ajax(BASE + '/bill/estimation/clearEstSave', searchParams, 'POST', true);

// /billOrderFile/selectListByBillNo 查询文件详情
export const selectListByBillNo = (params) => ajax(BASE + '/bill/billOrderFile/selectListByBillNo', {}, 'POST', true, 'application/json', {params: params});

// /billOrderFile/downloadFile 下载文件
export const billOrderFileDownloadFile = (searchParams) => exportAjax(BASE + '/bill/billOrderFile/downloadFile', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /bill/statementAccount/invoiceDownload 下载文件
export const invoiceDownload = (searchParams) => exportAjax1(BASE + '/bill/statementAccount/invoiceDownload', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

/* /estimation/getQtPageList 查询暂估(质检中)分页数据 */
export const getQtPageList = (searchParams) => ajax(BASE + '/bill/estimation/getQtPageList', searchParams, 'POST', true);

/* /estimation/checkClearEstSave 校验暂估清理是否是同一类型 */
export const checkClearEstSave = (searchParams) => ajax(BASE + '/bill/estimation/checkClearEstSave', searchParams, 'POST', true);

/* /billEstDiff/deleteByIdStr 价差单- 根据编号删除价差单 */
export const deleteByIdStr = (searchParams) => ajax(BASE + '/bill/billEstDiff/deleteByIdStr', {}, 'POST', true, '', searchParams);

/* /billEstDiff/diffAffirmOrReject 价差单-确认或驳回 */
export const diffAffirmOrReject = (searchParams) => ajax(BASE + '/bill/billEstDiff/diffAffirmOrReject', searchParams, 'POST', true);

/* /billEstDiff/diffAffirmOrReject 价差单-部门经理-审批 */
export const diffManagerAudit = (params) => ajax(BASE + '/bill/billEstDiff/diffManagerAudit', {}, 'POST', true, '', {params: params});

/* /billEstDiff/diffAffirmOrReject 价差单-废弃 */
export const diffAbandon = (params) => ajax(BASE + '/bill/billEstDiff/diffAbandon', {}, 'POST', true, '', {params: params});

/* /billEstDiff/diffAffirmOrReject 价差单-驳回 */
export const diffReject = (params) => ajax(BASE + '/bill/billEstDiff/diffReject', {}, 'POST', true, '', {params: params});

/* /billEstDiff/diffUploadFile 价差单-上传附件 */
export const diffUploadFile = (searchParams) => ajax(BASE + '/bill/billEstDiff/diffUploadFile', searchParams, 'POST', true);

// /billOrderFile/removeByOrderFileId 删除文件
export const removeByOrderFileId = (params) => ajax(BASE + '/bill/billOrderFile/removeByOrderFileId', {}, 'POST', true,'application/json', {params: params});

/* /billEstDiff/getDiffInfoByDiffNo 根据 价差单 - 价差单号查看详情 */
export const getDiffInfoByDiffNo = (searchParams) => ajax(BASE + '/bill/billEstDiff/getDiffInfoByDiffNo', {}, 'POST', true, '', searchParams);

// /billEstDiff/getEstDiffBtn 获取按钮
export const getEstDiffBtn = (searchParams) => ajax(BASE + '/bill/billEstDiff/getEstDiffBtn', {}, 'POST', true, '', searchParams);

/* /billEstDiff/getExcel 价差单 - 导出execl */
export const getExcel= (searchParams) => exportAjax(BASE + '/bill/billEstDiff/getExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

/* /billEstDiff/getPageList 价差单 - 查询价差分页数据 */
export const getPriceDifferencePageList = (searchParams) => ajax(BASE + '/bill/billEstDiff/getPageList', searchParams, 'POST', true);

/* /estimation/getEstContractList 暂估获取合同信息列表*/
export const getEstContractList = (searchParams) => ajax(BASE + '/bill/estimation/getEstContractList', searchParams, 'POST', true);

/* /estimation/getQtEstInfo 暂估(质检中) - 查询暂估(质检中)详情 */
export const getQtEstInfo = (searchParams) => ajax(BASE + '/bill/estimation/getQtEstInfo', searchParams, 'POST', true);

// /estimation/getEstClearBtn 获取按钮
export const getEstClearBtn = (searchParams) => ajax(BASE + '/bill/estimation/getEstClearBtn', {}, 'POST', true, '', searchParams);

// /finance/staff/deleteAuditStaff 财务审批人员配置-删除
export const deleteAuditStaff = (params) => ajax(BASE + '/bill/finance/staff/deleteAuditStaff', {}, 'POST', true, '', {params: params});

// /finance/staff/exportExcel 财务审批人员配置-导出Excel
export const exportExcel = (searchParams) => exportAjax(BASE + '/bill/finance/staff/exportExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

// /finance/staff/exportTemplate 财务审批人员配置-模板下载
export const exportTemplate = (searchParams) => exportAjax(BASE + '/bill/finance/staff/exportTemplate', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

// /finance/staff/getPageList 财务审批人员配置-分页查询
export const getFinancePageList = (searchParams) => ajax(BASE + '/bill/finance/staff/getPageList', searchParams, 'POST', true);

// /finance/staff/getStaffDetail 财务审批人员配置-详情查询（对账单会计/财务，预付单财务）
export const getStaffDetail = (searchParams) => ajax(BASE + '/bill/finance/staff/getStaffDetail', {}, 'POST', true, '', searchParams);

// /finance/staff/getStaffInfo 财务审批人员配置-暂估清理流程财务/会计主管审批，提前/延迟/冻结付款查询
export const getStaffInfo = (searchParams) => ajax(BASE + '/bill/finance/staff/getStaffInfo', {}, 'POST', true, '', searchParams);

// /finance/staff/importAuditStaff 财务审批人员配置-导入
export const importAuditStaff = (searchParams,params) => ajax(BASE + '/bill/finance/staff/importAuditStaff', searchParams, 'POST', true,'application/json', {...params});

// /finance/staff/saveAuditStaff1 财务审批人员配置-保存（对账单会计确认/财务审核、预付申请流程审批人员保存）
export const saveAuditStaff1 = (searchParams) => ajax(BASE + '/bill/finance/staff/saveAuditStaff1', searchParams, 'POST', true);

// /finance/staff/saveAuditStaff2 财务审批人员配置-保存（暂估清理流程、提前/延迟/冻结付款审批人员保存）
export const saveAuditStaff2 = (searchParams) => ajax(BASE + '/bill/finance/staff/saveAuditStaff2', searchParams, 'POST', true);

// /finance/pool/getDzdPageList 财务审核待审池-对账单分页查询
export const getDzdPageList = (searchParams) => ajax(BASE + '/bill/finance/pool/getDzdPageList', searchParams, 'POST', true);

// /estimation/getClearEstInfo 查询暂估清理-详情
export const getClearEstInfo = (searchParams) => ajax(BASE + '/bill/estimation/getClearEstInfo', searchParams, 'POST', true);

// /finance/pool/getYfdPageList 财务审核待审池-预付单分页查询
export const getYfdPageList = (searchParams) => ajax(BASE + '/bill/finance/pool/getYfdPageList', searchParams, 'POST', true);

// /config/deleteConfig 系统配置-删除（通用）
export const deleteConfig = (searchParams) => ajax(BASE + '/bill/config/deleteConfig', {}, 'POST', true, 'application/json', {params: searchParams});

// /config/deleteManagerConfig 系统配置-部门经理删除
export const deleteManagerConfig = (searchParams) => ajax(BASE + '/bill/config/deleteManagerConfig', {}, 'POST', true, 'application/json', {params: searchParams});

// /config/exportExcel 系统配置-导出Excel
export const configExportExcel = (searchParams) => exportAjax(BASE + '/bill/config/exportExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

// /config/exportDepartManagerExcel 系统配置-部门经理导出Excel
export const exportDepartManagerExcel = (searchParams) => exportAjax(BASE + '/bill/config/exportDepartManagerExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /config/exportTemplate 系统配置-模板下载
export const configExportTemplate = (searchParams) => exportAjax(BASE + '/bill/config/exportTemplate', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

// /config/getManagerPageList 系统配置-部门经理配置列表分页查询）
export const getManagerPageList = (searchParams) => ajax(BASE + '/bill/config/getManagerPageList', {}, 'POST', true, 'application/json', {params: searchParams});

// /config//config/getOperateExplain 系统配置-获取操作说明
export const getOperateExplain = (searchParams) => ajax(BASE + '/bill/config/getOperateExplain', {}, 'POST', true, 'application/json', {params: searchParams});

// /config/getSubjectPageList 系统配置-扣款类型科目列表分页查询
export const getSubjectPageList = (searchParams) => ajax(BASE + '/bill/config/getSubjectPageList', {}, 'POST', true, 'application/json', {params: searchParams});

// /config/importSubjectConfig 系统配置-扣款类型科目配置Excel导入
export const importSubjectConfig = (searchParams) => ajax(BASE + '/bill/config/importSubjectConfig', searchParams, 'POST', true);

// /config/getSupplierPageList 系统配置-供应商白名单配置列表分页查询
export const getSupplierPageList = (searchParams) => ajax(BASE + '/bill/config/getSupplierPageList', {}, 'POST', true, 'application/json', {params: searchParams});

// /config/importManagerConfig 系统配置-部门经理配置Excel导入
export const importManagerConfig = (searchParams) => ajax(BASE + '/bill/config/importManagerConfig', searchParams, 'POST', true);

// /config/importSupplierConfig 系统配置-供应商白名单配置Excel导入
export const importSupplierConfig = (searchParams) => ajax(BASE + '/bill/config/importSupplierConfig', searchParams, 'POST', true);

// /config/saveManagerConfig 系统配置-部门经理配置保存
export const saveManagerConfig = (searchParams) => ajax(BASE + '/bill/config/saveManagerConfig', searchParams, 'POST', true);

// /config/saveSubjectConfig 系统配置-扣款单科目配置保存
export const saveSubjectConfig = (searchParams) => ajax(BASE + '/bill/config/saveSubjectConfig', searchParams, 'POST', true);

// /config/saveSupplierConfig 系统配置-供应商白名单配置保存
export const saveSupplierConfig = (searchParams) => ajax(BASE + '/bill/config/saveSupplierConfig', searchParams, 'POST', true);

// /config/doTaskTransfer 系统配置-任务转办
export const doTaskTransfer = (searchParams) => ajax(BASE + '/bill/config/doTaskTransfer', searchParams, 'POST', true);

// /billEstErpDiff/freeze 冻结erp价差单
export const freeze = (params) => ajax(BASE + '/bill/billEstErpDiff/freeze', {}, 'POST', true, 'application/json', {params: params});

// /billEstErpDiff/getListPage erp价差列表
export const getListPage = (searchParams) => ajax(BASE + '/bill/billEstErpDiff/getListPage', searchParams, 'POST', true);

// /bill/billEstErpDiff/getExcel erp价差列表导出
export const reqExportEstErpDiffExcel = (searchParams) => exportAjax(BASE + '/bill/billEstErpDiff/getExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

// /bill/billEstErpDiff/getDzQty erp价差 获取勾选的明细的对账数量的接口
export const getEstErpDiffDzQty = (searchParams) => ajax(BASE + '/bill/billEstErpDiff/getDzQty', searchParams, 'POST', true);

// /deduction/getFiles 扣款单-（公共方法）获取附件列表数据
export const getFiles = (searchParams) => ajax(BASE + '/bill/deduction/getFiles', searchParams, 'POST', true);

// /deduction/abandonOrder 扣款单-废弃
export const deductionAbandonOrder = (params) => ajax(BASE + '/bill/deduction/abandonOrder', {}, 'POST', true, 'application/json', {params: params});

// /deduction/accountCostConfirm 扣款单-成本会计确认/驳回
export const deductionAccountCostConfirm = (searchParams) => ajax(BASE + '/bill/deduction/accountCostConfirm', searchParams, 'POST', true);

// /deduction/deleteDeductionOrder扣款单-删除
export const deleteDeductionOrder = (params) => ajax(BASE + '/bill/deduction/deleteDeductionOrder', {}, 'POST', true, 'application/json', {params: params});

// /deduction/downloadFile 扣款单-附件下载
export const deductionDownloadFile = (searchParams) => exportAjax(BASE + '/bill/deduction/downloadFile', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

// /deduction/deleteFile 扣款单-（公共方法）附件删除
export const deleteFile = (params) => ajax(BASE + '/bill/deduction/deleteFile', {}, 'POST', true, 'application/json', {params: params});

// /deduction/exportExcel 扣款单-导出Excel
export const deductionExportExcel = (searchParams) => exportAjax(BASE + '/bill/deduction/exportExcel', searchParams, 'POST', true, 'application/json', {responseType: 'blob'});

// /deduction/exportItemExcel 扣款单-详情明细列表导出Excel
export const deductionExportItemExcel = (searchParams) => exportAjax(BASE + '/bill/deduction/exportItemExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

// /deduction/exportTemplate 扣款单-明细模板下载
export const deductionExportTemplate = (searchParams) => exportAjax(BASE + '/bill/deduction/exportTemplate', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

// /deduction/financeConfirm 扣款单-财务审核确认
export const deductionFinanceConfirm = (searchParams) => ajax(BASE + '/bill/deduction/financeConfirm', searchParams, 'POST', true);

// /deduction/getSupplierName 扣款单-根据供应商编码查询供应商名称
export const getSupplierName = (params) => ajax(BASE + '/bill/deduction/getSupplierName', {}, 'POST', true, 'application/json', {params: params});

// /deduction/getDeductionInfo 扣款单-详情查询
export const deductionGetDeductionInfo = (params) => ajax(BASE + '/bill/deduction/getDeductionInfo', {}, 'POST', true, 'application/json', {params: params});

// /deduction/getPageList 扣款单-分页查询
export const deductionGetPageList = (searchParams) => ajax(BASE + '/bill/deduction/getPageList', searchParams, 'POST', true);

// /deduction/getSubject 扣款单-根据扣款类型查询科目
export const deductionGetSubject = (params) => ajax(BASE + '/bill/deduction/getSubject', {}, 'POST', true, 'application/json', {params: params});

// /deduction/importManagerConfig 扣款单-扣款明细Excel导入
export const deductionImportManagerConfig = (searchParams,params) => ajax(BASE + '/bill/deduction/importItemExcel', searchParams, 'POST', true,'application/json', {params: params});

// /deduction/initDeductionOrder 扣款单-新建（数据初始化）
export const initDeductionOrder = (searchParams) => ajax(BASE + '/bill/deduction/initDeductionOrder', searchParams, 'GET', true)

// /deduction/printDeductionPDF 扣款单-打印扣款单PDF
export const printDeductionPDF = (searchParams) => exportAjax(BASE + '/bill/deduction/printDeductionPDF', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

// /deduction/purManagerConfirm 扣款单-采购经理确认/驳回
export const purManagerConfirm = (searchParams) => ajax(BASE + '/bill/deduction/purManagerConfirm', searchParams, 'POST', true);

// /deduction/rejectOrder 扣款单-驳回操作统一接口
export const rejectOrder = (searchParams) => ajax(BASE + '/bill/deduction/rejectOrder', searchParams, 'POST', true);

// /deduction/getSubject 扣款单-根据扣款类型查询科目
export const getSubject = (searchParams) => ajax(BASE + '/bill/deduction/getSubject', searchParams, 'POST', true);

// /deduction/saveDeductionOrder 扣款单-暂存
export const saveDeductionOrder = (searchParams) => ajax(BASE + '/bill/deduction/saveDeductionOrder', searchParams, 'POST', true);

// /deduction/submitDeductionOrder 扣款单-提交申请
export const submitDeductionOrder = (searchParams) => ajax(BASE + '/bill/deduction/submitDeductionOrder', searchParams, 'POST', true);

// /deduction/supplierConfirm 扣款单-供应商确认/驳回
export const supplierConfirm = (searchParams) => ajax(BASE + '/bill/deduction/supplierConfirm', searchParams, 'POST', true);

// /deduction/uploadFile 扣款单-上传附件
export const uploadFile = (searchParams) => exportAjax(BASE + '/bill/deduction/uploadFile', searchParams, 'POST', true);

// /advanced/abandonOrder 预付单-废弃
export const advancedAbandonOrder = (params) => ajax(BASE + '/bill/advanced/abandonOrder', {}, 'POST', true, 'application/json', {params: params});

// /advanced/deleteAdvancedOrder 预付单-删除
export const deleteAdvancedOrder = (searchParams) => ajax(BASE + '/bill/advanced/deleteAdvancedOrder', searchParams, 'POST', true);

// /advanced/exportExcel 预付单-导出Excel
export const advancedExportExcel = (searchParams) => exportAjax(BASE + '/bill/advanced/exportExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /advanced/exportItemExcel 预付单-导出预付单明细Excel
export const advancedExportItemExcel = (searchParams) => exportAjax(BASE + '/bill/advanced/exportItemExcel', searchParams, 'GET', true, 'application/json',{responseType: 'blob'});

// /advanced/financeConfirm 预付单-财务确认
export const financeConfirm = (searchParams) => ajax(BASE + '/bill/advanced/financeConfirm', searchParams, 'POST', true);

// /advanced/financeConfirm 预付单-财务驳回
export const financeRejectOrder = (searchParams) => ajax(BASE + '/bill/advanced/rejectOrder', searchParams, 'POST', true);

// /advanced/getAdvancedInfo 预付单-详情查询
export const getAdvancedInfo = (params) => ajax(BASE + '/bill/advanced/getAdvancedInfo', {}, 'POST', true, 'application/json', {params: params});

// /advanced/getEkpoPageList 预付单-EKPO订单分页查询（详情页弹窗列表数据查询）
export const getEkpoPageList = (searchParams) => ajax(BASE + '/bill/advanced/getAdvancedEkpoList', searchParams, 'POST', true);

// /advanced/getPageList 预付单-分页查询
export const advancedGetPageList = (searchParams) => ajax(BASE + '/bill/advanced/getPageList', searchParams, 'POST', true);

// /advanced/getTermsOfPayment 预付单-获取付款条件下拉框数据
export const getTermsOfPayment = (searchParams) => ajax(BASE + '/bill/advanced/getTermsOfPayment', searchParams, 'POST', true);

// /advanced/initAdvanced 预付单-新建（数据初始化）
export const initAdvanced = (searchParams) => ajax(BASE + '/bill/advanced/initAdvanced', searchParams, 'GET', true);

// /advanced/inner/refreshOrderStatus 预付单-SAP调用此接口刷新订单状态
export const refreshOrderStatus = (searchParams) => ajax(BASE + '/bill/advanced/inner/refreshOrderStatus', searchParams, 'POST', true);

// /advanced/saveAdvancedOrder 预付单-暂存
export const saveAdvancedOrder = (searchParams) => ajax(BASE + '/bill/advanced/saveAdvancedOrder', searchParams, 'POST', true);

// /advanced/submitAdvancedOrder 预付单-提交申请
export const submitAdvancedOrder = (searchParams) => ajax(BASE + '/bill/advanced/submitAdvancedOrder', searchParams, 'POST', true);

// /payment/abandonOrder 提前/延迟/冻结付款-废弃付款单
export const abandonOrder = (searchParams) => ajax(BASE + '/bill/payment/abandonOrder', searchParams, 'POST', true);

// /payment/deletePaymentOrder 提前/延迟/冻结付款-删除付款单
export const deletePaymentOrder = (params) => ajax(BASE + '/bill/payment/deletePaymentOrder', {}, 'POST', true, 'application/json', {params: params});

// /payment/exportExcel 提前/延迟/冻结付款-导出Excel
export const paymentExportExcel = (searchParams) => exportAjax(BASE + '/bill/payment/exportExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /payment/financeConfirm 提前/延迟/冻结付款-财务审核确认
export const paymentFinanceConfirm = (searchParams) => ajax(BASE + '/bill/payment/financeConfirm', searchParams, 'POST', true);

// /payment/getPageList 提前/延迟/冻结付款-分页查询
export const paymentGetPageList = (searchParams) => ajax(BASE + '/bill/payment/getPageList', searchParams, 'POST', true);

// /payment/getPaymentInfo 提前/延迟/冻结付款-详情查询
export const getPaymentInfo = (searchParams) => ajax(BASE + '/bill/payment/getPaymentInfo', searchParams, 'POST', true);

// /payment/initPayment 提前/延迟/冻结付款-新建（数据初始化）
export const initPayment = (searchParams) => ajax(BASE + '/bill/payment/initPayment', searchParams, 'GET', true);

// /payment/purManagerConfirm 提前/延迟/冻结付款-采购经理确认/驳回
export const paymentPurManagerConfirm = (searchParams) => ajax(BASE + '/bill/payment/purManagerConfirm', searchParams, 'POST', true);

// /payment/rejectOrder 提前/延迟/冻结付款-废弃
export const paymentRejectOrder = (searchParams) => ajax(BASE + '/bill/payment/rejectOrder', searchParams, 'POST', true);

// /payment/savePaymentOrder 提前/延迟/冻结付款-暂存
export const savePaymentOrder = (searchParams) => ajax(BASE + '/bill/payment/savePaymentOrder', searchParams, 'POST', true);

// /payment/submitPaymentOrder 提前/延迟/冻结付款-提交申请
export const submitPaymentOrder = (searchParams) => ajax(BASE + '/bill/payment/submitPaymentOrder', searchParams, 'POST', true);

// /config/getDictionaries 字典表数据-扣款类型
export const getDictionaries = (params) => ajax(BASE + '/bill/config/getDictionaries', {}, 'POST', true,'application/json', {params: params});

// /finance/task/getDzdTaskPageList 我的待办任务-对账单任务领取列表分页查询
export const getDzdTaskPageList = (searchParams) => ajax(BASE + '/bill/finance/task/getDzdTaskPageList', searchParams, 'POST', true);

// /finance/task/getTaskPageList 我的待办任务-暂估清理，提前/延迟/冻结付款，价差单任务列表分页查询
export const getTaskPageList = (searchParams) => ajax(BASE + '/bill/finance/task/getTaskPageList', searchParams, 'POST', true);

// /finance/task/getYfdTaskPageList 我的待办任务-对账单任务领取列表分页查询
export const getYfdTaskPageList = (searchParams) => ajax(BASE + '/bill/finance/task/getYfdTaskPageList', searchParams, 'POST', true);

// /finance/task/receiveTask 我的待办任务-对账单任务领取列表分页查询
export const receiveTask = (params) => ajax(BASE + '/bill/finance/task/receiveTask', {}, 'POST', true,'application/json', {params: params});

// 物料送货提前维护列表查询
export const reqGetmaterialDeliveryDateList= (searchParams) => ajax(BASE + '/order/material-delivery-date/list', searchParams, 'GET', true);

// 物料送货提前维护新增
export const reqAddMaterialDeliveryDate = (searchParams) => ajax(BASE + '/order/material-delivery-date/add', searchParams, 'POST', true);

// 物料送货提前维护修改
export const reqUpdateAddMaterialDeliveryDate = (searchParams) => ajax(BASE + '/order/material-delivery-date/update', searchParams, 'POST', true);

// 物料送货提前维护删除
export const reqDeleteMaterialDeliveryDate = (id) => ajax(BASE + '/order/material-delivery-date/delete', {}, 'POST', true,'',{params: {id: id}});

// 物料送货提前维护下载
export const reqDownloadMaterialDeliveryDate = (searchParams) => exportAjax(BASE + '/order/material-delivery-date/export-template', searchParams, 'POST', false, 'application/json',{responseType: 'blob',});

// 物料送货提前维护上传
export const reqUploadMaterialDeliveryDate = (searchParams) => ajax(BASE + '/order/material-delivery-date/import-template', searchParams, 'POST', true);

// 对账单管理--月结-获取月结列表
export const getMonthList = (searchParams) => ajax(BASE + '/bill/statementAccount/pageList', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-暂估标签页-删除明细
export const deleteDetailsSingles = (searchParams) => ajax(BASE + '/bill/billOrder/delBillOrderItem', searchParams, 'POST', true);

// /billOrder/getShouldDzNum 获取要进行对账的数量
export const getShouldDzNum = (searchParams) => ajax(BASE + '/bill/billOrder/getShouldDzNum', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-暂估标签页-添加明细
export const addDetailsSingles = (searchParams) => ajax(BASE + '/bill/billOrder/addBillOrderItem', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-发票标签页-获取发票列表
export const getInvoiceList = (searchParams) => ajax(BASE + '/bill/statementAccount/invoicePageList', searchParams, 'POST', false);

// 对账单管理--月结编辑页面-发票标签页-新增发票
export const addInvoiceSingle = (searchParams) => ajax(BASE + '/bill/statementAccount/invoiceAdd', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-发票标签页-修改发票
export const changeInvoiceSingle = (searchParams) => ajax(BASE + '/bill/statementAccount/invoiceEdit', searchParams, 'POST', true);

// 对账单管理--发票标签页-国内发票调税务平台接口
export const listInvoice = (searchParams) => ajax(BASE + '/bill/statementAccount/listInvoice', searchParams, 'POST', true);

// 对账单管理--发票标签页-国内发票调税务平台接口-发票查询次数接口
export const listInvoiceTimes = (searchParams) => ajax(BASE + '/bill/statementAccount/getBtnName', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-发票标签页-删除发票
export const deleteInvoiceSingle = (searchParams) => ajax(BASE + '/bill/statementAccount/invoiceDelete', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-发票标签页-导出发票
export const exportInvoiceSingle = (searchParams) => exportAjax(BASE + '/bill/statementAccount/exportInvoiceExecl', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

// 对账单管理--月结编辑页面-价差标签页-获取价差列表
export const getPriceDiffList = (searchParams) => ajax(BASE + '/bill/statementAccount/priceDiffPageList', searchParams, 'POST', false);

// 对账单管理--月结编辑页面-价差标签页-导出价差
export const exportPriceDiffSingle = (searchParams) => exportAjax(BASE + '/bill/statementAccount/exportPriceDiffExecl', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

// 对账单管理--月结编辑页面-获取编辑页详情
export const statementAccountDetails = (searchParams) => ajax(BASE + '/bill/statementAccount/detail', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-修改快递单号
export const editExpressNo = (searchParams) => ajax(BASE + '/bill/statementAccount/editExpressInfo', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-预付详情列表
export const getAdvancedList = (searchParams) => ajax(BASE + '/bill/statementAccount/advancedPageList', searchParams, 'POST', false);

// 对账单管理--月结编辑页面-预付标签页-导出预付
export const exportAdvancedSingle = (searchParams) => exportAjax(BASE + '/bill/statementAccount/exportAdvancedExecl', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

// 对账单管理--月结编辑页面-预制凭证列表
export const getCerfiticateList = (searchParams) => ajax(BASE + '/bill/statementAccount/certificatePageList', searchParams, 'POST', false);

// 对账单管理--月结编辑页面-预制凭证标签页-保存
export const updateCerfiticateSingle = (searchParams) => ajax(BASE + '/bill/statementAccount/certificateUpdate', searchParams, 'POST', false);

// 对账单管理--月结编辑页面-预制凭证标签页-导出预制凭证
export const exportCerfiticateSingle = (searchParams) => exportAjax(BASE + '/bill/statementAccount/certificateExport', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

// 对账单管理--月结编辑页面-扣款详情列表
export const getDeductionList = (searchParams) => ajax(BASE + '/bill/statementAccount/deductionPageList', searchParams, 'POST', false);

// 对账单管理--月结编辑页面-提前/延迟冻结列表
export const getTydpaymentList = (searchParams) => ajax(BASE + '/bill/statementAccount/tydPaymentPageList', searchParams, 'POST', false);

// 对账单管理--月结编辑页面-操作记录列表
export const getLogList = (searchParams) => ajax(BASE + '/bill/statementAccount/billOrderLogPageList', searchParams, 'POST', false);

// 对账单管理--月结编辑页面-扣款标签页-导出扣款详情列表
export const exportDeductionSingle = (searchParams) => exportAjax(BASE + '/bill/statementAccount/exportDeductionExecl', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

// 对账单管理--月结编辑页面-暂估标签页-暂估详情列表
export const getEstimationList = (searchParams) => ajax(BASE + '/bill/statementAccount/estimationPageList', searchParams, 'POST', false);

// 对账单管理--月结编辑页面-暂估标签页-保存暂估修改
export const saveEstimationList = (searchParams) => ajax(BASE + '/bill/billOrder/updateBillOrderNum', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-报关合同标签页-报关合同列表
export const getContractList = (searchParams) => ajax(BASE + '/bill/statementAccount/declContractList', searchParams, 'POST', false);

// 对账单管理--月结编辑页面-报关合同标签页-查询其他合同列表
export const getOtherContractList = (searchParams) => ajax(BASE + '/bill/statementAccount/declContractOtherList', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-报关合同标签页-添加合同
export const addContract = (searchParams) => ajax(BASE + '/bill/statementAccount/declContractAdd', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-报关合同标签页-导出合同
export const exportContract = (searchParams) => exportAjax(BASE + '/bill/statementAccount/exportDeclContractExecl', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

// 对账单管理--月结编辑页面-报关合同标签页-启用保留
export const contractReserve = (searchParams) => ajax(BASE + '/bill/statementAccount/declContractReserve', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-发票标签页-导入发票
export const exportInvoiceImg = (searchParams) => ajax(BASE + '/bill/statementAccount/invoiceUpload', searchParams, 'POST');

// 对账单管理--月结编辑页面-发票详情-下载发票图像
export const downloadInvoiceImg = (searchParams) => exportAjax(BASE + '/bill/statementAccount/invoiceDownload', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

// 对账单管理--月结编辑页面-价差详情-获取添加明细中列表
export const getPriceDiffListAdd = (searchParams) => ajax(BASE + '/bill/statementAccount/priceDiffItemPageList', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-价差详情-新增明细
export const AddPriceDiffDetails = (searchParams) => ajax(BASE + '/bill/statementAccount/priceDiffItemAdd', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-价差详情-删除明细
export const detailsPriceDiffDetails = (searchParams) => ajax(BASE + '/bill/statementAccount/priceDiffItemDelete', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-打印对账单
export const printBill = (searchParams) => exportAjax1(BASE + '/bill/statementAccount/printStatementPdf', searchParams, 'GET', true, 'application/json',{responseType: 'blob',});

// 对账单管理--月结编辑页面-按钮点击-更新状态
export const updataBtnClick = (searchParams) => ajax(BASE + '/bill/statementAccount/operationBillStatus', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-扣款详情-获取添加明细中列表
export const getDebitList = (searchParams) => ajax(BASE + '/bill/statementAccount/deductionList', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-扣款详情-新增明细
export const AddDebitDetails = (searchParams) => ajax(BASE + '/bill/statementAccount/deductionAdd', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-扣款详情-删除明细
export const deletebitDetails = (searchParams) => ajax(BASE + '/bill/statementAccount/deductionDelete', searchParams, 'POST', true);

// 对账管理--提前/延迟/冻结付款-从对账单发起冻结按钮获取数据
export const getBillData = (searchParams) => ajax(BASE + '/bill/payment/dzLaunchTydPayment', searchParams, 'POST', true);

// 对账单管理--月结编辑页面-发票详情-下载发票
export const downloadInvoice = (searchParams) => exportAjax(BASE + '/bill/statementAccount/invoiceExportTemplate', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

/*导入送货单*/
export const importantBill = (searchParams) =>  ajax(BASE + '/bill/statementAccount/importInvoiceExcel', searchParams, 'POST');

/*对账单-月结-导出*/
export const exportAccountMonth = (searchParams) => exportAjax(BASE + '/bill/statementAccount/exportBillOrderExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

// 对账单管理--月结列表页面-合并对账单
export const mergeBillOrder = (searchParams) => ajax(BASE + '/bill/statementAccount/billOrderMerge', searchParams, 'POST', true);

// 对账单管理--月结编辑页-获取头部按钮权限
export const getpermission = (searchParams) => ajax(BASE + '/bill/statementAccount/getPermission', searchParams, 'POST', true);

// 系统配置--任务列表
export const getWorkList = (searchParams) => ajax(BASE + '/bill/estimation/syncErpEstData', searchParams, 'POST', true);

// // 系统配置--日志记录
export const reqGetEstTaskLogPage = (searchParams) => ajax(BASE + '/bill/estimation/getEstTaskLogPage', searchParams, 'POST', true);

/*对账单-月结随货-暂估列表导出*/
export const exportestimationExcel = (searchParams) => exportAjax(BASE + '/bill/statementAccount/exportEstimationExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob',});

// 对账单-月结随货-预付保存
export const saveVerification = (searchParams) => ajax(BASE + '/bill/statementAccount/verificationBill', searchParams, 'POST', true);

// /statementAccount/verificationBillYF 对账单-月结随货-预付保存
export const verificationBillYF = (searchParams) => ajax(BASE + '/bill/statementAccount/verificationBillYF', searchParams, 'POST', true);

// 扣款单列表-根据单据类型获取扣款类型
export const getDeductionType = (searchParams) => ajax(BASE + '/bill/deduction/getDeductionTypes', searchParams, 'POST', true);

// /payment/purManagerConfirm 提前/延迟冻结/付款-采购经理确认/驳回
export const advanceManagerConfirm = (searchParams) => ajax(BASE + '/bill/payment/purManagerConfirm', searchParams, 'POST', true);

// /payment/rejectOrder 提前/延迟冻结/付款-驳回操作统一接口
export const advanceOrder = (searchParams) => ajax(BASE + '/bill/payment/rejectOrder', searchParams, 'POST', true);

// /payment/financeConfirm 提前/延迟冻结/付款-财务确认
export const financeAdvanceManagerConfirm = (searchParams) => ajax(BASE + '/bill/payment/financeConfirm', searchParams, 'POST', true);

// /payment/rejectOrder 提前/延迟冻结/付款-财务确认驳回操作统一接口
// export const financeAdvanceOrder = (searchParams) => ajax(BASE + '/bill/payment/rejectOrder', searchParams, 'POST', true);

// /billPageField/getPageField 获取页面字段
export const billPageFieldGetPageField = (params) => ajax(BASE + '/bill/billPageField/getPageField', {}, 'POST', true, 'application/json', {params: params});

// /billPageField/insertPageField 新增
export const billPageFieldInsertPageField = (searchParams) => ajax(BASE + '/bill/billPageField/insertPageField', searchParams, 'POST', true);

// /et/exportOrderExcel 转口贸易-订单管理-导出Excel
export const etExportOrderExcel  = (searchParams) => exportAjax(BASE + '/et/exportOrderExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /et/getOrderList 转口贸易-订单管理-列表分页查询
export const etGetOrderList = (searchParams) => ajax(BASE + '/et/getOrderList', searchParams, 'POST', true);

// /printOrderPdf 转口贸易-订单管理-打印PDF
export const reqEtPrintOrderPdf = (searchParams) => exportAjax(BASE + '/et/printOrderPdf', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /purConfirmOrder 转口贸易-订单管理-采购确认
export const etPurConfirmOrder = (searchParams) => ajax(BASE + '/et/purConfirmOrder', searchParams, 'POST', true);

// /purRejectOrder 转口贸易-订单管理-采购驳回
export const etPurRejectOrder = (searchParams) => ajax(BASE + '/et/purRejectOrder', searchParams, 'POST', true);

// /replyOrder 转口贸易-订单管理-回复交期
export const etReplyOrder = (searchParams) => ajax(BASE + '/et/replyOrder', searchParams, 'POST', true);

// /downloadCiFile 转口贸易-订单管理-下载CI文件
export const downloadCiFile = (params) => exportAjax1(BASE + '/et/downloadCiFile', {}, 'GET', true, 'application/json', {params: params, responseType: 'blob'});

// /downloadFile 转口贸易-订单管理-下载文件
export const downloadFile = (params) => exportAjax(BASE + '/et/downloadFile', {}, 'GET', true, 'application/json', {params: params, responseType: 'blob'});

// /selectDeliveryFile 查看送货单文件
export const selectDeliveryFile = (searchParams) => ajax(BASE + '/et/selectDeliveryFile', searchParams, 'POST', true, 'application/json');

// /et/exportGenerateDeliveryOrderExcel 转口贸易-生成送货单-导出Excel
export const etExportGenerateDeliveryOrderExcel = (searchParams) => exportAjax(BASE + '/et/exportGenerateDeliveryOrderExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /et/getGenerateDeliveryOrderList 转口贸易-生成送货单-列表分页查询
export const etGetGenerateDeliveryOrderList = (searchParams) => ajax(BASE + '/et/getGenerateDeliveryOrderList', searchParams, 'POST', true);

// /generateDeliveryOrder 转口贸易-生成送货单
export const generateDeliveryOrder = (searchParams) => ajax(BASE + '/et/generateDeliveryOrder', searchParams, 'POST', true);

// /cancelDeliveryOrder 转口贸易-送货单管理-按送货单号取消送货单
export const etCancelDeliveryOrder= (searchParams) => ajax(BASE + '/et/cancelDeliveryOrder', searchParams, 'POST', true);

// /exportDeliveryOrderExcel 转口贸易-送货单管理-导出Excel
export const etExportDeliveryOrderExcel = (searchParams) => exportAjax(BASE + '/et/exportDeliveryOrderExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /getDeliveryOrderList 转口贸易-送货单管理-列表分页查询
export const etGetDeliveryOrderList = (searchParams) => ajax(BASE + '/et/getDeliveryOrderList', searchParams, 'POST', true);

// /updateDeliveryOrder 转口贸易-送货单管理-修改送货单
export const etUpdateDeliveryOrder = (searchParams) => ajax(BASE + '/et/updateDeliveryOrder', searchParams, 'POST', true);

// /deliveryFileSubmit 转口贸易-送货单附件-提交
export const deliveryFileSubmit = (searchParams) => ajax(BASE + '/et/deliveryFileSubmit', searchParams, 'POST', true);

// /confirmReceipt 转口贸易-收货管理-确认收货
export const etConfirmReceipt = (searchParams) => ajax(BASE + '/et/confirmReceipt', searchParams, 'POST', true);

// /exportReceiptOrderExcel 转口贸易-收货管理-导出Excel
export const etExportReceiptOrderExcel = (searchParams) => exportAjax(BASE + '/et/exportReceiptOrderExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /getReceiptOrderList 转口贸易-收货管理-列表分页查询
export const etGetReceiptOrderList = (searchParams) => ajax(BASE + '/et/getReceiptOrderList', searchParams, 'POST', true);

// /refuseReceipt 转口贸易-收货管理-拒绝收货
export const etRefuseReceipt = (searchParams) => ajax(BASE + '/et/refuseReceipt', searchParams, 'POST', true);

// /exportSoOrderExcel 转口贸易-SO查询-导出Excel
export const etExportSoOrderExcel = (searchParams) => exportAjax(BASE + '/et/exportSoOrderExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /getStockSnapshotPage 试剂VMI-库存快照-列表
export const getStockSnapshotPage = (searchParams) => ajax(BASE + '/sjvmi/sjVmiStock/getStockSnapshotPage', searchParams, 'POST', true);

// /getStockSnapshotPage 试剂VMI-库存快照-详情页列表
export const getStockSnapshotPageTemplate = (searchParams) => ajax(BASE + '/sjvmi/sjVmiStock/getStockSnapshotItemPage', searchParams, 'POST', true);

// /getStockPage 试剂VMI-库存报表-列表
export const getStockPage = (searchParams) => ajax(BASE + '/sjvmi/sjVmiStock/getStockPage', searchParams, 'POST', true);

// /confirmStockInfo 试剂VMI-库存报表-供应商确认按钮
export const confirmStockInfo = (searchParams) => ajax(BASE + '/sjvmi/sjVmiStock/confirmStockInfo', searchParams, 'POST', true);

// /exportGoodsDetailsExecl 试剂VMI-库存报表-导出交货明细报表按钮
export const exportGoodsDetailsExecl = (searchParams) => ajax(BASE + '/sjvmi/sjVmiStock/exportGoodsDetailsExecl', searchParams, 'POST', true);

// /getStockPage 试剂VMI-库存报表-发料详情列表
export const getLssueDetailsList = (searchParams) => ajax(BASE + '/sjvmi/sjVmiStock/getLssueDetailsList', searchParams, 'POST', true);

// /getSoOrderList 转口贸易-SO查询-列表分页查询
export const etGetSoOrderList = (searchParams) => ajax(BASE + '/et/getSoOrderList', searchParams, 'POST', true);

// /synCiFormErp 转口贸易-SO查询-同步CI
export const synCiFormErp = (searchParams) => ajax(BASE + '/et/synCiFormErp', searchParams, 'POST', true);

// /vmiMatnrFloat/deleteMatnrFloatById 删除浮动值
export const deleteMatnrFloatById = (params) => ajax(BASE + '/vmi/vmiMatnrFloat/deleteMatnrFloatById', {}, 'POST', true, 'application/json', {params});

// /vmiMatnrFloat/getMatnrFloatExcel 导出浮动值
export const getMatnrFloatExcel = (searchParams) => exportAjax(BASE + '/vmi//vmiMatnrFloat/getMatnrFloatExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /vmiMatnrFloat/getMatnrFloatHistory 浮动值历史
export const getMatnrFloatHistory = (params) => ajax(BASE + '/vmi/vmiMatnrFloat/getMatnrFloatHistory', {}, 'POST', true, 'application/json', {params});

// /vmiMatnrFloat/matnrFloatPageList 浮动值分页列表
export const matnrFloatPageList = (searchParams) => ajax(BASE + '/vmi/vmiMatnrFloat/matnrFloatPageList', searchParams, 'POST', true);

// /vmiMatnrFloat/saveMatnrFloat 浮动值保存
export const saveMatnrFloat = (searchParams) => ajax(BASE + '/vmi/vmiMatnrFloat/saveMatnrFloat', searchParams, 'POST', true);

// /vmiMatnrFloat/uploadMatnrFloatExcel 导入Excel文件
export const uploadMatnrFloatExcel= (searchParams) => ajax(BASE + '/vmi/vmiMatnrFloat/uploadMatnrFloatExcel', searchParams, 'POST', true);

// /vmi/vmiMatnrFloat/downloadFloatTemplate 下载Excel模版
export const matnrExportExcel = (searchParams) => exportAjax(BASE + '/vmi/vmiMatnrFloat/downloadFloatTemplate', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /vmiDelivery/queryPageReceiveList 确认收货管理分页
export const queryPageReceiveList = (searchParams) => ajax(BASE + '/vmi/vmiDelivery/queryPageReceiveList', searchParams, 'POST', true);

// /vmiDelivery/confirmReceiveDelivery 确认收货
export const confirmReceiveDelivery = (searchParams) => ajax(BASE + '/vmi/vmiDelivery/confirmReceiveDelivery', searchParams, 'POST', true);

// /vmiStorageInfo/vmiStorageInfoNotarizePage 库存快照分页
export const reqVmiStorageInfoNotarizePage = (searchParams) => ajax(BASE + '/vmi/vmiStorageInfo/vmiStorageInfoNotarizePage', searchParams, 'POST', true);

// /vmiStorageInfo/selectStorageInfoNotarizeDetail VMI-库存快照-查看详情
export const reqSelectStorageInfoNotarizeDetail = (params) => ajax(BASE + '/vmi/vmiStorageInfo/selectStorageInfoNotarizeDetail', {}, 'POST', true, 'application/json', {params});

// /vmiStorageInfo/getVmiStorageInfoNotarizeExcel VMI-库存快照-导出库存信息
export const reqExportVmiStorageInfoNotarizeExcel = (params) => exportAjax(BASE + '/vmi/vmiStorageInfo/getVmiStorageInfoNotarizeExcel', {}, 'POST', true, 'application/json',{params, responseType: 'blob'});

// /vmiStorageInfo/storageInfoPageList 库存信息报表-库存信息报表分页列表
export const getstorageInfoPageList = (searchParams) => ajax(BASE + '/vmi/vmiStorageInfo/storageInfoPageList', searchParams, 'POST', true);

///vmiStorageInfo/storageInfoDetail  库存信息报表-库存信息详情
export const storageInfoDetail = (searchParams) => ajax(BASE + '/vmi/vmiStorageInfo/storageInfoDetail', searchParams, 'POST', true);

// /vmiVoucherStorageInfo/voucherStorageInfo 交货信息详情
export const voucherStorageInfo  = (searchParams) => ajax(BASE + '/vmi/vmiVoucherStorageInfo/voucherStorageInfo', searchParams, 'POST', true);

// /vmiStorageInfo/getStorageInfoExcel 导出库存信息
export const getStorageInfoExcel = (searchParams) => exportAjax(BASE + '/vmi/vmiStorageInfo/getStorageInfoExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /vmiVoucherStorageInfo/getNewVoucherStorageInfoExcel 导出交货明细报表
export const getNewVoucherStorageInfoExcel = (searchParams) => exportAjax(BASE + '/vmi/vmiVoucherStorageInfo/getNewVoucherStorageInfoExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /vmiStorageInfo/supplierAffirm 库存信息报表-供应商确认
export const supplierAffirm = (searchParams) => ajax(BASE + '/vmi/vmiStorageInfo/supplierAffirm', searchParams, 'POST', true);

// /vmiSupplierRequest/supplierRequestPageList 综合信息分页列表
export const supplierRequestPageList = (searchParams) => ajax(BASE + '/vmi/vmiSupplierRequest/supplierRequestPageList', searchParams, 'POST', true);

// /vmiSupplierRequest/getSupplierRequestExcel 导出综合报表
export const getSupplierRequestExcel = (searchParams) => exportAjax(BASE + '/vmi/vmiSupplierRequest/getSupplierRequestExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

//试剂VMI订单管理列表
export const getReagentVmiOrderList = (searchParams) => ajax(BASE + '/order/sjVmiOrder/getSjVmiOrderPage', searchParams, 'POST', true,);

//试剂VMI订单管理=>供应商确认订单
export const supplierConfirmSjVmiOrder = (searchParams) => ajax(BASE + '/order/sjVmiOrder/supplierConfirmSjVmiOrder', searchParams, 'POST', true,);

//试剂VMI订单管理=>回复交期
export const supplierReplyDeliveryDate = (searchParams) => ajax(BASE + '/order/sjVmiOrder/supplierReplyDeliveryDate', searchParams, 'POST', true,);

//试剂VMI订单管理=>采购确认
export const purchConfirmPass = (searchParams) => ajax(BASE + '/order/sjVmiOrder/purchConfirmPass', searchParams, 'POST', true,);

//试剂VMI订单管理=>采购驳回
export const purchConfirmReject = (searchParams) => ajax(BASE + '/order/sjVmiOrder/purchConfirmReject', searchParams, 'POST', true,);

// 试剂VMI订单管理=>导出
export const exportSjVmiOrderExecl = (searchParams) => exportAjax(BASE + '/order/sjVmiOrder/exportSjVmiOrderExecl', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// 试剂VMI订单管理=>查看详情
export const getSjVmiOrderInfo = (searchParams) => ajax(BASE + '/order/sjVmiOrder/getSjVmiOrderInfo', searchParams, 'POST', true);

// 试剂VMI订单管理=>打印pdf
export const printSjOrderPdf = (searchParams) => exportAjax(BASE + '/sjvmi/sjVmiOrder/printSjOrderPdf', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// VMI-试剂 > 送货单管理
export const getSjVmiDeliveryPage = (searchParams) => ajax(BASE + '/sjvmi/sjVmiDelivery/getSjVmiDeliveryPage', searchParams, 'POST', true,);

//VMI-试剂 > 送货单管理 >打印pdf
export const printDeliveryPdf = (searchParams) => exportAjax(BASE + '/sjvmi/sjVmiDelivery/printDeliveryPdf', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

//VMI-试剂 > 送货单管理 >打印外箱标贴
export const sjvmiPrintDeliveryBoxPdf = (searchParams) => exportAjax(BASE + '/sjvmi/sjVmiDelivery/printDeliveryBoxPdf', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// VMI-试剂 > 取消
export const sjvmiCancelDeliveryById = (searchParams) => ajax(BASE + '/sjvmi/sjVmiDelivery/cancelDeliveryById', searchParams, 'POST', true,);

// VMI-试剂 > 送货单详情
export const getSjVmiDeliveryInfo = (searchParams) => ajax(BASE + '/sjvmi/sjVmiDelivery/getSjVmiDeliveryInfo', searchParams, 'POST', true,);

//VMI-试剂 > 送货单管理 >导出送货单明细
export const sjvmiExportDeliveryExecl = (searchParams) => exportAjax(BASE + '/sjvmi/sjVmiDelivery/exportDeliveryExecl', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /vmiDelivery/deliveryManagePage 送货单管理-分页查询
export const deliveryManagePage = (searchParams) => ajax(BASE + '/vmi/vmiDelivery/deliveryManagePage', searchParams, 'POST', true);

// /vmiDelivery/printDelieryPdf 送货单管理-打印送货单PDF
export const printDelieryPdf = (params) => exportAjax(BASE + '/vmi/vmiDelivery/printDelieryPdf', {}, 'POST', true, 'application/json',{params, responseType: 'blob'});

// /vmiDelivery/printDeleiverBarCode 送货单管理-打印外箱标贴
export const printDeleiverBarCode = (params) => exportAjax(BASE + '/vmi/vmiDelivery/printDeleiverBarCode', {}, 'POST', true, 'application/json',{params, responseType: 'blob'});

// /vmiDelivery/vmiDeliveryDetails 送货单管理-送货单详情
export const vmiDeliveryDetails = (params) => ajax(BASE + '/vmi/vmiDelivery/vmiDeliveryDetails', {}, 'POST', true, 'application/json',{params});

// /vmi/vmiVoucher/queryPageVoucherList 送货单管理-生成送货单分页列表
export const queryPageVoucherList = (searchParams) => ajax(BASE + '/vmi/vmiVoucher/queryPageVoucherList', searchParams, 'POST', false, 'application/json');

// /vmi/vmiVoucher/generateSendGoods 送货单管理-生成送货单
export const generateSendGoods = (searchParams) => ajax(BASE + '/vmi/vmiVoucher/generateSendGoods', searchParams, 'POST', true, 'application/json');

// /vmi/vmiDelivery/updateSendGoods 送货单管理-修改送货单
export const updateSendGoods = (searchParams) => ajax(BASE + '/vmi/vmiDelivery/updateSendGoods', searchParams, 'POST', true, 'application/json');

// /vmi/vmiDelivery/getReceiveExcel 送货单管理-导出送货单明细
export const getReceiveExcel = (searchParams) => exportAjax(BASE + '/vmi/vmiDelivery/getReceiveExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /vmi/vmiDelivery/cancelDeliveryByNumber 按送货单号取消送货单
export const cancelDeliveryByNumber = (searchParams) => ajax(BASE + '/vmi/vmiDelivery/cancelDeliveryByNumber', searchParams, 'POST', true);

// /vmi/vmiSupplierRequest/quotaConfirmPageList 需求信息确认-分页列表
export const quotaConfirmPageList = (searchParams) => ajax(BASE + '/vmi/vmiSupplierRequest/quotaConfirmPageList', searchParams, 'POST', true);

// /vmi/vmiSupplierRequest/confirmCheckDemand 需求信息确认-确认勾选项
export const confirmCheckDemand = (searchParams) => ajax(BASE + '/vmi/vmiSupplierRequest/confirmCheckDemand', searchParams, 'POST', true);

// /vmi/vmiSupplierRequest 需求信息确认-确认勾选项
export const deleteReply = (id) => ajax(BASE + '/vmi/vmiSupplierRequest/' + id, {}, 'DELETE', true);

// /vmi/vmiSupplierRequest/splitReplyList 需求信息确认-需求分批回复列表
export const reqSplitReplyList = (searchParams) => ajax(BASE + '/vmi/vmiSupplierRequest/splitReplyList', searchParams, 'POST', true);

// /vmi/vmiSupplierRequest/supplierRequestOperLog 需求信息确认-查看历史记录
export const ViewHistoryList = (searchParams) => ajax(BASE + '/vmi/vmiSupplierRequest/supplierRequestOperLog', searchParams, 'POST', true);
// /vmi/vmiSupplierRequest/splitReplySubmit 需求信息确认-分批回复提交
export const reqSplitReplySubmit = (searchParams) => ajax(BASE + '/vmi/vmiSupplierRequest/splitReplySubmit', searchParams, 'POST', true);

// /vmi/vmiSupplierRequest/uploadSupplierRequestExcel 需求信息确认-Excel导入确认
export const uploadSupplierRequestExcel = (searchParams) => ajax(BASE + '/vmi/vmiSupplierRequest/uploadSupplierRequestExcel', searchParams, 'POST', true, 'application/json');

// /vmi/vmiSupplierRequest/quotaConfirmPageListExcel 需求信息确认-Excel导出
export const quotaConfirmPageListExcel = (searchParams) => exportAjax(BASE + '/vmi/vmiSupplierRequest/quotaConfirmPageListExcel', searchParams, 'POST', true, 'application/json',{responseType: 'blob'});

// /vmi/vmiSupplierRequest/confrimRequestAndPurConfirm 需求信息确认-采购确认
export const confrimRequestAndPurConfirm = (searchParams) => ajax(BASE + '/vmi/vmiSupplierRequest/confrimRequestAndPurConfirm', searchParams, 'POST', true);

// /vmi/vmiSupplierRequest/updateDrawingVersion 需求信息确认-修改版本号
export const updateDrawingVersion = (searchParams) => ajax(BASE + '/vmi/vmiSupplierRequest/updateDrawingVersion', searchParams, 'POST', true);

// VMI-试剂 > 生成货单列表
export const sjVmiGetGenerateDeliveryList = (searchParams) => ajax(BASE + '/sjvmi/sjVmiDelivery/showShouldDeliverySjOrderPage', searchParams, 'POST', true);

//VMI-试剂 >送货单管理 >确认订单
export const sjVmiConfirmReceipt = (searchParams) => ajax(BASE + '/sjvmi/sjVmiDelivery/confirmReceipt', searchParams, 'POST', true);

// /exportLssueDetailsExecl 试剂VMI-库存报表-导出发料明细报表按钮
export const exportLssueDetailsExecl = (searchParams) => exportAjax(BASE + '/sjvmi/sjVmiStock/exportLssueDetailsExecl', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /exportStockExecl 试剂VMI-库存报表-导出库存信息报表按钮
export const exportStockExecl = (searchParams) => exportAjax(BASE + '/sjvmi/sjVmiStock/exportStockExecl', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /exportStockExecl 试剂VMI-库存快照-导出库存快照按钮
export const exportStockExeclAgain = (searchParams) => exportAjax(BASE + '/sjvmi/sjVmiStock/exportStockSnapshotItemList', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /syncStockInfo 试剂VMI-库存报表-同步/刷新刷新库存信息
export const syncStockInfo = (searchParams) => ajax(BASE + '/sjvmi/sjVmiStock/syncStockInfo', searchParams, 'POST', true);

// /vmi/vmiQuota/quotaPageList VMI-需求信息查询分页列表
export const reqQuotaQuotaPageList = (searchParams) => ajax(BASE + '/vmi/vmiQuota/quotaPageList', searchParams, 'POST', true);

//// /vmi/vmiPlanRequest/quotaPageList  VMI-配额信息查询分页列表
export const quotaPageList = (searchParams) => ajax(BASE + '/vmi/vmiPlanRequest/quotaPageList', searchParams, 'POST', true);

//VMI-试剂 >送货单管理 >生成送货单提交
export const sjvmiSaveSjVmiDelivery = (searchParams) => ajax(BASE + '/sjvmi/sjVmiDelivery/saveSjVmiDelivery', searchParams, 'POST', true);

// /vmi/vmiPlanRequest/getSyncUrgencyPlanRequest  VMI-同步紧急需求
export const getSyncUrgencyPlanRequest = (searchParams) => ajax(BASE + '/vmi/vmiPlanRequest/getSyncUrgencyPlanRequest', searchParams, 'GET', true);

// /vmi/vmiPlanRequest/getSyncPlanRequest  VMI-正常同步需求
export const getSyncPlanRequest = (searchParams) => ajax(BASE + '/vmi/vmiPlanRequest/getSyncPlanRequest', searchParams, 'GET', true);

// /vmi/vmiPlanRequest/quotaPageList  VMI-重新计算勾选项
export const reCountQuotaSupplier = (searchParams) => ajax(BASE + '/vmi/vmiPlanRequest/reCountQuotaSupplier', searchParams, 'POST', true);

// /vmi/vmiPlanRequest/quotaPageList  VMI-查看配额
export const reqGetQuotaInfo = (id) => ajax(BASE + '/vmi/vmiPlanRequest/getQuotainfo/' + id, {}, 'GET', true);

// /vmi/vmiPlanRequest/updatePlanRequest  VMI-需求信息查询分页列表
export const updatePlanRequest = (searchParams) => ajax(BASE + '/vmi/vmiPlanRequest/updatePlanRequest', searchParams, 'POST', true);

// VMI-试剂 >送货单管理 >获取送货单号
export const getSjVmiDeliveryNoApi = (searchParams) => ajax(BASE + '/sjvmi/sjVmiDelivery/getSjVmiDeliveryNo', searchParams, 'POST', false);

// /pcbaOrder/pcbaList  PCBA工单列表-查询分页列表
export const reqPcbaPageList = (searchParams) => ajax(BASE + '/pcba/pcbaOrder/pcbaList', searchParams, 'POST', true);

// /pcbaOrder/getPcbaExcel  PCBA工单列表-导出工单列表
export const reqExportPcbaExcel = (searchParams) => exportAjax(BASE + '/pcba/pcbaOrder/getPcbaExcel', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /pcbaOrder/subReply  PCBA工单列表-分批提交回复
export const reqPcbaSubReply = (searchParams) => ajax(BASE + '/pcba/pcbaOrder/subReply', searchParams, 'POST', true);

// /pcbaOrder/subReplyList  PCBA工单列表-分批回复记录
export const reqPcbaSubReplyList = (searchParams) => ajax(BASE + '/pcba/pcbaOrder/subReplyList', searchParams, 'POST', true);

// /pcbaOrder/pcabDesList  PCBA工单列表-PCBA回复详情
export const reqPcbaDetailsList = (searchParams) => ajax(BASE + '/pcba/pcbaOrder/pcabDesList', searchParams, 'POST', true);

// /pcbaOrder/uploadPcbaExcel  PCBA工单列表-上传PCBA工单计划
export const reqPcbaUploadPcbaExcel = (searchParams) => ajax(BASE + '/pcba/pcbaOrder/uploadPcbaExcel', searchParams, 'POST', true);

// /pcbaOrder/manualSynchronization  PCBA工单列表-手工同步PCBA
export const reqPcbaManualSynchronization = (searchParams) => ajax(BASE + '/pcba/pcbaOrder/manualSynchronization', searchParams, 'POST', true);

// /pcbaOrder/downloadPcbaTemplate  PCBA工单列表-PCBA工单模板
export const reqDownloadPcbaTemplate = (searchParams) => exportAjax(BASE + '/pcba/pcbaOrder/downloadPcbaTemplate', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /pbacPro/pbacProList  PCBA产线列表-查询分页列表
export const reqPbacProList = (searchParams) => ajax(BASE + '/pcba/pbacPro/pbacProList', searchParams, 'POST', true);

// /pbacPro/downloadPcbaProTemplate  PCBA产线列表-下载模板
export const reqGetPcbaProExcel = (searchParams) => exportAjax(BASE + '/pcba/pbacPro/downloadPcbaProTemplate', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /pbacPro/uploadPcbaExcel  PCBA产线列表-导入Excel文件
export const reqUploadPcbaExcel = (searchParams) => ajax(BASE + '/pcba/pbacPro/uploadPcbaExcel', searchParams, 'POST', true);

// /tauinf/tAuinfList  机加工单-分页查询列表
export const reqTAuinfList = (searchParams) => ajax(BASE + '/pcba/tauinf/tAuinfList', searchParams, 'POST', true);

// /tauinf/subReply  机加工单-分批提交回复
export const reqSubReply = (searchParams) => ajax(BASE + '/pcba/tauinf/subReply', searchParams, 'POST', true);

// /tauinf/getTAuinfExcel  机加工单-导出机加工单列表
export const reqGetTAuinfExcel = (searchParams) => exportAjax(BASE + '/pcba/tauinf/getTAuinfExcel', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /tauinf/uploadTAuinfExcel  机加工单-导入机加工单
export const reqUploadTAuinfExcel = (searchParams) => ajax(BASE + '/pcba/tauinf/uploadTAuinfExcel', searchParams, 'POST', true);

// /tauinf/getTauinfDetailed 机加工单-机加工单回复详情
export const reqGetTauinfDetailed = (searchParams) => ajax(BASE + '/pcba/tauinf/getTauinfDetailed', searchParams, 'POST', true);

// /tauinf/subReplyList  机加工单-分批回复查看
export const reqSubReplyList = (searchParams) => ajax(BASE + '/pcba/tauinf/subReplyList', searchParams, 'POST', true);

// /truckBill/listTruckBill  装箱单列表-分页查询
export const reqListTruckBill = (searchParams) => ajax(BASE + '/dac/truckBill/listTruckBill', searchParams, 'POST', true);

// /truckBill/downloadFile  装箱单列表-文件下载
export const reqTruckBillDownloadFile = (searchParams) => exportAjax(BASE + '/dac/truckBill/downloadFile', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /truckBill/uploadFile 装箱单列表-文件批量上传
export const reqTruckBillUploadFile = (searchParams) => ajax(BASE + '/dac/truckBill/uploadFile', searchParams, 'POST', true);

// /sign/listSign 领导签名-分页查询
export const reqListSign = (searchParams) => ajax(BASE + '/dac/sign/listSign', searchParams, 'POST', true);

// /sign/removeSign 领导签名-删除
export const reqRemoveSign = (searchParams) => ajax(BASE + '/dac/sign/removeSign', searchParams, 'POST', true);

// /sign/importSign 领导签名-导入
export const reqImportSign = (searchParams) => ajax(BASE + '/dac/sign/importSign', searchParams, 'POST', true);

// /sign/exportSign 领导签名-导出
export const reqExportSign = (searchParams) => exportAjax(BASE + '/dac/sign/exportSign', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /sign/saveSign 领导签名-添加数据
export const reqSaveSign = (searchParams) => ajax(BASE + '/dac/sign/saveSign', searchParams, 'POST', true);

// /sign/editSign 领导签名-保存
export const reqEditSign = (searchParams) => ajax(BASE + '/dac/sign/editSign', searchParams, 'POST', true);

// /sign/downloadTemplate 领导签名-下载模板
export const reqSignDownloadTemplate = (searchParams) => exportAjax(BASE + '/dac/sign/downloadTemplate', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /baseData/listBaseData  报关基础数据-分页查询
export const reqListBaseData = (searchParams) => ajax(BASE + '/dac/baseData/listBaseData', searchParams, 'POST', true);

// /baseData/syncBaseData  报关基础数据-同步数据
export const reqSyncBaseData = (searchParams) => ajax(BASE + '/dac/baseData/syncBaseData', searchParams, 'POST', true);

// /importExportBill/listImportExport  进出品业务申请单查询-分页查询
export const reqListContractPayment = (searchParams) => ajax(BASE + '/dac/importExportBill/listImportExport', searchParams, 'POST', true);

// /importExportBill/exportImportExportBill  进出品业务申请单查询-导出
export const reqExportImportExportBill = (searchParams) => exportAjax(BASE + '/dac/importExportBill/exportImportExportBill', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /importExportBill/exportBillBatch  进出品业务申请单查询-批量导出
export const reqExportBillBatch = (searchParams) => exportAjax(BASE + '/dac/importExportBill/exportBillBatch', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /contractPayment/listContractPayment  凭证付汇明细报表-分页查询
export const reqContractPaymentlist = (searchParams) => ajax(BASE + '/dac/contractPayment/listContractPayment', searchParams, 'POST', true);

// /contractPayment/exportContractPayment  凭证付汇明细报表-导出
export const reqExportContractPayment = (searchParams) => exportAjax(BASE + '/dac/contractPayment/exportContractPayment', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /addRequirements  需求信息查询-手动添加需求
export const reqTzAddRequirements = (searchParams) => ajax(BASE + '/tz/addRequirements', searchParams, 'POST', true);
// /allocateRequirements  需求信息查询-继续匹配需求
export const reqTzAllocateRequirements = (searchParams) => ajax(BASE + '/tz/allocateRequirements', searchParams, 'POST', true);
// /deleteRequirements  需求信息查询-删除选择的需求
export const reqTzDeleteRequirements = (searchParams) => ajax(BASE + '/tz/deleteRequirements', searchParams, 'POST', true);
// /deleteRequirePo  需求信息查询-删除匹配PO
export const reqTzDeleteRequirePo = (searchParams) => ajax(BASE + '/tz/deleteRequirePo', searchParams, 'POST', true);
// /importRequirements  需求信息查询-批量导入需求
export const reqTzImportRequirements = (searchParams) => ajax(BASE + '/tz/importRequirements', searchParams, 'POST', true);
// /exportTemplate  需求信息查询-模板下载
export const reqTzExportTemplate = (searchParams) => ajax(BASE + '/tz/exportTemplate', searchParams, 'GET', true);
// /getRequireSummaryList  需求信息查询-列表分页查询
export const reqTzGetRequireSummaryList = (searchParams) => ajax(BASE + '/tz/getRequireSummaryList', searchParams, 'POST', true);
// /exportRequireSummaryExcel  需求信息查询-导出Excel
export const reqTzExportRequireSummaryExcel = (searchParams) => exportAjax(BASE + '/tz/exportRequireSummaryExcel', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /saveReplyDelivery  回复交期-保存回复交期
export const reqTzSaveReplyDelivery = (searchParams) => ajax(BASE + '/tz/saveReplyDelivery', searchParams, 'POST', true);
// /importReplyDeliveries  回复交期-批量导入回复交期
export const reqTzImportReplyDeliveries = (searchParams) => ajax(BASE + '/tz/importReplyDeliveries', searchParams, 'POST', true);
// /getReplyDeliveryList  回复交期-列表分页查询
export const reqTzGetReplyDeliveryList = (searchParams) => ajax(BASE + '/tz/getReplyDeliveryList', searchParams, 'POST', true);
// /exportReplyDeliveryExcel  回复交期-导出Excel
export const reqTzExportReplyDeliveryExcel = (searchParams) => exportAjax(BASE + '/tz/exportReplyDeliveryExcel', searchParams, 'POST', true,'application/json',{responseType: 'blob'})
// /exportDelayReplyLogExcel  回复交期-导出延迟回复Excel
export const reqTzExportDelayReplyLogExcel = (searchParams) => exportAjax(BASE + '/tz/exportDelayReplyLogExcel', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /generateDeliveryOrder  生成送货单-提交生成送货单
export const reqTzGenerateDeliveryOrder = (searchParams) => ajax(BASE + '/tz/generateDeliveryOrder', searchParams, 'POST', true);
// /importGenerateDeliveryOrder  生成送货单-导入生成送货单
export const reqTzImportGenerateDeliveryOrder = (searchParams) => ajax(BASE + '/tz/importGenerateDeliveryOrder', searchParams, 'POST', true);
// /getGenerateDeliveryOrderList  生成送货单-列表分页查询
export const reqTzGetGenerateDeliveryOrderList = (searchParams) => ajax(BASE + '/tz/getGenerateDeliveryOrderList', searchParams, 'POST', true);
// /exportGenerateDeliveryOrderExcel  生成送货单-导出Excel
export const reqTzExportGenerateDeliveryOrderExcel = (searchParams) => exportAjax(BASE + '/tz/exportGenerateDeliveryOrderExcel', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /cancelDeliveryOrders  送货单管理-取消送货单
export const reqTzCancelDeliveryOrders = (searchParams) => ajax(BASE + '/tz/cancelDeliveryOrders', searchParams, 'POST', true);
// /saveDeliveryOrders  送货单管理-保存送货单
export const reqTzSaveDeliveryOrders = (searchParams) => ajax(BASE + '/tz/saveDeliveryOrders', searchParams, 'POST', true);
// /getDeliveryOrderList  送货单管理-列表分页查询
export const reqTzGetDeliveryOrderList = (searchParams) => ajax(BASE + '/tz/getDeliveryOrderList', searchParams, 'POST', true);
// /exportDeliveryOrderExcel  送货单管理-导出Excel
export const reqTzExportDeliveryOrderExcel = (searchParams) => exportAjax(BASE + '/tz/exportDeliveryOrderExcel', searchParams, 'POST', true,'application/json',{responseType: 'blob'})
// /printOrderPdf  送货单管理-打印PDF
export const reqTzPrintOrderPdf = (searchParams) => exportAjax(BASE + '/tz/printOrderPdf', searchParams, 'POST', true,'application/json',{responseType: 'blob'})
// /printBoxLabel  送货单管理-打印外箱标贴
export const reqTzPrintBoxLabel = (searchParams) => exportAjax(BASE + '/tz/printBoxLabel', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /getStatisticalReportList  统计报表-列表分页查询
export const reqTzGetStatisticalReportList = (searchParams) => ajax(BASE + '/tz/getStatisticalReportList', searchParams, 'POST', true);
// /exportStatisticalReportExcel  统计报表-导出Excel
export const reqTzExportStatisticalReportExcel = (searchParams) => exportAjax(BASE + '/tz/exportStatisticalReportExcel', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /getPerformanceReportList  绩效报表-列表分页查询
export const reqTzGetPerformanceReportList = (searchParams) => ajax(BASE + '/tz/getPerformanceReportList', searchParams, 'POST', true);
// /exportPerformanceReportExcel  绩效报表-导出Excel
export const reqTzExportPerformanceReportExcel = (searchParams) => exportAjax(BASE + '/tz/exportPerformanceReportExcel', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

// /confirmReceiptOrders  收货管理-确认收货
export const reqTzConfirmReceiptOrders = (searchParams) => ajax(BASE + '/tz/confirmReceiptOrders', searchParams, 'POST', true);
// /notDelivered  收货管理-未到货
export const reqTzNotDelivered = (searchParams) => ajax(BASE + '/tz/notDelivered', searchParams, 'POST', true);
// /getReceiptOrderList  收货确认-列表分页查询
export const reqTzGetReceiptOrderList = (searchParams) => ajax(BASE + '/tz/getReceiptOrderList', searchParams, 'POST', true);
// /exportReceiptOrderExcel  收货确认-导出Excel
export const reqTzExportReceiptOrderExcel = (searchParams) => exportAjax(BASE + '/tz/exportReceiptOrderExcel', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

//获取按扭的权限
export const getHasAuthButtonListApi = (searchParams) => ajax(BASE + '/user/getHasAuthButtonList', searchParams, 'POST', false);

// 系统管理
//用户管理-列表分页查询
export const requserManagementList = (searchParams) => ajax(BASE + '/system/user/list', searchParams, 'GET', true);

//物料管理-物料清单-获取物料清单分页查询
export const reqQueryMaterialInfoPage = (searchParams) => ajax(BASE + '/supplierSource/materialInfo/queryMaterialInfoPage', searchParams, 'POST', true);

//物料管理-物料清单-保存物料信息
export const reqSaveMaterialInfo = (searchParams) => ajax(BASE + '/supplierSource/materialInfo/saveMaterialInfo', searchParams, 'POST', true);

//物料管理-物料清单-获取物料详情信息
export const reqGetMaterialInfoById = (searchParams) => ajax(BASE + '/supplierSource/materialInfo/getMaterialInfoById', searchParams, 'POST', true);

//物料管理-物料清单-编辑获取各规格物料信息
export const reqQueryMaterialSpecPage = (searchParams) => ajax(BASE + '/supplierSource/materialInfo/queryMaterialSpecPage', searchParams, 'POST', true);

//物料管理-物料清单-删除
export const reqDelMaterialByIds = (searchParams) => ajax(BASE + '/supplierSource/materialInfo/delMaterialByIds', searchParams, 'POST', true);

//物料管理-物料清单-获取库存
export const reqGetMaterialStockById = (searchParams) => ajax(BASE + '/supplierSource/materialInfo/getMaterialStockById', searchParams, 'POST', true);

//物料管理-物料分类-树状
export const reqMaterialClassificationList = (searchParams) => ajax(BASE + '/supplierSource/configMaterialSpec/getMaterialCategoryTree', searchParams, 'POST', true);

//物料管理-物料分类-获取规格配置列表
export const reqGetMaterialSpecsByParentId = (searchParams) => ajax(BASE + '/supplierSource/configMaterialSpec/getMaterialSpecsByParentId', searchParams, 'POST', true);

//物料管理-物料分类-保存规格配置
export const reqSaveMaterialSpec = (searchParams) => ajax(BASE + '/supplierSource/configMaterialSpec/saveMaterialSpec', searchParams, 'POST', true);

//物料管理-物料分类-删除
export const reqDelMaterialCategoryById = (searchParams) => ajax(BASE + '/supplierSource/configMaterialSpec/delMaterialCategoryById', searchParams, 'POST', true);

//物料管理-物料分类-保存分类
export const reqSaveMaterialCategory = (searchParams) => ajax(BASE + '/supplierSource/configMaterialSpec/saveMaterialCategory', searchParams, 'POST', true);

//物料管理-物料分类-获取分类(规格)数据详情
export const reqGetMaterialSpecsInfoById = (searchParams) => ajax(BASE + '/supplierSource/configMaterialSpec/getMaterialSpecsInfoById', searchParams, 'POST', true);

//物料管理-物料单位设置-获取单位信息
export const reqGetUnitTree = (searchParams) => ajax(BASE + '/supplierSource/configUnit/getUnitTree', searchParams, 'POST', true);

//物料管理-物料单位设置-保存单位分类
export const reqSaveUnitClassInfo = (searchParams) => ajax(BASE + '/supplierSource/configUnit/saveUnitClassInfo', searchParams, 'POST', true);

//物料管理-物料单位设置-保存单位
export const reqSaveUnitInfo = (searchParams) => ajax(BASE + '/supplierSource/configUnit/saveUnitInfo', searchParams, 'POST', true);

//物料管理-物料单位设置-删除单位
export const reqDelUnitById = (searchParams) => ajax(BASE + '/supplierSource/configUnit/delUnitById', searchParams, 'POST', true);

//物料管理-品牌-获取品牌分页查询
export const reqQueryBrandPage = (searchParams) => ajax(BASE + '/supplierSource/materialBrand/queryBrandPage', searchParams, 'POST', true);

//物料管理-品牌-删除品牌
export const reqDelBrandByIds = (searchParams) => ajax(BASE + '/supplierSource/materialBrand/delBrandByIds', searchParams, 'POST', true);

//物料管理-品牌-保存品牌
export const reqSaveBrand = (searchParams) => ajax(BASE + '/supplierSource/materialBrand/saveBrand', searchParams, 'POST', true);

//采购计划-采购需求池-获取采购需求池分页查询
export const reqQueryPruchPlanPage = (searchParams) => ajax(BASE + '/supplierSource/pruchPlan/queryPruchPlanPage', searchParams, 'POST', true);

//采购计划-采购需求池-下载bom模板
export const reqDownBomTemplateExcel = (searchParams) => exportAjax(BASE + '/supplierSource/pruchPlan/downBomTemplateExcel', searchParams, 'POST', true,'application/json',{responseType: 'blob'})

//采购计划-采购需求池-保存采购需求计划(批量)
export const reqBatchSavePruchPlans = (searchParams) => ajax(BASE + '/supplierSource/pruchPlan/batchSavePruchPlans', searchParams, 'POST', true);

//采购计划-采购需求池-导入bom
export const reqParsePlanBomExcel = (searchParams) => ajax(BASE + '/supplierSource/pruchPlan/parsePlanBomExcel', searchParams, 'POST', true);

//采购计划-采购需求池-查询采购需求计划详情
export const reqQueryPruchPlanDetail = (searchParams) => ajax(BASE + '/supplierSource/pruchPlan/queryPruchPlanDetail', searchParams, 'POST', true);

//采购计划-询报价-获取询报价分页查询
export const reqGetQuoteOrderPage = (searchParams) => ajax(BASE + '/supplierSource/quoteOrder/getQuoteOrderPage', searchParams, 'POST', true);

//采购计划-询报价-新建报价计划页-根据各条件查询报价的物料及供应商
export const reqQueryQuoteMaterialAndSupplier = (searchParams) => ajax(BASE + '/supplierSource/quoteOrder/queryQuoteMaterialAndSupplier', searchParams, 'POST', true);

//采购计划-询报价-报价单暂存(下一步)
export const reqDraftQuoteOrder = (searchParams) => ajax(BASE + '/supplierSource/quoteOrder/draftQuoteOrder', searchParams, 'POST', true);

//采购计划-询报价-报价单详情
export const reqGetQuoteOrderDetailById = (searchParams) => ajax(BASE + '/supplierSource/quoteOrder/getQuoteOrderDetailById', searchParams, 'POST', true);

//采购计划-询报价-生成报价单
export const reqSaveQuoteOrder = (searchParams) => ajax(BASE + '/supplierSource/quoteOrder/saveQuoteOrder', searchParams, 'POST', true);

//采购计划-询报价-通知报价
export const reqNoticeQuote = (searchParams) => ajax(BASE + '/supplierSource/quoteOrder/noticeQuote', searchParams, 'POST', true);

//采购计划-招投标-获取评分项配置列表
export const reqGetBidGradeConfigList = (searchParams) => ajax(BASE + '/supplierSource/bidInfo/getBidGradeConfigList', searchParams, 'POST', true);

//采购计划-招投标-获取招标详情
export const reqGetBidInfoByid = (searchParams) => ajax(BASE + '/supplierSource/bidInfo/getBidInfoByid', searchParams, 'POST', true);

//采购计划-招投标-获取招标分页列表
export const reqGetBidInfoPage = (searchParams) => ajax(BASE + '/supplierSource/bidInfo/getBidInfoPage', searchParams, 'POST', true);

//采购计划-招投标-招标新增页-获取采购计划的物料分页列表
export const reqQueryPlanMaterialPage = (searchParams) => ajax(BASE + '/supplierSource/bidInfo/queryPlanMaterialPage', searchParams, 'POST', true);

//采购计划-招投标-保存评分项配置信息
export const reqSaveBidGradeConfig = (searchParams) => ajax(BASE + '/supplierSource/bidInfo/saveBidGradeConfig', searchParams, 'POST', true);

//采购计划-招投标-保存招标信息
export const reqSaveBidInfo = (searchParams) => ajax(BASE + '/supplierSource/bidInfo/saveBidInfo', searchParams, 'POST', true);

//供应商管理-评分设置-保存评分规则
export const reqSaveScoreRule = (searchParams) => ajax(BASE + '/supplierSource/score/saveScoreRule', searchParams, 'POST', true);

//供应商管理-评分设置-获取评分规则分页查询
export const reqGetScoreRulePage = (searchParams) => ajax(BASE + '/supplierSource/score/getScoreRulePage', searchParams, 'POST', true);

//供应商管理-评分设置-删除评分
export const reqDelScoreRuleById = (searchParams) => ajax(BASE + '/supplierSource/score/delScoreRuleById', searchParams, 'POST', true);

//供应商管理-评分设置-评分详情
export const reqGetScoreRuleDetail = (searchParams) => ajax(BASE + '/supplierSource/score/getScoreRuleDetail', searchParams, 'POST', true);

//供应商管理-评分设置-获取评审部门
export const reqQueryDeptPageList = (searchParams) => ajax(BASE + '/system/dept/queryDeptPageList', searchParams, 'GET', true);

//供应商管理-评分设置-获取评审人员
export const reqSelectUserPageList = (searchParams) => ajax(BASE + '/system/user/selectUserPageList', searchParams, 'GET', true);

//供应商管理-证书配置-获取证书配置分页查询
export const reqGetCertRulePage = (searchParams) => ajax(BASE + '/supplierSource/supplierCertRule/getCertRulePage', searchParams, 'POST', true);

//供应商管理-证书配置-保存证书类型
export const reqSaveCertifType = (searchParams) => ajax(BASE + '/supplierSource/supplierCertRule/saveCertifType', searchParams, 'POST', true);

//供应商管理-证书配置-保存证书
export const reqSaveCertif = (searchParams) => ajax(BASE + '/supplierSource/supplierCertRule/saveCertif', searchParams, 'POST', true);

//供应商管理-证书配置-获取证书类型列表
export const reqGetCertifTypeList = (searchParams) => ajax(BASE + '/supplierSource/supplierCertRule/getCertifTypeList', searchParams, 'POST', true);

//供应商管理-证书配置-文件上传
export const reqFileUpload = (searchParams) => ajax(BASE + '/supplierSource/fileUpload/uploadFile', searchParams, 'POST', true);

//供应商管理-证书配置-删除
export const reqDelCertifByIds = (searchParams) => ajax(BASE + '/supplierSource/supplierCertRule/delCertifByIds', searchParams, 'POST', true);

//供应商管理-证书配置-编辑
export const reqGetCertRuleDetail = (searchParams) => ajax(BASE + '/supplierSource/supplierCertRule/getCertRuleDetail', searchParams, 'POST', true);

//供应商管理-供应商准入准出-获取供应商审批分页列表
export const reqQuerySupplierApplyPage = (searchParams) => ajax(BASE + '/supplierSource/supplierManager/querySupplierApplyPage', searchParams, 'POST', true);

//供应商管理-供应商准入准出-获取供应商分页列表
export const reqQuerySupplierPage = (searchParams) => ajax(BASE + '/supplierSource/supplierManager/querySupplierPage', searchParams, 'POST', true);

//供应商管理-供应商准入准出-邀请供应商
export const reqInviteSupplier = (searchParams) => ajax(BASE + '/supplierSource/supplierManager/inviteSupplier', searchParams, 'POST', true);

//供应商管理-供应商准入准出-供应商详情
export const reqQuerySupplierDetails = (searchParams) => ajax(BASE + '/supplierSource/supplierManager/querySupplierDetails', searchParams, 'POST', true);

//供应商管理-供应商准入准出-供应商审批详情
export const reqQueryApplyDetailBySupplierId = (searchParams) => ajax(BASE + '/supplierSource/supplierManager/queryApplyDetailBySupplierId', searchParams, 'POST', true);

//供应商管理-供应商准入准出-查看-同意审批
export const reqFlowComplete = (searchParams) => ajax(BASE + '/flow/api/clientFlow/complete', searchParams, 'POST', true);

//供应商管理-供应商准入准出-查看-驳回审批
export const reqFlowStopProcess = (searchParams) => ajax(BASE + '/flow/api/clientFlow/stopProcess', searchParams, 'POST', true);

//供应商管理-供应商准入准出-获取审批流程数据
export const reqGetPagerModel = (searchParams) => ajax(BASE + '/flow/flowable/modelInfo/getPagerModel', searchParams, 'POST', true);

//供应商管理-供应商准入准出-变更
export const reqApplyModifySupplier = (searchParams) => ajax(BASE + '/supplierSource/supplierManager/applyModifySupplier', searchParams, 'POST', true);

//供应商管理-供应商准入准出-审批
export const reqApplySupplier = (searchParams) => ajax(BASE + '/supplierSource/supplierManager/applySupplier', searchParams, 'POST', true);

//供应商管理-供应商准入准出-供应商保存
export const reqSaveSupplierManagerInfo = (searchParams) => ajax(BASE + '/supplierSource/supplierManager/saveSupplierInfo', searchParams, 'POST', true);

//供应商注册-提交个人信息
export const reqInitSupplier = (searchParams) => ajax(BASE + '/supplierSource/registerSupplier/initSupplier', searchParams, 'POST', true);

//供应商注册-获取供应商信息
export const reqGetSupplierInfoByUserPhone = (searchParams) => ajax(BASE + '/supplierSource/registerSupplier/getSupplierInfoByUserPhone', searchParams, 'POST', true);

//供应商注册-保存供应商信息
export const reqSaveSupplierInfo = (searchParams) => ajax(BASE + '/supplierSource/registerSupplier/saveSupplierInfo', searchParams, 'POST', true);

//供应商注册-获取上传资质文件配置
export const reqGetUploadFileConfig = (searchParams) => ajax(BASE + '/supplierSource/registerSupplier/getUploadFileConfig', searchParams, 'POST', true);

//获取权限系统-字典管理-具体字典类型的字典数据
export const reqDictDataList = (searchParams) => ajax(BASE + '/system/dict/data/list', searchParams, 'GET', true);

//获取地区树状
export const reqGetAreaTree = (searchParams) => ajax(BASE + '/system/sysArea/getAreaTree', searchParams, 'POST', true);

//获取地区列表
export const reqGetAreaList = (searchParams) => ajax(BASE + '/system/sysArea/getAreaList', searchParams, 'POST', true);

//获取权限系统-获取部门树状
export const reqGetSysDeptTrees = (searchParams) => ajax(BASE + '/system/user/getSysDeptTrees', searchParams, 'GET', true);

//个人中心-供应商信息管理-供应商物料地址管理-列表分页查询
export const reqGetSupplierMaterialAddressPage = (searchParams) => ajax(BASE + '/supplier/getSupplierMaterialAddressPage', searchParams, 'POST', true);

//供应商物料地址管理-列表分页查询-保存供应商各物料的送货地址
export const reqSaveSupplierMaterialAddress = (searchParams) => ajax(BASE + '/supplier/saveSupplierMaterialAddress', searchParams, 'POST', true);
