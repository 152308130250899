import React, {Component} from 'react'
import {Menu} from "antd";
// import logo from '../../asserts/images/logo.png'
import {Link, withRouter} from "react-router-dom"
import {connect} from 'react-redux'
import './nav-left.less'
import {FormattedMessage, injectIntl} from "react-intl";
import {saveMenuOpenKeys, getMenuOpenKeys} from '../../utils/disk-cache/disk-cache'
import '../../../node_modules/react-resizable/css/styles.css';
import {getUserCache, saveUserCache} from '@/utils/memory/memory'
const {SubMenu} = Menu;
class NavLeft extends Component {
  state = {
    isLoadData: false,
    menuNames: [],
    openKeys: [],
    width: 200,
    height: 200,
  }
  getNavMenus = (navLeftMenus) => {
    // let {lang} = this.props
    if (navLeftMenus && navLeftMenus instanceof Array) {
      return navLeftMenus.map((menu) => {
        if (menu.children && menu.children.length) {
          return (
            <SubMenu key={menu.path} title={menu.meta ? menu.meta.title : '首页'}>
              {this.getNavMenus(menu.children)}
            </SubMenu>
          )
        } else {
          return (
            <Menu.Item key={menu.path} onClick={() => this.menuClick(menu.path)}>
              <Link to={menu.path} title={menu.meta ? menu.meta.title : ''}>
                {menu.meta ? menu.meta.title : ''}
              </Link>
            </Menu.Item>
          )
        }
      })
    }

  }
  menuClick = (menuUrl) => {
    const pathname = this.props.location.pathname;
    if (menuUrl === pathname) {
      this.props.history.push('/blank')
      setTimeout(() => {
        this.props.history.push(menuUrl)
      })
    }
  }
  getOpenKeys = () => {
    const pathname = this.props.location.pathname;
    const {menuPermissions} = this.props.permissions
    const data = getUserCache()
    let user = {}
    if (menuPermissions && menuPermissions instanceof Array) {
      for (let i = 0; i < menuPermissions.length; i++) {
        const menu = menuPermissions[i];
        const subMenu = menu.children;
        if (subMenu) {
          for (let j = 0; j < subMenu.length; j++) {
            if (subMenu[j].path === pathname) {
              if (subMenu[j].isFrame === 0) {
                user.open = subMenu[j].component
                saveUserCache({...data,...user});
              }
              return menu.path;
            }
          }
        }
      }
    }
  }

  openChange = (openKeys) => {
    this.setState({
      openKeys
    })
    saveMenuOpenKeys(openKeys)
  }
  componentDidMount() {
    this.setState({
      openKeys: getMenuOpenKeys()
    })
  }

  render() {
    const {collapsed, lang, permissions} = this.props
    const {openKeys} = this.state
    const path = this.props.location.pathname
    const style = {
      letterSpacing: '0px',
      fontSize: '16px'
    }
    return (
        <div className='nav-left'>
          <span className="nav-left-header"
                style={{display: 'flex', justifyContent: collapsed ? 'center' : 'flexStart'}}>

                {/* <img alt='logo' src={logo} className='nav-left-header-logo'
                     style={{marginLeft: collapsed ? 0 : '8px'}}/> */}
            {
              collapsed ? null : (<span className='logo-text' style={lang === 'en' ? style : {}}><FormattedMessage
                id='LOGIN_SRM_TITLE'/></span>)
            }
            </span>

          <Menu
            mode="inline"
            className='nav-left-menu'
            theme="dark"
            openKeys={openKeys}
            selectedKeys={[path]}
            onOpenChange={this.openChange}
            defaultOpenKeys={[this.getOpenKeys()]}>
            {this.getNavMenus(permissions.menuPermissions)}
          </Menu>
        </div>
    )
  }
}

export default injectIntl(connect(
  state => ({
    lang: state.lang,
    permissions: state.permissions
  }),
)(withRouter(NavLeft)))
