import React, {Component} from 'react'
import {Button, Row, Col} from 'antd'
import './not-found.less'
import {oldUrlPre} from "../../utils/constant/old-system-url-pre";
import {removerAllKeys} from "../../utils/disk-cache/disk-cache";
import navLeftMenus from '../../config/nav-left-menu-config'
import {getCookie} from '../../utils/cookie/cookie'
/*
前台404页面
 */
class NotFound extends Component {

  goHome = () => {
    this.props.history.replace('/home')
  }
  /*click=()=>{
    let path = this.props.history.location.pathname
    let search = this.props.history.location.search
    debugger
    path = path.split('/old')[1]
    // window.open(oldUrlPre+path+search)
    console.log('path>>>>',oldUrlPre+path+search);
  }*/
  componentDidMount() {
/*    this.props.history.goForward()
    let path = this.props.history.location.pathname
    let search = this.props.history.location.search
    path = path.split('/old')[1]
    // a.href = oldUrlPre+path+search
    let a = document.createElement('a') // 创建a标签
    let e = document.createEvent('MouseEvents') // 创建鼠标事件对象
    e.initEvent('click', false, false) // 初始化事件对象
    a.target = '_blank';
    a.href = oldUrlPre+path+search// 设置下载地址
    a.click()*/
  }

  render() {
    let path = this.props.history.location.pathname
    let menu = navLeftMenus.filter(item=>item.key===path)
    if (!getCookie('key')) {
      removerAllKeys()
      this.props.history.replace('/login')
      return null
    }else if(menu.length){
      this.props.history.push(path)
      return null
    }
    let search = this.props.history.location.search;
    path = path.split('/old')[1]
    let a = document.createElement('a') // 创建a标签
    let e = document.createEvent('MouseEvents') // 创建鼠标事件对象
    e.initEvent('click', false, false) // 初始化事件对象
    a.target = '_blank';
    a.href = oldUrlPre+path+search// 设置下载地址
    a.click()
    this.props.history.go(-1)
    return (
      <Row className='not-found'>
        {/*<a id='clickme' target='_blank' href={oldUrlPre+path+search}>sss</a>*/}
        <Col span={12} className='left'></Col>
        <Col span={12} className='right'>
          <h1>404</h1>
          <h1>抱歉，你访问的页面不存在</h1>
          <div>
            <Button type='primary' onClick={this.goHome}>
              回到首页
            </Button>
          </div>
        </Col>
      </Row>
    )
  }
}

export default NotFound
