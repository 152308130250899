import { FormattedMessage } from "react-intl";
import {
  SPAN_COL_24,
  SPAN_COL,
  addTitle,
} from '@/utils/util'
import { Input, Select, Form, Space, Button, InputNumber  } from 'antd'
import {  MinusCircleOutlined, PlusOutlined  } from '@ant-design/icons';
const { Option } = Select;
//注册页
export const registerList = [
  {
    type: 'customInput', // 联系人姓名
    key: 'realName',
    name: 'realName',
    span: SPAN_COL_24,
    formatMessageId:'COMMONS_CONTACT_NAME',
    disabled: false,
    initialValue: '',
    labelCol: {span: 4},
    rules: [
      {
        required: true,
        message: '请输入联系人姓名',
      },
    ],
  },
  {
    type: 'customInput', // 职位
    key: 'postName',
    name: 'postName',
    span: SPAN_COL_24,
    formatMessageId:'COMMONS_POSITION',
    labelCol: {span: 4},
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: true,
        message: '请输入职位',
      },
    ],
  },
  {
    type: 'customDictSelect', // 性别
    span: SPAN_COL_24,
    labelCol: {span: 4},
    key: 'sexType',
    name: 'sexType',
    formatMessageId: 'COMMONS_GENDER',
    disabled: false,
    initialValue: '',
    dictTypeName: 'sys_user_sex',
    rules: [
      {
        required: true,
        message: '请选择性别',
      },
    ],
  },
  {
    type: 'customInput', // 联系人手机
    key: 'phone',
    name: 'phone',
    span: SPAN_COL_24,
    formatMessageId:'COMMONS_CONTACT_MOBILE',
    labelCol: {span: 4},
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: true,
        message: '请输入联系人手机',
      },
    ],
  },
  {
    type: 'customInput', // 采购商邀请码
    key: 'purInviteCodes',
    name: 'purInviteCodes',
    span: SPAN_COL_24,
    formatMessageId:'COMMONS_PURCHASING_INVITATION_CODE',
    labelCol: {span: 4},
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: true,
        message: '请输入采购商邀请码',
      },
    ],
  },
]
//企业基本信息
export const basicEnterpriseInformationList = [
  {
    type: 'customInput', // 公司全称
    key: 'supplierCompanyName',
    name: 'supplierCompanyName',
    span: SPAN_COL,
    formatMessageId: 'COMMONS_FULL_NAME_OF_THE_COMPANY',
    disabled: false,
    initialValue: '',
    // labelCol: {span: 4},
    rules: [
      {
        required: false,
        message: '请输入公司全称',
      },
    ],
  },
  {
    type: 'customInput', // 公司简称
    key: 'supplierCompanyShortName',
    name: 'supplierCompanyShortName',
    formatMessageId: 'COMMONS_COMPANY_ABBREVIATION',
    span: SPAN_COL,
    disabled: false,
    initialValue: '',
    // labelCol: {span: 4},
    rules: [
      {
        required: false,
        message: '请输入公司简称',
      },
    ],
  },
  {
    type: 'customInput', // 机构代码
    key: 'orgCode',
    name: 'orgCode',
    formatMessageId: 'COMMONS_ORGANIZATION_CODE',
    span: SPAN_COL,
    disabled: false,
    initialValue: '',
    // labelCol: {span: 4},
    rules: [
      {
        required: false,
        message: '请输入机构代码',
      },
    ],
  },
  {
    type: 'customInput', // 收款账号
    key: 'bankAccountNo',
    name: 'bankAccountNo',
    formatMessageId: 'COMMONS_ACCOUNT_NUMBERS',
    span: SPAN_COL,
    disabled: false,
    initialValue: '',
    // labelCol: {span: 4},
    rules: [
      {
        required: false,
        message: '请输入收款账号',
      },
    ],
  },
  {
    type: 'customDictSelect', // 税种
    key: 'taxCode',
    name: 'taxCode',
    formatMessageId: 'COMMONS_CATEGORIES_OF_TAXES',
    span: SPAN_COL,
    disabled: false,
    initialValue: '',
    dictTypeName: 'source_trans_invoice_type',
  },
  {
    type: 'customInput', // 年营收
    key: 'companyYearIncomeAmount',
    name: 'companyYearIncomeAmount',
    formatMessageId:'COMMONS_ANNUAL_REVENUE',
    span:SPAN_COL,
    // labelCol: {span: 6},
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入年营收',
      },
    ],
  },
  {
    type: 'customInput', // 开户行
    key: 'bankNo',
    name: 'bankNo',
    formatMessageId:'COMMONS_BANK',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    // labelCol: {span: 6},
    rules: [
      {
        required: false,
        message: '请输入开户行',
      },
    ],
  },
  {
    type: 'customInput', // 公司人数
    key: 'companyWorkerNum',
    name: 'companyWorkerNum',
    formatMessageId:'COMMONS_NUMBER_OF_COMPANIES',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入公司人数',
      },
    ],
  },
  {
    type: 'customInput', // 公司电话
    key: 'companyTelePhone',
    name: 'companyTelePhone',
    formatMessageId:'COMMONS_COMPANY_PHONE',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入公司电话',
      },
    ],
  },
  {
    type: 'customInput', // 公司传真
    key: 'companyFaxNo',
    name: 'companyFaxNo',
    formatMessageId:'COMMONS_COMPANY_FAX',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入公司传真',
      },
    ],
  },
  {
    type: 'customInput', // 公司邮箱
    key: 'companyMail',
    name: 'companyMail',
    formatMessageId:'COMMONS_COMPANY_EMAIL',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入公司邮箱',
      },
    ],
  },
  {
    type: 'customInput', // 公司邮编
    key: 'companyZipCode',
    name: 'companyZipCode',
    formatMessageId:'COMMONS_POSTCODE',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入公司邮编',
      },
    ],
  },
  {
    type: 'cascaderAddress', // 地区
    span: SPAN_COL,
    key: 'addressAreaId',
    name: 'addressAreaId',
    formatMessageId: 'COMMONS_REGION',
    initialValue: "",
    disabled: false,
    fieldNames:{label: 'label',value: 'id',children: 'children'},
    rules: [
      {
        required: true,
        message: '请输入地区'
      }
    ]
  },
  {
    type: 'customInput', // 详细地址
    span: SPAN_COL,
    key: 'companyAddress',
    name: 'companyAddress',
    formatMessageId: 'COMMONS_DETAILED_ADDRESS',
    initialValue: "",
    disabled: false,
    // rules: [
    //   {
    //     required: true,
    //     message: '请输入详细地址'
    //   }
    // ]
  },
  {
    type: 'customInput', // 备注
    key: 'remark',
    name: 'remark',
    formatMessageId:'COMMON_REMARK',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
  }
]
// 企业工商信息
export const  enterpriseBusinessInformationList = [
  {
    type: 'customInput', // 纳税人识别号
    key: 'taxNo',
    name: 'taxNo',
    formatMessageId:'COMMONS_TAXPAYER_IDENTIFICATION_NUMBER',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入纳税人识别号',
      },
    ],
  },
  {
    type: 'customInput', // 企业状态
    key: 'companyStatus',
    name: 'companyStatus',
    formatMessageId:'COMMONS_ENTERPRISE_STATUS',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入企业状态',
      },
    ],
  },
  {
    type: 'customInput', // 法定代表人
    key: 'legalUserName',
    name: 'legalUserName',
    formatMessageId:'COMMONS_LEGAL_REPRESENTATIVE',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入法定代表人',
      },
    ],
  },
  {
    type: 'customInput', // 注册资本
    key: 'companyRegisterAmount',
    name: 'companyRegisterAmount',
    formatMessageId:'COMMONS_REGISTERED_CAPITAL',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入注册资本',
      },
    ],
  },
  {
    type: 'customInput', // 实缴资本
    key: 'companyRealPayAmount',
    name: 'companyRealPayAmount',
    formatMessageId:'COMMONS_PAID_CAPITAL',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入实缴资本',
      },
    ],
  },
  {
    type: 'customInput', // 企业性质
    key: 'companyNatureType',
    name: 'companyNatureType',
    formatMessageId:'COMMONS_ENTERPRISE_NATURE',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入企业性质',
      },
    ],
  },
  {
    type: 'rangePicker', // 营业有效期
    key: 'licenseDate',
    name: 'licenseDate',
    formatMessageId:'COMMONS_BUSINESS_VALIDITY_PERIOD',
    span:SPAN_COL,
    disabled: false,
    initialValue: '',
    rules: [
      {
        required: false,
        message: '请输入营业有效期',
      },
    ],
  },
]
//联络人
export const CONTACT_PERSON_HEADER = [
  {
    title: <FormattedMessage id='COMMON_NAME'/>, //姓名
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'realName',
    ellipsis: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_CELL_PHONE_NUMBER'/>, //手机号
    width: 0,
    zhWidth: 130,
    enWidth: 130,
    dataIndex: 'phone',
    ellipsis: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_POSITION'/>, //职位
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'postName',
    ellipsis: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_GENDER'/>, //性别
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'sexType',
    ellipsis: true,
    render(text, record, index) {
      if (record.sexType === '0') return <FormattedMessage id='COMMONS_MALE' />
      if (record.sexType === '1') return <FormattedMessage id='COMMONS_WOMAN' />
    },
  }, 
  {
    title: <FormattedMessage id='COMMONS_TEL_PHONE_NUMBER'/>, //电话
    width: 0,
    zhWidth: 160,
    enWidth: 160,
    dataIndex: 'telePhone',
    ellipsis: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_FAX'/>, //传真
    width: 0,
    zhWidth: 160,
    enWidth: 160,
    dataIndex: 'faxNo',
    ellipsis: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_REGION'/>, //地区
    width: 0,
    zhWidth: 300,
    enWidth: 300,
    dataIndex: 'areaStr',
    ellipsis: true,
    render:addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_ADDRESS'/>, //地址
    width: 0,
    zhWidth: 300,
    enWidth: 300,
    dataIndex: 'address',
    ellipsis: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_EMAIL'/>, //邮箱
    width: 0,
    zhWidth: 160,
    enWidth: 160,
    dataIndex: 'mail',
    ellipsis: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_ZIP_CODE'/>, //邮编
    width: 0,
    zhWidth: 300,
    enWidth: 300,
    dataIndex: 'areaCode',
    ellipsis: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_ADDRESS_TYPE'/>, //地址类型
    width: 0,
    zhWidth: 160,
    enWidth: 160,
    dataIndex: 'addressUseType',
    ellipsis: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMON_REMARK'/>, //备注
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'remark',
    ellipsis: true,
    render: addTitle,
  },
] 
//获取联络人表头
export function getPersonTableHeader(_this) {
  let tempHeader = CONTACT_PERSON_HEADER.slice(0)
  return tempHeader
}

// 联络人-添加/编辑
export const contactPersonFormModalArrList = [
  {
    type: 'customInput', // 姓名
    span: 24,
    key: 'realName',
    name: 'realName',
    formatMessageId: 'COMMON_NAME',
    initialValue: "",
    disabled: false,
    rules: [
      {
        required: true,
        message: '请输入姓名'
      }
    ]
  },
  {
    type: 'customInput', // 手机号
    span: 24,
    key: 'phone',
    name: 'phone',
    formatMessageId: 'COMMONS_CELL_PHONE_NUMBER',
    initialValue: "",
    disabled: false,
    rules: [
      {
        required: true,
        message: '请输入手机号'
      }
    ]
  },
  {
    type: 'customInput', // 职位
    span: 24,
    key: 'postName',
    name: 'postName',
    formatMessageId: 'COMMONS_POSITION',
    initialValue: "",
    disabled: false,
    rules: [
      {
        required: true,
        message: '请输入职位'
      }
    ]
  },
  {
    type: 'customDictSelect', // 性别
    span: SPAN_COL_24,
    key: 'sexType',
    name: 'sexType',
    formatMessageId: 'COMMONS_GENDER',
    disabled: false,
    initialValue: '',
    dictTypeName: 'sys_user_sex',
    rules: [
      {
        required: true,
        message: '选择性别'
      }
    ]
  },
  {
    type: 'customInput', // 电话
    span: 24,
    key: 'telePhone',
    name: 'telePhone',
    formatMessageId: 'COMMONS_TEL_PHONE_NUMBER',
    initialValue: "",
    disabled: false,
    rules: [
      {
        required: true,
        message: '请输入电话'
      }
    ]
  },
  {
    type: 'customInput', // 传真
    span: 24,
    key: 'faxNo',
    name: 'faxNo',
    formatMessageId: 'COMMONS_FAX',
    initialValue: "",
    disabled: false,
    rules: [
      {
        required: true,
        message: '请输入传真'
      }
    ]
  },
  {
    type: 'cascaderAddress', // 地区
    span: 24,
    key: 'areaId',
    name: 'areaId',
    formatMessageId: 'COMMONS_REGION',
    initialValue: "",
    disabled: false,
    fieldNames:{label: 'label',value: 'id',children: 'children'},
    rules: [
      {
        required: true,
        message: '请输入地区'
      }
    ]
  },
  {
    type: 'customInput', // 详细地址
    span: 24,
    key: 'address',
    name: 'address',
    formatMessageId: 'COMMONS_DETAILED_ADDRESS',
    initialValue: "",
    disabled: false,
    rules: [
      {
        required: true,
        message: '请输入详细地址'
      }
    ]
  },
  {
    type: 'customInput', // 邮箱
    span: 24,
    key: 'mail',
    name: 'mail',
    formatMessageId: 'COMMONS_EMAIL',
    initialValue: "",
    disabled: false,
    rules: [
      {
        required: true,
        message: '请输入邮箱'
      }
    ]
  },
  {
    type: 'customInput', // 邮编
    span: 24,
    key: 'areaCode',
    name: 'areaCode',
    formatMessageId: 'COMMONS_ZIP_CODE',
    initialValue: "",
    disabled: false,
    rules: [
      {
        required: true,
        message: '请输入邮编'
      }
    ]
  },
  {
    type: 'customInput', // 地址类型
    span: 24,
    key: 'addressUseType',
    name: 'addressUseType',
    formatMessageId: 'COMMONS_ADDRESS_TYPE',
    initialValue: "",
    disabled: false,
    // rules: [
    //   {
    //     required: true,
    //     message: '请输入地址类型'
    //   }
    // ]
  },
  {
    type: 'customInput', // 备注
    span: 24,
    key: 'remark',
    name: 'remark',
    formatMessageId: 'COMMON_REMARK',
    initialValue: "",
    disabled: false,
    // rules: [
    //   {
    //     required: true,
    //     message: '请输入备注'
    //   }
    // ]
  },
  {
    type: 'slot', //地区id
    key:'areaStr',
    span:SPAN_COL_24,
    disabled: false,
    initialValue: '',
    render: (formatMessage, that) => {
      return (
        <>
          <Form.Item
          key='areaStr'
          name='areaStr'
          style={{display:'none'}}
        >
          <Input/>
        </Form.Item>
        </>
      )
    },
  },
]
//供应产品
export const SUPPLY_PRODUCTS_HEADER = [
  {
    title: <FormattedMessage id='COMMONS_PRODUCT_CLASSIFICATION'/>, //产品分类
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'configCategoryName',
    ellipsis: true,
    editable: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_PRODUCT_NAME'/>, //产品名称
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'materialBaseName',
    ellipsis: true,
    editable: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_MATERIAL_NUMBER_SPECIFICATION'/>, //物料号(规格)
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'materialSpecStr',
    ellipsis: true,
    editable: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_SUPPLY_ADDRESS'/>, //供货地址
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'areaMergerName',
    ellipsis: true,
    editable: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_UNIT'/>, //单位
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'unit',
    ellipsis: true,
    editable: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_HOME_PRICE'/>, //当家价格
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'quotedPriceAmount',
    ellipsis: true,
    editable: true,
    render(text, record, index) {
      if (text) {
        return(
          <span>{text}{' '}{record.quotedPriceUnitCode}</span>
        )
      }
    }
  },
  {
    title: <FormattedMessage id='COMMONS_VALIDITY_PERIOD_OF_QUOTATION'/>, //报价有效期
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'quotedValidityNum',
    ellipsis: true,
    editable: true,
    render(text, record, index) {
      if (text) {
        return(
          <span>{text}{' 自然日'}</span>
        )
      }
    }
  },
  {
    title: <FormattedMessage id='COMMONS_STARTING_SALES_QUANTITY'/>, //起售数量
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'saleMinNum',
    ellipsis: true,
    editable: true,
    render: addTitle,
  },
  {
    title: <FormattedMessage id='COMMONS_PRODUCTION_SCHEDULING_CYCLE'/>, //排产周期
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'productScheduleCycleNum',
    ellipsis: true,
    editable: true,
    render(text, record, index) {
      if (text) {
        return(
          <span key={text}>{text}{' 自然日'}</span>
        )
      }
    }
  },
  {
    title: <FormattedMessage id='COMMONS_VOLUME_DISCOUNT'/>, //批量折扣
    width: 0,
    zhWidth: 200,
    enWidth: 200,
    dataIndex: 'productDiscountItems',
    ellipsis: true,
    editable: true,
    render(text, record, index) {
      if (text) {
        let list = text.map((item,index) => {
          return(
            <p>数量{'>'}{item.startNum},单价*{item.discountRatioNum}%</p>
          )
        })
        return list
      }
    }
  },
  {
    title: <FormattedMessage id='COMMONS_DAILY_PRODUCTION_VOLUME'/>, //日生产量
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'dayOutput',
    ellipsis: true,
    editable: true,
    render(text, record, index) {
      if (text) {
        return(
          <span>{text}{' 个'}</span>
        )
      }
    }
  },
  {
    title: <FormattedMessage id='COMMONS_MONTHLY_PRODUCTION_VOLUME'/>, //月生产量
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'monthOutput',
    ellipsis: true,
    editable: true,
    render(text, record, index) {
      if (text) {
        return(
          <span>{text}{' 个'}</span>
        )
      }
    }
  },
  {
    title: <FormattedMessage id='COMMONS_WARRANTY_PERIOD'/>, //质保期限
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'qualityDeadlineNum',
    ellipsis: true,
    editable: true,
    render(text, record, index) {
      if (text) {
        return(
          <span>{text}{' 个月'}</span>
        )
      }
    }
  },
  {
    title: <FormattedMessage id='COMMONS_BILLING_TYPE'/>, //开票类型
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'invoiceType',
    ellipsis: true,
    editable: true,
    render(text, record, index) {
      if (record.invoiceType === 'add_tax_invoice') return <FormattedMessage id='COMMONS_VAT_ORDINARY_INVOICE' />
      if (record.invoiceType === 'add_special_tax_invoice') return <FormattedMessage id='COMMONS_VAT_INVOICE' />
      if (record.invoiceType === 'no_tax_invoice') return <FormattedMessage id='COMMONS_NO_TICKET' />
    }
  },
  {
    title: <FormattedMessage id='COMMONS_TAX_RATES'/>, //税率
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'taxRate',
    ellipsis: true,
    editable: true,
    render(text, record, index) {
      if (text) {
        return(
          <span>{text}{' %'}</span>
        )
      }
    }
  },
  {
    title: <FormattedMessage id='COMMON_REMARK'/>, //备注
    width: 0,
    zhWidth: 120,
    enWidth: 120,
    dataIndex: 'remark',
    ellipsis: true,
    editable: true,
    render: addTitle,
  },
]
// 供应产品-添加/编辑
export const supplyProductsFormModalArrList = [
  {
    type: 'cascaderClass', // 产品分类
    span: SPAN_COL_24,
    key: 'configCategoryId',
    name: 'configCategoryId',
    formatMessageId: 'COMMONS_PRODUCT_CLASSIFICATION',
    label: 'COMMONS_PRODUCT_CLASSIFICATION',
    initialValue: '',
    options: [],
    fieldNames: {
      label: 'label',
      value: 'id',
      children: 'children'
    },
    changeOnSelect: true,
    onChange: async(value,info,that) => {
      that.handleGetSpecificationChange(value)
    },
    rules: [
      {
        required: true,
        message: '请选择产品分类'
      }
    ]
  },
  {
    type: 'selectPageList', // 产品名称
    key: 'materialBaseId',
    name: 'materialBaseId',
    span:SPAN_COL_24,
    disabled: false,
    initialValue: '',
    options: [],
    formatMessageId: 'COMMONS_PRODUCT_NAME',
    onPageChange: (value,info,that) => {
      that.handleGetSpecificationChange()
    },
    onChange: async(value,info,that) => {
      that.handleMaterialBaseIdChange(value)
    },
    rules: [
      {
        required: true,
        message: '请选择产品名称'
      }
    ]
  },
  {
    type: 'slot', //物料号(规格)
    key:'materialId',
    span:SPAN_COL_24,
    disabled: false,
    initialValue: '',
    render: (formatMessage, that) => {
      return (
        <>
          <Form.Item
          key='materialId'
          name='materialId'
          label={formatMessage({ id: 'COMMONS_MATERIAL_NUMBER_SPECIFICATION' })}
          rules={[{required: true,message: '请选择产品名称'}]}
        >
          <Select
            options={that.state.materialSpecData ? that.state.materialSpecData.map((item) => ({
              label: item.materialSpecStr,
              value: item.materialId,
            })) : []}
            onChange={that.materialIdChange}
          />
        </Form.Item>
        </>
      )
    },
  },
  {
    type: 'cascaderAddress', // 供货地址
    span: SPAN_COL_24,
    key: 'areaParentIds',
    name: 'areaParentIds',
    formatMessageId: 'COMMONS_SUPPLY_ADDRESS',
    initialValue: "",
    disabled: false,
    changeOnSelect: true,
    fieldNames:{label: 'label',value: 'id',children: 'children'},
    rules: [
      {
        required: true,
        message: '请选择供货地址'
      }
    ]
  },
  {
    type: 'customInput', // 单位
    span: 24,
    key: 'unit',
    name: 'unit',
    formatMessageId: 'COMMONS_UNIT',
    initialValue: "",
    disabled: true,
  },
  {
    type: 'slot', //当家价格
    key:'quotedPriceAmount',
    span:SPAN_COL_24,
    disabled: false,
    initialValue: '',
    render: (formatMessage, that) => {
      return (
        <Form.Item label={formatMessage({ id: 'COMMONS_HOME_PRICE' })} >
          <Space>
            <Form.Item name='quotedPriceAmount'>
              <Input/>
            </Form.Item>
            <Form.Item name='quotedPriceUnitCode' initialValue='CNY'>
              <Select>
                <Option value='CNY'>CNY</Option>
                <Option value='USD'>USD</Option>
                <Option value='JAN'>JAN</Option>
              </Select>
            </Form.Item>
          </Space>
        </Form.Item>
      )
    }
  },
  {
    type: 'slot', //报价有效期
    key:'quotedValidityNum',
    span:SPAN_COL_24,
    disabled: false,
    initialValue: '',
    render: (formatMessage, that) => {
      return (
        <Form.Item label={formatMessage({ id: 'COMMONS_VALIDITY_PERIOD_OF_QUOTATION' })}>
          <Space>
            <Form.Item name='quotedValidityNum'>
              <InputNumber min={0} style={{width:'100%'}}/>
            </Form.Item>
           <p style={{width:'50px'}}>自然日</p>
          </Space>
        </Form.Item>
      )
    }
  },
  {
    type: 'inputnumber', // 起售数量
    span: 24,
    key: 'saleMinNum',
    name: 'saleMinNum',
    formatMessageId: 'COMMONS_STARTING_SALES_QUANTITY',
    initialValue: "",
    inputDisabled: false,
    min: 0,
  },
  {
    type: 'slot', //排产周期
    key:'productScheduleCycleNum',
    span:SPAN_COL_24,
    disabled: false,
    initialValue: '',
    render: (formatMessage, that) => {
      return (
        <Form.Item label={formatMessage({ id: 'COMMONS_PRODUCTION_SCHEDULING_CYCLE' })}>
          <Space>
            <Form.Item name='productScheduleCycleNum'>
              <InputNumber min={0} style={{width:'100%'}}/>
            </Form.Item>
           <p style={{width:'50px'}}>自然日</p>
          </Space>
        </Form.Item>
      )
    }
  },
  {
    type: 'slot', //批量折扣
    key:'productDiscountItems',
    span:SPAN_COL_24,
    disabled: false,
    initialValue: '',
    render: (formatMessage, that) => {
      return (
        <Form.Item label={formatMessage({ id: 'COMMONS_VOLUME_DISCOUNT' })}>
          <Form.List name="productDiscountItems">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{
                    display: 'flex',
                  }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, 'startNum']}
                    label="数量>"
                    colon={false}
                  >
                    <Input/>
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, 'discountRatioNum']}
                    label="单价*"
                    colon={false}
                  >
                    <Input/>
                  </Form.Item>
                  <p>%</p>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button onClick={() => add()} icon={<PlusOutlined />} style={{width:'20px',height:'20px',borderRadius:'20px',textAlign:'center',lineHeight:'16px'}}></Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        </Form.Item>
      )
    }
  },
  {
    type: 'slot', //日生产量
    key:'dayOutput',
    span:SPAN_COL_24,
    disabled: false,
    initialValue: '',
    render: (formatMessage, that) => {
      return (
        <Form.Item label={formatMessage({ id: 'COMMONS_DAILY_PRODUCTION_VOLUME' })}>
          <Space>
            <Form.Item name='dayOutput'>
              <InputNumber min={0}  style={{width:'100%'}}/>
            </Form.Item>
           <p>个</p>
          </Space>
        </Form.Item>
      )
    }
  },
  {
    type: 'slot', //月生产量
    key:'monthOutput',
    span:SPAN_COL_24,
    disabled: false,
    initialValue: '',
    render: (formatMessage, that) => {
      return (
        <Form.Item label={formatMessage({ id: 'COMMONS_MONTHLY_PRODUCTION_VOLUME' })}>
          <Space>
            <Form.Item name='monthOutput'>
              <InputNumber min={0}  style={{width:'100%'}}/>
            </Form.Item>
           <p>个</p>
          </Space>
        </Form.Item>
      )
    }
  },
  {
    type: 'slot', //质保期限
    key:'qualityDeadlineNum',
    span:SPAN_COL_24,
    disabled: false,
    initialValue: '',
    render: (formatMessage, that) => {
      return (
        <Form.Item label={formatMessage({ id: 'COMMONS_WARRANTY_PERIOD' })}>
          <Space>
            <Form.Item name='qualityDeadlineNum'>
              <InputNumber min={0}  style={{width:'100%'}}/>
            </Form.Item>
           <p style={{width:'30px'}}>个月</p>
          </Space>
        </Form.Item>
      )
    }
  },
  {
    type: 'customDictSelect', // 开票类型
    span: 24,
    key: 'invoiceType',
    name: 'invoiceType',
    formatMessageId: 'COMMONS_BILLING_TYPE',
    initialValue: "",
    disabled: false,
    dictTypeName: 'source_trans_invoice_type',
  },
  {
    type: 'slot', //税率
    key:'taxRate',
    span:SPAN_COL_24,
    disabled: false,
    initialValue: '',
    render: (formatMessage, that) => {
      return (
        <Form.Item label={formatMessage({ id: 'COMMONS_TAX_RATES' })}>
          <Space>
            <Form.Item name='taxRate'>
              <InputNumber min={0}  style={{width:'100%'}}/>
            </Form.Item>
           <p>%</p>
          </Space>
        </Form.Item>
      )
    }
  },
  {
    type: 'customInput', // 备注
    span: 24,
    key: 'remark',
    name: 'remark',
    formatMessageId: 'COMMON_REMARK',
    initialValue: "",
    disabled: false,
  },
  {
    type: 'slot', //虚拟id
    key:'_uuid',
    span:SPAN_COL_24,
    disabled: false,
    initialValue: '',
    render: (formatMessage, that) => {
      return (
        <Form.Item style={{display:'none'}} name='_uuid'>
          <Input/>
        </Form.Item>
      )
    }
  },
]
//获取供应产品表头
export function getSupplyProductsTableHeader(_this) {
  let tempHeader = SUPPLY_PRODUCTS_HEADER.slice(0)
  return tempHeader
}

//注册页面进度条
export const stepList = [
  {
    title: <FormattedMessage id='COMMONS_REGISTER' />, // 注册
    description: '',
    content:registerList,
  },
  {
    title: <FormattedMessage id='COMMONS_CONTACT_PERSON' />, // 联络人
    description: '',
    content_table: {columns:CONTACT_PERSON_HEADER}
  },
  {
    title: <FormattedMessage id='COMMONS_BASIC_INFORMATION_OF_THE_ENTERPRISE' />, // 企业基本信息
    description: '',
    title_two:'工商信息',
    content:basicEnterpriseInformationList,
    content_two:enterpriseBusinessInformationList,
  },
  {
    title: <FormattedMessage id='COMMONS_SUPPLY_PRODUCTS' />, // 供应产品
    description: '',
    content_table: {columns:SUPPLY_PRODUCTS_HEADER}
  },
  {
    title: <FormattedMessage id='COMMONS_ENTERPRISE_QUALIFICATION_CERTIFICATE' />, // 企业资质证书
    description: '',
    carTitle1:'资质信息',
    // carTitle2:'采购资质',
  },
  // {
  //   title: <FormattedMessage id='COMMONS_OTHER_ATTACHMENTS' />, // 其它附件
  //   description: '',
  // }
]

