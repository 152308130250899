import {createStore, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'
import {composeWithDevTools} from 'redux-devtools-extension'

import reducer from './reducer'
import {persistReducer,persistStore} from 'redux-persist'
// import storageSession from 'redux-persist/lib/storage/session'
import storage from 'redux-persist/lib/storage'


//数据对象
export const storageConfig = {
  key:'root',
  // storage:storageSession,
  storage:storage,
  blacklist: ['isLoading'],
  whitelist:['lang','permissions','factoryList','deliveryAddressList','purchaserCodeList',"authButtonList"],//'isLoading'
}

const myPersistReducer = persistReducer(storageConfig,reducer)
let store  = createStore(myPersistReducer,process.env.NODE_ENV === 'production' ? applyMiddleware(thunk) : composeWithDevTools(applyMiddleware(thunk)))
export const persistor = persistStore(store)
// export default store
// 向外默认暴露store
// export default createStore(myPersistReducer, composeWithDevTools(applyMiddleware(thunk)))
export default store
