/*导出ajax*/
import axios from 'axios'
import {getUserCache} from '../utils/memory/memory'
import {message} from "antd";
import store from '../store/store'
import {receiveLoading} from '../store/createActions'
import en_US from "../locale/en_US/en_US";
import zh_CN from "../locale/zh_CN/zh_CN";

//创建axios实例
const axiosInstance = axios.create({
  // baseURL: baseURL, // api的base_url
  timeout: 300000, // 请求超时时间  5*60*1000
  // withCredentials: true // 选项表明了是否是跨域请求
})

export default function exportAjax(url, data = {}, type = "GET", isLoading=true,ContentType='',config = {},) {
  if (type === 'GET') {
    return axiosInstance.get(url, {//配置对象
      params: data, //请求参数
      loading:isLoading,
      ContentType:ContentType,
      ...config
    });
  } else {
    return axiosInstance({
      loading:isLoading,
      method: 'post',
      ContentType:ContentType,
      url: url,
      data: data,
      ...config
    })
  }
}

//请求拦截器
axiosInstance.interceptors.request.use(
  config => {
    let lang = store.getState().lang==="en"?"en_US":"zh_CN";
    //获取用户信息
    const user = getUserCache();
    //显示加载动画
    store.dispatch(receiveLoading(true))
    config.headers['tenantUrl']='192.168.0.222:'+ window.location.port
    if (user && user.username) {
      // 设置统一的请求header
      // config.headers.token = user.access_token //授权(每次请求把token带给后台)
      config.headers["token"] = user.access_token //授权(每次请求把token带给后台)
      if(config.ContentType){
        config.headers['Cache-Control']='no-cache'
        config.headers['Content-Type'] = config.ContentType //授权(每次请求把token带给后台)
      }
    }
    config.url = config.url+"?lang="+lang;
    return config;
  },
  error => {
    //关闭加载动画
    store.dispatch(receiveLoading(false))
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    //关闭加载动画
    store.dispatch(receiveLoading(false))
    let result = response.data
    if (result && result.type==='application/json') {
      let errorMessage = decodeURIComponent(response.headers.errormessage)
      // if(response.headers&&response.headers.errorcode){
      response.headers.errorcode === '600' ? message.info(errorMessage) : message.error(errorMessage)
      // }
      return ''
    }else {
      return response
    }
  },
  //接口出错
  error => {
    //关闭加载动画
    store.dispatch(receiveLoading(false))
    message.error(intlErrorMessage('COMMON_NET_ERROR'))
    return error
  }
);

//创建axios实例
const axiosInstance1 = axios.create({
  // baseURL: baseURL, // api的base_url
  timeout: 300000, // 请求超时时间  5*60*1000
  // withCredentials: true // 选项表明了是否是跨域请求
})

export function exportAjax1(url, data = {}, type = "GET", isLoading=true,ContentType='',config = {},) {
  if (type === 'GET') {
    return axiosInstance1.get(url, {//配置对象
      params: data, //请求参数
      loading:isLoading,
      ContentType:ContentType,
      ...config
    });
  } else {
    return axiosInstance1({
      loading:isLoading,
      method: 'post',
      ContentType:ContentType,
      url: url,
      data: data,
      ...config
    })
  }
}

//请求拦截器
axiosInstance1.interceptors.request.use(
  config => {
    let lang = store.getState().lang==="en"?"en_US":"zh_CN";
    //获取用户信息
    const user = getUserCache();
    //显示加载动画
    store.dispatch(receiveLoading(true))
    if (user && user.username) {
      // 设置统一的请求header
      config.headers.token = user.access_token //授权(每次请求把token带给后台)
      if(config.ContentType){
        config.headers['Cache-Control']='no-cache'
        config.headers['Content-Type'] = config.ContentType //授权(每次请求把token带给后台)
      }
    }
    config.url = config.url+"?lang="+lang;
    return config;
  },
  error => {
    //关闭加载动画
    store.dispatch(receiveLoading(false))
    return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance1.interceptors.response.use(
  response => {
    //关闭加载动画
    store.dispatch(receiveLoading(false))
    let result = response.data
    if (result && result.type==='application/json') {
      let errorMessage = decodeURIComponent(response.headers.errormessage)
      if(response.headers&&response.headers.errorcode){
        response.headers.errorcode === '600' ? message.info(errorMessage) : message.error(errorMessage)
      }
      return response
    }else {
      return response
    }
  },
  //接口出错
  error => {
    //关闭加载动画
    store.dispatch(receiveLoading(false))
    message.error(intlErrorMessage('COMMON_NET_ERROR'))
    return error
  }
);

const intlErrorMessage=(id)=>{
  const lang = store.getState().lang
  return lang === 'en'?en_US[id]:zh_CN[id]
}


