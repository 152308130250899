import React, {Component} from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import 'antd/dist/antd.less';
// import Login from './pages/login/login'
import LoginNoAuth from './pages/login/login-no-auth'
// import LoginJump from './pages/auth/password/login'
// import GetTokenForOldSystem from './pages/auth/password/gettokenforoldsystem'
// import Logout from './pages/auth/logout'
import Admin from './pages/admin/admin'
import Register from './pages/register/register'
import ForgetPassword from './pages/login/login-forget-password'
import en_US from "./locale/en_US/en_US";
import zh_CN from "./locale/zh_CN/zh_CN";
import enUS from 'antd/lib/locale/en_US';
import zhCN from 'antd/lib/locale/zh_CN';
import {IntlProvider } from 'react-intl';
import {ConfigProvider} from "antd";
import {connect} from 'react-redux'
import './asserts/style/common-modal.less'
import './asserts/style/common-upload.less'

import './App.less'
/**
 * 应用的根组件
 */
class App extends Component {
  render() {
    let lang = this.props.lang
    let messages = {}
    messages['en'] = en_US;
    messages['zh'] = zh_CN;
    return (
      <ConfigProvider locale={lang==='en'?enUS:zhCN} >
        {/*<IntlProvider locale={store.getState().lang} messages={messages[store.getState().lang]}>*/}
        <IntlProvider locale={lang} messages={lang==='en'?en_US:zh_CN}>
          <BrowserRouter>
            <Switch>
              {/* <Route path='/login' component={Login}/> */}
              {/* <Route path='/auth/password/login' component={LoginJump}/>
              <Route path='/auth/password/gettokenforoldsystem' component={GetTokenForOldSystem}/>
              <Route path='/auth/logout' component={Logout}/> */}
              {/* <Route path='/password-free-login' component={LoginNoAuth}/> */}
              <Route path='/login' component={LoginNoAuth}/>
              <Route path='/register' component={Register}/>
              <Route path='/forgetPassword' component={ForgetPassword}/>
              <Route path='/' component={Admin}/>
            </Switch>
          </BrowserRouter>
        </IntlProvider>
      </ConfigProvider>
    )
  }
}
export default connect(
  state=>({lang:state.lang})
)(App)
