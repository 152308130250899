//存储
export function setCookie(cname, cvalue, exdays = 0) { // 当使用setCookie时，exdays传0或不传，表示关闭浏览器后就被清除，截图预览：
  cvalue = encodeURIComponent(JSON.stringify(cvalue))
  if (exdays > 0) {
    var d = new Date().getTime() + exdays * 24 * 3600 * 1000 + 8 * 3600 * 1000
    var expires = 'expires=' + new Date(d).toUTCString()
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  } else {
    document.cookie = cname + '=' + cvalue + ';path=/'
  }
}

//获取
export function getCookie(cname) {
  var name = cname + '='
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    // eslint-disable-next-line eqeqeq
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    // eslint-disable-next-line eqeqeq
    if (c.indexOf(name) == 0) {
      let d = c.substring(name.length, c.length)
      return JSON.parse(decodeURIComponent(d))
    }
  }
  return ''
}

//清除
export function clearCookie(name) {
  setCookie(name, "", -1);
}
