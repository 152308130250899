import React from 'react'
import {Form, Select} from "antd";
import {formatMessage,initSelectListBuyer} from "../../utils/util";
import {injectIntl} from "react-intl";
const {Option} = Select

class PurchaserCodeSelectList extends React.Component {
  render() {
    const {purchaserCodeList,name,disabled, labelCol} = this.props
    return (
      <Form.Item name={name} labelCol={labelCol} label={formatMessage(this,'COMMONS_BUYER')} initialValue={initSelectListBuyer()}>
        <Select mode='multiple' maxTagCount={1} optionLabelProp="label" placeholder={formatMessage(this,'COMMONS_BUYER')} disabled={disabled}>
          {
            purchaserCodeList.length ? purchaserCodeList.map(item => <Option value={item.purchaseCode}
                                                                            key={item.purchaseCode} label={item.purchaseCode}>{item.purchaseCode} {item.purchaseName}</Option>) : null
          }
        </Select>
      </Form.Item>)
  }
}

export default injectIntl(PurchaserCodeSelectList)
