// 物料管理/物料单位设置
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import {
  formatMessage
} from '@/utils/util'
import { connect } from 'react-redux'
import {
  reqDictDataList
} from '@/api/index'
import { Select, Tooltip, Form } from 'antd'
const { Option } = Select
class CustomDictSelect extends Component {
  formWrap = React.createRef()
  state = {
    optionList: [{
      value: 1,
      label:1
    }]
  }
  
  searchMaterialUnitSettingsList = async() => {
    const { dictTypeName } = this.props
    const result = await reqDictDataList({dictType:dictTypeName})
    if (result.errorCode !== '200') return
    this.setState({
      optionList: []
    })
    let optionList = []
    const resultData = result?.data
    if (Array.isArray(resultData) && resultData.length > 0) {
      resultData.forEach((item) => {
          optionList.push({
            value: item.dictValue,
            label: item.dictLabel
          })
        })
      }
    this.setState({
      optionList,
    })
  }
  componentDidMount() {
    this.searchMaterialUnitSettingsList()
  }

  render() {
    const {name, formatMessageId, initialValue, icon, maxTagCount, onFocus, onChange, disabled, labelCol, wrapperCol, rules, mode, style} = this.props
    const { optionList } = this.state
    return (
      // <p>hh</p>
      <Form.Item
      name={name}
      label={formatMessageId ? formatMessage(this,formatMessageId) : ''}
      initialValue={initialValue}
      rules={rules}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      style={style}
    >
      <Select
        suffixIcon={icon}
        disabled={disabled}
        mode={mode}
        placeholder={icon ? icon : formatMessageId ? formatMessage(this,formatMessageId) : ''}
        maxTagCount={maxTagCount}
        onFocus={onFocus ? onFocus : null}
        onChange={onChange ? onChange : null}
        >
          {optionList && optionList.map((option,index) => {
            return (
              <Option key={index} value={option.value}>
                <Tooltip title={option.title}>
                  {option.label}
                </Tooltip>
              </Option>
            )
          })}
        </Select>
      </Form.Item>
    )
  }
}

export default injectIntl(
  connect((state) => ({
    lang: state.lang,
  }))(CustomDictSelect)
)