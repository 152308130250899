import React,{Component} from 'react'
import './custom-link.less'
import {FormattedMessage} from 'react-intl'
import {connect} from "react-redux";
 class CustomLink extends Component{
  render() {
    const {id,authBtnCode,isAuth,authButtonList,customSpan,title} = this.props
    const newProps = {
      ...this.props
    }
    delete newProps.authButtonList
    delete newProps.dispatch
    return !isAuth || (isAuth && authBtnCode && authButtonList.includes(authBtnCode))?<span className={customSpan ?  'custom-link-span' : 'custom-link'}  {...newProps} >
            {
              id ? <FormattedMessage id={id}/> : title ? title : ''
            }
        </span>:null;
  }
}

export default connect(state => ({
  authButtonList: state.authButtonList,
}))(CustomLink)
