import React from 'react'
import {Form, Input, Card, Row, Col, Button } from "antd";
import {injectIntl, FormattedMessage} from "react-intl";
import {formatMessage} from '@/utils/util'
import {getValueFromEvent,} from "@/utils/util";
import './login-forget-password.less'
class ForgetPassword extends React.Component{
  newDataFormRef = React.createRef()
  state={
    pwrules: ''
  }

  getPhoneCode = () => {

  }

  pwBurl = () => {
    console.log(this.newDataFormRef.current.getFieldsValue().loginPassword);
    const {loginPassword, passwordConfirmation} = this.newDataFormRef.current.getFieldsValue()
    if (loginPassword !== passwordConfirmation) {
      console.log('密码不一致');
    }
  }

  submit = (value) => {
    console.log(value);//表单的值

  }
  render() {
    const { } = this.state
    return (
      <div className='div-box'>
        {/* <div className='card-box'> */}
        <Card title={(formatMessage(this,'LOGIN_RETRIEVE_PASSWORD'))}>
          <Form ref={this.newDataFormRef} className='form-box' onFinish={this.submit}>
            <Form.Item name='registerPhone' label='注册手机' initialValue='' rules={[{required: false,message: '请输入11位纯数字手机号'},{pattern: /^1[3456789]\d{9}$/,message: '手机号格式错误'}]} labelCol={{span:3}}>
              <Col span={15}>
                <Input placeholder='请输入11位纯数字手机号'/>
              </Col>
            </Form.Item>
            <Form.Item name='mobileCode'  label='手机验证码'  rules={[{required: false,message: '请输入手机验证码'}]} labelCol={{span:3}}>
              <Row>
                <Col span={15}>
                  <Input placeholder='请输入手机验证码'/>
                </Col>
                <Col span={4}>
                  <Button onClick={this.getPhoneCode}>
                    <FormattedMessage id='LOGIN_OBTAIN_VERIFICATION_CODE'/>
                  </Button>
                </Col>
              </Row>
            </Form.Item>
            <Form.Item name='loginPassword' label='登录密码' initialValue='' rules={[{required: false,message: '请输入登录密码'}]} labelCol={{span:3}}>
              <Col span={15}>
                <Input.Password placeholder='请输入登录密码'/>
              </Col>
            </Form.Item>
            <Form.Item name='passwordConfirmation' label='密码确认' initialValue='' rules={[{required: true,message:'请输入登录密码'}]} labelCol={{span:3}} onBlur={this.pwBurl}>
              <Col span={15}>
                <Input.Password placeholder='请再次输入登录密码'/>
              </Col>
            </Form.Item>
            <Button type='primary' htmlType='submit'>
              <FormattedMessage id='COMMON_SUBMIT'/>
            </Button>
          </Form>
        </Card>
        {/* </div> */}
      </div>
    )
  }
}

export default injectIntl(ForgetPassword)