import React,{Component} from 'react'
import {Button} from 'antd'
import './custom-buttom.less'
import {FormattedMessage,injectIntl} from 'react-intl'
import {connect} from "react-redux";
class CustomButton extends Component{
  render() {
    // const {butText,imgUrl,onClick,id,isLoading, type,authButtonList,authBtnCode,isAuth,unshowBtn} = this.props/*id*/
    const { imgUrl, onClick, id, type, authButtonList, authBtnCode, isAuth, unshowBtn, loading } = this.props/*id*/
    return <>
        {
          !isAuth || (isAuth && authBtnCode && authButtonList.includes(authBtnCode))?<Button className={unshowBtn?'buts-container-button-disabled':'buts-container-button'} type={type} onClick={onClick} disabled={unshowBtn} loading={loading}>
              {
                imgUrl?<img className='buts-container-img' src={imgUrl} alt=""/>:null
              }

              {/*{butText}*/}{/*loading={isLoading}*/}
              <FormattedMessage id={id}/>
            </Button>:null
        }
    </>
  }
}

export default injectIntl(connect(
  state => ({
    authButtonList: state.authButtonList,
  }),
)(CustomButton))
