// 物料管理/物料单位设置
import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import {
  formatMessage
} from '@/utils/util'
import { connect } from 'react-redux'
import {
  reqDictDataList
} from '@/api/index'
import { Form, Checkbox } from 'antd'
class CustomDictRadio extends Component {
  formWrap = React.createRef()
  state = {
    optionList: [{
      value: 1,
      label:1
    }]
  }
  
  searchList = async() => {
    const { dictTypeName } = this.props
    const result = await reqDictDataList({dictType:dictTypeName})
    if (result.errorCode !== '200') return
    this.setState({
      optionList: []
    })
    let optionList = []
    const resultData = result?.data
    if (Array.isArray(resultData) && resultData.length > 0) {
      resultData.forEach((item) => {
          optionList.push({
            value: item.dictValue,
            label: item.dictLabel
          })
        })
      }
    this.setState({
      optionList,
    })
  }
  componentDidMount() {
    this.searchList()
  }
  
  render() {
    const {name, formatMessageId, initialValue, labelCol, wrapperCol, rules, style} = this.props
    const { optionList } = this.state
    return (
      <Form.Item
      name={name}
      label={formatMessageId ? formatMessage(this,formatMessageId) : ''}
      initialValue={initialValue}
      rules={rules}
      labelCol={labelCol}
      wrapperCol={wrapperCol}
      style={style}
    >
      <Checkbox.Group
        options={optionList}
      />
    </Form.Item>
    )
  }
}

export default injectIntl(
  connect((state) => ({
    lang: state.lang,
  }))(CustomDictRadio)
)