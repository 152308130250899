import { Modal } from 'antd'
import React, { Component } from 'react'
import {formatMessage, computedBodyWidth, mapFormOption} from "../../utils/util"
import {Form, Row, Button, Spin} from 'antd'
import {FormattedMessage, injectIntl} from 'react-intl'
import {withRouter} from 'react-router-dom'

class LzListForm extends Component {
  formRef = React.createRef()
  state = {
    loading: false
  }
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    },
  }
  handleSubmit = async () => {
    this.setState({
      loading: true
    })
    await this.props.handleSubmit()
    this.setState({
      loading: false
    })
  }
  handleClose = () => {
    this.props.handleClose()
  }
  render() {
    const {
      submitFormArrList, // 表单提交数据
      span, // 一行多少列
      intl, // injectIntl数据
      that, // 父组件this
      visible, // 弹窗开关
      isModal, // 是否开启弹窗模式
      isAdd, // 是否是新增
      isEdit, //编辑
      // width,
      isNotShowFoot,
      isCustomTitle,
      isinvite,
      isCreate,
      isGoTem,
      title
    } = this.props
    const {loading} = this.state
    return (
      (
        isModal ?
        <Modal
          title={isCustomTitle ? formatMessage(that,'COMMON_ADD') : isAdd ? formatMessage(that,'COMMON_ADD') : isEdit ? formatMessage(that,'COMMONS_EDIT') : isinvite ? formatMessage(that,'COMMONS_INVITE_SUPPLIERS') : isCreate ? formatMessage(that,'COMMONS_CREATE_CLASSIFICATION') : isGoTem ? formatMessage(that,'COMMONS_MOVE_IN_CLASSIFICATION') : title ? title : formatMessage(that,'COMMONS_UPDATE')}
          visible={visible}
          width={computedBodyWidth()*0.4}
          onCancel={this.handleClose}
          forceRender={true}
          keyboard={false}
          maskClosable={false}
        >
          <Spin spinning={loading} size="middle">
            <Form ref={this.formRef} {...this.formItemLayout}>
              <Row gutter={18} className='form-item-container'>
                { submitFormArrList && mapFormOption(submitFormArrList, true, span, intl.formatMessage, that) }
              </Row>
              {
                !isNotShowFoot &&
                <div className='form-foot' >
                  <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
                  {/* 确定*/}
                    <FormattedMessage id='COMMONS_CONFIRM'/>
                  </Button>
                  <Button onClick={this.handleClose}>
                    {/*取消*/}
                    <FormattedMessage id='COMMONS_CANCEL'/>
                  </Button>
                </div>
              }
            </Form>
          </Spin>
        </Modal>
        :
        <Form ref={this.formRef} {...this.formItemLayout} size='small'>
          <Row gutter={18} className='form-item-container'>
            { submitFormArrList && mapFormOption(submitFormArrList, true, span, intl.formatMessage, that) }
          </Row>
        </Form>
      )
    )
  }
}

export default injectIntl(withRouter(LzListForm))
