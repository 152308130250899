import React,{ Component } from 'react'
import { Pagination } from 'antd'
import { injectIntl } from 'react-intl'
import './custom-pagination.less'
import { formatMessage } from "../../utils/util";
class CustomPagination extends Component{
  state = {
    currentPage: 1,
    totalSize: 0,
    pageSize: 20,
  }
  showTotal=(total)=> {
    return `${formatMessage(this,'COMMONS_ALL_TOTAL')} ${total} ${formatMessage(this,'COMMONS_ITEM')}`;
  }
  render() {
    const {pageSizeOptions,style} = this.props
    return <Pagination
      className='pagination-container'
      {...this.props}
      // size='small'
      style={style}
      showQuickJumper
      showSizeChanger
      pageSizeOptions={pageSizeOptions}
      // simple={simple}
      // total={total}
      // showTotal={()=>this.showTotal(total)}
    />;
  }
}
CustomPagination.defaultProps = {
  pageSizeOptions: [10, 20, 50, 100,120,150,200]
}
export default injectIntl(CustomPagination)
