import {Breadcrumb,Button, Row} from "antd"
import React from 'react'
import store from '../store/store'
import {getUser} from './disk-cache/disk-cache'
import XLSX from "xlsx-style";
import PubSub from "pubsub-js";
import moment from "moment";
import {FormattedMessage} from 'react-intl'
import { Form, Col, Select, DatePicker, Radio,InputNumber, Input, Tooltip, Checkbox } from 'antd'
import CustomButton from "../components/custom-button/custom-button"
import CustomTableNoType from "../components/custom-table-no-type/custom-table-no-type"
import CustomInput from '../components/custom-input/custom-input'
import CustomDictSelect from '../components/custom-dict-select/custom-dict-select'
import CustomDictRadio from '@/components/custom-dict-radio/custom-dict-radio'
import CustomDictCheckbox from '@/components/custom-dict-checkbox/custom-dict-checkbox'
import FactorySelectList from '../components/factory-select-list/factory-select-list'
import PurchaserCodeSelectList from "../components/purchaser-code-select-list/purchaser-code-select-list";
import CategoryListCode from "../components/category-list-code/category-list-code";
import CategoryInput from "../components/category-input/category-input";
import SelectList from '../components/select-list/select-list'
import SelectPageList from '../components/select-page-list/select-page-list'
import RangePickerDate from '../components/range-picker-date/range-picker-date'
import DatePickerDate from '../components/date-picker-date/date-picker-date'
import CustomCheckbox from '../components/custom-checkbox/custom-checkbox'
import {navLeftMenus} from '../config/nav-left-menu-config'
import en_US from "../locale/en_US/en_US";
import zh_CN from "../locale/zh_CN/zh_CN";
const {Option} = Select
const {RangePicker} = DatePicker
const { TextArea } = Input
export const PAGE_SIZE = 20
export const SPAN_COL = 8;
export const SPAN_COL1 = 6;
export const SPAN_COL_10 = 10;
export const SPAN_COL_12=12
export const SPAN_COL_24=24
export const SPAN_COL_5=5
export const SPAN_COL_SIX = 6;
export const CONDITION_STYLE={width:'100%'}
export const PUB_SUB_LANG_CHANGE = 'langChange'
export const ADFS_URL_LOGOUT = 'https://adfsforms.mindray.com/adfs/oauth2/logout'
export const ADFS_URL_AUTHORIZE = 'https://adfsforms.mindray.com/adfs/oauth2/authorize'
export const ADFS_URL_MAP_CLIENT_ID_LIST = [
  {
    url: 'localhost',
    clientId: '385bd811-d471-4f33-bddc-cd40a42fd17d',
  },
  {
    url: '10.1.5.60',
    clientId: 'd00c1f62-9a36-4bed-90ae-f88b3896393b'
  },
  {
    url: '10.14.155.52',
    clientId: 'c7030362-e47a-40f7-8319-cd3471def57b'
  },
  {
    url: 'srm2.mindray.com',
    clientId: 'a15e8c02-1e47-4a4f-a57a-47397c2c7b1a'
  }
]
/*南迈/南京迈瑞：1008*/
export const NAN_MAI_FACTORY_LIST =[
  {"factoryCode":"1008","factoryName":"1008"},
]
/*南迈/南京迈瑞 bukrs：1001*/
export const NAN_MAI_BUKRS = '1001'
/*南迈/南京生物：1040*/
export const NAN_MAI_SW_FACTORY_LIST =[
  {"factoryCode":"1040","factoryName":"1040"},
]
/*南迈/南京生物 bukrs：1040*/
export const NAN_MAI_SW_BUKRS = '1040'
/*深迈科：1090*/
export const SHEN_MAI_KE_FACTORY_LIST =[
  {"factoryCode":"1090","factoryName":"1090"},
]
/*深迈科bukrs：1910*/
export const SHEN_MAI_KE_BUKRS = '1910'
/*深迈/迈瑞公司：1000,1001，1600,1700,2996*/
export const SHEN_MAI_FACTORY_LIST =[
  {"factoryCode":"1000","factoryName":"1000"},
  {"factoryCode":"1001","factoryName":"1001"},
  {"factoryCode":"1600","factoryName":"1600"},
  {"factoryCode":"1700","factoryName":"1700"},
  {"factoryCode":"2996","factoryName":"2996"},
  {"factoryCode":"1000,1001,1600,1700,2996","factoryName":'全部'}]
/*深迈/迈瑞公司：1000*/
export const SHEN_MAI_BUKRS = '1000'

export const SRM_ID_TOKEN_HINT_KEY = 'srm-id-token-hint'
export const adfsLogout = (id_token_hint = '') => {
  if (!id_token_hint) {
    id_token_hint = localStorage.getItem(SRM_ID_TOKEN_HINT_KEY) || ''
  }
  const location = window.location
  const url = location.protocol + '//' + location.host
  const byUrlMapClientIdList = ADFS_URL_MAP_CLIENT_ID_LIST
   //默认的clientId，用于本地调试，通过开发人员本地调试，通常本地localhost
  let clientId = byUrlMapClientIdList[0].clientId
  for (let i = 1; i < byUrlMapClientIdList.length; i++) {
    const item = byUrlMapClientIdList[i]
    if (item.url === location.hostname) {
      clientId = item.clientId
      break
    }
  }
  window.location.href = `${ADFS_URL_LOGOUT}?client_id=${clientId}&id_token_hint=${id_token_hint}&post_logout_redirect_uri=${url}/auth/logout`
}
export const setInterval=(num,len)=> {
  //显示的长度，如果以0001则长度为4
  num = parseInt(num, 10) + 1//转数据类型，以十进制自增
  num = num.toString()//转为字符串
  while (num.length < len) {//当字符串长度小于设定长度时，在前面加0
    num = "0" + num
  }
  //如果字符串长度超过设定长度只做自增处理。
  return num
}

export const downloadBlob = (blob, fileName) => {
  try {
    const href = window.URL.createObjectURL(blob); //创建下载的链接
    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // 谷歌浏览器 创建a标签 添加download属性下载
      const downloadElement = document.createElement("a");
      downloadElement.href = href;
      downloadElement.target = "_blank";
      downloadElement.download = fileName;
      document.body.appendChild(downloadElement);
      downloadElement.click(); // 点击下载
      document.body.removeChild(downloadElement); // 下载完成移除元素
      window.URL.revokeObjectURL(href); // 释放掉blob对象
    }
  } catch (e) {
    console.log("下载失败");
  }
};

/**
 * url地址转blob下载
 * @param url  需要下载的url地址
 * @param name 下载的文件名
 * @returns {Promise<void>}
 */
export const xhrequest = async (url,name)=> {
  let data = await fetch(url)
    .then((response) => response.blob())
    .then((res) => {
      console.log(res);
      let blod = new Blob([res]);
      downloadBlob(blod, name);
    });
  return data;
}

/*判断浏览器是否是ie*/
export const isIE =()=> {
  if (!!window.ActiveXObject || 'ActiveXObject' in window) {
    return true
  } else {
    return false
  }
}

/**
 * 获取地址对应的value值
 * @param deliveryAddressList 送货地址列表
 * @param deliveryAddressKey 送货地址的key
 * @returns {string}
 */
export const filterDeliveryAddressValue = (deliveryAddressList,deliveryAddressKey)=>{
  let deliveryAddressValue = ''
  if (deliveryAddressList instanceof Array && deliveryAddressList.length) {
    //{address":"xxx ","addressDesc":"xxx"}
    let filterList = deliveryAddressList.filter(item=>item.addressDesc===deliveryAddressKey)
    if (filterList.length) {
      deliveryAddressValue = filterList[0]['address']
    }
  }
  return deliveryAddressValue
}

/**
 * 获取当天的日期
 * @returns {string} 返回当天日期2021-11-09
 */
export const getCurrentDate = ()=>{
  return moment(new Date()).format('YYYYMMDD')
}

/**
 * 监听数组的改变,并重置工厂组数列表
 * @param that  组件
 * @param name  工厂字段
 */
export const setFactoryList = (that,name)=>{
  PubSub.subscribe('companyChang',(msgName,factoryList)=>{
    console.log(factoryList)
    that.setState({
      'factoryList': factoryList
    })
    initFactoryCode(factoryList,that,name)
  })
}

/**
 * 输入框去掉两端的空格
 * @param e  事件对象
 * @returns {*}  返回去掉空格后的值
 */
export const getValueFromEvent=(e)=>{
  if (typeof e === 'object') {
    return e.target && e.target.value.replace(/(^\s*)|(\s*$)/g, '');
  }else if(typeof e === 'string'){
    return e.replace(/(^\s*)|(\s*$)/g, '');
  }else{
    return  ''
  }
}

/**
 * 采购员编码数组转成字符串
 * @param purchaseCodeList  采购员编码数组 如['admin','123']
 * @returns {string} "admin,123"
 */
export const purchaseCodeListToString = (purchaseCodeList)=>{
  if (purchaseCodeList instanceof Array && purchaseCodeList.length) {
    return purchaseCodeList.join()
  }
}


// 将一个sheet转成最终的excel文件的blob对象，然后利用URL.createObjectURL下载
export const sheet2blob=(sheet, sheetName)=> {
  sheetName = sheetName || 'sheet1';
  var workbook = {
    SheetNames: [sheetName],
    Sheets: {}
  };
  workbook.Sheets[sheetName] = sheet;
  // 生成excel的配置项
  var wopts = {
    bookType: 'xlsx', // 要生成的文件类型
    bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
    type: 'binary'
  };
  var wbout = XLSX.write(workbook, wopts);
  var blob = new Blob([s2ab(wbout)], {type:"application/octet-stream"});
  // 字符串转ArrayBuffer
  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i=0; i!==s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }
  return blob;
}

/**
 *
 * 处理列表编辑单元格
 * @param arr columns数组长
 * @param DataStr 列表数组变量字符串
 * @param rowSelectionStr  rowSelection变符串
 * @param idStr id字符串
 * @param that  当前组件
 * @param handleSave  保存的回调函数
 * @returns {*}
 */
export const handleCell1 =(arr,DataStr,rowSelectionStr,idStr,that,handleSave)=>{
  return arr.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave:(row)=> handleSave(row,DataStr,rowSelectionStr,idStr,that),
      }),
    };
  });
}
/**
 * 处理列表编辑单元格
 * @param arr columns数组长
 * @param DataStr 列表数组变量字符串
 * @param rowSelectionStr  rowSelection变符串
 * @param idStr id字符串
 * @param that  当前组件
 * @returns {*}
 */
export const handleCell =(arr,DataStr,rowSelectionStr,idStr,that)=>{
  return arr.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave:(row)=> handleSave(row,DataStr,rowSelectionStr,idStr,that),
      }),
    };
  });
}

/**
 * 保存列表编辑单元格
 * @param row 当前行对象
 * @param dataStr 列列量字符串
 * @param rowSelectionStr rowSelection字符串
 * @param idStr id字段字符串
 * @param that  当前组件
 */
export const handleSave = (row,dataStr,rowSelectionStr,idStr,that) => {
  /*修改列表数据 */
  let {selectedRowKeys,selectedRows} = that.state[rowSelectionStr]
  let newData = [...that.state[dataStr]];
  const index = newData.findIndex((item) => row[idStr] === item[idStr]);
  const item = newData[index];
  newData.splice(index, 1, { ...item, ...row });
  /*修改所选中行的数据 */
  let newSelectedRows = [...selectedRows]
  const rowIndex = newSelectedRows.findIndex((item) => row[idStr] === item[idStr]);
  if (rowIndex !== -1) {
    const rowItem = newSelectedRows[rowIndex];
    newSelectedRows.splice(rowIndex, 1, { ...rowItem, ...row });
  }
  that.setState({
    [dataStr]: newData,
    [rowSelectionStr]:{
      ...that.state[rowSelectionStr],
      selectedRowKeys,
      selectedRows:newSelectedRows
    }
  })
};

/**
 * 动态计算table的滚动距离
 * @param that  当前组件
 */
export const getSearchFormHeight = (that, isFooter,moreHeight) => {
  if (!that.formWrap) return
  that.setState({
    tableBodyHeight: computedTableBodyHeight(that.formWrap, isFooter, moreHeight)
  })
}

/**
 * 页码/pageSize改变时
 * @param _currentPage 当前页
 * @param _pageSize 一页显示多少条
 * @param that 当前组件
 * @param currentPageStr 当前页参数的字符串
 * @param pageSizeStr 一页显示多少条参数字符串
 * @param callback 查询列表的函数
 */
export const onPageNumChange = (_currentPage, _pageSize,that,currentPageStr,pageSizeStr,callback) => {
  let pageSize = that.state[pageSizeStr]
  if (pageSize !== _pageSize) {
    _currentPage=1
    pageSize=_pageSize
  }
  that.setState({
    [currentPageStr]:_currentPage,
    [pageSizeStr]:pageSize
  },()=>{
    callback()
  });
}

export const getCompanyName=(that,companyName)=> {
  switch (companyName) {
    case '1000':
      return formatMessage(that,'COMMONS_SHEN_MAI')/*深迈*/
    case '1910':
      return formatMessage(that,'COMMONS_SHEN_MAI_KE')/*深迈科*/
    default:
      return ''
  }
}

/**
 * userType = '1'  供应商
 * userType = '2'  采购员
 * userType = '0'  系统管理员
 * userType = '3'  计划员
 * 返回当前用户类型
 * @returns {*}
 */
export const getCurrentUserType =()=>{
  let user= getUser()
  if (user) {
    return user.userType;
  }else{
    return '';
  }
}

/**
 * userType = '1'  供应商
 * userType = '2'  采购员
 * userType = '0'  系统管理员
 * userType = '3'  计划员
 * 判断当前用户是否是采购员
 * @returns {boolean} true为是采购员或者,false为不是采购员
 */
export const currentAccountIsBuyer =()=>{
  let user= getUser()
  return user.userType==='2'
}

/**
 * userType = '1'  供应商
 * userType = '2'  采购员
 * userType = '0'  系统管理员
 * userType = '3'  计划员
 * 判断当前用户是否是采购员或者是系统管理员
 * @returns {boolean} true为是采购员或者是系统管理员,false为不是采购员也不是系统管理员
 */
export const currentAccountIsSupplierOrAdmin =()=>{
  let user= getUser()
  return user.userType==='2' || user.userType==='0'
}

/**
 * userType = '1'  供应商
 * userType = '2'  采购员
 * userType = '0'  系统管理员
 * userType = '3'  计划员
 * 判断当前用户是否是供应商
 * @returns {boolean} true为供应商,false为非供应商
 */
export const currentAccountIsSupplier=()=>{
  let user= getUser() || {}
  return user.userType==='1'
}

/**
 * userType = '0'  管理员
 * userType = '1'  供应商
 * userType = '2'  采购员
 * userType = '3'  采购主管
 * userType = '4'  采购经理
 * userType = '5'  财务人员
 * userType = '6'  会计主管
 * userType = '7'  MC类型
 * userType = '8'  半成品原材料发布专员
 * userType = '9'  试剂采购员
 * userType = 'A'  采购商务
 * userType = 'B'  成本会计
 * 根据类型组来判断
 * @returns {boolean} true为属于该组,false为非属于该组
 */
 export const currentAccountGroup=()=>{
  let user= getUser() || {}
  let allGroup={
    // 是否采购及以上
    isPurchase:false,
    // 是否是供应商
    isSupplier:false
  }
  // 是否采购及以上
  if(user.userType==='2'||user.userType==='3'||user.userType==='4'){
    allGroup.isPurchase=true
  }
  // 是否是供应商
  if(user.userType==='1'){
    allGroup.isSupplier=true
  }
  return allGroup
}

/**
 * userType = '1'  供应商
 * userType = '2'  采购员
 * userType = '0'  系统管理员
 * userType = '3'  计划员
 * 当前用户是供应商,搜索条件初如化供应商(当前用户是供应商时,供应商是禁用状态,不允许修改)
 * @returns {string} 返回当前供应商代码字符串
 */
export const initSupplierCode =()=>{
  let user= getUser() || {}
  let supplierCode = ''
  if(user.userType==='1'){
    supplierCode = (user && user.supplierCode) || ""
  }
  return supplierCode
}

export const initPurchaseCode =()=>{
  let user= getUser() || {}
  let username = ''
  if(user.userType==='2'){
    username = (user && user.username) || ""
  }
  return username
}

// FCST采购角色
export const FcstinitPurchaseCode =()=>{
  let user= getUser() || {}
  let username = ''
  if(user.userType==='2'||user.userType==='3'||user.userType==='4'||user.userType==='8'||user.userType==='9'){
    username = (user && user.username) || ""
  }
  return username
}
/**
 *
 * userType = '1'  供应商
 * userType = '2'  采购员
 * userType = '0'  系统管理员
 * userType = '3'  计划员
 * 当前用户是采购员时,搜索条件初如化采购员(采购员是下拉列表的时候)
 * @returns {string} 返回用户名
 */
export const initSelectListBuyer = ()=>{
  let user= getUser()
  let userName = []
  if(user.userType==='2'){
    userName.push(user.username)
  }
  return userName
}

/**
 * userType = '1'  供应商
 * userType = '2'  采购员
 * userType = '0'  系统管理员
 * userType = '3'  计划员
 * 当前用户是采购员时,搜索条件初如化采购员(采购员不是下拉列表的时候)
 * @returns {string}
 */
export const initBuyer = ()=>{
  let user= getUser()
  let username = ''
  if(user.userType==='2'){
    username = user.username
  }
  return username
}

/**
 * 设置工厂的初始化值
 * @param factoryList  factoryList 工厂列表
 * @param _this  _this 当前组件
 * @param factoryCode  接收工厂值的字段
 */
export const initFactoryCode = (factoryList,_this,factoryCode,searchFormRefStr)=>{
  let allItemArr = factoryList.filter(item=>item.factoryName==='全部')
  let firstItemList = factoryList.slice(0,1)
  let initArr = allItemArr.length? allItemArr:firstItemList
  if(initArr && initArr.length){
    // _this && _this.searchFormRef && _this.searchFormRef.current &&_this.searchFormRef.current.setFieldsValue({
    //   [factoryCode]:initArr[0].factoryCode
    // })
    _this?.[searchFormRefStr]?.current?.setFieldsValue({
      [factoryCode]:initArr[0].factoryCode
    }) ?? _this?.searchFormRef?.current?.setFieldsValue({
      [factoryCode]:initArr[0].factoryCode
    })
  }
}

/**
 * 计算某个日期 n 天后/前的日期
 * @param str 时间字符串 初始时间字符串如：2021-2-6
 * @param num 整数型，需要计算几天后的数字，正数为几天后，负数为几天前
 * @returns {string} 返回 n 天后的时间字符串,格式如：2021-8-16
 */
export const computedAfterDaysDate=(str,num)=>{
  let date = new Date(str)
  date.setDate(date.getDate()+num)
  return date.getFullYear() +"-"+ addZero((date.getMonth()+1)) +"-"+ addZero(date.getDate())
}
/**
 * 计算某个日期 n 天后/前的日期
 * @param str 时间字符串 初始时间字符串如：2021-2-6
 * @param num 整数型，需要计算几天后的数字，正数为几天后，负数为几天前
 * @returns {string} 返回 n 天后的时间字符串,格式如：20210816
 */
export const computedAfterDaysDateNoSymbol=(str,num)=>{
  let date = new Date(str)
  date.setDate(date.getDate()+num)
  return date.getFullYear()+ addZero((date.getMonth()+1))+ addZero(date.getDate())
}
/**
 * 计算某个日期 n 天后/前的日期
 * @param str 时间字符串 初始时间字符串如：2021-2-6 
 * @param num 整数型，需要计算几天后的数字，正数为几天后，负数为几天前
 * @returns {string} 返回 n 天后的时间字符串,格式如：20210816
 */
export const computedAfterDaysDateWithName=(str,num,name)=>{
  let date = new Date(str)
  date.setDate(date.getDate()+num)
  return (date.getMonth()+1) + '月' + date.getDate() + '日' + name
}

export const S4=()=> {
  return (((1+Math.random())*0x10000)|0).toString(16).substring(1);
}
/*生成uid*/
export const guid=()=> {
  return (S4()+S4()+"-"+S4()+"-"+S4()+"-"+S4()+"-"+S4()+S4()+S4());
}

/**
 * @param str 需要计算的字符串
 * @returns {number}
 */
export const byteLength =(str)=> {  //获取字符串的字节数，扩展string类型方法
  let b = 0
  let len = str.length;  //初始化字节数递加变量并获取字符串参数的字符个数
  if(len) {  //如果存在字符串，则执行计划
    for(let i = 0; i < len; i ++) {  //遍历字符串，枚举每个字符
      if(str.charCodeAt(i) > 255) {  //字符编码大于255，说明是双字节字符
        b += 2;  //则累加2个
      }else {
        b ++;  //否则递加一次
      }
    }
    return b;  //返回字节数
  } else {
    return 0;  //如果参数为空，则返回0个
  }
}
/**
 * @param _this
 * @param column  表格的column数组
 * @param columnsWidthSumStr 表格滚动宽度的变量名
 * @param columnsStr 表格column接收的变量名
 */
export const getColumnsWidthSum = (_this,column,columnsWidthSumStr,columnsStr)=>{
  const {lang} = _this.props
  let columns = column.map(item=>{
    item.width = lang==='zh'?item.zhWidth:item.enWidth;
    if (item.fixed && isIE()) {
      item.fixed = false
    }
    return item;
  })
  _this.setState({
    [columnsWidthSumStr]:calculateColumnsWidthSum(columns,0),
    [columnsStr]:columns,
  })
}

export const arrReducer = (accumulator, currentValue) => {
  if (!currentValue || !currentValue.width) {
    return accumulator;
  }

  let width = currentValue.width;
  if (typeof width === "string") {
    if (width.endsWith("px")) {
      width = parseFloat(width.split("px")[0]);
    } else {
      return accumulator;
    }
  } else if (typeof width === "number") {
    width = parseFloat(width);
  } else {
    return accumulator;
  }
  return accumulator + width;
}
/**
 * 动态计算table表格的columns的宽度
 * @param columns 表格列数组
 * @param lastColWidth 最后一行的宽度
 */
export const  calculateColumnsWidthSum = (columns = [], lastColWidth = 0) => {
  return columns.reduce(arrReducer, 0) + lastColWidth;
}

//表格行的背景颜色
export const getRowClassName = (record, index) => {
  let className = '';
  className = index % 2 === 0 ? 'oddRow': 'evenRow';
  return className;
}
/**
 * 文件上传类型
 * @type {string[]}
 */
export const fileTypes =["jpeg","bmp","jpg","tif",".jpg",".png",".jpeg ",".rar",
  ".zip",".doc",".docx",".xls",".xlsx",".pdf"];
/**
 *
 * @param filepath 文件名
 * @param filetypes 允许的文件类型列表
 * @returns {boolean}
 */
export const isFileTypes=(filepath,filetypes)=> {
  if (filepath) {
    var isnext = false;
    var fileend = filepath.substring(filepath.lastIndexOf("."));
    if (filetypes && filetypes.length > 0) {
      for (var i = 0; i < filetypes.length; i++) {
        if (filetypes[i] === fileend) {
          isnext = true;
          break;
        }
      }
    }
    return isnext
  }
}
//节流,防止一定时间多次触发
export const debounce = (fn, delay)=>{
  //记录上次的延时器
  var timer = null
  return ()=>{
    //清除上一次的延时器
    clearTimeout(timer)
    //重新设置新的延时器
    timer = setTimeout(()=>{
      fn.apply(window)
      // fn.apply(this)
    }, delay)
  }
}

/*处理列表单元格鼠标移上去提示内空*/
export const addTitle = (title)=>{
  return (
      <div title={title}>{title}</div>)
}

/*下载或者导出*/
export const createExportOrPrintLink = (bolb,type,filename='')=>{
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {//ie浏览器
    const name=decodeURI(filename);
    let b = new Blob([bolb],{type: type})
    window.navigator.msSaveOrOpenBlob(b, `${name}`);
  }else{//除ie以外的浏览器
    const downloadUrl=window.URL.createObjectURL(new Blob([bolb]),{type: type})
    const nodeLink=document.createElement('a');
    nodeLink.href=downloadUrl;
    const name=decodeURI(filename);
    nodeLink.setAttribute('download',`${name}`);
    document.body.appendChild(nodeLink);
    nodeLink.click();
    document.body.removeChild(nodeLink);
    window.URL.revokeObjectURL(downloadUrl);
  }
}

export const addZero=(num)=>{
  if(num<10){
    return '0' + num
  }
  return num
}
/*转换成 YYYY-MM-DD 格式日期*/
export const formateDayDate = time => {
  if (!time) return '';
  //解决ie问题,ie不识别'-',必须改成'/'
  let dateValue = time
  /*  if (typeof (time) === "string") {
      dateValue =time.replace(new RegExp(/-/gm) ,"/"); //转换
    }*/
  let date = new Date(dateValue);
  return date.getFullYear() + '-' + addZero((date.getMonth() + 1)) + '-' + addZero(date.getDate());
}

/**
 * 渲染列 当前单元格的值
 * @param text 当前单元格的值
 * @param record  当前行值
 * @param index 当前数据所在下标
 * @param data 当前分页所有数据
 * @param key 当前列的dataIndex
 * @returns {string|{children: (*|string), props: {}}}
 */
export const renderColumn=(text, record, index,data,key)=> {
  if (data && data.length === 0) {
    return ''
  }
  const obj = {
    children: text !== null ? addTitle(text): '',
    props: {}
  };
  obj.props.rowSpan = mergeCells(record[key], data, key, index)
  return obj
}


/**
 * 单元格合并处理
 * @param text 当前单元格的值
 * @param data 当前分页所有数据
 * @param key 当前列的dataIndex
 * @param index 当前数据所在下标
 * @returns {number} 待合并单元格数量
 */
export const mergeCells=(text, data, key, index)=> {
  if(!data){
    return 0
  }
  // 上一行该列数据是否一样
  if (data[index - 1]&& index !== 0 && text === data[index - 1][key]) {
    return 0
  }
  let rowSpan = 1
  // 判断下一行是否相等
  for (let i = index + 1; i < data.length; i++) {
    if (text !== data[i][key]) {
      break
    }
    rowSpan++
  }
  return rowSpan
}

//获取面包屑Item
export const getBreadItem = (list, that) => {
  const arr = [];
  function getItem(allList) {
    let lang =store.getState().lang
    allList.forEach((item, index) => {
      if (item.children && item.children.length > 0) {
        arr.push(
          <Breadcrumb.Item key={index}>
            <span style={{padding: '2px'}} >
            <span>{item.meta.title}</span>
              {/* <span>{lang==='en'?item.menuDescription:item.menuName}</span> */}
            </span>
          </Breadcrumb.Item>
        )
        getItem(item.children)

      } else {
        arr.push(
          <Breadcrumb.Item key={index}>
            <span style={{padding: '2px'}}>
            <span>{item.meta.title}</span>
              {/* <span>{lang==='en'?item.menuDescription:item.menuName}</span> */}
            </span>
          </Breadcrumb.Item>
        )
        if (that.props.history.location.pathname.split('/').length <= allList[0].path.split('/').length) return
        for (let i = 0; i < navLeftMenus.length; i++) {
          const navLeft = navLeftMenus[i];
          if (navLeft.key.startsWith(item.path + '/') && navLeft.key !== item.path && navLeft.isNeedShow) {
            arr.push(
              <Breadcrumb.Item key={index}>
                <span style={{padding: '2px'}}>
                  <span>{navLeft.title}</span>
                </span>
              </Breadcrumb.Item>
            )
          }
        }
      }
    })
  }
  getItem(list);
  return arr
}

//重定向到children的第一个路由
// export function recursionRouterTwo(userRouter = [], allRouter = []) { 
//   return allRouter
//     .filter(item => userRouter.includes(item.menuUrl))
//     .map(item => {
//       return {
//         ...item,
//         redirect: item.childMenuList && item.childMenuList.length ? item.childMenuList[0].menuUrl : null,
//         children: item.childMenuList
//           ? recursionRouterTwo(userRouter, item.childMenuList)
//           : null
//       }
//     })
// }
export function recursionRouterTwo(userRouter = [], allRouter = []) {
  return allRouter
    .filter(item => userRouter.includes(item.path))
    .map(item => {
      return {
        ...item,
        redirect: item.children && item.children.length ? item.children[0].path : null,
        children: item.children
          ? recursionRouterTwo(userRouter, item.children)
          : null
      }
    })
}
//左侧栏默认展开项
export const filterRoutes = pathname => {
  //将路径分割。例：/user/role  == 'user' 'role'
  let pathSnippets = pathname.split('/').filter(path => path)
  // 拼接路由中对应的路径 例：'user' 'role'  == /user   ;/user/role
  let paths = pathSnippets.map((path, index) =>`/${pathSnippets.slice(0, index + 1).join('/')}`)
  return paths
}
//根据后台数据筛选出路由表
export function recursionRouter(userRouter = [], allRouter = []) {
  const realRoutes = allRouter
    .filter(item => userRouter.includes(item.title))
    .map(item => ({
      ...item,
      children: item.children
        ? recursionRouter(userRouter, item.children)
        : null
    }))
  return realRoutes
}


//做placeholder,和message等的国际化的
export const formatMessage = (_that,id1,tipMsg='',id2='')=>{
  return (id1?_that.props.intl.formatMessage({id:id1}):'')+tipMsg+(id2?_that.props.intl.formatMessage({id:id2}):'')
}

/*获取屏幕的宽度*/
export function computedBodyWidth() {
  return document.body.offsetWidth
}
/*获取屏幕的高度*/
export function computedBodyHeight() {
  return document.body.offsetHeight
}
/**
 * @param formWrap {object}
 * @returns {number}
 */
export function computedTableBodyHeight(formWrap, isFooter, moreHeight) {
  if(!moreHeight) moreHeight = 0
  /*container上外边距 3 ,container下外边距 8,buts容器下外边距 3, table表头38, 分页30, 系统头部50,暂估合计 60*/
  const CUSTOMMARGIN = isFooter ?  3+8+3+38+30+50+isFooter : 3+8+3+38+30+50
  //搜索框和按扭的高度
  if (formWrap.current) {
    let SEARCHANDBUTHEIGHT = formWrap.current.offsetHeight
    return window.innerHeight-SEARCHANDBUTHEIGHT-CUSTOMMARGIN-moreHeight
  }
}

/*获取浏览器语言*/
export function getBrowseLang (){
  // let _lang = store.getState().lang
  let _lang
  var langStr = (navigator.language || navigator.browserLanguage).toLowerCase();
  //默认为中文
  let lang = 'zh'
  if(langStr.indexOf('en') >= 0){
    lang = 'en'
  }
  return _lang || lang
  // return lang
}

export const getFontSize = () => {
  const root = document.getElementById('root')
  return window.getComputedStyle(root).fontSize
}

export const getMaxLength = (result, fileName) => {
  if (!result) return
  if (result.length === 0) {
    return {
      maxstr: null,
      chineseStr: '为空返回此字符串'
    }
  }
  const arr = result.map(item => item[fileName] ? item[fileName] : '')
  let maxstr = ''
  maxstr = arr.reduce((acc, val) => {
    if (acc && val) {
      return acc.trim().length >= val.trim().length ? acc.trim() : val.trim()
    } else {
      return acc.trim() || val.trim()
    }
  })
  let chineseStr = ''
  let chineseArr = []
  arr.forEach(item => isChn(item) && item ? chineseArr.push(item) : '')
  chineseStr = chineseArr.length > 0 && chineseArr.reduce((acc, val) => acc && val ? acc.trim().length >= val.trim().length ? acc.trim() : val.trim() : acc.trim() || val.trim())
  if ((maxstr && maxstr.length < 7) || !maxstr) {
    return {
      maxstr: null,
      chineseStr: '表头的字符串较长'
    }
  }
  return {
    maxstr,
    chineseStr
  }
}

export function isChn(str) {
  var reg=/^[\u4E00-\u9FA5]+$/;
  if (!reg.test(str)){
      return false ;
  }else{
      return true ;
  }
}
/**
 *
 * @param {*需要设置的列} setWidthColumn
 * @param {*请求回来的列表数据} resData
 * @param {*全部的column} columnArr
 */
export const setColumnWidth = (setWidthColumn, resData, columnArr) => {
  if (!resData) return
  for (let i = 0; i < setWidthColumn.length; i++) {
    const {maxstr, chineseStr} = getMaxLength(resData, setWidthColumn[i])
    const strArr = maxstr ? maxstr.split('') : []
    let chineseCharactersCount = 0
    for (let k = 0; k < strArr.length; k++) {
      if (isChinese(strArr[k])) {
        chineseCharactersCount = chineseCharactersCount + 1
      }
      let notChineseCharactersCount = strArr.length - chineseCharactersCount
      const strWidth = chineseCharactersCount * (parseInt(getFontSize().slice(0, 2)) + 1) + notChineseCharactersCount * ((parseInt(getFontSize().slice(0, 2)))  - 4)
      const chineseWidth = chineseStr && chineseStr.split('').length * (parseInt(getFontSize().slice(0, 2)) + 1)
      if (columnArr && columnArr.length > 0) {
        for (let j = 0; j < columnArr.length; j++) {
          if (setWidthColumn[i] === columnArr[j].dataIndex) {
            columnArr[j].width = columnArr[j].minWidth?columnArr[j].minWidth:chineseWidth > strWidth ? chineseWidth : strWidth
            columnArr[j].zhWidth = columnArr[j].minWidth?columnArr[j].minWidth:chineseWidth > strWidth ? chineseWidth : strWidth
            columnArr[j].enWidth = columnArr[j].minWidth?columnArr[j].minWidth:chineseWidth > strWidth ? chineseWidth : strWidth
            break
          }
        }
      }
    }
  }
}

export const getMaxLength1 = (result, fileName) => {
  if (result.data.datas.length === 0) return '11111111'
  const arr = result.data.datas.map(item => {
    if(item[fileName]) {
      return item[fileName]
    } else {
      return ''
    }
  })
  let res = ''
  res = arr.reduce((acc, val) => {
    return acc.trim().length >= val.trim().length ? acc.trim() : val.trim();
  })
  if (res.length < 7) return '11111111'
  return res
}

export const setColumnWidth1 = (fileName, width, arr) => {
  for (let i = 0; i < arr.length; i++) {
    if (fileName === arr[i].dataIndex){
      arr[i].zhWidth = width
      arr[i].enWidth = width
      break
    }
  }
}

/* startList--添加数组对象，getIndex--指定得位置，endList--要添加得对象 */
// 在对象数组指定位置添加对象
export const addObject=(startList,getIndex,endList)=>{
  startList.splice(getIndex,0,endList)
  return startList
}

/* 获取年月 */
export const doData=(_year,isChange)=>{
  const allData=Object.keys(_year)
  const yearList=[]
  // const arr = {
  //   'firstMonth': 'mng00',
  //   'secondMonth':
  // }
  allData.forEach((item,index)=>{
      // if(item==='twelfthMonth'||item==='eleventhMonth'||item==='tenthMonth') return

      let lf_data={
        title: _year[item],//年月
        width: 80,
        zhWidth:80,
        enWidth:80,
        dataIndex: 'perid',
        ellipsis:true,
        fixed: false
      }

      switch(item) {
        case 'firstMonth':
          isChange==='0' ? lf_data.dataIndex = 'mngad00': lf_data.dataIndex = 'mng00'
          break
        case 'secondMonth':
          isChange==='0'?lf_data.dataIndex='mngad01':lf_data.dataIndex='mng01'
          break
        case 'thirdMonth':
          isChange==='0'?lf_data.dataIndex='mngad02':lf_data.dataIndex='mng02'
          break
        case 'fourthMonth':
          isChange==='0'?lf_data.dataIndex='mngad03':lf_data.dataIndex='mng03'
          break
        case 'fiveMonth':
          isChange==='0'?lf_data.dataIndex='mngad04':lf_data.dataIndex='mng04'
          break
        case 'sixMonth':
          isChange==='0'?lf_data.dataIndex='mngad05':lf_data.dataIndex='mng05'
          break
        case 'seventhMonth':
          isChange==='0'?lf_data.dataIndex='mngad06':lf_data.dataIndex='mng06'
          break
        case 'eighthMonth':
          isChange==='0'?lf_data.dataIndex='mngad07':lf_data.dataIndex='mng07Ex'
          break
        case 'ninthMonth':
          isChange==='0'?lf_data.dataIndex='mngad08':lf_data.dataIndex='mng08Ex'
          break
        case 'tenthMonth':
          isChange==='0'?lf_data.dataIndex='mngad09':lf_data.dataIndex='mng09Ex'
          break
        case 'eleventhMonth':
          isChange==='0'?lf_data.dataIndex='mngad10':lf_data.dataIndex='mng10'
          break
        case 'twelfthMonth':
          isChange==='0'?lf_data.dataIndex='mngad11':lf_data.dataIndex='mng11'
          break
        default:
          break
      }

      yearList.push(lf_data)
  })
  return yearList
}

/* 获取年月 */
export const doDataNew=(_year,isChange)=>{
  const allData=Object.keys(_year)
  const yearList=[]
  const yearPassageList=[]
  const yearResultList=[]
  // const arr = {
  //   'firstMonth': 'mng00',
  //   'secondMonth':
  // }
  allData.forEach((item,index)=>{
    // if(item==='twelfthMonth'||item==='eleventhMonth'||item==='tenthMonth') return

    let lf_data={
      title: _year[item],//年月
      width: 90,
      zhWidth:90,
      enWidth:90,
      dataIndex: 'perid',
      ellipsis:true,
      fixed: false
    }
    //在途
    let lf_data_passage={
      title: _year[item],//年月
      width: 145,
      zhWidth:145,
      enWidth:195,
      dataIndex: 'perid',
      ellipsis:true,
      fixed: false
    }
    let currentIndexStr=''

    switch(item) {
      case 'firstMonth':
        isChange==='0' ? lf_data.dataIndex = 'mngad00': lf_data.dataIndex = 'mng00'
        currentIndexStr='0'
        break
      case 'secondMonth':
        isChange==='0'?lf_data.dataIndex='mngad01':lf_data.dataIndex='mng01'
        currentIndexStr='1'
        break
      case 'thirdMonth':
        isChange==='0'?lf_data.dataIndex='mngad02':lf_data.dataIndex='mng02'
        currentIndexStr='2'
        break
      case 'fourthMonth':
        isChange==='0'?lf_data.dataIndex='mngad03':lf_data.dataIndex='mng03'
        currentIndexStr='3'
        break
      case 'fiveMonth':
        isChange==='0'?lf_data.dataIndex='mngad04':lf_data.dataIndex='mng04'
        currentIndexStr='4'
        break
      case 'sixMonth':
        isChange==='0'?lf_data.dataIndex='mngad05':lf_data.dataIndex='mng05'
        currentIndexStr='5'
        break
      case 'seventhMonth':
        isChange==='0'?lf_data.dataIndex='mngad06':lf_data.dataIndex='mng06'
        currentIndexStr='6'
        break
      case 'eighthMonth':
        isChange==='0'?lf_data.dataIndex='mngad07':lf_data.dataIndex='mng07Ex'
        currentIndexStr='7'
        break
      case 'ninthMonth':
        isChange==='0'?lf_data.dataIndex='mngad08':lf_data.dataIndex='mng08Ex'
        currentIndexStr='8'
        break
      case 'tenthMonth':
        isChange==='0'?lf_data.dataIndex='mngad09':lf_data.dataIndex='mng09Ex'
        currentIndexStr='9'
        break
      case 'eleventhMonth':
        isChange==='0'?lf_data.dataIndex='mngad10':lf_data.dataIndex='mng10'
        currentIndexStr='10'
        break
      case 'twelfthMonth':
        isChange==='0'?lf_data.dataIndex='mngad11':lf_data.dataIndex='mng11'
        currentIndexStr='11'
        break
      default:
        break
    }

    yearList.push(lf_data)

    lf_data_passage.title=lf_data.title+' '+intlErrorMessage('COMMON_PO_QUANTITY_ON_PASSAGE');
    lf_data_passage.dataIndex='poQty'+currentIndexStr;
    yearPassageList.push(lf_data_passage)
  })
  for (let i = 0; i < yearPassageList.length; i++) {
    yearResultList.push(yearPassageList[i]);
  }
  for (let i = 0; i < yearList.length; i++) {
    yearResultList.push(yearList[i]);
  }
  return yearResultList
}

/* 根据搜索条件年月获取列表表头 */
export const doDataTable=(_year,isChange,isAgo)=>{
  const allData=Object.keys(_year)
  const yearList=[]
  // const arr = {
  //   'firstMonth': 'mng00',
  //   'secondMonth':
  // }
  allData.forEach((item,index)=>{
      // if(item==='twelfthMonth'||item==='eleventhMonth'||item==='tenthMonth') return
      let lf_data={
        title: _year[item],//年月
        width: 80,
        zhWidth:80,
        enWidth:80,
        dataIndex: 'perid',
        ellipsis:true,
        fixed: false
      }
      const yearItem=_year[item]
      switch (item){
        case 'firstMonth':
          isAgo===true?lf_data.title='N':lf_data.title=yearItem
          isChange==='0'?lf_data.dataIndex='mngad00':lf_data.dataIndex='mng00'
          break
        case 'secondMonth':
          isAgo===true?lf_data.title='N+1':lf_data.title=yearItem
          isChange==='0'?lf_data.dataIndex='mngad01':lf_data.dataIndex='mng01'
          break
        case 'thirdMonth':
          isAgo===true?lf_data.title='N+2':lf_data.title=yearItem
          isChange==='0'?lf_data.dataIndex='mngad02':lf_data.dataIndex='mng02'
          break
        case 'fourthMonth':
          isAgo===true?lf_data.title='N+3':lf_data.title=yearItem
          isChange==='0'?lf_data.dataIndex='mngad03':lf_data.dataIndex='mng03'
          break
        case 'fiveMonth':
          isAgo===true?lf_data.title='N+4':lf_data.title=yearItem
          isChange==='0'?lf_data.dataIndex='mngad04':lf_data.dataIndex='mng04'
          break
        case 'sixMonth':
          isAgo===true?lf_data.title='N+5':lf_data.title=yearItem
          isChange==='0'?lf_data.dataIndex='mngad05':lf_data.dataIndex='mng05'
          break
        case 'seventhMonth':
          isAgo===true?lf_data.title='N+6':lf_data.title=yearItem
          isChange==='0'?lf_data.dataIndex='mngad06':lf_data.dataIndex='mng06'
          break
        case 'eighthMonth':
          isAgo===true?lf_data.title='N+7':lf_data.title=yearItem
          isChange==='0'?lf_data.dataIndex='mngad07':lf_data.dataIndex='mng07Ex'
          break
        case 'ninthMonth':
          isAgo===true?lf_data.title='N+8':lf_data.title=yearItem
          isChange==='0'?lf_data.dataIndex='mngad08':lf_data.dataIndex='mng08Ex'
          break
        case 'tenthMonth':
          isAgo===true?lf_data.title='N+9':lf_data.title=yearItem
          isChange==='0'?lf_data.dataIndex='mngad09':lf_data.dataIndex='mng09Ex'
          break
        case 'eleventhMonth':
          isAgo===true?lf_data.title='N+10':lf_data.title=yearItem
          isChange==='0'?lf_data.dataIndex='mngad10':lf_data.dataIndex='mng10'
          break
        case 'twelfthMonth':
          isAgo===true?lf_data.title='N+11':lf_data.title=yearItem
          isChange==='0'?lf_data.dataIndex='mngad11':lf_data.dataIndex='mng11'
          break
        default:
          break
      }

      yearList.push(lf_data)
  })
  return yearList
}



/* 根据搜索条件年月获取列表表头 */
export const doDataTableNew=(_year,isChange,isAgo)=>{
  const allData=Object.keys(_year)
  const yearList=[]
  const yearPassageList=[]
  const yearResultList=[]
  debugger;
  allData.forEach((item,index)=>{
    let lf_data={
      title: _year[item],//年月
      width: 80,
      zhWidth:80,
      enWidth:80,
      dataIndex: 'perid',
      ellipsis:true,
      fixed: false
    }
    //在途
    let lf_data_passage={
      title: _year[item],//年月
      width: 135,
      zhWidth:135,
      enWidth:195,
      dataIndex: 'perid',
      ellipsis:true,
      fixed: false
    }
    let currentIndexStr=''
    const yearItem=_year[item]
    switch (item){
      case 'firstMonth':
        currentIndexStr='0'
        isAgo===true?lf_data.title='N':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad00':lf_data.dataIndex='mng00'
        break
      case 'secondMonth':
        currentIndexStr='1'
        isAgo===true?lf_data.title='N+1':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad01':lf_data.dataIndex='mng01'
        break
      case 'thirdMonth':
        currentIndexStr='2'
        isAgo===true?lf_data.title='N+2':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad02':lf_data.dataIndex='mng02'
        break
      case 'fourthMonth':
        currentIndexStr='3'
        isAgo===true?lf_data.title='N+3':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad03':lf_data.dataIndex='mng03'
        break
      case 'fiveMonth':
        currentIndexStr='4'
        isAgo===true?lf_data.title='N+4':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad04':lf_data.dataIndex='mng04'
        break
      case 'sixMonth':
        currentIndexStr='5'
        isAgo===true?lf_data.title='N+5':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad05':lf_data.dataIndex='mng05'
        break
      case 'seventhMonth':
        currentIndexStr='6'
        isAgo===true?lf_data.title='N+6':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad06':lf_data.dataIndex='mng06'
        break
      case 'eighthMonth':
        currentIndexStr='7'
        isAgo===true?lf_data.title='N+7':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad07':lf_data.dataIndex='mng07Ex'
        break
      case 'ninthMonth':
        currentIndexStr='8'
        isAgo===true?lf_data.title='N+8':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad08':lf_data.dataIndex='mng08Ex'
        break
      case 'tenthMonth':
        currentIndexStr='9'
        isAgo===true?lf_data.title='N+9':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad09':lf_data.dataIndex='mng09Ex'
        break
      case 'eleventhMonth':
        currentIndexStr='10'
        isAgo===true?lf_data.title='N+10':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad10':lf_data.dataIndex='mng10'
        break
      case 'twelfthMonth':
        currentIndexStr='11'
        isAgo===true?lf_data.title='N+11':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad11':lf_data.dataIndex='mng11'
        break
      default:
        break
    }

    yearList.push(lf_data)
    lf_data_passage.title=lf_data.title+' '+intlErrorMessage('COMMON_PO_QUANTITY_ON_PASSAGE');
    lf_data_passage.dataIndex='poQty'+currentIndexStr;
    yearPassageList.push(lf_data_passage)
  })

  for (let i = 0; i < yearPassageList.length; i++) {
    yearResultList.push(yearPassageList[i]);
  }
  for (let i = 0; i < yearList.length; i++) {
    yearResultList.push(yearList[i]);
  }
  return yearResultList
}


/* 根据搜索条件年月获取列表表头 */
export const doDataTableNewForPo=(_year,isChange,isAgo)=>{
  const allData=Object.keys(_year)
  const yearList=[]
  const yearPassageList=[]
  const yearResultList=[]
  debugger;
  allData.forEach((item,index)=>{
    let lf_data={
      title: _year[item],//年月
      width: 80,
      zhWidth:80,
      enWidth:80,
      dataIndex: 'perid',
      ellipsis:true,
      fixed: false
    }
    //在途
    let lf_data_passage={
      title: _year[item],//年月
      width: 135,
      zhWidth:135,
      enWidth:195,
      dataIndex: 'perid',
      ellipsis:true,
      fixed: false
    }
    let currentIndexStr=''
    const yearItem=_year[item]
    switch (item){
      case 'firstMonth':
        currentIndexStr='0'
        isAgo===true?lf_data.title='N':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad00':lf_data.dataIndex='mng00'
        break
      case 'secondMonth':
        currentIndexStr='1'
        isAgo===true?lf_data.title='N+1':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad01':lf_data.dataIndex='mng01'
        break
      case 'thirdMonth':
        currentIndexStr='2'
        isAgo===true?lf_data.title='N+2':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad02':lf_data.dataIndex='mng02'
        break
      case 'fourthMonth':
        currentIndexStr='3'
        isAgo===true?lf_data.title='N+3':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad03':lf_data.dataIndex='mng03'
        break
      case 'fiveMonth':
        currentIndexStr='4'
        isAgo===true?lf_data.title='N+4':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad04':lf_data.dataIndex='mng04'
        break
      case 'sixMonth':
        currentIndexStr='5'
        isAgo===true?lf_data.title='N+5':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad05':lf_data.dataIndex='mng05'
        break
      case 'seventhMonth':
        currentIndexStr='6'
        isAgo===true?lf_data.title='N+6':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad06':lf_data.dataIndex='mng06'
        break
      case 'eighthMonth':
        currentIndexStr='7'
        isAgo===true?lf_data.title='N+7':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad07':lf_data.dataIndex='mng07Ex'
        break
      case 'ninthMonth':
        currentIndexStr='8'
        isAgo===true?lf_data.title='N+8':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad08':lf_data.dataIndex='mng08Ex'
        break
      case 'tenthMonth':
        currentIndexStr='9'
        isAgo===true?lf_data.title='N+9':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad09':lf_data.dataIndex='mng09Ex'
        break
      case 'eleventhMonth':
        currentIndexStr='10'
        isAgo===true?lf_data.title='N+10':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad10':lf_data.dataIndex='mng10'
        break
      case 'twelfthMonth':
        currentIndexStr='11'
        isAgo===true?lf_data.title='N+11':lf_data.title=yearItem
        isChange==='0'?lf_data.dataIndex='mngad11':lf_data.dataIndex='mng11'
        break
      default:
        break
    }

    yearList.push(lf_data)
    lf_data_passage.title=lf_data.title+' '+intlErrorMessage('COMMON_PO_QUANTITY_ON_PASSAGE');
    lf_data_passage.dataIndex='poQty'+currentIndexStr;
    yearPassageList.push(lf_data_passage)
  })

  for (let i = 0; i < yearPassageList.length; i++) {
    yearResultList.push(yearPassageList[i]);
  }
  /*for (let i = 0; i < yearList.length; i++) {
    yearResultList.push(yearList[i]);
  }*/
  return yearResultList
}


export const  mapFormOption = (searchArrList, expand,span, formatMessage, that) => {
  const searchItems = []
  const style = {width: '100%'}
  const tempArrList = searchArrList.map((item, index) => {
    if (index > 7) {
      return {
        ...item,
        span: 0
      }
    } else {
      return {
        ...item
      }
    }
  })
  if (expand) {
    for (let i = 0; i < searchArrList.length; i++) {
      if (i > 7 && !searchArrList[i].span) searchArrList[i].span = span
    }
  } else {
    // for (let i = 0; i < searchArrList.length; i++) {
    //   if (i > 7) searchArrList[i].span = 0
    // }
    searchArrList = tempArrList
  }
  searchArrList.forEach(item => {
    if (item.type === 'customInput') {
      searchItems.push(<Col span={item.span} key={item.key} className={item.className}>
        <CustomInput
          name={item.name}
          initialValue={item.initialValue}
          labelId={item.formatMessageId}
          placeholderId={item.formatMessageId}
          placeholder={item.placeholder}
          inputDisabled={item.disabled}
          labelCol={item.labelCol}
          wrapperCol={item.wrapperCol}
          rules={item.rules}
          maxLength={item.maxLength}
          keyPress={item.keyPress ? e => item.keyPress(e, searchArrList, that) : null}
          onBlur={item.onBlur ? e => item.onBlur(e, searchArrList, that) : null}
          onFocus={item.onFocus ? e => item.onFocus(e, that) : null}
          extra={item.extra}
        />
      </Col>)
    } else if (item.type === 'customInputButton') {
      searchItems.push(<Col span={item.span} key={item.key}>
        <Row>
          <Col span={20}>
            <CustomInput
              name={item.name}
              initialValue={item.initialValue}
              labelId={item.formatMessageId}
              placeholderId={item.placeholderId ? item.placeholderId : item.formatMessageId}
              placeholder={item.placeholder}
              inputDisabled={item.disabled}
              labelCol={item.labelCol}
              wrapperCol={item.wrapperCol}
              rules={item.rules}
              maxLength={item.maxLength}
              keyPress={item.keyPress ? e => item.keyPress(e, searchArrList, that) : null}
              onBlur={item.onBlur ? e => item.onBlur(e, searchArrList, that) : null}
              onFocus={item.onFocus ? e => item.onFocus(e, that) : null}
            />
          </Col>
          <Col span={4}>
            <Button onFocus={item.onClick ? e => item.onClick(e, that) : null}>{item.bottonText ? item.bottonText : '选择'}</Button>
          </Col>
        </Row>
      </Col>)
    } else if(item.type === 'uploadImg') {
      searchItems.push(
        <Col span={item.span} key={item.key}>
          <Row gutter={item.gutter}>
            <Col span={item.inputSpan}>
              <CustomInput
                name={item.name}
                initialValue={item.initialValue}
                labelId={item.formatMessageId}
                placeholderId={item.formatMessageId}
                inputDisabled={item.disabled}
                labelCol={item.labelCol}
                wrapperCol={item.wrapperCol}
                rules={item.rules}
              />
            </Col>
            <Col span={item.buttonSpan}>
              <CustomButton
                style={item.style}
                id={item.id}
                onClick={item.inputOnClick ? e => item.inputOnClick(e, that) : null}
              ></CustomButton>
            </Col>
          </Row>
        </Col>
      )
    } else if (item.type === 'select') {
      searchItems.push(<Col span={item.span} key={item.key} style={item.style}>
        <SelectList
          name={item.name}
          label={item.formatMessageId}
          initialValue={item.initialValue}
          rules={item.rules}
          labelCol={item.labelCol}
          wrapperCol={item.wrapperCol}
          icon={item.icon}
          disabled={item.disabled}
          mode={item.mode}
          maxTagCount={item.maxTagCount}
          isNouseFormatted={item.isNouseFormatted}
          options={item.options}
          valueName={item.valueName}
          labelNam={item.labelNam}
          onFocus={item.onFocus ? e => item.onFocus(e, that) : null}
          onChange={item.onChange ? e => item.onChange(e, searchArrList, that) : null}
        />
      </Col>)
    }  else if (item.type === 'selectPageList') {
      searchItems.push(<Col span={item.span} key={item.key} style={item.style}>
        <SelectPageList
          name={item.name}
          label={item.formatMessageId}
          initialValue={item.initialValue}
          rules={item.rules}
          labelCol={item.labelCol}
          wrapperCol={item.wrapperCol}
          icon={item.icon}
          disabled={item.disabled}
          mode={item.mode}
          maxTagCount={item.maxTagCount}
          isNouseFormatted={item.isNouseFormatted}
          options={item.options}
          valueName={item.valueName}
          labelNam={item.labelNam}
          defaultValue={item.defaultValue}
          onFocus={item.onFocus ? e => item.onFocus(e, that) : null}
          onChange={item.onChange ? e => item.onChange(e, searchArrList, that) : null}
          onPageChange={item.onPageChange ? e => item.onPageChange(e, searchArrList, that) : null}
        />
      </Col>)
    } else if (item.type === 'customDictSelect') {
      searchItems.push(<Col span={item.span} key={item.key} className={item.className}>
        <CustomDictSelect
          name={item.name}
          initialValue={item.initialValue}
          formatMessageId={item.formatMessageId}
          icon={item.icon}
          maxTagCount={item.maxTagCount}
          onFocus={item.onFocus ? e => item.onFocus(e, that) : null}
          onChange={item.onChange ? e => item.onChange(e, searchArrList, that) : null}
          disabled={item.disabled}
          labelCol={item.labelCol}
          wrapperCol={item.wrapperCol}
          rules={item.rules}
          mode={item.mode}
          style={item.style}
          dictTypeName={item.dictTypeName}
        />
      </Col>)
    } else if (item.type === 'customDictRadio') {
      searchItems.push(<Col span={item.span} key={item.key} className={item.className}>
        <CustomDictRadio
          name={item.name}
          initialValue={item.initialValue}
          formatMessageId={item.formatMessageId}
          icon={item.icon}
          maxTagCount={item.maxTagCount}
          onFocus={item.onFocus ? e => item.onFocus(e, that) : null}
          onChange={item.onChange ? e => item.onChange(e, searchArrList, that) : null}
          disabled={item.disabled}
          labelCol={item.labelCol}
          wrapperCol={item.wrapperCol}
          rules={item.rules}
          mode={item.mode}
          style={item.style}
          itemStyle={item.itemStyle}
          dictTypeName={item.dictTypeName}
        />
      </Col>)
    } else if (item.type === 'customDictCheckbox') {
      searchItems.push(<Col span={item.span} key={item.key} className={item.className}>
        <CustomDictCheckbox
          name={item.name}
          initialValue={item.initialValue}
          formatMessageId={item.formatMessageId}
          icon={item.icon}
          maxTagCount={item.maxTagCount}
          onFocus={item.onFocus ? e => item.onFocus(e, that) : null}
          onChange={item.onChange ? e => item.onChange(e, searchArrList, that) : null}
          disabled={item.disabled}
          labelCol={item.labelCol}
          wrapperCol={item.wrapperCol}
          rules={item.rules}
          mode={item.mode}
          style={item.style}
          itemStyle={item.itemStyle}
          dictTypeName={item.dictTypeName}
        />
      </Col>)
    } else if (item.type === 'rangePicker') {
      searchItems.push(<Col span={item.span} key={item.key}>
        <RangePickerDate
          name={item.name}
          label={item.formatMessageId}
          initialValue={item.initialValue}
          rules={item.rules}
          labelCol={item.labelCol}
          wrapperCol={item.wrapperCol}
          disabled={item.disabled}
          defaultValue={item.defaultValue}
        />
      </Col>)
    } else if (item.type === 'datePicker') {
      const disabledDate = (current) => {
        return current && current < moment().subtract(1,"days")
      }
      searchItems.push(<Col span={item.span} key={item.key}>
        <DatePickerDate
          name={item.name}
          label={item.formatMessageId}
          initialValue={item.initialValue}
          rules={item.rules}
          labelCol={item.labelCol}
          wrapperCol={item.wrapperCol}
          style={item.style}
          disabled={item.disabled}
          format={item.format ? item.format : item.pickerMonth ? 'YYYY-MM' : 'YYYY-MM-DD'}
          showTime={item.showTime}
          disabledDate={item.disabledDateFlag ? disabledDate : ''}
          picker={item.picker}
        />
        {/* <Form.Item
          name={item.name}
          label={formatMessage({id: item.formatMessageId})}
          initialValue={item.initialValue}
          rules={item.rules}
          labelCol={item.labelCol}
          wrapperCol={item.wrapperCol}
        >
          <DatePicker style={style} disabled={item.disabled} format={item.format ? item.format : item.pickerMonth ? 'YYYY-MM' : 'YYYY-MM-DD'} showTime={item.showTime} disabledDate={item.disabledDateFlag ? disabledDate : ''} picker={item.picker}/>
        </Form.Item> */}
      </Col>)
    } else if (item.type === 'factoryCode') {
      searchItems.push(<Col span={item.span} key={item.key ? item.key : item.type}>
        {/*工厂*/}
        <FactorySelectList labelCol={item.labelCol} initialValue={item.initialValue} rules={item.rules} disabled={item.disabled} filterFn={item.filterFn} factoryList={that.state.factoryList} name={item.name ? item.name : item.type} that={that}/>
      </Col>)
    } else if (item.type === 'purCode') {
      searchItems.push(<Col span={item.span} key={item.key}>
        {/* 采购员列表 */}
        <PurchaserCodeSelectList labelCol={item.labelCol} disabled={item.disabled} purchaserCodeList={that.state.purchaserCodeList} name={item.name}/>
      </Col>)
    } else if (item.type === 'cascaderList') {
      searchItems.push(<Col span={item.span} key={item.key}>
      {/* <Form.Item name={item.name} label={formatMessage({id: item.formatMessageId})} initialValue={item.initialValue} rules={item.rules} labelCol={item.labelCol} wrapperCol={item.wrapperCol}>
        <Cascader options={item.options} disabled={item.disabled} fieldNames={item.fieldNames}/>
      </Form.Item> */}
      <CategoryListCode
        categoryTreeList={that.state.categoryTreeList}
        disabled={item.disabled}
        fieldNames={item.fieldNames}
        label={item.formatMessageId}
        initialValue={item.initialValue}
        name={item.name}
        changeOnSelect={item.changeOnSelect}
        multiple={item.multiple}
        labelCol={item.labelCol}
        onFocus={item.onFocus ? e => item.onFocus(e, that) : null}
        onBlur={item.onBlur ? e => item.onBlur(e, searchArrList, that) : null}
        onChange={item.onChange ? e => item.onChange(e, searchArrList, that) : null}
      ></CategoryListCode>
      </Col>)
    } else if (item.type === 'cascaderClass') {
      searchItems.push(<Col span={item.span} key={item.key}>
      {/* <Form.Item name={item.name} label={formatMessage({id: item.formatMessageId})} initialValue={item.initialValue} rules={item.rules} labelCol={item.labelCol} wrapperCol={item.wrapperCol}>
        <Cascader options={item.options} disabled={item.disabled} fieldNames={item.fieldNames}/>
      </Form.Item> */}
      <CategoryListCode
        categoryTreeList={that.state.materialClassificationList}
        disabled={item.disabled}
        fieldNames={item.fieldNames}
        label={item.formatMessageId}
        initialValue={item.initialValue}
        name={item.name}
        changeOnSelect={item.changeOnSelect}
        multiple={item.multiple}
        labelCol={item.labelCol}
        rules={item.rules}
        onFocus={item.onFocus ? e => item.onFocus(e, that) : null}
        onBlur={item.onBlur ? e => item.onBlur(e, searchArrList, that) : null}
        onChange={item.onChange ? e => item.onChange(e, searchArrList, that) : null}
      ></CategoryListCode>
      </Col>)
    } else if (item.type === 'cascaderUnit') {
      searchItems.push(<Col span={item.span} key={item.key}>
      {/* <Form.Item name={item.name} label={formatMessage({id: item.formatMessageId})} initialValue={item.initialValue} rules={item.rules} labelCol={item.labelCol} wrapperCol={item.wrapperCol}>
        <Cascader options={item.options} disabled={item.disabled} fieldNames={item.fieldNames}/>
      </Form.Item> */}
      <CategoryListCode
        categoryTreeList={that.state.unitClassificationList}
        disabled={item.disabled}
        fieldNames={item.fieldNames}
        label={item.formatMessageId}
        initialValue={item.initialValue}
        name={item.name}
        changeOnSelect={item.changeOnSelect}
        multiple={item.multiple}
        labelCol={item.labelCol}
        rules={item.rules}
        onFocus={item.onFocus ? e => item.onFocus(e, that) : null}
        onBlur={item.onBlur ? e => item.onBlur(e, searchArrList, that) : null}
        onChange={item.onChange ? e => item.onChange(e, searchArrList, that) : null}
      ></CategoryListCode>
      </Col>)
    } else if (item.type === 'cascaderAddress') {
      searchItems.push(<Col span={item.span} key={item.key}>
      <CategoryListCode
        categoryTreeList={that.state.areaTreeList}
        disabled={item.disabled}
        fieldNames={item.fieldNames}
        label={item.formatMessageId}
        initialValue={item.initialValue}
        name={item.name}
        changeOnSelect={item.changeOnSelect}
        multiple={item.multiple}
        labelCol={item.labelCol}
        rules={item.rules}
        onFocus={item.onFocus ? e => item.onFocus(e, that) : null}
        onBlur={item.onBlur ? e => item.onBlur(e, searchArrList, that) : null}
        onChange={item.onChange ? e => item.onChange(e, searchArrList, that) : null}
      ></CategoryListCode>
      </Col>)
    }else if (item.type === 'span') {
      searchItems.push(
      <Col span={item.span} key={item.key}>
        <Form.Item
          name={item.name}
          label={formatMessage({id: item.formatMessageId})}
          rules={item.rules}
          labelCol={item.labelCol}
          wrapperCol={item.wrapperCol}
        >
          <span>
            {that.searchFormRef ? that.searchFormRef.current.getFieldsValue()[item.name] : null}
          </span>
        </Form.Item>
      </Col>)
    } else if (item.type === 'radio') {
      searchItems.push(<Col span={item.span} key={item.key}>
        <Form.Item name={item.name} label={formatMessage({id: item.formatMessageId})} initialValue={item.initialValue} rules={item.rules} labelCol={item.labelCol} wrapperCol={item.wrapperCol}>
          <Radio.Group onChange={e => item.onChange(e, that)}>
            {item.options.map(radio => <Radio value={radio.value}><FormattedMessage id={radio.label}/></Radio>)}
          </Radio.Group>
        </Form.Item>
      </Col>)
    } else if (item.type === 'inputnumber') {
      searchItems.push(<Col span={item.span} key={item.key}>
        <Form.Item name={item.name} label={formatMessage({id: item.formatMessageId})} initialValue={item.initialValue} rules={item.rules} labelCol={item.labelCol} wrapperCol={item.wrapperCol} extra={item.extra} getValueFromEvent={item.getValueFromEvent}>
          <InputNumber style={style} min={item.min} max={item.max} precision={item.precision} disabled={item.disabled}/>
        </Form.Item>
      </Col>)
    } else if (item.type === 'customtablenotype') {
      const _columns = handleCell(item.columns(that), item.dataSource, item.rowSelectionStr,item.rowKey,that)
      searchItems.push(<Col span={item.span} key={item.key}>
        <Form.Item label={item.formatMessageId ? formatMessage({id: item.formatMessageId}) : null}>
          <div className="buts-container">
            {/* <CustomButton id='COMMON_ADD' onClick={item.onClick ? e => item.onClick(e, that) : null}></CustomButton> */}
            {
              item.btnList.map(btn => <CustomButton id={btn.id} onClick={btn.onClick ? e => btn.onClick(e, that) : null}></CustomButton>)
            }
          </div>
          <CustomTableNoType
            columns={_columns}
            dataSource={that.state[item.dataSource]}
            rowKey={item.rowKey}
            components={item.components}
          ></CustomTableNoType>
        </Form.Item>
      </Col>)
    } else if (item.type === 'textarea') {
      searchItems.push(<Col span={item.span} key={item.key}>
        <Form.Item
          name={item.name}
          label={item.formatMessageId ? formatMessage({id: item.formatMessageId}) : ''}
          initialValue={item.initialValue}
          rules={item.rules}
          labelCol={item.labelCol}
          wrapperCol={item.wrapperCol}
        >
          <TextArea
            disabled={item.disabled}
            allowClear
            rows={item.rows}
            maxLength={item.maxLength}
          />
        </Form.Item>
      </Col>)
    } else if (item.type === 'checkbox') {
      searchItems.push(<Col span={item.span} key={item.key} className={item.className} style={item.style}>
        <CustomCheckbox
          name={item.name}
          label={item.formatMessageId}
          initialValue={item.initialValue}
          rules={item.rules}
          labelCol={item.labelCol}
          valuePropName={item.valuePropName}
          wrapperCol={item.wrapperCol}
          disabled={item.disabled}
          onChange={item.onChange ? e => item.onChange(e, searchArrList, that) : null}
        />
      </Col>)
    } else if (item.type === 'slot') {
      const renderContent = item.render(formatMessage, that);
      searchItems.push(<Col span={item.span} key={item.key} className={item.className} style={item.style}>
        {renderContent}
      </Col>)
    }
  })
  return searchItems
}

export const limitSpecialCharacters = () => {}

export function isChinese(temp) {
  var regex = /(?:[\u3400-\u4DB5\u4E00-\u9FEF\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\uD840-\uD868\uD86A-\uD86C\uD86F-\uD872\uD874-\uD879][\uDC00-\uDFFF]|\uD869[\uDC00-\uDED6\uDF00-\uDFFF]|\uD86D[\uDC00-\uDF34\uDF40-\uDFFF]|\uD86E[\uDC00-\uDC1D\uDC20-\uDFFF]|\uD873[\uDC00-\uDEA1\uDEB0-\uDFFF]|\uD87A[\uDC00-\uDFE0])/;
  return regex.test(temp)
}

/**
 *
 * @param {*当前组件this} that
 * @param {*文件名-国际化string} idStr
 * @param {*导出请求接口} request
 */
export const downloadExcel = async (that, idStr, request, params) => {
  let searchCondition = {...that.searchCondition, ...params}
  searchCondition.pageSize = null
  searchCondition.currentPage = null
  const response = await request(searchCondition)
  if (response) {
    const type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml'
    const filename = `${formatMessage(that,idStr)}${moment(new Date()).format('MM月DD日 HH时mm分ss秒')}.xlsx`
    createExportOrPrintLink(response.data,type,filename)
  }
}

export const getUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0,
          // eslint-disable-next-line eqeqeq, no-mixed-operators
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

export const setExpandColumnDataArr = (colomns, that, expandColumnDataArrStr) => {
  const expandColumnDataArr = colomns.map(item => {
    return {
      id: item.dataIndex,
      columnName: formatMessage(that, item.title.props.id)
    }
  })
  that.setState({
    [expandColumnDataArrStr]: expandColumnDataArr
  })
}

export const expandColumnClick = (columns, expandColumnModalRef, that, visible) => {
  const expandSelectedRowKeys = columns.map(item => item.dataIndex)
  const expandSelectedRows = columns.map(item => {
    return {
      id: item.dataIndex,
      columnName: formatMessage(that, item.title.props.id)
    }
  })
  that[expandColumnModalRef].current.state.rowSelection = {
    selectedRowKeys: expandSelectedRowKeys,
    selectedRows: expandSelectedRows
  }
  that.setState({
    [visible]: true
  })
}

export const changeColumns = (selectedRows, columns, expandColumnModalRef, that, visible, listColumnsName, actionsName) => {
  const columnsArr = columns.filter(item => selectedRows.some(selected => item.dataIndex === selected.id))
  const expandSelectedRowKeys = columnsArr.map(item => item.dataIndex)
  const expandSelectedRows = columnsArr.map(item => {
    return {
      id: item.dataIndex,
      columnName: formatMessage(that, item.title.props.id)
    }
  })
  that[actionsName].forEach((item) => columnsArr.push(item))
  that.setState({
    [listColumnsName]: columnsArr,
    [visible]: false,
  })
  that[expandColumnModalRef].current.changeRowSelection(expandSelectedRowKeys, expandSelectedRows)
}

export const getDay = (num) => {
  var date1 = new Date(),
  time1 = date1.getFullYear() + "-" + (date1.getMonth()+1) + "-" + date1.getDate() // time1表示当前时间
  var date2 = new Date(date1)
  date2.setDate(date1.getDate() + num)
  var time2 = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate()
  return {
    time1,
    time2
  }
}

export const formatDate = (oldDate) => {
  // 方式1 转换为'yyyy-MM-dd HH:mm:ss'
  function add0(num) { return num < 10 ? '0' + num : num } // 个位数的值在前面补0
  const date = new Date(oldDate)
  const Y = date.getFullYear()
  const M = date.getMonth() + 1
  const D = date.getDate()

  const dateString = Y + '-' + add0(M) + '-' + add0(D)
  return dateString

  // 方式2 转换为'yyyy/MM/dd HH:mm:ss'
  // return new Date(oldDate).toLocaleString()
}

export const showBtnFun = async (btnList, that) => {
  const showBtnObj = {}
  if (!(btnList && btnList.length > 0)) return
  for (let i = 0; i < btnList.length; i++) {
    for (const key in that.state.showBtn) {
      if (Object.hasOwnProperty.call(that.state.showBtn, key)) {
        if (btnList[i] === key) {
          showBtnObj[key] = true
        }
      }
    }
  }
  that.setState({
    showBtn: showBtnObj
  })
}

export const toD = (val) => {
  const e = String(val)
  let rex = /^([0-9])\.?([0-9]*)e-([0-9])/
  if (!rex.test(e)) return val
  const numArr = e.match(rex)
  const n = Number('' + numArr[1] + (numArr[2] || ''))
  const num = '0.' + String(Math.pow(10, Number(numArr[3]) - 1)).substr(1) + n
  return num.replace(/0*$/, '') // 防止可能出现0.0001540000000的情况
}

export const byValueGetLabel = (arr1, arr2, filed) => {
  for (let i = 0; i < arr1.length; i++) {
    const item = arr1[i];
    for (let j = 0; j < arr2.length; j++) {
      const orderType = arr2[j];
      if (item[filed] === orderType.value) {
        item[filed] = orderType.label
        break
      }
    }
  }
}

export const getPermissionBtn = (isShowBtn, authButtonList) => {
  const hasPermissions = authButtonList.some(permissions => {
    return permissions === "*:*:*"
  })
  if (hasPermissions) {
    for (const key in isShowBtn) {
      authButtonList.push(key)
    }
  }
  const showBtn = {}
  for (const key in isShowBtn) {
    if (Object.hasOwnProperty.call(isShowBtn, key)) {
      for (let i = 0; i < authButtonList.length; i++) {
        const item = authButtonList[i];
        if (key === item) {
          showBtn[key] = true
          break
        }
      }
    }
  }
  return showBtn
}

export const isEmptyObj = (obj) => {
  return JSON.stringify(obj) === '{}'
}


const intlErrorMessage=(id)=>{
  const lang = store.getState().lang
  return lang === 'en'?en_US[id]:zh_CN[id]
}

export const empty2Zero=(sourceValue)=>{
  // eslint-disable-next-line eqeqeq
  if(sourceValue == null || sourceValue == undefined || sourceValue == ''){
    return 0;
  }
  return sourceValue;
}
