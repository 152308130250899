//解决ie问题
import "./polyfill";
import "core-js/es";
import "mutation-observer";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {getUser} from "./utils/disk-cache/disk-cache";
import {saveUserCache} from './utils/memory/memory';
import { Provider } from "react-redux";
import {PersistGate} from 'redux-persist/lib/integration/react'
import {persistor} from './store/store'
import store from './store/store'

function getApp() {
    const user = getUser();
    if (user && user.username) {
          saveUserCache(user);
    }
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    )
}


ReactDOM.render(getApp(),
  document.getElementById('root')
);
