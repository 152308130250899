const SRM_CACHE = {}
/*

*'125' pur012  usertype = 2
*'123' usertype = 0
*'124' mxp003  usertype = 1
* */
/*
* user.userType
* userType = 0  系统管理员
* userType = 1  供应商
* userType = 2  采购员
* userType = '3'  计划员
* mxp003
* */

export function getUserCache() {
    const {USER} = SRM_CACHE;
    return USER;
}

export function saveUserCache(user) {
    SRM_CACHE.USER = user;
}

export function removeUserCache() {
    // SRM_CACHE.USER = undefined;
    SRM_CACHE.USER = {}
}

export function getUserName() {
    const user = SRM_CACHE.USER;
    if (user && user.username) {
        return user.username;
    }
    // return "未登录";
    return "";
}

export function getUserCompany() {
    const user = SRM_CACHE.USER;
    if (user && user.userCompany) {
        return user.userCompany;
    }
    // return "未登录";
    return "";
}

