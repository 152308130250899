// import React, { Children } from 'react'
import React from 'react'
import { HomeOutlined} from '@ant-design/icons';
import asyncComponent from '../utils/asyncComponent'
export const navLeftMenus = [
    {
        title: '首页',
        enTitle:'Home',
        key: '/home',
        icon:<HomeOutlined />,
        component: asyncComponent(() => import('../pages/admin/home/home')),
    },
    {
        title: '登录跳转页',
        enTitle:'Login jump',
        key: '/auth/password/login',
        component: asyncComponent(() => import('../pages/auth/password/login')),
    },
    {
        title: '老系统获取token页',
        enTitle:'Old System Get Token',
        key: '/auth/password/gettokenforoldsystem',
        component: asyncComponent(() => import('../pages/auth/password/gettokenforoldsystem')),
    },
    {
        title: '注销页',
        enTitle:'/auth/logout',
        key: '/home',
        component: asyncComponent(() => import('../pages/auth/logout')),
    },
    {// 注册页面
        title: '注册',
        enTitle:'register',
        key: '/register',
        component:asyncComponent(() => import('../pages/register/register'))
    },
    {// 忘记密码页面
        title: '忘记密码',
        enTitle:'forget password',
        key: '/forgetPassword',
        component:asyncComponent(() => import('../pages/login/login-forget-password'))
    },
    {// 个人中心/用户列表
        title: '用户列表',
        enTitle:'List of users',
        key: '/center/userList',
        component: asyncComponent(() => import('../pages/admin/personal-center/user/user-list')),
    },
    {// 个人中心/我的供应商
        title: '我的供应商',
        enTitle:'My supplier',
        key: '/center/supplierList',
        component: asyncComponent(() => import('../pages/admin/personal-center/my-supplier/supplier-list')),
    },
    {// 个人中心/供应商信息管理/物料送货提前维护
        title: '物料送货提前维护',
        enTitle:'Material delivery maintenance in advance',
        key: '/center/supplierInformationManager/old/supplier/MaterialDeliveryMaintenanceInAdvance',
        component: asyncComponent(() => import('../pages/admin/center/supplierInformationManager/material-delivery-maintenance-in-advance/material-delivery-maintenance-in-advance')),
    },
    {// 个人中心/供应商信息管理/供应商物料地址管理
        title: '供应商物料地址管理',
        enTitle:'Supplier Material Address Management',
        key: '/center/supplierInformationManager/supplierMaterialAddressManagement',
        component: asyncComponent(() => import('../pages/admin/center/supplierInformationManager/supplier-material-address-management/supplier-material-address-management')),
    },
    {// 关务管理/生成送货单
        title: '生成送货单',
        enTitle:'Generate delivery note',
        key: '/customsManager/customsOrderList',
        exact:true,
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-generate-order-list/customs-generate-order-list')),
    },
    {// 关务管理/送货单管理
        title: '送货单管理',
        enTitle:'Delivery note management',
        key: '/customsManager/deliveryManageList',
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-delivery-manage-list/customs-delivery-manage-list')),
    },
    {// 关务管理/采购送货单管理
        title: '采购送货单管理',
        enTitle:'Purchase delivery note management',
        key: '/customsManager/purchaseDeliveryManageList',
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-purchase-delivery-manage-list/customs-purchase-delivery-manage-list')),
    },
    {// 关务管理/装箱单明细查询
        title: '装箱单明细查询',
        enTitle:'Packing list details inquiry',
        key: '/customsManager/arrangeCarManageList',
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-packing-list-details/customs-packing-list-details')),
    },
    {// 关务管理/EDI生成送货单报表
        title: 'EDI生成送货单报表',
        enTitle:'EDI generates delivery note Report',
        key: '/customsManager/generateEdiDeliveryNote',
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-generate-edi-delivery-note/customs-generate-edi-delivery-note')),
    },
    {// 关务管理/EDI生成快递单报表
        title: 'EDI生成快递单报表',
        enTitle:'EDI generates express order report',
        key: '/customsManager/generatesEdiExpressOrderReport',
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-generates-edi-express-order-report/customs-generates-edi-express-order-report')),
    },
    {// 关务管理/车次管理
        title: '车次管理',
        enTitle:'Service management',
        key: '/customsManager/truckList',
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-truck-list/customs-truck-list')),
    },
    {// 关务管理/基础数据
        title: '报关基础数据',
        enTitle:'customs basic data',
        key: '/customsManager/basedataList',
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-base-data/customs-base_data_list')),
    },
    {// 关务管理/领导签名
        title: '领导签名',
        enTitle:'leader signature',
        key: '/customsManager/leaderSignature',
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-leader-signature/customs-leader-signature')),
    },
    {// 关务管理/报关基础数据查询
        title: '报关基础数据查询',
        enTitle:'Basic data query for customs declaration',
        key: '/customsManager/basicDataQueryForCustomsDeclaration',
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-basic-data-query-declaration/customs-basic-data-query-declaration')),
    },
    {// 关务管理/装箱单列表
        title: '装箱单列表',
        enTitle:'Packing list list',
        key: '/customsManager/CustomsPackingListList',
        // key: '/customsManager/old/dac/queryTruckBillList.action',
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-packing-list-list/customs-packing-list-list')),
    },
    {// 关务管理/进出口业务申请单
        title: '进出口业务申请单',
        enTitle:'Import and export business application form',
        key: '/customsManager/importAndExportBusinessApplicationForm',
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-import-and-export-business-application-form/customs-import-and-export-business-application-form')),
    },
    {// 关务管理/凭证付汇明细报表
        title: '凭证付汇明细报表',
        enTitle:'Voucher payment details report',
        key: '/customsManager/voucherPaymentDetailsReport',
        component: asyncComponent(() => import('../pages/admin/customs-management/customs-voucher-payment-details-report/customs-voucher-payment-details-report')),
    },
    {// 订单管理/生产采购/回复交期
        title: '回复交期',
        key: '/orderManager/productionPurchase/replyDelivery',
        component:asyncComponent(() => import('../pages/admin/order-management/order-production-procurement/reply-delivery-list/reply-delivery-list')),
    },
    {// 订单管理/生产采购/上传重排数据
        title: '重排管理',
        key: '/orderManager/productionPurchase/rearrangementManagement',
        component:asyncComponent(() => import('../pages/admin/order-management/order-production-procurement/rearrangement-data-list/rearrangement-data-list')),
    },
    {// 订单管理/生产采购/生成送货单-PO
        title: '生成送货单-PO',
        key: '/orderManager/productionPurchase/generateDelivery',
        component:asyncComponent(() => import('../pages/admin/order-management/order-production-procurement/generate-delivery-list/generate-delivery-list')),
    },
    {// 订单管理/生产采购/管理送货单-PO
        title: '管理送货单-PO',
        key: '/orderManager/productionPurchase/managementDelivery',
        component:asyncComponent(() => import('../pages/admin/order-management/order-production-procurement/manage-delivery-list/manage-delivery-list')),
    },
    /*{// 订单管理/生产采购/已收货未质检
        title: '管理送货单-PO',
        key: '/orderManager/productionPurchase/managementDelivery',
        component:asyncComponent(() => import('../pages/admin/order/no-quality-inspection-lsit/no-quality-inspection-list')),
    }*/
    {// 往来账单/生产采购/付款信息列表（月结）
        title: '付款信息列表（月结）',
        key: '/currentAccountManagement/productionPurchase/PaymentInformationMonthlyStatement',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/reconciliation-production-procurement/payment-information-monthly-statement/payment-information-monthly-statement')),
    },
    {// 往来账单/生产采购/付款信息列表（随货）
        title: '付款信息列表（随货）',
        key: '/currentAccountManagement/productionPurchase/PaymentInformationAlongWithCargo',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/reconciliation-production-procurement/payment-Information-along-cargo/payment-Information-along-cargo')),
    },
    {// 订单管理/生产采购/查询采购订单-PP
        title: '查询采购订单-PP',
        key: '/orderManager/productionPurchase/queryPurchaseOrder',
        component:asyncComponent(() => import('../pages/admin/order-management/order-production-procurement/query-purchase-order/query-purchase-order')),
    },
    {// 订单管理/生产采购/国际订单确认
        title: '国际订单确认',
        key: '/orderManager/productionPurchase/InternationalOrderConfirmation',
        component:asyncComponent(() => import('../pages/admin/order-management/order-production-procurement/intel-order-comfirm/intel-order-confirm')),
    },
    {// 订单管理/生产采购/修改送货单-PO
        title: '修改送货单-PO',
        key: '/orderManager/productionPurchase/deliveryNoteModify',
        component:asyncComponent(() => import('../pages/admin/order-management/order-production-procurement/update-delivery-list/update-delivery-list')),
    },

    {// 订单管理/生产采购/送货单异常确认
        title: '送货单异常确认',
        key: '/orderManager/productionPurchase/deliveryNoteExceptionConfirm',
        component:asyncComponent(() => import('../pages/admin/order-management/order-production-procurement/delivery-note-exception-confirm/delivery-note-exception-confirm')),
    },

    {// 订单管理/生产采购/国际订单审核
        title: '国际订单审核',
        key: '/orderManager/productionPurchase/InternationalOrderExamine',
        component:asyncComponent(() => import('../pages/admin/order-management/order-production-procurement/intel-order-examine/intel-order-examine')),
    },

    {// 订单管理/生产采购/审批提前收货
        title: '审批提前收货',
        key: '/orderManager/productionPurchase/approveDdvanceReceipt',
        component:asyncComponent(() => import('../pages/admin/order-management/order-production-procurement/approve-advance-receipt/approve-advance-receipt')),
    },

    {// 订单管理/生产采购/查询提前送货记录
        title: '查询提前送货记录',
        key: '/orderManager/productionPurchase/queryAdvanceDeliveryRecord',
        component:asyncComponent(() => import('../pages/admin/order-management/order-production-procurement/query-advance-delivery-record/query-advance-delivery-record')),
    },

    {// 仓储管理/收货管理/收货过账管理-PO
        title: '收货过账管理-PO',
        key: '/warehousingManagement/receivingManagement/receiptPostingManagement',
        component:asyncComponent(() => import('../pages/admin/warehouse-management/receiving-management/receipt-posting-management/receipt-posting-management')),
    },

    {// 订单管理/生产采购/查询未按承诺交付
        title: '查询未按承诺交付',
        key: '/orderManager/productionPurchase/queryDeliveryAsPromised',
        component:asyncComponent(() => import('../pages/admin/order-management/order-production-procurement/query-delivery-as-promised/query-delivery-as-promised')),
    },

    {// 订单管理/BPO订单/BPO订单查询
        title: 'BPO订单查询',
        key: '/orderManager/BPOmanager/bpoOrderQuery', // bpo-order-query
        component:asyncComponent(() => import('../pages/admin/order-management/bpo-order/bpo-order-query/bpo-order-query')),
    },

    // {// 订单管理/BPO订单/BPO订单确认
    //     title: 'BPO订单确认',
    //     key: '/orderManager/BPOmanager/bpoOrderConfirmation', // bpo-order-confirmation
    //     component:asyncComponent(() => import('../pages/admin/order-management/bpo-order/bpo-order-confirmation/bpo-order-confirmation')),
    // },

    {// 订单管理/BPO订单/BPO可供回复
        title: 'BPO可供回复',
        key: '/orderManager/BPOmanager/bpoAvailableForReply', // bpo-available-for-reply
        component:asyncComponent(() => import('../pages/admin/order-management/bpo-order/bpo-available-for-reply/bpo-available-for-reply')),
    },

    {// 订单管理/BPO订单/BPO综合报表
        title: 'BPO综合报表',
        key: '/orderManager/BPOmanager/bpoComprehensiveReport', // bpo-comprehensive-report
        component:asyncComponent(() => import('../pages/admin/order-management/bpo-order/bpo-comprehensive-report/bpo-comprehensive-report')),
    },

    {// 交互模式管理/jit/生成送货单
        title: '生成送货单-jit',
        key: '/interactionModeManager/JIT/generateDeliveryForJIT',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/JIT/generate-delivery-order-list/generate-delivery-order-list')),
    },

    {// 交互模式管理/jit/JIT查询（SAP)
        title: 'JIT查询（SAP)',
        key: '/interactionModeManager/JIT/queryOrderListForJIT',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/JIT/query-jit-list/query-jit-list')),
    },

    {// 交互模式管理/jit/JIT工单预留列表
        title: 'JIT工单预留列表',
        key: '/interactionModeManager/JIT/queryReservedOrderListForJIT',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/JIT/reserved-order-List/reserved-order-list')),
    },

    {// 交互模式管理/MilkRun/生成送货单-MR
        title: '生成送货单-MR',
        key: '/interactionModeManager/MilkRun/generateDeliveryForMilkRun',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/milkRun/milkRun-generate-delivery-order-list/milkRun-generate-delivery-order-list')),
    },

    {// 仓储管理/收货管理/收货过账管理-MilkRun
        title: '收货过账管理-MilkRun',
        key: '/warehousingManagement/receivingManagement/receiptPostingManagementForMilkRun',
        component:asyncComponent(() => import('../pages/admin/warehouse-management/receiving-management/receipt-posting-milkRun-list/receipt-posting-milkRun-list')),
    },

    {// 仓储管理/收货管理/收货过账管理-jit
        title: '收货过账管理-jit',
        key: '/warehousingManagement/receivingManagement/receiptPostingManagementForJIT',
        component:asyncComponent(() => import('../pages/admin/warehouse-management/receiving-management/receipt-posting-jit-list/receipt-posting-jit-list')),
    },
    {// 仓储管理/收货管理/VMI仪器-收货过账管理
        title: 'VMI仪器-收货过账管理',
        key: '/warehousingManagement/receivingManagement/queryPageReceiveList',
        component:asyncComponent(() => import('../pages/admin/vmi/query-page-receive-list/query-page-receive-list'))
    },
    {// 交互模式管理/MilkRun/需求信息查询
        title: '需求信息查询',
        key: '/interactionModeManager/MilkRun/queryRequestListForMilkRun',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/milkRun/demand-information-query-list/demand-information-query-list')),
    },

    {// 交互模式管理/MilkRun/需求信息汇总
        title: '需求信息汇总',
        key: '/interactionModeManager/MilkRun/queryRequestSumListForMilkRun',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/milkRun/demand-summary-query-list/demand-summary-query-list')),
    },

    {// 交互模式管理/MilkRun/管理送货单-MR
        title: '管理送货单-MR',
        key: '/interactionModeManager/MilkRun/managementDeliveryForMilkRun',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/milkRun/milkRun-manage-delivery-order-list/milkRun-manage-delivery-order-list')),
    },

    {// 交互模式管理/按需拉料（SA_01)/生成送货单-SA
        title: '生成送货单-SA',
        key: '/interactionModeManager/pullOnDemand/generateDeliveryForSA',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/pull-material-on-demand/pull-material-generate-order/pull-material-generate-order')),
    },

    {// 交互模式管理/按需拉料（SA_01)/管理送货单-SA
        title: '管理送货单-MR',
        key: '/interactionModeManager/pullOnDemand/managementDeliveryForSA',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/pull-material-on-demand/pull-material-manage-order/pull-material-manage-order')),
    },

    {// 交互模式管理/按需拉料（SA_01)/拉料需求排程列表
        title: '拉料需求排程列表',
        key: '/interactionModeManager/pullOnDemand/pullDemandSchedulingForSA',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/pull-material-on-demand/pull-demand-scheduling-list/pull-demand-scheduling-list')),
    },

    {// 交互模式管理/VMI仪器/配额信息查询
        title: '配额信息查询',
        key: '/interactionModeManager/VMIinstrument/listSupplierQuota',
        component:asyncComponent(() => import('../pages/admin/vmi/list-supplier-quota/list-supplier-quota'))
    },
    {// 交互模式管理/VMI仪器/浮动值管理
        title: '浮动值管理',
        key: '/interactionModeManager/VMIinstrument/listMatnrFloat',
        component:asyncComponent(() => import('../pages/admin/vmi/list-matnr-float/list-matnr-float'))
    },
    {// 交互模式管理/VMI仪器/需求信息查询
        title: '需求信息查询',
        key: '/interactionModeManager/VMIinstrument/queryPagePlanRequest',
        component:asyncComponent(() => import('../pages/admin/vmi/query-page-plan-request/query-page-plan-request'))
    },
    {// 交互模式管理/VMI仪器/需求信息确认
        title: '需求信息确认',
        key: '/interactionModeManager/VMIinstrument/queryPageSupplierRequest',
        component:asyncComponent(() => import('../pages/admin/vmi/query-page-supplier-request/query-page-supplier-request'))
    },
    {// 交互模式管理/VMI仪器/送货单管理
        title: '送货单管理',
        key: '/interactionModeManager/VMIinstrument/queryPageDelivery',
        component:asyncComponent(() => import('../pages/admin/vmi/query-page-delivery/query-page-delivery'))
    },
    {// 交互模式管理/VMI仪器/送货单管理详情
        title: '送货单管理详情',
        key: '/interactionModeManager/VMIinstrument/queryPageDelivery/details/:enterId',
        component:asyncComponent(() => import('../pages/admin/vmi/query-page-delivery/details/details')),
        isNotMenu: true,
        isNeedShow: true
    },
    // {// 交互模式管理/VMI仪器/收货确认管理 已放在 仓储管理/收货管理/VMI仪器-收货过账管理
    //     title: '收货确认管理',
    //     key: '/interactionModeManager/VMIinstrument/queryPageReceiveList',
    //     component:asyncComponent(() => import('../pages/admin/vmi/query-page-receive-list/query-page-receive-list'))
    // },
    {// 交互模式管理/VMI仪器/库存快照
        title: '库存快照',
        key: '/interactionModeManager/VMIinstrument/inventorySnapshot',
        component:asyncComponent(() => import('../pages/admin/vmi/inventory-snapshot/inventory-snapshot'))
    },
    {// 交互模式管理/VMI仪器/库存快照 -> 库存快照详情
        title: '库存快照详情',
        key: '/interactionModeManager/VMIinstrument/inventorySnapshot/details/:enterId',
        component:asyncComponent(() => import('../pages/admin/vmi/inventory-snapshot/details/details')),
        isNotMenu: true,
        isNeedShow: true
    },
    {// 交互模式管理/VMI仪器/库存信息报表
        title: '库存信息报表',
        key: '/interactionModeManager/VMIinstrument/listBaseStorage',
        component:asyncComponent(() => import('../pages/admin/vmi/list-base-storage/list-base-storage'))
    },
    {// 交互模式管理/VMI仪器/综合报表
        title: '综合报表',
        key: '/interactionModeManager/VMIinstrument/listVmiReport',
        component:asyncComponent(() => import('../pages/admin/vmi/list-vmi-report/list-vmi-report'))
    },

    {// 交付模式管理/按通知到货/需求信息查询
        title: '需求信息查询',
        key: '/interactionModeManager/arrivalOnNotification/demandInformationQuery',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/notify-arrival/demand-information-query/demand-information-query')),
    },
    {// 交付模式管理/按通知到货/生成送货单
        title: '生成送货单',
        key: '/interactionModeManager/arrivalOnNotification/generateDeliveryOrder',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/notify-arrival/generate-delivery-order/generate-delivery-order')),
    },
    {// 交付模式管理/按通知到货/管理送货单
        title: '管理送货单',
        key: '/interactionModeManager/arrivalOnNotification/manageDeliveryNotes',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/notify-arrival/manage-delivery-notes/manage-delivery-notes')),
    },
    {// 交付模式管理/按通知到货/收货确认
        title: '收货确认',
        key: '/interactionModeManager/arrivalOnNotification/receiptConfirmation',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/notify-arrival/receipt-confirmation/receipt-confirmation')),
    },
    {// 交付模式管理/按通知到货/回复交期
        title: '回复交期',
        key: '/interactionModeManager/arrivalOnNotification/replyToTheDeliveryDate',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/notify-arrival/reply-to-the-delivery-date/reply-to-the-delivery-date')),
    },
    {// 交付模式管理/按通知到货/绩效报表
        title: '绩效报表',
        key: '/interactionModeManager/arrivalOnNotification/performanceReports',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/notify-arrival/performance-reports/performance-reports')),
    },
    {// 交付模式管理/按通知到货/统计报表
        title: '统计报表',
        key: '/interactionModeManager/arrivalOnNotification/statisticalReports',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/notify-arrival/statistical-reports/statistical-reports')),
    },
    // {//交付模式管理-PCBA交付管理-PCBA工单列表
    //     title: 'PCBA工单列表',
    //     key: '/interactionModeManager/PCBAinteractionManager/pcbaTicketsList',
    //     component:asyncComponent(() => import('../pages/admin/exchange-mode/pcba-delivery-management/pcba-tickets-list/pcba-tickets-list')),
    // },
    // {//交付模式管理-PCBA交付管理-PCBA工单列表/详情
    //     title: 'PCBA工单列表详情',
    //     key: '/interactionModeManager/PCBAinteractionManager/pcbaTicketsList/details',
    //     component:asyncComponent(() => import('../pages/admin/exchange-mode/pcba-delivery-management/pcba-tickets-list/details/details')),
    //     isNotMenu: true,
    //     isNeedShow: true
    // },
    {//交付模式管理-PCBA交付管理-PCBA产线列表
        title: 'PCBA产线列表',
        key: '/interactionModeManager/PCBAinteractionManager/pcbaProductionList',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/pcba-delivery-management/pcba-production-list')),
    },
    {//交付模式管理-自制钣金&机加-机加工单列表
        title: '机加工单列表',
        key: '/interactionModeManager/homemadeAndMachine/auinfList',
        component:asyncComponent(() => import('../pages/admin/exchange-mode/homemade-and-machine/machining-sheet-list/machining-sheet-list')),
    },
    // {//交付模式管理-自制钣金&机加-机加工单列表/详情
    //     title: '机加工单列表详情',
    //     key: '/interactionModeManager/homemadeAndMachine/auinfList/details',
    //     component:asyncComponent(() => import('../pages/admin/exchange-mode/homemade-and-machine/machining-sheet-list/details/details')),
    //     isNotMenu: true,
    //     isNeedShow: true
    // },
    {// 仓储管理/收货管理/收货过账管理-SA_01
        title: '收货过账管理-SA_01',
        key: '/warehousingManagement/receivingManagement/receiptPostingManagementForSA',
        component:asyncComponent(() => import('../pages/admin/warehouse-management/receiving-management/receipt-posting-pull-demand-list/receipt-posting-pull-demand-list')),
    },

    {// 供需管理/备货管理/上品类清单&查询
        title: '上品类清单&查询',
        key: '/SupplyAndDemandManagement/stockManagement/categoryListAndQuery',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/stock-management/category-list-and-query-list/category-list-and-query-list')),
    },

    {// 供需管理/备货管理/物料与供应商型号
        title: '物料与供应商型号',
        key: '/SupplyAndDemandManagement/FCSTManagement/MaterialAndSupplierModel',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/material-and-supplier-model/material-and-supplier-model')),
    },

    {// 供需管理/备货管理/供应商库存
        title: '供应商库存',
        key: '/SupplyAndDemandManagement/FCSTManagement/SupplierInventory',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/supplier-inventory-list/supplier-inventory-list')),
    },

    {// 供需管理/备货管理/TI供需匹配报表
        title: 'TI供需匹配报表',
        key: '/SupplyAndDemandManagement/FCSTManagement/TiSupplyDemandMatchingReport',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/ti-supply-demand-matching-report/ti-supply-demand-matching-report')),
    },

    {// 供需管理/备货管理/备货综合报表
        title: '备货综合报表',
        key: '/SupplyAndDemandManagement/stockManagement/StockComprehensiveReportList',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/stock-management/stock-comprehensive-report-list/stock-comprehensive-report-list')),
    },

    {// 订单管理/研发采购/订单管理
        title: '订单管理',
        key: '/orderManager/RDprocurement/orderManagement',
        component:asyncComponent(() => import('../pages/admin/order-management/develop-purchase/order-management/order-management')),
    },
    {// 订单管理/研发采购/生成送货单
        title: '生成送货单',
        key: '/orderManager/RDprocurement/productionDeliveryNote',
        component:asyncComponent(() => import('../pages/admin/order-management/develop-purchase/production-delivery-note/production-delivery-note')),
    },
    {// 订单管理/研发采购/送货单管理
        title: '送货单管理',
        key: '/orderManager/RDprocurement/deliveryNoteManagement',
        component:asyncComponent(() => import('../pages/admin/order-management/develop-purchase/delivery-note-management/delivery-note-management')),
    },
    {// 订单管理/研发采购/退货管理
        title: '退货管理',
        key: '/orderManager/RDprocurement/returnsManagement',
        component:asyncComponent(() => import('../pages/admin/order-management/develop-purchase/returns-management/returns-management')),
    },
    {// 订单管理/转口贸易/订单管理
        title: '订单管理',
        key: '/orderManager/transportTrade/orderManager',
        component:asyncComponent(() => import('../pages/admin/order-management/transport-trade/order-manager/order-manager')),
    },
    {// 订单管理/转口贸易/生成送货单-转口
        title: '生成送货单-转口',
        key: '/orderManager/transportTrade/generateDeliveryOrder',
        component:asyncComponent(() => import('../pages/admin/order-management/transport-trade/generate-delivery-order/generate-delivery-order')),
    },
    {// 订单管理/转口贸易/管理送货单-转口
        title: '管理送货单-转口',
        key: '/orderManager/transportTrade/deliveryNoteManagement',
        component:asyncComponent(() => import('../pages/admin/order-management/transport-trade/delivery-note-management/delivery-note-management')),
    },
    {// 订单管理/转口贸易/SO查询
        title: 'SO查询',
        key: '/orderManager/transportTrade/soQuery',
        component:asyncComponent(() => import('../pages/admin/order-management/transport-trade/so-query/so-query')),
    },
    {// 订单管理/转口贸易/退货管理-转口
        title: '退货管理-转口',
        key: '/orderManager/transportTrade/returnsManagement',
        component:asyncComponent(() => import('../pages/admin/order-management/transport-trade/returns-management/returns-management')),
    },
    {// 对账管理/暂估管理
        title: '暂估管理',
        key: '/reconciliationManagement/estimationManagement',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/estimation-management/estimation-management')),
    },
    {// 对账管理/暂估管理 - 质检中
        title: '暂估管理 - 质检中',
        key: '/reconciliationManagement/estimationManagementInQualityInspection',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/estimation-management-in-quality-inspection/estimation-management-in-quality-inspection')),
    },
    {// 对账管理/暂估清理
        title: '暂估清理',
        key: '/reconciliationManagement/estimationCleanup',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/estimation-cleanup/estimation-cleanup')),
    },
    {   // 对账管理/暂估清理/详情
        title: '暂估清理列表详情',
        key: '/reconciliationManagement/estimationCleanup/estimationCleanupDetails/:enterId',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/estimation-cleanup/estimation-cleanup-details/estimation-cleanup-details')),
        isNotMenu: true,
        isNeedShow: true
    },
    {// 对账管理/对账单管理
        title: '对账单列表',
        key: '/reconciliationManagement/statementManagement',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/statement-management-monthly-settlement/statement-management-monthly-settlement'))
    },
    {// 对账管理/对账单管理月结详情
        title: '对账单列表详情',
        key: '/reconciliationManagement/statementManagement/monthlySettlementDetails',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/statement-management-monthly-settlement/details/details')),
        isNotMenu: true, // 非菜单路由需要配置isNotMenu为true
        isNeedShow: true // 当有多个路由映射一个页面时,只需要用其中一个菜单对象配置isNeedShow
    },
    // {// 对账管理/对账单管理 - 随货
    //     title: '对账单列表 - 随货',
    //     key: '/reconciliationManagement/statementManagementWithGoods',
    //     component:asyncComponent(() => import('../pages/admin/reconciliation-management/statement-management-with-goods/statement-management-with-goods'))
    // },
    {// 对账管理/对账单管理 - 随货/详情
        title: '对账单列表详情',
        key: '/reconciliationManagement/statementManagement/accompanyingDetails',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/statement-management-with-goods/details/details')),
        isNotMenu: true
    },
    {// 对账管理/提前/延迟/冻结付款
        title: '提前/延迟/冻结付款',
        key: '/reconciliationManagement/advanceDelayFreezePayment',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/advance-delay-freeze-payment/advance-delay-freeze-payment'))
    },
    {// 对账管理/提前/延迟/冻结付款 / 详情
        title: '提前/延迟/冻结付款详情',
        key: '/reconciliationManagement/advanceDelayFreezePayment/details/:enterId',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/advance-delay-freeze-payment/details/details')),
        isNotMenu: true,
        isNeedShow: true
    },
    {// 价差管理
        title: '价差管理',
        key: '/reconciliationManagement/priceDifferenceManagement',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/price-difference-management/price-difference-management'))
    },
    {// 价差单列表详情
        title: '价差单列表详情',
        key: '/reconciliationManagement/priceDifferenceManagement/details/:enterId',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/price-difference-management/details/details')),
        isNotMenu: true,
        isNeedShow: true
    },
    {// ERP价差报表
        title: 'ERP价差报表',
        key: '/reconciliationManagement/erpPriceDifferenceReport',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/erp-price-difference-report/erp-price-difference-report'))
    },
    {// 扣款管理
        title: '扣款管理',
        key: '/reconciliationManagement/deductionManagement',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/deduction-management/deduction-management'))
    },
    {// 扣款管理/详情
        title: '扣款单列表详情',
        key: '/reconciliationManagement/deductionManagement/details/:enterId',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/deduction-management/details/details')),
        isNotMenu: true,
        isNeedShow: true
    },
    {// 预付管理
        title: '预付管理',
        key: '/reconciliationManagement/prepaymentManagement',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/prepayment-management/prepayment-management'))
    },
    {// 预付管理/详情
        title: '预付单列表详情',
        key: '/reconciliationManagement/prepaymentManagement/details/:enterId',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/prepayment-management/details/details')),
        isNotMenu: true,
        isNeedShow: true
    },
    {// 系统配置
        title: '系统配置',
        key: '/reconciliationManagement/systemConfiguration',
        component: asyncComponent(() => import('../pages/admin/reconciliation-management/system-configuration/system-configuration'))
    },
    {// 财务审批人员配置
        title: '财务审批人员配置',
        key: '/reconciliationManagement/FinancialApprovalPersonnelAllocation',
        component: asyncComponent(() => import('../pages/admin/reconciliation-management/financial-approval-personnel-allocation/financial-approval-personnel-allocation'))
    },
    {// 财务审核个人任务中心
        title: '财务审核个人任务中心',
        key: '/reconciliationManagement/FinancialAuditPersonalTaskCenter',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/financial-audit-personal-task-center/financial-audit-personal-task-center'))
    },
    {// 财务审核待审池
        title: '财务审核待审池',
        key: '/reconciliationManagement/FinancialReviewPendingPool',
        component:asyncComponent(() => import('../pages/admin/reconciliation-management/financial-review-pending-pool/financial-review-pending-pool'))
    },
    {// 供需管理 -> FCST管理 -> 关键料FCST可供回复
        title: '关键料FCST可供回复',
        key: '/SupplyAndDemandManagement/FCSTManagement/KeyMaterialsFCSTAvailableForReply',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/key-materials-fcst-available-for-reply/key-materials-fcst-available-for-reply'))
    },
    {// 供需管理 -> FCST管理 -> 关键料FCST可供回复
        title: '关键料FCST可供回复',
        key: '/SupplyAndDemandManagement/FCSTManagement/internationalFCST/InternationalFCSTInformationInquiry',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/international-fcst-information-inquiry/international-fcst-information-inquiry'))
    },
    {// 供需管理 -> FCST管理 -> 国际FSCT库存回复
        title: '关键料FCST可供回复',
        key: '/SupplyAndDemandManagement/FCSTManagement/internationalFCST/InternationalFCSTInventoryReply',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/international-fcst-inventoryreply/international-fcst-inventoryreply'))
    },
    {// FCST管理 -> FSCT库存回复
        title: 'FCST信息查询',
        key: '/SupplyAndDemandManagement/FCSTManagement/FCST/FCSTInformationQuery',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/FCST-inventory-reply/FCST-inventory-reply'))
    },
    {// FCST管理 -> 回复可供库存
        title: 'FCST信息查询',
        key: '/SupplyAndDemandManagement/FCSTManagement/FCST/ReplyAvailableForStock',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/reply-available-in-stock/reply-available-in-stock'))
    },
    {// FCST管理 -> 修改可供库存
        title: 'FCST信息查询',
        key: '/SupplyAndDemandManagement/FCSTManagement/FCST/ModifyAvailableInventory',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/change-reply-available-in-stock/change-reply-available-in-stock'))
    },
    {// FCST管理 -> 确认可供库存
        title: 'FCST信息查询',
        key: '/SupplyAndDemandManagement/FCSTManagement/FCST/ConfirmAvailableInventory',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/sure-reply-available-in-stock/sure-reply-available-in-stock'))
    },
    {// FCST -> 物料协议信息审核
        title: 'FCST信息查询',
        key: '/SupplyAndDemandManagement/FCSTManagement/FCST/MaterialAgreementInformationApproval',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/material-agreement-information/material-agreement-information'))
    },
    {// FCST -> 物料协议信息维护
        title: 'FCST信息查询',
        key: '/SupplyAndDemandManagement/FCSTManagement/FCST/MaterialAgreementInformationMaintenance',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/material-agreement-information-maintenance/material-agreement-information-maintenance'))
    },
    {// FCST -> 备货综合报表
        title: '备货综合报表',
        key: '/SupplyAndDemandManagement/FCSTManagement/FCST/StockComprehensiveReport',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/stock-report/stock-report'))
    },
    {// 半成品&原材料 -> 回复可供库存
        title: '回复可供库存',
        key: '/SupplyAndDemandManagement/FCSTManagement/SemiFinishedProductsRawMaterials/SemiFinishedProductsReplyToRawMaterialInventory',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/raw-material/reply-available-in-stock'))
    },
    {// 半成品&原材料 -> 物料协议信息维护
        title: '回复可供库存',
        key: '/SupplyAndDemandManagement/FCSTManagement/SemiFinishedProductsRawMaterials/SemiFinishedProductsRawMaterialAgreementInformationMaintenance',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/raw-material-mainten/reply-available-in-stock'))
    },
    {// 半成品&原材料 -> 信息查询
        title: '信息查询',
        key: '/SupplyAndDemandManagement/FCSTManagement/SemiFinishedProductsRawMaterials/SemiFinishedProductsRawMaterialInventoryQuery',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/raw-material-information-query/reply-available-in-stock'))
    },
    {// FCST管理 -> 可查看毛需求的供应商
        title: 'FCST信息查询',
        key: '/SupplyAndDemandManagement/FCSTManagement/FCST/SuppliersWithGrossDemandCanBeChecked',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/Gross_Demand_Supplier/Gross_Demand_Supplier'))
    },
    {// FCST管理 -> 手动添加节假日天数
        title: 'FCST信息查询',
        key: '/SupplyAndDemandManagement/FCSTManagement/FCST/FCSTHoliday',
        component:asyncComponent(() => import('../pages/admin/supply-and-demand-management/FCST-management/FCST-holiday/FCST-holiday'))
    },
    {// 订单管理 -> 试剂VMI -> 试剂VMI订单管理
        title: '试剂VMI订单管理',
        key: '/orderManager/reagentVMI/orderManagement',
        component:asyncComponent(() => import('../pages/admin/ReagentVMI/Order-management-list/Order-management-list'))
    },
    {// 订单管理 -> 试剂VMI -> 试剂VMI送货单管理 -> 生成送货单
        title: '生成送货单',
        key: '/orderManager/reagentVMI/deliveryOrderManagement/generateDeliveryorder',
        component:asyncComponent(() => import('../pages/admin/ReagentVMI/VMI-generate-delivery-list/VMI-generate-delivery-list')),
        isNotMenu: true,
        isNeedShow: true
    },
    {// 订单管理 -> 试剂VMI -> 送货单管理
        title: '送货单管理',
        key: '/orderManager/reagentVMI/deliveryOrderManagement',
        component:asyncComponent(() => import('../pages/admin/ReagentVMI/Delivery-management-list/Delivery-management-list'))
    },
    {// 订单管理 -> 试剂VMI -> 试剂VMI订单管理 -> 试剂VMI订单详情
        title: '试剂VMI订单详情',
        key: '/reagentVMI/OrderManagement/details',
        component:asyncComponent(() => import('../pages/admin/ReagentVMI/OrderManagement/details/details')),
        isNotMenu: true
    },
    {// 订单管理 -> 试剂VMI -> 试剂VMI库存信息报表
        title: '试剂VMI库存信息报表',
        key: '/orderManager/reagentVMI/InventoryReport',
        component:asyncComponent(() => import('../pages/admin/ReagentVMI/InventoryReport/InventoryReport')),
    },
    {// 订单管理 -> 试剂VMI -> 试剂VMI库存快照
        title: '库存快照',
        key: '/orderManager/reagentVMI/InventorySnapshot',
        component:asyncComponent(() => import('../pages/admin/ReagentVMI/InventorySnapshot/InventorySnapshot'))
    },
    {// 订单管理 -> 试剂VMI -> 试剂VMI库存快照 -> 库存快照详情
        title: '库存快照详情',
        key: '/orderManager/reagentVMI/InventorySnapshot/details/:enterId',
        component:asyncComponent(() => import('../pages/admin/ReagentVMI/InventorySnapshot/details/details')),
        isNotMenu: true,
        isNeedShow: true
    },
    //供应商管理
    {// 供应商管理 -> 供应商准入准出
        title: '供应商准入准出',
        enTitle:'supplier admission and exit permit',
        key: '/supplierManagement/supplierAccessExit',
        component:asyncComponent(() => import('../pages/admin/supplier-management/supplier-access-exit/supplier-access-exit'))
    },
    {// 供应商管理 -> 供应商准入准出详情
        title: '供应商准入准出详情',
        enTitle:'supplier admission and exit permit details',
        key: '/supplierManagement/supplierAccessExit/supplierAccessExitDetails',
        component:asyncComponent(() => import('../pages/admin/supplier-management/supplier-access-exit/details/details')),
        isNotMenu: true,
        isNeedShow: true
    },
    {// 供应商管理 -> 供应商评分
        title: '供应商评分',
        enTitle:'Supplier rating',
        key: '/supplierManagement/supplierRating',
        component:asyncComponent(() => import('../pages/admin/supplier-management/supplier-rating/supplier-rating'))
    },
    {// 供应商管理 -> 证书设置
        title: '证书设置',
        enTitle:'Certificate settings',
        key: '/supplierManagement/certificateSettings',
        component:asyncComponent(() => import('../pages/admin/supplier-management/certificate-settings/certificate-settings'))
    },
    // {// 供应商管理 -> 供应商资质档案
    //     title: '供应商资质档案',
    //     enTitle:'Supplier Qualification Archive',
    //     key: '/supplierManagement/supplierQualificationArchive',
    //     component:asyncComponent(() => import('../pages/admin/supplier-management/supplier-qualification-archive/supplier-qualification-archive'))
    // },
    // {// 供应商管理 -> 供应商资料
    //     title: '供应商资料',
    //     enTitle:'Supplier Information',
    //     key: '/supplierManagement/supplierInformation',
    //     component:asyncComponent(() => import('../pages/admin/supplier-management/supplier-information/supplier-information'))
    // },
    // {// 供应商管理 -> 供应商样品
    //     title: '供应商样品',
    //     enTitle:'Supplier samples',
    //     key: '/supplierManagement/supplierSamples',
    //     component:asyncComponent(() => import('../pages/admin/supplier-management/supplier-samples/supplier-samples'))
    // },
    // {//供应商管理 -> 供应商准入准出/联系人管理
    //     title: '联系人管理',
    //     enTitle:'Contact manager',
    //     key: '/supplierManagement/supplierSamples/contactManager',
    //     component:asyncComponent(() => import('../pages/admin/supplier-management/supplier-access-exit/contact-manager/contact-manager')),
    //     isNotMenu: true,
    //     isNeedShow: true
    // },
    // {// 供应商管理 -> 评分规则设置
    //     title: '评分规则设置',
    //     enTitle:'Scoring Rule Settings',
    //     key: '/supplierManagement/scoringRuleSettings',
    //     component:asyncComponent(() => import('../pages/admin/supplier-management/scoring-rule-settings/scoring-rule-settings'))
    // },
    // {// 供应商管理 -> 条码规则
    //     title: '条码规则',
    //     enTitle:'Barcode rules',
    //     key: '/supplierManagement/barcodeRules',
    //     component:asyncComponent(() => import('../pages/admin/supplier-management/barcode-rules/barcode-rules'))
    // },
    //采购计划
    {//采购计划 ->采购需求池
        title: '采购需求池',
        enTitle:'Procurement demand pool',
        key: '/procurementPlanning/procurementDemandPool',
        component:asyncComponent(() => import('../pages/admin/procurement-planning/procurement-demand-pool/procurement-demand-pool'))
    },
    {//采购计划 ->采购申请
        title: '询报价',
        enTitle:'rfq',
        key: '/procurementPlanning/purchasingRequisition',
        component:asyncComponent(() => import('../pages/admin/procurement-planning/purchasing-requisition/purchasing-requisition'))
    },
    {//采购计划 ->招投标
        title: '招投标',
        enTitle:'Tendering and bidding',
        key: '/procurementPlanning/tenderingAndBidding',
        component:asyncComponent(() => import('../pages/admin/procurement-planning/tendering-and-bidding/tendering-and-bidding'))
    },
    //物料管理
    {// 物料管理 -> 物料清单
        title: '物料清单',
        enTitle:'BoM',
        key: '/materialManagement/BoM',
        component:asyncComponent(() => import('../pages/admin/material-management/BoM/BoM'))
    },
     {//物料管理 -> 物料清单/清单详情
        title: '物料清单详情',
        enTitle:'Bill of Materials Details',
        key: '/materialManagement/BoM/BoMDetails',
        component:asyncComponent(() => import('../pages/admin/material-management/BoM/BoM-details/BoM-details')),
        isNotMenu: true,
        isNeedShow: true
    },
    {// 物料管理 -> 物料分类
        title: '物料分类',
        enTitle:'Material classification',
        key: '/materialManagement/material-classification',
        component:asyncComponent(() => import('../pages/admin/material-management/material-classification/material-classification'))
    },
    {// 物料管理 -> 品牌设置
        title: '品牌设置',
        enTitle:'Brand settings',
        key: '/materialManagement/brand-settings',
        component:asyncComponent(() => import('../pages/admin/material-management/brand-settings/brand-settings'))
    },
    {// 物料管理 -> 物料单位设置
        title: '物料单位设置',
        enTitle:'Material Unit Settings',
        key: '/materialManagement/material-unit-settings',
        component:asyncComponent(() => import('../pages/admin/material-management/material-unit-settings/material-unit-settings'))
    },
    //系统管理
    {// 系统管理 -> 权限系统
        title: '权限系统',
        enTitle:'Permission System',
        key: '/systemManagement/userManagement',
        component:asyncComponent(() => import('../pages/admin/system-management/user-management/user-management'))
    },
    {// 系统管理 -> 流程设置
        title: '流程设置',
        enTitle:'Process settings',
        key: '/systemManagement/processSettings',
        component:asyncComponent(() => import('../pages/admin/system-management/process-settings/process-settings'))
    },
    /*{
        title: '订单管理',
        key: '/orderManager',
        icon:<BarsOutlined />,
        children: [
            {
                title: '生产采购',
                key: '/productionPurchase',
                icon: <BarsOutlined />,
                children: [
                    {
                        title: '查询采购订单-PP',
                        key: '/orderManager/productionPurchase/old/dac/queryTruckBillList.action',
                        component: asyncComponent(() => import('../pages/admin/old-page/oldPage')),
                    },
                    {
                        title: '回复交期',
                        key: '/orderManager/productionPurchase/old/dac/queryTruckBillList.action',
                        component: asyncComponent(() => import('../pages/admin/old-page/oldPage')),
                    }
                ]
            }
        ]
    },*/
];
export default navLeftMenus;
