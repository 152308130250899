import React from 'react'
import { Form, Select, Tooltip} from "antd";
import { formatMessage } from "../../utils/util";
import { FormattedMessage,injectIntl } from "react-intl";
const {Option} = Select

class SelectList extends React.Component {
  render() {
    const {name,label,initialValue,rules,labelCol,wrapperCol,icon,disabled,mode,maxTagCount,onFocus,onChange,isNouseFormatted,options,valueName,labelName,defaultValue} = this.props
    return (
      <Form.Item
          name={name}
          label={label ? formatMessage(this,label) : ''}
          initialValue={initialValue}
          rules={rules}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
        >
          <Select
            suffixIcon={icon}
            disabled={disabled}
            mode={mode}
            placeholder={icon ? icon : label ? formatMessage(this,label) : ''}
            maxTagCount={maxTagCount ? maxTagCount : null}
            onFocus={onFocus ? onFocus : null}
            onChange={onChange ? onChange : null}
            defaultValue={defaultValue}
          >
            {
              isNouseFormatted ?
              options && options.map((option,index) => {
                return (
                  <Option key={index} value={valueName ? option[valueName] : option.value}>
                    <Tooltip title={option.title}>
                      {labelName ? option[labelName] : option.label}
                    </Tooltip>
                  </Option>
                )
              }) :
              options && options.map((option,index) => {
                return (
                  <Option key={index} value={valueName ? option[valueName] : option.value}>
                    <Tooltip title={option.title}>
                      <div>
                        <FormattedMessage id={labelName ? option[labelName] : option.label}/>
                      </div>
                    </Tooltip>
                  </Option>
                )
              })
            }
          </Select>
        </Form.Item>
      )
  }
}

export default injectIntl(SelectList)
