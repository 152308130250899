import React from 'react'
import { Form, Cascader } from "antd";
import { formatMessage } from "../../utils/util";
import { injectIntl } from "react-intl";

class CategoryListCode extends React.Component {
  render() {
    const {categoryTreeList,name, disabled, fieldNames, initialValue, changeOnSelect, label, multiple, labelCol, onChange, onFocus, onBlur} = this.props
    return (
      <Form.Item name={name} label={label ? formatMessage(this,label) : formatMessage(this,'COMMONS_CATEGORY_NAME')} initialValue={initialValue} labelCol={labelCol}>
        <Cascader options={categoryTreeList} disabled={disabled} fieldNames={fieldNames} changeOnSelect={changeOnSelect} multiple={multiple} onChange={onChange} onFocus={onFocus} onBlur={onBlur}/>
        {/* <Input placeholder={placeholder}/> */}
      </Form.Item>)
  }
}

export default injectIntl(CategoryListCode)
