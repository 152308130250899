import React from 'react'
import {Button, Col, Form, Input, message, Row, Upload} from "antd";
// import {UploadOutlined} from "@ant-design/icons";
import {FormattedMessage, injectIntl} from "react-intl";
import {formatMessage, isFileTypes} from '../../utils/util'
import {connect} from "react-redux";
class BaseUploadForm extends React.Component{
  uploadForm = React.createRef()
  state={
    file:{},
  }
  /*上传前*/
  fileChange = ({file})=>{
    const {fileType,getFile,} =this.props
    let isAllowType = isFileTypes(file.name.toLowerCase(),fileType)
    if(!isAllowType){
      message.info(formatMessage(this,'COMMONS_FILE_TYPE_NOT_ACCEPTED'))
      return
    }
    this.setState(state => ({
      file: file,
    }));
    this.uploadForm.current.setFieldsValue({fileName:file.name})
    getFile(file)
    return false;
  }

  componentDidMount() {
    this.props.getFormRef(this.uploadForm)
  }

  render() {
    const {file} = this.state
    const {isLoading,label,closeWindow,uploadFile,isShowUploadList,isMultiple,fileType,download,handleExportExcel} = this.props
    const style={marginBottom:'10px'}
    return(
      <>
        <Form ref={this.uploadForm} style={{height:'150px'}}>
          <Row>
            <Col span={download ? 24 : 19}>
              {/*送货单  文件不能为空*/}
              <Form.Item label={formatMessage(this,label)} name='fileName' style={style} rules={[{ required: true, message: <FormattedMessage id='COMMONS_THE_FILE_CANNOT_BE_EMPTY' />}]}>{/*文件不能为空*/}
                <Input disabled value={file.name}/>
              </Form.Item>
            </Col>
            <Col span={download ? 3 : 5} style={{marginRight:'30px'}}>
              <Form.Item label=' ' colon={false} style={style}>
                <Upload showUploadList={isShowUploadList} onChange={this.fileChange} accept={fileType} multiple={isMultiple}  beforeUpload={()=>false} >
                  {/*选择文件*/}
                  <Button  type="primary"><FormattedMessage id='COMMONS_SELECT_FILE' /></Button>{/*选择文件*/}
                </Upload>
              </Form.Item>
            </Col>
            {/* 下载模版 */}
            {download &&
            <Col span={3}>
              <Form.Item label=' ' colon={false} style={style}>
                <Button type="primary" onClick={handleExportExcel} style={style}>
                  <FormattedMessage id={'COMMON_DOWNLOAD_MODAL'} />
                </Button>
              </Form.Item>
            </Col>
            }
          </Row>
        </Form>
        <div className='form-foot'>
          <Button type="primary" onClick={uploadFile} loading={isLoading}>
            {/*确定*/}
            <FormattedMessage id='COMMONS_CONFIRM' />
          </Button>
          <Button onClick={closeWindow}>
            {/*取消*/}
            <FormattedMessage id='COMMONS_CANCEL' />
          </Button>
        </div>
      </>
    )
  }
}
export default injectIntl(connect(
  state => ({
    isLoading: state.isLoading,
  }))(BaseUploadForm))
