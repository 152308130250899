import React, { Component } from 'react'
import './home.less'
import { reqCheckToken } from '../../../api/index'
import { FormattedMessage, injectIntl } from 'react-intl'
import { getUser } from '../../../utils/disk-cache/disk-cache'
import { getCookie } from '../../../utils/cookie/cookie'

class Home extends Component {
  /*判断token 是否过期*/
  handleCheckToken = async () => {
    await reqCheckToken()
  }
  componentDidMount() {
    if (getCookie('key') && getUser() && getUser().username) {
      this.handleCheckToken()
    }
  }

  render() {
    return (
      <div className='container'>
        <h1 className='home-title'>
          <FormattedMessage id='COMMON_WELCOME_SRM' />
        </h1>
      </div>
    )
  }
}
export default injectIntl(Home)
