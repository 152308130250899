import store from 'store'
import {clearCookie} from "../cookie/cookie";
const USER_KEY = "srm_user_key00001";
const MENU_OPEN_KEYS = 'menu_open_keys'


export function saveUser(user) {
    // setCookie('key',user.username)
    store.set(USER_KEY, user);
    // sessionStorage.setItem(USER_KEY, JSON.stringify(user))
}


export function getUser() {
    return store.get(USER_KEY);
    // return JSON.parse(sessionStorage.getItem(USER_KEY))
}

export function removeUser() {
    return store.remove(USER_KEY);
    // sessionStorage.removeItem(USER_KEY);
}

export function saveMenuOpenKeys(openKeys){
    store.set(MENU_OPEN_KEYS, openKeys);
    // sessionStorage.setItem(MENU_OPEN_KEYS, JSON.stringify(openKeys))
}

export function getMenuOpenKeys(){
    return store.get(MENU_OPEN_KEYS);
    // sessionStorage.getItem(MENU_OPEN_KEYS)
}

export function removeMenuOpenKeys() {
    store.remove(MENU_OPEN_KEYS);
    // sessionStorage.removeItem(MENU_OPEN_KEYS);
}

export function removerAllKeys(){
    clearCookie("key")
    // setCookie("key",'')
    store.remove(MENU_OPEN_KEYS);
    store.remove(USER_KEY);
    // store.clear();
    sessionStorage.clear();
    localStorage.clear();
}


