//设置采购订单号
export const BUY_NO = 'buy_no'
//设置请求是否加载动画
export const IS_LOADING = 'is_loading'
//语言type
export const RECEIVE_LANG='receive_lang'
//接收左侧菜单的权限
export const RECEIVE_PERMISSION = 'receive_permission'
//收获地址
export const DELIVERY_ADDRESS_LIST ='delivery_address_list'
//工厂列表
export const FACTORY_LIST ='factory_list'
//采购员列表
export const PURCHASER_CODE_LIST ='purchaser_code_list'
//品类列表
export const CATEGORY_TREE_LIST ='category_tree_list'
//权限按扭列表
export const AUTH_BUTTON_LIST ='auth_button_list'
//物料分类列表
export const MATERIAL_CLASSIFICATION_LIST ='material_classification_list'
//物料单位列表
export const UNIT_CLASSIFICATION_LIST ='unit_classification_list'
//地区树状列表
export const AREA_TREE_LIST ='area_tree_list'
//地区列表
export const AREA_LIST ='area_list'

