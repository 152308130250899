import React from 'react'
import {Dropdown, Menu, Breadcrumb, Layout, Spin} from 'antd';
import zhCN from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import {Redirect, Route, Switch} from 'react-router-dom'
// import oldPage from './old-page/oldPage'
import NotFound from "../not-found/not-found";
import NavLeft from "../../components/nav-left/nav-left";
import {getUserName, removeUserCache} from '../../utils/memory/memory'
import {getUser, removerAllKeys} from "../../utils/disk-cache/disk-cache";
import {connect} from 'react-redux'
import './admin.less'
import avatar from '../../asserts/images/character-avatar.png'
// import logout from '../../asserts/images/logout.svg'
import {
  // receiveAuthButtonList,
  // receivePurchaserCodeList,
  receiveFactoryList,
  // receiveDeliveryAddressList,
  receiveLang,
  // receivePermissions
} from "../../store/createActions";
import {FormattedMessage, injectIntl} from 'react-intl'
import navLeftMenus from '../../config/nav-left-menu-config'
import {
  // adfsLogout,
  getCurrentUserType,
  // currentAccountIsSupplier,
  filterRoutes,
  getBreadItem,
  recursionRouterTwo,
  PUB_SUB_LANG_CHANGE,
} from '../../utils/util'
import BlankComponent from "../../components/blank-component/blank-component";
import home from "./home/home";
import PubSub from "pubsub-js";
import { LogoutOutlined, MenuUnfoldOutlined, MenuFoldOutlined, TranslationOutlined } from '@ant-design/icons';
import {Resizable} from "re-resizable";
// import {oldUrlPre} from '../../utils/constant/old-system-url-pre'
// import {ResizableBox} from 'react-resizable';/*不要删除,删除后左侧收缩没有样式*/
import {getCookie} from '../../utils/cookie/cookie'
import {reqLogout} from "@/api";
const {Header, Sider} = Layout;
class Admin extends React.Component {
  state = {
    collapsed: false,//侧边栏是否收起
    locale: zhCN,
    companyName: '',
    minWidth: 245,
    width: 245,
    i: 0,
    flag: true
  };

  f = (arr)=> {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].children && arr[i].children.length) {
        return this.f(arr[i].children)
      }
      return arr[i].meta.title
    }
  }

  getSystemTitle = (breadList)=> {
    for (let i = 0; i <  breadList.length; i++) {
      let item =  breadList[i];
      if (item.children && item.children.length) {
        return this.f(item.children)
      }
      return item.meta.title
    }
  }
  getRearrangementStatus(flag) {
    let rearrangementStatus = ''
    switch (flag) {
      case 'uploadSuccess':
        rearrangementStatus = '上传成功'
        break;
      case 'uploadFail':
        rearrangementStatus = '上传失败'
        break;
      case 'running':
        rearrangementStatus = '正在上传'
        break;
      default:
        rearrangementStatus = '重排消息'
        break
    }
    return rearrangementStatus
  }

  //拉伸动态计算宽度
  onResize = (e, direction, ref, d) => {
    const {collapsed} = this.state
    if (!collapsed) {
      const {width} = this.state
      this.setState({
        width: width + d.width,
      })
    }
  }

  //触发侧边栏收起展开函数
  onCollapse = collapsed => {
    const {width} = this.state
    this.setState({
      collapsed,
      minWidth: collapsed ? 80 : 245,
      width: collapsed ? 80 : width
    });
  };

  onCollapsed = () => {
    const {width,collapsed} = this.state
    this.setState({
      collapsed:!collapsed,
      minWidth: !collapsed ? 80 : 275,
      width: !collapsed ? 80 : width
    })
  }

  logout = async() => {
    // const id_token_hint = getUser()?.idToken
    // Modal.confirm({
    //   content: formatMessage(this, 'COMMONS_CONFIRM_LOG_OUT'),/*确定退出吗*/
    //   cancelText: formatMessage(this, 'COMMONS_CANCEL'),
    //   okText: formatMessage(this, 'COMMONS_CONFIRM'),
    //   onOk: async () => {

        await reqLogout({});
        this.props.history.replace('/login')
        removerAllKeys()
        removeUserCache();
        //跳转到迈的老系统
        // adfsLogout(id_token_hint)
        // try {
        //   const { receivePermissions, receiveFactoryList, receiveDeliveryAddressList, receivePurchaserCodeList, receiveAuthButtonList } = this.props
        //   receivePermissions({
        //   menuPermissions: []
        // })
        //   receiveFactoryList([])
        //   receiveDeliveryAddressList([])
        //   receivePurchaserCodeList([])
        //   receiveAuthButtonList([])
        // } catch (error) {
        //   console.log(error, 'error')
        // } finally {
        //   adfsLogout(id_token_hint)
        // }
    //   }
    // })
  }

  /*中英文切换*/
  changeLang = () => {
    // const {lang, receiveLang} = this.props
    // lang === 'en' ? receiveLang('zh') : receiveLang('en')
    // PubSub.publish(PUB_SUB_LANG_CHANGE, lang)
    const {lang, receiveLang} = this.props
    lang === 'en' ? receiveLang('zh') : receiveLang('en')
    PubSub.publish(PUB_SUB_LANG_CHANGE, lang)
  }

  /*创建路由*/
  createRoute = (value) => {
    let currentMenu = navLeftMenus.filter(item => item.key === value.path)
    // let currentMenu = navLeftMenus.filter(item=>item.title===value.menuName)
    // let noCurrentMenu = navLeftMenus.filter(item => item.isNotMenu)
    // console.log(noCurrentMenu, 'noCurrentMenu')
    if (currentMenu.length) {
      // let path = this.props.history.location.pathname
      let search = this.props.history.location.search
      // path = path.split('/old')[1]
      return (
          <Route
              key={value.path}
              // exact={value.children && value.children.length}
              exact={true}
              path={value.path + search}
              component={currentMenu[0]['component']}
          />
      )
      // }else if (value.isChildMenuLinks) {
    } else if (value.isChildMenuLinks) {
      // let path = value.menuUrl.split('/old/')[1] + "/"
      // window.open(oldUrlPre+path);
      // console.log('oldUrlPre+path>>>>',oldUrlPre + path);
      // return <NavLink target='_blank' href={oldUrlPre+path}>{value.menuName}</NavLink>
      // return <NavLink to={path}>{value.menuName}</NavLink>
      // return <Link onClick={()=>window.open(oldUrlPre+path)}>{value.menuName}</Link>
      // return (
      //     <Route
      //         key={value.menuUrl}
      //         exact={true}
      //         path={value.menuUrl}
      //         component={oldPage}/>)
    }
  }
  /*获取路由*/
  getRoute = (permissionList) => {
    if (permissionList instanceof Array && permissionList.length) {
      // eslint-disable-next-line array-callback-return
      return permissionList && permissionList.map(value => {
        let children = value.children
        if (!children || (children instanceof Array && !children.length)) {
          return this.createRoute(value)
        }
        if (children && children.length) {
          return this.getRoute(children)
        }
      })
    }
  }

  getNoCurrentMenu = () => {
    return (
      navLeftMenus.filter(item => item.isNotMenu).map(navLef => {
        return (
          <Route
            key={navLef.key}
            path={navLef.key}
            exact={true}
            component={navLef['component']}></Route>
        )
      })
    )
  }

  opeMenuClick = ({key}) => {
    switch (key) {
      case '1':
        this.logout()
        break;
      default:
        break;
    }
  }
  componentDidMount() {
    const userInfo = getUser()
    if (getCurrentUserType() === '1') {
      const companyName = userInfo?.defaultCompany || ''
      this.setState({
        companyName
      });
    }
    if (!getCookie("key")) {
      removerAllKeys()
      this.props.history.replace('/login')
    }
  }

  render() {
    let userInfo = getUser()
    if (!userInfo || !userInfo.username) {
      removerAllKeys()
      this.props.history.replace('/login')
    }
    let {width, minWidth, collapsed, companyName} = this.state;

    const {isLoading, lang, permissions} = this.props
    switch (companyName) {
      case '深':
      case 'Mindray Scientific':
        companyName = lang==='en'?'Mindray Scientific':'深'
        break;
      case '南京':
      case 'Nanjin Mindray':
        companyName = lang==='en'?'Nanjin Mindray':'南京'
        break;
      case '南京生物':
      case 'Nanjin Biology':
        companyName = lang==='en'?'Nanjin Biology':'南京生物'
        break
      default:
        companyName = lang==='en'?'Shenzhen Mindray':''
        break
    }

    const {menuPermissions} = permissions
    const path = this.props.location.pathname
    const defaultOpenKeys = filterRoutes(path)
    const breadList = recursionRouterTwo(defaultOpenKeys, menuPermissions)
    let systemTitle = ''
    if (path === '/login') {
      systemTitle = "登录"
    }else{
      systemTitle = this.getSystemTitle(breadList) || ''
    }
    document.getElementById('systemTitle').innerText=systemTitle

    const menuItems = (
      <Menu onClick={this.opeMenuClick}>
        <Menu.Item key='1'>
          <LogoutOutlined/>
          <span className='logout-text'><FormattedMessage id='COMMON_LOG_OUT'/></span>
        </Menu.Item>
      </Menu>
    )
    const menuTranslateItems = (
      <Menu onClick={this.changeLang}>
        {lang === 'en' &&
        <Menu.Item>
          <span>中文</span>
        </Menu.Item>}
        {lang === 'zh' &&
        <Menu.Item>
          <span>English</span>
        </Menu.Item>}
      </Menu>
    )
    return (
        <Layout className='srm-layout'>
          <Resizable enable={{
            top: false,
            right: !collapsed,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false
          }} size={{width: width}} maxWidth={400} minWidth={minWidth} axis='x' onResizeStop={this.onResize}>
            {/* <Sider className='left-sider' style={{maxWidth: '400px!important', width: width + '!important'}}
                  collapsible collapsed={collapsed} onCollapse={this.onCollapse}> */}
            <Sider className='left-sider' collapsible collapsed={collapsed} trigger={null}
              style={{width: width + '!important'}}>
              <NavLeft collapsed={collapsed}/>
            </Sider>
          </Resizable>
          <Layout className="content-layout">
            <Spin spinning={isLoading}>
              <Header className="site-layout-header" style={{padding: 0}}>
                <div className='site-layout-head-left'>
                  <span
                    onClick={this.onCollapsed}
                    className='site-layout-head-left-collapsed'
                  >
                  {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                  </span>

                  <Breadcrumb separator='/'>
                    {
                      getBreadItem(breadList, this)
                    }
                  </Breadcrumb>
                </div>
                <div className="site-layout-header-right">
                  {/* 公司切换
                  {
                    currentAccountIsSupplier() ? <Dropdown overlay={menu} trigger={['click']}>
                  <span className="ant-dropdown-link" style={{display: 'inlineBlock', width: '130px'}}>
                    {companyName} <DownOutlined/>
                  </span>

                    </Dropdown> : null
                  } */}
                  {/*跳转旧的srm*/}
                  {/* <span className="site-layout-header-content-user-info">
                    <a style={{color: 'white'}} href={oldUrlPre} rel="noreferrer" target="_blank"><FormattedMessage
                      id='COMMONS_OLD_SRM'/></a>
                  </span> */}
                  <Dropdown overlay={menuItems} arrow>
                    <span className='logout'>
                      {/*用户头像*/}
                      <img className='avatar' src={avatar} alt="头像"/>
                      {/*用户名*/}
                      <span className="site-layout-header-content-user-info">{getUserName()}</span>
                    </span>
                  </Dropdown>
                  {/*中英文切换*/}
                  {/* <span className='language' onClick={this.changeLang}>{lang === 'en' ? '中文' : 'EN'}</span> */}
                  <Dropdown overlay={menuTranslateItems} arrow>
                    <TranslationOutlined className='translate'/>
                  </Dropdown>
                  {/*退出登录*/}
                  {/* <span className='logout' onClick={this.logout}>
                    <LogoutOutlined/>
                    <img src={logout} alt="登出图标" className='logout-icon'/>
                    <span className='logout-text'><FormattedMessage id='COMMON_LOG_OUT'/></span>
                  </span> */}
                </div>
              </Header>
              <Switch>
                {/*<Redirect exact from='/' to='/home'/>*/}
                <Redirect exact from='/' to='/home'/>
                <Route path='/home' component={home}/>
                {/* <Route path='' exact component={home}/> */}
                {
                  this.getRoute(menuPermissions)
                }
                {
                  this.getNoCurrentMenu()
                }
                <Route exact path="/" component={home}/>
                <Route exact path='/blank' component={BlankComponent}/>
                <Route component={NotFound}/> {/*上面没有一个匹配, 直接显示*/}
              </Switch>
            </Spin>
          </Layout>
        </Layout>
    );
  }
}

export default injectIntl(connect(
    state => ({
      isLoading: state.isLoading,
      lang: state.lang,
      permissions: state.permissions,
    }),
    {
      receiveLang,
      // receivePermissions,
      receiveFactoryList,
      // receiveDeliveryAddressList,
      // receivePurchaserCodeList,
      // receiveAuthButtonList,
    }
)(Admin))
