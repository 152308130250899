import React from 'react'
import { Form, DatePicker } from "antd";
import { formatMessage } from "../../utils/util";
import { injectIntl } from "react-intl";
const {RangePicker} = DatePicker

class RangePickerDate extends React.Component {
  render() {
    const {name,label,initialValue,rules,labelCol,wrapperCol,disabled, defaultValue} = this.props
    return (
      <Form.Item name={name} label={formatMessage(this,label)} initialValue={initialValue} rules={rules} labelCol={labelCol} wrapperCol={wrapperCol}>
        <RangePicker allowEmpty = {[true,true]} disabled={disabled} style={{width: '100%'}} defaultValue={defaultValue}/>
      </Form.Item>
      )
  }
}

export default injectIntl(RangePickerDate)
