//第三方库
import {combineReducers} from 'redux'
import {getBrowseLang} from "../utils/util";

//开发者的js
import {
  PURCHASER_CODE_LIST,
  FACTORY_LIST,
  DELIVERY_ADDRESS_LIST,
  IS_LOADING,
  RECEIVE_LANG,
  RECEIVE_PERMISSION,
  BUY_NO,
  CATEGORY_TREE_LIST, 
  AUTH_BUTTON_LIST,
  MATERIAL_CLASSIFICATION_LIST,
  UNIT_CLASSIFICATION_LIST,
  AREA_TREE_LIST,
  AREA_LIST
} from './action-types'

const initLang = getBrowseLang()

function buyNo(agoNo='',action){
  let moreData = '';
  switch (action.type){
    case BUY_NO:
      moreData= action.moreData
      break;
    default:
      moreData = agoNo
      break
  }
  return moreData
}

function purchaserCodeList(state=[],action){
  let purchaserList = [];
  switch (action.type){
    case PURCHASER_CODE_LIST:
      purchaserList= action.purchaserCodeList
      break;
    default:
      purchaserList = state
      break
  }
  return purchaserList
}

function categoryTreeList(state=[],action){
  let categoryTreeList = [];
  switch (action.type){
    case CATEGORY_TREE_LIST:
      categoryTreeList= action.categoryTreeList
      break;
    default:
      categoryTreeList = state
      break
  }
  return categoryTreeList
}

function materialClassificationList(state=[],action){
  let materialClassificationList = [];
  switch (action.type){
    case MATERIAL_CLASSIFICATION_LIST:
      materialClassificationList= action.materialClassificationList
      break;
    default:
      materialClassificationList = state
      break
  }
  return materialClassificationList
}

function unitClassificationList(state=[],action){
  let unitClassificationList = [];
  switch (action.type){
    case UNIT_CLASSIFICATION_LIST:
      unitClassificationList= action.unitClassificationList
      break;
    default:
      unitClassificationList = state
      break
  }
  return unitClassificationList
}

function areaTreeList(state=[],action){
  let areaTreeList = [];
  switch (action.type){
    case AREA_TREE_LIST:
      areaTreeList= action.areaTreeList
      break;
    default:
      areaTreeList = state
      break
  }
  return areaTreeList
}

function areaList(state=[],action){
  let areaList = [];
  switch (action.type){
    case AREA_LIST:
      areaList= action.areaList
      break;
    default:
      areaList = state
      break
  }
  return areaList
}

function deliveryAddressList(state=[],action){
  let addressList = [];
  switch (action.type){
    case DELIVERY_ADDRESS_LIST:
      addressList= action.deliveryAddressList
      break;
    default:
      addressList = state
      break
  }
  return addressList
}

function factoryList(state=[],action){
  let factoryList = [];
  switch (action.type){
    case FACTORY_LIST:
      factoryList= action.factoryList
      break;
    default:
      factoryList = state
      break
  }
  return factoryList
}

function isLoading(state=false,action) {
  switch (action.type) {
    case IS_LOADING:
      return action.isLoading
    default:
      return state
  }
}

function lang(state=initLang,action) {
  switch (action.type) {
    case RECEIVE_LANG:
      return action.lang
    default:
      return state
  }
}

function permissions(state={},action) {
  switch (action.type) {
    case RECEIVE_PERMISSION:
      return action.permissions
    default:
      return state
  }
}
function authButtonList(state=[],action) {
  switch (action.type) {
    case AUTH_BUTTON_LIST:
      return action.authButtonList
    default:
      return state
  }
}

export default combineReducers({
  purchaserCodeList,
  factoryList,
  deliveryAddressList,
  isLoading,
  lang,
  permissions,
  buyNo,
  categoryTreeList,
  authButtonList,
  materialClassificationList,
  unitClassificationList,
  areaTreeList,
  areaList
})