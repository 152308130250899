const common_zh_CN ={
  /*生成送货单 /送货单管理*/
  COMMON_PURCHASE_ORDER:'采购订单',
  COMMON_SO_NO:'SO编号',
  COMMON_SO_ITEM:'SO项次',
  COMMON_PURCHASE_ORDER_MANAGEMENT_LIST:'研发采购订单管理列表',
  COMMON_TRANSIT_TRADE_ORDER_MANAGEMENT_LIST:'转口贸易订单管理列表',
  COMMON_PURCHASE_RETURN:'研发采购退货单',
  COMMON_RECEIPT_OF_ENTREPOT_TRADE:'转口贸易收货单',
  COMMON_SO_SHEET:'SO单',
  COMMON_PURCHASE_GENERATE_DELIVERY_NOTE:'研发采购生成送货单查询',
  COMMON_TRANSPORTTRADE_GENERATE_DELIVERY_NOTE:'转口贸易生成送货单查询',
  COMMON_PURCHASE_DELIVERY_NOTE:'研发采购送货单',
  COMMON_TRANSIT_DELIVERY_NOTE:'转口贸易送货单',
  COMMONS_MANUFACTURER:'制造商',
  COMMONS_MANUFACTURER_BRAND:'制造商/品牌',
  COMMONS_RETURN_VOUCHER:'退货凭证',
  COMMONS_RETURN_DATE:'退货日期',
  COMMONS_MANUFACTURER_NUMBER:'制造商编号',
  COMMON_PURCHASE_ORDER_ITEMS:'采购订单项次',
  COMMON_PURCHASE_ORDER_CODE:'采购订单编号',
  COMMON_APPLICATION_CODE:'申请单编号',
  COMMON_APPLICANT: '申请人',
  COMMON_APPLICANT_INFORMATION: '申请人信息',
  COMMON_SUPPLY:'供应商',
  COMMON_SUPPLY_CODE:'供应商编号',
  COMMON_MVT:'MVT',
  COMMON_MATERIAL_CODE:'物料编码',
  COMMON_REQUIRED_DELIVERY_DATE:'要求交货日',
  COMMON_SORT:'排序',
  COMMON_NULL:'无',
  COMMON_ORDER_DELIVERY:'订单交期',
  COMMON_ARRANGE_DELIVERY:'重排交期',
  COMMON_PURCHASE_CONFIRMATION:'采购确认',
  COMMON_REPLY_DATE:'回复交期',
  COMMON_COURIER_NUMBER:'快递单号',
  COMMON_EXPECTED_DATE_OF_ARRIVAL:'预计到货日期',
  COMMON_PACK_UP:'收起',
  COMMON_OPEN:'展开',
  COMMON_SUBMIT:'提交',
  COMMON_PUT_IN_DELIVERY_NOTE:'放入送货单',
  COMMON_PRINT_DELIVERY_NOTE:'打印送货单',
  COMMON_PRINT:'打印',
  COMMON_EXPORT_DELIVERY_NOTE:'导出送货单',
  COMMON_EXPORT_DELIVERY_NOTE_XLSX:'导出送货单.xlsx',
  COMMON_DETAIL:'明细',
  COMMON_CONFIRM:'确认',
  COMMON_ADD:'新增',
  COMMON_DELETE:'删除',
  COMMON_OPERATION:'操作',
  COMMON_CANCEL:'取消',
  COMMON_DELIVERY_NOTE_NO:'送货单号',
  COMMON_DELIVERY_ITEM:'送货单项次',
  COMMON_DATE_RECEIVED:'接收日期',
  COMMON_DECLARATION_MODEL_OR_MATERIAL_NUMBER:'报关型号/料号',
  COMMON_MATERIAL_NUMBER_OR_MODEL:'物料编码/型号',
  COMMON_UNIT:'单位',
  COMMON_DELIVERY_QTY:'送货数量QTY',
  COMMON_RECEIVE_QTY:'港口收货数量',
  COMMON_UNIT_PRICE:'单价U/P',
  COMMON_TOTAL_PRICE:'总价Amount',
  COMMON_BRAND:'报关品牌Brand',
  COMMON_COUNTRY_ORIGINAL:'报关生产国Original',
  COMMON_TEMPERATURE:'温度',
  COMMON_TOTAL_GROSS_WEIGHT:'总毛重',
  COMMON_TOTAL_NUMBER:'总件数',
  COMMON_EXPRESS_OR_SO_TRACKING_NUMBER:'快递/SO单号',
  COMMON_ARRIVAL_PORT:'到货港口',
  COMMON_INVOICE:'发票',
  COMMON_WAYBILL:'运单',
  COMMON_SHIPPING_ORDER_NO:'发运单号',
  COMMON_DELIVERY_INSPECTION_REPORT:'出货检验报告',
  COMMON_SUPPLIER_CODE:'供应商代码',
  COMMON_MRPC:'MRPC',
  COMMON_BOTTLENECK_MATERIAL:'瓶颈物料',
  COMMON_SUPPLIER_NAME:'收货确认状态',
  COMMON_Hong_Kong_remark:'香港备注',
  COMMON_CONFIRMATION_RECEIPT_STATUS:'收货确认状态',
  COMMON_ITEMS:'项次',
  COMMON_NAME_AND_SPECIFICATION:'品名及规格',
  COMMON_CAN_SEND_NUMBER:'可送数量',
  COMMON_DELIVERY_NUMBER:'发货数量',
  COMMON_PACKING_DETAIL:'包装明细',
  COMMON_SERIAL_NUMBER:'序列号',
  COMMONS_SERIAL_NUMBER:'编号',
  COMMON_SERIAL:'序列号(多个以;分开)',
  COMMON_REMARK:'备注',
  COMMON_PRODUCTION_BATCH:'生产批次',
  COMMON_IQC_CHECKBATCH:'IQC检验批号',
  COMMON_PRODUCT_DATE:'生产日期',
  COMMON_EXPIRY_DATE:'到期日期',
  COMMON_BRAND_NOT_ENGLISH:'报关品牌',
  COMMON_ORIGIN_COUNTRY:'报关原产国',
  COMMON_MANUFACTURER_COUNTRY:'报关生产商',
  COMMON_DEPARTURE_AREA:'启运国(地区)',
  COMMON_PO_PRICE:'PO价格',
  COMMON_LATEST_PRICE:'发票价',
  COMMON_NET_WEIGHT:'净重(kgs)',
  COMMON_GROSS_WEIGHT:'毛重(kgs)',
  COMMON_BOX_QTY:'件数',
  COMMONS_STORE_TEMPERATURE:'储存温度',
  COMMONS_SUPPLIER_MODEL:'供应商型号',
  COMMONS_DELIVERY_MODE:'交付模式',
  COMMONS_TERMS_INSPECTION:'检验方式',
  COMMONS_INVENTORY_LOCATION:'库存地点',
  COMMONS_EXPORT:'导出',
  COMMONS_SYNCHRONIZE_CI:'同步CI',
  COMMONS_EXPAND_COLUMN:'展开列',
  COMMONS_DETAILED:'详细',
  COMMONS_DETAIL_INFO:'详细信息',
  COMMONS_DATE_PURCHASE_CERTIFICATE:'采购凭证日期',
  COMMONS_MATERIAL_NUMBER:'物料编号',
  COMMONS_RETURN_QUANTITY:'退货数量',
  COMMONS_RETURN_ORDER_STATUS:'退货单状态',
  COMMONS_RETURN_ORDER_NO:'退货单号',
  COMMONS_MATERIAL_DESCRIPTION:'物料描述',
  COMMONS_PROCESS_CODE:'工艺代码',
  COMMONS_PRODUCT_GROUP:'产品组',
  COMMONS_INSPECTION_TEAM:'检验组',
  COMMONS_ENVIRONMENTAL_PROTECTION_SIGNS:'环保标识',
  COMMONS_WHETHER_JIT_MATERIAL:'是否JIT物料',
  COMMONS_PROJECT_CATEGORY:'项目类别',
  COMMONS_PURCHASING_INFORMATION_RECORD_NUMBER:'采购信息记录编号',
  COMMONS_EDITION:'版次',
  COMMONS_EDITION1:'版本',
  COMMONS_ACKNOWLEDGE_EDITION:'承认书版次',
  COMMONS_DRAWING_EDITION:'图纸版本',
  COMMONS_DRAWING_LT: 'LT',
  COMMONS_UNIT_PRICE:'单价',
  COMMONS_PRICE_UNIT:'价格单位',
  COMMONS_SALES_PURCHASE_TAX_CODE:'销售/购买税代码',
  COMMONS_CONDITIONAL_AMOUNT_OR_PERCENTAGE:'条件金额或百分比',
  COMMONS_NEW_PRICE:'新价格',
  COMMONS_DEFAULT_PREPAYMENT_AMOUNT:'默认预付金额',
  COMMONS_NEW_ARIBA_QUOTATION_NO:'新Ariba报价单号',
  COMMONS_ABC_LOGO:'abc标识',
  COMMONS_BUYER:'采购员',
  COMMONS_QUOTA_VALUE:'配额值',
  COMMONS_BUYER_CODE:'采购员编号',
  COMMONS_BUYER_NAME:'采购员姓名',
  COMMONS_NUMBER:'数量',
  COMMONS_UNIT:'单位',
  COMMONS_PURCHASING_UNIT:'采购单位',
  COMMONS_CONVERSION_RATE:'转换率',
  COMMONS_DELIVERY_SUMMARY:'交货汇总',
  COMMONS_INVENTORY_QUANTITY_TO_BE_INSPECTED:'待检库存数量',
  COMMONS_CHECKED_INVENTORY_QUANTITY:'已检库存数量',
  COMMONS_QUANTITY_OF_DEFECTIVE_STOCK:'不良库存数量',
  COMMONS_MATERIAL_ISSUANCE_SUMMARY:'发料汇总',
  COMMONS_QUANTITY_RECEIVED:'已收货数量',
  COMMONS_OUTSTANDING_AMOUNT:'未清数量',
  COMMONS_DELIVERY_COMPLETED_MARK:'交货已完成标识',
  COMMONS_CONTROLLER:'MRP控制者',
  COMMONS_ORDER_DELIVERY_DATE :'订单交期',
  COMMONS_REARRANGEMENT_STATE :'重排状态',
  COMMONS_AGREE_TO_CANCEL_ORDER :'是否同意取消订单',
  COMMONS_RESPONSE_DATE :'回复交期',
  COMMONS_SUPPLIER_ID :'供应商ID',
  COMMONS_RECEIVED_DATE:'到货日期',
  COMMONS_SHIPPING_SUPPLIER:'发货供应商',
  COMMONS_DATE_OF_DELIVERY:'发货通知日期',
  COMMONS_CONFIRM_DELIVERY_HISTORY:'交期确认历史',
  COMMONS_PURCHASE_ORDER_BATCH_IMPORT:'采购订单批量导入',
  COMMONS_DELIVERY_NOTE_DETAILS:'送货单详情',
  COMMONS_IMPORT:'导入',
  COMMONS_IMPORT_DEMAND_CONFIRMATION_INFORMATION:'导入需求确认信息',
  COMMONS_IMPORT_SUCCESS:'导入成功',
  COMMONS_CONFIRM:'确定',
  COMMONS_CANCEL:'取消',
  COMMONS_IMPORT_GENERATE_DELIVERY_NOTE:'导入生成送货单',
  COMMONS_ADD:'添加',
  COMMONS_LEGAL_BODY:'法体',
  COMMONS_CONTINUE_ADD:'继续添加',
  COMMONS_DELETE:'删除',
  COMMONS_DELETE_SELECTED_ITEM:'删除选中项',
  COMMONS_OPERATION:'操作',
  COMMONS_DOWNLOAD:'下载',
  COMMONS_DOWNLOAD_CI:'下载CI',
  COMMONS_DOWNLOAD_DELIVER_PO:'下载生成送货单的PO',
  COMMONS_UPLOAD_DELIVER_PO:'上传生成送货单',
  COMMONS_DETAIL:'详情',
  COMMONS_DELIVERY_NO:'送货单号',
  COMMONS_MUST_WAY_BILL_NO:'发运单上传和填写发运单号必须选择其中一项',
  COMMONS_SHEN_ZHEN:'深圳',
  COMMONS_HONG_KONG:'香港',
  COMMONS_EXPORT_DELIVERY_DETAILS_XLSX:'导出送货单明细.xlsx',
  COMMONS_EXPORT_DELIVERY_DETAILS:'导出送货单明细',
  COMMONS_SELECT_DATA_FOR_EXPORT:'请勾选一条数据导出',
  COMMONS_SUBMIT_SUCCESS:'提交成功',
  COMMONS_CANCEL_SUCCESS:'取消成功',
  COMMONS_DELETED_SUCCESS:'删除成功',
  COMMONS_DELIVERY_NUMBER:'送货数量',
  COMMONS_RECEIVED_QUANTITY:'实收数量',
  COMMONS_DIFFERENCE:'差额',
  COMMONS_QUALIFIED_NUMBER:'合格数量',
  COMMONS_NO_DATA_SUBMIT:'没有可提交的数据',
  COMMONS_REVIEW_DATE_LIST:'回复交期列表.xlsx',
  COMMONS_SELECT_DATA_FOR_PRINTING:'只能勾选一条数据打印',
  COMMONS_SELECT_DATA_FOR_CANCEL_DELIVERY_NOTE:'只能勾选一条数据取消送货单',
  COMMONS_SELECT_DATA_FOR_SAVE:'只能勾选一条数据保存',
  COMMONS_PO_DELIVERY:'PO送货单.pdf',
  COMMONS_IMPORT_SUCCESSFULLY:'数据导入成功',
  COMMONS_SELECT_DATA_FOR_MANIPULATE:'请选择需要操作的数据',
  COMMONS_SAVE_SUCCESSFULLY:'保存成功',
  COMMONS_NEW_SUCCESSFULLY:'新增成功',
  COMMONS_SUMMARY:'汇总',
  COMMONS_TOTAL_AMOUNT:'总金额',
  COMMONS_PURCHASE_BACK_DELIVERY_DATE:'采购批量回复交期',
  COMMONS_PRINT_CONTRACT:'打印合同',
  COMMONS_EXPORT_CONTRACT:'导出合同',
  COMMONS_PROJECT_REPORT_CUSTOMS_PRE_VERIFICATION_CERTIFICATE:'立项报告/预核销证/注册证',
  COMMONS_INPUT_PACKING_NUMBER_EXPORT:'请输入装箱单号才能导出',
  COMMONS_NOTHING_EXPORT:'没有可导出的内容',
  COMMONS_CHECK_DATA_PRINT:'请勾选要打印的数据',
  COMMONS_CHECK_DATA_EXPORT:'请勾选要导出的数据',
  COMMONS_UPLOADED_SUCCESSFULLY:'上传成功',
  COMMONS_DELETED_SUCCESSFULLY:'删除成功',
  COMMONS_TURN_SINGLE_SUCCESSFUL:'转单成功',
  COMMONS_SELECT_A_DATA:'请选择一条数据',
  COMMONS_CHECK_AT_LEAST_NUMBER:'请至少勾选一条数据',
  COMMONS_NO_DATA_EXPORT_ON_PRICE_DIFFERENCE:'价差详情页面无数据导出',
  COMMONS_HAS_BEEN_PAID:'该对账单已经完成了付款，不能在发起提前/延迟/冻结申请流程!',
  COMMONS_ADDED_CONTRACT_SUCCESSFULLY:'添加合同成功',
  COMMONS_SELECT_DATA_WANT_TO_MODIFY:'请选择要修改的数据',
  COMMONS_MODIFY_SUCCESSFULLY:'修改成功',
  COMMONS_SIGNED_IN_SUCCESSFULLY:'签收成功',
  COMMONS_TURN_SINGLE:'转单',
  COMMONS_ADD_CONTRACT:'添加合同',
  COMMONS_DEL_CONTRACT:'移除合同',
  COMMONS_SAVE_EDIT:'保存修改',
  COMMONS_ONE_KEY_PRINT_CUSTOMS_DECLARATION_CONTRACT:'一键打印报关合同',
  COMMONS_PRINT_CONTRACT_AND_INVOICE:'打印报关合同与发票',
  COMMONS_EXPORT_CUSTOMS_DECLARATION_CONTRACT:'一键导出报关合同',
  COMMONS_EXPORT_CONTRACT_AND_INVOICE:'导出报关合同与发票',
  COMMONS_EXPORT_PACKING_LIST:'导出装箱单',
  COMMONS_TOTAL:'共',
  COMMONS_ITEMS:'票',
  COMMONS_CONTRACT_DETAILS:'合同明细',
  COMMONS_SERVICE_MANAGEMENT:'车次管理',
  COMMONS_PROJECT_REPORT_CUSTOMS_PRE_VERIFICATION:'立项报告/预核销证列表',
  COMMONS_NAME:'名称',
  COMMONS_TYPE:'类型',
  COMMONS_TRAINS:'车次',
  COMMONS_PACKING_LIST_NO:'装箱单号',
  COMMONS_PORT_ID:'港口标识',
  COMMONS_DATE_OF_DEPARTURE:'发车日期',
  COMMONS_TRAINS_STATE:'车次状态',
  COMMONS_PACKING_DATE:'装箱日期',
  COMMONS_CONTRACT_NUMBER:'合同号',
  COMMONS_GOODS_NAME:'品名',
  COMMONS_CUSTOMS_DECLARATION:'报关单号',
  COMMONS_TARIFFS:'关税',
  COMMONS_THE_VAT:'增值税',
  COMMONS_CURRENCY:'币种',
  COMMONS_PLACE_OF_TRADE_AND_PLACE_OF_SHIPMENT:'贸易地/起运地',
  COMMONS_CREATER:'创建人',
  COMMONS_CONTAINER_NUMBER:'箱数',
  COMMONS_CHECK_DOCUMENTS:'单证核对',
  COMMONS_CONTRACT_STATES:'合同状态',
  COMMONS_CARTON_NO:'箱号',
  COMMONS_WHETHER_OK:'是否OK',
  COMMONS_JUN_WEI_REPLY:'骏伟回复',
  COMMONS_REMARKS_CARD_BOARD_VARIABLE_PRODUCT_NAME:'备注(卡板/品名可变部份)',
  COMMONS_CUSTOMS_CLEARANCE_CUSTOMS_BROKER_COMMODITY_INSPECTION_REPLY:'报关部/报关行/商检回复',
  COMMONS_ADD_TARIFF_EXCLUSION_NUMBER:'加征关税排除号',
  COMMONS_ANTI_DUMPING_DUTIES:'反倾销税',
  COMMONS_DEEP_PORT_FREIGHT:'深港运费',
  COMMONS_BUSINESS_DATE:'业务日期',
  COMMONS_CUSTOMS_DECLARATION_CONTRACT_NUMBER:'报关合同号',
  COMMONS_DATE:'日期',
  COMMONS_DECLARATION:'新关单',
  COMMONS_HAVE_TO_CHECK:'已核对',
  COMMONS_HAS_BEEN_UPDATED:'已更新',
  COMMONS_CAN_ADD_SINGLE:'可加单',
  COMMONS_STATEMENT:'结单',
  COMMONS_ALREADY_START:'已发车',
  COMMONS_IMPORT_PRICE:'进口总价',
  COMMONS_DECLARATION_CONTRACT_STATUS:'报关合同状态',
  COMMONS_YES:'是',
  COMMONS_NO:'否',
  COMMONS_PROJECT_REPORT:'立项报告',
  COMMONS_CUSTOMS_PRE_VERIFICATION:'预核销证',
  COMMONS_PACKING_LIST_NOT_EMPTY:'装箱单号不能为空',
  COMMONS_COMPANY_NAME:'公司名称',
  COMMONS_CAR_STATE:'发车状态',
  COMMONS_PORT_ID_2:'口岸标识',
  COMMONS_CAPACITY_NUMBER:'可容件数',
  COMMONS_CAPACITY_GROSS_WEIGHT:'可容毛重(KG)',
  COMMONS_CAR_NUMBER:'车牌号码',
  COMMONS_DRIVER_NAME:'司机名称',
  COMMONS_PHONE:'联系电话',
  COMMONS_SEAL_NUMBER:'封条号码',
  COMMONS_CAN_LOADED:'可装载',
  COMMONS_SHEN_MAI:'深迈',
  COMMONS_SHEN_MAI_KE:'深迈科',
  COMMONS_RULE_OUT:'排除',
  COMMONS_COMPANY:'公司',
  COMMONS_IS_REQUIRED:'必填',
  COMMONS_MUST_BE_13:'必须13位',
  COMMONS_UPLOAD_FILE:'上传文件',
  COMMONS_PACKING_LIST_DETAILS:'箱单详情',
  COMMONS_CANCEL_FULL_CAR:'取消满车',
  COMMONS_GRID_ABNORMAL:'发车异常',
  COMMONS_FULL_CAR:'满车',
  COMMONS_START_CAR:'发车',
  COMMONS_CAR_NO_1_AND_NO_5_CANNOT_BE_BLANK:'1号车和5号车除外的公司名称',
  COMMONS_UPDATE:'修改',
  COMMONS_GENERATE_PRICE_DIFFERENCE_DOC:'生成价差单',
  COMMONS_VIEW_HISTORY:'查看历史',
  COMMONS_GENERATE_STATEMENT:'生成对账单',
  COMMONS_ESTIMATION_CLEANUP:'暂估清理',
  COMMONS_ADD_BASE_DATA:'添加基础数据',
  COMMONS_EDIT_BASE_DATA:'修改基础数据',
  COMMONS_SUPPLIER_CODE:'供应商代码',
  COMMONS_SUPPLIER_CODE_NOT_EMPTY:'物料编码不能空',
  COMMONS_TEL_PHONE_NUMBER:'电话',
  COMMONS_FAX:'传真',
  COMMONS_EMAIL:'邮箱',
  COMMONS_EDIT:'编辑',
  COMMONS_EDIT_SUCCESSFULLY:'编辑成功',
  COMMONS_QUERY_RESULTS:'查询结果',
  COMMONS_COMPARE_RESULTS:'比对结果',
  COMMONS_MERGE_SUCCEEDED:'合并成功',
  COMMONS_ADD_USER:'添加用户',
  COMMONS_EDIT_USER:'编辑用户',
  COMMONS_USER_NAME_CANNOT_EMPTY:'用户名不能为空',
  COMMONS_PASSWORD:'密码',
  COMMONS_TEL_PHONE_CANNOT_EMPTY:'电话不能空',
  COMMONS_EMAIL_CANNOT_EMPTY:'邮箱不能空',
  COMMONS_NAME_CANNOT_EMPTY:'姓名不能空',
  COMMONS_TYPE_CANNOT_EMPTY:'类型不能空',
  COMMONS_PASSWORD_NOT_EMPTY:'密码不能空',
  COMMONS_ADDRESS:'地址',
  COMMONS_CONTACT:'联系人',
  COMMONS_SYNCHRONIZATION_TIME:'同步时间',
  COMMONS_NEWEST:'最新',
  COMMONS_MATERIAL_GROUP:'物料组',
  COMMONS_SCOPE_COOPERATION:'合作范围',
  COMMONS_SCOPE_BUSINESS:'经营范围',
  COMMONS_OPTIMUM_LEVEL:'优选等级',
  COMMONS_REGION:'地区',
  COMMONS_QUALITY_SYSTEM:'质量体系',
  COMMONS_PRODUCT_CERTIFICATION:'产品认证',
  COMMONS_EXPORT_TEMPLATE:'导出模板',
  COMMONS_EDIT_VENDOR:'编辑供应商',
  COMMONS_BULK_IMPORT_SUPPLIERS:'批量导入供应商',
  COMMONS_ADDRESS_CANNOT_BE_EMPTY:'地址不能为空',
  COMMONS_VENDOR_NAME_CANNOT_EMPTY:'供应商名称不能为空',
  COMMONS_VENDOR_CANNOT_EMPTY:'供应商不能为空',
  COMMONS_DELIVERY_DETAILS_XLSX:'送货单明细.xlsx',
  COMMONS_DELIVERY_DETAILS:'送货单明细',
  COMMONS_GENERATE_NOTE_AND_EXPORT_XLSX:'生成送货单导出.xlsx',
  COMMONS_SELECT_CONTRACT_TO_DELETE:'请选择要删除的合同',
  COMMONS_CONTRACT_DELETED_SUCCESSFULLY:'删除合同成功',
  COMMONS_FILE:'文件',
  COMMONS_LOOK_FILE:'查看文件',
  COMMONS_TYPE_CANNOT_BE_EMPTY:'类型不能为空',
  COMMONS_THE_FILE_CANNOT_BE_EMPTY:'文件不能为空',
  COMMONS_SELECT_FILE:'选择文件',
  COMMONS_INVOICE_NAME:'发票名称',
  COMMONS_INVOICE_NAME_CANNOT_BLANK:'发票名称不能为空',
  COMMONS_NATIONAL_CODE_STANDARD:'国家代码标准',
  COMMONS_NATIONAL_CODE_STANDARD_XLSX:'国家代码标准.xlsx',
  COMMONS_UPLOAD_INVOICE:'上传发票',
  COMMONS_FILE_NAME:'文件名称',
  COMMONS_FILE_NAME_CANNOT_BE_EMPTY:'文件名称不能为空',
  COMMONS_ADD_SUCCESSFULLY:'添加成功',
  COMMONS_IMPORT_ORDER_TEMPLATE_XLSX:'订单导入模板.xlsx',
  COMMONS_IMPORT_SUPPLIER_TEMPLATE_XLSX:'供应商导入模板.xlsx',
  COMMONS_CAN_NOT_EMPTY:'不能为空 。',
  COMMONS_ALL_TOTAL:'共',
  COMMONS_ITEM:'条',
  COMMON_ALL:'全部',
  COMMON_FAILURE_TO_REPLY_TO_DELIVERY_DATE:'未回复交期',
  COMMON_UNSATISFIED_DELIVERY_DATE:'交期不满足',
  COMMON_BE_OVERDUE:'逾期',
  COMMON_DELIVERY_WITHIN_THREE_DAYS:'三天内交货',
  COMMON_NEW_ORDER:'新单',
  COMMON_TO_BE_CONFIRMED:'待确认',
  COMMON_CONFIRMATION_OK:'收货确认OK',
  COMMON_CONFIRM_OK:'确认OK',
  COMMON_PLAN_DELIVERY_DATES:'计划到货日期',
  COMMON_THE_SERIAL_NUMBER:'序号',
  COMMON_INTERNAL_CHECKING:'内检',
  COMMON_OUTSIDE_INSPECTION:'外检',
  COMMON_EXEMPTED_FROM_INSPECTION:'免检',
  COMMON_SEARCH:'搜索',
  COMMON_LOG_OUT:'退出登录',
  COMMON_RESET:'重置',
  COMMON_PRINT_PDF:'打印PDF',
  COMMON_PURCHASE_ORDER_NO:'采购订单编号',
  COMMON_FACTORY:'工厂',
  COMMON_COLUMN_NAME:'列名',
  COMMON_USERNAME:'用户名',
  COMMON_NAME:'姓名',
  COMMON_CODE:'供应商编码',
  COMMON_COMPANY_NAME:'供应商名称',
  COMMON_CONFIRMATION_TIME:'确认时间',
  COMMON_COMPANY:'公司',
  COMMON_COPYRIGHT :'© 2020 深圳迈瑞生物医疗电子股份有限公司 版权所有',
  COMMON_CURRENCY:'币别',
  COMMON_WELCOME_SRM:'欢迎登录srm系统',
  COMMON_NET_ERROR:'网络错误,请联系管理员',
  COMMON_REPLY_DELIVERY_DATE_CANNOT_BE_BLANK:'回复交期不能为空',
  COMMON_NO_ACCESS :'没有访问权限',
  COMMON_REQUEST_NOT_EXIST :'网络请求资源地址不存在',
  COMMON_TOKEN_HAS_EXPIRED:'token过期,请重新登录',
  COMMONS_LOGIN_SUCCESSFUL:'登录成功',
  COMMONS_CONFIRM_LOG_OUT:'确定退出登录吗?',
  COMMONS_CONCENTRATIONS:'浓度',
  COMMONS_ENZYME_ACTIVITY:'酶活力',
  COMMONS_TOTAL_GROSSWEIGHT_MORE_ZERO:'总毛重必须大于0 。',
  COMMONS_total_No_MORE_ZERO:'总件数必须大于0 。',
  COMMONS_NET_WEIGHT_MORE_ZERO:'净重必须大于0',
  COMMONS_CONFIRMATION_OF_RECEIPT_DATE:'香港收货确认',
  COMMONS_SURE_TO_DELETE_FILE:'确定要删除文件吗？',
  COMMONS_FILE_TYPE_NOT_ACCEPTED:'不接受此文件类型',
  COMMONS_FILE_TYPE_NOT_SIZE:'文件大小不允许超过20MB',
  COMMONS_DOWN_LINE:'拆行',
  COMMONS_OLD_SRM:'旧 SRM',
  COMMONS_SURE_DEL:'你确定要删除吗?',
  COMMONS_SURE_DEL1:'你确定要取消送货单',
  COMMONS_SURE_ABANDON:'你确定要废弃吗?',
  /*以下是发了，没有更新的*/
  COMMONS_PURCHASE_REQUESTS:'采购申请',
  COMMONS_MONEY :'金额',
  COMMONS_CURRENCY_CODE :'货币码',
  COMMONS_ARRANGE_NUMBER :'重排次数',
  COMMONS_REASONS:'原因',
  COMMONS_UPLOAD_DATE:'上传日期',
  COMMONS_UPLOAD_TIME:'上传时间',
  COMMONS_UPLOAD_BY:'上传人',
  COMMONS_UPLOAD_REORDER_LIST:'上传重排表',
  COMMONS_REARRANGEMENT_TEMPLATE:'重排模板',
  COMMONS_UPLOAD:'上传',
  COMMONS_DELIVERY:'送货单',
  COMMONS_UPLOAD_DELIVERY:'上传送货单',
  COMMONS_REARRANGING_TABLE:'重排表',
  COMMONS_DELETE_SELECTED:'删除所选',
  COMMONS_PRINT_DELIVERY:'打印送货单(PDF)',
  COMMONS_PRINT_DELIVERY_PDF:'打印送货单(PDF)',
  COMMONS_PRINT_BOX_LABEL:'打印外箱标贴',
  COMMONS_IMPORT_DELIVERY:'导入送货单',
  // COMMONS_CHECK_DELIVERY:'查看送货单',
  COMMON_BUYER_CONFIRM:'采购确认',
  COMMON_BUYER_DISMISS:'采购驳回',
  COMMON_REQUEST_OR_CLEAR_EARLY_RECEIPT:'申请/清除提前收货',
  COMMON_REQUEST_EARLY_RECEIPT:'申请提前收货',
  COMMON_CLEAR_EARLY_RECEIPT:'清除提前收货',
  // COMMON_ORDER_CONFIRMATION_AND_PDF_GENERATION:'订单确认并生成PDF',
  COMMON_BATCH_REPLY_TEMPLATE:'批量回复模版',
  COMMON_EXPORT_TO_EXCEL:'导出到Excel',
  COMMON_IMPORT_EXCEL_CONFIRMATION:'导入Excel确认',
  COMMON_CONFIRM_TICK:'确认勾选项',
  COMMON_BATCH_REPLY_DELIVERY_DATE:'批量回复交期',
  COMMON_CLEAR_REARRANGEMENT:'清空重排',
  COMMON_FILE_CANNOT_EXCEED_39:'文件名不能超过39个字符',
  COMMONS_EXPORT_GENERATE_DELIVERY_NOTE:'导出生成送货单',
  COMMONS_DELIVERYQTY_MORE_THAN_COMFIRMQTY:'发货数量不能大于可送数量',
  COMMONS_NO_CAN_NOT_BLANK:'勾选了是否ok,装修单号不能为空',
  COMMONS_GROSS_WEIGHT_MUST_MORE_THAN_NET_WEIGHT:'总毛重必须大于总净重',
  COMMON_ORDER_CONFIRMATION:'订单确认',
  COMMON_PDF_GENERATION:'生成PDF',
  COMMON_SPLIT_BATCH_REPLY_DELIVERY_DATE:'分批回复交期',
  COMMON_BATCH_QUANTITY_MORE_THAN_ZERO:'分批数量必须大于0',
  COMMON_BATCH_REPLY_DATE_NOT_BLANK:'分批回复交期不能为空',
  COMMON_REPLY_DATE_NOT_BLANK:'回复交期不能为空',
  COMMONS_SELECT_DATA_ONE:'请勾选一条数据',
  COMMONS_DOCUMENT_CHECK:'单证核对',
  COMMONS_ONLY_ONE_CHECKED:'只能勾选一条数据',
  COMMONS_MODIFY_RECONCILIATION_QUANTITY_OR_REMARKS:'修改对账数量/备注',
  COMMONS_INITIAL_BE_INVOICED:'待开票退回初始',
  COMMONS_ONE_CLICK_INVOICING_400:'一键开票(400)',
  COMMONS_ONE_CLICK_INVOICING_250:'一键开票(250)',
  COMMONS_CHANGE_TO_MONTHLY_SETTLEMENT:'更改为月结',
  COMMONS_EXPORT_DELAYED_DELIVERY_LIST:'导出延迟交期列表',
  COMMONS_PRINT_ENGLISH_PDF:'打印英文PDF',
  COMMONS_TOTAL_NO_MORE_THAN_ZERO:'总件数必须大于0',
  COMMONS_NO_QUANTITY_TO_SEND:'没有可送数量',
  COMMONS_REPLY_DELIVERY_DATE_TEMPLATE:'批量回复交期模板',
  COMMONS_INCORRECT_FORMAT_OF_PACKAGING_DETAILS:'包装明细格式不正确',
  COMMONS_DELIVERY_NOT_EMPTY:'发货数量不能空',
  COMMONS_DELIVERY_MORE_THAN_ZERO:'发货数量必须大于0',
  COMMONS_PACKAGE_DETAILS_NOT_EMPTY:'包装明细不能为空',
  COMMONS_GENERATE_DELIVERY:'生成送货单',
  COMMONS_GENERATE_DELIVERY_NOTE:'是否生成送货单',
  COMMONS_OPERATION_TYPE:'操作类型',
  COMMONS_LOGIN_NAME:'登录名',
  COMMONS_OPERATION_DATE:'操作日期',
  COMMONS_OPERATION_TIME:'操作时间',
  COMMONS_MATERIAL_IDENTIFICATION:'物料标识',
  COMMONS_VALIDITY_PERIOD:'是否有效期',
  COMMONS_ARRIVAL_ACCORDING_TO_NOTICE:'按通知到货',
  COMMONS_PRINT_ORDER_PDF:'打印订单pdf',
  COMMONS_INTERNAL_OR_EXTERNAL_INSPECTION:'内/外检',
  COMMONS_RECEIVING_STATUS:'收货状态',
  COMMONS_MORNING:'上午',
  COMMONS_AFTERNOON:'下午',
  COMMONS_EVENING:'晚上',
  COMMONS_ESTIMATED_ARRIVAL_TIME:'预计到货时间',
  COMMONS_ESTIMATED_ARRIVAL_TIME_NOT_BLANK:'预计到货时间不能为空',
  COMMONS_HIDE:'隐藏',
  COMMONS_SHOW:'显示',
  COMMONS_CLICK_TO_EXPAND:'点击展开',
  COMMONS_STAFFING:'配置人员',
  COMMONS_RECEIPT_QTY_MORE_THAN_DELIVERY_QTY:'港口收货数量不能大于发货数量',
  COMMONS_COMPANY_CODE:'公司代码',
  COMMONS_ACCOUNTING_VOUCHER:'会计凭证',
  COMMONS_FISCAL_YEAR:'会计年度',
  COMMONS_ACCOUNTING_ITEMS:'会计项次',
  COMMONS_PURCHASE_VOUCHER_NO:'采购凭证号',
  COMMONS_PURCHASE_VOUCHER_LN:'采购凭证项次',
  COMMONS_MATERIAL_NUMBER_USED_BY_SUPPLIER:'供应商使用的物料编号',
  COMMONS_POSTING_DATE:'过帐日期',
  COMMONS_MOVEMENT_TYPE:'移动类型',
  COMMONS_RECEIPT_QUANTITY:'收货数量',
  COMMONS_BASIC_UOM:'基本计量单位',
  COMMONS_NET_PRICE:'净价',
  COMMONS_PURCHASING_ORGANIZATION:'采购组织',
  COMMONS_RECONCILIATION_TYPE:'对账类型',
  COMMONS_RECONCILIATION_STATUS:'对账状态',
  COMMONS_ORDER_TYPE:'订单类型',
  COMMONS_MONTHLY_SETTLEMENT:'月结',
  COMMONS_WITH_GOODS:'随货',
  COMMONS_DOCUMENT_TYPE:'凭证类型',
  COMMONS_VOUCHER_CURRENCY_AMOUNT:'凭证货币金额',
  COMMONS_CONFIRM_STATUS:'确认状态',
  COMMONS_TAX_RATE:'税率(%)',
  COMMONS_PURCHASER_CODE:'采购员代码',
  COMMONS_SPLIT_RECONCILIATION_OR_NOT:'是否拆分对账',
  COMMONS_SPLIT_RECONCILIATION_QUANTITY:'已拆分对账数量',
  COMMONS_ACCOUNTING_SUBJECT:'会计科目',
  COMMONS_ORDER_QUANTITY:'订单数量',
  COMMONS_ITEM_VOUCHER_LN:'物料凭证项次',
  COMMONS_TAX_CODE:'税码',
  COMMONS_OPERATION_RECORD:'操作记录',
  COMMONS_ARE_YOU_SURE_TO_DELETE_THE_SELECTED:'确定删除所选吗',
  COMMONS_DELAY_TABLE:'延迟表',
  COMMONS_BATCH_QUANTITY:'分批数量',
  COMMONS_REPLY_DELIVERY_DATE:'回复重排交期',
  COMMONS_EARLY_RECEIPT:'提前收货',
  COMMONS_DELAY_TYPE:'推迟类型',
  COMMONS_ADVANCE_OR_ELAY_DESCRIPTION:'提前/推迟说明',
  COMMONS_DEAD_CYCLE:'呆滞周期',
  COMMONS_REARRANGEMENT_STATUS:'重排状态',
  COMMONS_IS_CONFIRM:'是否确认',
  COMMONS_IS_VALIDITY_PERIOD:'是否有效期',
  COMMONS_DELIVERY_CONFIRMATION_STATUS:'交期确认状态',
  COMMONS_UNCONFIRMED:'未确认',
  COMMONS_BE_CONFIRMED_BY_PURCHASE:'待采购审批',
  COMMONS_TO_BE_UPDATED:'待更新',
  COMMONS_COMPLETED:'已完成',
  COMMONS_CONFIRMED:'已确认',
  COMMONS_CONFIRMED_NOT_GENERATED:'已确认(未生成)',
  COMMONS_GENERATED_ALL:'已生成(全部)',
  COMMONS_GENERATED_INSUFFICIENT_REPLENISHMENT:'已生成(未足额补货)',
  COMMONS_GENERATED_REPLENISHED_IN_FULL:'已生成(已足额补货)',
  COMMONS_REMINDER_TYPE:'提醒类型',
  COMMONS_FAILURE_DELIVER_TIME:'未按期交货',
  COMMONS_PARTIAL_DELIVERY:'分批交货',
  COMMONS_PARTIAL_DELIVERIES_REASONS_FOR_DELAYS:'分批交货&延迟原因',
  COMMONS_EXPORT_DELAYED_REPLIES:'导出延迟回复',
  COMMONS_PO_CONFIRMED:'PO是否确认',
  COMMONS_NOT_GENERATED:'未生成',
  COMMONS_GENERATED:'已生成',
  COMMONS_DECLARED:'已报关',
  COMMONS_NO_REARRANGEMENT:'无重排',
  COMMONS_REARRANGEMENT_NOT_EMPTY:'重排不为空',
  COMMONS_ADVANCE:'提前',
  COMMONS_DELAY:'推迟',
  COMMONS_FORCED_REARRANGEMENT:'强制重排',
  COMMONS_1_3_DAYS_OVERDUE:'逾期1-3天',
  COMMONS_4_7_DAYS_OVERDUE:'逾期4-7天',
  COMMONS_8_15_DAYS_OVERDUE:'逾期8-15天',
  COMMONS_MORE_THAN_15_DAYS_OVERDUE:'逾期15天以上',
  COMMONS_REARRANGEMENT_WARNING:'重排预警',
  COMMONS_ALL_EXCEPT_REARRANGEMENT_ALERT:'全部(重排预警除外)',
  COMMONS_ALERT_TYPE:'预警类型',
  COMMONS_SPECIAL_SCREENING:'特殊筛选',
  COMMONS_SUPPLIER_WITHIN_3_DAYS:'供应商晚于3天内',
  COMMONS_SUPPLIER_WITHIN_4_DAYS:'供应商晚于4天内',
  COMMONS_SUPPLIER_WITHIN_5_DAYS:'供应商晚于5天内',
  COMMONS_SUPPLIER_MORE_THAN_5_DAYS_LATER:'供应商晚于5天以上',
  COMMONS_IMPORT_REPLY_DELIVERY_DATE:'导入回复交期',
  COMMONS_QUALITY_PROBLEM:'质量问题',
  COMMONS_ACCOUNTING_ISSUES:'账务问题',
  COMMONS_ORIGINAL_LT_LENGTHENING:'原厂LT拉长',
  COMMONS_BUSINESS_ISSUES:'商务问题',
  COMMONS_TEMPORARY_ORDER_INSERTION:'临时插单',
  COMMONS_ABNORMAL_CUSTOMER_SUPPLY:'客供料异常',
  COMMONS_PROCUREMENT_EXECUTION:'采购执行',
  COMMONS_FORCE_MAJEURE:'不可抗力',
  COMMONS_CUSTOMS_DECLARATION_RESOURCES:'报关资源',
  COMMONS_OTHER:'其他',
  COMMONS_CONFIRMATION_SUCCESSFUL:'确认成功',
  COMMONS_SUPPLIER_IN_SEARCH_CRITERIA_IS_REQUIRED:'搜索条件中的供应商为必填项',
  COMMONS_UPDATE_DELIVERY:'修改送货单',
  COMMONS_PASS:'通过',
  COMMONS_PURCHASE_ORDER_AND_ITEM_NOT_EXIST:'excel表中采购订单和项次不存在',
  COMMONS_ORDER_REARRANGEMENT_HISTORY:'订单重排历史记录',
  COMMONS_REARRANGE_TEMPLATE:'重排模版',
  COMMONS_APPROVED:'审核通过',
  COMMONS_REVIEW_REJECTED:'审核驳回',
  COMMONS_DELIVERY_LN:'送货项次',
  COMMONS_CREATION_TIME:'创建时间',
  COMMONS_DELIVERY_NOTE_STATUS:'送货单状态',
  COMMONS_SUPPLIER:'供应商',
  COMMONS_RECEIPT_COMPLETED:'收货完成',
  COMMONS_GENERATING:'生成中',
  COMMONS_VERSION_NUMBER:'版本号',
  COMMONS_EMPTY_REARRANGEMENT_SUCCEEDED:'清空重排成功',
  COMMONS_EXPORT_ERROR_MESSAGE:'导出错误信息',
  COMMONS_REARRANGE_DATA:'重排数据',
  COMMONS_PURCHASE_ORDER:'采购订单',
  COMMONS_APPROVAL_IDENTIFICATION:'审批标识',
  COMMONS_REVIEWER:'审核人',
  COMMONS_APPLICATION_DATE:'申请日期',
  COMMONS_APPLICANT:'申请者',
  COMMONS_QUANTITY_EXPLAIN:'提前收货数量&说明',
  COMMONS_ADVANCE_RECEIPT_DATE:'提前收货日期',
  COMMONS_TO_BE_REVIEWED:'待审核',
  COMMONS_ETD_DATE_CANNOT_BLANK:'ETD日期 不能为空',
  COMMONS_FAILURE_TO_COMPLY_WITH_COMMITMENTS:'未按承诺',
  COMMONS_RECEIPT_CONFIRMATION:'收货确认',
  COMMONS_REASONS_CONFIRMATION_AND_REJECTION:'确认/驳回原因',
  COMMONS_REJECTED_SUCCESSFULLY:'驳回成功',
  COMMONS_IS_REJECTED:'确认驳回吗',
  COMMONS_IS_ABANDONMENT:'确认废弃吗',
  COMMONS_IS_REVIEW:'确认复核吗',
  COMMONS_IS_APPROVAL:'确认审批吗',
  COMMONS_ORDER_STATUS:'订单状态',
  COMMONS_DELIVERY_STATUS:'交期状态',
  COMMONS_ETD_DATE:'ETD日期',
  COMMONS_REPLY_TO_ETD:'回复ETD',
  COMMONS_SO_NO:'SO号',
  COMMONS_REARRANGE_ETD:'重排ETD',
  COMMONS_ON_THE_WAY:'在途',
  COMMONS_CANCELLED:'已取消',
  COMMONS_DELIVERED:'已送货',
  COMMONS_SELECT_OR_ENTER_THE_PURCHASE_ORDER_TO_PRINTED:'请选择或输入需要打印的采购订单',
  COMMONS_ONLY_ONE_DATA_CAN_SELECTED_FOR_PRINTING:'只能选择一条数据打印',
  COMMONS_ORDER:'订单',
  COMMONS_APPROVAL_REJECTED_SUCCESSFULLY:'审核驳回成功',
  COMMONS_SUCCESSFULLY_APPROVED:'审核通过成功',
  COMMONS_RECEIPT_COMPLETED_STATUS_CANNOT_DELETED:'收货完成状态,不允许删除',
  COMMONS_GENERATION_IN_PROGRESS_DELETION_ALLOWED:'生成中成状态,不允许删除',
  COMMONS_RECEIPT_COMPLETED_STATUS_TABLE_DATA_CANNOT_MODIFIED:'收货完成状态,不允许修改表格数据',
  COMMONS_SELECT_OR_ENTER_DELIVERY_ORDER_NUMBER_TO_PRINTED:'请选择或输入需要打印的送货单号',
  COMMONS_ONLY_ONE_DELIVERY_NOTE_CAN_SELECTED_FOR_PRINTING:'只能选择一张送货单进行打印',
  COMMONS_SELECT_A_DELIVERY_NOTE:'请选择一个送货单',
  COMMONS_ONLY_ONE_DELIVERY_ORDER_CAN_BE_SELECTED:'只能选择一个送货单',
  COMMONS_OUTER_BOX_LABEL:'外箱标贴',
  COMMONS_PO_DATE:'PO日期',
  COMMONS_AUDIT_TYPE:'审核类型',
  COMMONS_REARRANGE_AUDIT:'重排审核',
  COMMONS_ORDER_REVIEW:'订单审核',
  COMMONS_SELECT_AT_LEAST_ONE_ORDER:'请至少选择一个订单',
  COMMONS_APPROVAL_TYPE_IS_REARRANGEMENT_APPROVAL_REARRANGEMENT_ETD_REQUIRED:'审核类型为重排审核,重排ETD为必填',
  COMMONS_APPROVAL_TYPE_IS_ORDER_APPROVAL_ETDDATE_REQUIRED:'审核类型为订单审核,etdDate为必填',
  COMMONS_OPERATION_SUCCEEDED:'操作成功',
  COMMONS_ORIGINAL_REPLY_DELIVERY_DATE:'原回复交期',
  COMMONS_DELAYED_DELIVERY:'延迟后交期',
  COMMONS_DEFERRED_DESCRIPTION:'推迟说明',
  COMMONS_BPO_NO:'BPO编号',
  COMMONS_BPO_LN:'BPO项次',
  COMMONS_NON_ORDER_DELIVERY:'不按单交付',
  COMMONS_CREATION_DATE:'创建日期',
  COMMONS_DELIVERY_TO_ORDER:'按单交付',
  COMMONS_PROCUREMENT_GROUP:'采购组',
  COMMONS_MATERIAL_DOCUMENT:'物料凭证',
  COMMONS_ITEMS_IN_THE_MATERIAL_DOCUMENT:'物料凭证中的项目',
  COMMONS_MATERIAL_VOUCHER_YEAR:'物料凭证年度',
  COMMONS_DATE_OF_RECEIPT:'收货日期',
  COMMONS_RECEIPT_AMOUNT:'收货金额',
  COMMONS_RECONCILIATION_QUANTITY:'可对账数量',
  COMMONS_RECONCILIATION_AMOUNT:'对账金额',
  COMMONS_MATERIAL_ORDER_NO:'叫料单号',
  COMMONS_ESTIMATION_STATUS: '暂估状态',
  COMMONS_INITIAL:'初始',
  COMMONS_STATEMENT_GENERATED:'已生成对账单',
  COMMONS_CLEARED:'已清理',
  COMMONS_TO_BE_INVOICED:'待开票',
  COMMONS_CONFIRM_INVOICING:'确认开票',
  COMMONS_TO_BE_PREFABRICATED:'待预制',
  COMMONS_PREFABRICATED:'已预制',
  COMMONS_PENDING_PAYMENT:'待付款',
  COMMONS_CLEARED_ACCOUNT:'已清账',
  COMMONS_CUSTOMS_DECLARATION_CONTRACT:'报关合同',
  COMMONS_ADD_CUSTOMS_DECLARATION_CONTRACT_SUCCESS:'添加报关合同成功',
  COMMONS_SPLIT_RECONCILIATION:'拆分对账',
  COMMONS_UNDIVIDED_RECONCILIATION:'未拆分对账',
  COMMONS_HAS:'有',
  COMMONS_IS_HAS_REMARK:'有',
  COMMONS_PURCHASE_PAYMENT_INFORMATION_LIST:'采购付款信息列表',
  COMMONS_PURCHASE_PAYMENT_INFORMATION_DETAILS:'采购付款信息详情',
  COMMONS_TOTAL_ITEMS:'总项次',
  COMMONS_TOTAL_QUANTITY_RECEIVED:'收货总数量',
  COMMONS_AMOUNT_RECEIVED_WITHOUT_TAX:'收货未税金额',
  COMMONS_TAX_INCLUDED_AMOUNT_GOODS_RECEIVED:'收货含税金额',
  COMMONS_TOTAL_RECONCILIATION_QUANTITY:'对账总数量',
  COMMONS_RECONCILED_AMOUNT_BEFORE_TAX:'对账未税金额',
  COMMONS_RECONCILIATION_AMOUNT_INCLUDING_TAX:'对账含税金额',
  COMMONS_MAKE_BATCH_INVOICING_FOR_EVERY_400_ITEMS:'您确定要把当前查询条件下的所有数据进行每400项,批量开票吗?确定页面上修改的数据都将被保存',
  COMMONS_MAKE_BATCH_INVOICING_FOR_EVERY_200_ITEMS:'您确定要把当前查询条件下的所有数据进行每200项,批量开票吗?确定页面上修改的数据都将被保存',
  COMMONS_ORDER_NO:'订单编号',
  COMMONS_URGENT_MATERIAL_FLAG:'急料标识',
  COMMONS_PROTECTIVE_SIGNS:'防护标识',
  COMMONS_INSPECTION_TYPE:'检查类型',
  COMMONS_SRM_BATCH_NUMBER:'SRM批号',
  COMMONS_IS_IT_URGENT:'是否急料',
  COMMONS_GENERATION_IN_PROGRESS_DELETION_NOT_ALLOWED:'生成中成状态,不允许删除',
  COMMONS_RECEIPT_COMPLETED_STATUS_CANNOT_BE_DELETED:'收货完成状态,不允许删除',
  COMMONS_IS_COMPLETED_NOT_EDIT:'收货完成状态,不允许修改表格数据',
  COMMONS_RECEIPT_QUANTITY_MUST_BE_GREATER_THAN_ZERO:'收货数量必须大于0',
  COMMONS_REPLY_TIME:'回复时间',
  COMMONS_SUPPLIER_REPLY_DELIVERY_DATE:'供应商回复交期',
  COMMONS_PLANNED_REPLY_DELIVERY_DATE:'计划回复交期',
  COMMONS_PURCHASE_CONFIRMATION_DELIVERY_DATE:'采购确认交期',
  COMMONS_DELIVER_GOODS:'发货',
  COMMONS_OPERATOR:'操作人员',
  COMMONS_OPERATORS:'操作人',
  COMMONS_CONTENT:'内容',
  COMMONS_EVENT:'事件',
  COMMONS_SUBMIT_FOR_CONFIRMATION:'提交确认',
  /*以下发了，没有更新的，2022-3-11发的*/
  COMMONS_SELECT_OR_ENTER_DELIVERY_ORDER_NUMBER_EXPORT:'请选择或输入需要导出的送货单号',
  COMMONS_PO_NO:'PO编号',
  COMMONS_MODEL:'型号',
  COMMONS_ACTUAL_ARRIVAL_DATE:'实际到货日期',
  COMMONS_ACTUAL_ARRIVAL_DATE_NOT_EMPTY:'实际到货日期不能为空',
  COMMONS_WORK_ORDER_NO:'工单号',
  COMMONS_PART_NAME:'零件名称',
  COMMONS_QUANTITY_TO_BE_DELIVERED:'应送数量',
  COMMONS_DEMAND_DATE:'需求日期',
  COMMONS_MATERIAL_ISSUANCE_OF_THIS_WORK_ORDER:'本次工单发料',
  COMMONS_CALL_QUANTITY:'叫料数量',
  COMMONS_CALL_DATE:'叫料日期',
  COMMONS_CALL_TIME:'叫料时间',
  COMMONS_MATERIAL_DEPARTMENT:'叫料部门',
  COMMONS_CALLER:'叫料人',
  COMMONS_MATERIAL_UNIT:'物料单位',
  COMMONS_DELIVERY_FLOOR:'交货楼层',
  COMMONS_DEMAND_TIME:'需求时间',
  COMMONS_ENTER_MATERIAL_CODE_SEPARATED_BY_SEMICOLONS_English:'输入物料编码,用分号(英文)隔开',
  COMMONS_ULTRASONIC_PRODUCT_PRODUCTION_DEPARTMENT:'超声产品生产部',
  COMMONS_BLOOD_CELL_PRODUCT_PRODUCTION_DEPARTMENT:'血球产品生产部',
  COMMONS_BIOCHEMICAL_PRODUCTS_PRODUCTION_DEPARTMENT:'生化产品生产部',
  COMMONS_ANESTHESIA_RESPIRATORY_PRODUCTS_PRODUCTION_DEPARTMENT:'麻醉呼吸产品生产部',
  COMMONS_ANESTHESIA_MODULE_PRODUCTION_DEPARTMENT:'麻醉模块生产部',
  COMMONS_PRODUCT_PRODUCTION_DEPARTMENT:'监护产品生产部',
  COMMONS_DISTRIBUTION_DEPARTMENT:'配送部',
  COMMONS_RADIOGRAPHIC_PRODUCT_PRODUCTION_DEPARTMENT:'放射影像产品生产部',
  COMMONS_BIOCHEMICAL_REAGENT_FACTORY:'生化试剂厂',
  COMMONS_BLOOD_CELL_REAGENT_FACTORY:'血球试剂厂',
  COMMONS_IMMUNE_REAGENT_FACTORY:'免疫试剂厂',
  COMMONS_ACTUAL_DELIVERY_QUANTITY:'实际送货数量',
  COMMONS_IQC_STATION_ACCOUNT_NUMBER:'IQC台账号',
  COMMONS_MACHINING_PRODUCTION_DEPARTMENT:'机加生产部',
  COMMONS_RADIOGRAPHIC_PRODUCTION_DEPARTMENT:'放射影像生产部',
  COMMONS_MONITORING_AND_CONSUMABLES_PRODUCTION_DEPARTMENT:'免疫产品生产部',
  COMMONS_SPARE_PARTS_MANAGEMENT:'备件管理',
  COMMONS_SAFETY_STOCK:'安全库存',
  COMMONS_WORK_ORDER_DEMAND_IN_RECENT_5_DAYS:'工单近5天需求',
  COMMONS_PREVIOUS_DEMAND:'以前需求',
  COMMONS_UNRESTRICTED_INVENTORY:'非限制库存',
  COMMONS_QUALITY_INSPECTION_INVENTORY:'质检库存',
  COMMONS_AGGREGATE_DEMAND:'总需求',
  COMMONS_PLANNED_ORDER_DEMAND_IN_THE_NEXT_90_DAYS:'计划订单未来90天需求',
  COMMONS_PO_ITEM:'PO项次',
  COMMONS_OCCUPIED_QUANTITY:'占用数量',
  COMMONS_STATE:'状态',
  COMMONS_PRINTED:'已打印',
  COMMONS_UNRECEIVED_GOODS:'未收货',
  COMMONS_RECEIVED:'已收货',
  COMMONS_PRINT_MATERIAL_ISSUANCE:'打印发料单',
  COMMONS_PRINT_RECEIPT:'打印收货单',
  COMMONS_UNDELIVERED:'未到货',
  COMMONS_CONFIRM_RECEIPT:'确认收货',
  COMMONS_REJECT_RECEIPTS:'拒绝收货',
  COMMONS_DEPARTMENT:'部门',
  COMMONS_MATERIAL_STORAGE:'物料储位',
  COMMONS_PO_DELIVERY_DATE:'PO交期',
  COMMONS_UNOCCUPIED_QUANTITY:'未占用数量',
  COMMONS_DEMAND_QUANTITY:'需求数量',
  COMMONS_DELIVERY_ADDRESS:'交货地址',
  COMMONS_ULTRASONIC:'超声',
  COMMONS_CUSTODY:'监护',
  COMMONS_ANAESTHESIA:'麻醉',
  COMMONS_BIOCHEMISTRY:'生化',
  COMMONS_INFUSION_PUMP:'输注泵',
  COMMONS_BLOOD_CELL:'血球',
  COMMONS_X_RAY:'X-射线',
  COMMONS_BLOOD_CELL_REAGENT:'血球试剂',
  COMMONS_GROUP_CODE:'组别代码',
  COMMONS_ACTUAL_CALL_QUANTITY:'实际叫料数量',
  COMMONS_INVENTORY_QUANTITY:'库存数量',
  COMMONS_10_PIECES:'10件',
  COMMONS_CALL_DATE_OR_TIME:'叫料日期/时间',
  COMMONS_NUMBER_2:'汇总编号',
  COMMONS_SOURCE:'来源',
  COMMONS_MATCHING_QUANTITY:'匹配数量',
  COMMONS_MATCH_COMPLETE:'匹配完成',
  COMMONS_UNMATCHED_COMPLETION:'未匹配完成',
  COMMONS_MATCH_PO:'是否匹配PO',
  COMMONS_STOCK:'库存',
  COMMONS_STOCK_MES_LIST:'库存信息报表',
  COMMONS_DELIVERY_MES_LIST:'库存信息报表',
  COMMONS_DELIVERY_MES_LIST_AGAIN:'库存快照报表',
  COMMONS_DELIVERY_DETAILS_REPORT:'交货明细报表',
  COMMONS_INVENTORY_ADDRESS:'库存地址',
  COMMONS_ABNORMAL_INFORMATION:'异常信息',
  COMMONS_UNMATCHED_QUANTITY:'未匹配数量',
  COMMONS_GENERATED_QUANTITY:'生成数量',
  COMMONS_ORDER_LN:'订单项次',
  COMMONS_ORDER_OPEN_QUANTITY:'订单未清数量',
  COMMONS_HAS_MATCHED_QUANTITY:'已匹配数量',
  COMMONS_MAY_MATCHING_QUANTITY:'可匹配数量',
  COMMONS_MANUAL_DEMAND_MATCHING:'手动需求匹配',
  COMMONS_SUBMIT_MATCH:'提交匹配',
  COMMONS_MANUALLY_ADD_REQUIREMENTS:'手动添加需求',
  COMMONS_DEMAND_INFORMATION:'需求信息',
  COMMONS_DEMAND_CONFIRMATION_INFORMATION:'需求确认信息',
  COMMONS_CONTINUE_MATCHING_REQUIREMENTS:'继续匹配需求',
  COMMONS_DELETE_SELECTED_REQUIREMENTS:'删除选择的需求',
  COMMONS_SUPPLIER_CODE_CANNOT_BE_BLANK:'供应商编码不能空',
  COMMONS_DEMAND_QUANTITY_CANNOT_BE_BLANK:'需求数量不能为空',
  COMMONS_DEMAND_SUMMARY:'需求汇总',
  COMMONS_SURE_WANT_TO_CONFIRM_RECEIPT:'确定要收货确认吗?',
  COMMONS_CALL_SUMMARY:'叫料汇总',
  COMMONS_RECEIPT_CONFIRMATION_SUCCEEDED:'收货确认成功',
  COMMONS_ACTUAL_DELIVERY_QUANTITY_MUST_GREATER_THAN_ZERO:'实际送货数量必须大于0',
  COMMONS_SURE_YOU_WANT_TO_SUBMIT:'确定要提交吗?',
  COMMONS_SURE_YOU_WANT_TO_DEL:'确定删除吗?',
  COMMONS_CONTRACT_QUANTITY:'合同数量',
  COMMONS_LIST:'列表',
  COMMONS_MATERIAL_REQUIREMENTS:'物料需求',
  COMMONS_SURE_YOU_WANT_TO_ADD_REQUIREMENTS:'确定要添加需求吗',
  COMMONS_MATCHING_QUANTITY_MUST_BE_GREATER_THAN_ZERO:'匹配数量必须大于0',
  COMMONS_MATCH_SUCCESSFUL:'匹配成功',
  COMMONS_DELIVERY_QUANTITY_MUST_BE_GREATER_THAN_0:'送货数量必须大于0',
  COMMONS_EXPORT_SERIAL_NUMBER:'导出序列号',
  COMMONS_DELIVERY_NOTE_NO:'送货单编号',
  COMMONS_UNOCCUPIED:'未占用',
  COMMONS_SAVE_DELIVERY_NOTE:'保存送货单',
  COMMONS_SAVE:'保存',
  COMMONS_CANCEL_DELIVERY_NOTE:'取消送货单',
  COMMONS_BY_DELIVERY_NOTE_NO_CANCEL_DELIVERY_NOTE:'按送货单号取消送货单',
  COMMONS_RECEIPT:'收货单',
  COMMONS_SURE_WANT_TO_SAVE_THE_DELIVERY_NOTE:'确定要保存送货单吗?',
  COMMONS_DETAILS_ARE_EMPTY_AND_NOT_SUBMIT:'包装明细都为空,没有可提交的数据',
  COMMONS_AFTER_CONFIRM_IN_DELIVERY_GENERATE_DELIVERY:'有效期不足T1,请采购确认后,再在打印送货单中生成送货单',
  COMMONS_SURE_TO_CLEAR_SUBMITTED_RECEIPT:'确定要清除提交收货吗',
  COMMONS_SELECT_SAME_DELIVERY_NO_DATA:'只能勾选同一个送货单号的数据',
  COMMONS_SURE_TO_CANCEL_DELIVERY_NO:'确定要取消送货单吗',
  COMMONS_DELIVERY_NOTE_CANCELLED_SUCCESSFULLY:'取消送货单成功',
  COMMONS_ADVANCE_DELIVERY_RECORD:'提前送货记录表',
  COMMONS_DELIVERY_QUANTITY:'交货数量',
  COMMONS_CANCEL_BY_DELIVERY_ORDER_NUMBER:'按送货单号取消',
  COMMONS_MINI_MUM:'最小数量',
  COMMONS_MAX_MUM:'最大数量',
  COMMONS_AVAILABLE_QUANTITY:'可供数量',
  COMMONS_RECEIVED_CONFIRMED_QUANTITY:'已收货确认数量',
  COMMONS_UNDELIVERED_QUANTITY:'未交货数量',
  COMMONS_QUANTITY_OF_DELIVERY_NOTES_GENERATED:'已生成送货单数量',
  COMMONS_QUANTITY_IN_TRANSIT:'在途数量',
  COMMONS_AUDIT_STATUS:'审核状态',
  COMMONS_REPLY_STATUS:'回复状态',
  COMMONS_PASSED:'已通过',
  COMMONS_REJECTED:'已驳回',
  COMMONS_NOT_PASSED:'未通过',
  COMMONS_REPLIED:'已回复',
  COMMONS_NO_REPLY:'未回复',
  COMMONS_OPENING_DEMAND:'期初需求',
  COMMONS_CHARACTERISTIC_VALUE:'特性值',
  COMMONS_MATERIAL_SHORTAGE_IN_30_DAYS:'30天缺料数',
  COMMONS_NUMBER_OF_REPLIES:'回复数量',
  COMMONS_AVAILABLE_DIFFERENCES:'可供差异',
  COMMONS_SURE_TO_CONTINUE_MATCHING_REQUIREMENTS:'确定要继续匹配需求吗',
  COMMONS_WANT_TO_SUBMIT_MATCH:'确定要提交匹配吗',
  COMMONS_SUPPLIER_RESPONSE:'供应商回复',
  COMMONS_KEY_MATERIALS_FCST_AVAILABLE_FOR_REPLY:'关键料FCST可供回复',
  COMMONS_PURCHASE_REVIEW:'采购审核',
  COMMONS_MC_REVIEW:'MC审核',
  COMMONS_PROVISIONAL_ESTIMATE:'暂估',
  COMMONS_COMPREHENSIVE_STATEMENT:'综合报表',
  // COMMONS_MC_DELETE:'MC删除',
  COMMONS_EXPORT_REPORT:'导出报表',
  COMMONS_PULL_DEMAND_SCHEDULING_AVAILABLE_FOR_REPLY:'拉料需求排程可供回复',
  COMMONS_FLUCTUATION_QUANTITY:'波动数量',
  COMMONS_SAP_BATCH:'SAP批次',
  COMMONS_SHELF_LIFE_EXPIRATION_DATE:'货架寿命到期日',
  COMMONS_CONFIRM_ORDER:'确认订单',
  COMMONS_MVT:'MVT',
  COMMONS_MC:'MC',
  COMMONS_SUPPLIER_DELIVERY_NOTE_MR:'供应商送货单(MR)',
  COMMONS_CANCEL_CONFIRMATION:'取消确认',
  COMMONS_DISAGREE:'不同意',
  COMMONS_AGREE:'同意',
  COMMONS_CUSTOMS_DECLARATION_UNIT:'报关单位',
  COMMONS_CUSTOMS_DECLARATION_QUANTITY:'报关数量',
  COMMONS_EXPORT_PULL_DEMAND_SCHEDULING:'导出拉料需求排程',
  COMMONS_IMPORT_PULL_DEMAND_SCHEDULING:'导入拉料需求排程',
  COMMONS_HONG_KONG_EXPORT_DELIVERY_NOTE:'香港导出送货单',
  COMMONS_PULL_DEMAND_SCHEDULING:'拉料需求排程',
  COMMONS_PULL_DEMAND_SCHEDULING_DELIVERY_DOC:'拉料需求排程送货单',
  COMMONS_PULL_DEMAND_SCHEDULING_REVIEW:'拉料需求排程审核',
  COMMONS_NOT_PASS:'不通过',
  COMMONS_NOT_PASS_SUCCESSFULLY:'不通过提交成功',
  COMMONS_PASS_SUCCESSFULLY:'通过提交成功',
  COMMONS_WO_PULL_HISTORY_DELIVERY_DOC:'工单拉料历史送货单',
  // COMMONS_ENTER_AT_LEAST_ONE_ITEM_OF_MATERIAL_CODE_AND_DELIVERY_ORDER_NUMBER:'物料编码和送货单号请至少输入一项',
  COMMONS_PULL_AND_SCHEDULE_DELIVERY_NOTE_ON_DEMAND:'按需拉料排程送货单',
  COMMONS_DELETED:'已删除',
  COMMONS_PULL_DEMAND_SCHEDULING_REPORT:'拉料需求排程报表',
  COMMONS_CLEAR_LIST:'清空列表',
  COMMONS_ENTER_COMPLETE_DELIVERY_ORDER_NUMBER:'请输入完整的送货单号打印',
  COMMONS_UNLOCK:'解锁',
  COMMONS_UNLOCK_SUCCEEDED:'解锁成功',
  // COMMONS_CHECK_A_DELIVERY_ORDER_NUMBER:'请勾选一个送货单号',
  COMMONS_MATERIAL_ISSUANCE:'发料单',
  COMMONS_MATERIAL_NAME:'物料名称',
  COMMONS_SUBCLASS:'小类',
  COMMONS_MIDDLE_CLASS:'中类',
  COMMONS_BIG_CLASS:'大类',
  COMMONS_CATEGORY_NAME:'品类',
  COMMONS_PRODUCT_MANAGER:'产品经理',
  COMMONS_BUSINESS_AFFAIRS:'商务',
  COMMONS_MINING_AND_FOOTWEAR_GROUP:'采履组别',
  COMMONS_PURCHASE_NO:'采购编号',
  COMMONS_ONLY_ONE_DELIVERY_ORDER_NUMBER_CAN_BE_CHECKED:'只能勾选同一个送货单号',
  COMMONS_ADD_CATEGORY:'添加分类',
  COMMONS_MODIFY_CLASSIFICATION:'修改分类',
  COMMONS_SURE_TO_DELETE_THE_CLASSIFICATION:'确定要删除分类?',
  COMMONS_CATEGORY_DELETED_SUCCESSFULLY:'删除分类成功',
  COMMONS_TOP_CLASS:'上品类',
  COMMONS_UPLOAD_CATEGORY:'上传上品类',
  COMMONS_SURE_UPLOAD_REPLY_DELIVERY_FORM:'确认要上传回复交期表吗',
  COMMONS_SURE_SUBMIT_BATCH_REPLY_DELIVERY_DATE:'确定要提交分批回复交期吗?',
  COMMONS_REPLY_IN_BATCHES:'分批回复',
  COMMONS_SUPPLIER_MODIFIED_SUCCESSFULLY:'修改供应商成功',
  COMMONS_SURE_WANT_TO_MODIFY_SUPPLIER:'确定要修改供应商吗',
  COMMONS_ENTER_A_DELIVERY_ORDER_NUMBER:'请输入一个送货单号',
  COMMONS_ENTER_OR_SELECT_DELIVERY_ORDER:'请输入送货单号或者勾选送货单',
  COMMONS_JIT_WORK_ORDER_FORECAST_LIST:'JIT工单预测列表',
  COMMONS_DEMAND_DATE_CANNOT_BE_BLANK:'需求日期不能为空',
  COMMONS_MATERIAL_CATEGORY:'物料品类',
  COMMONS_MATERIAL_BIG_CATEGORY:'物料大类',
  COMMONS_MATERIAL_MIDDLE_CATEGORY:'物料中类',
  COMMONS_SPECIFICATION_AND_MODEL:'规格型号',
  COMMONS_QUOTA_INVENTORY:'配额库存',
  COMMONS_QUALITY_INSPECTION_QUANTITY:'质检数量',
  COMMONS_VMI_INVENTORY:'VMI库存',
  COMMONS_ASN_QUANTITY:'ASN数量',
  COMMONS_SUPPLIER_FINISHED_PRODUCT_INVENTORY:'供应商成品库存',
  COMMONS_SEMI_FINISHED_PRODUCT_INVENTORY:'半成品库存',
  COMMONS_DEMAND_IN_MONTH_N:'N月需求',
  COMMONS_N_1_MONTHLY_DEMAND:'N+1月需求',
  COMMONS_N_2_MONTHLY_DEMAND:'N+2月需求',
  COMMONS_N_3_MONTHLY_DEMAND:'N+3月需求',
  COMMONS_MONTHLY_DOSAGE:'月用量',
  COMMONS_REQUIRED_STOCK_CYCLE:'要求备货周期',
  COMMONS_TOTAL_INVENTORY_FACTOR:'总库存系数',
  COMMONS_TOTAL_INVENTORY_LEVEL:'总库存水位',
  COMMONS_SRM_INVENTORY_FACTOR:'srm库存系数',
  COMMONS_SRM_INVENTORY_LEVEL:'srm库存水位',
  COMMONS_SUPPLIER_INVENTORY_FACTOR:'供应商库存系数',
  COMMONS_SUPPLIER_INVENTORY_LEVEL:'供应商库存水位',
  COMMONS_FULFILLMENT_RATE_OF_ITM_GOODS_PREPARATIONL:'ITM备货达成率',
  COMMONS_FULFILLMENT_RATE_OF_GOODS_PREPARATION:'备货达成率',
  COMMONS_STOCK_HEALTH:'备货健康度',
  COMMONS_G_S_CLASSIFICATION:'G/S分类',
  COMMONS_DO_NOT_PUBLISH_FCST:'不发布FCST',
  COMMONS_MRPC_CONTROLLER:'MRPC控制者',
  COMMONS_COMPREHENSIVEZ_REPORT_OF_GOODS_PREPARATION:'备货综合报表',
  COMMON_SERIAL_BEAN_NUMBER:'序列号(多个以,分开)',
  COMMON_IMPORT_REPORT:'导入报表',
  COMMON_REPORT_NAME:'报表名称',
  COMMON_SURE_TO_UPLOAD_REPORT:'确定要上传报表吗?',
  COMMON_SELECT_OR_INPUT_DELIVERY_ORDER:'请选择或输入需要打印的送货单号',
  COMMON_CHANGE_COMPANY:'切换公司',
  COMMON_NAN_JIN_MAI_RUI:'南京迈瑞',
  COMMON_NAN_JIN_SW_RUI:'南京生物',
  COMMON_MAI_RUI:'深圳迈瑞',
  COMMON_CANCEL_BUYER_CONFIRM:'取消采购确认',
  COMMON_EWMS_FLAG:'EWMS标识',
  COMMON_GSP_MATERIAL:'GSP物料',
  COMMON_TI_DELIVERY_NUMBER:'TI送货单号',
  COMMON_PO_ORDER:'PO订单',
  COMMON_BPO_ORDER:'BPO订单',
  COMMON_PREPAYMENT_TYPE:'预付类型',
  COMMON_ERP_PRICE_DIFFERENCE_REPORT_LIST:'ERP价差报表清单',
  COMMON_PROFORMA_INVOICE_NO:'形式发票号',
  COMMON_GROSS_WEIGHT_UNIT:'毛重单位',
  COMMON_COUNTRY_OF_ORIGIN:'报送原产国',
  COMMON_CORRESPONDING_SUBMITTED_QUANTITY:'对应报送数量',
  COMMON_DELIVERY_NOTE_CREATION_DATE:'送货单创建日期',
  COMMON_ORDER_DATE:'订单下单日期',
  COMMON_ACTUAL_DELIVERY_DATE:'实际发货日期',
  COMMON_ESTIMATED_ARRIVAL_DATE:'预计到货日期',
  COMMON_SUBMIT_BRAND:'报送品牌',
  COMMON_REPORT_TO_MANUFACTURER:'报送生产商',
  COMMON_COUNTRY_OF_SHIPMENT_REGION:'启运国(地区)',
  COMMON_ERROR_CODE:'错误代码',
  COMMON_UPDATE_PERSON:'更新人',
  COMMON_UPDATE_DATE:'更新日期',
  COMMON_COURIER:'快递商',
  COMMON_FILE_NAME:'文件名',
  COMMON_INVOICE_NO:'发票号',
  COMMON_ITEM:'项次',
  COMMON_VOUCHER_CODE:'凭证编码',
  /*以下是未发的*/
  COMMON_STATUS_CODE:'状态编码',
  COMMON_PURCHASE_CANCELLATION_CONFIRMED_SUCCESSFULLY:'消取采购确认成功',
  COMMON_MONITORING_CONSUMABLES_PRODUCTION_DEPARTMENT:'监护耗材生产部',
  COMMON_GENERATE_EXCEPTION:'生成异常',
  COMMON_IN_TRANSIT_STATUS:'在途状态',
  COMMON_SHEKOU_SELF_LIFTING:'蛇口自提',
  COMMON_HONG_KONG_WAREHOUSE_IN_TRANSIT:'香港仓在途',
  COMMON_SUPPLIER_IN_TRANSIT:'供应商在途',
  COMMON_HIGH_RISK:'是否高风险',
  COMMON_MATERIAL_AND_SUPPLIER_MODEL_TEMPLATE:'物料与供应商型号模版',
  COMMON_UPLOAD_MATERIAL_AND_SUPPLIER_MODEL:'上传物料与供应商型号',
  COMMON_MATERIAL_AND_SUPPLIER_MODEL:'物料与供应商型号',
  COMMON_PRICE:'价格',
  COMMON_PRICE_DISCOUNT:'价格折扣',
  COMMON_LAST_UPDATE_TIME:'最后更新时间',
  COMMON_MINIMUM_NUMBER_PURCHASES:'最小购买数',
  COMMON_PURCHASE_ADDRESS:'购买地址',
  COMMON_REARRANGE_ERROR_MESSAGE:'重排错误信息',
  COMMON_EXPORT_TEMPLATE:'导出模版',
  COMMON_DELIVERY_METHOD:'交付方式',
  COMMON_TIME_TYPE:'时间类型',
  COMMON_BY_WEEK:'按周',
  COMMON_BY_MONTH:'按月',
  COMMON_CLASSIFICATION:'分类',
  COMMON_PO_QUANTITY_IN_PROCESS:'在制PO数量',
  COMMON_PO_QUANTITY_ON_PASSAGE:'PO在途数量',
  COMMON_OFFICIAL_INVENTORY:'官方库存',
  COMMON_QUALITY_INSPECTION_INVENTORY_QUANTITY:'质检库存数量',
  COMMON_DECLARE_CUSTOMS:'报关',
  COMMON_NEEDS_BEFORE_TODAY:'今天以前的需求',
  COMMON_INVENTORY_IDENTIFICATION:'库存标识',
  COMMON_HAS_INVENTORY:'有库存',
  COMMON_NO_INVENTORY:'无库存',
  COMMON_IS_THERE_INVENTORY:'是否有库存',
  COMMON_WAREHOUSE_SUPPLIER_INVENTORY:'库应商库存',
  COMMON_NO_RISK_MATERIAL:'否风险物料',
  COMMON_DEMAND:'需求',
  COMMON_DEMAND_STATUS:'需求状态',
  COMMON_NET_DEMAND:'净需求',
  COMMON_MPQ:'MPQ',
  COMMON_REPLENISHMENT_INTERVAL:'补货区间',
  COMMON_NOT_IN_THE_INTERVAL:'不在区间',
  COMMON_IN_INTERVAL:'在区间',
  COMMON_PROOF_OF_DELIVERY:'交货凭证',
  COMMON_REVISION_NO:'修改版本号',
  COMMON_NUMBER_OF_REPLY_DELIVERY_DATES:'回复交期数',
  COMMON_MANUAL_SYNCHRONIZATION:'手工同步',
  COMMON_SUPPLY_AND_DEMAND_MATCHING_REPORT:'供需匹配报表',
  COMMON_PROOFING_TYPE:'打样类型',
  COMMON_INITIATE_ESTIMATION_CLEANUP: '发起暂估清理',
  COMMON_RECONCILIATION_DATE:'对账日',
  COMMON_HARVEST_QUANTITY:'收获数量',
  COMMON_UNTAXED_HARVEST_AMOUNT:'未税收获金额',
  COMMON_TAX_INCLUDED_HARVEST_AMOUNT:'含税收获金额',
  COMMON_MATERIAL_SUB_CATEGORY:'物料小类',
  COMMON_SUPPLIER_SELECTION_NO:'供应商选择单号',
  COMMON_RECONCILIATION_NO:'对账单号',
  COMMON_CLEANING_LIST_NO:'清理单号',
  COMMON_QUANTITY_TO_BE_WRITTEN_OFF:'待核销数量',
  COMMON_ESTIMATED_LIQUIDATION_DOC_NO:'暂估清理单号',
  COMMON_PRICE_DIFFERENCE_APPLICATION_NO:'价差申请单号',
  COMMON_PRICE_DIFFERENCE_APPLICATION:'价差申请',
  COMMON_ADVANCE_PAYMENT_NO:'预付款单号',
  COMMON_RECONCILIATION_PRICE:'对账价格',
  COMMON_PURCHASE_ORDER_ITEM:'采购订单项目',
  COMMON_QUALITY_INSPECTION_STATUS:'质检状态',
  COMMONS_ACCOUNTING_PERIOD:'账期',
  COMMONS_COLUMN_DISPLAY_HIDE:'列显隐',
  COMMONS_HARVEST_DATE:'收获日期',
  COMMONS_RECONCILED_QUANTITY:'已对账数量',
  COMMONS_UNRECONCILED_QUANTITY:'未对账数量',
  COMMONS_PRICE_DIFFERENCE_DOC_NO:'价差单号',
  COMMONS_PREPAYMENT_NOTE_NO:'预付款单号',
  COMMONS_ARE_THERE_ANY_COMMENTS:'是否有备注',
  COMMONS_NEW_BUILD:'新建',
  COMMONS_CAUSE_LOSSES_TO_THE_COMPANY:'造成公司损失',
  COMMONS_NO_LOSS_TO_THE_COMPANY:'未造成公司损失',
  COMMONS_NO_LOSS_OF_THE_COMPANY:'公司无损失',
  COMMONS_LOSS_OF_THE_COMPANY:'公司有损失',
  COMMONS_ESTIMATED_LIQUIDATION_DOC:'暂估清理单号',
  COMMONS_CLEANING_TYPE:'清理类型',
  COMMONS_TO_BE_CONFIRMED_BY_THE_SUPPLIER:'待供应商确认',
  COMMONS_PENDING_PURCHASE_APPROVAL:'待采购审批',
  COMMONS_PENDING_FINANCIAL_APPROVAL:'待财务审批',
  COMMONS_TOTAL_AMOUNT_ORIGINAL_CURRENCY:'合计金额-原币',
  COMMONS_TOTAL_AMOUNT_FUNCTIONAL_CURRENCY:'合计金额-本币',
  COMMONS_REASON_FOR_APPLICATION:'申请原因',
  COMMONS_APPROVAL_NO:'审批单号',
  COMMONS_APPLICATION_STATUS:'申请状态',
  COMMONS_VOUCHER_NO:'凭证号',
  COMMONS_APPLICATION_TIME:'申请时间',
  COMMONS_DIVISION_MANAGER:'部门经理',
  COMMONS_JOB_NUMBER:'工号',
  COMMONS_MINING_MANAGER:'采履经理',
  COMMONS_CLEANUP_STATUS:'清理单状态',
  COMMONS_ADD_DETAILS:'添加明细',
  COMMONS_DELETE_DETAILS:'删除明细',
  COMMONS_TEMPORARY_STORAGE:'暂存',
  COMMONS_SUBMIT_APPLICATION:'提交申请',
  COMMONS_SUPPLIER_CONFIRMATION:'供应商确认',
  COMMONS_REOLENISHMENT_INTERVAL:'补货区间',
  COMMONS_WATER_LEVEL_RANGE:'水位区间',
  COMMONS_RELEASE_DATE:'发布日期',
  COMMONS_REPLENISHMENT_DATE:'补货日期',
  COMMONS_SHIPPING_ADDRESS:'送货地址',
  COMMONS_AVAILABLE_DATE:'可供日期',
  COMMONS_NOT_MEETING_REQUIREMENTS:'不满足要求',
  COMMONS_MEET_THE_REQUIREMENTS:'满足要求',
  COMMONS_SUPPLIER_CONFIRMATION_RETURN:'驳回至供应商确认',
  COMMONS_UPLOAD_ATTACHMENTS:'上传附件',
  COMMONS_ABANDON:'废弃',
  COMMONS_DEPARTMENT_MANAGER_APPROVAL:'部门经理审批',
  COMMONS_DEPARTMENT_MANAGER_REJECTED:'部门经理驳回',
  COMMONS_APPROVAL_OF_PROCUREMENT_MANAGER:'采履经理审批',
  COMMONS_REJECTE_BY_THE_PROCUREMENT_MANAGER:'采履经理驳回',
  COMMONS_FINANCIAL_CONFIRMATION:'财务确认',
  COMMONS_FINANCIAL_REJECTION:'财务驳回',
  COMMONS_ATTACHMENT_NAME:'附件名称',
  COMMONS_UPLOAD_EVENT:'上传事件',
  COMMONS_UPLOADED_BY:'上传人',
  COMMONS_UNTAXED_HARVEST_AMOUNT:'未税收货金额',
  COMMONS_AMOUNT_OF_GOODS_INCLUDING_TAX:'含税收货金额',
  COMMONS_AMOUNT_OF_GOODS_INCLUDING_TAX_ORIGINAL_CURRENCY:'含税收货金额 - 原币',
  COMMONS_FIVE:'5号',
  COMMONS_TEN:'10号',
  COMMONS_VMI:'VMI',
  COMMONS_JIT:'JIT',
  COMMONS_PAY_IN_ADVANCE:'预付',
  COMMONS_PREPAID_WRITE_OFF_DATE:'预付核销日期',
  COMMONS_ESTIMATED_WRITE_OFF_DATE:'预计核销日期',
  COMMONS_SUPPLIER_CONFIRMED:'供应商已确认',
  COMMONS_PURCHASE_CONFIRMED:'采购已确认',
  COMMONS_NAME_OF_MANUFACTURER:'制造商名称',
  COMMONS_AMOUNT_VARIANCE:'金额差异',
  COMMONS_EXPLANATION_OF_DIFFERENCE_REASON:'差异原因说明',
  COMMONS_DRAFT:'草稿',
  COMMONS_CREATED:'已创建',
  COMMONS_GOODS_TO_BE_RECEIVED:'待收货',
  COMMONS_REJECT_RECEIPT:'已拒收',
  COMMONS_PENDING_APPROVAL:'待审批',
  COMMONS_UNDER_APPROVAL:'审批中',
  COMMONS_APPROVAL:'已审批',
  COMMONS_REASON_FOR_DIFFERENCE:'差异原因',
  COMMONS_PRICE_DIFFERENCE_AMOUNT:'价差金额',
  COMMONS_PRICE_DIFFERENCE_STATUS:'价差状态',
  COMMONS_VIEW_DETAILS:'查看详情',
  COMMONS_APPROVED_BY_THE_MANAGER_OF_THE_PROCUREMENT_DEPARTMENT:'部门经理审批',
  COMMONS_REJECTED_BY_THE_MANAGER_OF_THE_PROCUREMENT_DEPARTMENT:'部门经理驳回',
  COMMONS_TOTAL_NUM_AMOUNT_ORIGINAL_CURRENCY:'总金额-原币',
  COMMONS_TOTAL_NUM_AMOUNT_LOCAL_CURRENCY:'总金额-本币',
  COMMONS_DOCUMENT_TYPES:'单据类型',
  COMMONS_ATTACHMENT_ATTRIBUTION:'附件归属',
  COMMONS_DEDUCTION_NO:'扣款单号',
  COMMONS_DEDUCTION_AMOUNT:'扣款金额',
  COMMONS_REASON_FOR_DEDUCTION:'扣款原因',
  COMMONS_DEDUCTION_TYPE:'扣款类型',
  COMMONS_DEDUCTION_METHOD:'扣款方式',
  COMMONS_SUPPLIER_CONTACT:'供应商联系人',
  COMMONS_CONTACT_PHONE:'联系人电话',
  COMMONS_COST_CENTER:'成本中心',
  COMMONS_RESPONSIBLE_DEPARTMENT:'责任部门',
  COMMONS_PRODUCT_LINE:'产品线',
  COMMONS_DEDUCTION_FORM_STATUS:'扣款单状态',
  COMMONS_TO_BE_CONFIRMED_BY_FINANCE:'待财务确认',
  COMMONS_OBSOLETE:'已废弃',
  COMMONS_RECONCILED:'已对账',
  COMMONS_PARTIAL_WRITE_OFF:'部分核销',
  COMMONS_WRITTEN_OFF:'已核销',
  COMMONS_FINE:'罚款',
  COMMONS_LOST_TIME:'工时损失',
  COMMONS_SUPPLIER_REBATE:'供应商返利',
  COMMONS_REWORK_COST:'返工费用',
  COMMONS_JOINT_SCRAP:'连带报废',
  COMMONS_CUSTOMER_COMPLAINT_COMPENSATION:'客诉赔偿',
  COMMONS_IDENTIFICATION_FEE:'鉴定费用',
  COMMONS_FAILURE_ANALYSIS_COST:'故障分析费用',
  COMMONS_PREPAID_QUANTITY:'预付数量',
  COMMONS_ADVANCE_RATIO:'预付比例',
  COMMONS_PREPAID_AMOUNT:'预付金额',
  COMMONS_PREPAYMENT_STATUS:'预付单状态',
  COMMONS_REVIEWED:'已审核',
  COMMONS_PURCHASE_ORDER_NO:'采购订单号',
  COMMONS_STATEMENT_STATUS:'对账单状态',
  COMMONS_CURRENT_PROCESSOR:'当前处理人',
  COMMONS_NUMBER_OF_ROWS:'行数',
  COMMONS_RECONCILIATION_DATE:'对账日期',
  COMMONS_ORDER_TAX_AMOUNT:'订单含税金额',
  COMMONS_COPIES_OF_INVOICE:'发票份数',
  COMMONS_TOTAL_INVOICE_AMOUNT:'发票总金额',
  COMMONS_TOTAL_INVOICE_AMOUNT_OF_TAX:'发票含税总金额',
  COMMONS_TOTAL_INVOICE_AMOUNT_OF_NOTAX:'发票未税总金额',
  COMMONS_THE_TOTAL_AMOUNT_OF_INVOICE_TAX:'发票税额总额',
  COMMONS_CUSTOMS_DECLARATION_FREIGHT_AND_MISCELLANEOUS_CHARGES:'报关运杂费',
  COMMONS_COURIER_NUMBER:'快递单号',
  COMMONS_DIFFERENCE_BEFORE_TAX:'未税差额',
  COMMONS_TAX_DIFFERENCE:'含税差额',
  COMMONS_BASE_DATE:'基准日期',
  COMMONS_THEORETICAL_PAYMENT_DATE:'理论付款日期',
  COMMONS_INVOICE_PREFABRICATION_VOUCHER:'发票预制凭证',
  COMMONS_INVOICE_VERIFICATION_VOUCHER:'发票校验凭证',
  COMMONS_PAYMENT_REQUISITION_NO:'付款申请单号',
  COMMONS_ACTUAL_PAYMENT_DATE:'实际付款日期',
  COMMONS_RECONCILIATION_GROUP_ID:'对账组标识',
  COMMONS_FINANCIAL_CONFIRMED:'财务已确认',
  COMMONS_INVOICE_TO_BE_PREFABRICATED:'发票待预制',
  COMMONS_INVOICE_PREFABRICATED:'发票已预制',
  COMMONS_INVOICE_HAS_BEEN_SIGNED:'发票已签收',
  COMMONS_INVOICE_CONFIRMED:'发票已确认',
  COMMONS_PAYMENT_APPLICATION_TO_BE_APPROVED:'付款申请待审批',
  COMMONS_PAYMENT_APPLICATION_IS_UNDER_APPROVAL:'付款申请审批中',
  COMMONS_PAYMENT_APPLICATION_HAS_BEEN_APPROVED:'付款申请已审批',
  COMMONS_ESTIMATED_PAYMENT_DATE:'预计付款日期',
  COMMONS_Paid:'已付款',
  COMMONS_NO_USE:'已失效',
  COMMONS_MATERIAL_VOUCHER_NO:'物料凭证号',
  COMMONS_ORDER_UNIT:'订单单位',
  COMMONS_RECEIPT_UNIT_PRICE:'入库单价',
  COMMONS_SUPPLIER_SELECTION_AND_PRICING_PROCESS_NO:'入库单价的供应商选择和定价流程单号',
  COMMONS_INVOICE_UNIT_PRICE:'发票单价',
  COMMONS_SUPPLIER_SELECTION_AND_PRICING_PROCESS_NO_OF_INVOICE_UNIT_PRICE:'发票单价的供应商选择和定价流程单号',
  COMMONS_UNIT_PRICE_DIFFERENCE_BEFORE_TAX:'未税单价差异',
  COMMONS_DIFFERENCE_OF_UNTAXED_AMOUNT:'未税金额差异',
  COMMONS_TAX_AMOUNT_DIFFERENCE:'含税金额差异',
  COMMONS_CONFIRMED_BY_THE_MANAGER_OF_THE_PROCUREMENT_DEPARTMENT:'部门经理确认',
  COMMONS_PRICE_DIFFERENCE_TYPE:'价差类型',
  COMMONS_PRICE_RISE:'涨价',
  COMMONS_PRICE_REDUCTION:'降价',
  COMMONS_AMOUNT_OF_UNTAXED_PRICE_DIFFERENCE:'未税价差金额',
  COMMONS_AMOUNT_OF_PRICE_DIFFERENCE_INCLUDING_TAX:'含税价差金额',
  COMMONS_THE_TOTAL_AMOUNT_OF_THE_SPREAD_INCLUDING_TAX:'总含税价差金额',
  COMMONS_THE_TOTAL_AMOUNT_OF_THE_UNTAXED_SPREAD:'总未税价差金额',
  COMMONS_APPLICATION_FORM_STATUS:'申请单状态',
  COMMONS_REASON_FOR_PRICE_DIFFERENCE:'价差原因',
  COMMONS_AMOUNT_OF_GOODS_NOT_TAXED_ORIGINAL_CURRENCY:'未税收货金额-原币',
  COMMONS_AMOUNT_OF_GOODS_NOT_TAXED_FUNCTIONAL_CURRENCY:'未税收货金额-本币',
  COMMONS_CLEANING_TYPE_SECONDARY_CLASSIFICATION:'清理类型二级分类',
  COMMONS_ACCOUNTING_SUPERVISOR_REVIEW:'会计主管复核',
  COMMONS_ACCOUNTING_SUPERVISOR_REJECTED:'会计主管驳回',
  COMMONS_MANAGER_OF_PROCUREMENT_DEPARTMENT:'采履部门经理',
  COMMONS_INVENTORY_DEDUCTION:'存货类扣款',
  COMMONS_NON_INVENTORY_DEDUCTION:'非存货类扣款',
  COMMONS_PRINT_DEDUCTION_FORM:'打印扣款单',
  COMMONS_COST_ACCOUNTING_RECOGNITION:'成本会计确认',
  COMMONS_COST_ACCOUNTING_REJECTION:'成本会计驳回',
  COMMONS_FINANCIAL_REVIEW_AND_CONFIRMATION:'财务审核确认',
  COMMONS_NO_TAX_DEDUCTION_AMOUNT:'未税扣款金额',
  COMMONS_TAX_DEDUCTION_AMOUNT:'含税扣款金额',
  COMMONS_TAX_DEDUCTION_ALL_AMOUNT:'含税扣款总金额',
  COMMONS_EXPLANATION_OF_DEDUCTION_REASON:'扣款原因说明',
  COMMONS_AMOUNT_INCLUDING_TAX_DEDUCTED:'扣款含税金额',
  COMMONS_DEDUCTION_VOUCHER_NO:'扣款凭证号',
  COMMONS_SUPPLIER_REJECTION:'供应商驳回',
  COMMONS_SUPPLIER_TRANSFER:'供应商转账',
  COMMONS_LOAN_DEDUCTION:'货款扣除',
  COMMONS_LOSS_TYPE:'损失类型',
  COMMONS_INVENTORY_RELATED:'存货相关',
  COMMONS_NON_INVENTORY_RELATED:'非存货相关',
  COMMONS_AMOUNT_OF_DAMAGES:'损失金额',
  COMMONS_SUBJECT:'科目',
  COMMONS_PAYMENT_CONFIRMATION:'付款确认',
  COMMONS_PAYMENT_REJECTION:'付款驳回',
  COMMONS_REASON_FOR_PREPAYMENT:'预付原因',
  COMMONS_TERM_OF_PAYMENT:'付款条件',
  COMMONS_BPO:'BPO',
  COMMONS_BPO_ITEM:'BPO项次',
  COMMONS_UNPAID_QUANTITY:'未付数量',
  COMMONS_APPLIED_PREPAYMENT_QUANTITY:'申请预付数量',
  COMMONS_APPLICATION_PREPAYMENT_RATIO:'申请预付比例(%)',
  COMMONS_APPLIED_PREPAYMENT_AMOUNT:'申请预付金额',
  COMMONS_APPLIED_PREPAYMENT_AMOUNT_TAX_INCLUDED:'申请预付金额-含税',
  COMMONS_APPLIED_PREPAYMENT_AMOUNT_NOTAX_INCLUDED:'申请预付金额-未税',
  COMMONS_PREPAID_AMOUNT_OF_NON_TAX_APPLICATION:'未税申请预付金额',
  COMMONS_PREPAID_AMOUNT_OF_TAX_INCLUSIVE_APPLICATION:'含税申请预付金额',
  COMMONS_PREPAID_QUANTITY_APPLIED:'已申请预付数量',
  COMMONS_PREPAID_PROPORTION_APPLIED:'已申请预付比例',
  COMMONS_WRITTEN_OFF_QUANTITY:'已核销数量',
  COMMONS_PREPAID_VOUCHER:'预付凭证',
  COMMONS_RECONCILIATION_ACCOUNTING_CONFIRMATION:'对账会计确认',
  COMMONS_ACCOUNTING_REJECTION:'会计驳回',
  COMMONS_SUBMIT_INVOICE:'提交发票',
  COMMONS_PRINT_STATEMENT:'打印对账单',
  COMMONS_INVOICE_RECEIPT:'发票签收',
  COMMONS_INVOICE_CONFIRMATION:'发票确认',
  COMMONS_INVOICE_REJECTION:'发票驳回',
  COMMONS_FINANCIAL_REVIEW_REJECTED:'财务审核驳回',
  COMMONS_START_RECONCILIATION:'启动对账',
  COMMONS_ACCOUNTING_RECOGNITION:'会计确认',
  COMMONS_PREFABRICATED_INVOICE:'预制发票',
  COMMONS_SIGN_FOR_INVOICE:'签收发票',
  COMMONS_REVIEW_AND_CONFIRMATION:'审核确认',
  COMMONS_PAYMENT_COMPLETED:'付款完成',
  COMMONS_ESTIMATED_LINES:'暂估行数',
  COMMONS_RECONCILIATION_AMOUNT_BEFORE_TAX:'未税对账金额',
  COMMONS_RECONCILIATION_AMOUNT_INCLUDING_TAXS:'含税对账金额',
  COMMONS_REFERENCE_NUMBER:'参照号',
  COMMONS_FILL_IN_THE_EXPRESS_BILL:'填写快递单',
  COMMONS_ESTIMATION_DETAILS:'暂估详情',
  COMMONS_PRICE_DIFFERENCE_DETAILS:'价差详情',
  COMMONS_INVOICE_DETAILS:'发票详情',
  COMMONS_DETAILS_OF_CUSTOMS_DECLARATION_CONTRACT:'报关合同详情',
  COMMONS_DEDUCTION_DETAILS:'扣款详情',
  COMMONS_PREPAYMENT_DETAILS:'预付详情',
  COMMONS_ADVANCE_DELAY_FREEZE_PAYMENT_DETAILS:'提前/延迟/冻结付款详情',
  COMMONS_PARKING_DOCEMENT:'预制凭证',
  COMMONS_INITIATE_PRICE_DIFFERENCE:'发起价差',
  COMMONS_PREFABRICATED_VOUCHER_NO:'预制凭证号',
  COMMONS_PREFABRICATED_INVOICE_NO:'预制发票号',
  COMMONS_POSTING_VOUCHER_NO:'过账凭证号',
  COMMONS_ESTIMATED_UNIT_PRICE:'暂估单价',
  COMMONS_INVOICE_UNTAXED_AMOUNT:'发票未税金额',
  COMMONS_INVOICE_TAX_AMOUNT:'发票未税金额',
  COMMONS_RECEIPT_POSTING_DATE:'收货过账日期',
  COMMONS_MATERIAL_ISSUE_POSTING_DATE:'发料过账日期',
  COMMONS_EFFECTIVE_DATE:'生效日期',
  COMMONS_PRICE_VARIANCE:'价格差异',
  COMMONS_ADD_INVOICE:'添加发票',
  COMMONS_QUERY_INVOICE_INFORMATION:'查询发票信息',
  COMMONS_QUERY_SUCCESSFULLY:'查询成功',
  COMMONS_MODIFY_INVOICE:'修改发票',
  COMMONS_INVOICE_CODE_AGAIN:'发票代码',
  COMMONS_INVOICE_CODE:'添加发票',
  COMMONS_INVOICE_NO:'发票号码',
  COMMONS_UNTAXED_AMOUNT:'未税金额',
  COMMONS_UNTAXED_AMOUNT_INPUT:'未税金额(录入)',
  COMMONS_UNTAXED_AMOUNT_TAX:'未税金额(税务)',
  COMMONS_TAX_AMOUNT:'税额',
  COMMONS_INVOICE_TAX_AMOUNTS:'发票税额',
  COMMONS_TAX_AMOUNT_INPUT:'税额(录入)',
  COMMONS_TAX_AMOUNT_TAX:'税额(税务)',
  COMMONS_TAX_CLASSIFICATION_CODE:'税收分类编码',
  COMMONS_TOTAL_AMOUNT_INCLUDING_TAX:'含税总金额',
  COMMONS_TOTAL_AMOUNT_INCLUDING_TAX_INPUT:'含税总金额(录入)',
  COMMONS_TOTAL_AMOUNT_INCLUDING_TAXS:'含税总金额(税务)',
  COMMONS_INVOICE_DATE:'发票日期',
  COMMONS_BILLING_DATE:'开票日期',
  COMMONS_INVOICE_TYPE:'发票类型 ',
  COMMONS_LAST_SIX_DIGITS_OF_THE_CHECK_DIGIT:'校验码后6位',
  COMMONS_CHECKSUM:'校验码',
  COMMONS_PURCHASER_TAX_NO:'购方税号',
  COMMONS_PURCHASER_NAME:'购方名称',
  COMMONS_SALES_TAX_NO:'销方税号',
  COMMONS_SALES_NAME:'销方名称',
  COMMONS_AMOUNT_TAX:'价税合计',
  COMMONS_INVOICE_STATE:'发票状态',
  COMMONS_BILLING_STATUS:'入账状态',
  COMMONS_CHECK_TIME:'查验时间',
  COMMONS_SALES_BANK:'销方开户行账户',
  COMMONS_TOTAL_TAX_AMOUNT:'合计税额',
  COMMONS_REFINED_OIL_LOGO:'成品油标志',
  COMMONS_VEHICLE_TYPE:'车辆类型',
  COMMONS_BRAND_MODEL:'厂牌型号',
  COMMONS_TAX_PAYMENT_CERTIFICATE_NUMBER:'完税凭证号码',
  COMMONS_SUBSTITUTE_OPEN_LOGO:'代开标志',
  COMMONS_SPECIAL_POLICY_SIGN:'特殊政策标识',
  COMMONS_REAL_TAX_RATE:'实际税率',
  COMMONS_REAL_TAX_AMOUNT:'实际税额',
  COMMONS_TOLLS_SIGNS:'通行费标志',
  COMMONS_FILE_DOWNLOAD_LINK:'版式文件下载链接',
  COMMONS_OFD_DOWNLOAD_ADDRESS:'ofd文件下载地址',
  COMMONS_PDF_DOWNLOAD_ADDRESS:'pdf文件下载地址',
  COMMONS_XML_DOWNLOAD_ADDRESS:'xml文件下载地址',
  COMMONS_CARGO_DETAILS:'货物明细',
  COMMONS_INVOICE_IMAGE:'发票图像',
  COMMONS_PAYMENT_AMOUNT:'付款金额',
  COMMONS_POSTING_VOUCHER:'过账凭证',
  COMMONS_CONTRACT_AMOUNT:'合同金额',
  COMMONS_CUMULATIVE_USE:'累计使用（包含本次费用）',
  COMMONS_THIS_USE:'本次使用金额',
  COMMONS_NOT_USED:'未使用金额',
  COMMONS_RETAIN:'保留',
  COMMONS_REMOVE:'移除',
  COMMONS_ENABLE_RETENTION:'启用保留',
  COMMONS_ENABLE_RETENTION_SUCCESS:'启用保留成功',
  COMMONS_DEDUCTION_VOUCHER:'扣款凭证',
  COMMONS_PREPAYMENT_AMOUNT_OF_APPLICATION_FORM:'申请单预付金额',
  COMMONS_UNWRITTEN_OFF_QUANTITY:'未核销数量',
  COMMONS_APPLIED_WRITE_OFF_QUANTITY:'申请核销数量',
  COMMONS_APPLIED_WRITE_OFF_PROPORTION:'申请核销比例',
  COMMONS_APPLIED_WRITE_OFF_AMOUNT:'申请核销金额',
  COMMONS_WRITTEN_OFF_PROPORTION:'已核销比例',
  COMMONS_APPLICATION_TYPE:'申请类型',
  COMMONS_ADVANCE_DELAY_FREEZE_PAYMENT_NO:'提前/延迟/冻结付款单号',
  COMMONS_ADVANCE_DELAY_FREEZE_APPLICATION_FORM_STATUS:'提前/延迟/冻结申请单状态',
  COMMONS_DOCUMENT_NUMBER:'凭证编号',
  COMMONS_VOUCHER_LN:'凭证项次',
  COMMONS_SYSTEM_PAYMENT_DATE:'系统付款日期',
  COMMONS_DATE_OF_APPLICATION_FOR_PAYMENT:'申请付款日期',
  COMMONS_REASON_DESCRIPTION:'原因说明',
  COMMONS_OPERATION_DATE_AND_TIME:'操作日期时间',
  COMMONS_INVOICE_AMOUNT:'发票金额',
  COMMONS_PURCHASER_ADDRESS_TEL:'购方地址电话',
  COMMONS_PURCHASER_BANK_AND_ACCOUNT:'购方开户行及账号',
  COMMONS_MACHINE_CODE:'机器编码',
  COMMONS_ADDRESS_AND_TEL_OF_SELLER:'销方地址电话',
  COMMONS_TAXES:'税金',
  COMMONS_DELIVERY_TIME_IN_ADVANCE_DAYS:'提前收货时间(天)',
  COMMONS_STOCK_INTERVAL_LOWER:'备货区间-下',
  COMMONS_STOCKING_INTERVAL_UPPER:'备货区间-上',
  COMMON_UPDATE_TIME:'更新时间',
  COMMON_MATERIAL_DELIVERY_MAINTENANCE_IN_ADVANCE:'物料送货提前维护',
  COMMON_BY_DAY:'按天',
  COMMON_BY_INTERVAL:'按区间',
  COMMON_MATERIAL_ADVANCE_AREA:'物料提前区',
  COMMON_ADD_STATEMENT:'添加对账单',
  COMMON_DELETE_STATEMENT:'删除对账单',
  COMMON_ADVANCE_PAYMENT:'提前付款',
  COMMON_LATE_PAYMENT:'延迟付款',
  COMMON_FREEZE_PAYMENT:'冻结付款',
  COMMON_PURCHASING_SUPERVISOR:'采购主管',
  COMMON_PURCHASING_MANAGER:'采购经理',
  COMMON_FINANCIAL_STAFF:'财务人员',
  COMMON_INCREASE:'增加',
  COMMON_STATEMENT_AMOUNT_RANGE:'对账单金额区间',
  COMMON_INCORPORATED_COMPANY:'法人公司',
  COMMON_RECOGNITION_ACCOUNTING:'确认会计',
  COMMON_SAVE_TIME:'保存时间',
  COMMON_SAVE_BY:'保存人员',
  COMMON_NAME_OF_APPROVER:'审批人姓名',
  COMMON_APPROVAL_LABOR_NO:'审批人工号',
  COMMON_TO_DO_TYPE:'待办类型',
  COMMON_ODD_NUMBERS:'单号',
  COMMON_RECEIVE_TASK:'领取任务',
  COMMON_CLAIM_STATUS:'领取状态',
  COMMON_RECEIVED:'已领取',
  COMMON_NOT_CLAIMED:'未领取',
  COMMON_PAYABLE_PERSON:'可领取人',
  COMMON_RECIPIENT:'领取人',
  COMMON_RECONCILIATION_PENDING_POOL:'对账待审池',
  COMMON_PREPAID_PENDING_POOL:'预付待审池',
  COMMON_ESTIMATED_CLEANUP_PENDING_POOL:'暂估清理待审池',
  COMMON_TASK_TYPE:'任务类型',
  COMMON_TASK_LINK:'任务环节',
  COMMON_RECEIVING_TIME:'领取时间',
  COMMON_DESCRIPTION_OF_COLLECTION_AND_PAYMENT_CONDITIONS:'收付条件描述',
  COMMON_TOTAL_AMOUNT_OF_TAX_NOT_APPLIED_FOR_PREPAYMENT:'未税预付金额-合计',
  COMMON_TOTAL_PREPAID_AMOUNT_INCLUDING_TAX_APPLICATION:'含税预付金额-合计',
  COMMON_PREPAID_AMOUNT_INCLUDING_TAX:'含税预付金额',
  COMMON_ESTIMATED_LIST:'暂估清单',
  COMMON_ESTIMATION_LIST_IN_QUALITY_INSPECTION:'暂估清单-质检中',
  COMMON_ESTIMATED_CLEARING_LIST:'暂估清理清单',
  COMMON_ESTIMATION_COMPLETED_BY_QUALITY_INSPECTION:'质检完成的暂估',
  COMMON_ESTIMATION_BACK_OFFSET_FOR_QUALITY_INSPECTION_COMPLETION:'针对质检完成的暂估反冲',
  COMMON_RETURN_ESTIMATION:'退货暂估',
  COMMON_BACKFLUSH_FOR_RETURN_ESTIMATION:'针对退货暂估的反冲',
  COMMON_PRICE_DIFFERENCE_LIST:'价差清单',
  COMMON_CONFIGURATION_TEMPLATE:'对账单-对账会计确认环节配置模板',
  COMMON_CONFIGURATION_TEMPLATE_LIST:'对账单-对账会计确认环节配置清单',
  COMMON_FINANCIAL_APPROVAL:'对账单-财务审核环节配置模板',
  COMMON_FINANCIAL_APPROVAL_LIST:'对账单-财务审核环节配置清单',
  COMMON_FINANCIAL_AUDIT:'预付申请流程-财务审核环节配置模板',
  COMMON_FINANCIAL_AUDIT_LIST:'预付申请流程-财务审核环节配置清单',
  COMMON_MINIMUM_AMOUNT_RANGE:'最小金额区间',
  COMMON_MAXIMUM_AMOUNT_RANGE:'最大金额区间',
  COMMON_ENTER_INVOICE:'录入发票',
  COMMON_PARTIAL_PAYMENT:'部分已付款(拆分对账)',
  COMMON_RELATED_TO_RETURN:'与退货相关',
  COMMON_NOT_RELATED_TO_RETURN:'与退货无关',
  COMMON_REVERSED:'被冲销',
  COMMON_WRITE_OFF:'冲销',
  COMMON_CLEANING_STATUS:'清理状态',
  COMMON_TO_BE_CONFIRMED_BY_THE_MANAGER_OF_THE_PURCHASING_DEPARTMENT:'待部门经理审批',
  COMMON_PRICE_DIFFERENCE_LIST_STATUS:'价差单状态',
  COMMON_EXCESSIVE_LOSS_OF_UNPLANNED_PICKING:'计划外领料超损',
  COMMON_POOR_CUSTOMER_SUPPLY:'客供料不良',
  COMMON_PAYMENT_METHOD:'付款方式',
  COMMON_PROCESS_TYPE:'流程类型',
  COMMON_EST_CLEAR_APPLICATION:'暂估清理申请',
  COMMON_PAYMENT_APPLICATION:'提前/延迟/冻结付款申请',
  COMMON_PRICE_APPLICATION:'价差单申请',
  COMMON_DEDUCTION_APPLICATION:'扣款单申请',
  COMMON_FINANCIAL_APPROVER:'审批人员',
  COMMON_STATEMENT_CONFIGURATION_OF_RECONCILIATION_ACCOUNTING_CONFIRMATION_PHASE:'对账单-对账会计确认环节配置',
  COMMON_STATEMENT_FINANCIAL_AUDIT_LINK_CONFIGURATION:'对账单-财务审核环节配置',
  COMMON_PREPAYMENT_FINANCIAL_APPROVAL_PHASE_CONFIGURATION:'预付申请流程-财务审核环节配置',
  COMMON_DEDUCTION_FORM_DEDUCTION_TYPE_ACCOUNT_MAINTENANCE:'扣款单-扣款类型科目维护',
  COMMON_DEPARTMENT_MANAGER_CONFIGURATION:'部门经理配置',
  COMMON_SUPPLIER_WHITE_LIST_CONFIGURATION:'供应商白名单配置',
  COMMON_RECONCILING_SUPPLIER_LIST_CONFIGURATION:'对账供应商名单配置',
  COMMON_EMPLOYEE_NAME:'员工姓名',
  COMMON_EMPLOYEE_ID:'员工工号',
  COMMON_NAME_OF_DEPARTMENT_MANAGER:'经理姓名',
  COMMON_MANAGER_JOB_NO:'经理工号',
  COMMON_DEPARTMENT_MANAGER_CONFIGURATION_LIST:'部门经理配置清单',
  COMMON_DEPARTMENT_MANAGER_CONFIGURATION_TEMPLATE:'部门经理配置模板',
  COMMON_DEDUCTION_TYPE_ACCOUNT_MAINTENANCE_LIST:'扣款单-扣款类型科目维护清单',
  COMMON_DEDUCTION_TYPE_ACCOUNT_MAINTENANCE_TEMPLATE:'扣款单-扣款类型科目维护模板',
  COMMON_SUPPLIER_WHITE_LIST_CONFIGURATION_LIST:'供应商白名单配置清单',
  COMMON_SUPPLIER_WHITE_LIST_CONFIGURATION_TEMPLATE:'供应商白名单配置模板',
  COMMON_RECONCILIATION_SUPPLIER_LIST_CONFIGURATION_TEMPLATE:'对账供应商名单配置模板',
  COMMON_PROCESS_PROCESSING:'流程处理',
  COMMON_APPLICATION_TASKS:'我的暂估清理、提前/延迟/冻结付款申请任务',
  COMMON_RECONCILIATION_TASK_ACCOUNTING:'我的对账任务--对账会计确认环节',
  COMMON_RECONCILIATION_TASK_FINANCE:'我的对账任务--对账会计确认环节',
  COMMON_RECONCILIATION_TASK_MANAGER:'我的对账任务-经理确认环节',
  COMMON_PROCESS_LINK:'流程环节',
  COMMON_MY_RECONCILIATION:'我的对账',
  COMMON_MY_PREPAYMENT:'我的预付',
  COMMON_UNFREEZE:'未冻结',
  COMMON_FROZEN:'冻结',
  COMMON_FROZEN_STATE:'冻结状态',
  COMMON_FROZENR:'冻结人',
  COMMON_FREEZING_TIME:'冻结时间',
  COMMON_RECOVERY:'恢复',
  COMMON_TO_BE_CONFIRMED_BY_ACCOUNTING:'待会计确认',
  COMMON_RELATED_PRICE_DIFFERENCE_DOC_NO:'关联价差单号',
  COMMON_RELATED_STATEMENT_NO:'关联对账单号',
  COMMON_RELATED_ESTIMATION_CLEARING_DOC_NO:'关联暂估清理单号',
  COMMON_TOTAL_AMOUNT_DEDUCTED_TAX_INCLUDED:'扣款含税总金额',
  COMMON_SUPPLIER_INVENTORY_LOSS:'供应商盘亏',
  COMMON_EXCESSIVE_LOSS_OF_CUSTOMER_SUPPLIED_MATERIALS:'客供料超损',
  COMMON_DEDUCTION_ACCOUNT:'扣款科目',
  COMMON_COST_ACCOUNTING_CONFIRMATION_AMOUNT:'成本会计确认金额',
  COMMON_BANK_CODE:'银行代码',
  COMMON_TAX_RATE_CODE:'税率代码',
  COMMON_CONTACT_EMAIL:'联系邮箱',
  COMMON_DEDUCTION_AMOUNT:'扣款数量',
  COMMON_PREPAYMENT_APPLICATION_NO:'预付申请单号',
  COMMON_PREPAYMENT_APPLICATION_FORM_STATUS:'预付申请单状态',
  COMMON_BPO_NO:'BPO号',
  COMMON_PO_NO:'PO号',
  COMMON_APPROVED_BY:'审批人',
  COMMON_DEDUCTION_LIST:'扣款清单',
  COMMON_ADVANCE_DELAY_FREEZE_PAYMENT_LIST:'提前/延迟/冻结付款清单',
  COMMON_DEDUCTION_FORM_DETAIL_TEMPLATE:'扣款单明细模板',
  COMMON_DEDUCTION_SHEET_DETAILS:'扣款单明细',
  COMMON_PREPAYMENT_LIST:'预付清单',
  COMMON_TO_BE_CONFIRMED_BY_COST_ACCOUNTING:'待成本会计确认',
  COMMON_TO_BE_CONFIRMED_BY_THE_DEPARTMENT_MANAGER:'待部门经理确认',
  COMMON_ESTIMATION_CLEARING_PREFABRICATION_VOUCHER_NO:'暂估清理预制凭证号',
  COMMON_SUPPLIER_EXCESS_LOSS:'供应商超损（含计划外领料）',
  COMMON_DEFECTIVE_INCOMING_MATERIALS:'供应商来料不良连带报废',
  COMMON_NAME_OF_APPLICANT:'申请人姓名',
  COMMONS_SRM_ID:'暂估编号',
  COMMONS_TO_BE_CONFIRMED_BY_PURCHASE:'待采购确认',
  COMMONS_DEDUCTION_PREFABRICATION_VOUCHER_NO:'扣款预制凭证号',
  COMMONS_WHETHER_IT_IS_IN_THE_WHITE_LIST:'是否在白名单内',
  COMMONS_RETURN_MANAGEMENT_DOCUMENT:'退货管理单',
  COMMONS_ZRDP:'研发采购',
  COMMONS_ZNB:'生产采购',
  COMMONS_ZRWK:'生产退货',
  COMMONS_ZSOT:'转口贸易订单',
  COMMONS_ZSOT_PDF:'转口贸易订单PDF',
  COMMONS_SJVMI:'试剂vmi',
  COMMONS_ZGP:'GP采购订单',
  COMMONS_ZJJ:'机加中心采购订单',
  COMMONS_ZNBC:'标准采购订单',
  COMMONS_CUSTOMIZE_DISPLAY_COLUMNS:'自定义显示列',
  COMMONS_TOTAL_NO_TAX_AMOUNT_VARIANCE:'总未税金额差异',
  COMMONS_TOTAL_TAX_AMOUNT_DIFFERENCE:'总含税金额差异',
  COMMONS_INTERNATIONAL_RETURN_CYCLE:'国际退货周期',
  COMMONS_DOMESTIC_RETURN_CYCLE:'国内退货周期',
  COMMONS_FINANCIAL_REVIEWER:'财务审核人员',
  COMMONS_PO_ORDER_STATUS:'PO订单状态',
  COMMONS_BPO_CONFIRMATION_STATUS:'BPO确认状态',
  COMMONS_UNHANDLED:'未处理',
  COMMONS_ABNORMAL:'异常',
  COMMONS_THAW:'解冻',
  COMMONS_RETURN_CYCLE_MODIFICATION:'退货周期修改',
  COMMONS_RETURN_CYCLE_MODIFICATION_LIST:'退货周期修改清单',
  COMMONS_RETURN_CYCLE_MODIFICATION_TEMPLATE:'退货周期修改模板',
  COMMONS_DEFAULT_PAYMENT_TERMS:'默认付款条件',
  COMMONS_TOBE_REVIEWED_ACCOUNTING:'待会计主管复核',
  COMMONS_AMOUNT_WRITTEN_OFF:'已核销金额',
  COMMONS_AMOUNT_WRITTEN_OFF_INCLUDING_TAX:'含税已核销金额',
  COMMONS_UNWRITTEN_OFF_AMOUNT:'未核销金额',
  COMMONS_UNWRITTEN_OFF_AMOUNT_INCLUDING_TAX:'含税未核销金额',
  COMMONS_PAYMENT_TERM_CODE:'付款条件编码',
  COMMONS_DEFAULT_PREPAYMENT_PROPORTION:'默认预付比例(%)',
  COMMONS_PRICE_INCREASE_ORDER:'涨价单',
  COMMONS_PRICE_REDUCTION_SHEET:'降价单',
  COMMONS_FREIGHT_FORWARDING_INFORMATION:'货代信息',
  COMMONS_CUSTOMER_ADDRESS:'客户地址',
  COMMONS_CUSTOMER_PO_NO:'客户PO号',
  COMMONS_CUSTOMER_NAME:'客户名称',
  COMMONS_CITY:'城市',
  COMMONS_POSTAL_CODE:'邮政编码',
  COMMONS_STREET:'街道',
  COMMONS_STREET_ONE:'街道1',
  COMMONS_STREET_TWO:'街道2',
  COMMONS_STREET_THREE:'街道3',
  COMMONS_HOUSE_NUMBER:'门牌号',
  COMMONS_COUNTRY:'国家',
  COMMONS_TYPE_OF_SHIPPING:'运输方式',
  COMMONS_ORDER_REASON:'订单原因',
  COMMONS_CUSTOMER_MATERIAL_NO:'客户物料号',
  COMMONS_CUSTOMER_SPECIFIED_BATCH:'客户指定批次',
  COMMONS_ESTIMATED_DELIVERY_DATE:'预计发货日期',
  COMMONS_QUANTITY_OF_QUALITY_INSPECTION_RETURNS:'质检退货数',
  COMMONS_QUALITY_INSPECTION_RETURN_VOUCHER_NO:'质检退货凭证号',
  COMMONS_DOWNLOAD_INVENTORY_IMPORT_TEMPLATE:'下载库存导入模板',
  COMMONS_MAINTAINER:'维护人',
  COMMONS_ACCUMULATED_PREPAID_AMOUNT:'累计预付金额',
  COMMONS_ACCUMULATED_PREPAID_AMOUNT_INCLUDING_TAX:'累计含税预付金额',
  COMMONS_CUMULATIVE_PREPAYMENT_RATIO:'累计预付比例',
  COMMONS_TO_DO_ARRIVAL_TIME:'待办到达时间',
  COMMONS_SYNCHRONIZE_REFRESH_INVENTORY_INFORMATION:'同步/刷新库存信息',
  COMMONS_EXPORT_DELIVERY_DETAILS_REPORT:'导出交货明细报表',
  COMMONS_EXPORT_INVENTORY_INFORMATION_REPORT:'导出库存信息报表',
  COMMONS_EXPORT_MATERIAL_ISSUANCE_DETAILS_REPORT:'导出发料明细报表',
  COMMONS_DELIVERY_DETAIL:'交货详情',
  COMMONS_MATERIAL_ISSUANCE_DETAILS:'发料详情',
  COMMONS_DELIVERY_EARLY_WARNING:'交期预警',
  COMMONS_REPLENISHMENT_ALERT:'补货预警',
  COMMONS_RETURN_ALERT:'退货预警',
  COMMONS_HISTORICAL_DATA:'历史数据',
  COMMONS_HISTORICAL_SYNCHRONIZATION:'历史同步',
  COMMONS_QUALIFIED_INVENTORY_QUANTITY:'合格库存数量',
  COMMONS_IQC_RETURNED_QUANTITY:'IQC退货数量',
  COMMONS_EXPORT_COMPREHENSIVE_REPORT:'导出综合报表',
  COMMONS_Modify_demand_information:'修改需求信息',
  COMMONS_RECALCULATE_CHECK_BOX:'重新计算勾选项',
  COMMONS_SYNCHRONIZE_EMERGENCY_NEEDS:'同步紧急需求',
  COMMONS_NORMAL_SYNCHRONIZATION_REQUIREMENTS_COVERAGE:'正常同步需求(覆盖)',
  COMMONS_VIEW_MODIFICATION_HISTORY:'查看修改历史',
  COMMONS_MAINTENANCE_TIME:'维护时间',
  COMMONS_SELECT_DELIVERY_NOTE_DETAILS:'选择送货单明细',
  COMMONS_GENERATEDS_QUANTITY:'已生成数量',
  COMMONS_DELIVERY_NOTE_NOS:'送货单编号',
  COMMONS_PROCUREMENT_BUSINESS:'采购商务',
  COMMONS_IS_NON_TRADE_FOREIGN_EXCHANGE_PAYMENT:'是否非贸付汇',
  COMMONS_POST_PAYMENT_TERMS:'过账付款条件',
  COMMONS_TIME_SLOT:'时间段',
  COMMONS_PREPAYMENT_NOTE_NOS:'预付单号',
  /* FCST模块 */
  COMMON_YEAR:'年月',
  COMMON_YEAR_DAY:'年月日',
  COMMON_FCST_BRAND:'品牌',
  COMMON_VERSION:'版本',
  COMMON_MATERIAL_MODEL:'物料型号',
  COMMON_MATERIAL_QUOTA:'配额',
  COMMON_STORAGE_SITE:'储位',
  COMMON_MPR_CONTROLLER:'MPR控制者',
  COMMON_PROTECTION_REQUIREMENTS:'防护要求',
  COMMON_MATERIAL_IN_STOKE:'srm库存',
  COMMON_REPLY_TO_STOKE:'回复库存',
  COMMON_IN_TRANSIT_ORDER:'在途订单',
  COMMON_RECEIPT_THIS_MONTH:'本月收货',
  COMMON_RECEIPT_LAST_MONTH:'上月收货',
  COMMON_VIEW_RESPONSES:'查看回复',
  COMMON_FCST_International:'国际FCST-历史回复记录',
  COMMON_FCST_NUMBER:'No.',
  COMMON_FCST_EXPORT:'国际FCST原始数据导出_',
  COMMON_FCST_SUPPLY_INVENTORY:'供应商库存',
  COMMON_FCST_EXPORT_SUPPLY:'导入可供库存',
  COMMON_FCST_UPLOAD_SUPPLY:'上传可供库存',
  COMMONS_EXPORT_OR_IMPORTANT:'导出/导入模版',
  COMMONS_EXPORT_CLOSE:'关闭',
  COMMONS_MATERIAL_TYPE:'物料类型',
  COMMONS_MRPC:'MRPC',
  COMMONS_MATERIAL_ISSUANCE_DETAILS_REPORT:'发料明细报表',
  COMMONS_INVENTORY_SNAPSHOT:'库存快照',
  COMMONS_QUOTA_NOT_FOUND:'找不到配额',
  COMMONS_INCORRECT_QUOTA_INFORMATION:'配额信息有误',
  COMMONS_NO_NEED_TO_PUBLISH:'不需要发布',
  COMMONS_DEMAND_IS_LESS_THAN_INVENTORY:'需求小于库存',
  COMMONS_NOT_NULL:'不为空',
  COMMONS_FLOATING_VALUE:'浮动值',
  COMMONS_IMPORT_REAGENT_FCST:'导入试剂FCST',
  COMMONS_UPLOAD_REAGENT_FCST:'上传试剂FCST',
  COMMONS_DOCUMENT_STATUS:'单据状态',
  COMMONS_LESS_THAN:'是否小于上期',
  COMMONS_LESS_THAN_IS:'小于上期',
  COMMONS_CLASSIFICATION:'分类',
  COMMONS_OUTSIDE_THE_FCST:'是否FCST外',
  COMMONS_ADDED_OUTSIDE_FCST:'新增FCST外',
  COMMONS_EXPORT_OUTSIDE_FCST:'导入FCST外',
  COMMON_FCST_DOMESTIC_EXPORT:'FCST原始数据导出_',
  COMMON_SUPPLIER_INVENTORY:'供方库存',
  COMMONS_ABC:'ABC',
  COMMONS_EXPORT_FCST:'FCST数据导出',
  COMMONS_EXPORT_ERROR_FCST:'FCST导出错误信息',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT:'公告：',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT1:'1)每个月的需求为srm当月实际生产上线总需求',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT2:'，未减去srm库存；',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT3:'2)供应商在N月需要交货的数量=N月需求-库存;在N+1月需要交货数量=N月需求+(N+1)月需求-库存;依此类推N+2月需要交货数量;',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT4:'3)供应商在每个月的交货量最低要满足到本FCST的月需求,具体交货时间点和数量则按srm相应PO和交货规则来实现;',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT5:'4)当库存不为0且小于N月需求时,就判定为在线缺料;因为srm的产品种类特别多；显示的库存往往是被在制工单已经占用和分配；所以其它还没分配到的',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT6:'工单仍然可能是属于在线缺料状态,具体交货时间点和紧急程度按srm的PO交期和重排提前来安排;',
  // COMMON_LAST_UPDATE_TIME:'更新时间',
  COMMON_SURE_DELETE:'确认删除吗？',
  COMMON_ADD_EDIT:'新增/编辑',
  COMMON_CHOOSE_SUPPLIER:'选择供应商',
  COMMON_DOWNLOAD_MODAL:'下载模板',
  COMMON_SURE_PASS:'确认通过',
  COMMON_CONFIRM_REJECTION:'确认驳回',
  COMMON_STATUS:'协议状态',
  COMMON_REPLY_TYPE:'回复类型',
  COMMON_IS_ADD:'是否参与统计',
  COMMON_FCST_STOCK_UP:'FCST备货',
  COMMON_INVENTORY_ARRIVAL:'库存&到库',
  COMMON_YES:'是',
  COMMON_NO:'否',
  COMMON_AGREEMENT_TYPE:'协议类型',
  COMMON_AGREEMENT_MATH:'协议数量',
  COMMON_QUANTITY:'最小开机数量',
  COMMON_WEEK:'要求备货周数',
  COMMON_AUDIT_TIME:'审核时间',
  COMMON_MATERIAL_INFORMATION:'物料协议信息',
  COMMON_STOCK_REPORT:'备货综合报表',
  COMMON_STOCK_REPORT_TITLE1:'备货达成率',
  COMMON_STOCK_REPORT_TITLE2:'安全库存协议达成率',
  COMMON_ALERT:'交付预警',
  COMMON_ALERT_N:'N月预警',
  COMMON_ALERT_N1:'N+1月预警',
  COMMON_ALERT_N2:'N+2月预警',
  COMMON_ALERT_N3:'N+3月预警',
  COMMON_ALERT_YEAR_N1:'前两个月有预警',
  COMMON_ALERT_YEAR_N2:'前三个月有预警',
  COMMON_ALERT_YEAR_N3:'任一个月有预警',
  COMMON_SYSTEM:'库存系数',
  COMMON_INTERVAL:'Lead Time区间',
  COMMON_SUPPLIER_HAS:'供应商备货达成率',
  COMMON_DAYS:'Lead Time(天)',
  COMMON_WAIT_RETURN:'待回复',
  COMMON_WAIT_SURE:'待确认',
  COMMON_SUPPLIER_REPLY_TIME:'供应商回复时间',
  COMMON_DESCRIPTION:'描述',
  COMMON_RAW_MATERIAL:'半成品&原材料',
  COMMON_RAW_MATERIAL_PRODUCT:'半成品',
  COMMON_RAW_MATERIAL_ALL:'原材料',
  COMMON_RELEASE:'发布',
  COMMON_PLEASE_CHOOSE:'请选择',
  COMMON_REPLY_PEOPLE:'回复人',
  COMMON_INSTRUMENT:'仪器',
  COMMON_REAGENT:'试剂',
  COMMONS_GROUP:'组别',
  COMMONS_REMARK_INFORMATION:'FCST备注信息维护',
  COMMONS_INFORMATION_CHANGE:'物料协议信息维护',
  COMMONS_AGO:'历史回复记录',
  COMMONS_FCST_DOWNLOAD_MODULE:'FCST导入模板',
  COMMONS_FCST_DOWNLOAD_MODULE_OUT:'FCST外导入模板',
  COMMONS_HINT:'提示',
  COMMONS_CHECK_ONE:'至少勾选一项',
  COMMONS_ANNOUNCEMENTS:'P: 指预计当月要交货给srm的数量; C1: 表示当期确认可供的数量; C2: 表示上期确认的可供数量; B: 表示 Balance;',
  COMMONS_REPLY_AND_GET:'库存&到库',
  COMMONS_EXPORT_PEOPLE:'导入试剂专员',
  COMMONS_RELEASE_PEOPLE:'发布专员',
  COMMONS_HOLIDAY:'自发布日起T+',
  COMMONS_HOLIDAY_DAY:'天，系统统计备货达成率',
  COMMONS_STATUS_NO:'未审核',
  COMMONS_STATUS_PASS:'审核通过',
  COMMONS_RAW_MATERIAL_IMPORTANT:'导入模版',
  COMMONS_FCST_EXPORT_NAME:'国内FCST物料协议信息导入',
  COMMONS_FCST_EXPORT_NAME1:'国内FCST物料协议信息导出',
  COMMONS_FCST_EXPORT_NAME2:'导出错误信息',
  COMMONS_INPUT_NUMBER:'必须大于等于0',
  COMMONS_SUPPLIER_STOCK:'库存不能为空',
  COMMONS_THE_FIRST:'第',
  COMMONS_ROW:'行',
  COMMONS_RAW_MATERIAL_EXPORT:'原材料&半成品导出数据',
  COMMONS_RAW_MATERIAL_IMPORTANT1:'原材料&半成品导入模版',
  COMMONS_HOLIDAY_NO_EMPTY:'天数不能为空',
  COMMONS_DEDUCTION_WRITE_OFF_AMOUNT:'核销金额',
  COMMONS_DEDUCTION_AMOUNT_PAID:'实付金额',
  COMMONS_DELAYED_PAYMENT:'发起提前/冻结/延迟付款',
  COMMONS_CONSOLIDATED:'合并对账单',
  COMMONS_DOWNLOAD_IMG:'下载图像',
  COMMONS_IMPORTANT_INVOICE_IMG:'导入发票图像',
  COMMONS_IMPORTANT_INVOICE:'导入发票',
  COMMONS_ACCOUNT_INVOICE_EXPORT:'对账单发票数据导出',
  COMMONS_ACCOUNT_PRICE_DIFF_EXPORT:'对账单价差数据导出',
  COMMONS_ACCOUNT_DEDUCTION_EXPORT:'对账单扣款数据导出',
  COMMONS_ACCOUNT_CONTRACT_EXPORT:'对账单报关合同数据导出',
  COMMONS_ACCOUNT_ADVANCED_EXPORT:'对账单预付数据导出',
  COMMONS_ACCOUNT_CERFITICATE_EXPORT:'对账单预制凭证数据导出',
  COMMONS_FREIGHT_LINE:'运费行',
  COMMONS_DATA_RANGE:'日期范围',
  COMMONS_CUMULATIVE_USE_NO:'累计使用',
  COMMONS_POSTSCRIPT:'汇款附言',
  COMMONS_WRITE_OFF_QUANTITY:'本次核销数量',
  COMMONS_WRITE_OFF_RATIO:'本次核销比例',
  COMMONS_CLICK_UPLOAD:'点击上传',
  COMMONS_GROSS_P:'公告：该列表的供应商,FCST信息页面可以查看各月毛需求(未减srm库存);',
  COMMONS_GROSS_P1:'不在该列表的供应商,FCST信息页面查看各月净需求(已减去srm库存);',
  COMMONS_EXPORT_NAME:'物料协议信息维护导出',
  COMMONS_IMPORTANT_NAME:'物料协议信息维护导入模版',
  COMMONS_EXPORT_MESSAGE_NAME:'信息查询导出',
  COMMONS_EXPORT_REPLY_NAME:'回复可供库存导出',
  COMMONS_IMPORTANT_REPLY_NAME:'回复可供库存导入模版',
  COMMONS_PRICE_DIFF_DETAILS_NO:'价差单详情编码',
  COMMONS_PRICE_DIFF_NO:'价差单编号',
  COMMONS_ESTIMATED:'暂估编号',
  COMMONS_INVENTORY_LOSS:'存货类损失',
  COMMONS_INVENTORY_NO_LOSS:'非存货类损失',
  COMMONS_SURE_DELETE_DEBIT:'确认需要删除扣款明细?',
  COMMONS_SURE_DELETE_PRICE_DIFF:'确认需要删除价差明细?',
  COMMONS_SURE_DELETE_ESTIMATION:'确认需要删除暂估明细?',
  COMMONS_SURE_DELETE_INVOICE:'确认需要删除发票?',
  COMMONS_EXPRESS_COMPANY:'快递公司',
  COMMONS_PREPAYMENT:'预付款详情',
  COMMONS_DISMISSED_TO:'驳回至:',
  COMMONS_MIN_SHELF_LIFE:'到货剩余效期',
  COMMONS_BILL_EXPORT_MODAL:'对账单月结发票导入模版',
  COMMONS_BILL_RMB_EXPORT_MODAL:'对账单人民币发票导入模板',
  COMMONS_BILL_FOREIGN_EXPORT_MODAL:'对账单外币发票导入模板',
  COMMONS_ACCOUNT_DATA_CONFIRM_TITLE_SUPPLIER:'对账确认是否通过',
  COMMONS_ACCOUNT_DATA_CONFIRM_SUPPLIER:'对账数据将确认，确认后对账单状态将更新',
  COMMONS_ACCOUNT_DATA_CONFIRM_TITLE_ACCOUNTING:'对账确认是否通过',
  COMMONS_ACCOUNT_DATA_CONFIRM_ACCOUNTING:'对账数据将确认，确认后对账单状态将更新',
  COMMONS_ACCOUNT_DATA_CONFIRM_TITLE_INVOCING:'是否确认提交发票',
  COMMONS_ACCOUNT_DATA_CONFIRM_INVOCING:'供应商发票数据将确认，确认后对账单状态将更新',
  COMMONS_ACCOUNT_DATA_CONFIRM_TITLE_INVOCING_GET:'发票签收是否通过',
  COMMONS_ACCOUNT_DATA_CONFIRM_INVOCING_GET:'供应商发票数据将确认，确认后对账单状态将更新',
  COMMONS_ACCOUNT_DATA_CONFIRM_TITLE_INVOCING_SURE:'请确认发票是否通过',
  COMMONS_ACCOUNT_DATA_CONFIRM_INVOCING_SURE:'供应商发票数据将确认，确认后对账单状态将更新',
  COMMONS_ACCOUNT_DATA_CONFIRM_TITLE_FINANCE_SURE:'财务审核是否通过',
  COMMONS_ACCOUNT_DATA_CONFIRM_FINANCE_SURE:'财务审核数据将确认，确认后对账单状态将更新',
  COMMONS_RETURN_CONFIRM:'发票确认是否驳回？',
  COMMONS_RETURN_REASON:'请输入驳回原因',
  COMMONS_REASON_RETURN_CONFIRM:'对账确认是否驳回？',
  COMMONS_FINANCE_RETURN_CONFIRM:'财务确认是否驳回？',
  COMMONS_ACCOUNT_MONTH_EXPORT:'对账单数据导出',
  STATEMENT_LIST:'对账单列表',
  COMMONS_CAN_NOT_DO:'对账类型为随货得不能发起提前/冻结/延迟付款',
  COMMONS_MERGE_BILL_ORDER:'确认要合并对账单吗？',
  COMMONS_MERGE_BILL_ORDER_MESSAGE:'对账单合并',
  COMMONS_SHIPPING_CHARGES:'实际运杂费',
  COMMONS_INVOICE_IMG_HAS:'修改发票图像',
  COMMONS_INVOICE_IMG_HAS_CONTENT:'发票图像将被覆盖，是否确定？',
  COMMONS_CURRENT_EDITOR:'当前编辑人',
  COMMONS_FINANCIAL_OFFICER:'财务对账人员',
  COMMONS_GET_INVOICE:'驳回至录入发票',
  COMMONS_ADD_DEBIT:'添加扣款单',
  COMMONS_ADD_PRICE_DIFFERENCE:'添加价差单',
  COMMONS_DELETE_DEBIT:'删除扣款单',
  COMMONS_DELETE_PRICE_DIFFERENCE_DOC:'删除价差单',
  COMMONS_CHOOSE_DATE:'日期选择',
  COMMONS_PLEASE_CHOOSE_DATE:'请选择日期范围',
  COMMONS_TIMER_NAME:'定时器名称',
  COMMONS_CHANGE_ACCOUNTMATH:'修改对账数量不能小于等于0',
  COMMONS_CHANGE_ACCOUNTMATH1:'修改对账数量不能大于未对账数量',
  COMMONS_PAYMENT_DETAILS:'预付明细',
  COMMONS_CURRENT_WRITE_OFF_AMOUNT:'本次核销金额',
  COMMONS_NOT_PRICEDIFF:'该暂估单已经发起过价差，不能重复发起价差',
  COMMONS_ALREADY_AMOUNT_WRITE_OFF:'已核销金额',
  COMMONS_NOT_AMOUNT_WRITE_OFF:'未核销金额',
  ESTIMATED_NUMBER:'暂估单号',
  ESTIMATED_CAN_WRITE_OFF:'可核销金额',
  COMMONS_TOTAL_RECONCILIATION:'总对账数量',
  COMMON_FINANCIAL_SUPERVISOR:'会计主管',
  COMMON_FINANCIAL_RELEASE:'半成品原材料发布专员',
  COMMONS_FINANCIAL_POST:'财务签收岗',
  COMMONS_COST_ACCOUNTING:'成本会计',
  COMMONS_TQC:'TQC',
  COMMONS_INVOICE_MONEY:'发票余额',
  COMMONS_SURE_PRICE_DIFF:'确认要删除价差明细？',
  COMMONS_CANCEL_MESSAGE:'列表中存在勾选的数据, 请取消勾选后再进行添加',
  COMMONS_YEAR:'年月不能为空',
  COMMONS_YEAR_AND:'所选年月范围不能大于6个月',
  COMMONS_DOWNLOAD_CAN:'下载可供库存模板',
  COMMONS_DOWNLOAD_FCST:'下载FCST外模板',
  COMMONS_SURE_TIME:'确认时间',
  COMMONS_DELIVERY_LIST_CREATION_TIME:'送货单创建时间',
  COMMONS_SUPPLIER_CONFIRMS_ORDER:'供应商确认订单',
  COMMONS_SUPPLIER_MATERIAL_MODEL:'供应商物料型号',
  COMMONS_MPR_CONTROLLER:'MPR控制者',
  COMMONS_ORDER_CONFIRMED:'订单已确认',
  COMMONS_CONFIRMATION_SUCCEEDED:'确认成功',
  COMMONS_SUCCESSFUL_REPLY_TO_DELIVERY_DATE:'回复交期成功',
  COMMONS_PURCHASE_CONFIRMATION_SUCCEEDED:'采购确认成功',
  COMMONS_UNRECEIVED_QUANTITY:'未收货数量',
  COMMONS_BATCH_DELIVERY_PLAN:'分批交货计划',
  COMMONS_EIKTO:'采购员',
  COMMONS_LIFNR:'供应商编码',
  COMMONS_DISPO:'MRP控制者',
  COMMONS_LSSUE_DUDAT_END:'发料过账日期',
  COMMONS_MATNR:'物料编码',
  COMMONS_ISCONFIRM:'供应商确认',
  COMMONS_LSSUE_DUDAT_START:'收货过账日期(开始)',
  COMMONS_WERKS:'工厂',
  COMMONS_MEINS:'单位',
  COMMONS_FLHZ:'发料汇总',
  COMMONS_SINSM:'待检库存数量',
  COMMONS_SLABS:'已检库存数量',
  COMMONS_SLABX:'不良库存数量',
  COMMONS_LIFNRNAME:'供应商名称',
  COMMONS_CONFIR_DATE:'确认时间',
  COMMONS_EIKTOSTR:'采购员编码',
  COMMONS_INDEXS:'序号',
  COMMONS_MBLNR:'物料凭证',
  COMMONS_EBELN:'采购凭证',
  COMMONS_MENGNE:'发料数量',
  COMMONS_BUDAT:'过账日期',
  COMMONS_BASIC_UNIT:'基本单位',
  COMMONS_BATCH:'批次',
  COMMONS_MRP_CONTROLLER:'mrp控制者',
  COMMONS_SEQUENCE:'序列',
  COMMONS_FCST_CANGET:'FCST可供库存导入模板',
  COMMONS_FCST_NAME:'国际FCST_',
  COMMONS_FCST_ERROR:'国际FCST_ERROR_',
  COMMONS_FCST_EXPORT_TEMPLATE:'导入模版',
  COMMONS_ORDER_CONFIRM:'订单已确认',
  COMMONS_SUPPLIER_CONFIRM:'供应商确认订单',
  COMMONS_TIPS:'Tips: 须供应商先确认订单,才能进行后继操作;',
  COMMONS_TO_BE_REPLIED_BY_THE_SUPPLIER:'待供应商回复',
  COMMONS_PLEASE_SELECT_A_TIME:'请选择时间',
  COMMONS_PLEASE_SELECT_TYPE:'请选择内外检类型',
  COMMONS_PRODUCTION_DATE_CANNOT_BE_BLANK:'生产日期不能空',
  COMMONS_EXPIRATION_DATE_CANNOT_BE_BLANK:'到期日期不能空',
  COMMONS_PRODUCTION_BATCH_CANNOT_BE_EMPTY:'生产批次不能空',
  COMMONS_EXPIRATION_DATE_MUST_BE_GREATER_THAN_CURRENT_DATE:'到期日期必须大于当前日',
  COMMONS_NO_DELIVERY_NOTE_DETAILS_NO_DELIVERY_NOTE_CAN_BE_GENERATED:'没有送货单明细,不可生成送货单',
  COMMONS_PLEASE_SELECT_THE_DELIVERY_SLIP_WITH_THE_SAME_SUPPLIER_NUMBER:'请选择同一个供应商编号的送货单',
  COMMONS_RECONCILIATION_QUANTITYS:'对账数量',
  COMMONS_ROWNO:'行号',
  COMMONS_COMMODITYNAME:'货物或应税劳务名称',
  COMMONS_LICENSE_PLATE_NUMBER:'车牌号',
  COMMONS_START_DATE:'通行起日期',
  COMMONS_END_DATE:'通行止日期',
  COMMONS_FULL_AMOUNT:'全额',
  COMMONS_FLOATING_VALUE_LIST:'浮动值列表',
  COMMONS_BPO_ORDER_NO:'BPO单号',
  COMMONS_WHETHER_BPO_IS_CLOSED:'BPO是否关闭',
  COMMONS_DATE_CERTIFICATE:'凭证日期',
  COMMONS_NOT_CLOSED:'未关闭',
  COMMONS_LARGE_ORDER_TYPE:'大订单类型',
  COMMONS_NON_LTB:'非LTB',
  COMMONS_STOP_PRODUCTION_LTB:'停产LTB',
  COMMONS_BPO_VOUCHER_DATE:'BPO凭证日期',
  COMMONS_BPO_TOTAL:'BPO总数',
  COMMONS_QUANTITY_DELIVERED:'已交数量',
  COMMONS_PO_QUANTITY_RELEASED:'已释放PO数量',
  COMMONS_EXECUTABLE:'是否可执行',
  COMMONS_ABNORMAL_CAUSES:'异常原因',
  COMMONS_SUPPLIER_INVENTORY_PREPARATION_METHOD:'供方库存备货方式',
  COMMONS_SUPPLIER_INVENTORY_REQUIRED:'要求供方库存',
  COMMONS_QUANTITY_OR_WEEK:'数量/周数',
  COMMONS_CURRENT_VERSION:'当前版本',
  COMMONS_HISTORICAL_VERSION:'历史版本',
  COMMONS_IMPLEMENTATION_RATE_RANGE:'执行率范围',
  COMMONS_CONSUMPTION_RATE_RANGE:'消耗率范围',
  COMMONS_TOTAL_IN_TRANSIT_PO: '总在途PO',
  COMMONS_IN_TRANSIT_PO: '在途PO',
  COMMONS_BACKLOG_OR_SEMI_FINISHED_PRODUCTS: 'backlog/半成品',
  COMMONS_THEORETICAL_REMAINING_AVAILABLE_QUANTITY: '理论剩余可供数量',
  COMMONS_ACTUAL_STOCK_VARIANCE: '实际备货差异',
  COMMONS_AVERAGE_MONTHLY_DEMAND: '月均需求',
  COMMONS_BPO_DELIVERY_RATE: 'BPO交货率',
  COMMONS_NUMBER_OF_MONTHS_COVERED_BY_UNUSED_THEORY: '未使用理论可覆盖月数',
  COMMONS_BPO_IMPLEMENTATION_RATE: 'BPO执行率(%)',
  COMMONS_MC_CONFIRMATION: 'MC确认',
  COMMONS_PUBLISHED_OR_NOT: '是否已发布',
  COMMONS_PUBLISH_BPO: '发布BPO',
  COMMONS_CONFIRM_PUBLISH_BPO: '确认发布Bpo信息?',
  COMMONS_CONFIRM_THE_RELEASE_OF_BPO_REPLY_FOR: '确认发布BPO回复可供?',
  COMMONS_BPO_QUERY: 'BPO查询',
  COMMONS_BPO_CONFIRMATION_REPORT: 'BPO确认报表',
  COMMONS_BPO_REPLY_AVAILABLE_REPORT: 'BPO可供回复报表',
  COMMONS_SUPPLIER_BPO_REMAINING_AVAILABLE_QUANTITY: '供方BPO剩余可供数量',
  COMMONS_PUBLISH_BPO_REPLIES_FOR: '发布BPO回复可供',
  COMMONS_BPO_COMPREHENSIVE_REPORT: 'BPO综合报表',
  COMMONS_REFERENCE_FCST_INVENTORY: '引用FCST库存',
  COMMONS_CONFIRM_TO_QUOTE_FCST_TO_REPLY_INVENTORY: '确定引用FCST回复库存?',
  COMMONS_BATCH_UPLOAD: '批量上传',
  COMMONS_UPLOAD_SUPPLIER_RESPONSE_INFORMATION: '上传供应商回复信息',
  COMMONS_BPO_REPLY_AVAILABLE_REPORT_ANNOUNCEMENT:'公告：',
  COMMONS_BPO_REPLY_AVAILABLE_REPORT_ANNOUNCEMENT1:'1) 供方库存：指供应商端成品库存。',
  // COMMONS_BPO_REPLY_AVAILABLE_REPORT_ANNOUNCEMENT2:'2) 途中数量：指BPO发布到SRM，到供应商回复库存的时间段，已送迈瑞还未过账的数量。',
  // COMMONS_BPO_REPLY_AVAILABLE_REPORT_ANNOUNCEMENT3:'3) 半成品/Backlog：指半成品转换为成品的可成套数量，或backlog数量。',
  COMMONS_BPO_REPLY_AVAILABLE_REPORT_ANNOUNCEMENT4:'2) 注：按模板导入时，请不要更改模板，否则无法导入成功。',
  COMMONS_TASK_TRANSFER: '任务转办',
  COMMONS_ORIGINAL_TASK_PROCESSING_LABOR_NUMBER: '原任务处理人工号',
  COMMONS_NEW_TASK_PROCESSING_LABOR_NUMBER: '新任务处理人工号',
  COMMONS_NAME_OF_ORIGINAL_TASK_HANDLER: '原任务处理人姓名',
  COMMONS_NAME_OF_NEW_TASK_HANDLER: '新任务处理人姓名',
  COMMONS_IMPORT_REQUIREMENTS: '导入需求',
  COMMONS_REQUIREMENTS_TEMPLATE: '需求模板',
  COMMONS_SUMMARY_INFORMATION_OF_NOTIFICATION_ARRIVAL_DEMAND: '通知到货需求汇总信息',
  COMMONS_IQC_ACCOUNT_NUMBER: 'IQC台帐号',
  COMMONS_PERFORMANCE_MONTH:'绩效月份',
  COMMONS_TOTAL_NUMBER_OF_ITEMS:'总项数',
  COMMONS_THE_NUMBER_OF_OVERDUE_ITEMS:'逾期项数',
  COMMONS_DELIVERY_RATE:'交付率',
  COMMONS_REQUIREMENT_NUMBER:'需求编号',
  COMMONS_WHETHER_IT_IS_OVERDUE:'是否逾期',
  COMMONS_START_RECONCILIATION_DATE:'启动对账日期',
  COMMONS_PLEASE_SEPARATE_MULTIPLE_WITH_COMMAS: '多个请用逗号分割',
  COMMONS_RECONCILIATION_ACCOUNTING:'对账会计',
  COMMONS_AUDIT_ACCOUNTING:'审核会计',
  COMMONS_LEADER_NAME:'领导姓名',
  COMMONS_EXPORT_EXCEL:'导出Excel',
  COMMONS_EXCEL_IMPORT:'Excel导入',
  COMMONS_LEAD_SIGNATURE_IMPORT:'领导签名导入',
  COMMONS_LEADER_SIGNATURE:'领导签名',
  COMMONS_LEADER_SIGNATURE_TEMPLATE:'领导签名模板',
  COMMONS_PART_NUMBER:'料号',
  COMMONS_MANUFACTURERS:'生产商',
  COMMONS_ORIGIN:'原产地',
  COMMONS_WAYS_OF_TRADE:'贸易方式',
  COMMONS_CUSTOMS_NUMBER:'海关编号',
  COMMONS_PARAMETER:'参数',
  COMMONS_TRADING_COUNTRIES:'贸易国',
  COMMONS_FIRST_NOTE:'备注1',
  COMMONS_SECOND_NOTE:'备注2',
  COMMONS_CUSTOMS_CLEARANCE_MODEL:'报关型号',
  COMMONS_SYNCHRONIZE_DATA:'同步数据',
  COMMONS_SEQUENCE_NUMBER:'车次序号',
  COMMONS_DRIVER:'司机',
  COMMONS_LAST_PICKUP_TIME:'最后提货时间',
  COMMONS_ARIBA_CONTRACT_NO:'Ariba 合同编号',
  COMMONS_IMPORT_AND_EXPORT_BUSINESS_APPLICATION_FORM:'进出口业务申请单',
  COMMONS_ROUGH_WEIGHT:'毛重',
  COMMONS_MATERIAL_DETAILS:'物料明细',
  COMMONS_INVOICE_VERIFICATION_VOUCHER_NO:'发票校验凭证号',
  COMMONS_ACCOUNTING_VOUCHER_NUMBER:'会计凭证号',
  COMMONS_CUSTOMS_DECLARATION_DATE:'报关日期',
  COMMONS_AMOUNT_OF_FOREIGN_EXCHANGE_PAYMENT:'付汇金额',
  COMMONS_TOTAL_FOREIGN_EXCHANGE_PAYMENT:'付汇总额',
  COMMONS_CUSTOMS_DECLARATION_FEES_AND_MISCELLANEOUS_CHARGES:'报关费杂费',
  COMMONS_DETAILED_STATEMENT_OF_VOUCHER_PAYMENT_IN_FOREIGN_EXCHANGE:'凭证付汇明细报表',
  COMMONS_PROCESS_STATUS:'流程状态',
  COMMONS_FLOATING_VALUE_TEMPLATE:'浮动值模板',
  COMMONS_SURE_UPLOAD_FILE:'确认要上传文件吗?',
  COMMONS_UNIT_PRICE_WITHOUT_TAX:'未税单价',
  COMMONS_CONFIRM_TO_SAVE:'确认要保存吗?',
  COMMONS_SYNCHRONIZATION_SUCCEEDED:'同步成功',
  COMMONS_EXPORT_APPLICATION_FORMS:'导出进出口申请单',
  COMMONS_ADJUST_CUSTOMS_CONTRACTS:'调整报关合同',
  COMMONS_TOTALS:'合计',
  COMMONS_POST_TAXES:'过账税金',
  COMMONS_SUBMIT_REPLY_DELIVERY_DATE:'确定要提交回复的交期吗?',
  COMMONS_NOTIFICATION_OF_ARRIVAL_DELAY_RESPONSE_RECORDS:'通知到货延迟回复记录',
  COMMONS_NOTIFICATION_ARRIVAL_PERFORMANCE_STATISTICS_REPORT:'通知到货绩效统计报表',
  COMMONS_NOTIFICATION_ARRIVAL_PERFORMANCE_REPORTS:'通知到货绩效报表',
  COMMONS_FABRICATION_VOUCHER_AMOUNT:'预制凭证金额',
  COMMONS_TOTAL_AMOUNT_OF_PREFABRICATED_TAX:'预制税总金额',
  COMMONS_REQUIREMENTS_FILE:'需求文件',
  COMMONS_SUPPLIER_DELIVERY_NOTE:'供应商送货单',
  COMMONS_WORK_TICKETS:'工单',
  COMMONS_TICKET_STATUS:'工单状态',
  COMMONS_REL:'REL',
  COMMONS_CRTD:'CRTD',
  COMMONS_WHETHER_SATISFIED:'是否满足',
  COMMONS_AVAILABLE:'可供',
  COMOMNS_AVAILABLE_TIME:'可供时间',
  COMONS_MANTISSA_IS_AVAILABLE_FOR_TIME:'尾数可供时间',
  COMMONS_QUANTITY_TO_BE_MEASURED:'待测数量',
  COMMONS_NUMBER_OF_MANTISSAS:'尾数数量',
  COMMONS_VIEW_RECORDS_IN_BATCHES:'分批记录查看',
  COMMONS_REPLY_SUBMISSIONS_IN_BATCHES:'分批回复提交',
  COMMONS_EXPORT_THE_PCBA_TICKET_LIST:'导出PCBA工单列表',
  COMMONS_IMPORT_PCBA_TICKET_LIST_INFORMATION:'导入PCBA工单列表信息',
  COMMONS_PCBA_TICKETS_LIST:'PCBA工单列表',
  COMMONS_IMPORT_PCBA_TICKET_LIST:'PCBA工单列表导入',
  COMMONS_CONFIRM_TO_SUBMIT:'确认要提交吗?',
  COMMONS_DATE_OF_RESPONSE:'回复日期',
  COMMONS_WHETHER_TO_STORE_IN_BATCHES:'是否分批入库',
  COMMONS_REL_DATE:'REL时间',
  COMMONS_NUMBER_OF_TICKETS:'工单数量',
  COMMONS_DEMAND_BEFORE_TODAY:'今天以前需求',
  COMMONS_PRODUCTION_LINE:'产线',
  COMMONS_DOWNLOAD_PCBA_PRODUCTION_LINE_TEMPLATE:'下载PCBA产线模板',
  COMMONS_PCBA_PRODUCTION_LINE_TEMPLATE:'PCBA产线模板',
  COMMONS_MANUALLY_SYNCHRONIZE_PCBA:'手工同步PCBA',
  COMMONS_UPLOAD_THE_PCBA_TICKET_PLAN:'上传PCBA工单计划',
  COMMONS_PCBA_TICKET_TEMPLATE:'PCBA工单模板',
  COMMONS_IMPORT_MACHINING_SHEET_LIST_INFORMATION:'导入机加工单列表信息',
  COMMONS_EXPORT_MACHINING_ORDERS:'导出机加工单列表',
  COMMONS_FIRST_AVAILABLE_TIME:'首批可供时间',
  COMMONS_REMAINDER_AVAILABLE_TIME:'余数可供时间',
  COMMONS_FIRST_AVAILABLE_QUANTITY:'首批可供数量',
  COMMONS_REMAINDER_AVAILABLE_QUANTITY:'余数可供数量',
  COMMONS_NUMBER_ONE:'1',
  COMMONS_NUMBER_TWO:'2',
  COMMONS_NUMBER_THREE:'3',
  COMMONS_NUMBER_FOUR:'4',
  COMMONS_NUMBER_FIVE:'5',
  COMMONS_NUMBER_SIX:'6',
  COMMONS_NUMBER_SEVEN:'7',
  COMMONS_NUMBER_EIGHT:'8',
  COMMONS_NUMBER_NINE:'9',
  COMMONS_NUMBER_TEN:'10',
  COMMONS_COMPANY_1000:'1000',
  COMMONS_COMPANY_1910:'1910',
  COMMONS_LOGGING:'日志记录',
  COMMONS_PROCESSING_TYPE:'处理类型',
  COMMONS_VERBOSE_LOG_FILE:'详细日志文件',
  COMMONS_MONTH:'月份',
  COMMONS_START_EXECUTION:'开始执行',
  COMMONS_EXECUTION_COMPLETE:'执行完成',
  COMMONS_EXECUTION_FAILED:'执行失败',
  COMMONS_REQUEST_DATA:'请求数据',
  COMMONS_USE:'用途',
  COMMONS_VIEW:'查看',
  COMMONS_CHANGE:'变更',
  COMMONS_SUPPLIER_STATUS:'供应商状态',
  COMMONS_FULL_NAME_OF_SUPPLIER:'供应商全称',
  COMMONS_SUPPLIER_ABBREVIATION:'供应商简称',
  COMMONS_APPLY_FOR_BUSINESS:'申请业务',
  COMMONS_APPLICATION_RESULTS:'申请结果',
  COMMONS_ANNUAL_REVENUES:'年营收(万)',
  COMMONS_ANNUAL_REVENUE:'年营收',
  COMMONS_CATEGORIES_OF_TAXES:'税种',
  COMMONS_BANK:'开户行',
  COMMONS_ACCOUNT_NUMBERS:'收款账号',
  COMMONS_SUPPLY_CATEGORY:'供应类别',
  COMMONS_SUPPLY_PRODUCTS:'供应产品',
  COMMONS_LOCATION:'所在地',
  COMMONS_NUMBER_OF_COMPANIES:'公司人数',
  COMMONS_COMPANY_PHONE:'公司电话',
  COMMONS_COMPANY_FAX:'公司传真',
  COMMONS_COMPANY_EMAIL:'公司邮箱',
  COMMONS_POSTCODE:'公司邮编',
  COMMONS_NUMBER_OF_SOCIAL_SECURITY_PERSONNEL:'社保人数',
  COMMONS_LEGAL_REPRESENTATIVE:'法定代表人',
  COMMONS_ESTABLISHMENT_TIME_AND_OPERATING_PERIOD:'成立时间与经营期限',
  COMMONS_ENTERPRISE_STATUS:'企业状态',
  COMMONS_REGISTERED_CAPITAL:'注册资本',
  COMMONS_PAID_CAPITAL:'实缴资本',
  COMMONS_ACCESS:'准入',
  COMMONS_CANDIDATE:'候补',
  COMMONS_ELIMINATE:'淘汰',
  COMMONS_APPLYING:'申请中',
  COMMONS_FULL_NAME_OF_THE_COMPANY:'公司全称',
  COMMONS_COMPANY_ABBREVIATION:'公司简称',
  COMMONS_ORGANIZATION_CODE:'机构代码',
  COMMONS_TAX_RATES:'税率',
  COMMONS_CONTACT_ADDRESS:'联系地址',
  COMMONS_TAXPAYER_IDENTIFICATION_NUMBER:'纳税人识别号',
  COMMONS_PERIOD_OF_VALIDITY:'有效期',
  COMMONS_BUSINESS_UPDATE_TIME:'工商更新时间',
  COMMONS_ENTERPRISE_NATURE:'企业性质',
  COMMONS_COMPANY_REGISTERED_ADDRESS:'注册地址',
  COMMONS_CONTACT_PERSON:'联络人',
  COMMONS_BECOMING_A_QUALIFIED_SUPPLIER:'成为合格供应商',
  COMMONS_BECOMING_A_BACKUP_SUPPLIER:'成为候补供应商',
  COMMONS_BECOMING_AN_OBSOLETE_SUPPLIER:'成为淘汰供应商',
  COMMONS_REVOKE_APPLICATION:'撤销申请',
  COMMONS_REJECT:'驳回',
  COMMONS_CERTIFICATE:'证书名称',
  COMMONS_AUTHORIZATION_TYPE:'授权类型',
  COMMONS_SCOPE_OF_AUTHORITY:'授权范围',
  COMMONS_AUTHORIZED_AREA:'授权地区',
  COMMONS_CERTIFICATE_VALIDITY_PERIOD:'证书有效期',
  COMMONS_CERTIFICATE_TYPE:'证书类型',
  COMMONS_SUPPLIER_REGION:'供应商地区',
  COMMONS_BUSINESS_STATUS:'经营状态',
  COMMONS_ESTABLISHMENT_TIME:'成立时间',
  COMMONS_IN_QUOTATION:'报价中',
  COMMONS_OBLIGATION:'待付款（笔）',
  COMMONS_SCORING_TABLE:'评分表',
  COMMONS_REVIEW_PROGRESS:'评审进度',
  COMMONS_RATING_TYPE:'评分类型',
  COMMONS_GRADING_MODEL:'评分模式',
  COMMONS_BREAK_DOWN_ITEMS:'打分项',
  COMMONS_ADD_CERTIFICATE:'新增证书',
  COMMONS_CERTIFICATE_CODE:'证书编码',
  COMMONS_CERTIFICATE_FORMAT:'证书格式',
  COMMONS_AUTHORIZATION_FORM:'授权形式',
  COMMONS_PRODUCT_SCOPE:'商品范围',
  COMMONS_SPECIFIC_REGIONS:'特定地区',
  COMMONS_IS_IT_MANDATORY:'是否必填',
  COMMONS_RATING_TABLE_NAME:'评分表名称',
  COMMONS_FREQUENCY:'频率',
  COMMONS_NEW_RULE:'新建规则',
  COMMONS_RULE_NAME:'规则名称',
  COMMONS_INVOICING_RULES:'开票规则',
  COMMONS_REDEMPTION_CYCLE:'兑付周期',
  COMMONS_TRANSACTION_NODE_PAYMENT_RATIO_SETTLEMENT_ACCOUNTING_PERIOD:'交易节点&付款比例&结算账期',
  COMMONS_CONDITIONAL_VARIABLE_ONE:'条件变量1',
  COMMONS_CONDITIONAL_VARIABLE_TWO:'条件变量2',
  COMMONS_CONDITIONAL_VARIABLE_THREE:'条件变量3',
  COMMONS_INVITE_SUPPLIERS:'邀请供应商',
  COMMONS_CONTACT_NAME:'联系人姓名',
  COMMONS_CONTACT_MOBILE:'联系人手机',
  COMMONS_POSITION:'职位',
  COMMONS_GENDER:'性别',
  COMMONS_MALE:'男',
  COMMONS_WOMAN:'女',
  COMMONS_REGISTER:'注册',
  COMMONS_BASIC_INFORMATION_OF_THE_ENTERPRISE:'企业基本信息',
  COMMONS_ENTERPRISE_QUALIFICATION_CERTIFICATE:'企业资质证书',
  COMMONS_OTHER_ATTACHMENTS:'其它附件',
  COMMONS_MOBILE_VERIFICATION_CODE:'手机验证码',
  COMMONS_PURCHASING_INVITATION_CODE:'采购商邀请码',
  COMMONS_SUPPLIER_INVITATION_CODE:'供应商邀请码',
  COMMONS_VAT_ORDINARY_INVOICE:'增值税普通发票',
  COMMONS_VAT_INVOICE:'增值税专用发票',
  COMMONS_ENTERPRISE_QUERY_RESULTS:'企业查询结果',
  COMMONS_CELL_PHONE_NUMBER:'手机号码',
  COMMONS_PRODUCT_NAME:'产品名称',
  COMMONS_PRODUCT_CLASSIFICATION:'产品分类',
  COMMONS_SUPPLY_ADDRESS:'供货地址',
  COMMONS_PRODUCT_MODEL:'产品型号',
  COMMONS_SIZE:'尺寸',
  COMMONS_COLOUR:'颜色',
  COMMONS_MATERIAL:'材料',
  COMMONS_MONTHLY_PRODUCTION_VOLUME:'月生产量',
  COMMONS_ANNUAL_PRODUCTION_VOLUME:'年生产量',
  COMMONS_STARTING_SALES_QUANTITY:'起售数量',
  COMMONS_VOLUME_DISCOUNT:'批量折扣',
  COMMONS_WARRANTY_PERIOD:'质保期限',
  COMMONS_PICTURE:'图片',
  COMMONS_TEMPLATE_DOWNLOAD:'模版下载',
  COMMONS_RESET_PASSWORD:'重置密码',
  COMMONS_ASSIGNING_ROLES:'分配角色',
  COMMONS_USER_ID:'用户编号',
  COMMONS_USER_NAME:'用户名称',
  COMMONS_USER_NICKNAME:'用户昵称',
  COMMONS_USER_STATUS:'用户状态',
  COMMONS_NORMAL:'正常',
  COMMONS_BELONGING_DEPARTMENT:'归属部门',
  COMMONS_USER_PASSWORD:'用户密码',
  COMMONS_USER_GENDER:'用户性别',
  COMMONS_UNKNOWN:'未知',
  COMMONS_POST:'岗位',
  COMMONS_ROLE:'角色',
  COMMONS_LOGIN_ACCOUNT:'登录账号',
  COMMONS_ROLE_NUMBER:'角色编号',
  COMMONS_ROLE_NAME:'角色名称',
  COMMONS_PERMISSION_CHARACTERS:'权限字符',
  COMMONS_BASIC_INFORMATION:'基本信息',
  COMMONS_ROLE_INFORMATION:'角色信息',
  COMMONS_DATA_PERMISSIONS:'数据权限',
  COMMONS_ROLE_ORDER:'角色顺序',
  COMMONS_DEACTIVATE:'停用',
  COMMONS_MENU_PERMISSIONS:'菜单权限',
  COMMONS_SELECT_ALL_OR_NOT_ALL:'全选/不全选',
  COMMONS_ENTER:'请输入',
  COMMONS_ASSIGN_DATA_PERMISSIONS:'分配数据权限',
  COMMONS_BATCH_CANCELLATION_OF_AUTHORIZATION:'批量取消授权',
  COMMONS_SELECT_USER:'选择用户',
  COMMONS_UNCHECK_USER_AUTHORIZATION:'是否取消选中用户授权数据项',
  COMMONS_REVOKE:'取消授权',
  COMMONS_POST_NO:'岗位编号',
  COMMONS_JOB_CODE:'岗位编码',
  COMMONS_JOB_NAME:'岗位名称',
  COMMONS_JOB_RANKING:'岗位排序',
  COMMONS_POSITION_STATUS:'岗位状态',
  COMMONS_DICTIONARY_STATUS:'字典状态',
  COMMONS_DICTIONARY_NUMBER:'字典编号',
  COMMONS_DICTIONARY_NAME:'字典名称',
  COMMONS_DICTIONARY_TYPE:'字典类型',
  COMMONS_PARAMETER_PRIMARY_KEY:'参数主键',
  COMMONS_PARAMETER_NAME:'参数名称',
  COMMONS_PARAMETER_KEY_NAME:'参数键名',
  COMMONS_PARAMETER_KEY:'参数键值',
  COMMONS_SYSTEM_BUILT_IN:'系统内置',
  COMMONS_SYNCHRONIZE_SAP_ORDERS:'同步sap订单',
  COMMONS_ANNOUNCEMENT_TITLE:'公告标题',
  COMMONS_ANNOUNCEMENT_TYPE:'公告类型',
  COMMONS_CREATOR:'创建者',
  COMMONS_NOTICE:'通知',
  COMMONS_ANNOUNCEMENT:'公告',
  COMMONS_LOG_NUMBER:'日志编号',
  COMMONS_SYSTEM_MODULE:'系统模块',
  COMMONS_OPERATION_ADDRESS:'操作地址',
  COMMONS_OPERATION_LOCATION:'操作地点',
  COMMONS_OPERATIONAL_STATE:'操作状态',
  COMMONS_TIME_CONSUMING:'消耗时间',
  COMMONS_EMPOWER:'授权',
  COMMONS_FORCED_RETREAT:'强退',
  COMMONS_WIPE_DATA:'清空数据',
  COMMONS_SUCCESS:'成功',
  COMMONS_FAIL:'失败',
  COMMONS_CLEAN_UP:'清空',
  COMMONS_CLEAR_SUCCESSFULLY:'清空成功',
  COMMONS_OPERATION_MODULE:'操作模块',
  COMMONS_REQUEST_ADDRESS:'请求地址',
  COMMONS_LOGIN_INFORMATION:'登录信息',
  COMMONS_REQUEST_METHOD:'请求方式',
  COMMONS_OPERATION_METHOD:'操作方法',
  COMMONS_REQUEST_PARAMETERS:'请求参数',
  COMMONS_RETURN_PARAMETERS:'返回参数',
  COMMONS_ACCESS_NUMBER:'访问编号',
  COMMONS_LOGIN_ADDRESS:'登录地址',
  COMMONS_LOGIN_LOCATION:'登录地点',
  COMMONS_BROWSER:'浏览器',
  COMMONS_OPERATING_SYSTEM:'操作系统',
  COMMONS_LOGIN_STATUS:'登录状态',
  COMMONS_OPERATIONAL_INFORMATION:'操作信息',
  COMMONS_LOGON_DATE:'登录日期',
  COMMONS_LOGIN_TIME:'登录时间',
  COMMONS_PURPOSE_OF_APPLICATION:'申请目的',
  COMMONS_PROCUREMENT_MANAGER:'采购负责人',
  COMMONS_POST_EXPIRATION_STATUS:'到期后状态',
  COMMONS_VALID_TO:'有效期至',
  COMMONS_STATUS_AFTER_EXPIRATION:'过期后状态',
  COMMONS_QUALIFIED:'合格',
  COMMONS_REJECT_REAPPLY:'驳回（再申请）',
  COMMONS_MAIN_NUMBER:'主编号',
  COMMONS_FINISH_PRODUCT:'成品',
  COMMONS_LOW_VALUE_CONSUMABLES:'低值易耗品',
  COMMONS_SPECIFICATION_PARAMETERS:'规格参数',
  COMMONS_HARDNESS:'硬度',
  COMMONS_MATERIAL_QUALITY:'材质',
  COMMONS_MINIMUM_UNIT:'最小单位',
  COMMONS_CREATE:'创建',
  COMMONS_GENERATE:'生成',
  COMMONS_CREATE_SPECIFICATIONS:'创建规格',
  COMMONS_SPECIFICATION_MATERIAL:'规格物料',
  COMMONS_MATERIAL_CODE:'物料号',
  COMMONS_POWER:'功率',
  COMMONS_LENGTH:'长度',
  COMMONS_WIDTH:'宽度',
  COMMONS_PURITY:'纯度',
  COMMONS_SUPPLIER_LIST:'供应商列表',
  COMMONS_SUPPLY_LOCATION:'供货地点',
  COMMONS_RECENT_QUOTATIONS:'近期报价',
  COMMONS_QUOTATION_DATE:'报价日期',
  COMMONS_HISTORICAL_PRICE:'历史价格',
  COMMONS_PRODUCTION_SCHEDULING_CYCLE:'排产周期',
  COMMONS_DAILY_PRODUCTION_VOLUME:'日生产量',
  COMMONS_BILLING_TYPE:'开票类型',
  COMMONS_PROCURE:'采购',
  COMMONS_INSUFFICIENT_TOLERANCE:'不足容差(%)',
  COMMONS_EXCESSIVE_TOLERANCE:'过量容差(%)',
  COMMONS_INVENTORY_INFORMATION:'库存信息',
  COMMONS_AVAILABLE_INVENTORY:'可用库存数',
  COMMONS_ESTIMATED_INWARD_DELIVERY_QUANTITY:'暂估内向交货数',
  COMMONS_NUMBER_OF_IN_TRANSIT_DELIVERIES:'在途内向交货数',
  COMMONS_BLOCKED_STOCK:'冻结库存',
  COMMONS_SAMPLE_MANAGEMENT:'样品管理',
  COMMONS_SAMPLE_SPECIFICATION_CODING_AND_IMAGES_MULTIPLE:'样品规格编码及图像（多个）',
  COMMONS_CATEGORY:'商品分类',
  COMMONS_SAMPLING_METHOD:'采样方式',
  COMMONS_NUMBER_OF_SAMPLES:'样品数量',
  COMMONS_WAREHOUSING_TIME:'入库时间',
  COMMONS_FIRST_SAMPLER:'首次采样人',
  COMMONS_CURRENT_CUSTODIAN:'当前保管人',
  COMMONS_SPECIFICATION_LIST_AND_RELATED_INFORMATION:'规格清单与相关信息',
  COMMONS_DIAMETER:'直径',
  COMMONS_BOM_PATH:'BOM路径',
  COMMONS_ENERGY_CONSUMPTION:'能耗',
  COMMONS_QUALITY_INSPECTION_REQUIREMENTS:'质检要求',
  COMMONS_MAIN_BARCODE:'主条码',
  COMMONS_IN_TRANSIT_PURCHASE_ORDERS:'在途采购订单',
  COMMONS_CONSUMABLES:'消耗品',
  COMMONS_VIRTUAL_ITEM:'虚拟物品',
  COMMONS_IN_REVIEW:'审核中',
  COMMONS_VALID_CONTACTS:'有效联系人',
  COMMONS_ARCHIVED:'已归档',
  COMMONS_ADDING_MATERIALS:'新增物料',
  COMMONS_FORM_NAME:'规格名称',
  COMMONS_SPECIFICATION_TYPE:'规格类型',
  COMMONS_SPECIFICATIONS_TEXT:'文本',
  COMMONS_SPECIFICATIONS_COLOR_PALETTE:'色板',
  COMMONS_SPECIFICATIONS_IMAGE:'图片',
  COMMONS_SORT:'类别',
  COMMONS_MAKE_MANDATORY:'设为必填',
  COMMONS_CANCEL_REQUIRED_FIELDS:'取消必填',
  COMMONS_CATEGORY_PARENT:'上级分类',
  COMMONS_CLASSIFICATION_NAME:'分类名称',
  COMMONS_CLASSIFICATION_CODE:'分类代码',
  COMMONS_TITLE:'标题',
  COMMONS_KEYWORD:'关键词',
  COMMONS_CREATE_CLASSIFICATION:'创建分类',
  COMMONS_CREATED_SUCCESSFULLY:'创建成功',
  COMMONS_MOVE_IN_CLASSIFICATION:'移入分类',
  COMMONS_UNIT_NAME:'单位名称',
  COMMONS_CONVERSION_RULES:'换算规则',
  COMMONS_ROUNDING_ACCURACY:'舍入精度',
  COMMONS_MEASUREMENT_TYPE:'计量类型',
  COMMONS_BASE_UNIT:'基准单位',
  COMMONS_NEW_UNIT_TYPE:'新增单位类型',
  COMMONS_NEW_UNIT:'新增单位',
  COMMONS_UNIT_CODE:'单位代码',
  COMMONS_CONVERSION_METHOD:'换算方式',
  COMMONS_CONVERSION_COUNT:'换算计数',
  COMMONS_IS_BENCHMARK_UNIT:'是否基准单位',
  COMMONS_MODIFICATION_TYPE:'修改类型',
  COMMONS_MATERIAL_GROUP_CODE:'物料组编码',
  COMMONS_DETAILED_ADDRESS:'详细地址',
  COMMONS_ZIP_CODE:'邮编',
  COMMONS_ABBREVIATION:'简称',
  COMMONS_LABEL:'标签',
  COMMONS_BUSINESS_INFORMATION:'工商信息',
  COMMONS_BUSINESS_LICENSE_NUMBER:'营业执照号',
  COMMONS_LEGAL_PERSON_NAME:'法人姓名',
  COMMONS_BUSINESS_VALIDITY_PERIOD:'营业有效期',
  COMMONS_FINANCE:'财务',
  COMMONS_BILLING_ADDRESS:'开票地址',
  COMMONS_TAXPAYER_NUMBER:'纳税号',
  COMMONS_BANKS:'银行',
  COMMONS_ACCOUNT_NUMBER:'账号',
  COMMONS_ACCOUNT_TYPE:'账户类型',
  COMMONS_ADDRESS_TYPE:'地址类型',
  COMMONS_MATERIAL_NUMBER_SPECIFICATION:'物料号(规格)',
  COMMONS_HOME_PRICE:'当家价格',
  COMMONS_VALIDITY_PERIOD_OF_QUOTATION:'报价有效期',
  COMMONS_QUALIFICATIONS:'企业资质',
  COMMONS_CORRESPONDENCE_CONTRACT:'往来合同',
  COMMONS_CONTRACT_NOTE:'合同说明',
  COMMONS_TYPE_OF_CONTRACT:'合同类型',
  COMMONS_SIGNING_TIME:'签订时间',
  COMMONS_EXPIRATION_TIME:'到期时间',
  COMMONS_PARTY_A:'甲方',
  COMMONS_PARTY_B:'乙方',
  COMMONS_FACE_TAX_RATE:'票面税率',
  COMMONS_QUOTATION_AND_TAXATION:'报价与计税',
  COMMONS_QUOTATION_AND_TRANSPORTATION:'报价与运输',
  COMMONS_TRANSACTION_RULES:'交易规则',
  COMMONS_SPECIFICATION_VALUE:'规格值',
  COMMONS_IS_IT_MANAGED_BY_THE_PURCHASER:'是否采购员托管',
  COMMONS_INTERNATIONAL_TRADE:'国际贸易',
  COMMONS_ON_DEMAND_MATERIAL_PULLING:'按需拉料',
  COMMONS_MILKRUN:'MILKRUN',
  COMMONS_MAINTENANCE_METHODS:'维护方式',
  COMMONS_ADD_NEW_SAMPLES:'新增样品',
  COMMONS_COMMODITY:'商品',
  COMMONS_ITEM_CODE:'商品编号',
  COMMONS_SPECIFICATIONS:'规格',
  COMMONS_SAMPLER:'采样人',
  COMMONS_WAREHOUSING_DATE:'入库日期',
  COMMONS_SAMPLE_VALIDITY_PERIOD:'样品有效期',
  COMMONS_CUSTODIAN:'保管人',
  COMMONS_SAMPLE_LOCATION:'样品位置',
  COMMONS_SAMPLE_IMAGES:'样品图片',
  COMMONS_MATERIAL_FIRST_NUMBER:'物料首编号',
  COMMONS_TRADE_NAME:'商品名称',
  COMMONS_SAMPLE_MODEL:'样品型号',
  COMMONS_SAMPLE_SPECIFICATIONS_MULTIPLE:'样品规格(多个)',
  COMMONS_NEW_TYPE:'新增类型',
  COMMONS_TYPE_NAME:'类型名称',
  COMMONS_TYPE_CODE:'类型编码',
  COMMONS_PRODUCT:'产品',
  COMMONS_GENERAL_TYPE:'一般类型',
  COMMONS_UPLOAD_TEMPLATE:'上传模版',
  COMMONS_SPECIAL_REGIONS:'特殊地区',
  COMMONS_ADD_REGION:'添加地区',
  COMMONS_SELECT_ADDRESS:'选择地址',
  COMMONS_IS_IGNORE_THE_CERTIFICATE:'是否忽略证书',
  COMMONS_ADD_RULES:'新增规则',
  COMMONS_NEW_ENTRY:'新建条目',
  COMMONS_REVIEWERS:'评审人',
  COMMONS_ADD_SCORING_ITEMS:'添加打分项',
  COMMONS_PURCHASING_MATERIALS:'采购物料',
  COMMONS_REQUIREMENT_TYPE:'需求类型',
  COMMONS_REQUIREMENT_PATH:'需求路径',
  COMMONS_MATERIAL_CLASSIFICATION:'物料分类',
  COMMONS_ALTERNATIVE_MATERIALS:'可替物料',
  COMMONS_CHANNEL_SCOPE:'渠道范围',
  COMMONS_BRAND_RANGE:'品牌范围',
  COMMONS_ATTACHMENT_REQUIREMENTS:'附件要求',
  COMMONS_QUALITY_STANDARD:'质量标准',
  COMMONS_PURCHASE_QUANTITY:'采购数量',
  COMMONS_UNIT_PRICE_RANGE_YUAN:'单价范围（元）',
  COMMONS_PROCUREMENT_PURPOSE:'采购用途',
  COMMONS_INITIATION_TIME:'发起时间',
  COMMONS_ADVANCE_STORAGE_TOLERANCE:'提前入库容差',
  COMMONS_STORAGE_LOCATION:'入库位置',
  COMMONS_PROCUREMENT_STATUS:'采购状态',
  COMMONS_NEW_REQUIREMENT:'新建需求',
  COMMONS_BOM_BULK_PROCUREMENT:'BOM表批量采购',
  COMMONS_DOWNLOAD_BOM:'下载BOM表',
  COMMONS_BOM:'BOM表',
  COMMONS_PURCHASING_GOODS:'采购商品',
  COMMONS_PRODUCT_SPECIFICATIONS:'商品规格',
  COMMONS_SPLIT_DELIVERY:'分批到货',
  COMMONS_STORAGE_TOLERANCE:'入库容差',
  COMMONS_LATENCY:'延迟',
  COMMONS_JIT_TIMELY_DELIVERY:'JIT及时到货',
  COMMONS_DELIVERY_REQUIREMENTS:'交付要求',
  COMMONS_NEW_QUOTATION_PLAN:'新建报价计划',
  COMMONS_VIEW_INQUIRY_FORM:'查看询价单',
  COMMONS_VIEW_QUOTATION:'查看报价单',
  COMMONS_QUOTATION_NO:'报价单号',
  COMMONS_BILL_OF_MATERIAL:'物料清单',
  COMMONS_REFUSAL_TO_QUOTE:'拒绝报价',
  COMMONS_REASON:'理由',
  COMMONS_INQUIRY_SHEET:'询价单',
  COMMONS_QUOTATION_STATUS:'报价状态',
  COMMONS_APPROVAL_STATUS:'审批状态',
  COMMONS_SOLICITATION_PLANNING:'询价计划',
  COMMONS_PLAN_EXECUTOR:'计划执行人',
  COMMONS_DELIVERY_DATE:'交货日期',
  COMMONS_JIT_OR_NOT:'是否JIT',
  COMMONS_ACCEPTABLE_QUANTITY:'可承接数量',
  COMMONS_PRICING_VALIDITY:'价格有效期',
  COMMONS_DELIVERY_TIME:'发货时间',
  COMMONS_QUOTATION_REPLY:'报价回复',
  COMMONS_QUOTATION_PROCESSING:'报价处理',
  COMMONS_VOID_QUOTATION:'作废报价单',
  COMMONS_URGENT_QUOTATION:'催报价',
  COMMONS_INDIVIDUAL_DELIVERY_DATE:'单独交付日期',
  COMMONS_CENTRALIZED_DELIVERY_DATE:'集中交货日期',
  COMMONS_SELLER_ACCEPTANCE_PRICE:'卖方接受价',
  COMMONS_ARRIVAL_TIME:'到货时间',
  COMMONS_INCLUDING_JIT_REQUIREMENTS:'含JIT需求',
  COMMONS_SUPPLIER_SCOPE:'供应商范围',
  COMMONS_STORAGE_ADVANCE_TOLERANCE:'入库提前容差',
  COMMONS_STORAGE_DELAY_TOLERANCE:'入库延迟容差',
  COMMONS_PLANNED_PROCUREMENT_TIME:'计划采购时间',
  COMMONS_RECEIVING_UNIT:'接收单位',
  COMMONS_SUPPLIER_TYPE:'供应商类型',
  COMMONS_FOB_PRICE:'离岸价',
  COMMONS_CIF_PRICE:'到岸价',
  COMMONS_PRE_TAX:'税前',
  COMMONS_AFTER_TAX:'税后',
  COMMONS_NO_TICKET:'无票',
  COMMONS_ALONG_WITH_GOODS_STORAGE:'随货（入库）',
  COMMONS_ADVANCE_CHARGE:'预付款',
  COMMONS_VIEW_SUPPLIERS:'查看供应商',
  COMMONS_LATEST_ORDER_DATE:'最晚下单日期',
  COMMONS_QUOTATION_DEADLINE:'报价截止日期',
  COMMONS_PRICE_ADJUSTMENT_METHOD:'改价方式',
  COMMONS_AFTER_THE_QUOTATION_IS_COMPLETED:'报价结束后',
  COMMONS_DURING_THE_QUOTATION_PERIOD:'报价期间',
  COMMONS_UNLIMITED:'无限制',
  COMMONS_EARLY_DELIVERY_TOLERANCE_WORKING_DAYS:'提前交货容差(工作日)',
  COMMONS_DELAY_IN_DELIVERY_TOLERANCE_WORKING_DAYS:'延迟交货容差(工作日)',
  COMMONS_INQUIRY_TYPE:'询价类型',
  COMMONS_TYPE_OF_BIDDING_MATERIALS:'招标物料类型',
  COMMONS_IMPORT_BOM:'导入BOM表',
  COMMONS_NEW_BIDDING_PLAN:'新建招标计划',
  COMMONS_SELECT_MATERIALS:'选择物料',
  COMMONS_BIDDING_TYPE:'招标类型',
  COMMONS_PUBLIC_CONTACTS:'公开联系人',
  COMMONS_BIDDING_AREA:'招标地区',
  COMMONS_TENDER_ATTACHMENTS:'招标附件',
  COMMONS_INVITER:'邀请人',
  COMMONS_INVITATION_TIME:'邀请时间',
  COMMONS_BIDDING_TIME:'招标时间',
  COMMONS_EVALUATION_TIME:'评标时间',
  COMMONS_RATING_ITEMS:'评分项',
  COMMONS_EVALUATOR:'评标人',
  COMMONS_TECHNICAL_EVALUATION_TIME:'技术评标时间',
  COMMONS_TECHNICAL_EVALUATOR:'技术评标人',
  COMMONS_SCHEDULED_START_TIME:'计划开始时间',
  COMMONS_INCREASE_METHOD:'增加方式',
  COMMONS_FILE_NOTES:'文件备注',
  COMMONS_EXAMINE_AND_APPROVE:'审批',
  COMMONS_BUSINESS_VALIDITY_PERIOD_EXPIRATION_DATE:'营业有效期(到期日)',
  COMMONS_BUSINESS_VALIDITY_PERIOD_START_DATE:'营业有效期(开始日)',
  COMMONS_APPROVAL_SUCCESSFUL:'审批成功',
  COMMONS_BATCH_ADDITION:'批量新增',
  COMMONS_BRAND_NAME:'品牌名称',
  COMMONS_BRAND_CODE:'品牌编码',
  COMMONS_BRAND_IMAGE_URL:'品牌图片url',
  COMMONS_NEW_BRANDS_ADDED:'新增品牌',
  COMMONS_UPLOAD_PICTURES:'上传图片',
  COMMONS_EDIT_BRAND:'编辑品牌',
  COMMONS_DOES_IT_INCLUDE_JIT_REQUIREMENTS:'是否含jit需求',
  COMMONS_BATCH_NUMBER:'分批数',
  COMMONS_PRICE_REMINDER_METHOD:'催价方式',
  COMMONS_SELLERS_PRICE:'卖方价格',
  COMMONS_BUYERS_PRICE:'买方价格',
  COMMONS_NOTIFY_QUOTATION:'通知报价',
  COMMONS_GENERATE_PURCHASE_ORDER:'生成采购订单',
  COMMONS_BIDDING_STATUS:'招标状态',
  COMMONS_STARTING_TIME_OF_BIDDING:'招标开始时间',
  COMMONS_END_TIME_OF_BIDDING:'招标结束时间',
  COMMONS_BIDDING_RESTRICTIONS:'招标限制',
  COMMONS_CREATE_NEW_RATING_ITEM:'新建评分项',
  COMMONS_RATING_ITEM_NAME:'评分项名称',
  COMMONS_RATING_PERSONNEL:'评分人员',
  PURCHASE_ORDER_REQUEST_NUMBER:'采购单需求编号',
  COMMONS_TENDER_DOCUMENT_NUMBER:'招标单号',
  COMMONS_STATUS_AFTER_EXPIRATION_DATE:'有效期截止后的状态',
  COMMONS_PROCUREMENT_BATCH:'采购批次',
  COMMONS_QUOTATION_VALIDITY_DAYS:'报价有效天数',
  COMMONS_PROCUREMENT_REQUIREMENTS:'采购需求',
  COMMONS_PROCUREMENT_DEPARTMENT:'采购部门',
  COMMONS_PROCUREMENT_PERSONNEL:'采购人员',
}
export default common_zh_CN
