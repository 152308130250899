// 注册页面
import React, { Component } from 'react'
import { Steps, Row, Form, Button, Card, Upload, Input, message, Col, DatePicker, Space } from 'antd'
import { 
  stepList, 
  getPersonTableHeader, 
  SUPPLY_PRODUCTS_HEADER,
  CONTACT_PERSON_HEADER,
  contactPersonFormModalArrList,
  supplyProductsFormModalArrList,
  getSupplyProductsTableHeader,
} from '@/utils/constant/register'
import './register.less'
import {FormattedMessage, injectIntl} from "react-intl";
import { connect } from 'react-redux'
import {
  getSearchFormHeight,
  getColumnsWidthSum,
  PAGE_SIZE,
  getPermissionBtn,
  PUB_SUB_LANG_CHANGE,
  isFileTypes,
  formatMessage,
  getUuid
} from '@/utils/util'
import CustomTableNoType from '@/components/custom-table-no-type/custom-table-no-type'
import store from '@/store/store'
import PubSub from 'pubsub-js'
import CustomButton from '@/components/custom-button/custom-button'
import ContactCardContent from '@/pages/register/contact-card-content/contact-card-content'
import CustomLink from '@/components/custom-link/custom-link';
import LFListForm from '@/components/LF-list-form/LF-list-form'
import {
  reqInitSupplier,
  reqGetSupplierInfoByUserPhone,
  reqSaveSupplierInfo,
  reqGetUploadFileConfig,
  reqQueryMaterialInfoPage,
  reqQueryMaterialSpecPage,
  reqFileUpload
} from '@/api/index'
import { getFactoryAddDeliveryAddressList } from "@/store/createActions"
import LzListForm from '@/components/lz-list-form/lz-list-form'
import dayjs from 'dayjs';
import moment from 'moment'
const { Step } = Steps
const FILE_TYPE = ['.txt', '.doc', '.xls', '.ppt', '.docx', '.xlsx', '.pptx', '.jpg', '.png', '.pdf', '.tiff', '.swf', '.PNG', '.TXT', '.DOC', '.XLS', '.PPT', '.DOCX', '.XLSX', '.PPTX', '.JPG', '.PNG', '.PDF', '.TIFF', '.SWF'];
const {RangePicker} = DatePicker

class Register extends Component {
  BaseDataFormRef = React.createRef()
  uploadForm = React.createRef()
  searchCondition = {}
  isNeedAreaTreeList = true
  isNeedAreaList = true
  isNeedMaterialClassificationList = true
  state = {
    areaTreeList: [],//地区树状
    areaList: [],//地区列表
    materialClassificationList: [],//物料分类列表
    current: 0,
    currentPage: 1,
    totalSize: 0,
    columns: [],
    _columns: [],
    personTableDataSource: [],
    productsTableDataSource: [],
    pageSize: PAGE_SIZE,
    columnsWidthSum: 0, //表格滚动的宽度
    tableBodyHeight: 170, //表格滚动的高度
    file:{},
    type:[],//[".xls", ".xlsx"]
    customtablenotype2: {
      columns: CONTACT_PERSON_HEADER,
      rowKey: 'id',
    },
    customtablenotype4: {
      columns: SUPPLY_PRODUCTS_HEADER,
      rowKey: 'id',
    },
    interfaceObject2: {
      //添加、编辑-保存
      submit: {
      },
      //表格数据
      getList: {
        setColumnWidthArr: [],
      },
    },
    interfaceObject4: {
      //下载
      download: {
      },
      //表格数据
      getList: {
        setColumnWidthArr: [],
      },
    },
    isShowBtn2: {
      addBtn: true,
      detailBtn: true,
      updateBtn: true,
      deleteBtn: true,
    },
    isShowBtn4: {
      deleteBtn: true,
      addBtn: true,
    },
    lzlistform2: {
      submitFormArrList: contactPersonFormModalArrList,
      span: 24,
    },
    lzlistform3: {
      span: 24,
    },
    baseuploadformmodal4: {
      titleId: 'COMMONS_DOWNLOAD',
    },
    file: {},
    formData: [],
    registerData: {},//第一步注册返回的数据
    getUserData: {},
    tableDatas: [],//联络人表格数据
    certificateList:[],
    checkAreaId: 0,//公司资质时选择的地区ID
    loading: false,
    materialInfoData: [],//选中该物料的分页列表
    materialSpecData: [],//物料规格列表
    isAdd: false,
    isEdit: false,
    lzListFormVisible: false,
    queryMaterialInfoPage: [], //物料(主数据)分页列表
    editRecord: {},//编辑的行数据
}
  actions = [
    {
      title:<FormattedMessage id='COMMONS_OPERATION'/>,
      key: 'detail',
      width: 138,
      zhWidth: 138,
      enWidth: 138,
      align: 'center',
      render: (text,record,index) => {
        return <>
        {/*删除*/}
        {
          <CustomLink id='COMMON_DELETE' onClick={(e) => this.handleProductDetele(record)}/>
        }
        {/*编辑*/}
        {
          <CustomLink id='COMMONS_EDIT' onClick={(e) => this.handleEdit(record)}/>
        }
        </>
      }
    }
  ]

  handleProductDetele = (record) => {
    let filterSource = [...this.state.productsTableDataSource]
    filterSource = filterSource.filter((item) => item._uuid !== record._uuid)
    this.setState({
      productsTableDataSource: filterSource,
    })
  }

  handleEdit = (record) => {
    this.setState({
      isEdit: true,
      lzListFormVisible: true,
      editRecord: record
    })
    for (let key in record) {
      if (key === 'configCategoryId' && !Array.isArray(record[key])) {
        record[key] = [record[key]]
      }
    }
    this.lzListFormModal.formRef.current.setFieldsValue({
      ...record,
    })
    this.handleGetSpecificationChange(record.configCategoryId,'2')
    this.handleMaterialBaseIdChange(record.materialBaseId,'2')
  }

  getParentId = (data,id,result) => {
    for (let i in data) {
      let item = data[i]
      if (item.areaId === id) {
        result.unshift(item.areaId)
        if (item.parentId) {
          this.getParentId(data,item.parentId,result)
        }
      }
    }
    return result
  }

  getParentStr = (data,id,str) => {
    for (let i in data) {
      let item = data[i]
      if (item.areaId === id) {
        str.unshift(item.name)
        if (item.parentId) {
          this.getParentStr(data,item.parentId,str)
        }
      }
    }
    return str
  }

//处理产品的地区数据
  getProductAreaId = () => {
    const { getUserData } = this.state
    let target = getUserData.productVos.slice(0)
    let list = target.map(item => {
      item.areaParentIds = item.areaParentIds
      return item
    })
    this.setState({
      productsTableDataSource: list
    })
  }

  //资质信息
  getCertVos = () => {
    const { getUserData } = this.state
    let upList = []
    this.uploadForm.current.getFieldsValue().certItems.forEach(item => {
      upList.push(item)
    })
    if (getUserData && getUserData.certVos && getUserData.certVos.length) {
      let certVosData = getUserData.certVos
        for (let i = 0; i < upList.length; i++) {
          for (let j = 0; j < certVosData.length; j++) {
          if (upList[i] && upList[i].relCertRuleItemId === certVosData[j].relCertRuleItemId) {
            if (certVosData[j].validStartDate) {
              certVosData[j].validDate = [moment(certVosData[j].validStartDate),moment(certVosData[j].validEndDate)]
            }
            upList[i] = certVosData[j]
          }
        }
      }
      this.uploadForm.current.setFieldsValue({certItems:upList})
    }
  }
  next = async() => {
    this.setState({
      loading: true
    })
    if (this.state.current === 0) {
      this.savePersonMsg()
    }
    //联络人
    if (this.state.current === 1) {
      let arr = JSON.parse(JSON.stringify(this.state.tableDatas))
      arr.forEach(item => {
        item.areaId = item.areaId.slice(-1)[0]
      })
      const params = {
        userItems: arr,
        stepFlage: 'save_contactUser'
      }
      this.saveSupplierInfoFn(params)
    }
    //先保存，再获取
    //保存公司资质
    if (this.state.current === 2) {
      if (this.addOrUpdateModalForm2 && this.addOrUpdateModalForm3) {
        let companyList = this.addOrUpdateModalForm3.formRef.current.getFieldsValue()
        let supplierList = this.addOrUpdateModalForm2.formRef.current.getFieldsValue()
        if (!supplierList.addressAreaId.length) {
          message.info('请选择地区')
          this.setState({
            loading: false
          })
          return
        }
        if (companyList.licenseDate) {
          let date = companyList.licenseDate
          companyList.licenseValidStartDate = date[0].format("YYYY-MM-DD HH:mm:ss")
          companyList.licenseValidEndDate = date[1].format("YYYY-MM-DD HH:mm:ss")
        }
        
        if (supplierList.addressAreaId && supplierList.addressAreaId.length > 0) {
          let address = supplierList.addressAreaId
          supplierList.addressAreaId = address.slice(-1)[0]
          this.setState({
            checkAreaId: address.slice(-1)[0]
          })
        }
        const params = {
          supplierInfoParam: supplierList,
          companyParam: companyList,
          stepFlage: 'save_supplierBase'
        }
        this.saveSupplierInfoFn(params)
      }
    }
    //产品明细
    if (this.state.current === 3) {
      this.state.productsTableDataSource.forEach(item => {
        item.areaId = item.areaParentIds.slice(-1)[0]
        item.configCategoryId = item.configCategoryId.slice(-1)[0]
      })
      const params = {
        productItems: this.state.productsTableDataSource,
        stepFlage: 'save_productItems'
      }
      this.saveSupplierInfoFn(params)
    }
    //资质信息
    if (this.state.current === 4) {
      let uploadFormList = []
      this.uploadForm.current.getFieldsValue().certItems.forEach(item => {
        if (item) {
          uploadFormList.push(item)
        }
      })
      uploadFormList.forEach(item => {
        if (item && item.validDate && item.validDate.length) {
          item.validStartDate = item.validDate[0].format('YYYY-MM-DD')
          item.validEndDate = item.validDate[1].format('YYYY-MM-DD')
        }
      })
      const params = {
        certItems: uploadFormList,
        stepFlage: 'save_certifItems'
      }
      this.saveSupplierInfoFn(params)
    }
  };

  handleSkip = () => {
    this.getUserDataFn()
  }

  //公司信息时获取完整的地区id
  findCompanyAreaIdParent = (data, id, result) => {
    for (let i in data) {
      if (id === data[i].areaId) {
        result.unshift(data[i].areaId)
        if (data[i].parentId) {
          this.findCompanyAreaIdParent(data,data[i].parentId,result)
        }
      }
    }
    return result
  }

  //处理时间数据
  getDate = () => {
    const { getUserData } = this.state
    let dateList = []
    if (getUserData.supplierCompanyVo && getUserData.supplierCompanyVo.licenseValidStartDate) {
      dateList.unshift(dayjs(getUserData.supplierCompanyVo.licenseValidStartDate).format(),dayjs(getUserData.supplierCompanyVo.licenseValidEndDate).format())
    }
    return dateList
  }
  //进入公司信息时，回显后端的数据
  showCompanyMsg = () => {
    const { getUserData } = this.state
    if (getUserData.supplierBaseVo) {
      //处理地址数据
      this.addOrUpdateModalForm2.formRef.current.setFieldsValue({
        ...getUserData.supplierBaseVo,
        addressAreaId: getUserData.supplierBaseVo.currentAndParentAreaIds
      })
    }
    let uerData = getUserData.supplierCompanyVo
    if (uerData) {
      this.addOrUpdateModalForm3.formRef.current.setFieldsValue({
        ...uerData,
        licenseDate: uerData.licenseValidStartDate ? [moment(uerData.licenseValidStartDate),moment(uerData.licenseValidEndDate)] : ''
      })
    }
  }

  prev = () => {
    this.setState({
      current: this.state.current - 1,
    }, () => {
      //第一步
      if (this.state.current === 0) {
        this.state.getUserData.contactUserVos.forEach(item => {
          if (item.isSupplierOwn === 'Y') {
            this.addOrUpdateModalForm1.formRef.current.setFieldsValue({...item})
          }
        })
      }
      //公司基本信息
      if (this.state.current === 2 && this.state.getUserData ) {
        this.showCompanyMsg()
      }
      //供应产品
      if (this.state.current === 3 && this.state.getUserData ) {
        this.getProductAreaId()
      }
      //资质信息
      if (this.state.current === 4 && this.state.getUserData && this.state.getUserData.certVos) {
        this.getCertVos()
      }
    })
  };

  //注册完成
  submit = () => {
    this.props.history.push('/login')
  }
  //保存第一步的供应商信息
  savePersonMsg = async() => {
    let promise = this.addOrUpdateModalForm1.formRef.current.validateFields()
    promise.then(async(values)=>{
      const res = await reqInitSupplier({...values})
    if (res.errorCode === '200') {
      this.setState({
        registerData: res?.data,
      },async() => {
        this.getUserDataFn()
      })
    }
    if (res.errorCode !== '200') {
      this.setState({
        loading: false
      })
    }
    }).catch((error) => {
      this.setState({
        loading: false
      })
    })
    // const registerParam = this.addOrUpdateModalForm1.formRef.current.getFieldsValue()
    // const res = await reqInitSupplier({...registerParam})
    // if (res.errorCode === '200') {
    //   this.setState({
    //     registerData: res?.data,
    //   },async() => {
    //     this.getUserDataFn()
    //   })
    // }
    // if (res.errorCode !== '200') {
    //   this.setState({
    //     loading: false
    //   })
    // }
  }
  //保存
  saveSupplierInfoFn = async(params) => {
    const param = {
      ...params,
      supplierBaseInfoId: this.state.registerData.supplierBaseInfoId,
    }
    const res = await reqSaveSupplierInfo({...param})
    if (res.errorCode === '200') {
      this.getUserDataFn()
    }
    if (res.errorCode !== '200') {
      this.setState({
        loading: false
      })
    }
  }

  //获取供应商信息
  getUserDataFn = async() => {
    const { registerData, current, checkAreaId } = this.state
    const params = {
      phone: registerData.phone
    }
    const result = await reqGetSupplierInfoByUserPhone({...params})
    if (result.errorCode === '200') {
      this.setState({
        getUserData: result?.data,
        current: current + 1,
        loading: false,
      },() => {
        //公司信息
        const { current,getUserData } = this.state
        if (current === 2 && getUserData) {
          this.showCompanyMsg()
        }
        //供应产品
        if (current === 3 && getUserData) {
          this.getProductAreaId()
        }
        //公司资质证书
        if (current === 4 && checkAreaId !==0) {
          this.uploadForm.current.resetFields()
          this.getCertificateList(checkAreaId)
        }
        if (current === 5) {
          this.props.history.push('/login')
        }
      })
    }
  }

  //获取上传资质文件配置
  getCertificateList = async(areaId) => {
    const res = await reqGetUploadFileConfig({areaId})
    // const res = await reqGetUploadFileConfig({areaIds:[100000]})
    if (res.errorCode !== '200') return
    this.setState({
      certificateList:res?.data
    },() => {
      this.getCertVos()
    })
  }
  //获取联络人的表格信息
  getTableDatas = (data) => {
    this.setState({
      tableDatas: data
    })
  }

  //产品明细-产品分类改变
  handleGetSpecificationChange = async(val,flag,data) => {
    if (Array.isArray(val) && val.length && val.length > 0) {
      val = val.slice(-1)[0]
    }
    const params = {
      configCategoryId: val,
      pageSize,
      currentPage,
    }
    const { pageSize, currentPage } = this.state
    const res = await reqQueryMaterialInfoPage({...params})
    if (res.errorCode !== '200') return
    const resData = res?.data
    if (!flag) {
      this.lzListFormModal.formRef.current.setFieldsValue({materialBaseId: '', materialId:'', unit:''})
    }
    supplyProductsFormModalArrList[1].options = []
    this.setState({
      materialSpecData: []
    })
    supplyProductsFormModalArrList[1].options = resData?.datas.map((item) => ({
      label: item.materialName,
      value: item.materialBaseId,
    }))
    this.setState({
      queryMaterialInfoPage: resData?.datas,
      totalSize: resData?.totalSize,
      current: 3
    },() => {
      if (flag === '2') {
        let list = this.lzListFormModal.formRef.current.getFieldsValue()
        this.lzListFormModal.formRef.current.setFieldsValue({
          ...list,
        })
      }
    })
  }
  //产品明细-产品名称选择改变,获取对应的物料规格
  handleMaterialBaseIdChange = async(val,flag) => {
    const params = {
      materialBaseId: val
    }
    const res = await reqQueryMaterialSpecPage({...params})
    const resData= res?.data
    this.setState({
      materialSpecData: resData?.datas
    }, ()=> {
      if(flag === '1' && this.state.getUserData && this.state.getUserData.productVos && this.state.getUserData.productVos.length) {
        this.state.getUserData.productVos.forEach((item,index) => {
          item.materialSpecStr = this.getmaterialStr(this.state.materialSpecData,item.materialId)
        })
      }
      if (flag === '2') {
        let list = this.lzListFormModal.formRef.current.getFieldsValue()
        this.lzListFormModal.formRef.current.setFieldsValue({
          ...list,
        })
      }
    })
  }

  //规格改变，得到对应的单位
  materialIdChange = (val) => {
    let arr = this.state.materialSpecData.find(item => item.materialId === val)
    this.lzListFormModal.formRef.current.setFieldsValue({unit: arr.unitName})
    this.setState({
      searchForm: true
    })
  }

  onValuesChange = (changedValues, allValues) => {
    this.handleSearchCondition(allValues)
  }

  //搜索按钮单击事件
  onFinish = (values) => {
    this.handleSearchCondition(values)
    this.setState(
      {
        currentPage: 1,
      },
      this.searchSupplierInformationList
    )
  }

  handleSearchCondition = (allValues) => {
    this.searchCondition = allValues
  }

  searchPersonList = async (isInit) => {
  }

  handleShowAdd = () => {
    this.lzListFormModal.formRef.current.resetFields()
    this.setState({
      isAdd: true,
      lzListFormVisible: true
    })
  }

  closeLzListFormModal = () => {
    this.setState({
      lzListFormVisible: false,
      isAdd: false,
      searchForm: false,
      queryMaterialInfoPage: [],
      materialSpecData: []
    })
    supplyProductsFormModalArrList[1].options = []
  }

  handleSubmit =  () => {
    const batchResponseFormInstance =  this.lzListFormModal.formRef.current
    const { isAdd, isEdit, editRecord } = this.state
    batchResponseFormInstance
    .validateFields()
      .then((values) => {
        let obj = Object.assign({},values)
        //处理产品分类中文
        obj.configCategoryName = this.getConfigCategoryStr(this.state.materialClassificationList,obj.configCategoryId)
        //产品名称
        obj.materialBaseName = this.getmaterialBaseStr(this.state.queryMaterialInfoPage,obj.materialBaseId)
        //规格名称
        obj.materialSpecStr = this.getmaterialStr(this.state.materialSpecData,obj.materialId)
        //地址
        obj.areaStr = this.getAreaName(obj.areaId)
        let tableList = []
        if (isAdd) {
          tableList = [...this.state.productsTableDataSource]
          tableList.push({
            ...obj,
            _uuid: getUuid(),
          })
        }
        if (isEdit) {
          tableList = [obj]
        }
        this.setState({
          productsTableDataSource: tableList,
          lzListFormVisible: false,
          isAdd: false,
          isEdit: false
        })
    })
    .catch((error) => console.log(error))
  }

  getConfigCategoryStr = (data,id) => {
    if (Array.isArray(id) && id.length && id.length > 0) {
      id = id.slice(-1)[0]
    }
    let str = ''
    data.forEach(item => {
      if (item.id === id) {
        str = item.label
        return
      }
      if (item.children) {
        this.getConfigCategoryStr(item.children,id)
      }
    })
    return str
  }

  getmaterialBaseStr = (data,id) => {
    let str = ''
    data.forEach(item => {
      if (item.materialBaseId === id) {
        str = item.materialName || ''
        return
      }
    })
    return str
  }

  getmaterialStr = (data,id) => {
    let str = ''
    data.forEach(item => {
      if (item.materialId === id) {
        str = item.materialSpecStr || ''
        return
      }
    })
    return str
  }

  //获取地址名称，地址id无先经过处理的
  getAreaName = (ids) => {
    let str = ''
    if (ids && ids.length > 0) {
      let arealist = this.state.areaList
      ids.forEach(item => {
        let obj = ''
        if (typeof(item) === 'number') {
          obj = arealist.filter(items => items.areaId === JSON.stringify(item))
        }else {
          obj = arealist.filter(items => items.areaId === item)
        }
        if (obj) {
          str+=obj[0].name
        }
      })
    }
    return str
  }

  onChangeFile = async({file},key) => {
    this.setState({
      loading: true
    })
    let isAllowType = isFileTypes(file.name,FILE_TYPE)
    if(!isAllowType){
      message.info(formatMessage(this,'COMMONS_FILE_TYPE_NOT_ACCEPTED'))
      return
    }
    let formData = new FormData()
    formData.append('multipartFile',file)
    const res = await reqFileUpload(formData)
    if (res.errorCode === '200') {
      let items = this.uploadForm.current.getFieldsValue().certItems
      items.forEach(item=> {
        if (item && item.relCertRuleItemId === key) {
          item.fileUrl = res.data
          item.fileName = file.name
        }
      })
      this.uploadForm.current.setFieldsValue({
        certItems: items
      })
    }
    this.setState({
      loading: false
    })
  }

  componentDidMount() {
    const isShowBtn = getPermissionBtn(this.state.isShowBtn, store.getState().authButtonList)
    const {getFactoryAddDeliveryAddressList} = this.props
    this.setState(
      {
        isShowBtn,
      },
      () => {
        //计算表格滚动的高度
        getSearchFormHeight(this)
        const columns = getPersonTableHeader(this)
        getColumnsWidthSum(this, columns, 'columnsWidthSum', 'columns')
        //产品
        const productsColumns = getSupplyProductsTableHeader(this)
        this.actions.forEach((item) => productsColumns.push(item))
        getColumnsWidthSum(this, productsColumns, 'columnsWidthSum', 'productsColumns')
        /*监听语言改变*/
        this.pubSubToken = PubSub.subscribe(PUB_SUB_LANG_CHANGE, () => {
          const productsColumns = getSupplyProductsTableHeader(this)
          this.actions.forEach((item) => productsColumns.push(item))
          getColumnsWidthSum(this, productsColumns, 'columnsWidthSum', 'productsColumns')
        })
        getFactoryAddDeliveryAddressList(this,'factoryCode',this.searchPersonList)
      }
    )
  }

  componentWillUnmount() {
    if (this.pubSubToken) {
      PubSub.unsubscribe(this.pubSubToken)
    }
  }

  render() {
    const { 
      current,
      customtablenotype2,
      interfaceObject2,
      isShowBtn2,
      isShowBtn4,
      lzlistform2,
      lzlistform3,
      columnsWidthSum,
      tableBodyHeight,
      productsTableDataSource,
      getUserData,
      loading,
      lzListFormVisible,
      isAdd,
      isEdit,
      productsColumns,
      certificateList
     } = this.state
    return (
      <div style={{backgroundColor:'#fff'}}>
        <div className='register_box'>
          <Steps current={current}>
            {stepList.map((item,index) => <Step title={item.title} key={item.title}/>)}
          </Steps>
          <div className='stepsContent'>
            {current === 0 && <Card style={{ width: '100%',border:'none'}} title={stepList[current].title}>
              <LFListForm
                that={this}
                wrappedComponentRef={e => this.addOrUpdateModalForm1 = e}
                myRef1={(selfInvoice)=>this.selfInvoice=selfInvoice}
                submitFormArrList={stepList[current].content}
                span={24}
                />
             </Card>}

             {/* 联络人 */}
            {current === 1 && <div>
             <ContactCardContent
                that={this}
                getStepList={stepList}
                title={stepList[1].title}
                customtablenotype={customtablenotype2}
                interfaceObject={interfaceObject2}
                isShowBtn={isShowBtn2}
                lzlistform={lzlistform2}
                getTableData={this.getTableDatas}
                current={1}
                getUserData={getUserData}
              />
            </div>}
            {/* 企业基本信息 */}
            {current === 2 && <div>
            <Card style={{ width: '100%',border:'none'}} title={stepList[2].title}>
              <LFListForm
                that={this}
                wrappedComponentRef={e => this.addOrUpdateModalForm2 = e}
                submitFormArrList={stepList[2].content}
                span={24}
                />
            </Card>
            <Card style={{ width: '100%',border:'none'}} title={stepList[2].title_two}>
              <LFListForm
                that={this}
                wrappedComponentRef={e => this.addOrUpdateModalForm3 = e}
                submitFormArrList={stepList[2].content_two}
                span={24}
                />
            </Card>
            </div>}

            {/* 产品明细 */}
            {
              current === 3 && <div>
              <Card title={stepList[3].title}>
                <CustomTableNoType
                  rowKey={(record) => record.supplierProductInfoId}
                  columns={productsColumns}
                  dataSource={productsTableDataSource}
                  scroll={{x: columnsWidthSum, y: tableBodyHeight}}
                />
              </Card>
              <div className='buts-container'>
                {/* 新增 */}
                {
                  isShowBtn4.addBtn ?
                  <CustomButton id='COMMON_ADD' onClick={this.handleShowAdd}/> :
                  null
                }
              </div>
              <LzListForm
                that={this}
                wrappedComponentRef={e => this.lzListFormModal = e}
                submitFormArrList={supplyProductsFormModalArrList}
                span={lzlistform3.span}
                visible={lzListFormVisible}
                handleSubmit={this.handleSubmit}
                handleClose={this.closeLzListFormModal}
                isModal={true}
                isAdd={isAdd}
                isEdit={isEdit}
              ></LzListForm>
            </div>
            }

          {current === 4 && <Form ref={this.uploadForm} >
            <Card style={{ width: '100%',border:'none',scrollBehavior:'auto'}}>
                <Form.List name='certItems' initialValue={[]}>
                {(fields, { add, remove }) => (
                  <>
                  {certificateList.map((item, key) => {
                    return (
                    <div>
                      <p style={{fontSize:'18px',fontWeight:'bold'}}>{item.certTypeName}</p>
                     <>
                      {(item.cretItems).map((childItem,childKeys) => {
                          return (
                            <div key={childKeys}>
                              <Form.Item label={childItem.certName}>
                                <Form.Item key={childItem.certRuleDetailId} initialValue={childItem.certRuleDetailId}>
                                  <Row>
                                    <Col>
                                      <Form.Item name={[childItem.certRuleDetailId,'fileName']} className="Item" label={formatMessage(this,'COMMONS_UPLOAD_TEMPLATE')} initialValue={childItem.fileName}>
                                        <Input disabled/>
                                      </Form.Item>
                                    </Col>
                                    <Col>
                                      <Form.Item colon={false}>
                                        <Upload fileType={FILE_TYPE} showUploadList={false} onChange={(val)=>this.onChangeFile(val,childItem.certRuleDetailId)} beforeUpload={()=>false} multiple={false}>
                                          <Button><FormattedMessage id='COMMONS_SELECT_FILE'/></Button>
                                        </Upload>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                </Form.Item>
                                <Space>
                                  <Form.Item key={childItem.certRuleDetailId} name={[childItem.certRuleDetailId,'remark']} label={formatMessage(this,'COMMONS_FILE_NOTES')} initialValue={childItem.remark}>
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item key={childItem.certRuleDetailId} name={[childItem.certRuleDetailId,'validDate']} label={formatMessage(this,'COMMONS_PERIOD_OF_VALIDITY')}>
                                    <RangePicker/>
                                  </Form.Item>
                                  <Form.Item key={childItem.certRuleDetailId} name={[childItem.certRuleDetailId,'fileUrl']} style={{display:'none'}} initialValue={childItem.templateFileUrl}>
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item key={childItem.certRuleDetailId} name={[childItem.certRuleDetailId,'certFileType']} style={{display:'none'}} initialValue={childItem.certTypeId}>
                                    <Input/>
                                  </Form.Item>
                                  <Form.Item key={childItem.certRuleDetailId} name={[childItem.certRuleDetailId,'relCertRuleItemId']} style={{display:'none'}} initialValue={childItem.certRuleDetailId}>
                                    <Input/>
                                  </Form.Item>
                                </Space>
                              </Form.Item>
                          </div>
                          )
                        })}
                     </>
                    </div>
                    )
                    })}
                  </>
                  )}
                  </Form.List>
              </Card>
            </Form>}
          </div>

          <div style={{ marginTop: '24px' }}>
            {current > 0 && (
              <Button style={{ margin: '0 8px' }} onClick={this.prev}>
                上一步
              </Button>
            )}
            {current < stepList.length - 1 && (
              <Button type="primary" style={{ margin: '0 8px' }} onClick={this.next} loading={loading}>
                下一步
              </Button>
            )}
            {current === 3 && (
              <Button onClick={this.handleSkip}>
                跳过
              </Button>
            )}
            {current === stepList.length - 1 && (
              <Button type="primary" onClick={this.next}>
                完成
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
};
export default injectIntl(
  connect((state) => ({ lang: state.lang }), {
    getFactoryAddDeliveryAddressList
  })(Register)
)
