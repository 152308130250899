/**
 * 能发送异步ajax请求的函数模块
 * 封装axios库
 * 函数返回的是promise对象
 *
 * 1.优化：统一处理请求异常
 * 在外层包一层自己创建promise的对象
 * 在请求出错时，不去reject，而是提示错误信息
 *
 */
import axios from 'axios'
import {getUserCache,removeUserCache} from '../utils/memory/memory'
import { removerAllKeys } from '../utils/disk-cache/disk-cache'
import {message} from "antd";
import store from '../store/store'
import {receiveLoading} from '../store/createActions'
import { debounce } from '../utils/util'
import en_US from "../locale/en_US/en_US";
import zh_CN from "../locale/zh_CN/zh_CN";
import {receivePermissions,receivePurchaserCodeList,receiveDeliveryAddressList,receiveFactoryList,receiveAuthButtonList} from '../store/createActions'
//创建axios实例
const axiosInstance = axios.create({
    // baseURL: baseURL, // api的base_url
    timeout: 900000, // 请求超时时间
    // withCredentials: true // 选项表明了是否是跨域请求
})

export default function ajax(url, data = {}, type = "GET", isLoading=true,ContentType='',config = {}) {
  if (type === 'GET') {
    return axiosInstance.get(url, {//配置对象
      params: data, //请求参数
      loading:isLoading,
      ContentType:ContentType,
      ...config
    });
  } else if (type === 'DELETE') {
    return axiosInstance({
      url: url,
      method: 'delete',
      params: data, //请求参数
      loading:isLoading,
      ContentType:ContentType,
      ...config
    })
  }else {
    return axiosInstance({
      loading:isLoading,
      method: 'post',
      ContentType:ContentType,
      url: url,
      data: data,
      ...config
    })
  }
}

//请求拦截器
axiosInstance.interceptors.request.use(
  config => {
      let lang = store.getState().lang==="en"?"en_US":"zh_CN";
      //获取用户信息
      const user = getUserCache();
      //显示加载动画
      if (config.loading) {
        store.dispatch(receiveLoading(true));
      }
      config.headers['tenantUrl']='192.168.0.222:'+ window.location.port
      if (user && user.username) {
        // 设置统一的请求header
        config.headers['Cache-Control']='no-cache'//Pragma: no-cache
        config.headers['If-Modified-Since']='0'//Pragma: no-cache
        // config.headers['Cache-Control']='no-cache'
        config.headers["token"] = user.access_token //授权(每次请求把token带给后台)
        if(config.ContentType){
          config.headers['Content-Type'] = config.ContentType
        }
      }
      config.url = config.url+"?lang="+lang;
      return config;
  },
  error => {
      //关闭加载动画
      store.dispatch(receiveLoading(false))
      return Promise.reject(error);
  }
);

// 响应拦截器
axiosInstance.interceptors.response.use(
  response => {
      //关闭加载动画
      store.dispatch(receiveLoading(false))
      let result = response.data
      if (result && result.errorCode) {
          switch (result.errorCode) {
              case "200":
                return result
              // 401: 未登录/token过期
              // 未登录则跳转登录页面，并携带当前页面的路径
              // 登录过期对用户进行提示
              // 清除本地token和清空redux中的数据
              // 跳转登录页面
              case "401":
                tokenOverdueFn()
                break
               // 403无权限
              case "403":
                //没有访问权限
                message.info(intlErrorMessage('COMMON_NO_ACCESS'))
                break
              // 404请求不存在
              case "404":
                // 网络请求资源地址不存在
                message.info(intlErrorMessage('COMMON_REQUEST_NOT_EXIST'))
                break
              // 500请求接口内部异常
              case "500":
                message.error(result.msg,5)
                break
              // 50001导入数据报错，要求返回错误换行
              case "50001":
                message.error(result.msg,5)
                break
              case "5000":
                message.error(result.msg,5)
                break
              // 其他错误，直接抛出错误提示
              default:
                  if(result.msg){
                      message.info(result.msg,5)
                  }
                break
          }
          return result
      }else {
          return result
      }
  },
  //接口出错
  error => {
      //关闭加载动画
      store.dispatch(receiveLoading(false))
      // errorMsgFn()
      // if (error.response.data.trace) {
      //   debounce(message.error(error.response.data.trace.slice(0, 1000)), 500)
      // } else {
      //   // errorMsgFn()
      //   debounce(message.error(error.response.data.error), 500)
      // }
      return error
  }
);

const intlErrorMessage=(id)=>{
  const lang = store.getState().lang
  return lang === 'en'?en_US[id]:zh_CN[id]
}

const tokenOverdue = ()=>{
  message.info({
    // content:'token过期,请重新登录',
    content:intlErrorMessage('COMMON_TOKEN_HAS_EXPIRED'),
    duration:2,
    onClose: () => {
      // const id_token_hint = getUser()?.idToken
      removerAllKeys()
      // 清除token和用户信息
      removeUserCache();
      receivePermissions({
        menuPermissions: []
      })
      receivePurchaserCodeList([])
      receiveDeliveryAddressList([])
      receiveFactoryList([])
      receiveAuthButtonList([])
      // adfsLogout(id_token_hint)
    }
  })
}
// const errorMsg = () =>{
//   message.error(intlErrorMessage('COMMON_NET_ERROR'))
// }

// const tokenOverdueFn = debounce(tokenOverdue,800)
const tokenOverdueFn = (() => debounce(tokenOverdue,800))
// const errorMsgFn= debounce(errorMsg,1000)
