import React from 'react'
import {Form, Select} from "antd";
import {formatMessage,setFactoryList} from "../../utils/util";
import {injectIntl} from "react-intl";
const {Option} = Select

class FactorySelectList extends React.Component {
  componentDidMount() {
    const {that,name} = this.props
    /*监听公司改变,并设置工厂*/
    setFactoryList(that,name)
  }

  render() {
    const {factoryList,name,labelCol,wrapperCol,rules,initialValue, disabled, filterFn} = this.props
    let tempFactoryList = JSON.parse(JSON.stringify(factoryList))

    if (typeof filterFn === 'function') {
      tempFactoryList = tempFactoryList.filter(filterFn)
    }

    tempFactoryList.forEach(item=>{
      if (item.factoryName === "全部") {
        item.factoryName = formatMessage(this,'COMMON_ALL')
      }
    })
    return (
      <Form.Item rules={rules} name={name} labelCol={labelCol} wrapperCol={wrapperCol} label={formatMessage(this,'COMMON_FACTORY')}  initialValue={initialValue}>{/*工厂*/}
        <Select disabled={disabled}>
          {
            tempFactoryList && tempFactoryList.length?tempFactoryList.map(item=><Option value={item.factoryCode} key={item.factoryCode}>{item.factoryName}</Option>):null
          }
        </Select>
      </Form.Item>)
  }
}

export default injectIntl(FactorySelectList)
