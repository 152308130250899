import React from 'react'
import { Form, DatePicker } from "antd";
import { formatMessage } from "../../utils/util";
import { injectIntl } from "react-intl";

class DatePickerDate extends React.Component {
  render() {
    const {name,label,initialValue,rules,labelCol,wrapperCol,disabled,style,format,showTime,disabledDate,picker} = this.props
    return (
      <Form.Item name={name} label={formatMessage(this,label)} initialValue={initialValue} rules={rules} labelCol={labelCol} wrapperCol={wrapperCol}>
        <DatePicker style={style ? style : {width: '100%'}} disabled={disabled} format={format} showTime={showTime} disabledDate={disabledDate} picker={picker}/>
      </Form.Item>
      )
  }
}

export default injectIntl(DatePickerDate)