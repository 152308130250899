import React,{Component} from 'react'
import {Table } from 'antd'
// import {getRowClassName} from "../../utils/util";

class CustomTableNoType extends Component{
  //设置表格的背景隔行变色，合并单元格算一行
  getRowClassName = (record, index,isMergerTable) => {
    const {mergeDataIndex} = this.props
    let {_dataSource} = this
    /*
    i 行code 与 i-1 行code相等 且 i 行oddEven = ‘ 0 ’，那 i 行 和 i+1 行 的 oddEven = ‘ 0 ’，
    i 行code 与 i-1 行code相等 且 i 行oddEven = ‘ 1 ’，那 i 行 和 i+1 行 的 oddEven = ‘ 1 ’，
    i 行code 与 i-1 行code不等 且 i 行oddEven = ‘ 0 ’，那 i+1 行 的 oddEven = ‘ 1 ’，
    i 行code 与 i-1 行code不等 且 i 行oddEven = ‘ 1 ’，那 i+1 行 的 oddEven = ‘ 0 ’，
     */
    let className = '';
    if(isMergerTable && index < _dataSource.length){//需要合并单元格的表格
      if(index===0){
        className = index % 2 === 0 ? 'oddRow': 'evenRow';
      }else if(_dataSource[index][mergeDataIndex] === _dataSource[index-1][mergeDataIndex] ){//i行的合并标识与i+1合并的标识一样
        // 那么i行和i-1行的className为'oddRow',否则为className为'evenRow'
        className = _dataSource[index-1].className === 'oddRow' ? 'oddRow': 'evenRow';
      }else{//i行的合并标识与i+1合并的标识不一样
        // 那么i行和i-1行的className为'evenRow',否则为className为'oddRow'
        className = _dataSource[index-1].className === 'evenRow' ? 'oddRow': 'evenRow';
      }
      this._dataSource[index].className = className

    }else {
      className = index % 2 === 0 ? 'oddRow': 'evenRow';
    }
    return className;
  }
  render(){
    let {summary,dataSource,scroll,rowKey,components,columns,isMergerTable,onChange,columnsItems,showIndex} = this.props
    this._dataSource = [...dataSource]
    const expandedRowRender = (record) => {
      return <Table columns={columnsItems} dataSource={record.items} bordered pagination={false} />
    }
    return(
      <Table
        size='middle'
        className={`srm-table notEditTable`}
        rowKey={rowKey}
        dataSource={dataSource}//表格内容
        columns={columns}//表头数据
        scroll={scroll}
        components={components}
        pagination={false}
        summary={summary}
        bordered
        rowClassName={(record, index)=>this.getRowClassName(record, index,isMergerTable)}
        onChange={onChange}
        expandable={showIndex > -1 ?{
          expandedRowRender,
          rowExpandable:(record) => {return record.items.length}
        } : ''}

      />
    )
  }
}
export default CustomTableNoType
