import React from 'react'
import {Form, Input } from "antd";
import {injectIntl} from "react-intl";
import {formatMessage} from '../../utils/util'
import {getValueFromEvent,} from "../../utils/util";
class CustomInput extends React.Component{
  render() {
    const {name,initialValue,placeholder,labelId,placeholderId,inputDisabled,colon, labelCol, wrapperCol, rules, style, maxLength, onBlur, keyPress, onFocus, extra} = this.props
    return (
      <Form.Item style={style} name={name} rules={rules} colon={colon!==false} labelCol={labelCol} wrapperCol={wrapperCol} label={labelId?formatMessage(this,labelId):''} initialValue={initialValue} getValueFromEvent={getValueFromEvent} extra={extra}>
        <Input
          placeholder={placeholder ? placeholder : formatMessage(this,placeholderId)}
          disabled={inputDisabled}
          allowClear
          maxLength={maxLength}
          onBlur={onBlur}
          onKeyPress={keyPress}
          onFocus={onFocus}
        />
      </Form.Item>
    )
  }
}

export default injectIntl(CustomInput)