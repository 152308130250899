import React, { Component } from 'react'
import { Form, Button, Modal, Input, Row, Col } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl } from 'react-intl'
import {
  formatMessage,
  SHEN_MAI_FACTORY_LIST,
  SHEN_MAI_KE_FACTORY_LIST,
  NAN_MAI_FACTORY_LIST,
  NAN_MAI_SW_FACTORY_LIST,
  SHEN_MAI_KE_BUKRS,
  NAN_MAI_BUKRS,
  NAN_MAI_SW_BUKRS,
  SHEN_MAI_BUKRS,
  computedBodyWidth,
} from '../../utils/util'
import {
  reqPasswordFreeLogin,
  getMenuInfos,
  getNewMenuInfos,
  reqCompany,
  getSingleSupplierByUserCode,
  getHasAuthButtonListApi,
} from '../../api'
import { removeUserCache, saveUserCache } from '../../utils/memory/memory'
import { removerAllKeys, saveUser } from '../../utils/disk-cache/disk-cache'

import './login.less'
import './rules-modal.less'
import logo from './images/logo.png'
import {
  receiveDeliveryAddressList,
  receiveFactoryList,
  receiveLang,
  receivePurchaserCodeList,
  receiveAuthButtonList,
} from '../../store/createActions'
import { getBrowseLang } from '../../utils/util'
import { setCookie, getCookie, clearCookie } from '../../utils/cookie/cookie'
import imgLarge from './images/img-large.png'
import imgLogin1 from './images/ImgDBLoginBG1.png'
import imgLogin2 from './images/ImgDBLoginBG2.png'
import imgLogin3 from './images/ImgDBLoginBG3.png'
import imgLogin4 from './images/ImgDBLoginBG4.png'
import imgLogin5 from './images/ImgDBLoginBG5.png'
import { receivePermissions } from '../../store/createActions'


/**
 * 免登录的路由组件
 */
class LoginNoAuth extends Component {
  loginFormRef = React.createRef()
  state = {
    captchaUrl: '',
    captchaKeyId: '',
    rulesVisible: false,
    loginTypeList: [
      {key:'email_login',name:'邮箱登录'},
      {key:'acc_login',name:'手机登录'},
      {key:'sms_login',name:'短信登录'},
    ],
    typeKey: 'email_login',
    codeUrl: '',
  }
  //拒绝规则
  handleCloseRulesModal = () => {
    this.setState({
      rulesVisible: false,
    })
    removerAllKeys()
  }

  //同意规则
  handleConfirm = () => {
    this.setState({
      rulesVisible: false,
    })
    this.handlePermission()
  }

  changeLang = () => {
    const { lang, receiveLang } = this.props
    lang === 'en' ? receiveLang('zh') : receiveLang('en')
  }

  onFinish = (async (values) => {
      const {receiveFactoryList} = this.props
      const {typeKey} = this.state
      let user = {};
      // user.userType = '2'
      // user.username = '80600778'
      const userName = values.username
      user.username = userName;
      // const response =  await reqPasswordFreeLogin({params: {code: userName}})
      const response =  await reqPasswordFreeLogin({loginType:typeKey,...values})
      if (response.errorCode !== '200') {
        return
      }
      user.username = response.data.userName
      user.access_token = response.data.access_token;
      user.userType = response.data.userType;
      user.empNo = response.data.empNo;
      user.supplierCode = response.data.supplierCode
      if(response.data.defaultCompany){
        user.defaultCompany = response.data.defaultCompany;
      }else{
        /*切换公司*/
        // user.defaultCompany = '切换公司'
        user.defaultCompany = formatMessage(this,"COMMON_CHANGE_COMPANY")
      }
      saveUserCache(user);
      saveUser(user);
      if (response.data.userType === '1') {
        const res = await reqCompany({code: user.username})
        const res1 = await getSingleSupplierByUserCode({params: {logonId: user.username}})
        if(res.errorCode === '200') user.userCompany = res.data
        if (res1.errorCode === '200') {
          user.supplierDzItemVoList = res1?.data?.supplierDzItemVoList || []
        }
      }
      this.tempUser= user
      if (user.userType === '1') {
        let factoryList
        let supplierDzItemVo
        const supplierDzItemVoList = user.supplierDzItemVoList || []
        let componany = '深迈科'
        /*南京迈瑞：NAN_MAI_FACTORY_LIST;南京生物：NAN_MAI_SW_FACTORY_LIST;深迈科:SHEN_MAI_KE_FACTORY_LIST;迈瑞公司:SHEN_MAI_FACTORY_LIST*/
        /* 南京迈瑞: 1001; 南京生物: 1040; 深迈科: 1910; 迈瑞公司: 1000 */
        switch (response.data.defaultCompany){
          case '深迈科':
            factoryList = SHEN_MAI_KE_FACTORY_LIST
            supplierDzItemVo = supplierDzItemVoList.find(item => item.bukrs === SHEN_MAI_KE_BUKRS)
            user.ztype = supplierDzItemVo?.ztype || ''
            user.bukrs = SHEN_MAI_KE_BUKRS
            break
          case '南京迈瑞':
            factoryList = NAN_MAI_FACTORY_LIST
            supplierDzItemVo = supplierDzItemVoList.find(item => item.bukrs === NAN_MAI_BUKRS)
            user.ztype = supplierDzItemVo?.ztype || ''
            user.bukrs = NAN_MAI_BUKRS
            break
          case '南京生物':
            factoryList = NAN_MAI_SW_FACTORY_LIST
            supplierDzItemVo = supplierDzItemVoList.find(item => item.bukrs === NAN_MAI_SW_BUKRS)
            user.ztype = supplierDzItemVo?.ztype || ''
            user.bukrs = NAN_MAI_SW_BUKRS
            break
          default://迈瑞公司
            factoryList = SHEN_MAI_FACTORY_LIST
            supplierDzItemVo = supplierDzItemVoList.find(item => item.bukrs === SHEN_MAI_BUKRS)
            user.ztype = supplierDzItemVo?.ztype || ''
            user.bukrs = SHEN_MAI_BUKRS
            break
        }
        
        receiveFactoryList(factoryList)
        saveUserCache(user);
        saveUser(user);
        this.setState({
          rulesVisible:true
        })
      }else{
        this.handlePermission()
      }
  })
  handlePermission = () => {
    /*获取左侧菜单栏*/
    this.getPermission(this.tempUser)
    this.getHasAuthButtonListApi()
    if (this.tempUser.username) {
      setCookie('key', this.tempUser.username)
      this.props.history.replace('/home')
    }
  }
  //权限按扭列表
  getHasAuthButtonListApi = async () => {
    const result = await getHasAuthButtonListApi({ userName: this.tempUser.username })
    let userAuthButtonList = []
    if (result.errorCode === '200') {
      userAuthButtonList = result?.data || []
    }
    this.props.receiveAuthButtonList(userAuthButtonList)
  }
  getPermission = async (values) => {
    // const menuResponse = await getMenuInfos(null)
    const systemFlag = 'srm'
    const menuResponse = await getNewMenuInfos({systemFlag})
    const result = menuResponse.data
    let userPermissions = {
      menuPermissions: [],
    }
    if (menuResponse.errorCode === '200') {
      userPermissions.menuPermissions = result || []
    }
    this.props.receivePermissions(userPermissions)
  }
  componentDidMount() {
    document.getElementById('systemTitle').innerText = '登录'
    this.props.receiveLang(getBrowseLang())
    //图片验证码
    this.getCode()
  }

  handleOnMouseEnter = (key) => {
    this.setState({
      typeKey: key
    })
    // this.loginFormRef.current.resetFields()
  }

  handelForget = () => {
    // this.props.history.push('/forgetPassword')
  }

  getPhoneCode = () => {

  }

  getCode = async() => {

  }

  goRegister = () => {
    this.props.history.push('/register')
  }
  render() {
    const { rulesVisible, loginTypeList, typeKey, codeUrl } = this.state
    if (rulesVisible) {
    } else if (!rulesVisible) {
    } else if (getCookie('key')) {
      this.props.history.goBack('/home')
      return
    } else {
      clearCookie('key', '')
      removerAllKeys()
      removeUserCache()
      const { receivePermissions, receiveFactoryList, receiveDeliveryAddressList, receivePurchaserCodeList, receiveAuthButtonList } = this.props
      receivePermissions({
        menuPermissions: [],
      })
      receiveFactoryList([])
      receiveDeliveryAddressList([])
      receivePurchaserCodeList([])
      receiveAuthButtonList([])
    }
    const { lang } = this.props
    const initialValues = { captcha: '', username: '', password: '' }
    return (
      <div className='login'>
        {/* <header className='login-header'> */}
          {/* <img src={logo} alt='logo' className='logo' /> */}
          {/* <span className='language' onClick={this.changeLang} >
            {lang === 'en' ? '中文' : 'EN'}
          </span> */}
          {/* <h1>管理系统</h1> */}
        {/* </header> */}
        {/* <img src={imgLogin1} style={{position:'absolute',left: '102px',top:'121px',width:'246px'}}/>
        <img src={imgLogin2} style={{position:'absolute',left: '112px',top:'239px',width:'43px'}}/>
        <img src={imgLogin3} style={{position:'absolute',left: '0px',bottom:'208px',width:'75px'}}/>
        <img src={imgLogin4} style={{position:'absolute',right: '300px',top:'82px',width:'75px'}}/>
        <img src={imgLogin5} style={{position:'absolute',right: '0',top:'300px',width:'300px'}}/> */}
        <section className='login-container'>
          <div className='login-content'>
            {/* <img src={imgLarge} alt='' className='img-large' /> */}
            <img src={imgLogin5} alt='' className='img-large' />
            <div className='form-container'>
              {/*SRM管理平台*/}
              <h2>
                <FormattedMessage id='LOGIN_TITLE' />
              </h2>
              {/* <div className='login-name'><FormattedMessage id='LOGIN_SRM_TITLE' /></div> */}
              {/* <p className='text-center'>V1.0.1.0</p> */}
                {
                  loginTypeList.map(item => {
                    return(
                      <span key={item.key} className={item.key === this.state.typeKey ? 'selectItem-hover' : 'selectItem'} onMouseEnter={() => this.handleOnMouseEnter(item.key)}>
                        {item.name}
                      </span>
                    )
                  })
                }
              <Form
                className='login-form'
                size='large'
                initialValues={initialValues}
                ref={this.loginFormRef}
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
              >
                {typeKey === 'email_login' &&
                <>
                {/*邮箱*/}
                <Form.Item name='userEmail' rules={[{required: false,whitespace: true,message: <FormattedMessage id='LOGIN_MAILBOX'/>}]} label={formatMessage(this,'LOGIN_MAILBOX')}>
                  <Input  
                    tabIndex={1}
                    prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder={formatMessage(this, 'LOGIN_MAILBOX')}
                  />
                </Form.Item>
                {/*密码*/}
                <Form.Item name='emailPassWord' rules={[{required: false,message: <FormattedMessage id='LOGIN_INPUT_PASSWORD'/>}]} label={formatMessage(this,'LOGIN_PASSWORD')}>
                  <Input.Password tabIndex={2} prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                    placeholder={formatMessage(this,'LOGIN_PASSWORD')}/>
                </Form.Item>
                {/* 忘记密码 */}
                <span className='forget-span' onClick={this.handelForget}>忘记密码？</span>
                </>
                }
                {typeKey === 'acc_login' &&
                  <>
                  {/*手机*/}
                  <Form.Item name='userPhone' rules={[{required: false,whitespace: true,message: <FormattedMessage id='LOGIN_MOBILE_PHONE'/>}]} label={formatMessage(this,'LOGIN_MOBILE_PHONE')}>
                    <Input
                      tabIndex={1}
                      prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={formatMessage(this, 'LOGIN_MOBILE_PHONE')}
                    />
                  </Form.Item>
                  {/*密码*/}
                  <Form.Item name='passWord' rules={[{required: false,message: <FormattedMessage id='LOGIN_INPUT_PASSWORD'/>}]} label={formatMessage(this,'LOGIN_PASSWORD')}>
                    <Input.Password tabIndex={2} prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                      placeholder={formatMessage(this,'LOGIN_PASSWORD')}/>
                  </Form.Item>
                  {/* 忘记密码 */}
                  <span className='forget-span' onClick={this.handelForget}>忘记密码？</span>
                  </>
                }
                {typeKey === 'sms_login' &&
                  <>
                  {/*手机*/}
                  <Form.Item name='userPhone' rules={[{required: false,whitespace: true,message: <FormattedMessage id='LOGIN_MOBILE_PHONE'/>}]} label={formatMessage(this,'LOGIN_MOBILE_PHONE')} labelCol={{span:4}}>
                    <Input
                      tabIndex={1}
                      prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder={formatMessage(this, 'LOGIN_MOBILE_PHONE')}
                    />
                  </Form.Item>
                  {/*验证码*/}
                  <Form.Item name='code' rules={[{required: false,message: <FormattedMessage id='LOGIN_CODE'/>}]} label={formatMessage(this,'LOGIN_CODE')} labelCol={{span:4}}>
                    <Row>
                      <Col span={15}>
                        <Input.Password tabIndex={2} prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                          placeholder={formatMessage(this,'LOGIN_CODE')}/>
                      </Col>
                      <Col span={9}>
                        <div className='code-box'>
                            <img className='code-img' src={codeUrl} alt='' onClick={this.getCode}></img>
                        </div>
                      </Col>
                    </Row>
                  </Form.Item>
                  {/* 短信码 */}
                  <Form.Item name='smsCode' rules={[{required: false,message: <FormattedMessage id='LOGIN_SMS_CODE'/>}]} label={formatMessage(this,'LOGIN_SMS_CODE')} labelCol={{span:4}}>
                    <Row>
                      <Col span={15}>
                        <Input.Password tabIndex={3} prefix={<LockOutlined style={{color: 'rgba(0,0,0,.25)'}}/>}
                          placeholder={formatMessage(this,'LOGIN_SMS_CODE')}/>
                      </Col>
                      <Col span={4}>
                        <Button onClick={this.getPhoneCode}>
                          <FormattedMessage id='LOGIN_OBTAIN_VERIFICATION_CODE'/>
                        </Button>
                      </Col>
                    </Row>
                  </Form.Item>
                  </>
                }
                <Form.Item>
                  <Button type='primary' htmlType='submit' className='login-form-button'>
                    <FormattedMessage id='LOGIN_LOGIN'/>
                  </Button>
                </Form.Item>
              </Form>
              <p className='tip-box'>
                <span>No account? sign up </span><span style={{color:'blue'}} onClick={this.goRegister}>click me</span>
              </p>
            </div>
          </div>
          {/*© 2020 深圳生物医疗电子股份有限公司 版权所有*/}
          {/* <p className='login-foot'>
            <FormattedMessage id='COMMON_COPYRIGHT' />
          </p> */}
          {/*规则弹层*/}
          <Modal
            title='提示'
            className='rules-modal'
            visible={rulesVisible}
            width={computedBodyWidth() * 0.8}
            // height={computedBodyHeight()*0.8}
            onCancel={this.handleCloseRulesModal}
          >
            <h2 className='title'>SRM 系统使用与帐号、密码管理与声明条款</h2>
            <div className='content-wrap'>
              <p className='rules-title'>总 则</p>
              <p className='rules-content'>
                欢迎您访问和使用供应商关系管理系统（以下简称“SRM系统”或“系统”）。
                系统致力于在与供应商之间建立并维持长久、紧密伙伴关系，提高交易效率与安全性，
                是一种以“扩展协作互助的伙伴关系、共同开拓和扩大市场份额、实现双赢”为导向的企业资源获取管理的系统工具。
              </p>
              <p className='rules-content'>
                在使用本系统前，请您仔细阅读以下各项使用条款。如果您打算继续浏览和使用本系统，
                表明您同意遵守以下使用条款；如果您无法接受以下条款，请务必以书面方式致函，以便寻求替代方案。
                {/* 深圳生物医疗电子股份有限公司及其子公司、分公司、代表处和相关关联公司（“”） */}
                保留随时修改这些条款的权利、并在系统中更新修改后的条款。
              </p>
              <p className='rules-title'>1、系统用途</p>
              <p className='rules-content'>
                您将使用自行设置并保管密码的唯一帐号登录SRM系统查询下达的电子采购订单情况，
                并在订单下达之时起48小时内点击“订单确认并生成PDF”按钮（逾期确认的属于未生效订单）接受相关订单，
                被您接受的订单是确认双方权利义务内容的法律文件，您有义务照单生产交付。
                您必须妥善保管好SRM系统登录帐号及密码，因帐号及密码外泄、被冒用等任何原因给造成损失的，
                将追究您的法律责任
              </p>
              <p className='rules-title'>2、账号申请</p>
              <p className='rules-content'>
                在您与成为的供应商后，将获得一个登陆账号；该账号在您与合作期间有效。
                账号的初始密码由提供，您有义务在获得账号与密码后随即进行登陆、并修改初始密码。
              </p>
              <p className='rules-title'>3、密码重置</p>
              <p className='rules-content'>
                {' '}
                如您因忘记密码，或在1小时内连续5次错误输入密码导致账号被锁定，请立即与对口您的责任采购工程师联系，
                责任采购工程师将协助您进行密码重置。密码重置后，您仍然有义务随即登陆系统并修改密码。
              </p>
              <p className='rules-content'>
                您在任何时候发现登陆异常的，务必立即以书面方式与取得联系，以便及时关闭系统；
                否则，凡通过您账号做出的任何行为均视为您本人的行为。
              </p>
              <p className='rules-title'>4、授权责任人</p>
              <p className='rules-content'>
                您有义务在您企业内部建立完整的SRM系统使用制度，
                只得授权唯一责任人保管SRM系统登陆密码，并由该授权人员进行订单确认及信息的回复。
                若授权责任人有变动，您必须立即以书面形式通知责任采购工程师进行密码重置、并重新指定授权责任人，
                否则因系统被冒用、滥用等任何原因引起的不利后果均由您承担。
              </p>
              <p className='rules-title'>5、数据丢失</p>
              <p className='rules-content'>
                可能在任何时间对SRM系统进行升级或更新，
                但受限于技术水平或意外事件等原因无法完全排除在升级或更新过程中可能造成历史数据的丢失。
                在您一旦接受使用SRM系统，即代表您已知晓该等风险的重要提示并能承受该等风险的客观存在，
                且接受在发生该等风险时的免责。
              </p>
              <p className='rules-title'>6、禁止盗链</p>
              <p className='rules-content'>
                禁止未经授权的指向本系统的超文本链接，
                以及擅自利用本系统内容的网页构架系统的行为。保留断开任何未经授权指向本系统的超文本链接或网页构架系统的权利。
              </p>
              <p className='rules-title'>7、知识产权声明</p>
              <p className='rules-content'>
                本系统，包括(但不仅限于)网页设计、结构、文字、图像、
                商标和/或服务标志（包括已注册和未注册的），以及其他资料（以下简称系统内容）都受到中国或其他国家与地区的版权法、商标法和其他知识产权法律或公约的保护。
                您不得自行、许可或纵容第三方将本系统任何内容复制、重组、编译、出版、上载并以此为范本创作、
                转载以及任何其他方式使用于任何其他网站。在法律许可的最大范围内，有权采取任何可能的措施（包括适用刑事法律）
                积极保护知识产权。
              </p>
              <p className='rules-title'>8、保密</p>
              <p className='rules-content'>
                您与通过SRM系统传递的所有信息或数据均属于需要
                保密的重要经营信息，您有义务采取合理的保密措施以防止未经授权披露、复制或使用该等保密信息；
                对保密信息的使用受您与签订的《保密协议》的约束。
              </p>
              <p className='rules-title'>9、责任限制</p>
              <p className='rules-content'>
                任何情况下，对于SRM系统导致的或由于使用此系统或由于使用链接到、
                引用此系统或通过此系统访问的任何站点或资源，或者由于使用、下载或访问任何资料、信息、
                产品或服务而导致的或与之相关的任何直接的、间接的、附带的、特别的、惩戒性的或任何种类的后果性的损害赔偿，
                包括但不限于任何利润的损失、商业中断、可节省金额的损失或者程序或其他数据的丢失，
                一旦您接受使用本系统即代表您接受不就前述损坏赔偿向您负责，即使已被明确告知可能有此类损害赔偿时，
                也是如此。这种关于责任的排除和放弃适用于所有诉因，无论是基于合同、保证、民事侵权行为还是任何其他法律理论。
              </p>
              <p className='rules-title'>10、条款构成</p>
              <p className='rules-content'>
                {' '}
                本条款（及根据本条款使用SRM系统形成的电子数据）
                构成您与双方签署的《采购框架协议》、《供应商质量保证协议》、《保密协议》等法律文件不可分割的组成部分、
                具有同等法律效力。
              </p>
            </div>
            <div className='form-foot'>
              <Button type='primary' onClick={this.handleConfirm}>
                接受
              </Button>
              <Button onClick={this.handleCloseRulesModal}>拒绝</Button>
            </div>
          </Modal>
        </section>
      </div>
    )
  }
}

export default injectIntl(
  connect((state) => ({ lang: state.lang }), {
    receiveFactoryList,
    receiveLang,
    receivePermissions,
    receiveDeliveryAddressList,
    receivePurchaserCodeList,
    receiveAuthButtonList,
  })(LoginNoAuth)
)
