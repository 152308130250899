
//老系统的url-prefix  测试
// export  const oldUrlPreDev = 'https://srm-qas.mindray.com';
// export  const oldUrlPreDev = 'http://srm-qas.mindray.com';
export  const oldUrlPreDev = 'http://47.106.152.98';

//老系统的url-prefix  生产
export  const oldUrlPrePrd = 'https://47.106.152.98';

// console.log('process.env.NODE_ENV',process.env.NODE_ENV)
// export  const oldUrlPre = process.env.NODE_ENV === 'production' ? oldUrlPrePrd : oldUrlPreDev

export const oldUrlPre = oldUrlPreDev // 测试环境
// export const oldUrlPre = oldUrlPrePrd // 正式环境
