/*登录页面中文*/
const LOGIN_ZH ={
  LOGIN_USER_NAME:'请输入用户名',
  LOGIN_INPUT_PASSWORD:'请输入密码',
  LOGIN_NAME:'账号',
  LOGIN_PASSWORD:'密码',
  LOGIN_LOGIN:'登录',
  LOGIN_INPUT_VERIFY_CODE:'请输入验证码',
  LOGIN_TITLE:'企业登录',
  LOGIN_MAILBOX:'邮箱',
  LOGIN_MOBILE_PHONE:'手机',
  LOGIN_CODE:'验证码',
  LOGIN_SMS_CODE:'短信码',
  LOGIN_OBTAIN_VERIFICATION_CODE:'获取验证码',
  LOGIN_SRM_TITLE:'汐帆信息',
  LOGIN_RETRIEVE_PASSWORD:'找回密码',
}

export default LOGIN_ZH
