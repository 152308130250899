import React from 'react'
import { Form, Select } from "antd";
import { formatMessage, onPageNumChange, PAGE_SIZE } from "../../utils/util";
import { injectIntl } from "react-intl";
import CustomPagination from "@/components/custom-pagination/custom-pagination"
const {Option} = Select
class SelectPageList extends React.Component {
  state = {
    currentPage: 1,
    pageSize: PAGE_SIZE,
  }
  render() {
    const {name,label,initialValue,rules,labelCol,wrapperCol,onPageChange,currentPage,totalSize, options,onChange,defaultValue,pageSize,that,currentPageStr,pageSizeStr,mode} = this.props
    return (
      <Form.Item
          name={name}
          label={label ? formatMessage(this,label) : ''}
          initialValue={initialValue}
          rules={rules}
          labelCol={labelCol}
          wrapperCol={wrapperCol}
        >
          <Select
            onChange={onChange ? onChange : null}
            defaultValue={defaultValue}
            mode={mode}
            dropdownRender={(menu) => (
              <>
                {menu}
                {currentPageStr ? <CustomPagination
                  className='pagination-container'
                  current={currentPage}
                  onChange={(currentPage, pageSize) =>
                    onPageNumChange(currentPage, pageSize, that, currentPageStr, pageSizeStr, onPageChange)
                  }
                  total={totalSize}
                  pageSize={pageSize}
                  pageSizeOptions={[5, 10, 20, 50, 100]}
                /> :
                <CustomPagination
                className='pagination-container'
                current={currentPage}
                onChange={(currentPage, pageSize) =>
                  onPageNumChange(currentPage, pageSize, this, 'currentPage', 'pageSize', onPageChange)
                }
                // total={totalSize}
                // pageSize={pageSize}
                pageSizeOptions={[5, 10, 20, 50, 100]}
              />}
              </>
            )}
          >
            {
              options && options.map((option,index) => {
                return (
                  <Option key={index} value={option.value}>
                    {option.label}
                  </Option>
                )
              })
            }
          </Select>
        </Form.Item>
      )
  }
}

export default injectIntl(SelectPageList)
