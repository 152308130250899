
import {
  AUTH_BUTTON_LIST,
  DELIVERY_ADDRESS_LIST,
  FACTORY_LIST,
  IS_LOADING,
  RECEIVE_LANG,
  RECEIVE_PERMISSION,
  PURCHASER_CODE_LIST,
  BUY_NO,
  CATEGORY_TREE_LIST,
  MATERIAL_CLASSIFICATION_LIST,
  UNIT_CLASSIFICATION_LIST,
  AREA_TREE_LIST,
  AREA_LIST
} from './action-types'
import store from "./store";
import {getCategoryTree, reqPurchaserCodeList,reqDeliveryDay, reqGetDeliveryAddressList, reqGetFactoryList, reqMaterialClassificationList, reqGetUnitTree, reqGetAreaTree, reqGetAreaList} from "../api";
import {computedAfterDaysDate, currentAccountIsSupplier, formateDayDate, initFactoryCode} from '../utils/util'
import {getUser} from "../utils/disk-cache/disk-cache";
import moment from "moment";
//设置采购订单的单号
export const receiveBuyNo = (moreData)=>({type:BUY_NO,moreData})
//设置动画的状态
export const receiveLoading = (isLoading)=>({type:IS_LOADING,isLoading})
//设置语言
export const receiveLang = (lang)=>({type:RECEIVE_LANG,lang})
//设置权限
export const receivePermissions = (permissions)=>({type:RECEIVE_PERMISSION,permissions})
//设置收货地址列表
export const receiveDeliveryAddressList = (deliveryAddressList)=>({type:DELIVERY_ADDRESS_LIST,deliveryAddressList})
//设置工厂列表
export const receiveFactoryList = (factoryList)=>({type:FACTORY_LIST,factoryList})
//设置采购列表
export const receivePurchaserCodeList = (purchaserCodeList)=>({type:PURCHASER_CODE_LIST,purchaserCodeList})
//设置品类列表
export const receiveCategoryTreeList = (categoryTreeList)=>({type:CATEGORY_TREE_LIST,categoryTreeList})
//获取权限按扭列表
export const receiveAuthButtonList = (authButtonList)=>({type:AUTH_BUTTON_LIST,authButtonList})
//设置物料分类列表
export const receiveMaterialClassificationList = (materialClassificationList)=>({type:MATERIAL_CLASSIFICATION_LIST,materialClassificationList})
//设置单位列表
export const receiveUnitClassificationList = (unitClassificationList)=>({type:UNIT_CLASSIFICATION_LIST,unitClassificationList})
//设置地区树状列表
export const receiveAreaTreeList = (areaTreeList)=>({type:AREA_TREE_LIST,areaTreeList})
//设置地区列表
export const receiveAreaList = (areaList)=>({type:AREA_LIST,areaList})
/*获取工厂地址*/
export const getFactoryList=(_this,factoryCode)=>{
  let {factoryList} = store.getState()
  initFactoryCode(factoryList,_this,factoryCode)
  let getFactoryListPromise = 1
  //设置条件
  if(!factoryList.length && _this.isNeedFactoryList && !currentAccountIsSupplier()) {
    getFactoryListPromise = reqGetFactoryList()
  }
  //没有条件，只要调总的就能调工厂
  // getFactoryListPromise = reqGetFactoryList()
  return getFactoryListPromise
}

/*获取收货地址*/
export const getDeliveryAddressList = (_this)=>{
  let {deliveryAddressList} = store.getState()
  let getAddressListPromise = 1
  if(!deliveryAddressList.length && _this.isNeedAddressList) {
    getAddressListPromise =  reqGetDeliveryAddressList()
  }
  return getAddressListPromise
}

/**
 * 获取收货截止日期
 * @param _this 使用函数的组件
 * @returns {number}  返回获取收货日期的promise
 */
function getReceivingDay(_this){//userType==='1' 是供应商
  let receivingDayPromise = 1
  if (_this.isNeedReceivingDay && getUser().userType === '1') {
    receivingDayPromise = reqDeliveryDay(getUser().username)
  }else{
    let today = formateDayDate(new Date())
    let requiredDeliveryDateEndStr = computedAfterDaysDate(today, 7)
    /*设置要求交货日的截止日期*/
    if (_this.searchFormRef) {
      _this.searchFormRef.current && _this.searchFormRef.current.setFieldsValue({
        requiredDeliveryDate: ['', moment(new Date(requiredDeliveryDateEndStr))]
      })
      //设置值,重置的时候读值使用
      _this.setState({
        requiredDeliveryDateEndStr:requiredDeliveryDateEndStr
      })
    }
  }
  return receivingDayPromise
}

/*获取采购员列表*/
export const getPurchaserCodeList = (_this)=>{
  let {purchaserCodeList} = store.getState()
  let getPurchaserCodeListPromise = 1
  if(!purchaserCodeList.length && _this.isNeedPurchaserCodeList) {
    getPurchaserCodeListPromise =  reqPurchaserCodeList()
  }
  return getPurchaserCodeListPromise
}

/*获取品类列表*/
export const getCategoryList = (_this) => {
  let {categoryTreeList} = store.getState()
  let getCategoryListPromise = 1
  if(!categoryTreeList.length && _this.isNeedCategoryListCodeList) {
    getCategoryListPromise =  getCategoryTree()
  }
  return getCategoryListPromise
}

/*获取物料分类列表*/
export const getMaterialClassificationList = (_this) => {
  let {materialClassificationList} = store.getState()
  let getMaterialClassificationListPromise = 1
  if(!materialClassificationList.length && _this.isNeedMaterialClassificationList) {
    getMaterialClassificationListPromise =  reqMaterialClassificationList({isTemplate:'N'})
  }
  return getMaterialClassificationListPromise
}

/*获取单位列表*/
export const getUnitClassificationList = (_this) => {
  let {unitClassificationList} = store.getState()
  let getUnitClassificationListPromise = 1
  if(!unitClassificationList.length && _this.isNeedUnitClassificationList) {
    getUnitClassificationListPromise =  reqGetUnitTree({queryType:'tree_page'})
  }
  return getUnitClassificationListPromise
}

/*获取地区树状列表*/
export const getAreaTreeList = (_this) => {
  let {areaTreeList} = store.getState()
  let getAreaTreeListPromise = 1
  if(!areaTreeList.length && _this.isNeedAreaTreeList) {
    getAreaTreeListPromise =  reqGetAreaTree()
  }
  return getAreaTreeListPromise
}

/*获取地区列表*/
export const getAreaList = (_this) => {
  let {areaList} = store.getState()
  let getAreaListPromise = 1
  if(!areaList.length && _this.isNeedAreaList) {
    getAreaListPromise = reqGetAreaList()
  }
  return getAreaListPromise
}
/**
 * 获取多个promise数组
 * @param _this
 * @param factoryCode  工厂字段字符串
 * @returns {[number, number, number]}  工厂,地址,收货截止日期,的promise
 */
export const getAllPromise = (_this,factoryCode)=>{
  return [getFactoryList(_this,factoryCode),getDeliveryAddressList(_this),getReceivingDay(_this),getPurchaserCodeList(_this), getCategoryList(_this), getMaterialClassificationList(_this), getUnitClassificationList(_this), getAreaTreeList(_this), getAreaList(_this)]
  // getCategoryList(_this)
}

export const getFactoryAddDeliveryAddressList = (_this,factoryCode,callback)=>{
  return async dispatch => {
    /*获取工厂列表,地址列表,收货截止日期,品类列表*/
    const results = await Promise.all(getAllPromise(_this,factoryCode))
    const len = results.length
    let {factoryList,deliveryAddressList,purchaserCodeList,categoryTreeList,materialClassificationList,unitClassificationList, areaTreeList, areaList} = store.getState()
    /*处理工厂数组*/
    if(len>=1 && results[0]!==1 && results[0].errorCode==='200'){
      factoryList = results[0].data
      initFactoryCode(factoryList,_this,factoryCode)
      dispatch(receiveFactoryList(factoryList))
    }

    /*处理地址数组*/
    if(len>=2 &&results[1]!==1 && results[1].errorCode==='200'){
      deliveryAddressList = results[1].data
      dispatch(receiveDeliveryAddressList(deliveryAddressList))
    }

    /*处理收货截止日期*/
    if(len>=3 &&results[2]!==1 && results[2].errorCode==='200'){
      let today = formateDayDate(new Date())
      let days = results[2].data || 5
      let requiredDeliveryDateEndStr = computedAfterDaysDate(today, days)
      //设置值,重置的时候读值使用
      _this.setState({
        requiredDeliveryDateEndStr:requiredDeliveryDateEndStr
      })
      /*设置要求交货日的截止日期*/
      _this.searchFormRef.current && _this.searchFormRef.current.setFieldsValue({
        requiredDeliveryDate: ['', moment(new Date(requiredDeliveryDateEndStr))]
      })
    }

    /*处理采购列表*/
    if(len>=4 &&results[3]!==1 && results[3].errorCode==='200'){
      purchaserCodeList = results[3].data
      dispatch(receivePurchaserCodeList(purchaserCodeList))
    }

    /*处理品类列表*/
    if(len>=5 &&results[4]!==1 && results[4].errorCode==='200'){
      categoryTreeList = results[4].data
      dispatch(receiveCategoryTreeList(categoryTreeList))
    }

    /*处理物料分类列表*/
    if(len>=6 &&results[5]!==1 && results[5].errorCode==='200'){
      materialClassificationList = results[5].data
      dispatch(receiveMaterialClassificationList(materialClassificationList))
    }
    
    /*处理单位列表*/
    if(len>=7 &&results[6]!==1 && results[6].errorCode==='200'){
      unitClassificationList = results[6]?.data
      dispatch(receiveUnitClassificationList(unitClassificationList))
    }

    /*处理地区树状列表*/
    if(len>=8 &&results[7]!==1 && results[7].errorCode==='200'){
      areaTreeList = results[7]?.data
      dispatch(receiveAreaTreeList(areaTreeList))
    }

    /*处理地区列表*/
    if(len>=9 &&results[8]!==1 && results[8].errorCode==='200'){
      areaList = results[8]?.data
      dispatch(receiveAreaList(areaList))
    }
    _this.setState({
      factoryList,
      deliveryAddressList,
      purchaserCodeList,
      categoryTreeList,
      materialClassificationList,
      unitClassificationList,
      areaTreeList,
      areaList
    })
    if (typeof callback === 'function') {
      callback();
    }
  }

}


