const common_en_US ={
  /*生成送货单 /送货单管理*/
  COMMON_PURCHASE_ORDER:"Purchase order",
  COMMON_SO_NO:'SO No.',
  COMMON_SO_ITEM:'SO Lot.',
  COMMON_PURCHASE_ORDER_MANAGEMENT_LIST:'R & D purchase order management list',
  COMMON_TRANSIT_TRADE_ORDER_MANAGEMENT_LIST:'Drop shipment Order Management List',
  COMMON_PURCHASE_RETURN:'R & D purchase return',
  COMMON_RECEIPT_OF_ENTREPOT_TRADE:'Receipt of entrepot trade',
  COMMON_SO_SHEET:'SO sheet',
  COMMON_PURCHASE_GENERATE_DELIVERY_NOTE:'R & D purchase generate delivery doc query',
  COMMON_TRANSPORTTRADE_GENERATE_DELIVERY_NOTE:'Drop shipment Generate delivery note',
  COMMON_PURCHASE_DELIVERY_NOTE:'R & D purchase delivery note',
  COMMON_TRANSIT_DELIVERY_NOTE:'Drop shipment Delivery note management',
  COMMONS_MANUFACTURER:'Manufacturer',
  COMMONS_MANUFACTURER_BRAND:'Manufacturer/Brand',
  COMMONS_RETURN_VOUCHER:'Return voucher',
  COMMONS_RETURN_DATE:'Return date',
  COMMONS_MANUFACTURER_NUMBER:'Manufacturer number',
  COMMON_PURCHASE_ORDER_ITEMS:'PO Lot.',
  COMMON_PURCHASE_ORDER_CODE:"PO",
  COMMON_APPLICATION_CODE:'Application Code',
  COMMON_APPLICANT: 'Applicant',
  COMMON_APPLICANT_INFORMATION: 'Applicant information',
  COMMON_SUPPLY:"Vendor",
  COMMON_SUPPLY_CODE:"Vendor code",
  COMMON_MATERIAL_CODE:"srm P/N",
  COMMON_REQUIRED_DELIVERY_DATE:"Required Delivery Date",
  COMMON_SORT:"SORT",
  COMMON_NULL:"NULL",
  COMMON_ORDER_DELIVERY:"Request ETD",
  COMMON_ARRANGE_DELIVERY:"Reschedule Date",
  COMMON_PURCHASE_CONFIRMATION:"Buyer Confirm",
  COMMON_REPLY_DATE:"Respond to ETD",
  COMMON_COURIER_NUMBER:"Tracking",
  COMMON_EXPECTED_DATE_OF_ARRIVAL:"ETA Date",
  COMMON_PACK_UP:"Collapse",
  COMMON_OPEN:"Expand",
  COMMON_SUBMIT:"Submit",
  COMMON_PUT_IN_DELIVERY_NOTE:"Put into delivery note",
  COMMON_PRINT_DELIVERY_NOTE:"Print Delivery Note",
  COMMON_PRINT:'Print',
  COMMON_EXPORT_DELIVERY_NOTE:"Download Delivery Note",
  COMMON_EXPORT_DELIVERY_NOTE_XLSX:"Download the delivery note.xlsx",
  COMMON_DETAIL:"Details",
  COMMON_CONFIRM:"Confirm",
  COMMON_ADD:'Add',
  COMMONS_CONTINUE_ADD:'Continue add',
  COMMON_DELETE:"Delete",
  COMMON_OPERATION:"Operation",
  COMMON_CANCEL:"Cancel",
  COMMON_DELIVERY_NOTE_NO:"Delivery note No.",
  COMMON_DELIVERY_ITEM:"Lot.",
  COMMON_DECLARATION_MODEL_OR_MATERIAL_NUMBER:"Customs Declaration Model/Material Number",
  COMMON_MATERIAL_NUMBER_OR_MODEL:'Material Number/MODEL',
  COMMON_UNIT:"Unit",
  COMMON_DELIVERY_QTY:"Delivery Qty.",
  COMMON_RECEIVE_QTY:"Receipt Qty before declaration",
  COMMON_UNIT_PRICE:"Unit Price",
  COMMON_TOTAL_PRICE:"Amount",
  COMMON_BRAND:"Customs Declaration Brand",
  COMMON_COUNTRY_ORIGINAL:"Country of Origin",
  COMMON_TEMPERATURE:"Temperature",
  COMMON_TOTAL_GROSS_WEIGHT:"Total GW(Kgs)",
  COMMON_TOTAL_NUMBER:"Total Cartons",
  COMMON_EXPRESS_OR_SO_TRACKING_NUMBER:"Tracking /Chunwai Booking",
  COMMON_ARRIVAL_PORT:"ARRIVAL_PORT",
  COMMON_INVOICE:"Invoice",
  COMMON_WAYBILL:"Waybill",
  COMMON_SHIPPING_ORDER_NO:'Waybill No.',
  COMMON_DELIVERY_INSPECTION_REPORT:"COA",
  COMMON_SUPPLIER_CODE:"Vendor Code",
  COMMON_SUPPLIER_NAME:"DN Status",
  COMMON_Hong_Kong_remark:"srm HK forwarder Remarks",
  COMMON_CONFIRMATION_RECEIPT_STATUS:"DN Status",
  COMMON_ITEMS:"Lot.",
  COMMON_NAME_AND_SPECIFICATION:"Des. And Spec.",
  COMMON_CAN_SEND_NUMBER:"Deliverable Qty",
  COMMON_DELIVERY_NUMBER:"Delivery Qty.",
  COMMON_PACKING_DETAIL:"VOLUME",
  COMMON_SERIAL_NUMBER:"Serial",
  COMMONS_SERIAL_NUMBER:"serial number",
  COMMON_SERIAL:"Serial Number",
  COMMON_REMARK:"Remark ",
  COMMON_PRODUCTION_BATCH:"Batch No.",
  COMMON_IQC_CHECKBATCH:"IQC Batch",
  COMMON_PRODUCT_DATE:"Production date",
  COMMON_EXPIRY_DATE:"Expire date",
  COMMON_BRAND_NOT_ENGLISH:"Customs Declaration Brand",
  COMMON_ORIGIN_COUNTRY:"Customs Declaration COO",
  COMMON_MANUFACTURER_COUNTRY:"Customs Declaration MFG",
  COMMON_DEPARTURE_AREA:"Departure From(Country or Area)",
  COMMON_PO_PRICE:"PO Price",
  COMMON_LATEST_PRICE:"Invoice Price",
  COMMON_NET_WEIGHT:"NET Weight(kgs)",
  COMMON_GROSS_WEIGHT:"Gross Weight(kgs)",
  COMMON_BOX_QTY:"Cartons",
  COMMONS_STORE_TEMPERATURE:"Temperature",
  COMMONS_SUPPLIER_MODEL:"Mfg. P/N",
  COMMONS_DELIVERY_MODE:"Delivery Model",
  COMMONS_TERMS_INSPECTION:"Terms Inspection",
  COMMONS_INVENTORY_LOCATION:"Inventory Location",
  COMMONS_EXPORT:"Excel download",
  COMMONS_SYNCHRONIZE_CI:"CI Synchronizing",
  COMMONS_DETAILED:"View Details",
  COMMONS_DETAIL_INFO:"View Details",
  COMMONS_DATE_PURCHASE_CERTIFICATE:"PO DATE",
  COMMONS_MATERIAL_NUMBER:"MR P/N",
  COMMONS_RETURN_QUANTITY:'Return quantity',
  COMMONS_RETURN_ORDER_STATUS:'Return order status',
  COMMONS_RETURN_ORDER_NO:'Return order No',
  COMMONS_MATERIAL_DESCRIPTION:"Des.",
  COMMONS_PROCESS_CODE:"Processing Code",
  COMMONS_PRODUCT_GROUP:"Product Group",
  COMMONS_INSPECTION_TEAM:"Inspection team",
  COMMONS_ENVIRONMENTAL_PROTECTION_SIGNS:"Environmental protection signs",
  COMMONS_WHETHER_JIT_MATERIAL:"Whether jit Material",
  COMMONS_PROJECT_CATEGORY:"Project Category",
  COMMONS_PURCHASING_INFORMATION_RECORD_NUMBER:"Purchasing information #",
  COMMONS_EDITION:"Version",
  COMMONS_EDITION1:'Version',
  COMMONS_ACKNOWLEDGE_EDITION:"Acknowledge Edition",
  COMMONS_DRAWING_EDITION:"Drawing Edition",
  COMMONS_DRAWING_LT: 'LT',
  COMMONS_UNIT_PRICE:"Unit Price",
  COMMONS_PRICE_UNIT:"Price Per",
  COMMONS_SALES_PURCHASE_TAX_CODE:"Sales/Purchase Tax Code",
  COMMONS_CONDITIONAL_AMOUNT_OR_PERCENTAGE:"Conditional amount or percentage",
  COMMONS_NEW_PRICE:'New price',
  COMMONS_NEW_ARIBA_QUOTATION_NO:'New Ariba quotation No',
  COMMONS_ABC_LOGO:"ABC Marks",
  COMMONS_BUYER:"Buyer",
  COMMONS_QUOTA_VALUE:"Quota value",
  COMMONS_BUYER_CODE:"Buyer",
  COMMONS_BUYER_NAME:"Buyer name",
  COMMONS_NUMBER:"QTY",
  COMMONS_UNIT:"Unit",
  COMMONS_PURCHASING_UNIT:"Purchasing unit",
  COMMONS_CONVERSION_RATE:'Conversion rate',
  COMMONS_DELIVERY_SUMMARY:"Delivery Summary",
  COMMONS_INVENTORY_QUANTITY_TO_BE_INSPECTED:"Inventory quantity to be inspected",
  COMMONS_CHECKED_INVENTORY_QUANTITY:'Checked inventory quantity',
  COMMONS_QUANTITY_OF_DEFECTIVE_STOCK:'Quantity of defective stock',
  COMMONS_MATERIAL_ISSUANCE_SUMMARY:'Material Issuance Summary',
  COMMONS_QUANTITY_RECEIVED:"QTY Received",
  COMMONS_REOLENISHMENT_INTERVAL:"Replenishment interval",
  COMMONS_WATER_LEVEL_RANGE:"Water level range",
  COMMONS_RELEASE_DATE:"Release Date",
  COMMONS_REPLENISHMENT_DATE:"Replenishment date",
  COMMONS_SHIPPING_ADDRESS:"Shipping address",
  COMMONS_AVAILABLE_DATE:'Available Date',
  COMMONS_NOT_MEETING_REQUIREMENTS:'Not meeting requirements',
  COMMONS_MEET_THE_REQUIREMENTS:'Meet the requirements',
  COMMONS_OUTSTANDING_AMOUNT:"Open Qty",
  COMMONS_DELIVERY_COMPLETED_MARK:"Delivery Completed Mark",
  COMMONS_CONTROLLER:"MRP CONTROLLER",
  COMMONS_ORDER_DELIVERY_DATE :"Required Delivery Date",
  COMMONS_REARRANGEMENT_STATE :"Reschedule status",
  COMMONS_AGREE_TO_CANCEL_ORDER :"Agree To Cancel Order ",
  //COMMONS_RESPONSE_DATE :"Confirm Delivery Date",
  COMMONS_RESPONSE_DATE :"Confirmed ETD Date",
  COMMONS_SUPPLIER_ID :"SUPPLIER_ID ",
  COMMONS_RECEIVED_DATE:"ETA Date",
  COMMONS_SHIPPING_SUPPLIER:"Shipping Supplier",
  COMMONS_DATE_OF_DELIVERY:"Date of Delivery Notification",
  COMMONS_CONFIRM_DELIVERY_HISTORY:"Delivery Confirm Records",
  COMMONS_PURCHASE_ORDER_BATCH_IMPORT:"Upload Purchase Order in bulk",
  COMMONS_DELIVERY_NOTE_DETAILS:"Delivery Note Details",
  COMMONS_IMPORT:"Upload",
  COMMONS_IMPORT_DEMAND_CONFIRMATION_INFORMATION:"Import demand confirmation information",
  COMMONS_IMPORT_SUCCESS:'Upload success',
  COMMONS_CONFIRM:"Summit",
  COMMONS_CANCEL:"Cancel",
  COMMONS_IMPORT_GENERATE_DELIVERY_NOTE:"Upload to generate a delivery note",
  COMMONS_ADD:"Add",
  COMMONS_LEGAL_BODY:"Legal body",
  COMMONS_DELETE:"Delete",
  COMMONS_DELETE_SELECTED_ITEM:"Delete Selected Item",
  COMMONS_OPERATION:"Operation",
  COMMONS_DOWNLOAD:"Download",
  COMMONS_DOWNLOAD_CI:"Download CI",
  COMMONS_DETAIL:"Detail",
  COMMONS_DELIVERY_NO:"Delivery note No.",
  COMMONS_MUST_WAY_BILL_NO:"must choose one: upload the Waybill or fill in the Way bill No.",
  COMMONS_SHEN_ZHEN:"SHEN ZHEN",
  COMMONS_HONG_KONG:"HONG KONG",
  COMMONS_EXPORT_DELIVERY_DETAILS_XLSX:"Download Delivery Details.xlsx",
  COMMONS_EXPORT_DELIVERY_DETAILS:"Download Delivery Details",
  COMMONS_SELECT_DATA_FOR_EXPORT:"Please Select the Items",
  COMMONS_SUBMIT_SUCCESS:"Submit Successfully",
  COMMONS_CANCEL_SUCCESS:"Cancel Successfully",
  COMMONS_DELETED_SUCCESS:"Delete Successfully",
  COMMONS_DELIVERY_NUMBER:"Delivery Qty",
  COMMONS_RECEIVED_QUANTITY:"Received quantity",
  COMMONS_DIFFERENCE:'实收数量',
  COMMONS_NO_DATA_SUBMIT:"No Data to be Submitted",
  COMMONS_REVIEW_DATE_LIST:"Confirm Delivery Date List",
  COMMONS_SELECT_DATA_FOR_PRINTING:"Please Only Select the Item to Print",
  COMMONS_SELECT_DATA_FOR_CANCEL_DELIVERY_NOTE:'Please Only Select the Item to cancel delivery note',
  COMMONS_SELECT_DATA_FOR_SAVE:'Please Only Select the Item to Save',
  COMMONS_PO_DELIVERY:"PO Delivery Note.pdf",
  COMMONS_IMPORT_SUCCESSFULLY:"Data upload succeeded ",
  COMMONS_SELECT_DATA_FOR_MANIPULATE:"Select item to Operate",
  COMMONS_SAVE_SUCCESSFULLY:"Save Successfully",
  COMMONS_NEW_SUCCESSFULLY:'New Successfully',
  COMMONS_SUMMARY:"Total",
  COMMONS_TOTAL_AMOUNT:"Total Amount",
  COMMONS_PURCHASE_BACK_DELIVERY_DATE:"Buyer Confirm Delivery Date in Batch",
  COMMONS_PRINT_CONTRACT:"Print Contract",
  COMMONS_EXPORT_CONTRACT:"Download Contract",
  COMMONS_PROJECT_REPORT_CUSTOMS_PRE_VERIFICATION_CERTIFICATE:"Project Report/Customs pre-verification/ Registration Certificate",
  COMMONS_INPUT_PACKING_NUMBER_EXPORT:"Please Input Packing Number before Download",
  COMMONS_NOTHING_EXPORT:"Nothing to be Exported",
  COMMONS_CHECK_DATA_PRINT:"Select the Items to print",
  COMMONS_CHECK_DATA_EXPORT:"Select the Items to Download",
  COMMONS_UPLOADED_SUCCESSFULLY:"Upload Successfully",
  COMMONS_DELETED_SUCCESSFULLY:"Delete Successfully",
  COMMONS_TURN_SINGLE_SUCCESSFUL:"Convert Vehicle Successful",
  COMMONS_SELECT_A_DATA:"Please Select One Item",
  COMMONS_CHECK_AT_LEAST_NUMBER:"Please Select at Least One Item",
  COMMONS_NO_DATA_EXPORT_ON_PRICE_DIFFERENCE:'No data export on price difference details page',
  COMMONS_HAS_BEEN_PAID:'The payment of this statement has been completed, and it is not possible to initiate the advance/delay/freeze application process!',
  COMMONS_ADDED_CONTRACT_SUCCESSFULLY:"Add Contract Successfully",
  COMMONS_SELECT_DATA_WANT_TO_MODIFY:"Please Select the Item to Modify",
  COMMONS_MODIFY_SUCCESSFULLY:"Modify Successfully",
  COMMONS_SIGNED_IN_SUCCESSFULLY:"Signed in successfully",
  COMMONS_TURN_SINGLE:"Convert Vehicle",
  COMMONS_ADD_CONTRACT:"Add New Declaration Contract",
  COMMONS_DEL_CONTRACT:"Remove Contract",
  COMMONS_SAVE_EDIT:"Save and submit",
  COMMONS_ONE_KEY_PRINT_CUSTOMS_DECLARATION_CONTRACT:"Print Customs Declaration Contract",
  COMMONS_PRINT_CONTRACT_AND_INVOICE:"Print Customs Declaration Contract and Invoice",
  COMMONS_EXPORT_CUSTOMS_DECLARATION_CONTRACT:"Download Customs Declaration Contract",
  COMMONS_EXPORT_CONTRACT_AND_INVOICE:"Download Customs Declaration Contract and Invoice",
  COMMONS_EXPORT_PACKING_LIST:"Download Packing List",
  COMMONS_TOTAL:"Total",
  COMMONS_ITEMS:"Declaration Contracts NO.",
  COMMONS_CONTRACT_DETAILS:"Contract Details",
  COMMONS_SERVICE_MANAGEMENT:"Vehicle Management",
  COMMONS_PROJECT_REPORT_CUSTOMS_PRE_VERIFICATION:"Project Report/Customs pre-verification",
  COMMONS_NAME:"File Name",
  COMMONS_TYPE:"Type",
  COMMONS_TRAINS:"Vehicle No.",
  COMMONS_PACKING_LIST_NO:"Packing List No",
  COMMONS_PORT_ID:"Port ID",
  COMMONS_DATE_OF_DEPARTURE:"Date of Departure",
  COMMONS_TRAINS_STATE:"Vehicle status",
  COMMONS_PACKING_DATE:"Packing Date",
  COMMONS_CONTRACT_NUMBER:"Contract Number",
  COMMONS_GOODS_NAME:"Customs Declaration Des.",
  COMMONS_CUSTOMS_DECLARATION:"CDS Number",
  COMMONS_TARIFFS:"Tariff",
  COMMONS_THE_VAT:"VAT",
  COMMONS_CURRENCY:"Currency",
  COMMONS_PLACE_OF_TRADE_AND_PLACE_OF_SHIPMENT:"Trading Country/ Place of Departure",
  COMMONS_CREATER:"Created By",
  COMMONS_CONTAINER_NUMBER:"Number of Container",
  COMMONS_CHECK_DOCUMENTS:"Check Documents",
  COMMONS_CONTRACT_STATES:"Contract States",
  COMMONS_CARTON_NO:"Carton No.",
  COMMONS_WHETHER_OK:"HK Confirm Status",
  COMMONS_JUN_WEI_REPLY:"Chun Wai Reply",
  COMMONS_REMARKS_CARD_BOARD_VARIABLE_PRODUCT_NAME:"Remark (for Pallett/ Volume or accessories)",
  COMMONS_CUSTOMS_CLEARANCE_CUSTOMS_BROKER_COMMODITY_INSPECTION_REPLY:"Reply by Customs Declaration Agent or Depart.",
  COMMONS_ADD_TARIFF_EXCLUSION_NUMBER:"VAT Exclusive Number",
  COMMONS_ANTI_DUMPING_DUTIES:"Antidumping Duties",
  COMMONS_DEEP_PORT_FREIGHT:"Freight Charge between Shenzhen/ HK",
  COMMONS_BUSINESS_DATE:"Business Date",
  COMMONS_CUSTOMS_DECLARATION_CONTRACT_NUMBER:"Customs Declaration Contract",
  COMMONS_DATE:"Date",
  COMMONS_DECLARATION:"New CDS",
  COMMONS_HAVE_TO_CHECK:"Checked",
  COMMONS_HAS_BEEN_UPDATED:"Updated",
  COMMONS_CAN_ADD_SINGLE:"Open",
  COMMONS_STATEMENT:"Closed",
  COMMONS_ALREADY_START:"Departure",
  COMMONS_IMPORT_PRICE:"Total amount declared",
  COMMONS_DECLARATION_CONTRACT_STATUS:"CDS Status",
  COMMONS_YES:"Yes",
  COMMONS_NO:"No",
  COMMONS_PROJECT_REPORT:"Project Report",
  COMMONS_CUSTOMS_PRE_VERIFICATION:"Customs pre-verification",
  COMMONS_PACKING_LIST_NOT_EMPTY:"Packing List Number cannot be Empty",
  COMMONS_COMPANY_NAME:"Company Name",
  COMMONS_CAR_STATE:"Vehicle Dispatching Status",
  COMMONS_PORT_ID_2:"Port ID",
  COMMONS_CAPACITY_NUMBER:"Vehicle Capacity",
  COMMONS_CAPACITY_GROSS_WEIGHT:"Gross Weight Capacity",
  COMMONS_CAR_NUMBER:"Vehicle Plate Number",
  COMMONS_DRIVER_NAME:"Driver",
  COMMONS_PHONE:"Phone",
  COMMONS_SEAL_NUMBER:"Seal Number",
  COMMONS_CAN_LOADED:"Can be Loaded",
  COMMONS_SHEN_MAI:"MRSZ",
  COMMONS_SHEN_MAI_KE:"srm Scientific",
  COMMONS_RULE_OUT:"Exclude",
  COMMONS_COMPANY:"Company",
  COMMONS_IS_REQUIRED:"Required",
  COMMONS_MUST_BE_13:"Must be 13 Characters",
  COMMONS_UPLOAD_FILE:"Upload File",
  COMMONS_PACKING_LIST_DETAILS:"Packing List Details",
  COMMONS_CANCEL_FULL_CAR:"Cancel Full-Vehicle status",
  COMMONS_GRID_ABNORMAL:"Failed",
  COMMONS_FULL_CAR:"Full Loaded",
  COMMONS_START_CAR:"Vehicle Dispatched",
  COMMONS_CAR_NO_1_AND_NO_5_CANNOT_BE_BLANK:"Cannot be Empty Except for Vehicle No.1 And No.5",
  COMMONS_UPDATE:"Change",
  COMMONS_GENERATE_PRICE_DIFFERENCE_DOC:'Generate price difference doc',
  COMMONS_VIEW_HISTORY:'View history',
  COMMONS_GENERATE_STATEMENT:'Generate statement',
  COMMONS_ESTIMATION_CLEANUP:'Estimation cleanup',
  COMMONS_EXPAND_COLUMN:'Expand column',
  COMMONS_ADD_BASE_DATA:"Add Basic Data",
  COMMONS_EDIT_BASE_DATA:"Edit Basic Data",
  COMMONS_SUPPLIER_CODE:"Vendor Code",
  COMMONS_SUPPLIER_CODE_NOT_EMPTY:"Material Number cannot be Empty",
  COMMONS_TEL_PHONE_NUMBER:"Telephone",
  COMMONS_FAX:"FAX",
  COMMONS_EMAIL:"Email",
  COMMONS_EDIT:"Edit",
  COMMONS_EDIT_SUCCESSFULLY:"Edit successfully",
  COMMONS_QUERY_RESULTS:'Query results',
  COMMONS_COMPARE_RESULTS:'Compare the results',
  COMMONS_MERGE_SUCCEEDED:"Merge succeeded",
  COMMONS_ADD_USER:"Add User",
  COMMONS_EDIT_USER:"Edit User",
  COMMONS_USER_NAME_CANNOT_EMPTY:"User Name Cannot be Empty",
  COMMONS_PASSWORD:"Password",
  COMMONS_TEL_PHONE_CANNOT_EMPTY:"Tel Phone Cannot be Empty",
  COMMONS_EMAIL_CANNOT_EMPTY:"Email Cannot be Empty",
  COMMONS_NAME_CANNOT_EMPTY:"Name Cannot be Empty",
  COMMONS_TYPE_CANNOT_EMPTY:"Type Cannot be Empty",
  COMMONS_PASSWORD_NOT_EMPTY:"Password Not be Empty",
  COMMONS_ADDRESS:"Address",
  COMMONS_CONTACT:"Contact person",
  COMMONS_CATEGORY:"Category",
  COMMONS_SYNCHRONIZATION_TIME:'Synchronization time',
  COMMONS_NEWEST:'newest',
  COMMONS_MATERIAL_GROUP:"Product Category",
  COMMONS_SCOPE_COOPERATION:"Cooperation Scope",
  COMMONS_SCOPE_BUSINESS:"Business Scope",
  COMMONS_OPTIMUM_LEVEL:"Optimum level",
  COMMONS_REGION:"Area",
  COMMONS_QUALITY_SYSTEM:"Quality system",
  COMMONS_PRODUCT_CERTIFICATION:"Product Qualification",
  COMMONS_EXPORT_TEMPLATE:"Download template",
  COMMONS_EDIT_VENDOR:"Edit Supplier Information",
  COMMONS_BULK_IMPORT_SUPPLIERS:"Batch upload supplier",
  COMMONS_ADDRESS_CANNOT_BE_EMPTY:"Address cannot be empty",
  COMMONS_VENDOR_NAME_CANNOT_EMPTY:"Vendor name cannot be empty",
  COMMONS_VENDOR_CANNOT_EMPTY:"Supplier cannot be empty",
  COMMONS_DELIVERY_DETAILS_XLSX:"Delivery note details.xlsx",
  COMMONS_DELIVERY_DETAILS:"Delivery note details",
  COMMONS_GENERATE_NOTE_AND_EXPORT_XLSX:"Generate delivery note and download.xlsx",
  COMMONS_SELECT_CONTRACT_TO_DELETE:"Select contract to delete",
  COMMONS_CONTRACT_DELETED_SUCCESSFULLY:"Contract deleted successfully",
  COMMONS_FILE:"File",
  COMMONS_LOOK_FILE:'Look file',
  COMMONS_TYPE_CANNOT_BE_EMPTY:"Type cannot be empty",
  COMMONS_THE_FILE_CANNOT_BE_EMPTY:"The file cannot be empty",
  COMMONS_SELECT_FILE:"Choose file",
  COMMONS_INVOICE_NAME:"Invoice name",
  COMMONS_INVOICE_NAME_CANNOT_BLANK:"Invoice name cannot be empty",
  COMMONS_NATIONAL_CODE_STANDARD:"National code standard",
  COMMONS_NATIONAL_CODE_STANDARD_XLSX:"National code standard .xlsx",
  COMMONS_UPLOAD_INVOICE:"Upload invoice",
  COMMONS_FILE_NAME:"File name",
  COMMONS_FILE_NAME_CANNOT_BE_EMPTY:"File name cannot be empty",
  COMMONS_ADD_SUCCESSFULLY:"Add successfully",
  COMMONS_IMPORT_ORDER_TEMPLATE_XLSX:"Upload order template .xlsx",
  COMMONS_IMPORT_SUPPLIER_TEMPLATE_XLSX:"Upload supplier template .xlsx",
  COMMONS_CAN_NOT_EMPTY:"Can not be empty",
  COMMONS_ALL_TOTAL:"Total",
  COMMONS_ITEM:"Item",
  COMMON_ALL:"Total",
  COMMON_FAILURE_TO_REPLY_TO_DELIVERY_DATE:'Failure to reply to delivery date',
  COMMON_UNSATISFIED_DELIVERY_DATE:'Unsatisfied delivery date',
  COMMON_BE_OVERDUE:'Be overdue',
  COMMON_DELIVERY_WITHIN_THREE_DAYS:'Delivery within three days',
  COMMON_NEW_ORDER:"New order",
  COMMON_TO_BE_CONFIRMED:"To be confirmed",
  COMMON_CONFIRMATION_OK:"Receipt confirmed",
  COMMON_CONFIRM_OK:"Confirmed",
  COMMON_PLAN_DELIVERY_DATES:"Planned arrival date",
  COMMON_THE_SERIAL_NUMBER:"Item",
  COMMON_INTERNAL_CHECKING:"Internal inspection",
  COMMON_OUTSIDE_INSPECTION:"External inspection",
  COMMON_EXEMPTED_FROM_INSPECTION:"Exempted from inspection",
  COMMON_SEARCH:"Search",
  COMMON_LOG_OUT:"Log out Login",
  COMMON_RESET:"Reset",
  COMMON_PRINT_PDF:"Print PO PDF",
  COMMON_PURCHASE_ORDER_NO:"Purchase order No.",
  COMMON_FACTORY:"Factory",
  COMMON_COLUMN_NAME:'Column name',
  COMMON_USERNAME:"User Name",
  COMMON_MRPC:'MRPC',
  COMMON_BOTTLENECK_MATERIAL:'Bottleneck material',
  COMMON_NAME:"Name",
  COMMON_CODE:"Vendor Code",
  COMMON_COMPANY_NAME:"Vendor Name",
  COMMON_CONFIRMATION_TIME:"Confirmation time",
  COMMONS_DEFAULT_PREPAYMENT_AMOUNT:'Default prepayment amount',
  COMMON_COMPANY:"Company",
  COMMON_COPYRIGHT :"© 2020 Shenzhen srm Bio-Medical Electronics Co., Ltd. All rights reserved",
  COMMONS_CONCENTRATIONS:"Concentration (COA)",
  COMMONS_ENZYME_ACTIVITY:"Enzyme Activity (COA)",
  COMMONS_TOTAL_GROSSWEIGHT_MORE_ZERO:"Total Gross Weight Must Exceed  0",
  COMMONS_total_No_MORE_ZERO:"Total Number of Packages Must Exceed  0",
  COMMONS_NET_WEIGHT_MORE_ZERO:"Net Weight Must Exceed  0",
  COMMONS_CONFIRMATION_OF_RECEIPT_DATE:"HK Receive Date",
  COMMONS_SURE_TO_DELETE_FILE:"Delete Confirm?",
  COMMONS_FILE_TYPE_NOT_ACCEPTED:"This File Type Is Not Accepted",
  COMMONS_FILE_TYPE_NOT_SIZE:"The file size cannot exceed 20MB",
  COMMONS_DOWN_LINE:"Split",
  COMMON_CURRENCY:'Currency',
  COMMON_WELCOME_SRM:'Welcome to the SRM system',
  COMMONS_OLD_SRM:'Old SRM',
  COMMON_NET_ERROR:'Network error, please contact the administrator',
  COMMON_CANNOT_BE_BLANK:'Reply delivery date cannot be blank',
  COMMONS_LOGIN_SUCCESSFUL:'Login successful',
  COMMONS_CONFIRM_LOG_OUT:'Are you sure to log out?',
  COMMON_TOKEN_HAS_EXPIRED:'Token expired, please login again',


  /*以下是发了，没有更新的*/
  COMMONS_SURE_DEL:'Are you sure to delete?',
  COMMONS_SURE_DEL1:'Are you sure you want to cancel the delivery order',
  COMMONS_SURE_ABANDON:'Are you sure to discard?',
  COMMONS_UPLOAD:'Upload',
  COMMONS_DOWNLOAD_DELIVER_PO: 'Download PO list',
  COMMONS_UPLOAD_DELIVER_PO:'Upload PO list of delivery note',
  COMMONS_DELIVERY:'Delivery',
  COMMONS_UPLOAD_DELIVERY:'Upload delivery',
  COMMONS_REARRANGING_TABLE:'Rearranging table',
  COMMONS_UPLOAD_REORDER_LIST:'Upload the reorder list',
  COMMONS_REARRANGEMENT_TEMPLATE:'Rearrangement template',
  COMMON_BUYER_CONFIRM:'Buyer confirm',
  COMMON_BUYER_DISMISS:'Buyer reject',
  COMMON_REQUEST_EARLY_RECEIPT:'Request early receipt',
  COMMON_CLEAR_EARLY_RECEIPT:'Clear early receipt',
  // COMMON_ORDER_CONFIRMATION_AND_PDF_GENERATION:'Order confirmation and PDF generation',
  COMMON_BATCH_REPLY_TEMPLATE:'Batch reply template',
  COMMON_EXPORT_TO_EXCEL:'Download in Excel',
  COMMON_IMPORT_EXCEL_CONFIRMATION:'Import Excel Confirmation',
  COMMON_CONFIRM_TICK:'Confirm Tick',
  COMMON_BATCH_REPLY_DELIVERY_DATE:'Batch reply delivery date',
  COMMON_FILE_CANNOT_EXCEED_39: 'The file name cannot exceed 46 characters',
  COMMONS_EXPORT_GENERATE_DELIVERY_NOTE:'Upload to generate a delivery note',
  COMMONS_DELIVERYQTY_MORE_THAN_COMFIRMQTY:'DeliveryQty cannot be greater than comfirmqty',
  COMMONS_NO_CAN_NOT_BLANK:'If checkOk is checked, the decoration doc No. cannot be blank',
  COMMONS_GROSS_WEIGHT_MUST_MORE_THAN_NET_WEIGHT:'Total gross weight must be greater than total net weight',
  COMMON_ORDER_CONFIRMATION:'Order confirmation',
  //COMMON_PDF_GENERATION:'Generation PDF',
  COMMON_PDF_GENERATION:'Download Order',
  COMMON_SPLIT_BATCH_REPLY_DELIVERY_DATE:'Split batch reply delivery date',
  COMMON_BATCH_QUANTITY_MORE_THAN_ZERO:'Batch quantity must be greater than zero',
  COMMON_BATCH_REPLY_DATE_NOT_BLANK:'Batch reply delivery date cannot be blank',
  COMMON_REPLY_DATE_NOT_BLANK: 'The reply delivery date is not blank by default',
  COMMONS_SELECT_DATA_ONE:'Please select a piece of data',
  COMMONS_DOCUMENT_CHECK:'Document check',
  COMMONS_ONLY_ONE_CHECKED:'Only one piece of data can be checked',
  COMMONS_MODIFY_RECONCILIATION_QUANTITY_OR_REMARKS:'Modify reconciliation quantity / remarks',
  COMMONS_INITIAL_BE_INVOICED:'Initial return to be invoiced',
  COMMONS_ONE_CLICK_INVOICING_400:'One click Invoicing(400)',
  COMMONS_ONE_CLICK_INVOICING_250:'One click Invoicing(250)',
  COMMONS_CHANGE_TO_MONTHLY_SETTLEMENT:'Change to monthly settlement',
  COMMONS_EXPORT_DELAYED_DELIVERY_LIST:'Export delayed delivery list',
  // COMMONS_ORDER_CONFIRMATION_AND_PDF_GENERATION:'Order confirmation and PDF generation',
  COMMONS_PRINT_ENGLISH_PDF:'Print English pdf',
  COMMONS_TOTAL_NO_MORE_THAN_ZERO:'The total number of pieces must be greater than zero',
  COMMONS_NO_QUANTITY_TO_SEND:'No quantity to send',
  COMMONS_REPLY_DELIVERY_DATE_TEMPLATE:'Bath reply delivery date template',
  COMMONS_INCORRECT_FORMAT_OF_PACKAGING_DETAILS: 'Incorrect format of packaging details',
  COMMONS_DELIVERY_NOT_EMPTY:'Delivery not empty',
  COMMONS_DELIVERY_MORE_THAN_ZERO:'Delivery is more than zero',
  COMMONS_PACKAGE_DETAILS_NOT_EMPTY:'PackageDetails not empty',
  COMMONS_GENERATE_DELIVERY:'Generate delivery',
  COMMONS_GENERATE_DELIVERY_NOTE:'Generate delivery note',
  COMMONS_OPERATION_TYPE:'Operation type',
  COMMONS_LOGIN_NAME:'Login name',
  COMMONS_OPERATION_DATE:'Operation date',
  COMMONS_OPERATION_TIME:'Operation time',
  COMMONS_MATERIAL_IDENTIFICATION:'Material identification',
  COMMONS_VALIDITY_PERIOD:'Validity period',
  COMMONS_ARRIVAL_ACCORDING_TO_NOTICE:'Arrival according to notice',
  COMMONS_PRINT_ORDER_PDF:'Print order pdf',
  COMMONS_INTERNAL_OR_EXTERNAL_INSPECTION:'Internal / external inspection',
  COMMONS_RECEIVING_STATUS:'Receiving status',
  COMMONS_MORNING:'Morning',
  COMMONS_AFTERNOON:'Afternoon',
  COMMONS_EVENING:'Evening',
  COMMONS_ESTIMATED_ARRIVAL_TIME:'Estimated arrival time',
  COMMONS_ESTIMATED_ARRIVAL_TIME_NOT_BLANK:'Estimated arrival time cannot be blank',
  COMMONS_HIDE:'Hide',
  COMMONS_SHOW:'Show',
  COMMONS_CLICK_TO_EXPAND:'Click to expand',
  COMMONS_STAFFING:'Staffing',
  COMMONS_RECEIPT_QTY_MORE_THAN_DELIVERY_QTY:'Port receipt quantity cannot be greater than delivery quantity',
  COMMONS_COMPANY_CODE:'Company code',
  COMMONS_ACCOUNTING_VOUCHER:'Accounting voucher',
  COMMONS_FISCAL_YEAR:'Fiscal year',
  COMMONS_ACCOUNTING_ITEMS:'Accounting items',
  COMMONS_PURCHASE_VOUCHER_NO:'Purchase voucher No',
  COMMONS_PURCHASE_VOUCHER_LN:'Purchase voucher LN',
  COMMONS_MATERIAL_NUMBER_USED_BY_SUPPLIER:'Material number used by the supplier',
  COMMONS_POSTING_DATE:'Posting date',
  COMMONS_MOVEMENT_TYPE:'Movement type',
  COMMONS_CURRENCY_CODE:'Currency code',
  COMMONS_RECEIPT_QUANTITY:'Received Qty',
  COMMONS_BASIC_UOM:'Basic UOM',
  COMMONS_NET_PRICE:'Net price',
  COMMONS_PURCHASING_ORGANIZATION:'Purchasing organization',
  COMMONS_RECONCILIATION_TYPE:'Reconciliation type',
  COMMONS_RECONCILIATION_STATUS:'Reconciliation status',
  COMMONS_ORDER_TYPE:'Order type',
  COMMONS_MONTHLY_SETTLEMENT:'Monthly settlement',
  COMMONS_WITH_GOODS:'With goods',
  COMMONS_DOCUMENT_TYPE:'document type',
  COMMONS_VOUCHER_CURRENCY_AMOUNT:'Voucher currency amount',
  COMMONS_CONFIRM_STATUS:'Confirm status',
  COMMONS_TAX_RATE:'Tax rate(%)',
  COMMONS_PURCHASER_CODE:'Purchaser code',
  COMMONS_SPLIT_RECONCILIATION_OR_NOT:'Split reconciliation',
  COMMONS_SPLIT_RECONCILIATION_QUANTITY:'Split reconciliation quantity',
  COMMONS_ACCOUNTING_SUBJECT:'Accounting subject',
  COMMONS_ORDER_QUANTITY:'Order quantity',
  COMMONS_ITEM_VOUCHER_LN:'Item voucher LN',
  COMMONS_TAX_CODE:'Tax code',
  COMMONS_OPERATION_RECORD:'Operation record',
  COMMONS_DELAY_TABLE:'Delay table',
  COMMONS_BATCH_QUANTITY:'Batch quantity',
  COMMONS_REPLY_DELIVERY_DATE:'Reply to reschedule delivery date',
  COMMONS_EARLY_RECEIPT:'Early receipt',
  COMMONS_DELAY_TYPE:'Delay type',
  COMMONS_ADVANCE_OR_ELAY_DESCRIPTION:'Advance / delay description',
  COMMONS_DEAD_CYCLE:'Dead cycle',
  COMMONS_REARRANGEMENT_STATUS:'Rearrangement status',
  COMMONS_IS_CONFIRM:'Confirm',
  COMMONS_IS_VALIDITY_PERIOD:'Validity period',
  COMMONS_DELIVERY_CONFIRMATION_STATUS:'Delivery confirmation status',
  COMMONS_UNCONFIRMED:'Unconfirmed',
  COMMONS_BE_CONFIRMED_BY_PURCHASE:'To be confirmed by purchase',
  COMMONS_TO_BE_UPDATED:'To be updated',
  COMMONS_COMPLETED:'Completed',
  COMMONS_CONFIRMED:'Confirmed',
  COMMONS_CONFIRMED_NOT_GENERATED:'Confirmed(not generated)',
  COMMONS_GENERATED_ALL:'Generated (all)',
  COMMONS_GENERATED_INSUFFICIENT_REPLENISHMENT:'Generated (insufficient replenishment)',
  COMMONS_GENERATED_REPLENISHED_IN_FULL:'Generated (replenished in full)',
  COMMONS_REMINDER_TYPE:'Reminder type',
  COMMON_MVT:'MVT',
  COMMONS_FAILURE_DELIVER_TIME:'Failure to deliver on time',
  COMMONS_PARTIAL_DELIVERY:'Partial delivery',
  COMMONS_PARTIAL_DELIVERIES_REASONS_FOR_DELAYS:'Partial deliveries & reasons for delays',
  COMMONS_EXPORT_DELAYED_REPLIES:'Export delayed replies',
  COMMONS_PO_CONFIRMED:'Po confirmed',
  COMMONS_NOT_GENERATED:'Not generated',
  COMMONS_GENERATED:'Generated',
  COMMONS_DECLARED:'Declared',
  COMMONS_NO_REARRANGEMENT:'No rearrangement',
  COMMONS_REARRANGEMENT_NOT_EMPTY:'Rearrangement is not empty',
  COMMONS_ADVANCE:'Advance',
  COMMONS_DELAY:'Delay',
  COMMONS_FORCED_REARRANGEMENT:'Forced rearrangement',
  COMMONS_1_3_DAYS_OVERDUE:'1-3 days overdue',
  COMMONS_4_7_DAYS_OVERDUE:'4-7 days overdue',
  COMMONS_8_15_DAYS_OVERDUE:'8-15 days overdue',
  COMMONS_MORE_THAN_15_DAYS_OVERDUE:'More than 15 days overdue',
  COMMONS_REARRANGEMENT_WARNING:'Rearrangement warning',
  COMMONS_ALL_EXCEPT_REARRANGEMENT_ALERT:'All (except rearrangement alert)',
  COMMONS_ALERT_TYPE:'Alert type',
  COMMONS_SPECIAL_SCREENING:'Special screening',
  COMMONS_SUPPLIER_WITHIN_3_DAYS:'Supplier within 3 days',
  COMMONS_SUPPLIER_WITHIN_4_DAYS:'Supplier within 4 days',
  COMMONS_SUPPLIER_WITHIN_5_DAYS:'Supplier within 5 days',
  COMMONS_SUPPLIER_MORE_THAN_5_DAYS_LATER:'Supplier more than 5 days later',
  COMMONS_IMPORT_REPLY_DELIVERY_DATE:'Import reply delivery date',
  COMMONS_QUALITY_PROBLEM:'quality problem',
  COMMONS_ACCOUNTING_ISSUES:'Accounting issues',
  COMMONS_ORIGINAL_LT_LENGTHENING:'Original LT lengthening',
  COMMONS_BUSINESS_ISSUES:'Business issues',
  COMMONS_TEMPORARY_ORDER_INSERTION:'Temporary order insertion',
  COMMONS_ABNORMAL_CUSTOMER_SUPPLY:'Abnormal customer supply',
  COMMONS_PROCUREMENT_EXECUTION:'Procurement execution',
  COMMONS_FORCE_MAJEURE:'Force majeure',
  COMMONS_CUSTOMS_DECLARATION_RESOURCES:'Customs declaration resources',
  COMMONS_OTHER:'other',
  COMMONS_CONFIRMATION_SUCCESSFUL:'Confirmation successful',
  COMMONS_SUPPLIER_IN_SEARCH_CRITERIA_IS_REQUIRED:'Supplier in search criteria is required',
  COMMONS_UPDATE_DELIVERY:'Update delivery',
  COMMONS_PURCHASE_ORDER_AND_ITEM_NOT_EXIST:'Purchase order and item in Excel table do not exist',
  COMMON_CLEAR_REARRANGEMENT:'Empty rearrangement',
  COMMONS_IMPORT_DELIVERY:'Import delivery note',
  COMMONS_PRINT_BOX_LABEL:'Print outer box label',
  COMMONS_DELETE_SELECTED:'Delete selected',
  COMMONS_PRINT_DELIVERY:'Print delivery',
  COMMONS_UPLOAD_DATE:'Upload date',
  COMMONS_ORDER_REARRANGEMENT_HISTORY:'Order rearrangement history',
  COMMONS_REASONS:"reason",
  COMMONS_MONEY:'Amount',

  /*以下是发了，没有更新的*/
  COMMONS_PURCHASE_REQUESTS:'Purchase requisition',
  COMMONS_ARRANGE_NUMBER:'Rearrangement times',
  COMMONS_UPLOAD_BY:'Uploader',
  COMMONS_UPLOAD_TIME:'Upload time',
  COMMONS_REARRANGE_TEMPLATE:'Rearrange template',
  COMMONS_APPROVED:'Approved',
  COMMONS_REVIEW_REJECTED:'Review rejected',
  COMMONS_DELIVERY_LN:'Delivery LN',
  COMMONS_CREATION_TIME:'Creation time',
  COMMONS_DELIVERY_NOTE_STATUS:'Status of Delivery note',
  COMMONS_SUPPLIER:'Supplier',
  COMMONS_RECEIPT_COMPLETED:'Received',
  COMMONS_GENERATING:'Generating',
  COMMONS_VERSION_NUMBER:'Version number',
  COMMONS_EMPTY_REARRANGEMENT_SUCCEEDED:'Empty rearrangement succeeded',
  COMMONS_EXPORT_ERROR_MESSAGE:'Export error message',
  COMMONS_REARRANGE_DATA:'Rearrange data',
  COMMONS_PURCHASE_ORDER:'Purchase order',
  COMMONS_APPROVAL_IDENTIFICATION:'Approval identification',
  COMMONS_REVIEWER:'Reviewer',
  COMMONS_APPLICATION_DATE:'Application date',
  COMMONS_APPLICANT:'Applicant',
  COMMONS_QUANTITY_EXPLAIN:'Quantity & description of goods received in advance by the applicant',
  COMMONS_ADVANCE_RECEIPT_DATE:'Advance receipt date',
  COMMONS_TO_BE_REVIEWED:'Un-checked',
  COMMONS_ETD_DATE_CANNOT_BLANK:'ETD date cannot be blank',
  COMMONS_FAILURE_TO_COMPLY_WITH_COMMITMENTS:'Failure to comply with commitments',
  COMMONS_RECEIPT_CONFIRMATION:'Receipt confirmation',
  COMMONS_REASONS_CONFIRMATION_AND_REJECTION:'Reasons for confirmation / rejection',
  COMMONS_REJECTED_SUCCESSFULLY:'Rejected successfully',
  COMMONS_IS_REJECTED:'Are you sure you want to refuse',
  COMMONS_IS_ABANDONMENT:'Do you confirm the abandonment',
  COMMONS_IS_REVIEW:'Are you sure to review',
  COMMONS_IS_APPROVAL:'Are you sure of approval',
  COMMONS_ORDER_STATUS:'Order status',
  COMMONS_DELIVERY_STATUS:'Delivery status',
  COMMONS_ETD_DATE:'Request ETD',
  COMMONS_REPLY_TO_ETD:'Confirmed ETD',
  COMMONS_SO_NO:'SO No.',
  COMMONS_REARRANGE_ETD:'Reschedule ETD',
  //COMMONS_ON_THE_WAY:'On the way',
  COMMONS_ON_THE_WAY:'Open PO',
  COMMONS_CANCELLED:'Cancel',
  COMMONS_SAVE:'Save',
  COMMONS_DELIVERED:'Delivered',
  COMMONS_SELECT_OR_ENTER_THE_PURCHASE_ORDER_TO_PRINTED:'Please select or enter the purchase order to be printed',
  COMMONS_ONLY_ONE_DATA_CAN_SELECTED_FOR_PRINTING:'Only one piece of data can be selected for printing',
  COMMONS_ORDER:'order',
  COMMONS_APPROVAL_REJECTED_SUCCESSFULLY:'Approval rejected successfully',
  COMMONS_SUCCESSFULLY_APPROVED:'Successfully approved',
  COMMONS_RECEIPT_COMPLETED_STATUS_CANNOT_DELETED:'Receipt completed status, cannot be deleted',
  COMMONS_GENERATION_IN_PROGRESS_DELETION_ALLOWED:'Generation in progress, deletion not allowed',
  COMMONS_RECEIPT_COMPLETED_STATUS_TABLE_DATA_CANNOT_MODIFIED:'Receipt completed status, table data cannot be modified',
  COMMONS_SELECT_OR_ENTER_DELIVERY_ORDER_NUMBER_TO_PRINTED:'Please select or enter the delivery order number to be printed',
  COMMONS_ONLY_ONE_DELIVERY_NOTE_CAN_SELECTED_FOR_PRINTING:'Only one delivery note can be selected for printing',
  COMMONS_SELECT_A_DELIVERY_NOTE:'Please select a delivery note',
  COMMONS_ONLY_ONE_DELIVERY_ORDER_CAN_BE_SELECTED:'Only one delivery order can be selected',
  COMMONS_OUTER_BOX_LABEL:'Outer box label',
  COMMONS_PO_DATE:'Issued Date',
  COMMONS_AUDIT_TYPE:'Audit type',
  COMMONS_REARRANGE_AUDIT:'Rearrange audit',
  COMMONS_ORDER_REVIEW:'Order review',
  COMMONS_PASS:'Pass',
  COMMONS_SELECT_AT_LEAST_ONE_ORDER:'Please select at least one order',
  COMMONS_APPROVAL_TYPE_IS_REARRANGEMENT_APPROVAL_REARRANGEMENT_ETD_REQUIRED:'The approval type is rearrangement approval, and rearrangement ETD is required',
  COMMONS_APPROVAL_TYPE_IS_ORDER_APPROVAL_ETDDATE_REQUIRED:'The approval type is order approval, and etddate is required',
  COMMONS_OPERATION_SUCCEEDED:'Operation succeeded',
  COMMONS_ORIGINAL_REPLY_DELIVERY_DATE:'Original reply delivery date',
  COMMONS_DELAYED_DELIVERY:'Delayed delivery',
  COMMONS_DEFERRED_DESCRIPTION:'Deferred description',
  COMMONS_BPO_NO:'BPO No',
  COMMONS_BPO_LN:'BPO LN',
  COMMONS_NON_ORDER_DELIVERY:'Non order delivery',
  COMMONS_CREATION_DATE:'Creation date',
  COMMONS_DELIVERY_TO_ORDER:'Delivery to order',
  COMMONS_PROCUREMENT_GROUP:'Procurement Group',
  COMMONS_MATERIAL_DOCUMENT:'Material Document',
  COMMONS_ITEMS_IN_THE_MATERIAL_DOCUMENT:'Items in the material document',
  COMMONS_MATERIAL_VOUCHER_YEAR:'Material voucher year',
  COMMONS_DATE_OF_RECEIPT:'Date of receipt',
  COMMONS_RECEIPT_AMOUNT:'Receipt amount',
  COMMONS_RECONCILIATION_QUANTITY:'Quantity that can be reconciled',
  COMMONS_RECONCILIATION_AMOUNT:'Reconciliation amount',
  COMMONS_MATERIAL_ORDER_NO:'Material order No',
  COMMONS_ESTIMATION_STATUS: 'Estimation status',
  COMMONS_INITIAL:'initial',
  COMMONS_STATEMENT_GENERATED:'Statement generated',
  COMMONS_CLEARED:'Cleared',
  COMMONS_TO_BE_INVOICED:'To be invoiced',
  COMMONS_CONFIRM_INVOICING:'Confirm Invoicing',
  COMMONS_TO_BE_PREFABRICATED:'To be prefabricated',
  COMMONS_PREFABRICATED:'Prefabricated',
  COMMONS_PENDING_PAYMENT:'Pending payment',
  COMMONS_CLEARED_ACCOUNT:'Cleared account',
  COMMONS_SPLIT_RECONCILIATION:'Split reconciliation',
  COMMONS_CUSTOMS_DECLARATION_CONTRACT:'Customs declaration contract',
  COMMONS_ADD_CUSTOMS_DECLARATION_CONTRACT_SUCCESS:'Add customs declaration contract success',
  COMMONS_UNDIVIDED_RECONCILIATION:'Undivided reconciliation',
  COMMONS_HAS:'Has',
  COMMONS_IS_HAS_REMARK:'Is has remark',
  COMMONS_PURCHASE_PAYMENT_INFORMATION_LIST:'Purchase payment information list',
  COMMONS_PURCHASE_PAYMENT_INFORMATION_DETAILS:'Purchase payment information details',
  COMMONS_TOTAL_ITEMS:'Total items',
  COMMONS_TOTAL_QUANTITY_RECEIVED:'Total quantity received',
  COMMONS_AMOUNT_RECEIVED_WITHOUT_TAX:'Amount received without tax',
  COMMONS_TAX_INCLUDED_AMOUNT_GOODS_RECEIVED:'Tax included amount of goods received',
  COMMONS_TOTAL_RECONCILIATION_QUANTITY:'Total reconciliation quantity',
  COMMONS_RECONCILED_AMOUNT_BEFORE_TAX:'Reconciled amount before tax',
  COMMONS_RECONCILIATION_AMOUNT_INCLUDING_TAX:'Reconciliation amount including tax',
  COMMONS_MAKE_BATCH_INVOICING_FOR_EVERY_400_ITEMS:'Are you sure you want to make batch Invoicing for every 400 items of all data under the current query conditions? Make sure that the modified data on the page will be saved',
  COMMONS_MAKE_BATCH_INVOICING_FOR_EVERY_200_ITEMS:'Are you sure you want to make batch Invoicing for every 200 items of all data under the current query conditions? Make sure that the modified data on the page will be saved',
  COMMONS_ORDER_NO:'Order No',
  COMMONS_URGENT_MATERIAL_FLAG:'Urgent material Flag',
  COMMONS_PROTECTIVE_SIGNS:'Protective signs',
  COMMONS_INSPECTION_TYPE:'Inspection type',
  COMMONS_SRM_BATCH_NUMBER:'SRM batch number',
  COMMONS_IS_IT_URGENT:'Is it urgent',
  COMMONS_GENERATION_IN_PROGRESS_DELETION_NOT_ALLOWED:'Generation in progress, deletion not allowed',
  COMMONS_RECEIPT_COMPLETED_STATUS_CANNOT_BE_DELETED:'Receipt completed status, cannot be deleted',
  COMMONS_IS_COMPLETED_NOT_EDIT:'Receipt completion status. It is not allowed to delete receipt completion status or modify table data',
  COMMONS_RECEIPT_QUANTITY_MUST_BE_GREATER_THAN_ZERO:'Receipt quantity must be greater than 0',
  COMMONS_REPLY_TIME:'Reply time',
  COMMONS_SUPPLIER_REPLY_DELIVERY_DATE:'Supplier reply delivery date',
  COMMONS_PLANNED_REPLY_DELIVERY_DATE:'Planned reply delivery date',
  COMMONS_PURCHASE_CONFIRMATION_DELIVERY_DATE:'Purchase confirmation delivery date',
  COMMONS_DELIVER_GOODS:'deliver goods',
  COMMONS_OPERATOR:'Operator',
  COMMONS_OPERATORS:'Operator',
  COMMONS_CONTENT:'content',
  COMMONS_EVENT:'Event',

  /*以下发了，没有更新的，2022-3-11发的*/
  COMMONS_SELECT_OR_ENTER_DELIVERY_ORDER_NUMBER_EXPORT:'Please select or enter the delivery order number to export',
  COMMONS_PO_NO:'Po No',
  COMMONS_MODEL:'model',
  COMMONS_ACTUAL_ARRIVAL_DATE:'Actual arrival date',
  COMMONS_ACTUAL_ARRIVAL_DATE_NOT_EMPTY:'Actual arrival date cannot be blank',
  COMMONS_WORK_ORDER_NO:'Work order No',
  COMMONS_PART_NAME:'Part name',
  COMMONS_QUANTITY_TO_BE_DELIVERED:'Quantity to be delivered',
  COMMONS_DEMAND_DATE:'Demand date',
  COMMONS_MATERIAL_ISSUANCE_OF_THIS_WORK_ORDER:'Material issuance of this work order',
  COMMONS_CALL_QUANTITY:'Call quantity',
  COMMONS_CALL_DATE:'Call date',
  COMMONS_CALL_TIME:'Call time',
  COMMONS_MATERIAL_DEPARTMENT:'Material department',
  COMMONS_CALLER:'Caller',
  COMMONS_MATERIAL_UNIT:'Material unit',
  COMMONS_DELIVERY_FLOOR:'Delivery floor',
  COMMONS_DEMAND_TIME:'Demand time',
  COMMONS_ENTER_MATERIAL_CODE_SEPARATED_BY_SEMICOLONS_English:'Enter the material code separated by semicolons (English)',
  COMMONS_ULTRASONIC_PRODUCT_PRODUCTION_DEPARTMENT:'Ultrasonic product production department',
  COMMONS_BLOOD_CELL_PRODUCT_PRODUCTION_DEPARTMENT:'Blood cell product production department',
  COMMONS_BIOCHEMICAL_PRODUCTS_PRODUCTION_DEPARTMENT:'Biochemical products production department',
  COMMONS_ANESTHESIA_RESPIRATORY_PRODUCTS_PRODUCTION_DEPARTMENT:'Anesthesia respiratory products production department',
  COMMONS_ANESTHESIA_MODULE_PRODUCTION_DEPARTMENT:'Anesthesia module production department',
  COMMONS_PRODUCT_PRODUCTION_DEPARTMENT:'Product production department',
  COMMONS_DISTRIBUTION_DEPARTMENT:'Distribution Department',
  COMMONS_RADIOGRAPHIC_PRODUCT_PRODUCTION_DEPARTMENT:'Radiographic product production department',
  COMMONS_BIOCHEMICAL_REAGENT_FACTORY:'Biochemical reagent factory',
  COMMONS_BLOOD_CELL_REAGENT_FACTORY:'Blood cell Reagent Factory',
  COMMONS_IMMUNE_REAGENT_FACTORY:'Immune reagent factory',
  COMMONS_ACTUAL_DELIVERY_QUANTITY:'Actual delivery quantity',
  COMMONS_IQC_STATION_ACCOUNT_NUMBER:'IQC station account number',
  COMMONS_MACHINING_PRODUCTION_DEPARTMENT:'Machining production department',
  COMMONS_RADIOGRAPHIC_PRODUCTION_DEPARTMENT:'Radiographic production department',
  COMMONS_IMMUNIZATION_PRODUCT_PRODUCTION_DEPARTMENT:'Immunization product production department',
  COMMONS_MONITORING_AND_CONSUMABLES_PRODUCTION_DEPARTMENT:'Monitoring and consumables production department',
  COMMONS_SPARE_PARTS_MANAGEMENT:'Spare parts management',
  COMMONS_SAFETY_STOCK:'safety stock',
  COMMONS_WORK_ORDER_DEMAND_IN_RECENT_5_DAYS:'Work order demand in recent 5 days',
  COMMONS_PREVIOUS_DEMAND:'Previous demand',
  COMMONS_UNRESTRICTED_INVENTORY:'Unrestricted inventory',
  COMMONS_QUALITY_INSPECTION_INVENTORY:'Quality inspection inventory',
  COMMONS_AGGREGATE_DEMAND:'Aggregate demand',
  COMMONS_PLANNED_ORDER_DEMAND_IN_THE_NEXT_90_DAYS:'Planned order demand in the next 90 days',
  COMMONS_PO_ITEM:'Po item',
  COMMONS_OCCUPIED_QUANTITY:'占用数量',
  COMMONS_STATE:'state',
  COMMONS_PRINTED:'Printed',
  COMMONS_UNRECEIVED_GOODS:'Unreceived goods',
  COMMONS_RECEIVED:'Confirmed receipt',
  COMMONS_PRINT_MATERIAL_ISSUANCE:'Print material issuance',
  COMMONS_PRINT_RECEIPT:'Print receipt',
  COMMONS_UNDELIVERED:'Undelivered',
  COMMONS_CONFIRM_RECEIPT:'Confirmed receipt',
  COMMONS_REJECT_RECEIPTS:'Rejected',
  COMMONS_DEPARTMENT:'department',
  COMMONS_MATERIAL_STORAGE:'Material storage',
  COMMONS_PO_DELIVERY_DATE:'PO delivery date',
  COMMONS_UNOCCUPIED_QUANTITY:'Unoccupied quantity',
  COMMONS_DEMAND_QUANTITY:'Demand Qty',
  COMMONS_DELIVERY_ADDRESS:'Delivery address',
  COMMONS_ULTRASONIC:'ultrasonic',
  COMMONS_CUSTODY:'custody',
  COMMONS_ANAESTHESIA:'anaesthesia',
  COMMONS_BIOCHEMISTRY:'biochemistry',
  COMMONS_INFUSION_PUMP:'Infusion pump',
  COMMONS_BLOOD_CELL:'blood cell',
  COMMONS_X_RAY:'X-ray',
  COMMONS_BLOOD_CELL_REAGENT:'Blood cell reagent',
  COMMONS_GROUP_CODE:'Group code',
  COMMONS_ACTUAL_CALL_QUANTITY:'Actual call quantity',
  COMMONS_INVENTORY_QUANTITY:'Inventory quantity',
  COMMONS_10_PIECES:'10 pieces',
  COMMONS_CALL_DATE_OR_TIME:'Call date / time',
  COMMONS_NUMBER_2:'Summary number',
  COMMONS_SOURCE:'source',
  COMMONS_MATCHING_QUANTITY:'Matching quantity',
  COMMONS_MATCH_COMPLETE:'Match complete',
  COMMONS_UNMATCHED_COMPLETION:'Unmatched completion',
  COMMONS_MATCH_PO:'Match Po',
  COMMONS_STOCK:'stock',
  COMMONS_STOCK_MES_LIST:'stock message list',
  COMMONS_DELIVERY_MES_LIST:'delivery message list',
  COMMONS_DELIVERY_MES_LIST_AGAIN:'Inventory Snapshot Report',
  COMMONS_DELIVERY_DETAILS_REPORT:'Delivery Details Report',
  COMMONS_INVENTORY_ADDRESS:'Inventory address',
  COMMONS_ABNORMAL_INFORMATION:'Abnormal information',
  COMMONS_UNMATCHED_QUANTITY:'Unmatched quantity',
  COMMONS_GENERATED_QUANTITY:'Generated quantity',
  COMMONS_ORDER_LN:'Order LN',
  COMMONS_ORDER_OPEN_QUANTITY:'Order open quantity',
  COMMONS_HAS_MATCHED_QUANTITY:'Matched quantity',
  COMMONS_MAY_MATCHING_QUANTITY:'May Matching quantity',
  COMMONS_MANUAL_DEMAND_MATCHING:'Manual demand matching',
  COMMONS_SUBMIT_MATCH:'Submit match',
  COMMONS_MANUALLY_ADD_REQUIREMENTS:'Manually add requirements',
  COMMONS_DEMAND_INFORMATION:'Demand information',
  COMMONS_DEMAND_CONFIRMATION_INFORMATION:'Demand confirmation information',
  COMMONS_CONTINUE_MATCHING_REQUIREMENTS:'Continue matching requirements',
  COMMONS_DELETE_SELECTED_REQUIREMENTS:'Delete selected requirements',
  COMMONS_SUPPLIER_CODE_CANNOT_BE_BLANK:'Supplier code cannot be blank',
  COMMONS_DEMAND_QUANTITY_CANNOT_BE_BLANK:'Demand quantity cannot be blank',
  COMMONS_DEMAND_SUMMARY:'Demand summary',
  COMMONS_SURE_WANT_TO_CONFIRM_RECEIPT:'Are you sure you want to confirm receipt?',
  COMMONS_CALL_SUMMARY:'Call summary',
  COMMONS_RECEIPT_CONFIRMATION_SUCCEEDED:'Receipt confirmation succeeded',
  COMMONS_ACTUAL_DELIVERY_QUANTITY_MUST_GREATER_THAN_ZERO:'The actual delivery quantity must be greater than 0',
  COMMONS_SURE_YOU_WANT_TO_SUBMIT:'Are you sure you want to submit?',
  COMMONS_SURE_YOU_WANT_TO_DEL:'Are you sure to delete?',
  COMMONS_CONTRACT_QUANTITY:'Contract quantity',
  COMMONS_LIST:'List',
  COMMONS_MATERIAL_REQUIREMENTS:'Material requirements',
  COMMONS_SURE_YOU_WANT_TO_ADD_REQUIREMENTS:'Are you sure you want to add requirements?',
  COMMONS_MATCHING_QUANTITY_MUST_BE_GREATER_THAN_ZERO:'Matching quantity must be greater than 0',
  COMMONS_MATCH_SUCCESSFUL:'Match successful',
  COMMONS_DELIVERY_QUANTITY_MUST_BE_GREATER_THAN_0:'Delivery quantity must be greater than 0',
  COMMONS_EXPORT_SERIAL_NUMBER:'Export serial number',
  COMMONS_DELIVERY_NOTE_NO:'Delivery note No',
  COMMONS_UNOCCUPIED:'UNOCCUPIED',
  COMMONS_SAVE_DELIVERY_NOTE:'Save delivery note',
  COMMONS_CANCEL_DELIVERY_NOTE: 'Cancel delivery note',
  COMMONS_BY_DELIVERY_NOTE_NO_CANCEL_DELIVERY_NOTE:'Cancel delivery note',
  COMMONS_RECEIPT:'Receipt',
  COMMONS_SURE_WANT_TO_SAVE_THE_DELIVERY_NOTE:'Are you sure you want to save the delivery note?',
  COMMONS_DETAILS_ARE_EMPTY_AND_NOT_SUBMIT:'The packing details are empty and there is no data to submit',
  COMMONS_AFTER_CONFIRM_IN_DELIVERY_GENERATE_DELIVERY:'If the validity period is less than T1, please confirm the purchase and then generate a delivery note in the printed delivery note',
  COMMONS_SURE_TO_CLEAR_SUBMITTED_RECEIPT:'Are you sure you want to clear the submitted receipt',
  COMMONS_SELECT_SAME_DELIVERY_NO_DATA:'Only data of the same delivery order number can be checked',
  COMMONS_SURE_TO_CANCEL_DELIVERY_NO:'Are you sure you want to cancel the delivery order',
  COMMONS_DELIVERY_NOTE_CANCELLED_SUCCESSFULLY:'Delivery note cancelled successfully',
  COMMONS_ADVANCE_DELIVERY_RECORD:'Advance delivery record',
  COMMONS_DELIVERY_QUANTITY:'Delivery quantity',
  COMMONS_CANCEL_BY_DELIVERY_ORDER_NUMBER:'Cancel by delivery order number',
  COMMONS_MINI_MUM:'Minimum',
  COMMONS_MAX_MUM:'Maxnum',
  COMMONS_AVAILABLE_QUANTITY:'Available quantity',
  COMMONS_RECEIVED_CONFIRMED_QUANTITY:'Received confirmed quantity',
  COMMONS_UNDELIVERED_QUANTITY:'Undelivered quantity',
  COMMONS_QUANTITY_OF_DELIVERY_NOTES_GENERATED:'Quantity of delivery notes generated',
  COMMONS_QUANTITY_IN_TRANSIT:'Quantity in transit',
  COMMONS_AUDIT_STATUS:'Audit status',
  COMMONS_REPLY_STATUS:'Reply status',
  COMMONS_PASSED:'Approved',
  COMMONS_REJECTED:'Rejected',
  COMMONS_NOT_PASSED:'Fail',
  COMMONS_REPLIED:'Replied',
  COMMONS_NO_REPLY:'No reply',
  COMMONS_OPENING_DEMAND:'Opening demand',
  COMMONS_CHARACTERISTIC_VALUE:'Characteristic value',
  COMMONS_MATERIAL_SHORTAGE_IN_30_DAYS:'Material shortage in 30 days',
  COMMONS_NUMBER_OF_REPLIES:'Number of replies',
  COMMONS_AVAILABLE_DIFFERENCES:'Available differences',
  COMMONS_SURE_TO_CONTINUE_MATCHING_REQUIREMENTS:'Are you sure you want to continue matching requirements',
  COMMONS_WANT_TO_SUBMIT_MATCH:'Are you sure you want to submit a match',
  COMMONS_SUPPLIER_RESPONSE:'Supplier response',
  COMMONS_KEY_MATERIALS_FCST_AVAILABLE_FOR_REPLY:'Key materials FCST available for reply',
  COMMONS_ARE_YOU_SURE_TO_DELETE_THE_SELECTED:'Are you sure to delete the selected',
  COMMONS_PURCHASE_REVIEW:'Purchase review',
  COMMONS_MC_REVIEW:'MC review',
  COMMONS_COMPREHENSIVE_STATEMENT:'Comprehensive statement',
  // COMMONS_MC_DELETE:'MC delete',
  COMMONS_EXPORT_REPORT:'export report',
  COMMONS_PULL_DEMAND_SCHEDULING_AVAILABLE_FOR_REPLY:'Pull demand scheduling available for reply',
  COMMONS_FLUCTUATION_QUANTITY:'Fluctuation quantity',
  COMMONS_SAP_BATCH:'SAP batch',
  COMMONS_SHELF_LIFE_EXPIRATION_DATE:'Shelf life expiration date',
  COMMONS_CONFIRM_ORDER:'Confirm order',
  COMMONS_MVT:'MVT',
  COMMONS_MC:'MC',
  COMMONS_SUPPLIER_DELIVERY_NOTE_MR:'Supplier delivery note (MR)',
  COMMONS_CANCEL_CONFIRMATION:'Cancel confirmation',
  COMMONS_DISAGREE:'disagree',
  COMMONS_AGREE:'agree',
  COMMONS_CUSTOMS_DECLARATION_UNIT:'Customs declaration unit',
  COMMONS_CUSTOMS_DECLARATION_QUANTITY:'Customs declaration quantity',
  COMMONS_EXPORT_PULL_DEMAND_SCHEDULING:'Export pull demand scheduling',
  COMMONS_IMPORT_PULL_DEMAND_SCHEDULING:'Import pull demand scheduling',
  COMMONS_HONG_KONG_EXPORT_DELIVERY_NOTE:'Download Delivery Note by Hongkong warehouse',
  COMMONS_PULL_DEMAND_SCHEDULING:'Pull demand scheduling',
  COMMONS_PULL_DEMAND_SCHEDULING_DELIVERY_DOC:'Pull demand scheduling delivery doc',
  COMMONS_PULL_DEMAND_SCHEDULING_REVIEW:'Pull demand scheduling review',
  COMMONS_NOT_PASS:'unPass',
  COMMONS_NOT_PASS_SUCCESSFULLY:'Failed to submit successfully',
  COMMONS_PASS_SUCCESSFULLY:'Successfully submitted by',
  COMMONS_WO_PULL_HISTORY_DELIVERY_DOC:'Wo pull history delivery doc',
  // COMMONS_ENTER_AT_LEAST_ONE_ITEM_OF_MATERIAL_CODE_AND_DELIVERY_ORDER_NUMBER:'Please enter at least one item of material code and delivery order number',
  COMMONS_PULL_AND_SCHEDULE_DELIVERY_NOTE_ON_DEMAND:'Pull and schedule delivery note on demand',
  COMMONS_DELETED:'Deleted',
  COMMONS_PULL_DEMAND_SCHEDULING_REPORT:'Pull demand scheduling Report',
  COMMONS_CLEAR_LIST:'clear list',
  COMMONS_ENTER_COMPLETE_DELIVERY_ORDER_NUMBER:'Please enter the complete delivery order number',
  COMMONS_UNLOCK:'Unlock',
  COMMONS_UNLOCK_SUCCEEDED:'Unlock succeeded',
  // COMMONS_CHECK_A_DELIVERY_ORDER_NUMBER:'Please check a delivery order number',
  COMMONS_MATERIAL_ISSUANCE:'Material issuance',
  COMMONS_MATERIAL_NAME:'Material name',
  COMMONS_SUBCLASS:'Subclass',
  COMMONS_MIDDLE_CLASS:'middleClass',
  COMMONS_BIG_CLASS:'bigClass',
  COMMONS_CATEGORY_NAME:'categoryName',
  COMMONS_PRODUCT_MANAGER:'product manager',
  COMMONS_BUSINESS_AFFAIRS:'business affairs',
  COMMONS_MINING_AND_FOOTWEAR_GROUP:'Mining and Footwear Group',
  COMMONS_PURCHASE_NO:'Purchase No',
  COMMONS_ONLY_ONE_DELIVERY_ORDER_NUMBER_CAN_BE_CHECKED:'Only one delivery order number can be checked',
  COMMONS_ADD_CATEGORY:'Add category',
  COMMONS_MODIFY_CLASSIFICATION:'Modify classification',
  COMMONS_SURE_TO_DELETE_THE_CLASSIFICATION:'Are you sure you want to delete the classification?',
  COMMONS_CATEGORY_DELETED_SUCCESSFULLY:'Category deleted successfully',
  COMMONS_TOP_CLASS:'Top class',
  COMMONS_UPLOAD_CATEGORY:'Upload category',
  COMMONS_SURE_UPLOAD_REPLY_DELIVERY_FORM:'Are you sure you want to upload the reply delivery form?',
  COMMONS_SURE_SUBMIT_BATCH_REPLY_DELIVERY_DATE:'Are you sure you want to submit the batch reply delivery date?',
  COMMONS_REPLY_IN_BATCHES:'Reply in batches',
  COMMONS_SUPPLIER_MODIFIED_SUCCESSFULLY:'Supplier modified successfully',
  COMMONS_SURE_WANT_TO_MODIFY_SUPPLIER:'Are you sure you want to modify the supplier?',
  COMMONS_ENTER_A_DELIVERY_ORDER_NUMBER:'Please enter a delivery order number',
  COMMONS_ENTER_OR_SELECT_DELIVERY_ORDER:'Please enter the delivery order number or check the delivery order',
  COMMONS_JIT_WORK_ORDER_FORECAST_LIST:'JIT work order forecast list',
  COMMONS_DEMAND_DATE_CANNOT_BE_BLANK:'Demand date cannot be blank',
  COMMONS_MATERIAL_CATEGORY:'Material category',
  COMMONS_MATERIAL_BIG_CATEGORY:'Material big category',
  COMMONS_MATERIAL_MIDDLE_CATEGORY:'Material middle category',
  COMMONS_SPECIFICATION_AND_MODEL:'Specification and model',
  COMMONS_QUOTA_INVENTORY:'Quota inventory',
  COMMONS_QUALITY_INSPECTION_QUANTITY:'Quality inspection quantity',
  COMMONS_VMI_INVENTORY:'VMI inventory',
  COMMONS_ASN_QUANTITY:'ASN quantity',
  COMMONS_SUPPLIER_FINISHED_PRODUCT_INVENTORY:'Supplier finished product inventory',
  COMMONS_SEMI_FINISHED_PRODUCT_INVENTORY:'Semi finished product inventory',
  COMMONS_DEMAND_IN_MONTH_N:'Demand in month n',
  COMMONS_N_1_MONTHLY_DEMAND:'N+1 Monthly demand',
  COMMONS_N_2_MONTHLY_DEMAND:'N+2 Monthly demand',
  COMMONS_N_3_MONTHLY_DEMAND:'N+3 Monthly demand',
  COMMONS_MONTHLY_DOSAGE:'Monthly dosage',
  COMMONS_REQUIRED_STOCK_CYCLE:'Required stock cycle',
  COMMONS_TOTAL_INVENTORY_FACTOR:'Total inventory factor',
  COMMONS_TOTAL_INVENTORY_LEVEL:'Total inventory level',
  COMMONS_SRM_INVENTORY_FACTOR:'srm inventory factor',
  COMMONS_SRM_INVENTORY_LEVEL:'srm inventory level',
  COMMONS_SUPPLIER_INVENTORY_FACTOR:'Supplier inventory factor',
  COMMONS_SUPPLIER_INVENTORY_LEVEL:'Supplier inventory level',
  COMMONS_FULFILLMENT_RATE_OF_ITM_GOODS_PREPARATIONL:'Fulfillment rate of ITM goods preparation',
  COMMONS_FULFILLMENT_RATE_OF_GOODS_PREPARATION:'Fulfillment rate of goods preparation',
  COMMONS_STOCK_HEALTH:'Stock health',
  COMMONS_G_S_CLASSIFICATION:'G/S classification',
  COMMONS_DO_NOT_PUBLISH_FCST:'Do not publish FCST',
  COMMONS_MRPC_CONTROLLER:'MRPC controller',
  COMMONS_INVENTORY_SNAPSHOT:'Inventory Snapshot',
  COMMONS_COMPREHENSIVEZ_REPORT_OF_GOODS_PREPARATION:'Comprehensive report of goods preparation',
  COMMON_SERIAL_BEAN_NUMBER:'Serial number (multiple, separate)',
  COMMON_IMPORT_REPORT:'Import Report',
  COMMON_REPORT_NAME:'Report name',
  COMMON_SURE_TO_UPLOAD_REPORT:'Are you sure you want to upload the report?',
  COMMON_SELECT_OR_INPUT_DELIVERY_ORDER:'Please select or enter the delivery order number to be printed',
  COMMON_CHANGE_COMPANY:'Change company',
  COMMON_NAN_JIN_MAI_RUI:'Nanjin srm',
  COMMON_NAN_JIN_SW_RUI:'Nanjin Biology',
  COMMON_MAI_RUI:'Shenzhen srm',
  COMMON_CANCEL_BUYER_CONFIRM:'Cancel buyer confirm',
  COMMON_EWMS_FLAG:'EWMS flag',
  COMMON_GSP_MATERIAL:'GSP material',
  COMMON_TI_DELIVERY_NUMBER:'Ti delivery number',
  COMMON_PO_ORDER:'Po order',
  COMMON_BPO_ORDER:'Bpo order',
  COMMON_PREPAYMENT_TYPE:'Prepayment type',
  COMMON_ERP_PRICE_DIFFERENCE_REPORT_LIST:'ERP Price Difference Report List',
  COMMON_PROFORMA_INVOICE_NO:'Proforma Invoice No',
  COMMON_GROSS_WEIGHT_UNIT:'Gross weight unit',
  COMMON_COUNTRY_OF_ORIGIN:'Country of origin',
  COMMON_CORRESPONDING_SUBMITTED_QUANTITY:'Corresponding submitted quantity',
  COMMON_DELIVERY_NOTE_CREATION_DATE:'Delivery note creation date',
  COMMON_ORDER_DATE:'Order date',
  COMMON_ACTUAL_DELIVERY_DATE:'Actual delivery date',
  COMMON_ESTIMATED_ARRIVAL_DATE:'Estimated arrival date',
  COMMON_SUBMIT_BRAND:'Submit brand',
  COMMON_REPORT_TO_MANUFACTURER:'Report to manufacturer',
  COMMON_COUNTRY_OF_SHIPMENT_REGION:'Country of shipment (region)',
  COMMON_ERROR_CODE:'error code',
  COMMON_UPDATE_PERSON:'Update person',
  COMMON_UPDATE_DATE:'Update date',
  COMMON_COURIER:'Courier',
  COMMON_FILE_NAME:'File name',
  COMMON_INVOICE_NO:'Invoice No',
  COMMON_ITEM:'item',
  /*以下是未发的*/
  COMMON_STATUS_CODE:'Status code',
  COMMON_PURCHASE_CANCELLATION_CONFIRMED_SUCCESSFULLY:'Purchase cancellation confirmed successfully',
  COMMON_MONITORING_CONSUMABLES_PRODUCTION_DEPARTMENT:'Monitoring consumables production department',
  COMMON_GENERATE_EXCEPTION:'Generate exception',
  COMMON_IN_TRANSIT_STATUS:'In transit status',
  COMMON_SHEKOU_SELF_LIFTING:'Shekou self lifting',
  COMMON_HONG_KONG_WAREHOUSE_IN_TRANSIT:'Hong Kong warehouse in transit',
  COMMON_SUPPLIER_IN_TRANSIT:'Supplier in transit',
  COMMON_HIGH_RISK:'High risk',
  COMMON_MATERIAL_AND_SUPPLIER_MODEL_TEMPLATE:'Material and supplier model template',
  COMMON_UPLOAD_MATERIAL_AND_SUPPLIER_MODEL:'Upload material and supplier model',
  COMMON_MATERIAL_AND_SUPPLIER_MODEL:'Material and supplier model',
  COMMON_PRICE:'Price',
  COMMON_PRICE_DISCOUNT:'Price discount',
  COMMON_LAST_UPDATE_TIME:'Last update time',
  COMMON_MINIMUM_NUMBER_PURCHASES:'Minimum number of purchases',
  COMMON_PURCHASE_ADDRESS:'Purchase address',
  COMMON_REARRANGE_ERROR_MESSAGE:'Rearrange error message',
  COMMON_EXPORT_TEMPLATE:'Import template',
  COMMON_DELIVERY_METHOD:'Delivery method',
  COMMON_TIME_TYPE:'Time type',
  COMMON_BY_WEEK:'By week',
  COMMON_BY_MONTH:'By month',
  COMMON_CLASSIFICATION:'classification',
  COMMON_PO_QUANTITY_IN_PROCESS:'Po quantity in process',
  COMMON_PO_QUANTITY_ON_PASSAGE:'Po Quantity in transit',
  COMMON_OFFICIAL_INVENTORY:'Official inventory',
  COMMON_QUALITY_INSPECTION_INVENTORY_QUANTITY:'Quality inspection inventory quantity',
  COMMON_DECLARE_CUSTOMS:'declare at customs',
  COMMON_NEEDS_BEFORE_TODAY:'Needs before today',
  COMMON_INVENTORY_IDENTIFICATION:'Inventory identification',
  COMMON_HAS_INVENTORY:'STOCK',
  COMMON_NO_INVENTORY:'No inventory',
  COMMON_IS_THERE_INVENTORY:'Is there inventory',
  COMMON_WAREHOUSE_SUPPLIER_INVENTORY:'Warehouse supplier inventory',
  COMMON_NO_RISK_MATERIAL:'No risk material',
  COMMON_DEMAND:'demand',
  COMMON_DEMAND_STATUS:'Demand Status',
  COMMON_NET_DEMAND:'Net demand',
  COMMON_MPQ:'MPQ',
  COMMON_REPLENISHMENT_INTERVAL:'Replenishment interval',
  COMMON_NOT_IN_THE_INTERVAL:'Not in the interval',
  COMMON_IN_INTERVAL:'In interval',
  COMMON_PROOF_OF_DELIVERY:'Proof of delivery',
  COMMON_REVISION_NO:'Revision No',
  COMMON_NUMBER_OF_REPLY_DELIVERY_DATES:'Number of reply delivery dates',
  COMMON_MANUAL_SYNCHRONIZATION:'Manual synchronization',
  COMMON_SUPPLY_AND_DEMAND_MATCHING_REPORT:'Supply and demand Matching report',
  COMMON_PROOFING_TYPE:'Proofing type',
  COMMON_INITIATE_ESTIMATION_CLEANUP:'Initiate estimation cleanup',
  COMMON_RECONCILIATION_DATE:'Reconciliation date',
  COMMON_HARVEST_QUANTITY:'Harvest quantity',
  COMMON_UNTAXED_HARVEST_AMOUNT:'Untaxed harvest amount',
  COMMON_TAX_INCLUDED_HARVEST_AMOUNT:'Tax included harvest amount',
  COMMON_MATERIAL_SUB_CATEGORY:'Material sub category',
  COMMON_SUPPLIER_SELECTION_NO:'Supplier selection No',
  COMMON_RECONCILIATION_NO:'Reconciliation No',
  COMMON_CLEANING_LIST_NO:'Cleaning list No',
  COMMON_QUANTITY_TO_BE_WRITTEN_OFF:'Quantity to be written off',
  COMMON_ESTIMATED_LIQUIDATION_DOC_NO:'Estimated liquidation doc No',
  COMMON_PRICE_DIFFERENCE_APPLICATION_NO:'Price difference application No',
  COMMON_PRICE_DIFFERENCE_APPLICATION:'Price difference application',
  COMMON_ADVANCE_PAYMENT_NO:'Advance payment No',
  COMMON_RECONCILIATION_PRICE:'Reconciliation price',
  COMMON_PURCHASE_ORDER_ITEM:'Purchase order items',
  COMMON_QUALITY_INSPECTION_STATUS:'Quality inspection status',
  COMMONS_ACCOUNTING_PERIOD:'Accounting period',
  COMMONS_COLUMN_DISPLAY_HIDE:'Column display hide',
  COMMONS_HARVEST_DATE:'Harvest date',
  COMMONS_RECONCILED_QUANTITY:'Reconciled quantity',
  COMMONS_UNRECONCILED_QUANTITY:'Unreconciled quantity',
  COMMONS_PRICE_DIFFERENCE_DOC_NO:'Price difference doc No',
  COMMONS_PREPAYMENT_NOTE_NO:'Prepayment note No',
  COMMONS_ARE_THERE_ANY_COMMENTS:'Are there any comments',
  COMMONS_NEW_BUILD:'New build',
  COMMONS_CAUSE_LOSSES_TO_THE_COMPANY:'Cause losses to the company',
  COMMONS_NO_LOSS_TO_THE_COMPANY:'No loss to the company',
  COMMONS_NO_LOSS_OF_THE_COMPANY:'No loss of the company',
  COMMONS_LOSS_OF_THE_COMPANY:'Loss of the company',
  COMMONS_ESTIMATED_LIQUIDATION_DOC:'Estimated liquidation doc',
  COMMONS_CLEANING_TYPE:'Cleaning type',
  COMMONS_TO_BE_CONFIRMED_BY_THE_SUPPLIER:'To be confirmed by the supplier',
  COMMONS_PENDING_PURCHASE_APPROVAL:'Pending purchase approval',
  COMMONS_PENDING_FINANCIAL_APPROVAL:'Pending financial approval',
  COMMONS_TOTAL_AMOUNT_ORIGINAL_CURRENCY:'Total amount - original currency',
  COMMONS_TOTAL_AMOUNT_FUNCTIONAL_CURRENCY:'Total amount - functional currency',
  COMMONS_REASON_FOR_APPLICATION:'Reason for application',
  COMMONS_APPROVAL_NO:'Approval No',
  COMMONS_APPLICATION_STATUS:'Application status',
  COMMONS_VOUCHER_NO:'Voucher No',
  COMMONS_APPLICATION_TIME:'Application time',
  COMMONS_DIVISION_MANAGER:'Division manager',
  COMMONS_JOB_NUMBER:'Job number',
  COMMONS_MINING_MANAGER:'Mining manager',
  COMMONS_CLEANUP_STATUS:'Cleanup status',
  COMMONS_ADD_DETAILS:'Add details',
  COMMONS_DELETE_DETAILS:'Delete details',
  COMMONS_TEMPORARY_STORAGE:'Temporary storage',
  COMMONS_SUBMIT_APPLICATION:'Submit application',
  COMMONS_SUPPLIER_CONFIRMATION:'Supplier confirmation',
  COMMONS_SUPPLIER_CONFIRMATION_RETURN:'Rejection to Supplier Confirmation',
  COMMONS_UPLOAD_ATTACHMENTS:'Upload attachments',
  COMMONS_ABANDON:'Abandon',
  COMMONS_DEPARTMENT_MANAGER_APPROVAL:'Department manager approval',
  COMMONS_DEPARTMENT_MANAGER_REJECTED:'Department Manager rejected',
  COMMONS_APPROVAL_OF_PROCUREMENT_MANAGER:'Approval of Procurement Manager',
  COMMONS_REJECTE_BY_THE_PROCUREMENT_MANAGER:'Rejected by the procurement manager',
  COMMONS_FINANCIAL_CONFIRMATION:'Financial confirmation',
  COMMONS_FINANCIAL_REJECTION:'Financial rejection',
  COMMONS_ATTACHMENT_NAME:'Attachment name',
  COMMONS_UPLOAD_EVENT:'Upload event',
  COMMONS_UPLOADED_BY:'Uploaded by',
  COMMONS_UNTAXED_HARVEST_AMOUNT:'Untaxed harvest amount',
  COMMONS_AMOUNT_OF_GOODS_INCLUDING_TAX:'Amount of goods including tax',
  COMMONS_AMOUNT_OF_GOODS_INCLUDING_TAX_ORIGINAL_CURRENCY:'Amount of goods including tax - original currency',
  COMMONS_FIVE:'five',
  COMMONS_TEN:'ten',
  COMMONS_VMI:'VMI',
  COMMONS_JIT:'JIT',
  COMMONS_PAY_IN_ADVANCE:'pay in advance',
  COMMONS_PREPAID_WRITE_OFF_DATE:'Prepaid Write off Date',
  COMMONS_ESTIMATED_WRITE_OFF_DATE:'Estimated Write off Date',
  COMMONS_SUPPLIER_CONFIRMED:'Supplier confirmed',
  COMMONS_NAME_OF_MANUFACTURER:'Name of manufacturer',
  COMMONS_AMOUNT_VARIANCE:'Amount variance',
  COMMONS_EXPLANATION_OF_DIFFERENCE_REASON:'Explanation of difference reason',
  COMMONS_DRAFT:'Draft',
  COMMONS_CREATED:'Generated',
  COMMONS_GOODS_TO_BE_RECEIVED:'Pending for receipt',
  COMMONS_REJECT_RECEIPT:'Rejected',
  COMMONS_PENDING_APPROVAL:'Pending approval',
  COMMONS_UNDER_APPROVAL:'Under approval',
  COMMONS_APPROVAL:'Approved',
  COMMONS_REASON_FOR_DIFFERENCE:'Reason for difference',
  COMMONS_PRICE_DIFFERENCE_AMOUNT:'Price difference amount',
  COMMONS_PRICE_DIFFERENCE_STATUS:'Price difference status',
  COMMONS_VIEW_DETAILS:'View details',
  COMMONS_APPROVED_BY_THE_MANAGER_OF_THE_PROCUREMENT_DEPARTMENT:'Department manager approval',
  COMMONS_REJECTED_BY_THE_MANAGER_OF_THE_PROCUREMENT_DEPARTMENT:'Rejected by Department Manager',
  COMMONS_TOTAL_NUM_AMOUNT_ORIGINAL_CURRENCY:'Total amount - original currency',
  COMMONS_TOTAL_NUM_AMOUNT_LOCAL_CURRENCY:'Total amount - local currency',
  COMMONS_DOCUMENT_TYPES:'Document type',
  COMMONS_ATTACHMENT_ATTRIBUTION:'Attachment attribution',
  COMMONS_DEDUCTION_NO:'Deduction No',
  COMMONS_DEDUCTION_AMOUNT:'Deduction amount',
  COMMONS_DEDUCTION_TYPE:'Deduction type',
  COMMONS_DEDUCTION_METHOD:'Deduction method',
  COMMONS_SUPPLIER_CONTACT:'Supplier Contact',
  COMMONS_CONTACT_PHONE:'Contact phone',
  COMMONS_COST_CENTER:'Cost center',
  COMMONS_RESPONSIBLE_DEPARTMENT:'Responsible department',
  COMMONS_PRODUCT_LINE:'Product line',
  COMMONS_DEDUCTION_FORM_STATUS:'Deduction form status',
  COMMONS_TO_BE_CONFIRMED_BY_FINANCE:'To be confirmed by Finance',
  COMMONS_OBSOLETE:'obsolete',
  COMMONS_RECONCILED:'Reconciled',
  COMMONS_PARTIAL_WRITE_OFF:'Partial write off',
  COMMONS_WRITTEN_OFF:'Written off',
  COMMONS_FINE:'fine',
  COMMONS_LOST_TIME:'Lost time',
  COMMONS_SUPPLIER_REBATE:'Supplier rebate',
  COMMONS_REWORK_COST:'Rework cost',
  COMMONS_JOINT_SCRAP:'Joint scrap',
  COMMONS_CUSTOMER_COMPLAINT_COMPENSATION:'Customer complaint compensation',
  COMMONS_IDENTIFICATION_FEE:'Identification fee',
  COMMONS_FAILURE_ANALYSIS_COST:'Failure analysis cost',
  COMMONS_PREPAID_QUANTITY:'Prepaid quantity',
  COMMONS_ADVANCE_RATIO:'Advance ratio',
  COMMONS_PREPAID_AMOUNT:'Prepaid amount',
  COMMONS_PREPAYMENT_STATUS:'Prepayment status',
  COMMONS_REVIEWED:'Reviewed',
  COMMONS_PURCHASE_ORDER_NO:'Purchase order No',
  COMMONS_STATEMENT_STATUS:'Statement status',
  COMMONS_CURRENT_PROCESSOR:'Current processor',
  COMMONS_NUMBER_OF_ROWS:'Number of rows',
  COMMONS_RECONCILIATION_DATE:'Reconciliation date',
  COMMONS_ORDER_TAX_AMOUNT:'Order tax amount',
  COMMONS_COPIES_OF_INVOICE:'Copies of invoice',
  COMMONS_TOTAL_INVOICE_AMOUNT:'Total invoice amount',
  COMMONS_TOTAL_INVOICE_AMOUNT_OF_TAX:'The total amount of the invoice including tax',
  COMMONS_TOTAL_INVOICE_AMOUNT_OF_NOTAX:'The total amount of the invoice that is not taxed',
  COMMONS_THE_TOTAL_AMOUNT_OF_INVOICE_TAX:'The total amount of invoice tax',
  COMMONS_CUSTOMS_DECLARATION_FREIGHT_AND_MISCELLANEOUS_CHARGES:'Customs declaration freight and miscellaneous charges',
  COMMONS_COURIER_NUMBER:'Courier number',
  COMMONS_DIFFERENCE_BEFORE_TAX:'Difference before tax',
  COMMONS_TAX_DIFFERENCE:'Tax difference',
  COMMONS_BASE_DATE:'Base date',
  COMMONS_THEORETICAL_PAYMENT_DATE:'Theoretical payment date',
  COMMONS_INVOICE_PREFABRICATION_VOUCHER:'Invoice prefabrication voucher',
  COMMONS_INVOICE_VERIFICATION_VOUCHER:'Invoice verification voucher',
  COMMONS_PAYMENT_REQUISITION_NO: 'Payment requisition No',
  COMMONS_ACTUAL_PAYMENT_DATE:'Actual payment date',
  COMMONS_RECONCILIATION_GROUP_ID:'Reconciliation group ID',
  COMMONS_FINANCIAL_CONFIRMED:'Financial confirmed',
  COMMONS_INVOICE_TO_BE_PREFABRICATED:'Invoice to be prefabricated',
  COMMONS_INVOICE_PREFABRICATED:'Invoice prefabricated',
  COMMONS_INVOICE_HAS_BEEN_SIGNED:'Invoice has been signed',
  COMMONS_INVOICE_CONFIRMED:'Invoice confirmed',
  COMMONS_PAYMENT_APPLICATION_TO_BE_APPROVED:'Payment application to be approved',
  COMMONS_PAYMENT_APPLICATION_IS_UNDER_APPROVAL:'Payment application is under approval',
  COMMONS_PAYMENT_APPLICATION_HAS_BEEN_APPROVED:'Payment application has been approved',
  COMMONS_ESTIMATED_PAYMENT_DATE:'Estimated payment date',
  COMMONS_Paid:'Paid',
  COMMONS_NO_USE:'Invalid',
  COMMONS_MATERIAL_VOUCHER_NO:'Material voucher No',
  COMMONS_ORDER_UNIT:'Order unit',
  COMMONS_RECEIPT_UNIT_PRICE: 'Receipt unit price',
  COMMONS_SUPPLIER_SELECTION_AND_PRICING_PROCESS_NO:'Supplier selection and pricing process no. of receipt unit price',
  COMMONS_INVOICE_UNIT_PRICE:'Invoice unit price',
  COMMONS_SUPPLIER_SELECTION_AND_PRICING_PROCESS_NO_OF_INVOICE_UNIT_PRICE:'Supplier selection and pricing process no. of invoice unit price',
  COMMONS_UNIT_PRICE_DIFFERENCE_BEFORE_TAX:'Unit price difference before tax',
  COMMONS_DIFFERENCE_OF_UNTAXED_AMOUNT:'Difference of untaxed amount',
  COMMONS_TAX_AMOUNT_DIFFERENCE:'Tax amount difference',
  COMMONS_SUBMIT_FOR_CONFIRMATION:'Submit for confirmation',
  COMMONS_CONFIRMED_BY_THE_MANAGER_OF_THE_PROCUREMENT_DEPARTMENT:'Confirmed by the manager of the procurement department',
  COMMONS_PRICE_DIFFERENCE_TYPE:'Price difference type',
  COMMONS_PRICE_RISE:'Price rise',
  COMMONS_PRICE_REDUCTION:'Price reduction',
  COMMONS_AMOUNT_OF_UNTAXED_PRICE_DIFFERENCE:'Amount of untaxed price difference',
  COMMONS_AMOUNT_OF_PRICE_DIFFERENCE_INCLUDING_TAX:'Amount of price difference including tax',
  COMMONS_THE_TOTAL_AMOUNT_OF_THE_SPREAD_INCLUDING_TAX:'The total amount of the spread including tax',
  COMMONS_THE_TOTAL_AMOUNT_OF_THE_UNTAXED_SPREAD:'The total amount of the untaxed spread',
  COMMONS_APPLICATION_FORM_STATUS:'Application form status',
  COMMONS_REASON_FOR_PRICE_DIFFERENCE:'Reason for price difference',
  COMMONS_AMOUNT_OF_GOODS_NOT_TAXED_ORIGINAL_CURRENCY:'Amount of goods not taxed - original currency',
  COMMONS_AMOUNT_OF_GOODS_NOT_TAXED_FUNCTIONAL_CURRENCY:'Amount of goods not taxed - functional currency',
  COMMONS_CLEANING_TYPE_SECONDARY_CLASSIFICATION:'Cleaning type secondary classification',
  COMMONS_ACCOUNTING_SUPERVISOR_REVIEW:'Accounting supervisor review',
  COMMONS_ACCOUNTING_SUPERVISOR_REJECTED:'Accounting supervisor rejected',
  COMMONS_MANAGER_OF_PROCUREMENT_DEPARTMENT:'Manager of procurement department',
  COMMONS_INVENTORY_DEDUCTION:'Inventory deduction',
  COMMONS_NON_INVENTORY_DEDUCTION:'Non inventory deduction',
  COMMONS_PRINT_DEDUCTION_FORM:'Print deduction form',
  COMMONS_COST_ACCOUNTING_RECOGNITION:'Cost accounting recognition',
  COMMONS_COST_ACCOUNTING_REJECTION:'Cost accounting rejection',
  COMMONS_FINANCIAL_REVIEW_AND_CONFIRMATION:'Financial review and confirmation',
  COMMONS_NO_TAX_DEDUCTION_AMOUNT:'No tax deduction amount',
  COMMONS_TAX_DEDUCTION_AMOUNT:'Tax deduction amount',
  COMMONS_EXPLANATION_OF_DEDUCTION_REASON:'Explanation of deduction reason',
  COMMONS_AMOUNT_INCLUDING_TAX_DEDUCTED:'Amount including tax deducted',
  COMMONS_DEDUCTION_VOUCHER_NO:'Deduction voucher No',
  COMMONS_SUPPLIER_REJECTION:'Supplier rejection',
  COMMONS_SUPPLIER_TRANSFER:'Supplier transfer',
  COMMONS_LOAN_DEDUCTION:'Loan deduction',
  COMMONS_LOSS_TYPE:'Loss type',
  COMMONS_INVENTORY_RELATED:'Inventory related',
  COMMONS_NON_INVENTORY_RELATED:'Non inventory related',
  COMMONS_AMOUNT_OF_DAMAGES:'Amount of damages',
  COMMONS_SUBJECT:'Subject',
  COMMONS_PAYMENT_CONFIRMATION:'Payment confirmation',
  COMMONS_PAYMENT_REJECTION:'Payment rejection',
  COMMONS_REASON_FOR_PREPAYMENT:'Reason for prepayment',
  COMMONS_TERM_OF_PAYMENT:'Term of payment',
  COMMONS_BPO_ITEM:'BPO item',
  COMMONS_UNPAID_QUANTITY:'Unpaid quantity',
  COMMONS_APPLIED_PREPAYMENT_QUANTITY:'Applied prepayment quantity',
  COMMONS_APPLICATION_PREPAYMENT_RATIO:'Application prepayment ratio',
  COMMONS_APPLIED_PREPAYMENT_AMOUNT:'Applied prepayment amount',
  COMMONS_APPLIED_PREPAYMENT_AMOUNT_TAX_INCLUDED:'Applied prepayment amount - tax included',
  COMMONS_APPLIED_PREPAYMENT_AMOUNT_NOTAX_INCLUDED:'Applied prepayment amount - tax not included',
  COMMONS_PREPAID_AMOUNT_OF_NON_TAX_APPLICATION:'Prepaid amount of non tax application',
  COMMONS_PREPAID_AMOUNT_OF_TAX_INCLUSIVE_APPLICATION:'Prepaid amount of tax inclusive application',
  COMMONS_PREPAID_QUANTITY_APPLIED:'Prepaid quantity applied',
  COMMONS_PREPAID_PROPORTION_APPLIED:'Prepaid proportion applied',
  COMMONS_WRITTEN_OFF_QUANTITY:'Written off quantity',
  COMMONS_PREPAID_VOUCHER:'Prepaid voucher',
  COMMONS_RECONCILIATION_ACCOUNTING_CONFIRMATION:'Reconciliation accounting confirmation',
  COMMONS_ACCOUNTING_REJECTION:'Accounting rejection',
  COMMONS_SUBMIT_INVOICE:'Submit invoice',
  COMMONS_PRINT_STATEMENT:'Print statement',
  COMMONS_INVOICE_RECEIPT:'Invoice receipt',
  COMMONS_INVOICE_CONFIRMATION:'Invoice confirmation',
  COMMONS_INVOICE_REJECTION:'Invoice rejection',
  COMMONS_FINANCIAL_REVIEW_REJECTED:'Financial review rejected',
  COMMONS_START_RECONCILIATION:'Start reconciliation',
  COMMONS_ACCOUNTING_RECOGNITION:'Accounting recognition',
  COMMONS_PREFABRICATED_INVOICE:'Prefabricated invoice',
  COMMONS_SIGN_FOR_INVOICE:'Sign for invoice',
  COMMONS_REVIEW_AND_CONFIRMATION:'Review and confirmation',
  COMMONS_PAYMENT_COMPLETED:'Payment completed',
  COMMONS_ESTIMATED_LINES:'Estimated lines',
  COMMONS_RECONCILIATION_AMOUNT_BEFORE_TAX:'Reconciliation amount before tax',
  COMMONS_RECONCILIATION_AMOUNT_INCLUDING_TAXS:'Reconciliation amount including tax',
  COMMONS_REFERENCE_NUMBER:'Reference number',
  COMMONS_FILL_IN_THE_EXPRESS_BILL:'Fill in the express bill',
  COMMONS_ESTIMATION_DETAILS:'Estimation details',
  COMMONS_PRICE_DIFFERENCE_DETAILS:'Price difference details',
  COMMONS_INVOICE_DETAILS:'Invoice details',
  COMMONS_DETAILS_OF_CUSTOMS_DECLARATION_CONTRACT:'Details of customs declaration contract',
  COMMONS_DEDUCTION_DETAILS:'Deduction details',
  COMMONS_PREPAYMENT_DETAILS:'Prepayment details',
  COMMONS_ADVANCE_DELAY_FREEZE_PAYMENT_DETAILS:'Advance / delay / freeze payment details',
  COMMONS_PARKING_DOCEMENT:'Parking docement',
  COMMONS_INITIATE_PRICE_DIFFERENCE:'Initiate price difference',
  COMMONS_PREFABRICATED_VOUCHER_NO:'Prefabricated voucher No',
  COMMONS_PREFABRICATED_INVOICE_NO:'Prefabricated invoice No',
  COMMONS_POSTING_VOUCHER_NO:'Posting voucher No',
  COMMONS_ESTIMATED_UNIT_PRICE:'Estimated unit price',
  COMMONS_INVOICE_UNTAXED_AMOUNT:'Invoice untaxed amount',
  COMMONS_INVOICE_TAX_AMOUNT:'Invoice tax amount',
  COMMONS_RECEIPT_POSTING_DATE:'Receipt posting date',
  COMMONS_MATERIAL_ISSUE_POSTING_DATE:'Material issue posting date',
  COMMONS_EFFECTIVE_DATE:'Effective date',
  COMMONS_PRICE_VARIANCE:'Price variance',
  COMMONS_ADD_INVOICE:'Add invoice',
  COMMONS_QUERY_INVOICE_INFORMATION:'Query invoice information',
  COMMONS_QUERY_SUCCESSFULLY:'Query was successful',
  COMMONS_MODIFY_INVOICE:'Modify Invoice',
  COMMONS_INVOICE_CODE_AGAIN:'Invoice code',
  COMMONS_INVOICE_CODE:'Invoice code',
  COMMONS_INVOICE_NO:'Invoice No',
  COMMONS_UNTAXED_AMOUNT:'Untaxed amount',
  COMMONS_UNTAXED_AMOUNT_INPUT:'Untaxed amount (entered)',
  COMMONS_UNTAXED_AMOUNT_TAX:'Untaxed amount (tax)',
  COMMONS_TAX_AMOUNT:'Tax amount',
  COMMONS_INVOICE_TAX_AMOUNTS:'Invoice tax amount',
  COMMONS_TAX_AMOUNT_INPUT:'Tax amount (entry)',
  COMMONS_TAX_AMOUNT_TAX:'Tax Amount (Tax)',
  COMMONS_TAX_CLASSIFICATION_CODE:'Tax classification code',
  COMMONS_TOTAL_AMOUNT_INCLUDING_TAX:'Total amount including tax',
  COMMONS_TOTAL_AMOUNT_INCLUDING_TAX_INPUT:'Total amount including tax (entered)',
  COMMONS_TOTAL_AMOUNT_INCLUDING_TAXS:'Total amount including tax (tax)',
  COMMONS_INVOICE_DATE:'Invoice date',
  COMMONS_BILLING_DATE:'Invoicing date',
  COMMONS_INVOICE_TYPE:'Invoice type',
  COMMONS_LAST_SIX_DIGITS_OF_THE_CHECK_DIGIT:'The last six digits of the check digit',
  COMMONS_CHECKSUM:'Checksum',
  COMMONS_PURCHASER_TAX_NO:'PurchaserTaxNo',
  COMMONS_PURCHASER_NAME:'PurchaserName',
  COMMONS_SALES_TAX_NO:'SalesTaxNo',
  COMMONS_SALES_NAME:'SalesName',
  COMMONS_AMOUNT_TAX:'Total price and tax',
  COMMONS_INVOICE_STATE:'Invoice status',
  COMMONS_BILLING_STATUS:'Billing status',
  COMMONS_CHECK_TIME:'Check time',
  COMMONS_SALES_BANK:'The seller opens an account with the bank',
  COMMONS_TOTAL_TAX_AMOUNT:'Total tax amount',
  COMMONS_REFINED_OIL_LOGO:'Refined oil logo',
  COMMONS_VEHICLE_TYPE:'Vehicle type',
  COMMONS_BRAND_MODEL:'Brand Model',
  COMMONS_TAX_PAYMENT_CERTIFICATE_NUMBER:'Tax payment certificate number',
  COMMONS_SUBSTITUTE_OPEN_LOGO:'Substitute open logo',
  COMMONS_SPECIAL_POLICY_SIGN:'Special policy sign',
  COMMONS_REAL_TAX_RATE:'Real tax rate',
  COMMONS_REAL_TAX_AMOUNT:'Real tax amount',
  COMMONS_TOLLS_SIGNS:'Tolls signs',
  COMMONS_FILE_DOWNLOAD_LINK:'File download link',
  COMMONS_OFD_DOWNLOAD_ADDRESS:'Ofd file download address',
  COMMONS_PDF_DOWNLOAD_ADDRESS:'Pdf file download address',
  COMMONS_XML_DOWNLOAD_ADDRESS:'Xml file download address',
  COMMONS_CARGO_DETAILS:'Cargo details',
  COMMONS_INVOICE_IMAGE:'Invoice image',
  COMMONS_PAYMENT_AMOUNT:'Payment amount',
  COMMONS_POSTING_VOUCHER:'Posting voucher',
  COMMONS_CONTRACT_AMOUNT:'Contract amount',
  COMMONS_CUMULATIVE_USE: 'Cumulative use (including this cost)',
  COMMONS_THIS_USE:'Amount used this time',
  COMMONS_NOT_USED:'Unused amount',
  COMMONS_RETAIN:'retain',
  COMMONS_REMOVE:'remove',
  COMMONS_ENABLE_RETENTION:'Enable retention',
  COMMONS_ENABLE_RETENTION_SUCCESS:'Enable retention success',
  COMMONS_DEDUCTION_VOUCHER:'Deduction voucher',
  COMMONS_PREPAYMENT_AMOUNT_OF_APPLICATION_FORM:'Prepayment amount of application form',
  COMMONS_UNWRITTEN_OFF_QUANTITY:'Unwritten off quantity',
  COMMONS_APPLIED_WRITE_OFF_QUANTITY:'Applied write off quantity',
  COMMONS_APPLIED_WRITE_OFF_PROPORTION:'Applied write off proportion',
  COMMONS_APPLIED_WRITE_OFF_AMOUNT:'Applied write off amount',
  COMMONS_WRITTEN_OFF_PROPORTION:'Written off proportion',
  COMMONS_APPLICATION_TYPE:'Application type',
  COMMONS_ADVANCE_DELAY_FREEZE_PAYMENT_NO:'Advance / delay / freeze payment No',
  COMMONS_ADVANCE_DELAY_FREEZE_APPLICATION_FORM_STATUS:'Advance / delay / freeze application form status',
  COMMONS_DOCUMENT_NUMBER:'Document number',
  COMMONS_VOUCHER_LN:'Voucher LN',
  COMMONS_SYSTEM_PAYMENT_DATE:'System payment date',
  COMMONS_DATE_OF_APPLICATION_FOR_PAYMENT:'Date of application for payment',
  COMMONS_REASON_DESCRIPTION:'Reason description',
  COMMONS_OPERATION_DATE_AND_TIME:'Operation date and time',
  COMMONS_INVOICE_AMOUNT:'Invoice amount',
  COMMONS_PURCHASER_ADDRESS_TEL:'Purchasers address and telephone',
  COMMONS_PURCHASER_BANK_AND_ACCOUNT:'Opening bank and account number of the purchaser',
  COMMONS_MACHINE_CODE:'Machine code',
  COMMONS_ADDRESS_AND_TEL_OF_SELLER:'Address and telephone of seller',
  COMMONS_TAXES:'Taxes',
  COMMONS_DELIVERY_TIME_IN_ADVANCE_DAYS:'Delivery time in advance (days)',
  COMMONS_STOCK_INTERVAL_LOWER:'Stock interval - lower',
  COMMONS_STOCKING_INTERVAL_UPPER:'Stocking interval - upper',
  COMMON_UPDATE_TIME:'Update time',
  COMMON_MATERIAL_DELIVERY_MAINTENANCE_IN_ADVANCE:'Material delivery maintenance in advance',
  COMMON_BY_DAY:'By day',
  COMMON_BY_INTERVAL:'By interval',
  COMMON_MATERIAL_ADVANCE_AREA:'Material advance area',
  COMMON_ADD_STATEMENT:'Add statement',
  COMMON_DELETE_STATEMENT:'Delete statement',
  COMMON_ADVANCE_PAYMENT:'Advance payment',
  COMMON_LATE_PAYMENT:'Late payment',
  COMMON_FREEZE_PAYMENT:'Freeze payment',
  COMMON_PURCHASING_SUPERVISOR:'Purchasing Supervisor',
  COMMON_PURCHASING_MANAGER:'Purchasing Manager',
  COMMON_FINANCIAL_STAFF:'Financial staff',
  COMMON_INCREASE:'increase',
  COMMON_STATEMENT_AMOUNT_RANGE:'Statement amount range',
  COMMON_INCORPORATED_COMPANY:'Incorporated company',
  COMMON_RECOGNITION_ACCOUNTING:'Recognition accounting',
  COMMON_SAVE_TIME:'Save time',
  COMMON_SAVE_BY:'Save by',
  COMMON_NAME_OF_APPROVER:'Name of approver',
  COMMON_APPROVAL_LABOR_NO:'Approval labor No',
  COMMON_TO_DO_TYPE:'To do type',
  COMMON_ODD_NUMBERS:'Odd numbers',
  COMMON_RECEIVE_TASK:'Receive task',
  COMMON_CLAIM_STATUS:'Claim status',
  COMMON_RECEIVED:'Received',
  COMMON_NOT_CLAIMED:'Not claimed',
  COMMON_PAYABLE_PERSON:'Payable person',
  COMMON_RECIPIENT:'Recipient',
  COMMON_RECONCILIATION_PENDING_POOL:'Reconciliation pending pool',
  COMMON_PREPAID_PENDING_POOL:'Prepaid pending pool',
  COMMON_ESTIMATED_CLEANUP_PENDING_POOL:'Estimated cleanup pending pool',
  COMMON_TASK_TYPE:'Estimated cleanup pending pool',
  COMMON_TASK_LINK:'Task link',
  COMMON_RECEIVING_TIME:'Receiving time',
  COMMONS_PROVISIONAL_ESTIMATE:'Provisional estimate',
  COMMON_DESCRIPTION_OF_COLLECTION_AND_PAYMENT_CONDITIONS:'Description of collection and payment conditions',
  COMMON_TOTAL_AMOUNT_OF_TAX_NOT_APPLIED_FOR_PREPAYMENT:'Total amount of tax not applied for prepayment',
  COMMON_TOTAL_PREPAID_AMOUNT_INCLUDING_TAX_APPLICATION:'Total prepaid amount including tax application',
  COMMON_PREPAID_AMOUNT_INCLUDING_TAX:'Prepaid amount including tax',
  COMMON_ESTIMATED_LIST:'Estimated list',
  COMMON_ESTIMATION_LIST_IN_QUALITY_INSPECTION:'Estimation list - in quality inspection',
  COMMON_ESTIMATED_CLEARING_LIST:'Estimated clearing list',
  COMMON_ESTIMATION_COMPLETED_BY_QUALITY_INSPECTION:'Estimation completed by quality inspection',
  COMMON_ESTIMATION_BACK_OFFSET_FOR_QUALITY_INSPECTION_COMPLETION:'Estimation back offset for quality inspection completion',
  COMMON_RETURN_ESTIMATION:'Return estimation',
  COMMON_BACKFLUSH_FOR_RETURN_ESTIMATION:'Backflush for return estimation',
  COMMON_PRICE_DIFFERENCE_LIST:'Price difference list',
  COMMON_CONFIGURATION_TEMPLATE:'Configuration template of reconciliation accounting confirmation phase',
  COMMON_CONFIGURATION_TEMPLATE_LIST:'Reconciliation Accounting Confirmation Phase Configuration List',
  COMMON_FINANCIAL_APPROVAL:'Financial approval phase configuration template',
  COMMON_FINANCIAL_APPROVAL_LIST:'Statement - Financial Approval Phase Configuration List',
  COMMON_FINANCIAL_AUDIT:'Prepayment Application Process - Financial Approval Phase Configuration Template',
  COMMON_FINANCIAL_AUDIT_LIST:'Prepayment Application Process - Financial Review Phase Configuration List',
  COMMON_MINIMUM_AMOUNT_RANGE:'Minimum amount range',
  COMMON_MAXIMUM_AMOUNT_RANGE:'Maximum amount range',
  COMMON_ENTER_INVOICE:'Enter invoice',
  COMMON_PARTIAL_PAYMENT:'Partial payment (split reconciliation)',
  COMMON_RELATED_TO_RETURN:'Related to return',
  COMMON_NOT_RELATED_TO_RETURN:'Not related to return',
  COMMON_REVERSED:'Reversed',
  COMMON_WRITE_OFF:'Write off',
  COMMON_CLEANING_STATUS:'Cleaning status',
  COMMON_TO_BE_CONFIRMED_BY_THE_MANAGER_OF_THE_PURCHASING_DEPARTMENT:'To be confirmed by the manager of the purchasing department',
  COMMON_PRICE_DIFFERENCE_LIST_STATUS:'Price difference list status',
  COMMON_EXCESSIVE_LOSS_OF_UNPLANNED_PICKING:'Excessive loss of unplanned picking',
  COMMON_POOR_CUSTOMER_SUPPLY:'Poor customer supply',
  COMMON_PAYMENT_METHOD:'Payment method',
  COMMON_PROCESS_TYPE:'Process type',
  COMMON_EST_CLEAR_APPLICATION:'Estimation Clearing Application',
  COMMON_PAYMENT_APPLICATION:'Advance/Delay/Freeze Payment Application',
  COMMON_PRICE_APPLICATION:'Price Variance Application',
  COMMON_DEDUCTION_APPLICATION:'Deduction form application',
  COMMON_FINANCIAL_APPROVER:'Financial approver',
  COMMON_STATEMENT_CONFIGURATION_OF_RECONCILIATION_ACCOUNTING_CONFIRMATION_PHASE:'Statement - Configuration of reconciliation accounting confirmation phase',
  COMMON_STATEMENT_FINANCIAL_AUDIT_LINK_CONFIGURATION: 'Statement - Financial audit link configuration',
  COMMON_PREPAYMENT_FINANCIAL_APPROVAL_PHASE_CONFIGURATION:'Prepayment application process - Financial approval phase configuration',
  COMMON_DEDUCTION_FORM_DEDUCTION_TYPE_ACCOUNT_MAINTENANCE:'Deduction form - deduction type account maintenance',
  COMMON_DEPARTMENT_MANAGER_CONFIGURATION:'Department Manager Configuration',
  COMMON_SUPPLIER_WHITE_LIST_CONFIGURATION:'Supplier white list configuration',
  COMMON_RECONCILING_SUPPLIER_LIST_CONFIGURATION:'Reconciling supplier list configuration',
  COMMON_EMPLOYEE_NAME:'Employee name',
  COMMON_EMPLOYEE_ID:'Employee ID',
  COMMON_NAME_OF_DEPARTMENT_MANAGER:'Name of Department Manager',
  COMMON_MANAGER_JOB_NO:'Manager job No',
  COMMON_DEPARTMENT_MANAGER_CONFIGURATION_LIST:'Department manager configuration list',
  COMMON_DEPARTMENT_MANAGER_CONFIGURATION_TEMPLATE:'Department Manager Configuration Template',
  COMMON_DEDUCTION_TYPE_ACCOUNT_MAINTENANCE_LIST:'Deduction type account maintenance list',
  COMMON_DEDUCTION_TYPE_ACCOUNT_MAINTENANCE_TEMPLATE:'Deduction type account maintenance template',
  COMMON_SUPPLIER_WHITE_LIST_CONFIGURATION_LIST:'Supplier white list configuration list',
  COMMON_SUPPLIER_WHITE_LIST_CONFIGURATION_TEMPLATE:'Supplier white list configuration template',
  COMMON_RECONCILIATION_SUPPLIER_LIST_CONFIGURATION_TEMPLATE:'Reconciliation Supplier List Configuration Template',
  COMMON_PROCESS_PROCESSING:'Process processing',
  COMMON_APPLICATION_TASKS:'My estimation cleanup, advance / delay / freeze payment application tasks',
  COMMON_RECONCILIATION_TASK_ACCOUNTING:'My reconciliation task - reconciliation accounting confirmation phase',
  COMMON_RECONCILIATION_TASK_FINANCE:'My reconciliation task - reconciliation accounting confirmation phase',
  COMMON_RECONCILIATION_TASK_MANAGER:'My reconciliation task - Manager confirmation',
  COMMON_PROCESS_LINK:'Process link',
  COMMON_MY_RECONCILIATION:'My reconciliation',
  COMMON_MY_PREPAYMENT:'My prepayment',
  COMMON_UNFREEZE:'unfreeze',
  COMMON_FROZEN:'frozen',
  COMMON_FROZEN_STATE:'frozen state',
  COMMON_FROZENR:'freezer',
  COMMON_FREEZING_TIME:'freezing time',
  COMMON_RECOVERY:'recovery',
  COMMON_TO_BE_CONFIRMED_BY_ACCOUNTING:'To be confirmed by accounting',
  COMMON_RELATED_PRICE_DIFFERENCE_DOC_NO:'Related price difference doc No',
  COMMON_RELATED_STATEMENT_NO:'Related statement no',
  COMMON_RELATED_ESTIMATION_CLEARING_DOC_NO:'Related estimation clearing doc no',
  COMMON_TOTAL_AMOUNT_DEDUCTED_TAX_INCLUDED:'Total amount deducted Tax included',
  COMMON_SUPPLIER_INVENTORY_LOSS:'Supplier inventory loss',
  COMMON_EXCESSIVE_LOSS_OF_CUSTOMER_SUPPLIED_MATERIALS:'Excessive loss of customer supplied materials',
  COMMON_DEDUCTION_ACCOUNT:'Deduction account',
  COMMON_COST_ACCOUNTING_CONFIRMATION_AMOUNT:'Cost accounting confirmation amount',
  COMMON_BANK_CODE:'Bank code',
  COMMON_TAX_RATE_CODE:'Tax rate code',
  COMMON_CONTACT_EMAIL:'Contact email',
  COMMON_DEDUCTION_AMOUNT:'Deduction amount',
  COMMON_PREPAYMENT_APPLICATION_NO:'Prepayment application No',
  COMMON_PREPAYMENT_APPLICATION_FORM_STATUS:'Prepayment application form status',
  COMMON_BPO_NO:'BPO NO',
  COMMON_PO_NO:'PO NO',
  COMMON_APPROVED_BY:'Approved by',
  COMMON_DEDUCTION_LIST:'Deduction list',
  COMMON_ADVANCE_DELAY_FREEZE_PAYMENT_LIST:'Advance / delay / freeze payment list',
  COMMON_DEDUCTION_FORM_DETAIL_TEMPLATE:'Deduction form detail template',
  COMMON_DEDUCTION_SHEET_DETAILS:'Deduction sheet details',
  COMMON_PREPAYMENT_LIST:'Prepayment list',
  COMMON_VOUCHER_CODE:'Voucher code',
  COMMON_TO_BE_CONFIRMED_BY_COST_ACCOUNTING:'To be confirmed by cost accounting',
  COMMON_TO_BE_CONFIRMED_BY_THE_DEPARTMENT_MANAGER:'To be confirmed by the Department Manager',
  COMMON_ESTIMATION_CLEARING_PREFABRICATION_VOUCHER_NO:'Estimation clearing prefabrication voucher No',
  COMMON_SUPPLIER_EXCESS_LOSS:'Supplier excess loss (including unplanned picking)',
  COMMON_DEFECTIVE_INCOMING_MATERIALS:'Defective incoming materials from suppliers are scrapped',
  COMMON_NAME_OF_APPLICANT:'Name of applicant',
  COMMONS_SRM_ID:'srm_id',
  COMMONS_TO_BE_CONFIRMED_BY_PURCHASE:'to be confirmed by purchase',
  COMMONS_DEDUCTION_PREFABRICATION_VOUCHER_NO:'Deduction prefabrication voucher no',
  COMMONS_WHETHER_IT_IS_IN_THE_WHITE_LIST:'Whether it is in the white list',
  COMMONS_RETURN_MANAGEMENT_DOCUMENT:'Return Management Document',
  COMMONS_ZRDP:'R&D procurement',
  COMMONS_ZNB:'Production procurement',
  COMMONS_ZRWK:'Production return',
  COMMONS_ZSOT:'Entrepot trade order',
  COMMONS_ZSOT_PDF:'Entrepot trade order pdf',
  COMMONS_SJVMI:'Reagent vmi',
  COMMONS_ZGP:'GP Purchase Order',
  COMMONS_ZJJ:'Purchase Order of Machining Center',
  COMMONS_ZNBC:'Standard Purchase Order',
  COMMONS_CUSTOMIZE_DISPLAY_COLUMNS:'Customize Display Columns',
  COMMONS_TOTAL_NO_TAX_AMOUNT_VARIANCE:'Total No Tax Amount Variance',
  COMMONS_TOTAL_TAX_AMOUNT_DIFFERENCE:'Total tax amount difference',
  COMMONS_INTERNATIONAL_RETURN_CYCLE:'International return cycle',
  COMMONS_DOMESTIC_RETURN_CYCLE:'Domestic return cycle',
  COMMONS_FINANCIAL_REVIEWER:'Financial reviewer',
  COMMONS_PO_ORDER_STATUS:'PO Order Status',
  COMMONS_BPO_CONFIRMATION_STATUS:'BPO confirmation status',
  COMMONS_UNHANDLED:'unhandled',
  COMMONS_ABNORMAL:'abnormal',
  COMMONS_THAW:'thaw',
  COMMONS_RETURN_CYCLE_MODIFICATION:'Return Cycle Modification',
  COMMONS_RETURN_CYCLE_MODIFICATION_LIST:'Return Cycle Modification List',
  COMMONS_RETURN_CYCLE_MODIFICATION_TEMPLATE:'Return Cycle Modification Template',
  COMMONS_DEFAULT_PAYMENT_TERMS:'Default Payment Terms',
  COMMONS_AMOUNT_WRITTEN_OFF:'Amount written off',
  COMMONS_AMOUNT_WRITTEN_OFF_INCLUDING_TAX:'Amount written off including tax',
  COMMONS_UNWRITTEN_OFF_AMOUNT:'Unwritten off amount',
  COMMONS_UNWRITTEN_OFF_AMOUNT_INCLUDING_TAX:'Unwritten off amount including tax',
  COMMONS_PAYMENT_TERM_CODE:'Payment Term Code',
  COMMONS_DEFAULT_PREPAYMENT_PROPORTION:'Default prepayment proportion',
  COMMONS_PRICE_INCREASE_ORDER:'Price increase order',
  COMMONS_PRICE_REDUCTION_SHEET:'Price Reduction Sheet',
  COMMONS_FREIGHT_FORWARDING_INFORMATION:'Forwarder information',
  COMMONS_CUSTOMER_ADDRESS:'Customer Address',
  COMMONS_CUSTOMER_PO_NO:'Customer PO',
  COMMONS_CUSTOMER_NAME:'Customer',
  COMMONS_CITY:'City',
  COMMONS_POSTAL_CODE:'Postal code',
  COMMONS_STREET:'Street',
  COMMONS_STREET_ONE:'Street 1',
  COMMONS_STREET_TWO:'Street 2',
  COMMONS_STREET_THREE:'Street 3',
  COMMONS_HOUSE_NUMBER:'Apartment No.',
  COMMONS_COUNTRY:'Country',
  COMMONS_TYPE_OF_SHIPPING:'Logistics',
  COMMONS_ORDER_REASON:'Order type',
  COMMONS_CUSTOMER_MATERIAL_NO:'Customer P/N',
  COMMONS_CUSTOMER_SPECIFIED_BATCH:'Customer indicated batch',
  COMMONS_ESTIMATED_DELIVERY_DATE:'Estimated ship date',
  COMMONS_QUANTITY_OF_QUALITY_INSPECTION_RETURNS:'Quantity of quality inspection returns',
  COMMONS_QUALITY_INSPECTION_RETURN_VOUCHER_NO:'Quality inspection return voucher No',
  COMMONS_DOWNLOAD_INVENTORY_IMPORT_TEMPLATE:'Download inventory import template',
  COMMONS_MAINTAINER:'Maintainer',
  COMMONS_ACCUMULATED_PREPAID_AMOUNT:'Accumulated prepaid amount',
  COMMONS_ACCUMULATED_PREPAID_AMOUNT_INCLUDING_TAX:'Accumulated prepaid amount including tax',
  COMMONS_CUMULATIVE_PREPAYMENT_RATIO:'Cumulative prepayment ratio',
  COMMONS_TO_DO_ARRIVAL_TIME:'To do arrival time',
  COMMONS_SYNCHRONIZE_REFRESH_INVENTORY_INFORMATION:'Synchronize/refresh inventory information',
  COMMONS_EXPORT_DELIVERY_DETAILS_REPORT:'Export Delivery Details Report',
  COMMONS_EXPORT_INVENTORY_INFORMATION_REPORT:'Export inventory information report',
  COMMONS_EXPORT_MATERIAL_ISSUANCE_DETAILS_REPORT:'Export Material Issuance Details Report',
  COMMONS_DELIVERY_DETAIL:'Delivery Detail',
  COMMONS_MATERIAL_ISSUANCE_DETAILS:'Material Issuance Details',
  COMMONS_DELIVERY_EARLY_WARNING:'Delivery early warning',
  COMMONS_REPLENISHMENT_ALERT:'Replenishment Alert',
  COMMONS_RETURN_ALERT:'Return Alert',
  COMMONS_HISTORICAL_DATA:'Historical data',
  COMMONS_HISTORICAL_SYNCHRONIZATION:'Historical synchronization',
  COMMONS_QUALIFIED_INVENTORY_QUANTITY:'Qualified inventory quantity',
  COMMONS_IQC_RETURNED_QUANTITY:'IQC returned quantity',
  COMMONS_EXPORT_COMPREHENSIVE_REPORT:'Export Comprehensive Report',
  COMMONS_Modify_demand_information:'Modify demand information',
  COMMONS_RECALCULATE_CHECK_BOX:'Recalculate check box',
  COMMONS_SYNCHRONIZE_EMERGENCY_NEEDS:'Synchronize emergency needs',
  COMMONS_NORMAL_SYNCHRONIZATION_REQUIREMENTS_COVERAGE:'Normal synchronization requirements (coverage)',
  COMMONS_VIEW_MODIFICATION_HISTORY:'View modification history',
  COMMONS_MAINTENANCE_TIME:'Maintenance time',
  COMMONS_SELECT_DELIVERY_NOTE_DETAILS:'Select delivery note details',
  COMMONS_GENERATEDS_QUANTITY:'Select delivery note details',
  COMMONS_DELIVERY_NOTE_NOS:'Delivery note No',
  COMMONS_PROCUREMENT_BUSINESS:'Procurement business',
  COMMONS_IS_NON_TRADE_FOREIGN_EXCHANGE_PAYMENT:'Is non-trade foreign exchange payment',
  COMMONS_POST_PAYMENT_TERMS:'Post Payment Terms',
  COMMONS_TIME_SLOT:'Time slot',
  COMMONS_PREPAYMENT_NOTE_NOS:'Prepayment note Nos',
  /* FCST模块 */
  COMMON_YEAR:'Year',
  COMMON_YEAR_DAY:'year month day',
  COMMON_FCST_BRAND:'Brand',
  COMMON_VERSION:'Version',
  COMMON_MATERIAL_MODEL:'Material model',
  COMMON_MATERIAL_QUOTA:'Quota',
  COMMON_STORAGE_SITE:'Storage site',
  COMMON_MPR_CONTROLLER:'MPR controller',
  COMMON_PROTECTION_REQUIREMENTS:'Protection requirements',
  COMMON_MATERIAL_IN_STOKE:'srm Inventory',
  COMMON_REPLY_TO_STOKE:'Reply to stock',
  COMMON_IN_TRANSIT_ORDER:'In-transit order',
  COMMON_RECEIPT_THIS_MONTH:'Receipt this month',
  COMMON_RECEIPT_LAST_MONTH:'Receipt last month',
  COMMON_VIEW_RESPONSES:'VIEW RESPONSES',
  COMMON_FCST_International:'International FCST - Historical Response Record',
  COMMON_FCST_NUMBER:'No.',
  COMMON_FCST_EXPORT:'International FCST raw data export_',
  COMMON_FCST_SUPPLY_INVENTORY:'Supplier inventory',
  COMMON_FCST_EXPORT_SUPPLY:'Import available stock',
  COMMON_FCST_UPLOAD_SUPPLY:'Upload Available Inventory',
  COMMONS_EXPORT_OR_IMPORTANT:'export/import templates',
  COMMONS_EXPORT_CLOSE:'CLOST',
  COMMONS_MATERIAL_TYPE:'Material type',
  COMMONS_MRPC:'MRPC',
  COMMONS_MATERIAL_ISSUANCE_DETAILS_REPORT:'Material Issuance Details Report',
  COMMONS_QUOTA_NOT_FOUND:'Quota not found',
  COMMONS_INCORRECT_QUOTA_INFORMATION:'Incorrect quota information',
  COMMONS_NO_NEED_TO_PUBLISH:'No need to publish',
  COMMONS_DEMAND_IS_LESS_THAN_INVENTORY:'Demand is less than inventory',
  COMMONS_NOT_NULL:'Not null',
  COMMONS_FLOATING_VALUE:'Floating value',
  COMMONS_IMPORT_REAGENT_FCST:'Import reagent FCST',
  COMMONS_UPLOAD_REAGENT_FCST:'Upload reagent FCST',
  COMMONS_DOCUMENT_STATUS:'Document status',
  COMMONS_LESS_THAN:'Is it less than the previous period',
  COMMONS_LESS_THAN_IS:'less than the previous period',
  COMMONS_CLASSIFICATION:'Classification',
  COMMONS_OUTSIDE_THE_FCST:'Whether outside the FCST',
  COMMONS_ADDED_OUTSIDE_FCST:'Added outside FCST',
  COMMONS_EXPORT_OUTSIDE_FCST:'Export outside FCST',
  COMMON_FCST_DOMESTIC_EXPORT:'FCST raw data export_',
  COMMON_SUPPLIER_INVENTORY:'supplier inventory',
  COMMONS_ABC:'ABC',
  COMMONS_EXPORT_FCST:'FCST data export',
  COMMONS_EXPORT_ERROR_FCST:'FCST export error message',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT:'announcement:',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT1:'1) The monthly demand is the total demand for the actual production and online production of srm that month',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT2:',without subtracting srm inventory;',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT3:'2) The quantity that the supplier needs to deliver in N months = N months demand - inventory; the number of deliveries required in N+1 months = N months demand + (N+1) monthly demand - inventory; and so on N+2 months Quantity required',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT4:'3) The minimum monthly delivery volume of the supplier must meet the monthly demand of this FCST, and the specific delivery time and quantity shall be realized according to the corresponding PO and delivery rules of srm;',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT5:'4) When the inventory is not 0 and less than the N-month demand, it is judged to be online material shortage; because srm has a large variety of products; the displayed inventory is often occupied and allocated by the work order in progress; so others have not been allocated. of',
  COMMONS_FCST_OUTSIDE_ANNOUNCEMENT6:"The work order may still be in the state of online material shortage. The specific delivery time and urgency are arranged in advance according to srm's PO delivery and rearrangement;",
  // COMMON_LAST_UPDATE_TIME:'Update time',
  COMMON_SURE_DELETE:'Confirm delete?',
  COMMON_ADD_EDIT:'Add/Edit',
  COMMON_CHOOSE_SUPPLIER:'Choose a supplier',
  COMMON_DOWNLOAD_MODAL:'Download template',
  COMMON_SURE_PASS:'Confirmed',
  COMMON_CONFIRM_REJECTION:'Confirm rejection',
  COMMON_STATUS:'protocol status',
  COMMON_REPLY_TYPE:'reply type',
  COMMON_IS_ADD:'whether to participate in statistics',
  COMMON_FCST_STOCK_UP:'FCST stocking',
  COMMON_INVENTORY_ARRIVAL:'Inventory & arrival',
  COMMON_YES:'Yes',
  COMMON_NO:'No',
  COMMON_AGREEMENT_TYPE:'Agreement type',
  COMMON_AGREEMENT_MATH:'Number of agreements',
  COMMON_QUANTITY:'Minimum power on quantity',
  COMMON_WEEK:'Required stocking weeks',
  COMMON_AUDIT_TIME:'Audit time',
  COMMON_MATERIAL_INFORMATION:'Material agreement information',
  COMMON_STOCK_REPORT:'Stock comprehensive report',
  COMMON_STOCK_REPORT_TITLE1:'Stocking achievement rate',
  COMMON_STOCK_REPORT_TITLE2:'Safety stock agreement achievement rate',
  COMMON_ALERT_N: 'N month warning',
  COMMON_ALERT_N1: 'N+1 month warning',
  COMMON_ALERT_N2: 'N+2 month warning',
  COMMON_ALERT_N3: 'N+3 month warning',
  COMMON_ALERT_YEAR_N1: 'There is an early warning for the first two months',
  COMMON_ALERT_YEAR_N2: 'There is an early warning for the first three months',
  COMMON_ALERT_YEAR_N3: 'There is an early warning in any month',
  COMMON_SYSTEM: 'Inventory factor',
  COMMON_INTERVAL:'Lead Time interval',
  COMMON_SUPPLIER_HAS: 'Supplier stocking achievement rate',
  COMMON_DAYS:'Lead Time (days)',
  COMMON_WAIT_RETURN: 'Un-responded',
  COMMON_WAIT_SURE: 'to be confirmed',
  COMMON_SUPPLIER_REPLY_TIME: 'Supplier reply time',
  COMMON_DESCRIPTION:'describe',
  COMMON_RAW_MATERIAL:'Semi-finished products & raw materials',
  COMMON_RAW_MATERIAL_PRODUCT:'Semi-finished products',
  COMMON_RAW_MATERIAL_ALL:'raw materials',
  COMMON_RELEASE:'release',
  COMMON_PLEASE_CHOOSE:'please choose',
  COMMON_REPLY_PEOPLE:'Responder',
  COMMON_INSTRUMENT:'instrument',
  COMMON_REAGENT:'reagent',
  COMMONS_GROUP:'group',
  COMMONS_REMARK_INFORMATION:'FCST remark information maintenance',
  COMMONS_SUCCESS:'success',
  COMMONS_INFORMATION_CHANGE:'Material Agreement Information Maintenance',
  COMMONS_AGO:'History reply record',
  COMMONS_FCST_DOWNLOAD_MODULE:'FCST import template',
  COMMONS_FCST_DOWNLOAD_MODULE_OUT:'FCST outside import template',
  COMMONS_HINT:'hint',
  COMMONS_CHECK_ONE:'Check at least one',
  COMMONS_ANNOUNCEMENTS:'P: Refers to the quantity expected to be delivered to srm in the current month; C1: Indicates the available quantity confirmed in the current period; C2: Indicates the available quantity confirmed in the previous period; B: Indicates Balance;',
  COMMONS_REPLY_AND_GET:'In Stock & In Stock',
  COMMONS_EXPORT_PEOPLE:'Import reagent specialist',
  COMMONS_RELEASE_PEOPLE: 'Release Specialist',
  COMMONS_HOLIDAY:'T+ since release date',
  COMMONS_HOLIDAY_DAY:'days,the system counts the completion rate of stocking',
  COMMONS_STATUS_NO: 'Unaudited',
  COMMONS_STATUS_PASS: 'Approval passed',
  // COMMONS_RAW_MATERIAL_IMPORTANT:'import template',
  COMMONS_FCST_EXPORT_NAME: 'Domestic FCST material agreement information import',
  COMMONS_FCST_EXPORT_NAME1: 'Domestic FCST material agreement information export',
  COMMONS_FCST_EXPORT_NAME2: 'Export error message',
  COMMONS_INPUT_NUMBER:'Must be greater than or equal to 0',
  COMMONS_SUPPLIER_STOCK:'Inventory cannot be empty',
  COMMONS_THE_FIRST:'First',
  COMMONS_ROW:'row',
  COMMONS_RAW_MATERIAL_EXPORT:'Raw material & semi-finished product export data',
  COMMONS_RAW_MATERIAL_IMPORTANT: 'Raw material & semi-finished product import template',
  COMMONS_HOLIDAY_NO_EMPTY:'Days cannot be empty',
  COMMONS_DEDUCTION_WRITE_OFF_AMOUNT: 'Write off amount',
  COMMONS_DEDUCTION_AMOUNT_PAID:'Actual payment amount',
  COMMONS_DELAYED_PAYMENT:'Initiate advance/freeze/delay payment',
  COMMONS_CONSOLIDATED:'Consolidated Statement',
  COMMONS_DOWNLOAD_IMG: 'Download image',
  COMMONS_IMPORTANT_INVOICE_IMG: 'Import Invoice Image',
  COMMONS_IMPORTANT_INVOICE:'Import Invoice',
  COMMONS_ACCOUNT_INVOICE_EXPORT:'Export statement invoice data',
  COMMONS_ACCOUNT_PRICE_DIFF_EXPORT:'Data export of monthly statement price difference',
  COMMONS_ACCOUNT_DEDUCTION_EXPORT:'Export statement deduction data',
  COMMONS_ACCOUNT_CONTRACT_EXPORT:'Export statement customs declaration contract data',
  COMMONS_ACCOUNT_ADVANCED_EXPORT:'Export statement prepayment data',
  COMMONS_ACCOUNT_CERFITICATE_EXPORT:'Export of statement Parked Voucher Data',
  COMMONS_FREIGHT_LINE:'freight line',
  COMMONS_DATA_RANGE:'date range',
  COMMONS_CUMULATIVE_USE_NO:'Cumulative use',
  COMMONS_POSTSCRIPT:'Remittance postscript',
  COMMONS_WRITE_OFF_QUANTITY: 'This write-off quantity',
  COMMONS_WRITE_OFF_RATIO: 'This write-off ratio',
  COMMONS_CLICK_UPLOAD:'Click to upload',
  COMMONS_GROSS_P: 'Announcement: The suppliers in this list, the FCST information page can view the monthly gross demand (srm inventory has not been reduced);',
  COMMONS_GROSS_P1: 'For suppliers who are not in this list, check the net demand for each month on the FCST information page (srm inventory has been subtracted);',
  COMMONS_EXPORT_NAME: 'Material Agreement Information Maintenance Export',
  COMMONS_IMPORTANT_NAME:'Material Agreement Information Maintenance Import Template',
  COMMONS_EXPORT_MESSAGE_NAME: 'Information query export',
  COMMONS_EXPORT_REPLY_NAME: 'Reply available for inventory export',
  COMMONS_IMPORTANT_REPLY_NAME: 'Reply Available Inventory Import Template',
  COMMONS_PRICE_DIFF_DETAILS_NO: 'Spread Order Detail Code',
  COMMONS_PRICE_DIFF_NO:'Spread Order Number',
  COMMONS_ESTIMATED:'estimated number',
  COMMONS_INVENTORY_LOSS:'Inventory loss',
  COMMONS_INVENTORY_NO_LOSS: 'Non-inventory losses',
  COMMONS_SURE_DELETE_DEBIT: 'Are you sure you need to delete the deduction details?',
  COMMONS_SURE_DELETE_PRICE_DIFF: 'Are you sure you need to delete the price difference details?',
  COMMONS_SURE_DELETE_ESTIMATION: 'Are you sure you need to delete the estimation details?',
  COMMONS_SURE_DELETE_INVOICE: 'Are you sure you need to delete the invoice?',
  COMMONS_EXPRESS_COMPANY:'Express company',
  COMMONS_PREPAYMENT:'Prepayment Details',
  COMMONS_DISMISSED_TO: 'Dismiss to:',
  COMMONS_MIN_SHELF_LIFE: 'Remaining validity period on arrival',
  COMMONS_BILL_EXPORT_MODAL:'Import template for monthly statement invoice',
  COMMONS_BILL_RMB_EXPORT_MODAL:'Statement RMB invoice import template',
  COMMONS_BILL_FOREIGN_EXPORT_MODAL:'Statement foreign currency invoice import template',
  COMMONS_ACCOUNT_DATA_CONFIRM_TITLE_SUPPLIER: 'Whether the reconciliation confirmation passed',
  COMMONS_ACCOUNT_DATA_CONFIRM_SUPPLIER: 'The reconciliation data will be confirmed, and the statement status will be updated after confirmation',
  COMMONS_ACCOUNT_DATA_CONFIRM_TITLE_ACCOUNTING: 'Whether the reconciliation confirmation passed',
  COMMONS_ACCOUNT_DATA_CONFIRM_ACCOUNTING: 'The reconciliation data will be confirmed, and the statement status will be updated after confirmation',
  COMMONS_ACCOUNT_DATA_CONFIRM_TITLE_INVOCING: 'Is the invoice submitted through',
  COMMONS_ACCOUNT_DATA_CONFIRM_INVOCING: 'The supplier invoice data will be confirmed, and the statement status will be updated after confirmation',
  COMMONS_ACCOUNT_DATA_CONFIRM_TITLE_INVOCING_GET: 'Is the submitted invoice passed',
  COMMONS_ACCOUNT_DATA_CONFIRM_INVOCING_GET: 'The supplier invoice data will be confirmed, and the statement status will be updated after confirmation',
  COMMONS_ACCOUNT_DATA_CONFIRM_TITLE_INVOCING_SURE: 'Please confirm whether the invoice is approved',
  COMMONS_ACCOUNT_DATA_CONFIRM_INVOCING_SURE: 'The supplier invoice data will be confirmed, and the statement status will be updated after confirmation',
  COMMONS_ACCOUNT_DATA_CONFIRM_TITLE_FINANCE_SURE: 'Whether the financial review passed',
  COMMONS_ACCOUNT_DATA_CONFIRM_FINANCE_SURE: 'The financial audit data will be confirmed, and the statement status will be updated after confirmation',
  COMMONS_RETURN_CONFIRM: 'Is the invoice confirmation rejected? ',
  COMMONS_RETURN_REASON: 'Please enter the reason for rejection',
  COMMONS_REASON_RETURN_CONFIRM: 'Is the reconciliation confirmation rejected? ',
  COMMONS_FINANCE_RETURN_CONFIRM: 'Is the financial confirmation rejected? ',
  COMMONS_ACCOUNT_MONTH_EXPORT:'Export statement data',
  STATEMENT_LIST:'Statement List',
  COMMONS_CAN_NOT_DO: 'The reconciliation type is with the goods and cannot initiate advance/freeze/delay payment',
  COMMONS_MERGE_BILL_ORDER: 'Are you sure you want to merge statements? ',
  COMMONS_MERGE_BILL_ORDER_MESSAGE: 'Bill Merge',
  COMMONS_SHIPPING_CHARGES:'Actual shipping and miscellaneous charges',
  COMMONS_CURRENT_EDITOR:'current editor',
  COMMONS_FINANCIAL_OFFICER:'financial reconciliation officer',
  COMMONS_GET_INVOICE:'Reject to enter invoice',
  COMMONS_ADD_DEBIT:'Add a debit slip',
  COMMONS_DELETE_DEBIT:'Delete debit slip',
  COMMONS_CHOOSE_DATE:'date selection',
  COMMONS_PLEASE_CHOOSE_DATE:'Please select a date range',
  COMMONS_TIMER_NAME:'Timer name',
  COMMONS_CHANGE_ACCOUNTMATH: 'The modified reconciliation quantity cannot be less than or equal to 0',
  COMMONS_CHANGE_ACCOUNTMATH1: 'The modified reconciliation quantity cannot be greater than the unreconciled quantity',
  COMMONS_PAYMENT_DETAILS:'Advance payment details',
  COMMONS_CURRENT_WRITE_OFF_AMOUNT:'Current write off amount',
  COMMONS_NOT_PRICEDIFF:'This estimation document has already initiated price variance, and price variance cannot be initiated repeatedly',
  COMMONS_ALREADY_AMOUNT_WRITE_OFF:'Amount written off',
  COMMONS_NOT_AMOUNT_WRITE_OFF:'Unwritten off amount',
  ESTIMATED_NUMBER:'Estimation Doc No',
  ESTIMATED_CAN_WRITE_OFF:'Write off amount',
  COMMONS_TOTAL_RECONCILIATION:'Total reconciliation quantity',
  COMMON_FINANCIAL_SUPERVISOR:'Accounting supervisor',
  COMMON_FINANCIAL_RELEASE:'Release specialist of semi-finished raw materials',
  COMMONS_FINANCIAL_POST:'Financial sign in post',
  COMMONS_COST_ACCOUNTING:'cost accounting',
  COMMONS_TQC:'TQC',
  COMMONS_INVOICE_MONEY:'Invoice balance',
  COMMONS_SURE_PRICE_DIFF:'Are you sure you want to delete price difference details?',
  COMMONS_CANCEL_MESSAGE:'If there is a checked data in the list, please uncheck it and add it',
  COMMONS_YEAR: 'Year and month cannot be empty',
  COMMONS_YEAR_AND: 'The selected month range cannot be greater than 6 months',
  COMMONS_DOWNLOAD_CAN: 'Download available inventory templates',
  COMMONS_DOWNLOAD_FCST: 'Download FCST external template',
  COMMONS_FCST_CANGET:'FCST Available Inventory Import Template',
  COMMONS_FCST_NAME:'International FCST_',
  COMMONS_FCST_ERROR:'International FCST_ERROR_',
  COMMONS_FCST_EXPORT_TEMPLATE:'Import Template',
  COMMONS_ORDER_CONFIRM:'Order confirmed',
  COMMONS_SUPPLIER_CONFIRM:'Supplier confirms order',
  COMMONS_TIPS:'Tips: The supplier must confirm the order before subsequent operations can be carried out;',
  COMMONS_SURE_TIME:'Confirmation time',
  COMMONS_EIKTO:'eikto',
  COMMONS_LIFNR:'lifnr',
  COMMONS_DISPO:'dispo',
  COMMONS_LSSUE_DUDAT_END:'lssueDudatEnd',
  COMMONS_MATNR:'matnr',
  COMMONS_ISCONFIRM:'isconfirm',
  COMMONS_LSSUE_DUDAT_START:'lssueDudatStart',
  COMMONS_WERKS:'werks',
  COMMONS_MEINS:'meins',
  COMMONS_FLHZ:'flhz',
  COMMONS_SINSM:'sinsm',
  COMMONS_SLABS:'slabs',
  COMMONS_SLABX:'slabx',
  COMMONS_LIFNRNAME:'lifnrName',
  COMMONS_CONFIR_DATE:'confirmDate',
  COMMONS_EIKTOSTR:'eiktoStr',
  COMMONS_INDEXS:'indexs',
  COMMONS_MBLNR:'mblnr',
  COMMONS_EBELN:'Purchasing Documents',
  COMMONS_MENGNE:'mengne',
  COMMONS_BUDAT:'budat',
  COMMONS_BASIC_UNIT:'Basic unit',
  COMMONS_BATCH:'batch',
  COMMONS_MRP_CONTROLLER:'Mrp controller',
  COMMONS_SEQUENCE:'sequence',
  COMMONS_DELIVERY_LIST_CREATION_TIME:'Delivery list creation time',
  COMMONS_SUPPLIER_CONFIRMS_ORDER:'Supplier confirms order',
  COMMONS_SUPPLIER_MATERIAL_MODEL:'Supplier material model',
  COMMONS_MPR_CONTROLLER:'MPR controller',
  COMMONS_ORDER_CONFIRMED:'Order confirmed',
  COMMONS_CONFIRMATION_SUCCEEDED:'Confirmation succeeded',
  COMMONS_SUCCESSFUL_REPLY_TO_DELIVERY_DATE:'Successful reply to delivery date',
  COMMONS_PURCHASE_CONFIRMATION_SUCCEEDED:'Purchase confirmation succeeded',
  COMMONS_UNRECEIVED_QUANTITY:'Unreceived quantity',
  COMMONS_BATCH_DELIVERY_PLAN:'Batch delivery plan',
  COMMONS_TO_BE_REPLIED_BY_THE_SUPPLIER:'To be replied by the supplier',
  COMMONS_PLEASE_SELECT_A_TIME:"Please select a time",
  COMMONS_PLEASE_SELECT_TYPE:'Please select type',
  COMMONS_PRODUCTION_DATE_CANNOT_BE_BLANK:'Production date cannot be blank',
  COMMONS_EXPIRATION_DATE_CANNOT_BE_BLANK:'Expiration date cannot be blank',
  COMMONS_PRODUCTION_BATCH_CANNOT_BE_EMPTY:'Production batch cannot be empty',
  COMMONS_EXPIRATION_DATE_MUST_BE_GREATER_THAN_CURRENT_DATE:'Expiration date must be greater than current date',
  COMMONS_NO_DELIVERY_NOTE_DETAILS_NO_DELIVERY_NOTE_CAN_BE_GENERATED:'No delivery note details, no delivery note can be generated',
  COMMONS_PLEASE_SELECT_THE_DELIVERY_SLIP_WITH_THE_SAME_SUPPLIER_NUMBER:'Please select the delivery slip with the same supplier number',
  COMMONS_DELETE_PRICE_DIFFERENCE_DOC:'Delete Price Difference Doc',
  COMMONS_TAX_DEDUCTION_ALL_AMOUNT:'Total amount of tax deduction',
  COMMONS_ADD_PRICE_DIFFERENCE:'Add Price Difference',
  COMMONS_RECONCILIATION_QUANTITYS:'Reconciliation quantity',
  COMMONS_ROWNO:'Line number',
  COMMONS_COMMODITYNAME:'Name of goods or taxable services',
  COMMONS_LICENSE_PLATE_NUMBER:'License plate number',
  COMMONS_START_DATE:'Start date',
  COMMONS_END_DATE:'End date',
  COMMONS_FULL_AMOUNT:'Full amount',
  COMMONS_FLOATING_VALUE_LIST:'Floating value list',
  COMMONS_BPO_ORDER_NO:'BPO No',
  COMMONS_WHETHER_BPO_IS_CLOSED:'Whether BPO is closed',
  COMMONS_DATE_CERTIFICATE:'Certificate Date',
  COMMONS_NOT_CLOSED:'Not closed',
  COMMONS_LARGE_ORDER_TYPE:'Large order type',
  COMMONS_NON_LTB:'Non LTB',
  COMMONS_STOP_PRODUCTION_LTB:'Stop production LTB',
  COMMONS_BPO_VOUCHER_DATE:'BPO voucher date',
  COMMONS_BPO_TOTAL:'Total BPO',
  COMMONS_QUANTITY_DELIVERED:'Quantity delivered',
  COMMONS_PO_QUANTITY_RELEASED:'PO quantity released',
  COMMONS_EXECUTABLE:'Executable',
  COMMONS_ABNORMAL_CAUSES:'Abnormal causes',
  COMMONS_SUPPLIER_INVENTORY_PREPARATION_METHOD:'Supplier"s inventory preparation method',
  COMMONS_SUPPLIER_INVENTORY_REQUIRED:'Supplier inventory required',
  COMMONS_QUANTITY_OR_WEEK:'Quantity/week',
  COMMONS_CURRENT_VERSION:'Current version',
  COMMONS_HISTORICAL_VERSION:'Historical version',
  COMMONS_IMPLEMENTATION_RATE_RANGE:'Implementation rate range',
  COMMONS_CONSUMPTION_RATE_RANGE:'Consumption rate range',
  COMMONS_TOTAL_IN_TRANSIT_PO: 'Total in transit PO',
  COMMONS_IN_TRANSIT_PO: 'in transit PO',
  COMMONS_BACKLOG_OR_SEMI_FINISHED_PRODUCTS: 'Backlog/semi-finished products',
  COMMONS_THEORETICAL_REMAINING_AVAILABLE_QUANTITY: 'Theoretical remaining available quantity',
  COMMONS_ACTUAL_STOCK_VARIANCE: 'Actual stock variance',
  COMMONS_AVERAGE_MONTHLY_DEMAND: 'Average monthly demand',
  COMMONS_BPO_DELIVERY_RATE: 'BPO delivery rate',
  COMMONS_NUMBER_OF_MONTHS_COVERED_BY_UNUSED_THEORY: 'Number of months covered by unused theory',
  COMMONS_BPO_IMPLEMENTATION_RATE: 'BPO implementation rate',
  COMMONS_MC_CONFIRMATION: 'MC confirmation',
  COMMONS_PUBLISHED_OR_NOT: 'Published or not',
  COMMONS_PUBLISH_BPO: 'Publish BPO',
  COMMONS_CONFIRM_PUBLISH_BPO: 'Are you sure to release Bpo information?',
  COMMONS_CONFIRM_THE_RELEASE_OF_BPO_REPLY_FOR: 'Confirm the release of BPO reply for?',
  COMMONS_BPO_QUERY: 'BPO Query',
  COMMONS_BPO_CONFIRMATION_REPORT: 'BPO Confirmation Report',
  COMMONS_BPO_REPLY_AVAILABLE_REPORT: 'BPO Reply Available Report',
  COMMONS_SUPPLIER_BPO_REMAINING_AVAILABLE_QUANTITY: 'Supplier BPO remaining available quantity',
  COMMONS_PUBLISH_BPO_REPLIES_FOR: 'Publish BPO replies for',
  COMMONS_BPO_COMPREHENSIVE_REPORT: 'BPO comprehensive report',
  COMMONS_REFERENCE_FCST_INVENTORY: 'Reference FCST inventory',
  COMMONS_CONFIRM_TO_QUOTE_FCST_TO_REPLY_INVENTORY: 'Confirm to quote FCST to reply inventory?',
  COMMONS_BATCH_UPLOAD: 'Batch upload',
  COMMONS_UPLOAD_SUPPLIER_RESPONSE_INFORMATION: 'Upload supplier response information',
  COMMONS_BPO_REPLY_AVAILABLE_REPORT_ANNOUNCEMENT:'announcement:',
  COMMONS_BPO_REPLY_AVAILABLE_REPORT_ANNOUNCEMENT1:'1) Supplier Inventory: refers to the supplier end finished product inventory.',
  // COMMONS_BPO_REPLY_AVAILABLE_REPORT_ANNOUNCEMENT2:'2) In transit quantity: refers to the quantity that has been sent to srm but not posted during the period from BPO release to SRM and supplier reply to inventory.',
  // COMMONS_BPO_REPLY_AVAILABLE_REPORT_ANNOUNCEMENT3:'3) Semi finished Product/Backlog: refers to the quantity that can be converted into a complete set of finished products, or the backlog quantity.',
  COMMONS_BPO_REPLY_AVAILABLE_REPORT_ANNOUNCEMENT4:'2) Note: When importing by template, please do not change the template, otherwise the import will not succeed.',
  COMMONS_TASK_TRANSFER: 'Task transfer',
  COMMONS_ORIGINAL_TASK_PROCESSING_LABOR_NUMBER: 'Original task processing labor number',
  COMMONS_NEW_TASK_PROCESSING_LABOR_NUMBER: 'New task processing labor number',
  COMMONS_NAME_OF_ORIGINAL_TASK_HANDLER: 'Name of original task handler',
  COMMONS_NAME_OF_NEW_TASK_HANDLER: 'Name of new task handler',
  COMMONS_IMPORT_REQUIREMENTS: 'Import Requirements',
  COMMONS_REQUIREMENTS_TEMPLATE: 'Requirements template',
  COMMONS_SUMMARY_INFORMATION_OF_NOTIFICATION_ARRIVAL_DEMAND: 'Summary information of notification arrival demand',
  COMMONS_IQC_ACCOUNT_NUMBER: 'IQC account number',
  COMMONS_PERFORMANCE_MONTH:'Performance month',
  COMMONS_TOTAL_NUMBER_OF_ITEMS:'Total number of items',
  COMMONS_THE_NUMBER_OF_OVERDUE_ITEMS:'The number of overdue items',
  COMMONS_DELIVERY_RATE:'Delivery rate',
  COMMONS_REQUIREMENT_NUMBER:'Requirement number',
  COMMONS_WHETHER_IT_IS_OVERDUE:'Whether it is overdue',
  COMMONS_START_RECONCILIATION_DATE:'Start reconciliation date',
  COMMONS_PLEASE_SEPARATE_MULTIPLE_WITH_COMMAS:'Please separate multiple with commas',
  COMMONS_RECONCILIATION_ACCOUNTING:'Reconciliation accounting',
  COMMONS_AUDIT_ACCOUNTING:'Audit accounting',
  COMMONS_LEADER_NAME:'Leader name',
  COMMONS_EXPORT_EXCEL:'export Excel',
  COMMONS_EXCEL_IMPORT:'Excel import',
  COMMONS_LEAD_SIGNATURE_IMPORT:'Lead signature import',
  COMMONS_LEADER_SIGNATURE:'Leader signature',
  COMMONS_LEADER_SIGNATURE_TEMPLATE:'Leader signature template',
  COMMONS_PART_NUMBER:'Part number',
  COMMONS_MANUFACTURERS:'Manufacturers',
  COMMONS_ORIGIN:'Origin',
  COMMONS_WAYS_OF_TRADE:'Ways of trade',
  COMMONS_CUSTOMS_NUMBER:'Customs number',
  COMMONS_PARAMETER:'parameter',
  COMMONS_TRADING_COUNTRIES:'Trading countries',
  COMMONS_FIRST_NOTE:'First note',
  COMMONS_SECOND_NOTE:'Second note',
  COMMONS_CUSTOMS_CLEARANCE_MODEL:'Customs Clearance Model',
  COMMONS_SYNCHRONIZE_DATA:'Synchronize data',
  COMMONS_SEQUENCE_NUMBER:'Sequence number',
  COMMONS_DRIVER:'driver',
  COMMONS_LAST_PICKUP_TIME:'Last pickup time',
  COMMONS_ARIBA_CONTRACT_NO:'Ariba Contract No',
  COMMONS_IMPORT_AND_EXPORT_BUSINESS_APPLICATION_FORM:'Import and export business application form',
  COMMONS_ROUGH_WEIGHT:'rough weight',
  COMMONS_MATERIAL_DETAILS:'Material details',
  COMMONS_INVOICE_VERIFICATION_VOUCHER_NO:'Invoice verification voucher No',
  COMMONS_ACCOUNTING_VOUCHER_NUMBER:'accounting voucher number',
  COMMONS_CUSTOMS_DECLARATION_DATE:'Customs declaration date',
  COMMONS_AMOUNT_OF_FOREIGN_EXCHANGE_PAYMENT:'Amount of foreign exchange payment',
  COMMONS_TOTAL_FOREIGN_EXCHANGE_PAYMENT:'Total foreign exchange payment',
  COMMONS_CUSTOMS_DECLARATION_FEES_AND_MISCELLANEOUS_CHARGES:'Customs declaration fees and miscellaneous charges',
  COMMONS_DETAILED_STATEMENT_OF_VOUCHER_PAYMENT_IN_FOREIGN_EXCHANGE:'Detailed statement of voucher payment in foreign exchange',
  COMMONS_PROCESS_STATUS:'Process status',
  COMMONS_FLOATING_VALUE_TEMPLATE:'Floating value template',
  COMMONS_SURE_UPLOAD_FILE:'Are you sure you want to upload a file?',
  COMMONS_UNIT_PRICE_WITHOUT_TAX:'Unit price without tax',
  COMMONS_CONFIRM_TO_SAVE:'Confirm that you want to save?',
  COMMONS_SYNCHRONIZATION_SUCCEEDED:'Synchronization succeeded',
  COMMONS_EXPORT_APPLICATION_FORMS:'Export import and export application forms',
  COMMONS_ADJUST_CUSTOMS_CONTRACTS:'Adjust customs contracts',
  COMMONS_TOTALS:'total',
  COMMONS_POST_TAXES:'Post taxes',
  COMMONS_SUBMIT_REPLY_DELIVERY_DATE:'Are you sure of the deadline for submitting your response?',
  COMMONS_NOTIFICATION_OF_ARRIVAL_DELAY_RESPONSE_RECORDS:'Notification of arrival delay response records',
  COMMONS_NOTIFICATION_ARRIVAL_PERFORMANCE_STATISTICS_REPORT:'Notification arrival performance statistics report',
  COMMONS_NOTIFICATION_ARRIVAL_PERFORMANCE_REPORTS:'Notification arrival performance reports',
  COMMONS_FABRICATION_VOUCHER_AMOUNT:'Fabrication voucher amount',
  COMMONS_TOTAL_AMOUNT_OF_PREFABRICATED_TAX:'Total amount of prefabricated tax',
  COMMONS_REQUIREMENTS_FILE:'Requirements file',
  COMMONS_SUPPLIER_DELIVERY_NOTE:'Supplier delivery note',
  COMMONS_WORK_TICKETS:'Work Tickets',
  COMMONS_TICKET_STATUS:'Ticket status',
  COMMONS_REL:'REL',
  COMMONS_CRTD:'CRTD',
  COMMONS_WHETHER_SATISFIED:'Whether it is satisfied',
  COMMONS_AVAILABLE:'Available',
  COMOMNS_AVAILABLE_TIME:'Available for time',
  COMONS_MANTISSA_IS_AVAILABLE_FOR_TIME:'The mantissa is available for time',
  COMMONS_QUANTITY_TO_BE_MEASURED:'The quantity to be measured',
  COMMONS_NUMBER_OF_MANTISSAS:'The number of mantissas',
  COMMONS_VIEW_RECORDS_IN_BATCHES:'View records in batches',
  COMMONS_REPLY_SUBMISSIONS_IN_BATCHES:'Reply submissions in batches',
  COMMONS_EXPORT_THE_PCBA_TICKET_LIST:'Export the PCBA ticket list',
  COMMONS_IMPORT_PCBA_TICKET_LIST_INFORMATION:'Import PCBA ticket list information',
  COMMONS_PCBA_TICKETS_LIST:'List of PCBA tickets',
  COMMONS_IMPORT_PCBA_TICKET_LIST:'Import the PCBA ticket list',
  COMMONS_CONFIRM_TO_SUBMIT:'Confirm to submit?',
  COMMONS_DATE_OF_RESPONSE:'Date of response',
  COMMONS_WHETHER_TO_STORE_IN_BATCHES:'Whether to store in batches',
  COMMONS_REL_DATE:'REL time',
  COMMONS_NUMBER_OF_TICKETS:'Number of tickets',
  COMMONS_DEMAND_BEFORE_TODAY:'Demand before today',
  COMMONS_PRODUCTION_LINE:'Production line',
  COMMONS_DOWNLOAD_PCBA_PRODUCTION_LINE_TEMPLATE:'Download the PCBA production line template',
  COMMONS_PCBA_PRODUCTION_LINE_TEMPLATE:'PCBA production line template',
  COMMONS_MANUALLY_SYNCHRONIZE_PCBA:'Manually synchronize PCBA',
  COMMONS_UPLOAD_THE_PCBA_TICKET_PLAN:'Upload the PCBA ticket plan',
  COMMONS_PCBA_TICKET_TEMPLATE:'PCBA ticket template',
  COMMONS_IMPORT_MACHINING_SHEET_LIST_INFORMATION:'Import machining sheet list information',
  COMMONS_EXPORT_MACHINING_ORDERS:'Export a list of machining orders',
  COMMONS_FIRST_AVAILABLE_TIME:'The first batch is available for time',
  COMMONS_REMAINDER_AVAILABLE_TIME:'The remainder is available for time',
  COMMONS_FIRST_AVAILABLE_QUANTITY:'First available quantity',
  COMMONS_REMAINDER_AVAILABLE_QUANTITY:'The remainder is available for quantity',
  COMMONS_NUMBER_ONE:'one',
  COMMONS_NUMBER_TWO:'two',
  COMMONS_NUMBER_THREE:'three',
  COMMONS_NUMBER_FOUR:'four',
  COMMONS_NUMBER_FIVE:'five',
  COMMONS_NUMBER_SIX:'six',
  COMMONS_NUMBER_SEVEN:'seven',
  COMMONS_NUMBER_EIGHT:'eight',
  COMMONS_NUMBER_NINE:'nine',
  COMMONS_NUMBER_TEN:'ten',
  COMMONS_COMPANY_1000:'1000',
  COMMONS_COMPANY_1910:'1910',
  COMMONS_LOGGING:'Logging',
  COMMONS_PROCESSING_TYPE:'Processing type',
  COMMONS_VERBOSE_LOG_FILE:'Verbose log file URL',
  COMMONS_MONTH:'month',
  COMMONS_START_EXECUTION:'Start execution',
  COMMONS_EXECUTION_COMPLETE:'Execution complete',
  COMMONS_EXECUTION_FAILED:'Execution failed',
  COMMONS_REQUEST_DATA:'Request data',
  COMMONS_USE:'Use',
  COMMONS_VIEW:'View',  
  COMMONS_CHANGE:'Change',
  COMMONS_SUPPLIER_STATUS:'Supplier Status',
  COMMONS_FULL_NAME_OF_SUPPLIER:'Full name of supplier',
  COMMONS_SUPPLIER_ABBREVIATION:'Supplier abbreviation',
  COMMONS_APPLY_FOR_BUSINESS:'Apply for business',
  COMMONS_APPLICATION_RESULTS:'Application results',
  COMMONS_ANNUAL_REVENUES:'Annual revenue (10000 yuan)',
  COMMONS_ANNUAL_REVENUE:'Annual revenue',
  COMMONS_CATEGORIES_OF_TAXES:'Categories of taxes',
  COMMONS_BANK:'BANK',
  COMMONS_ACCOUNT_NUMBER:'Account number',
  COMMONS_SUPPLY_CATEGORY:'Supply category',
  COMMONS_SUPPLY_PRODUCTS:'Supply products',
  COMMONS_LOCATION:'Location',
  COMMONS_NUMBER_OF_COMPANIES:'Number of companies',
  COMMONS_COMPANY_PHONE:'company phone',
  COMMONS_COMPANY_FAX:'Company Fax',
  COMMONS_COMPANY_EMAIL:'Company email',
  COMMONS_POSTCODE:'Postcode',
  COMMONS_NUMBER_OF_SOCIAL_SECURITY_PERSONNEL:'Number of social security personnel',
  COMMONS_LEGAL_REPRESENTATIVE:'Legal representative',
  COMMONS_ESTABLISHMENT_TIME_AND_OPERATING_PERIOD:'Establishment time and operating period',
  COMMONS_ENTERPRISE_STATUS:'Enterprise status',
  COMMONS_REGISTERED_CAPITAL:'Registered capital',
  COMMONS_PAID_CAPITAL:'Paid-in capital',
  COMMONS_ACCESS:'Access',
  COMMONS_CANDIDATE:'Be a candidate',
  COMMONS_ELIMINATE:'Eliminate',
  COMMONS_APPLYING:'Applying',
  COMMONS_FULL_NAME_OF_THE_COMPANY:'Full name of the company',
  COMMONS_COMPANY_ABBREVIATION:'Company abbreviation',
  COMMONS_ORGANIZATION_CODE:'Organization Code',
  COMMONS_TAX_RATES:'Tax rate',
  COMMONS_CONTACT_ADDRESS:'Contact address',
  COMMONS_TAXPAYER_IDENTIFICATION_NUMBER:'Taxpayer Identification Number',
  COMMONS_PERIOD_OF_VALIDITY:'Period of validity',
  COMMONS_BUSINESS_UPDATE_TIME:'Business update time',
  COMMONS_ENTERPRISE_NATURE:'Enterprise nature',
  COMMONS_COMPANY_REGISTERED_ADDRESS:'Company Registered Address',
  COMMONS_CONTACT_PERSON:'Contact person',
  COMMONS_BECOMING_A_QUALIFIED_SUPPLIER:'Becoming a qualified supplier',
  COMMONS_BECOMING_A_BACKUP_SUPPLIER:'Becoming a backup supplier',
  COMMONS_BECOMING_AN_OBSOLETE_SUPPLIER:'Becoming an obsolete supplier',
  COMMONS_REVOKE_APPLICATION:'Revoke application',
  COMMONS_REJECT:'Reject',
  COMMONS_CERTIFICATE:'certificate',
  COMMONS_AUTHORIZATION_TYPE:'Authorization Type',
  COMMONS_SCOPE_OF_AUTHORITY:'scope of authority',
  COMMONS_AUTHORIZED_AREA:'authorized area',
  COMMONS_CERTIFICATE_VALIDITY_PERIOD:'Certificate validity period',
  COMMONS_CERTIFICATE_TYPE:'Certificate Type',
  COMMONS_SUPPLIER_REGION:'Supplier Region',
  COMMONS_BUSINESS_STATUS:'Business status',
  COMMONS_ESTABLISHMENT_TIME:'Establishment time',
  COMMONS_IN_QUOTATION:'In quotation',
  COMMONS_OBLIGATION:'obligation',
  COMMONS_SCORING_TABLE:'Scoring table',
  COMMONS_REVIEW_PROGRESS:'Review progress',
  COMMONS_RATING_TYPE:'Rating type',
  COMMONS_GRADING_MODEL:'grading model',
  COMMONS_BREAK_DOWN_ITEMS:'Break down items',
  COMMONS_ADD_CERTIFICATE:'Add a certificate',
  COMMONS_CERTIFICATE_CODE:'Certificate Code',
  COMMONS_CERTIFICATE_FORMAT:'certificate format',
  COMMONS_AUTHORIZATION_FORM:'Authorization form',
  COMMONS_PRODUCT_SCOPE:'Product Scope',
  COMMONS_SPECIFIC_REGIONS:'Specific regions',
  COMMONS_IS_IT_MANDATORY:'Is it mandatory',
  COMMONS_RATING_TABLE_NAME:'Rating Table Name',
  COMMONS_FREQUENCY:'frequency',
  COMMONS_NEW_RULE:'New rule',
  COMMONS_RULE_NAME:'Rule Name',
  COMMONS_INVOICING_RULES:'Invoicing rules',
  COMMONS_REDEMPTION_CYCLE:'Redemption cycle',
  COMMONS_TRANSACTION_NODE_PAYMENT_RATIO_SETTLEMENT_ACCOUNTING_PERIOD:'Transaction Node&Payment Ratio&Settlement Accounting Period',
  COMMONS_CONDITIONAL_VARIABLE_ONE:'Conditional variable one',
  COMMONS_CONDITIONAL_VARIABLE_TWO:'Conditional variable two',
  COMMONS_CONDITIONAL_VARIABLE_THREE:'Conditional variable three',
  COMMONS_INVITE_SUPPLIERS:'Invite suppliers',
  COMMONS_CONTACT_NAME:'Contact Name',
  COMMONS_CONTACT_MOBILE:'Contact Mobile',
  COMMONS_POSITION:'Position',
  COMMONS_GENDER:'Gender',
  COMMONS_MALE:'Male',
  COMMONS_WOMAN:'Woman',
  COMMONS_REGISTER:'Register',
  COMMONS_BASIC_INFORMATION_OF_THE_ENTERPRISE:'Basic information of the enterprise',
  COMMONS_ENTERPRISE_QUALIFICATION_CERTIFICATE:'Enterprise Qualification Certificate',
  COMMONS_OTHER_ATTACHMENTS:'Other attachments',
  COMMONS_MOBILE_VERIFICATION_CODE:'Mobile verification code',
  COMMONS_PURCHASING_INVITATION_CODE:'Purchasing Invitation Code',
  COMMONS_SUPPLIER_INVITATION_CODE:'Supplier Invitation Code',
  COMMONS_VAT_ORDINARY_INVOICE:'VAT ordinary invoice',
  COMMONS_VAT_INVOICE:'VAT invoice',
  COMMONS_ENTERPRISE_QUERY_RESULTS:'Enterprise Query Results',
  COMMONS_CELL_PHONE_NUMBER:'Cell-phone number',
  COMMONS_PRODUCT_NAME:'Product name',
  COMMONS_PRODUCT_CLASSIFICATION:'Product classification',
  COMMONS_SUPPLY_ADDRESS:'Supply address',
  COMMONS_PRODUCT_MODEL:'Product Model',
  COMMONS_SIZE:'Size',
  COMMONS_COLOUR:'Colour',
  COMMONS_MATERIAL:'Material',
  COMMONS_MONTHLY_PRODUCTION_VOLUME:'Monthly production volume',
  COMMONS_ANNUAL_PRODUCTION_VOLUME:'Annual production volume',
  COMMONS_STARTING_SALES_QUANTITY:'Starting sales quantity',
  COMMONS_VOLUME_DISCOUNT:'Volume discount',
  COMMONS_WARRANTY_PERIOD:'Warranty Period',
  COMMONS_PICTURE:'Picture',
  COMMONS_TEMPLATE_DOWNLOAD:'Template download',
  COMMONS_RESET_PASSWORD:'Reset password',
  COMMONS_ASSIGNING_ROLES:'Assigning roles',
  COMMONS_USER_ID:'User ID',
  COMMONS_USER_NAME:'User Name',
  COMMONS_USER_NICKNAME:'User nickname',
  COMMONS_USER_STATUS:'User Status',
  COMMONS_NORMAL:'normal',
  COMMONS_BELONGING_DEPARTMENT:'Belonging department',
  COMMONS_USER_PASSWORD:'user password',
  COMMONS_USER_GENDER:'User Gender',
  COMMONS_UNKNOWN:'unknown',
  COMMONS_POST:'post',
  COMMONS_ROLE:'role',
  COMMONS_LOGIN_ACCOUNT:'Login account',
  COMMONS_ROLE_NUMBER:'Role Number',
  COMMONS_ROLE_NAME:'Role name',
  COMMONS_PERMISSION_CHARACTERS:'Permission Characters',
  COMMONS_BASIC_INFORMATION:'Basic information',
  COMMONS_ROLE_INFORMATION:'role information',
  COMMONS_DATA_PERMISSIONS:'Data permissions',
  COMMONS_ROLE_ORDER:'Role order',
  COMMONS_DEACTIVATE:'Deactivate',
  COMMONS_MENU_PERMISSIONS:'Menu permissions',
  COMMONS_SELECT_ALL_OR_NOT_ALL:'Select All/Not All',
  COMMONS_ENTER:'Please enter',
  COMMONS_ASSIGN_DATA_PERMISSIONS:'Assign data permissions',
  COMMONS_BATCH_CANCELLATION_OF_AUTHORIZATION:'Batch Cancellation of Authorization',
  COMMONS_SELECT_USER:'select user',
  COMMONS_UNCHECK_USER_AUTHORIZATION:'Do you want to uncheck the user authorization data item',
  COMMONS_REVOKE:'REVOKE',
  COMMONS_POST_NO:'Post No',
  COMMONS_JOB_CODE:'Job Code',
  COMMONS_JOB_NAME:'Job Name',
  COMMONS_JOB_RANKING:'Job ranking',
  COMMONS_POSITION_STATUS:'Position status',
  COMMONS_DICTIONARY_STATUS:'Dictionary Status',
  COMMONS_DICTIONARY_NUMBER:'Dictionary number',
  COMMONS_DICTIONARY_NAME:'Dictionary Name',
  COMMONS_DICTIONARY_TYPE:'Dictionary Type',
  COMMONS_PARAMETER_PRIMARY_KEY:'Parameter primary key',
  COMMONS_PARAMETER_NAME:'Parameter name',
  COMMONS_PARAMETER_KEY_NAME:'Parameter Key Name',
  COMMONS_PARAMETER_KEY:'Parameter Key',
  COMMONS_SYSTEM_BUILT_IN:'System built-in',
  COMMONS_SYNCHRONIZE_SAP_ORDERS:'Synchronize SAP orders',
  COMMONS_ANNOUNCEMENT_TITLE:'Announcement Title',
  COMMONS_ANNOUNCEMENT_TYPE:'Announcement Type',
  COMMONS_CREATOR:'creator',
  COMMONS_NOTICE:'notice',
  COMMONS_ANNOUNCEMENT:'announcement',
  COMMONS_LOG_NUMBER:'Log number',
  COMMONS_SYSTEM_MODULE:'System module',
  COMMONS_OPERATION_ADDRESS:'Operation address',
  COMMONS_OPERATION_LOCATION:'Operation location',
  COMMONS_OPERATIONAL_STATE:'Operational state',
  COMMONS_TIME_CONSUMING:'time consuming',
  COMMONS_EMPOWER:'empower',
  COMMONS_FORCED_RETREAT:'Forced retreat',
  COMMONS_WIPE_DATA:'wipe data',
  COMMONS_FAIL:'fail',
  COMMONS_CLEAN_UP:'Clean up',
  COMMONS_CLEAR_SUCCESSFULLY:'Clear successfully',
  COMMONS_OPERATION_MODULE:'operation module',
  COMMONS_REQUEST_ADDRESS:'Request Address',
  COMMONS_LOGIN_INFORMATION:'login information',
  COMMONS_REQUEST_METHOD:'Request Method',
  COMMONS_OPERATION_METHOD:'Operation method',
  COMMONS_REQUEST_PARAMETERS:'Request parameters',
  COMMONS_RETURN_PARAMETERS:'return parameters',
  COMMONS_ACCESS_NUMBER:'Access number',
  COMMONS_LOGIN_ADDRESS:'Login address',
  COMMONS_LOGIN_LOCATION:'Login location',
  COMMONS_BROWSER:'browser',
  COMMONS_OPERATING_SYSTEM:'operating system',
  COMMONS_LOGIN_STATUS:'Login status',
  COMMONS_OPERATIONAL_INFORMATION:'operational information',
  COMMONS_LOGON_DATE:'logon date',
  COMMONS_LOGIN_TIME:'login time',
  COMMONS_PURPOSE_OF_APPLICATION:'purpose of application',
  COMMONS_PROCUREMENT_MANAGER:'Procurement Manager',
  COMMONS_POST_EXPIRATION_STATUS:'Post expiration status',
  COMMONS_VALID_TO:'valid to',
  COMMONS_STATUS_AFTER_EXPIRATION:'Status after expiration',
  COMMONS_QUALIFIED:'qualified',
  COMMONS_REJECT_REAPPLY:'Reject (reapply)',
  COMMONS_MAIN_NUMBER:'Main number',
  COMMONS_FINISH_PRODUCT:'finish product',
  COMMONS_LOW_VALUE_CONSUMABLES:'Low value consumables',
  COMMONS_SPECIFICATION_PARAMETERS:'Specification parameters',
  COMMONS_HARDNESS:'hardness',
  COMMONS_MATERIAL_QUALITY:'material quality',
  COMMONS_MINIMUM_UNIT:'Minimum Unit',
  COMMONS_CREATE:'create',
  COMMONS_GENERATE:'generate',
  COMMONS_CREATE_SPECIFICATIONS:'Create specifications',
  COMMONS_SPECIFICATION_MATERIAL:'Specification materials',
  COMMONS_MATERIAL_CODE:'Material code',
  COMMONS_POWER:'power',
  COMMONS_LENGTH:'length',
  COMMONS_WIDTH:'width',
  COMMONS_PURITY:'purity',
  COMMONS_SUPPLIER_LIST:'Supplier List',
  COMMONS_SUPPLY_LOCATION:'Supply location',
  COMMONS_RECENT_QUOTATIONS:'Recent quotations',
  COMMONS_QUOTATION_DATE:'Quotation date',
  COMMONS_HISTORICAL_PRICE:'historical price',
  COMMONS_PRODUCTION_SCHEDULING_CYCLE:'Production scheduling cycle',
  COMMONS_DAILY_PRODUCTION_VOLUME:'Daily production volume',
  COMMONS_BILLING_TYPE:'Billing type',
  COMMONS_PROCURE:'procure',
  COMMONS_INSUFFICIENT_TOLERANCE:'Insufficient tolerance(%)',
  COMMONS_EXCESSIVE_TOLERANCE:'Excessive tolerance(%)',
  COMMONS_INVENTORY_INFORMATION:'Inventory information',
  COMMONS_AVAILABLE_INVENTORY:'Available inventory',
  COMMONS_ESTIMATED_INWARD_DELIVERY_QUANTITY:'Estimated inward delivery quantity',
  COMMONS_NUMBER_OF_IN_TRANSIT_DELIVERIES:'Number of in transit deliveries',
  COMMONS_BLOCKED_STOCK:'Blocked Stock',
  COMMONS_SAMPLE_MANAGEMENT:'Sample management',
  COMMONS_SAMPLE_SPECIFICATION_CODING_AND_IMAGES_MULTIPLE:'Sample specification coding and images (multiple)',
  COMMONS_SAMPLING_METHOD:'Sampling method',
  COMMONS_NUMBER_OF_SAMPLES:'Number of samples',
  COMMONS_WAREHOUSING_TIME:'Warehousing time',
  COMMONS_FIRST_SAMPLER:'First sampler',
  COMMONS_CURRENT_CUSTODIAN:'Current custodian',
  COMMONS_SPECIFICATION_LIST_AND_RELATED_INFORMATION:'Specification List and Related Information',
  COMMONS_DIAMETER:'diameter',
  COMMONS_BOM_PATH:'BOM Path',
  COMMONS_ENERGY_CONSUMPTION:'energy consumption',
  COMMONS_QUALITY_INSPECTION_REQUIREMENTS:'Quality inspection requirements',
  COMMONS_MAIN_BARCODE:'Main barcode',
  COMMONS_IN_TRANSIT_PURCHASE_ORDERS:'In transit purchase orders',
  COMMONS_CONSUMABLES:'consumables',
  COMMONS_VIRTUAL_ITEM:'Virtual Item',
  COMMONS_IN_REVIEW:'in review',
  COMMONS_VALID_CONTACTS:'Valid contacts',
  COMMONS_ARCHIVED:'Archived',
  COMMONS_ADDING_MATERIALS:'Adding materials',
  COMMONS_FORM_NAME:'form name',
  COMMONS_SPECIFICATION_TYPE:'Specification type',
  COMMONS_SPECIFICATIONS_TEXT:'Text',
  COMMONS_SPECIFICATIONS_COLOR_PALETTE:'Color palette',
  COMMONS_SPECIFICATIONS_IMAGE:'Image',
  COMMONS_SORT:'sort',
  COMMONS_MAKE_MANDATORY:'Make mandatory',
  COMMONS_CANCEL_REQUIRED_FIELDS:'Cancel required fields',
  COMMONS_CATEGORY_PARENT:'Category parent',
  COMMONS_CLASSIFICATION_NAME:'Classification name',
  COMMONS_CLASSIFICATION_CODE:'classification code',
  COMMONS_TITLE:'title',
  COMMONS_KEYWORD:'keyword',
  COMMONS_CREATE_CLASSIFICATION:'Create classification',
  COMMONS_CREATED_SUCCESSFULLY:'Created successfully',
  COMMONS_MOVE_IN_CLASSIFICATION:'Move in classification',
  COMMONS_UNIT_NAME:'Unit Name',
  COMMONS_CONVERSION_RULES:'Conversion rules',
  COMMONS_ROUNDING_ACCURACY:'Rounding accuracy',
  COMMONS_MEASUREMENT_TYPE:'Measurement type',
  COMMONS_BASE_UNIT:'Base unit',
  COMMONS_NEW_UNIT_TYPE:'New Unit Type',
  COMMONS_NEW_UNIT:'New unit',
  COMMONS_UNIT_CODE:'Unit Code',
  COMMONS_CONVERSION_METHOD:'Conversion method',
  COMMONS_CONVERSION_COUNT:'Conversion Count',
  COMMONS_IS_BENCHMARK_UNIT:'Is it a benchmark unit',
  COMMONS_MODIFICATION_TYPE:'Modification Type',
  COMMONS_MATERIAL_GROUP_CODE:'Material Group Code',
  COMMONS_DETAILED_ADDRESS:'detailed address',
  COMMONS_ZIP_CODE:'zip code',
  COMMONS_ABBREVIATION:'abbreviation',
  COMMONS_LABEL:'label',
  COMMONS_BUSINESS_INFORMATION:'Business Information',
  COMMONS_BUSINESS_LICENSE_NUMBER:'Business license number',
  COMMONS_LEGAL_PERSON_NAME:'Legal Person Name',
  COMMONS_BUSINESS_VALIDITY_PERIOD:'Business validity period',
  COMMONS_FINANCE:'finance',
  COMMONS_BILLING_ADDRESS:'Billing address',
  COMMONS_TAXPAYER_NUMBER:'Taxpayer number',
  COMMONS_BANKS:'bank',
  COMMONS_ACCOUNT_NUMBERS:'account number',
  COMMONS_ACCOUNT_TYPE:'Account Type',
  COMMONS_ADDRESS_TYPE:'address type',
  COMMONS_MATERIAL_NUMBER_SPECIFICATION:'Material number (specification)',
  COMMONS_HOME_PRICE:'Home price',
  COMMONS_VALIDITY_PERIOD_OF_QUOTATION:'Validity period of quotation',
  COMMONS_QUALIFICATIONS:'Qualifications',
  COMMONS_CORRESPONDENCE_CONTRACT:'Correspondence contract',
  COMMONS_CONTRACT_NOTE:'Contract Note',
  COMMONS_TYPE_OF_CONTRACT:'type of contract',
  COMMONS_SIGNING_TIME:'Signing time',
  COMMONS_EXPIRATION_TIME:'Expiration time',
  COMMONS_PARTY_A:'party A',
  COMMONS_PARTY_B:'party B',
  COMMONS_FACE_TAX_RATE:'Face tax rate',
  COMMONS_QUOTATION_AND_TAXATION:'Quotation and Taxation',
  COMMONS_QUOTATION_AND_TRANSPORTATION:'Quotation and Transportation',
  COMMONS_TRANSACTION_RULES:'Transaction rules',
  COMMONS_SPECIFICATION_VALUE:'Specification value',
  COMMONS_IS_IT_MANAGED_BY_THE_PURCHASER:'Is it managed by the purchaser',
  COMMONS_INTERNATIONAL_TRADE:'international trade',
  COMMONS_ON_DEMAND_MATERIAL_PULLING:'On-demand material pulling',
  COMMONS_MILKRUN:'MILKRUN',
  COMMONS_MAINTENANCE_METHODS:'Maintenance methods',
  COMMONS_ADD_NEW_SAMPLES:'Add new samples',
  COMMONS_COMMODITY:'commodity',
  COMMONS_ITEM_CODE:'Item',
  COMMONS_SPECIFICATIONS:'specifications',
  COMMONS_SAMPLER:'Sampler',
  COMMONS_WAREHOUSING_DATE:'Warehousing date',
  COMMONS_SAMPLE_VALIDITY_PERIOD:'Sample validity period',
  COMMONS_CUSTODIAN:'custodian',
  COMMONS_SAMPLE_LOCATION:'Sample Location',
  COMMONS_SAMPLE_IMAGES:'Sample images',
  COMMONS_MATERIAL_FIRST_NUMBER:'Material first number',
  COMMONS_TRADE_NAME:'Trade name',
  COMMONS_SAMPLE_MODEL:'Sample model',
  COMMONS_SAMPLE_SPECIFICATIONS_MULTIPLE:'Sample specifications (multiple)',
  COMMONS_NEW_TYPE:'New Type',
  COMMONS_TYPE_NAME:'Type name',
  COMMONS_TYPE_CODE:'Type code',
  COMMONS_PRODUCT:'product',
  COMMONS_GENERAL_TYPE:'general type',
  COMMONS_UPLOAD_TEMPLATE:'Upload template',
  COMMONS_SPECIAL_REGIONS:'Special regions',
  COMMONS_ADD_REGION:'add region',
  COMMONS_SELECT_ADDRESS:'select address',
  COMMONS_IS_IGNORE_THE_CERTIFICATE:'Do you want to ignore the certificate',
  COMMONS_ADD_RULES:'Add rules',
  COMMONS_NEW_ENTRY:'New Entry',
  COMMONS_REVIEWERS:'Reviewer',
  COMMONS_ADD_SCORING_ITEMS:'Add scoring items',
  COMMONS_PURCHASING_MATERIALS:'Purchasing Materials',
  COMMONS_REQUIREMENT_TYPE:'Requirement Type',
  COMMONS_REQUIREMENT_PATH:'Requirement Path',
  COMMONS_MATERIAL_CLASSIFICATION:'Material classification',
  COMMONS_ALTERNATIVE_MATERIALS:'Alternative materials',
  COMMONS_CHANNEL_SCOPE:'Channel scope',
  COMMONS_BRAND_RANGE:'Brand Range',
  COMMONS_ATTACHMENT_REQUIREMENTS:'Attachment requirements',
  COMMONS_QUALITY_STANDARD:'quality standard',
  COMMONS_PURCHASE_QUANTITY:'Purchase quantity',
  COMMONS_UNIT_PRICE_RANGE_YUAN:'Unit price range (yuan)',
  COMMONS_PROCUREMENT_PURPOSE:'Procurement purpose',
  COMMONS_INITIATION_TIME:'Initiation time',
  COMMONS_ADVANCE_STORAGE_TOLERANCE:'Advance storage tolerance',
  COMMONS_STORAGE_LOCATION:'Storage location',
  COMMONS_PROCUREMENT_STATUS:'Procurement status',
  COMMONS_NEW_REQUIREMENT:'New Requirement',
  COMMONS_BOM_BULK_PROCUREMENT:'BOM bulk procurement',
  COMMONS_DOWNLOAD_BOM:'Download BOM',
  COMMONS_BOM:'BOM',
  COMMONS_PURCHASING_GOODS:'Purchasing goods',
  COMMONS_PRODUCT_SPECIFICATIONS:'Product specifications',
  COMMONS_SPLIT_DELIVERY:'split delivery',
  COMMONS_STORAGE_TOLERANCE:'Storage tolerance',
  COMMONS_LATENCY:'delay',
  COMMONS_JIT_TIMELY_DELIVERY:'JIT timely delivery',
  COMMONS_DELIVERY_REQUIREMENTS:'Delivery requirements',
  COMMONS_NEW_QUOTATION_PLAN:'New quotation plan',
  COMMONS_VIEW_INQUIRY_FORM:'View inquiry form',
  COMMONS_VIEW_QUOTATION:'View quotation',
  COMMONS_QUOTATION_NO:'Quotation no',
  COMMONS_BILL_OF_MATERIAL:'Bill of Material',
  COMMONS_REFUSAL_TO_QUOTE:'Refusal to quote',
  COMMONS_REASON:'reason',
  COMMONS_INQUIRY_SHEET:'inquiry sheet',
  COMMONS_QUOTATION_STATUS:'Quotation status',
  COMMONS_APPROVAL_STATUS:'Approval Status',
  COMMONS_SOLICITATION_PLANNING:'Solicitation planning',
  COMMONS_PLAN_EXECUTOR:'Plan executor',
  COMMONS_DELIVERY_DATE:'Delivery Date',
  COMMONS_JIT_OR_NOT:'JIT or not',
  COMMONS_ACCEPTABLE_QUANTITY:'Acceptable quantity',
  COMMONS_PRICING_VALIDITY:'Pricing Validity',
  COMMONS_DELIVERY_TIME:'delivery time',
  COMMONS_QUOTATION_REPLY:'Quotation reply',
  COMMONS_QUOTATION_PROCESSING:'Quotation processing',
  COMMONS_VOID_QUOTATION:'Void quotation',
  COMMONS_URGENT_QUOTATION:'Urgent quotation',
  COMMONS_INDIVIDUAL_DELIVERY_DATE:'Individual delivery date',
  COMMONS_CENTRALIZED_DELIVERY_DATE:'Centralized delivery date',
  COMMONS_SELLER_ACCEPTANCE_PRICE:'Seller acceptance price',
  COMMONS_ARRIVAL_TIME:'arrival time',
  COMMONS_INCLUDING_JIT_REQUIREMENTS:'Including JIT requirements',
  COMMONS_SUPPLIER_SCOPE:'Supplier scope',
  COMMONS_STORAGE_ADVANCE_TOLERANCE:'Advance storage tolerance',
  COMMONS_STORAGE_DELAY_TOLERANCE:'Storage delay tolerance',
  COMMONS_PLANNED_PROCUREMENT_TIME:'Planned procurement time',
  COMMONS_RECEIVING_UNIT:'Receiving unit',
  COMMONS_SUPPLIER_TYPE:'Supplier Type',
  COMMONS_FOB_PRICE:'FOB price',
  COMMONS_CIF_PRICE:'CIF price',
  COMMONS_PRE_TAX:'pre-tax',
  COMMONS_AFTER_TAX:'after tax',
  COMMONS_NO_TICKET:'No ticket',
  COMMONS_ALONG_WITH_GOODS_STORAGE:'Along with goods (storage)',
  COMMONS_ADVANCE_CHARGE:'advance charge',
  COMMONS_VIEW_SUPPLIERS:'View suppliers',
  COMMONS_LATEST_ORDER_DATE:'Latest order date',
  COMMONS_QUOTATION_DEADLINE:'Quotation deadline',
  COMMONS_PRICE_ADJUSTMENT_METHOD:'Price adjustment method',
  COMMONS_AFTER_THE_QUOTATION_IS_COMPLETED:'After the quotation is completed',
  COMMONS_DURING_THE_QUOTATION_PERIOD:'During the quotation period',
  COMMONS_UNLIMITED:'unlimited',
  COMMONS_EARLY_DELIVERY_TOLERANCE_WORKING_DAYS:'Early delivery tolerance (working days)',
  COMMONS_DELAY_IN_DELIVERY_TOLERANCE_WORKING_DAYS:'Delay in delivery tolerance (working days)',
  COMMONS_INQUIRY_TYPE:'Inquiry type',
  COMMONS_TYPE_OF_BIDDING_MATERIALS:'Type of bidding materials',
  COMMONS_IMPORT_BOM:'Import BOM',
  COMMONS_NEW_BIDDING_PLAN:'New bidding plan',
  COMMONS_SELECT_MATERIALS:'Select materials',
  COMMONS_BIDDING_TYPE:'Bidding type',
  COMMONS_PUBLIC_CONTACTS:'Public contacts',
  COMMONS_BIDDING_AREA:'Bidding area',
  COMMONS_TENDER_ATTACHMENTS:'Tender attachments',
  COMMONS_INVITER:'Inviter',
  COMMONS_INVITATION_TIME:'Invitation time',
  COMMONS_BIDDING_TIME:'Bidding time',
  COMMONS_EVALUATION_TIME:'Evaluation time',
  COMMONS_RATING_ITEMS:'Rating items',
  COMMONS_EVALUATOR:'Evaluator',
  COMMONS_TECHNICAL_EVALUATION_TIME:'Technical evaluation time',
  COMMONS_TECHNICAL_EVALUATOR:'Technical evaluator',
  COMMONS_SCHEDULED_START_TIME:'Scheduled Start Time',
  COMMONS_INCREASE_METHOD:'Increase method',
  COMMONS_FILE_NOTES:'File notes',
  COMMONS_EXAMINE_AND_APPROVE:'examine and approve',
  COMMONS_BUSINESS_VALIDITY_PERIOD_EXPIRATION_DATE:'Business validity period (expiration date)',
  COMMONS_BUSINESS_VALIDITY_PERIOD_START_DATE:'Business validity period (start date)',
  COMMONS_APPROVAL_SUCCESSFUL:'Approval successful',
  COMMONS_BATCH_ADDITION:'Batch addition',
  COMMONS_BRAND_NAME:'Brand Name',
  COMMONS_BRAND_CODE:'Brand code',
  COMMONS_BRAND_IMAGE_URL:'Brand image URL',
  COMMONS_NEW_BRANDS_ADDED:'New brands added',
  COMMONS_UPLOAD_PICTURES:'upload pictures',
  COMMONS_EDIT_BRAND:'Edit Brand',
  COMMONS_DOES_IT_INCLUDE_JIT_REQUIREMENTS:'Does it include jit requirements',
  COMMONS_BATCH_NUMBER:'Batch number',
  COMMONS_PRICE_REMINDER_METHOD:'Price reminder method',
  COMMONS_SELLERS_PRICE:'sellers price',
  COMMONS_BUYERS_PRICE:'Buyers price',
  COMMONS_NOTIFY_QUOTATION:'Notify quotation',
  COMMONS_GENERATE_PURCHASE_ORDER:'Generate purchase order',
  COMMONS_BIDDING_STATUS:'Bidding status',
  COMMONS_STARTING_TIME_OF_BIDDING:'Starting time of bidding',
  COMMONS_END_TIME_OF_BIDDING:'End time of bidding',
  COMMONS_BIDDING_RESTRICTIONS:'Bidding restrictions',
  COMMONS_CREATE_NEW_RATING_ITEM:'Create a new rating item',
  COMMONS_RATING_ITEM_NAME:'Rating Item Name',
  COMMONS_RATING_PERSONNEL:'Rating personnel',
  PURCHASE_ORDER_REQUEST_NUMBER:'Purchase Order Number',
  COMMONS_TENDER_DOCUMENT_NUMBER:'Tender Document Number',
  COMMONS_STATUS_AFTER_EXPIRATION_DATE:'Status after expiration date',
  COMMONS_PROCUREMENT_BATCH:'Procurement batch',
  COMMONS_QUOTATION_VALIDITY_DAYS:'Quotation validity days',
  COMMONS_PROCUREMENT_REQUIREMENTS:'Procurement requirements',
  COMMONS_PROCUREMENT_DEPARTMENT:'Procurement Department',
  COMMONS_PROCUREMENT_PERSONNEL:'Procurement personnel',

}
export default common_en_US
