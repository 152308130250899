/*登录页面英文*/
const LOGIN_US ={
  LOGIN_USER_NAME:'Please Input user name.',
  LOGIN_INPUT_PASSWORD:'Please Input password.',
  LOGIN_NAME:'User name',
  LOGIN_PASSWORD:'Password',
  LOGIN_LOGIN:'Login',
  LOGIN_INPUT_VERIFY_CODE:'Please Input verify code.',
  LOGIN_TITLE:'Enterprise login',
  LOGIN_MAILBOX:'mailbox',
  LOGIN_MOBILE_PHONE:'mobile phone',
  LOGIN_CODE:'code',
  LOGIN_SMS_CODE:'SMS code',
  LOGIN_OBTAIN_VERIFICATION_CODE:'Obtain verification code',
  LOGIN_SRM_TITLE:'Xifan Information',
  LOGIN_RETRIEVE_PASSWORD:'Retrieve password',
}

export default LOGIN_US
