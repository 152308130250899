// import { Modal } from 'antd'
import React, { Component } from 'react'
import { mapFormOption } from "../../utils/util"
import { Form, Row, Button } from 'antd'
import { FormattedMessage, injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'

class LFListForm extends Component {
  formRef = React.createRef()
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 }
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
    },
  }
  handleCancel = e => {
    this.setState({
    });
  }
  handleSubmit = () => {
    this.props.handleSubmit(this)
  }
  handleClose = () => {
    this.props.handleClose()
  }

  componentDidMount() {
    const {myRef1}=this.props
    if(myRef1){
      myRef1(this)
    }
    // that.props.getFactoryAddDeliveryAddressList(that,'factoryCode')
    //原有的，新项目可能不需要，后面需要再复原
    // if(that.props.getFactoryAddDeliveryAddressList) {
    //   that.props.getFactoryAddDeliveryAddressList(that,'factoryCode')
    // }
  }

  render() {
    const {
      submitFormArrList, // 表单提交数据
      span, // 一行多少列
      intl, // injectIntl数据
      that, // 父组件this
      // visible, // 弹窗开关
      isModal, // 是否开启弹窗模式
      // isAdd, // 是否是新增
      // modalTitle,
      // isTwo
    } = this.props
    return (
      (
        isModal ?
          <Form ref={this.formRef} {...this.formItemLayout}>
            <Row gutter={18} className='form-item-container'>
              { submitFormArrList && mapFormOption(submitFormArrList, true, span, intl.formatMessage, that) }
            </Row>
            <div className='form-foot'>
              <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
              {/* 确定*/}
                <FormattedMessage id='COMMONS_CONFIRM'/>
              </Button>
              <Button onClick={this.handleClose}>
                {/*取消*/}
                <FormattedMessage id='COMMONS_CANCEL'/>
              </Button>
            </div>
          </Form>
        :
        <Form ref={this.formRef} {...this.formItemLayout}>
          <Row gutter={18} className='form-item-container'>
            { submitFormArrList && mapFormOption(submitFormArrList, true, span, intl.formatMessage, that) }
          </Row>
        </Form>
      )
    )
  }
}

export default injectIntl(withRouter(LFListForm))
