import React, { Component } from 'react'
import {FormattedMessage, injectIntl} from 'react-intl'
import {Modal,Row,Col, Card} from 'antd'
import {withRouter} from 'react-router-dom'
import CustomButton from "../../../components/custom-button/custom-button"
import CustomTableNoType from "../../../components/custom-table-no-type/custom-table-no-type"
import BaseUploadForm from "../../../components/base-upload-form/base-upload-form"
import LzListForm from '../../../components/lz-list-form/lz-list-form'
import CustomLink from '../../../components/custom-link/custom-link'
import {downloadExcel, onPageNumChange, formatMessage, getColumnsWidthSum, setColumnWidth, PAGE_SIZE, getSearchFormHeight, PUB_SUB_LANG_CHANGE, mapFormOption, computedBodyWidth, getUuid} from "../../../utils/util"
import PubSub from "pubsub-js"

const FILE_TYPE = [".xls",".xlsx"]
class ContactCardContent extends Component {
  searchCondition = {}
  style = {display: 'none'}
  formWrap = React.createRef()
  uploadFormRef = React.createRef()
  lzListFormModal = React.createRef()
  state = {
    columnsWidthSum: 0,
    tableBodyHeight: 170,
    baseUploadFormModalVisible: false,
    file: {},
    isAdd: false,
    lzListFormVisible: false,
    columns: [],
    tableDataSource: [], //提交的数据
    currentPage: 1,
    totalSize: 0,
    pageSize: PAGE_SIZE,
    editRecord: {},//编辑的行数据
  }
  actions = [
    {
      title:<FormattedMessage id='COMMONS_OPERATION'/>,
      key: 'detail',
      width: 138,
      zhWidth: 138,
      enWidth: 138,
      align: 'center',
      render: (text,record,index) => {
        return <>
        {/*删除*/}
        {
          this.props.isShowBtn.deleteBtn  && record.isSupplierOwn === 'N' ? 
          <CustomLink id='COMMON_DELETE' onClick={(e) => this.handleDetele(record)}/>: null
        }
        {
          this.props.isShowBtn.updateBtn ? 
          <CustomLink id='COMMONS_EDIT' onClick={(e) => this.handleEdit(record)}/>: null
        }
        </>
      }
    }
  ]

  handleDetele = (record) => {
    let filterSource = [...this.state.tableDataSource]
    filterSource = filterSource.filter((item) => item._uuid !== record._uuid)
    this.setState({
      tableDataSource: filterSource,
    },() => {
      this.props.getTableData(this.state.tableDataSource)
    })
  }

  handleEdit = (record) => {
    this.setState({
      isEdit: true,
      lzListFormVisible: true,
      editRecord: record
    })
    this.lzListFormModal.formRef.current.setFieldsValue({
      ...record,
    })
    if (record.isSupplierOwn && record.isSupplierOwn === 'Y') {
      this.props.lzlistform.submitFormArrList.forEach((item) => {
        if (item.name === 'phone' || item.name === 'postName' || item.name === 'realName' || item.name === 'sexType') {
          item.disabled = true
        }
      })  
    }
  }

  closeUploadWindow = () => {
    this.setState({
      baseUploadFormModalVisible: false
    })
  }
  closeLzListFormModal = () => {
    this.setState({
      lzListFormVisible: false,
      isAdd: false,
      isEdit: false
    })
  }
  getFiles = (file) => {
    this.setState({
      file
    })
  }
  handleSubmit =  () => {
    const batchResponseFormInstance =  this.lzListFormModal.formRef.current
    const {that,current}=this.props
    const { isAdd, isEdit, editRecord } = this.state
    batchResponseFormInstance
    .validateFields()
      .then((values) => {
        values.areaStr = this.getAreaName(values,that)
        let tableDataList = []
          tableDataList = [...this.state.tableDataSource]
        if (isAdd) {
          tableDataList.push({
            ...values,
            _uuid: getUuid(),
          })
        }
        if (isEdit) {
          let list = tableDataList.map(item => {
            if (item.supplierContactUserId) {
              if (item.supplierContactUserId === editRecord.supplierContactUserId) {
                // item = values
                values.supplierContactUserId = editRecord.supplierContactUserId
                return values
              } else {
                return item
              }
            }
           if (item._uuid) {
            if (item._uuid === editRecord._uuid) {
              return values
            } else {
              return item
            }
           }
          })
          tableDataList = [...list]
        }
      this.setState({
        tableDataSource: tableDataList,
        lzListFormVisible: false,
        isAdd: false,
        isEdit: false
      },() => {
        this.props.getTableData(this.state.tableDataSource)
      })
    })
    .catch((error) => console.log(error))
  }

  getAreaName = (val,that) => {
    let str = ''
    if (val.areaId && val.areaId.length > 0) {
      let ids = val.areaId
      let arealist = that.state.areaList
      ids.forEach(item => {
        let obj = ''
        if (typeof(item) === 'number') {
          obj = arealist.filter(items => items.areaId === JSON.stringify(item))
        }else {
          obj = arealist.filter(items => items.areaId === item)
        }
        if (obj) {
          str+=obj[0].name
        }
      })
    }
    return str
  }

  submitUploadFile = () => {
  }
  handleShowModal = (e, row) => {
    this.props.lzlistform.submitFormArrList.forEach((item) => {
      if (item.name === 'phone' || item.name === 'postName' || item.name === 'realName' || item.name === 'sexType') {
        item.disabled = false
      }
    }) 
    this.lzListFormModal.formRef.current.resetFields()
    this.setState({
      isAdd: true,
      lzListFormVisible: true
    })
  }
  handleShowImportModal = () => {
    this.setState({
      baseUploadFormModalVisible: true
    })
  }

  handleDownloadTemplate = () => {
  }

  getParentId = (data,id,result) => {
    for (let i in data) {
      let item = data[i]
      if (item.areaId === id) {
        result.unshift(item.areaId)
        if (item.parentId) {
          this.getParentId(data,item.parentId,result)
        }
      }
    }
    return result
  }

  getParentStr = (data,id,str) => {
    for (let i in data) {
      let item = data[i]
      if (item.areaId === id) {
        str.unshift(item.name)
        if (item.parentId) {
          this.getParentStr(data,item.parentId,str)
        }
      }
    }
    return str
  }

  findParent = (data, target, result,str) => {
    for (let i in target) {
      let id = JSON.stringify(target[i].areaId)
      for (let j in data) {
        if (id === data[j].areaId) {
          result.unshift(data[j].areaId)
          str.unshift(data[j].name)
          if (data[j].parentId) {
            result = this.getParentId(data,data[j].parentId,result)
            str = this.getParentStr(data,data[j].parentId,str)
          }
        }
      }
      target[i].areaId = result
      target[i].areaStr = str
    }
    return target
  }

  getTableDataList = (nextProps, prevState) => {
    if (nextProps.getUserData && nextProps.getUserData.contactUserVos && nextProps.getUserData.contactUserVos.length > 0 && !this.state.tableDataSource.length) {
      let target = nextProps.getUserData.contactUserVos
      let tableData = target.map(item => {
        item.areaId = item.currentParentAreaIds
        item.areaStr = item.mergerName
        return item
      })
      this.setState({
        tableDataSource: tableData
      })
    }
  }

  componentDidMount() {
    const columns = this.props.customtablenotype.columns.slice()
    this.actions.forEach(item => columns.push(item))
    getColumnsWidthSum(this, columns, 'columnsWidthSum', 'columns')
    /*监听语言改变*/
    this.pubSubToken = PubSub.subscribe(PUB_SUB_LANG_CHANGE, () => {
      const columns = this.props.customtablenotype.columns
      this.actions.forEach(item => columns.push(item))
      getColumnsWidthSum(this, columns, 'columnsWidthSum', 'columns')
    })
    
  }
  componentWillReceiveProps(nextProps, prevState) {
    //第一步注册供应商信息时，后端返回的数据显示在联络人的表格上，对数据进行处理
    this.getTableDataList(nextProps, prevState)

  }

  componentDidUpdate(prevProps,prevState){
    //点击上一步时，将后端的数据回显
    this.getTableDataList(prevProps, prevState)
    //新增添加到表格里，同步父级组件的数据
    if (prevState.tableDataSource !== this.state.tableDataSource) {
      this.props.getTableData(this.state.tableDataSource)
    }
  }
  componentWillUnmount() {
    if (this.pubSubToken) {
      PubSub.unsubscribe(this.pubSubToken)
    }
  }
  render() {
    const {
      that,
      title,
      baseuploadformmodal,
      lzlistform,
      isShowBtn,
    } = this.props
    const {
      columns,
      tableDataSource,
      columnsWidthSum,
      tableBodyHeight,
      baseUploadFormModalVisible,
      lzListFormVisible,
      isAdd,
      isEdit,
    } = this.state
    return (
      <div>
        <Card title={title}>
          <CustomTableNoType
            rowKey={(record) => record.supplierContactUserId || record._uuid}
            columns={columns}
            dataSource={tableDataSource}
            scroll={{x: columnsWidthSum, y: tableBodyHeight}}
            that={that}
          />
        </Card>
        <div className='buts-container'>
          {/* 新增 */}
          {
            isShowBtn.addBtn ?
            <CustomButton id='COMMON_ADD' onClick={this.handleShowModal}/> :
            null
          }
          {/* 下载模板 */}
          {
            isShowBtn.downloadBtn ?
            <CustomButton id='COMMON_DOWNLOAD_MODAL' onClick={this.handleDownloadTemplate}/> :
            null
          }
          {/* 上传 */}
          {
            isShowBtn.importBtn ?
            <CustomButton id='COMMONS_UPLOAD' onClick={this.handleShowImportModal}/> :
            null
          }
      </div>
        {
          baseuploadformmodal ?
          <Modal
            title={<FormattedMessage id={baseuploadformmodal.titleId}/>}
            destroyOnClose={true}
            width={computedBodyWidth()*0.4}
            visible={baseUploadFormModalVisible}
            onCancel={this.closeUploadWindow}
          >
            <BaseUploadForm
              label={baseuploadformmodal.titleId}
              getFormRef={ref => this.uploadFormRef = ref}
              getFile={file=>this.getFiles(file)}
              fileType={FILE_TYPE}
              isMultiple={false}
              isShowUploadList={false}
              multiple={false}
              uploadFile={this.submitUploadFile}
              closeWindow={this.closeUploadWindow}
            />
          </Modal> :
          null
        }
        {
          lzlistform ?
          <LzListForm
            that={that}
            wrappedComponentRef={e => this.lzListFormModal = e}
            submitFormArrList={lzlistform.submitFormArrList}
            span={lzlistform.span}
            visible={lzListFormVisible}
            handleSubmit={this.handleSubmit}
            handleClose={this.closeLzListFormModal}
            isModal={true}
            isAdd={isAdd}
            isEdit={isEdit}
          >
          </LzListForm> :
          null
        }
      </div>
    )
  }
}

export default injectIntl(withRouter(ContactCardContent))
